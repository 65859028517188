import { MappingPropertyConfig, ValueDataType } from '../index';

export const createMappingPropertyConfig: (
  path: Array<string>,
  valueData: ValueDataType,
  localized: boolean,
  modifier?: (valueData: any | Array<any>) => any | Array<any>,
  additionalDataCode?: string
) => MappingPropertyConfig = (
  path: Array<string>,
  valueData: ValueDataType,
  localized: boolean,
  modifier?: (valueData: any | Array<any>) => any | Array<any>,
  additionalDataCode?: string
) => ({
  path,
  valueData,
  localized,
  modifier,
  additionalDataCode,
});
