import { ContentTypeConfig, ContentTypeMap, EPageContentTypes, TypePage, TypePageAppointment } from '../../types';

export type Page<T extends EPageContentTypes> = T extends EPageContentTypes.PAGE
  ? TypePage
  : T extends EPageContentTypes.PAGE_APPOINTMENT
  ? TypePageAppointment
  : never;
export interface ContentPageBuilderProps<T extends EPageContentTypes> {
  page: Page<T>;
  locale: string;
}

export const ContentPageBuilder = <T extends EPageContentTypes>({ page, locale }: ContentPageBuilderProps<T>) => {
  const renderContentElements = (page: Page<T>) => {
    return page.fields?.contentElements?.map((ce) => {
      if (ce?.sys?.contentType?.sys?.id && ContentTypeMap.has(ce.sys.contentType.sys.id)) {
        const Component = (ContentTypeMap.get(ce.sys.contentType.sys.id) as ContentTypeConfig).component as (
          props: any
        ) => JSX.Element;
        return <Component key={ce.sys.id} {...ce.fields} locale={locale} />;
      } else {
        return null;
      }
    });
  };

  return page ? <>{renderContentElements(page)}</> : <></>;
};

export default ContentPageBuilder;
