import styles from './account-teaser.module.scss';
import { Colors, getIcon, RoundButton, StandardButton, VeneerAnimation } from '@dxp/veneer';
import Link from 'next-translate-routes/link';
import { useTranslation } from 'next-i18next';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { PlatformWebappStaticRoutes } from '@dxp/core';

export interface AccountTeaserProps {
  headline: string;
  copy: string;
  items: Array<{ icon: string; title: string; subline?: string }>;
}

export const AccountTeaser = ({ headline, copy, items }: AccountTeaserProps) => {
  const { t } = useTranslation('account');

  return (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
    >
      <div className={styles['inner']}>
        <h2 className={styles['headline']} data-testid={'headline'}>
          {headline}
        </h2>
        <p className={styles['intro']} data-testid={'copy'}>
          {copy}
        </p>
        <div className={styles['buttons']}>
          <Link href={{ pathname: PlatformWebappStaticRoutes.account.register }}>
            <StandardButton label={t('login.register.button')} variant={Colors.brilliantBlue} />
          </Link>
          <Link href={{ pathname: PlatformWebappStaticRoutes.account.login }}>
            <StandardButton label={t('login.headline')} variant={Colors.feelgoodMint} />
          </Link>
        </div>

        <div className={styles['item-grid']}>
          {items.map((item) => {
            const IconComponent = getIcon(item.icon.charAt(0).toUpperCase() + item.icon.slice(1));
            return (
              <div className={styles['item']} key={uuidv4()}>
                <RoundButton
                  title={item.subline}
                  variant={'outline'}
                  icon={<IconComponent />}
                  interactive={false}
                  className={styles['item__icon']}
                />
                <div className={styles['item__text']}>
                  <h3 className={styles['item__title']}>{item.title}</h3>
                  {item.subline && <div className={styles['item__subline']}>{item.subline}</div>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};

export default AccountTeaser;
