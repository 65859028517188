export const tidyHtml = (input: string) => {
  return (
    input
      // replace empty spans
      .replace(/(<\/?span[^>]*>)/g, '')
      // replace empty tags
      .replace(/(<[^/>][^>]*><\/[^>]+>)/g, '')
      // replace inline styles
      .replace(/(style=".+")/g, '')
      // replace \n with br
      .replace(/\n/g, `</br>`)
  );
};
