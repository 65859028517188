import { useRouter } from 'next/router';
import styles from './prompt-forgot-password.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Colors, Input, StandardButton } from '@dxp/veneer';
import { useTranslation } from 'next-i18next';
import { CustomerActions, CustomerSelectors, CustomerState, PromptActions } from '../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ControllerEmailRules, InputDefaultError } from '../../../utils';
import { Controller, FieldValues, useForm } from 'react-hook-form';

export interface PromptForgotPasswordProps {
  className?: string;
}

export const PromptForgotPassword = (props: PromptForgotPasswordProps) => {
  const [isSent, setSent] = useState<boolean>(false);
  const [cooldown, _setCooldown] = useState<number>(-1);
  const cooldownRef = useRef<number>(cooldown);
  const setCooldown = (cd: number) => {
    cooldownRef.current = cd;
    _setCooldown(cd);
  };
  const [cooldownInterval, _setCooldownInterval] = useState<NodeJS.Timer | null>(null);
  const cooldownIntervalRef = useRef<NodeJS.Timer | null>(cooldownInterval);
  const setCooldownInterval = (int: NodeJS.Timer | null) => {
    cooldownIntervalRef.current = int;
    _setCooldownInterval(int);
  };
  const [customerMail, setCustomerMail] = useState<string>('');
  const { t } = useTranslation('account');
  const dispatch = useDispatch();
  const error = useSelector((state: { customer: CustomerState }) =>
    CustomerSelectors.selectError(state.customer, 'customerForgotPassword')
  );
  const loading = useSelector((state: { customer: CustomerState }) => CustomerSelectors.isLoading(state.customer));
  const { control, handleSubmit } = useForm({ mode: 'onTouched' });

  const { locale } = useRouter();

  useEffect(
    () => () => {
      dispatch(CustomerActions.clearError('customerForgotPassword'));
    },
    []
  );

  const onSubmit: (values: FieldValues) => void = (values: FieldValues) => {
    setCustomerMail(values['email']);
    dispatch(CustomerActions.customerForgotPassword({ email: values['email'], locale }));
    setSent(true);
  };

  const startCooldown = () => {
    setCooldown(60);
    setCooldownInterval(
      setInterval(() => {
        setCooldown(cooldownRef.current - 1);
        if (cooldownRef.current === 0 && cooldownIntervalRef.current !== null) {
          clearInterval(cooldownIntervalRef.current);
          setCooldown(-1);
        }
      }, 1000)
    );
  };

  return (
    <div className={classNames(styles['container'], props.className)}>
      {(!isSent || error || loading) && cooldownRef.current < 0 && (
        <>
          <div className={'headline'}>{t('prompts.forgotPassword.headline')}</div>
          <div className={classNames('copy', styles['copy'])}>{t('prompts.forgotPassword.copy')}</div>

          <form onSubmit={handleSubmit(onSubmit)} className={'form'} suppressHydrationWarning={true}>
            <Controller
              control={control}
              defaultValue={''}
              name={'email'}
              rules={ControllerEmailRules}
              render={({ field: { name, onChange, ref, value, onBlur }, fieldState: { error } }) => (
                <Input
                  error={InputDefaultError(error)}
                  name={name}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  autocomplete="email"
                  className={classNames('prompt-input', styles['prompt-input'])}
                  disabled={loading}
                  label={t('prompts.forgotPassword.label')}
                  placeholder={t('prompts.forgotPassword.placeholder')}
                  type="text"
                  variant={Colors.lightGrey}
                />
              )}
            />

            <div className={'buttons'}>
              <StandardButton
                isLoading={loading}
                label={t('prompts.button.submit')}
                variant={Colors.feelgoodMint}
                type="submit"
              />
              <StandardButton
                isLoading={loading}
                label={t('prompts.button.cancel')}
                variant={Colors.brilliantBlue}
                onClick={() => dispatch(PromptActions.close())}
                type="button"
              />
            </div>
          </form>
        </>
      )}
      {((isSent && !error && !loading) || cooldownRef.current >= 0) && (
        <>
          <div className={'headline'}>{t('prompts.forgotPassword.success')}</div>
          <div className={'copy'}>{t('prompts.forgotPassword.instructions', { email: customerMail })}</div>
          <div className={styles['retry-label']}>{t('prompts.forgotPassword.retryLabel')}</div>
          <div className={'buttons'}>
            <StandardButton
              label={`${t('prompts.forgotPassword.button.retry')}${
                cooldownRef.current !== -1 ? ` (${cooldownRef.current})` : ``
              }`}
              variant={Colors.brilliantBlue}
              disabled={cooldownRef.current !== -1}
              onClick={() => {
                dispatch(CustomerActions.customerForgotPassword({ email: customerMail, locale }));
                startCooldown();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PromptForgotPassword;
