export interface CategoryConstant {
  categoryCode: string;
  pdpSlug: string;
  compareSlug?: string;
}

export interface CategoryConstants {
  [key: string]: CategoryConstant;
}

export const categoryConstants: CategoryConstants = {
  accessories: {
    categoryCode: 'accessories',
    pdpSlug: 'accessories/[...slug]',
  },
  articulation: {
    categoryCode: 'Articulation',
    pdpSlug: 'equipment/articulation/[...slug]',
    compareSlug: 'equipment/articulation/compare/[...slug]',
  },
  facebows: {
    categoryCode: 'Artex_Facebow',
    pdpSlug: 'equipment/facebows/[...slug]',
  },
  materials: {
    categoryCode: 'Materials',
    pdpSlug: 'material/[slug]',
  },
  modelFabrication: {
    categoryCode: 'Model_Fabrication',
    pdpSlug: 'equipment/model-fabrication/[...slug]',
  },
  production: {
    categoryCode: 'Production_CAM',
    pdpSlug: 'equipment/production/[...slug]',
  },
  scan: {
    categoryCode: 'Scan',
    pdpSlug: 'equipment/scan/[...slug]',
  },
  sintering: {
    categoryCode: 'Sintering',
    pdpSlug: 'equipment/sintering/[...slug]',
  },
  software: {
    categoryCode: 'Software',
    pdpSlug: 'software/[...slug]',
  },
};
