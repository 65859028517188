import styles from './dropdown.module.scss';
import { Colors, InputPurpose } from '../../../types';
import { ForwardedRef, forwardRef, useId, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { VeneerAnimation } from '../../../animations';
import { IconArrow } from '../../decoration/icons';

export interface DropdownProps {
  autocomplete?: InputPurpose | 'off';
  variant: Colors.white | Colors.lightGrey;
  borders?: 'round' | 'square';
  name?: string;
  values: Array<KeyValuePair>;
  placeholder?: string;
  label?: string;
  isFormGroup?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  resetButton?: boolean;
  disabledPlaceholder?: boolean;
}

export interface KeyValuePair {
  key: string;
  label?: string;
  disabled?: boolean;
}

export const Dropdown = forwardRef(
  (props: DropdownProps & { className?: string }, ref?: ForwardedRef<HTMLSelectElement>) => {
    const id = useId();
    const [isHovered, setHovered] = useState<boolean>(false);

    const classes = classNames(
      styles['container'],
      props.className,
      { 'form-group': props.isFormGroup },
      { invalid: props.error && props.isFormGroup },
      { required: props.required && props.isFormGroup },
      { [styles['disabled']]: props.disabled }
    );

    return (
      <div className={classes} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        {props.label && (
          <label className={'label'} htmlFor={id} data-testid={'label'}>
            {props.label}
          </label>
        )}
        <div className={'input-container'}>
          <div className={styles['decorator']}>
            <motion.div
              className={styles['decorator__helper']}
              animate={
                isHovered
                  ? { y: ['0%', '100%', '100%', '-100%', '-100%', '0%'], x: ['0%', '0%', '100%', '100%', '0%', '0%'] }
                  : { y: 0 }
              }
              transition={{
                duration: 0.8,
                easing: VeneerAnimation.easings.inOutCubic,
                times: [0, 0.5, 0.5, 0.5, 0.5, 1],
              }}
            >
              <IconArrow
                color={props.isFormGroup && props.error ? Colors.error : Colors.intenseBlue}
                className={styles['decorator__icon']}
              />
            </motion.div>
          </div>
          <select
            aria-label={props.label ? props.label : 'Choose'}
            autoComplete={props.autocomplete}
            ref={ref}
            className={classNames(
              styles['select'],
              { 'form-control': props.isFormGroup },
              styles['select--' + props.variant],
              styles['select--' + props.borders ?? 'round']
            )}
            data-testid={'select'}
            name={props.name}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={props.disabled}
            value={props.value !== null ? props.value : undefined}
            id={id}
          >
            {props.placeholder && (
              <option data-testid={'select-default-option'} value={''} disabled={props.disabledPlaceholder}>
                {props.placeholder}
              </option>
            )}
            {props.values?.length &&
              props.values.map((v) => (
                <option key={v.key} data-testid={'select-option'} value={v.key} disabled={v.disabled}>
                  {v.label}
                </option>
              ))}
          </select>
        </div>
        {props.isFormGroup && (
          <div className={'error-message'}>
            <span data-testid={'error'}>{props.error}</span>
          </div>
        )}
      </div>
    );
  }
);

export default Dropdown;
