/**
 * Spryker client. Allows you to create instances of a client with access to the Spryker Glue API.
 */

import { AxiosInstance } from 'axios';
import create from './http-client';
import { SprykerClientParams } from './types';
import * as endpoints from './endpoints';

type EndpointDefinition<P extends Record<string, any>, R> = (http: AxiosInstance, params: P) => R;

const wrap =
  <P extends Record<string, any>, R>(http: AxiosInstance, fn: EndpointDefinition<P, R>) =>
  (params: P) =>
    fn(http, params);

export const localStorageKey = 'cld';

/**
 * Create a client instance
 * @param params - Client initialization parameters
 *
 * ```javascript
 * const client = akeneo({
 *   url: SPRYKER_API_URL
 * });
 * ```
 */
export const createClient = (params: SprykerClientParams) => {
  const http = create.httpClient(params);
  const httpAuth = create.httpClientAuthorized(params);

  return {
    raw: {
      http,
    },
    store: {
      getAll: wrap(http, endpoints.store.getAll),
    },
    cart: {
      get: wrap(httpAuth, endpoints.cart.get),
      create: wrap(httpAuth, endpoints.cart.create),
      addItem: wrap(httpAuth, endpoints.cart.addItem),
      addItems: wrap(httpAuth, endpoints.cart.addItems),
      deleteItem: wrap(httpAuth, endpoints.cart.deleteItem),
      changeItemQuantity: wrap(httpAuth, endpoints.cart.changeItemQuantity),
      addVoucher: wrap(httpAuth, endpoints.cart.addVoucher),
      deleteVoucher: wrap(httpAuth, endpoints.cart.deleteVoucher),
      addCustomOrderReference: wrap(httpAuth, endpoints.cart.addCustomOrderReference),
    },
    guestCart: {
      get: wrap(http, endpoints.guestCart.get),
      addItem: wrap(http, endpoints.guestCart.addItem),
      addItems: wrap(http, endpoints.guestCart.addItems),
      deleteItem: wrap(http, endpoints.guestCart.deleteItem),
      changeItemQuantity: wrap(http, endpoints.guestCart.changeItemQuantity),
      addVoucher: wrap(http, endpoints.guestCart.addVoucher),
      deleteVoucher: wrap(http, endpoints.guestCart.deleteVoucher),
    },
    catalogSearch: {
      query: wrap(http, endpoints.catalogSearch.query),
    },
    concreteProducts: {
      bySku: wrap(http, endpoints.concreteProducts.bySku),
    },
    abstractProducts: {
      bySku: wrap(http, endpoints.abstractProducts.bySku),
    },
    auth: {
      login: wrap(http, endpoints.auth.login),
      logout: () => {
        if (typeof window !== 'undefined') {
          localStorage.removeItem(localStorageKey);
        }
      },
      forgotPassword: wrap(http, endpoints.auth.forgotPassword),
      restorePassword: wrap(http, endpoints.auth.restorePassword),
    },
    customer: {
      create: wrap(http, endpoints.customer.create),
      byCustomerRef: wrap(httpAuth, endpoints.customer.byCustomerRef),
      update: wrap(httpAuth, endpoints.customer.update),
      updatePassword: wrap(httpAuth, endpoints.customer.updatePassword),
    },
    address: {
      create: wrap(httpAuth, endpoints.address.create),
      byCustomerRef: wrap(httpAuth, endpoints.address.getAddresses),
      update: wrap(httpAuth, endpoints.address.patchAddress),
      delete: wrap(httpAuth, endpoints.address.deleteAddress),
    },
    checkoutData: {
      postAuthorized: wrap(httpAuth, endpoints.checkoutData.postAuthorized),
      postUnauthorized: wrap(http, endpoints.checkoutData.postUnauthorized),
    },
    checkout: {
      postAuthorized: wrap(httpAuth, endpoints.checkout.postAuthorized),
      postUnauthorized: wrap(http, endpoints.checkout.postUnauthorized),
    },
    checkoutPayments: {
      getAdyenPaymentMethodsConfigAuthorized: wrap(
        httpAuth,
        endpoints.checkoutPayments.getAdyenPaymentMethodsConfigAuthorized
      ),
      getAdyenPaymentMethodsConfigUnauthorized: wrap(
        http,
        endpoints.checkoutPayments.getAdyenPaymentMethodsConfigUnauthorized
      ),
    },
    orders: {
      get: wrap(httpAuth, endpoints.orders.get),
      getOne: wrap(httpAuth, endpoints.orders.getOne),
    },
    order: {
      updateOrderPaymentAuthorized: wrap(httpAuth, endpoints.order.updateOrderPayment),
      updateOrderPaymentGuest: wrap(http, endpoints.order.updateOrderPaymentGuest),
    },
  };
};

export type SprykerClientAPI = ReturnType<typeof createClient>;

export * from './constants';
export * from './endpoints';
export * from './helper';
export * from './interfaces';
export * from './types';
export * from '../mocks';

export default createClient;
