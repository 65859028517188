export const QUERY_BRANDLEVEL_SEARCH = `
query BrandLevelSearch($brand: ID!, $searchterm: String) {
  brand(id: $brand) {
    id
    name
    search(page: 1, limit: 10, query: {term: $searchterm}) {
      total
      edges {
        node {
          ... on Asset {
            id
            title
            createdAt
          }
          ...on Document {
            previewUrl
            downloadUrl
            size
            pageCount
            extension
          }
          ...on Image {
            previewUrl
            downloadUrl
            size
            extension
          }
        }
      }
    }
  }
}
`;

export const QUERY_BRAND_LIBRARIES = `
query BrandLibraries($brand: ID!) {
  brand(id: $brand) {
    id
    name
    libraries(page: 1, limit: 30) {
      total
      items {
        id
        name
        assetCount
        customMetadataProperties {
          id
          name
          defaultValue
          type {
            name
            ... on CustomMetadataPropertyTypeSelect {
              options {
                id
                value
                isDefault
              }
            }
            ... on CustomMetadataPropertyTypeMultiSelect {
              options {
                id
                value
                isDefault
              }
            }
          }
        }
        metadataFields {
          id
          label
          type
          isSearchable
          isEditable
          isVisible
          values {
            value
          }
        }
      }
    }
  }
}
`;

export const QUERY_LIBRARY_SEARCH = `
query LibrarySearch($libraryId: ID!, $page: Int, $pageSize: Int, $query: AssetQueryInput!) {
  library(id: $libraryId) {
    ... on Library {
      id
      name
      assets(page: $page, limit: $pageSize, query: $query) {
        total
        page
        limit
        items {
          type: __typename
          id
          title
          modifiedAt
          description
          tags {
            value
          }
          attachments {
            id
            name
            filename
            type
            extension
            size
            downloadUrl(validityInDays: 1, permanent: false)
          }
          metadataValues {
            id
            value
            metadataField {
              id
              label
            }
          }
          ...on Document {
            previewUrl
            downloadUrl
            size
            pageCount
            extension
          }
          ...on Video {
            previewUrl
            downloadUrl
            size
            duration
            bitrate
            extension
          }
          ...on Image {
            previewUrl
            downloadUrl
            size
            extension
          }
        }
      }
    }
  }
}
`;

export const QUERY_ASSET = `
query Asset($assetId: ID! ) {
  asset(id: $assetId) {
    type: __typename
    id
    title
    modifiedAt
    description
    tags {
      value
    }
    metadataValues {
      id
      value
      metadataField {
        id
        label
      }
    }
    ...on Video {
      previewUrl
      downloadUrl
      size
      duration
      bitrate
      extension
    }
    ...on Image {
      previewUrl
      downloadUrl
      size
      extension
    }
  }
}
`;
