import styles from './fullwidth-media.module.scss';
import { IHighlightVideo } from '@dxp/catalog';
import classNames from 'classnames';
import { MutableRefObject, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useVideoAutoplay, YoutubePlayer } from '@dxp/core';
import { Colors, IconPlay, RoundButton, VeneerAnimation } from '@dxp/veneer';
import Image from 'next/image';

/**
 * Workaround to conditionally use the hook
 * @see https://www.benmvp.com/blog/conditional-react-hooks/
 */
const AutoPlayHelper = (videoRef: MutableRefObject<HTMLVideoElement | null>) => {
  useVideoAutoplay(videoRef);

  return null;
};

export interface FullwidthMediaProps extends IHighlightVideo {
  videoAutoplay: boolean;
  youtubeCode?: string;
  classNames?: string;
  articleLayout?: boolean;
}

export const FullwidthMedia = (props: FullwidthMediaProps) => {
  const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null);
  const [playing, setPlaying] = useState<boolean>(props.videoAutoplay);

  return props.videoUrl !== '' || props.thumbnailUrl || props.youtubeCode !== '' ? (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(
        props.classNames,
        styles['container'],
        props.articleLayout ? styles['container--article'] : ''
      )}
    >
      {props.videoAutoplay && <AutoPlayHelper {...videoRef} />}

      <div className={styles['item-container']}>
        {props.videoUrl && props.videoUrl !== '' && !props.youtubeCode ? (
          <>
            <video
              ref={videoRef}
              className={classNames(styles['item'], styles['item--video'])}
              title={props.title}
              muted
              loop
              playsInline
              controls={!props.videoAutoplay && playing}
              onPause={() => setPlaying(false)}
              onEnded={() => setPlaying(false)}
              onPlay={() => setPlaying(true)}
              poster={props.thumbnailUrl}
              data-testid="video"
            >
              <source src={props.videoUrl} type="video/mp4" data-testid="source" />
              Your browser does not support the video tag.
            </video>
            <AnimatePresence initial={false} mode={'sync'}>
              {!playing && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, ease: 'linear' }}
                >
                  <RoundButton
                    variant={Colors.brilliantBlue}
                    icon={<IconPlay />}
                    size={'large'}
                    title={'Play'}
                    className={styles['play-button']}
                    onClick={() => videoRef.current?.play()}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </>
        ) : (
          props.thumbnailUrl &&
          !props.youtubeCode && (
            <Image
              width={1810}
              height={887}
              className={classNames(styles['item'], styles['item--image'])}
              src={props.thumbnailUrl}
              alt={props.alt}
              title={props.title}
              data-testid="image"
            />
          )
        )}
        {props.youtubeCode && (
          <YoutubePlayer className={styles['youtube-iframe']} id={props.youtubeCode} title={props.title} />
        )}
      </div>
    </motion.div>
  ) : null;
};

export default FullwidthMedia;
