export type JSONValue = string | number | boolean | { [x: string]: JSONValue } | Array<JSONValue>;

export interface CustomTrackingEvent {
  event: CustomSEOEvents;
  [property: string]: JSONValue;
}

export enum CustomSEOEvents {
  MANUALLY_PLAY_VIDEO = 'manually_play_video',
  OPEN_ACCORDION = 'open_accordion',
  SIGN_UP = 'sign_up',
}

interface EventKeyFeature {
  event: CustomSEOEvents.OPEN_ACCORDION;
  properties: {
    label: string;
  };
}

interface EventManuallyPlayVideo {
  event: CustomSEOEvents.MANUALLY_PLAY_VIDEO;
  properties: {
    location: string;
    name: string;
  };
}

interface EventSignUp {
  event: CustomSEOEvents.SIGN_UP;
  properties: {
    id: string;
    salutation: string;
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    phone: string;
    address1?: string;
    address2?: string;
    zipCode?: string;
    city?: string;
    iso2code?: string | number | boolean;
  };
}

export type CustomTrackingEventPayload = EventManuallyPlayVideo | EventKeyFeature | EventSignUp;
