import { PayloadAction } from '@reduxjs/toolkit';
import { EntryCollection } from 'contentful';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TypeTrainerFields } from '../../../types';
import { contentful } from '../../../utils';
import { ContentfulDataActions, TrainerRequest } from '../contentful-data.slice';

export function* watchFetchTrainers() {
  yield takeEvery(ContentfulDataActions.trainersRequested, fetchTrainersAsync);
}

function* fetchTrainersAsync(action: PayloadAction<TrainerRequest>) {
  try {
    const trainers: EntryCollection<TypeTrainerFields> = yield call(contentful.getTrainers, action.payload);
    const parsedTrainers = trainers.items?.map(({ sys, fields }) => ({ id: sys.id, data: fields }));

    yield put(ContentfulDataActions.trainersReceived(parsedTrainers));
  } catch (error) {
    console.error(error);
    yield put(ContentfulDataActions.trainersRequestFailed());
  }
}
