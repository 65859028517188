import styles from './row-item.module.scss';
import classNames from 'classnames';
import { Colors } from '../../../types';
import TextButton from '../../interaction/text-button/text-button';
import { IconDownload, IconHeart, IconLink } from '../../decoration/icons';

export interface RowItemProps {
  id: string;
  title: string;
  meta?: string;
  languages?: string;
  additional_information?: string;
  variant: 'download' | 'vacancy';
  url?: string;
  hideWishlist?: boolean;
  iconColor?: Colors.intenseBlue | Colors.smartBlue;
}

export const RowItem = (props: RowItemProps & { className?: string }) => {
  const renderTitle = () => {
    return (
      <>
        <div className={styles['row-item__title']} data-testid="row-item-title">
          {props.title}
        </div>

        <div className={styles['row-item__meta']} data-testid="row-item-meta">
          <div className={styles['row-item__label']}>{props.meta}</div>
        </div>
      </>
    );
  };

  return (
    <div
      className={classNames(styles['row-item'], props.className, styles['row-item--' + props.variant])}
      data-testid={`row-item-${props.id}`}
    >
      {props.variant === 'download' && props.url ? (
        <a
          href={props.url}
          rel="noreferrer"
          target="_blank"
          className={styles['row-item__top']}
          aria-label={props.title}
        >
          {renderTitle()}
        </a>
      ) : (
        <div className={styles['row-item__top']}>{renderTitle()}</div>
      )}
      <div className={styles['row-item__bottom']}>
        {props.languages && <div className={styles['row-item__additional-information']}>{props.languages}</div>}

        <div className={styles['row-item__additional-information']}>
          {props.variant === 'download' && !props.hideWishlist ? (
            <TextButton
              label={props.additional_information ? props.additional_information : ''}
              icon={<IconHeart />}
              color={Colors.intenseBlue}
              size={'subtext'}
              className={styles['row-item__add-to-wishlist']}
            />
          ) : props.additional_information ? (
            <div className={styles['row-item__label']}>{props.additional_information}</div>
          ) : null}
        </div>
        {props.variant === 'download' && props.url ? (
          <a
            href={props.url}
            aria-label={props.title}
            rel="noreferrer"
            target="_blank"
            className={styles['row-item__link']}
            data-testid={'row-item-anchor'}
          >
            <svg
              className={classNames(styles['row-item__icon'], styles['row-item__icon--download'])}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 26 36"
            >
              <g
                stroke={props.iconColor === Colors.smartBlue ? '#005ba0' : '#002d5f'}
                strokeMiterlimit="10"
                strokeWidth="1"
              >
                <path d="m4.802465587854385,29.504586845636368 h18.31315 " />

                <g className={styles['download-arrow-animate']}>
                  <path d="m13.958715587854385,8.504586845636368 v16.24 " />
                  <path d="m7.423555587854386,18.176186845636366 l6.44396,6.5683 l6.5355,-6.6616 " />
                </g>
              </g>
            </svg>
          </a>
        ) : (
          <div className={styles['row-item__link']}>
            {props.variant === 'download' ? (
              <IconDownload
                color={props.iconColor ?? Colors.intenseBlue}
                className={classNames(styles['row-item__icon'], styles['row-item__icon--link'])}
              />
            ) : (
              <IconLink
                color={props.iconColor ?? Colors.intenseBlue}
                className={classNames(styles['row-item__icon'], styles['row-item__icon--link'])}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RowItem;
