import { IPageConfig, PageConfigContext, PlatformWebappStaticRoutes } from '@dxp/core';
import { Colors, Loader, StandardButton } from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CartActions, useAnyCart } from '../../../';
import { CartEmptyCta, CartList, CartSummary } from '../cart-particles/';
import styles from './cart-in-flyout.module.scss';

/* TODO translations */

export function CartInFlyout() {
  const { t, i18n } = useTranslation(['cart']);
  const [i18nResourcesLoaded, setI18nResourcesLoaded] = useState<boolean>(false);
  const dispatch = useDispatch();
  const pageConfig: IPageConfig | null = useContext(PageConfigContext);

  useEffect(() => {
    if (i18n?.resolvedLanguage) {
      i18n.reloadResources(i18n.resolvedLanguage, ['cart']).then(() => setI18nResourcesLoaded(true));
    }
  }, [i18n.resolvedLanguage]);

  const closeFlyout = () => {
    dispatch(CartActions.closeCartFlyout());
  };

  const hasCartAndItems = useAnyCart();

  return i18nResourcesLoaded ? (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['title']}>{t('headline')}</div>
      </div>
      {hasCartAndItems ? (
        <CartList className={styles['body']} manipulatable={true} />
      ) : (
        <div className={classNames(styles['body'], styles['body--empty-cart'], styles['empty-cart'])}>
          <div className={styles['empty-cart__title']}>{t('emptyCart.title')}</div>
          <div className={styles['empty-cart__description']}>{t('emptyCart.description')}</div>
          <div className={styles['empty-cart__links']}>
            {!!pageConfig?.cartCTA &&
              pageConfig.cartCTA.map((cta) => (
                <CartEmptyCta key={cta.linkUrl} {...cta} onClick={() => closeFlyout()} />
              ))}
          </div>
        </div>
      )}
      <div className={styles['footer']}>
        <div className={styles['footer__top']}>{hasCartAndItems && <CartSummary />}</div>
        <div className={classNames(styles['footer__cta'], !hasCartAndItems ? styles['footer__cta--inactive'] : '')}>
          <Link href={PlatformWebappStaticRoutes.commerce.cart} onClick={() => closeFlyout()}>
            <StandardButton label={t('toCart')} variant={Colors.feelgoodMint} disabled={!hasCartAndItems} />
          </Link>
          <Link href={PlatformWebappStaticRoutes.commerce.checkout.index} onClick={() => closeFlyout()}>
            <StandardButton label={t('toCheckout')} variant={Colors.brilliantBlue} disabled={!hasCartAndItems} />
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <Loader color={Colors.intenseBlue} />
  );
}

export default CartInFlyout;
