import styles from './material-overview-column.module.scss';
import classNames from 'classnames';
import { IMaterialColumn } from '../../../interfaces';
import Link from 'next-translate-routes/link';
import { pdpLink } from '../../../utils';

export interface MaterialOverviewColumnProps {
  column: IMaterialColumn;
}

export const MaterialOverviewColumn = (props: MaterialOverviewColumnProps & { className?: string }) => (
  <div className={classNames(styles['container'], props.className)}>
    <div className={styles['title']} data-testid="columnCategory">
      {props.column.category}
    </div>
    {!!props.column.materials?.length &&
      props.column.materials.map((material, index) => (
        <div className={styles['item']} key={material.materialCode}>
          <Link href={pdpLink(material.slug, ['Materials'])} data-testid="columnLabels">
            <span className={styles['count']}>{String(index + 1).padStart(2, '0')}</span>
            {material.label}
          </Link>
        </div>
      ))}
  </div>
);

export default MaterialOverviewColumn;
