import {
  CartActions,
  CartSelectors,
  CartState,
  ConcreteProductActions,
  ConcreteProductSelectors,
  ConcreteProductState,
} from '../../../../';
import styles from './product-variant-row.module.scss';
import PriceInformation from '../../../price-information/price-information';
import {
  Colors,
  IconHeart,
  Price,
  Quantifier,
  RoundButton,
  ScreenSizes,
  StandardButton,
  useScreenSizes,
  DeliveryStatus,
  DeliveryState,
} from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { PlatformWebappFeatureFlags, useStateSafe } from '@dxp/core';
import Link from 'next/link';
import { IConcreteProductDataParsed } from '@dxp/spryker-client';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export interface ProductVariantRowProps {
  title?: string;
  sku: string;
  buyNow: boolean;
  isFirst?: boolean;
  currentQuantity?: number;
  onCartAdd?: (sku: string, quantity: number) => void;
}

export const ProductVariantRow = (props: ProductVariantRowProps) => {
  const { t } = useTranslation('common');
  const screenSize = useScreenSizes();
  const dispatch = useDispatch();
  const { locale } = useRouter();
  const cartLoading: boolean = useSelector((state: { cart: CartState }) => CartSelectors.selectCartLoading(state.cart));

  const [deliveryState, setDeliveryState] = useStateSafe<DeliveryState | undefined>(undefined);

  const concreteProductDataParsed: IConcreteProductDataParsed | undefined = useSelector(
    (state: { concreteProduct: ConcreteProductState }) =>
      ConcreteProductSelectors.selectConcreteProduct(state.concreteProduct, props.sku)
  );

  const [quantity, setQuantity] = useState<number>(0);

  useEffect(() => {
    if (props.sku) {
      dispatch(ConcreteProductActions.concreteProductRequested({ sku: props.sku, locale }));
    }
  }, [dispatch, locale, props.sku]);

  useEffect(() => {
    if (props.onCartAdd) {
      props.onCartAdd(props.sku, quantity);
    }
  }, [quantity]);

  useEffect(() => {
    if (concreteProductDataParsed?.concreteProduct) {
      setDeliveryState(
        concreteProductDataParsed?.concreteProduct.isRealtime
          ? concreteProductDataParsed?.concreteProduct.isAvailable
            ? DeliveryState.AVAILABLE
            : concreteProductDataParsed?.concreteProduct.isNeverOutOfStock
            ? DeliveryState.PENDING
            : DeliveryState.NOT_AVAILABLE
          : undefined
      );
    }
  }, [concreteProductDataParsed, setDeliveryState]);

  const handleAddToCartClick = (): void => {
    //add product to current cart
    if (locale && PlatformWebappFeatureFlags.commerce.enable[locale]) {
      dispatch(
        CartActions.addItemToCart({
          locale: locale,
          payload: { sku: props.sku, quantity },
        })
      );
    } else {
      window.open(`${t('product.commerce.cart.add.link')}search?q=${props.sku}`, '_ blank');
    }
  };

  return (
    <div className={classNames(styles['container'], { [styles['first']]: props.isFirst })} data-testid="container">
      <div className={classNames(styles['col'], styles['col--left'])}>
        {props.title && (
          <div className={classNames(styles['size'])} data-testid="row-title">
            {props.title}
          </div>
        )}
        <div className={classNames(styles['sku'], 'subtext-regular')}>
          {t('product.sku')}: <span data-testid={'product-sku'}>{props.sku}</span>
        </div>
      </div>
      <div className={classNames(styles['col'], styles['col--right'])}>
        <div className={styles['information']}>
          {props.sku && (
            <>
              {deliveryState && <DeliveryStatus className={styles['delivery-status']} deliveryState={deliveryState} />}
              <PriceInformation
                className={styles['price-information']}
                concreteProduct={concreteProductDataParsed?.concreteProduct}
              >
                <Price
                  layout={screenSize === ScreenSizes.LARGE ? 'minimal' : 'vertical'}
                  priceSize={'large'}
                  vat={false}
                />
              </PriceInformation>
            </>
          )}
        </div>
        <div className={classNames(styles['controls'])} data-testid="controls">
          {locale && PlatformWebappFeatureFlags.commerce.enable[locale] && (
            <Quantifier
              loading={cartLoading}
              variant={Colors.lightGrey}
              onChange={(q) => setQuantity(q)}
              minimum={0}
              triggerReset={props.currentQuantity === 0 ? 1 : 0}
              value={props.currentQuantity || 0}
            />
          )}
          {screenSize === ScreenSizes.SMALL
            ? props.buyNow && (
                <StandardButton
                  className={classNames(styles['button'], styles['button--cart'])}
                  variant={Colors.brilliantBlue}
                  label={t('product.commerce.cart.add')}
                  onClick={handleAddToCartClick}
                />
              )
            : PlatformWebappFeatureFlags.wishlistButton && (
                <RoundButton
                  className={classNames(styles['button'], styles['button--wishlist'])}
                  variant={Colors.feelgoodMint}
                  icon={<IconHeart />}
                />
              )}
        </div>
      </div>
    </div>
  );
};

export default ProductVariantRow;
