import styles from './round-button.module.scss';
import { Colors } from '../../../types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { cloneElement, ReactElement, useEffect, useState } from 'react';
import { VeneerAnimation } from '@dxp/veneer/animations';

export interface RoundButtonProps {
  disabled?: boolean;
  variant:
    | Colors.brilliantBlue
    | Colors.shinyYellow
    | Colors.feelgoodMint
    | Colors.white
    | Colors.lightGrey
    | 'outline';
  outlineColor?: Colors;
  icon?: ReactElement;
  label?: string;
  inactive?: boolean;
  interactive?: boolean;
  size?: 'standard' | 'medium' | 'large';
  title?: string;
  type?: 'button' | 'reset' | 'submit';
  fillOnHover?: boolean;
  fillBackgroundOnHover?: boolean;
  pseudoHover?: boolean;
  scaleOnHover?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const RoundButton = (props: RoundButtonProps & { className?: string }) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const classes: string = classNames(
    styles['inner'],
    styles[props.variant],
    props.className,
    styles[props.size ?? 'standard'],
    {
      [styles['inactive']]: props.disabled || props.inactive,
      [styles['not-interactive']]: props.interactive === false,
      [styles['fill-background']]: props.fillBackgroundOnHover,
      [styles['outline-color--' + props.outlineColor]]: props.outlineColor,
    }
  );

  useEffect(() => {
    setHovered(!!props.pseudoHover);
  }, [props.pseudoHover]);

  const handleMouseEnter = () => {
    if (props.interactive && !props.inactive && !props.disabled) {
      setHovered(true);
      if (props.onMouseEnter) {
        props.onMouseEnter();
      }
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
    if (props.onMouseLeave) {
      props.onMouseLeave();
    }
  };

  return (
    <motion.button
      animate={
        !props.disabled && !props.inactive && isHovered && props.scaleOnHover !== false
          ? { ...VeneerAnimation.uiScale, transition: VeneerAnimation.uiScaleTransition }
          : {}
      }
      className={classes}
      disabled={props.disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={props.onClick}
      title={props.title}
      type={props.type}
      data-testid={'round-button'}
    >
      {props.icon
        ? cloneElement(props.icon, {
            color: props.outlineColor
              ? props.outlineColor
              : isHovered && props.fillBackgroundOnHover
              ? Colors.white
              : props.variant === 'brilliant-blue' || props.variant === 'outline'
              ? Colors.intenseBlue
              : Colors.smartBlue,
            filled: props.fillOnHover && isHovered,
            className: styles['icon'],
          })
        : props.label && (
            <span className={styles['label']} data-testid={`label`}>
              {props.label}
            </span>
          )}
    </motion.button>
  );
};

export default RoundButton;
