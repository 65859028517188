import { ICart } from '@dxp/spryker-client';
import { createContext } from 'react';
import { useSelector } from 'react-redux';
import { CartSelectors, CartState } from '../../redux';
import CartInCheckout from './cart-in-checkout/cart-in-checkout';
import CartInFlyout from './cart-in-flyout/cart-in-flyout';
import CartOnPage from './cart-on-page/cart-on-page';

export interface CartWrapperProps {
  layout?: 'flyout' | 'page' | 'checkout';
}

export const CartContext = createContext<ICart | undefined>(undefined);

export const Cart = ({ layout }: CartWrapperProps) => {
  const defaultCart: ICart | undefined = useSelector((state: { cart: CartState }) =>
    CartSelectors.selectDefaultCart(state.cart)
  );

  let CartComponent;

  switch (layout) {
    case 'flyout':
      CartComponent = CartInFlyout;
      break;
    case 'checkout':
      CartComponent = CartInCheckout;
      break;
    default:
      CartComponent = CartOnPage;
      break;
  }

  return (
    <CartContext.Provider value={defaultCart}>
      <CartComponent />
    </CartContext.Provider>
  );
};

export default Cart;
