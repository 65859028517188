import {
  EIncludeTypes,
  IProductLabelsRowIncludedResponse,
  IProductRawResponse,
  TProductRowIncludedResponse,
} from '../../types';
import {
  IConcreteProductDataParsed,
  IIndexSignature,
  IProductLabel,
  IProductPropFullData,
  IRelationshipsDataResponse,
  ISuperAttribute,
} from '../../interfaces';
import { parseDescriptionAttributes, parseImageSets, parsePrices, parseSuperAttributes } from '../index';
import { concreteProductType } from '../../constants';

export const parseConcreteProductResponse = (response: IProductRawResponse): IConcreteProductDataParsed | null => {
  if (!response) {
    return null;
  }

  const initialProductData: IProductPropFullData = {
    sku: null,
    name: null,
    description: null,
    descriptionAttributes: null,
    images: null,
    prices: {
      priceOriginalGross: null,
      priceOriginalNet: null,
      priceDefaultGross: null,
      priceDefaultNet: null,
    },
    isAvailable: null,
    isNeverOutOfStock: null,
    isRealtime: null,
    quantity: null,
    productType: null,
  };

  const { data, included } = response;

  const result: IConcreteProductDataParsed = {
    superAttributes: null,
    concreteProduct: {
      ...initialProductData,
      sku: data.attributes.sku,
      name: data.attributes.name,
      description: data.attributes.description,
      productType: concreteProductType,
    },
    productLabels: [],
    selectedAttrNames: {},
  };

  result.concreteProduct.descriptionAttributes = parseDescriptionAttributes(
    data.attributes.attributeNames,
    data.attributes.attributes
  );

  const attributeNamesContainer: IIndexSignature = {};

  if (included) {
    included.forEach((row: TProductRowIncludedResponse) => {
      if (row.type === EIncludeTypes.CONCRETE_PRODUCT_IMAGE_SETS && row.id) {
        result.concreteProduct.images = parseImageSets(row.attributes.imageSets);

        return;
      }
      if (
        row.type === EIncludeTypes.CONCRETE_PRODUCT_PRICES &&
        row.id &&
        row.attributes.allPrices &&
        row.attributes.allPrices.length
      ) {
        result.concreteProduct.prices = parsePrices(row.attributes.allPrices, row.attributes.price);

        return;
      }
      if (row.type === EIncludeTypes.CONCRETE_PRODUCT_AVAILABILITIES && row.id) {
        result.concreteProduct.isAvailable = row.attributes.availability;
        result.concreteProduct.quantity = row.attributes.quantity;
        result.concreteProduct.isNeverOutOfStock = !!row.attributes.isNeverOutOfStock;
        result.concreteProduct.isRealtime = !!row.attributes.isRealtime;
      }
    });
  }

  const filteredIncludedLabels: TProductRowIncludedResponse[] = included
    ? included.filter((row) => row.type === EIncludeTypes.PRODUCT_LABELS)
    : [];

  if (data.relationships) {
    const labelsRelationships: { data: IRelationshipsDataResponse[] } =
      data.relationships[EIncludeTypes.PRODUCT_LABELS];
    const isLabelsExist = Boolean(labelsRelationships && filteredIncludedLabels.length);
    if (isLabelsExist) {
      const filteredAvailableLabels: string[] = labelsRelationships.data.map((item) => item.id);
      filteredAvailableLabels.forEach((availableLabelId: string) => {
        filteredIncludedLabels.forEach((includedLabel) => {
          const isLabelExist: boolean = availableLabelId === includedLabel.id;
          if (isLabelExist) {
            const labelData = {
              type: includedLabel.id,
              text: (includedLabel as IProductLabelsRowIncludedResponse).attributes.name,
              position: (includedLabel as IProductLabelsRowIncludedResponse).attributes.position,
            };
            result.productLabels.push(labelData as IProductLabel);
          }
        });
      });
    }
  }

  if (data?.attributes?.attributeMap) {
    const superAttributes: ISuperAttribute[] | null = parseSuperAttributes(
      data.attributes.attributeMap,
      attributeNamesContainer
    );
    result.superAttributes = superAttributes;

    if (superAttributes) {
      result.selectedAttrNames = superAttributes
        .map((attr) => attr.name)
        .reduce((accumulator: IIndexSignature, name: string) => {
          accumulator[name] = '';
          return accumulator;
        }, {});
    }
  }

  return result;
};
