import styles from './contact-person.module.scss';
import { IContactPerson } from '../../interfaces';
import { ContactTeaser, StandardButton, VeneerAnimation } from '@dxp/veneer';
import { Colors } from '@dxp/veneer/types';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

export interface ContactPersonProps {
  person: IContactPerson;
}

export const ContactPerson = (props: ContactPersonProps) => {
  const { t } = useTranslation('common');

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
    >
      <ContactTeaser
        className={styles['contact-teaser']}
        name={props.person.name}
        layout={'horizontal'}
        image={props.person.image}
        department={props.person.department}
        description={props.person.description}
        phone={props.person.phoneNumber}
        email={props.person.email}
        linkedInActive={props.person.linkedInActive}
        linkedProfile={props.person.linkedInUrl}
      >
        <StandardButton
          className={styles['button']}
          label={t('product.detail.makeAppointment')}
          variant={Colors.brilliantBlue}
        />
      </ContactTeaser>
    </motion.div>
  );
};

export default ContactPerson;
