import { Category } from '../types';

/**
 * Get the single value from akeneo's value object array optionally by given locale.
 * @param {Array<{ locale: string | null; data: string }>} array
 * @param {string} locale
 * @returns {string} The desired string value
 */
export const findStringValue: (
  array: Array<{ locale: string | null; data: string }>,
  locale?: string
) => string | '' = (array: Array<{ locale: string | null; data: string }>, locale?: string) =>
  array?.find((item: { locale: string | null; data: string }) => (locale ? item?.locale === locale : item?.data))
    ?.data || '';

/**
 * Get the single object value from akeneo's value object array optionally by given locale.
 * @param {Array<{ locale: string | null; data: unknown }>} array
 * @param {string} locale
 * @returns {unknown} The desired object value
 */
export const findObjectValue: (array: Array<{ locale: string | null; data: unknown }>, locale?: string) => unknown = (
  array: Array<{ locale: string | null; data: unknown }>,
  locale?: string
) =>
  array?.find((item: { locale: string | null; data: unknown }) => (locale ? item?.locale === locale : item?.data))
    ?.data;

/**
 * Get the values from akeneo's value object array optionally by given locale.
 * @param {Array<{ locale: string | null; data: Array<string> }>} array
 * @param {string} locale
 * @returns {Array<string>} The desired string values
 */
export const findStringsValue: (
  array: Array<{ locale: string | null; data: Array<string> }>,
  locale?: string
) => Array<string> = (array: Array<{ locale: string | null; data: Array<string> }>, locale?: string) =>
  array?.find((item: { locale: string | null; data: Array<string> }) => (locale ? item?.locale === locale : item?.data))
    ?.data || [];

/**
 * Converts two-level array to an object. Supports nested object with dot seperated keys (see @param key).
 * @param {any} object the destination object
 * @param {string} key first element of every array which will be an object property key
 * @param {any} value second element of every array which will be an object property value
 * @example
 * ```ts
 * // The source array:
 * const source: Array<any> = [
 *   ['id', 123],
 *   ['name', 'Awesome name'],
 *   ['address.street', 'Awesome street'],
 *   ['address.houseNumber', 42],
 *   ['address.zip', 57074],
 * ];
 * console.log(source.reduce(arrayToObject, {}));
 * // Prints following output:
 * // {
 * //   "id": 123,
 * //   "name": "Awesome name",
 * //   "address": {
 * //     "street": "Awesome street",
 * //     "houseNumber": 42,
 * //     "zip": 57074
 * //   }
 * // }
 * ```
 * @returns {any} converted object of the source array.
 */
export const arrayToObject = (object: any, [key, value]: [string, any]) => {
  const keys = key.split('.');
  const last = keys.pop();
  keys.reduce((o: any, k: string) => (o[k] ??= {}), object)[last as string] = value;
  return object;
};

/**
 * Get single value of nested object properties.
 * @param {any} object the source object
 * @param {Array<string>} path keys of the (nested) object which points to the desired value
 * @returns {any} the value
 */
export const getObjectValueByPath: any = (object: any, path: Array<string>) =>
  path.reduce((object: any, key: string) => object?.[key], object);

/**
 * Returns child categories, which have a parent in given categories.
 * @param {Array<Category>} categories
 * @example
 * ```ts
 * const categories: Array<Category> = [
 *   {
 *     code: 'Materials',
 *     parent: 'master',
 *     labels: {
 *       en_US: 'Materials',
 *     },
 *   },
 *   {
 *     code: 'Plastic',
 *     parent: 'Materials',
 *     labels: {
 *       en_US: 'Plastic',
 *     },
 *   },
 *   {
 *     code: 'Metal',
 *     parent: 'Materials',
 *     labels: {
 *       en_US: 'Metal',
 *     },
 *   },
 *   {
 *     code: 'Metal_1',
 *     parent: 'Metal',
 *     labels: {
 *       en_US: 'Metal 1',
 *     },
 *   },
 *   {
 *     code: 'Metal_1-a',
 *     parent: 'Metal_1',
 *     labels: {
 *       en_US: 'Metal 1.a',
 *     },
 *   },
 * ];
 * console.log(filterDeepestChildCategories(categories));
 * // Prints following result:
 * // [
 * //   {
 * //     "code": "Plastic",
 * //     "parent": "Materials",
 * //     "labels": {
 * //       "en_US": "Plastic"
 * //     }
 * //   },
 * //   {
 * //     "code": "Metal_1-a",
 * //     "parent": "Metal_1",
 * //     "labels": {
 * //       "en_US": "Metal 1.a"
 * //     }
 * //   }
 * // ]
 * ```
 * @return {Array<Category>}
 */
export const getDeepestChildCategories = (categories: Array<Category>): Array<Category> =>
  categories.filter(
    (category: Category) => !!category && !categories.some((cat: Category) => cat.parent === category.code)
  );
