import styles from './contact-forms.module.scss';
import { TypeCeContentFormsFields } from '../../types';
import classNames from 'classnames';
import { MutableRefObject, useRef, useState } from 'react';
import { Colors, ContactButton, HubspotForm, IconArrowLong, Pill, RoundButton } from '@dxp/veneer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import { HubSpotSelectors, HubSpotState } from '@dxp/core';
import { useSelector } from 'react-redux';

interface DetailItem {
  email: string;
  hubspotFormID: string;
  id: string;
  phone: string;
  question: string;
  title: string;
}

export const ContactForms = ({ headline, cta, items }: TypeCeContentFormsFields) => {
  const swiperRef: MutableRefObject<SwiperType | undefined> = useRef<SwiperType>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [formExists, setFormExists] = useState<boolean>(false);
  const portalId = useSelector((state: { hubSpot: HubSpotState }) => HubSpotSelectors.selectPortalId(state.hubSpot));

  const onSlideChangeTransitionEnd = (swiper: any) => {
    if (swiper.realIndex === 0) {
      setCurrentIndex(-1);
    }
  };

  const scrollTop = () => {
    if (containerRef?.current) containerRef.current.scrollIntoView();
  };

  const renderItems: (items: Array<DetailItem>) => Array<JSX.Element> = (items: Array<DetailItem>) =>
    Array.isArray(items)
      ? items.map((item: DetailItem, index: number) => {
          return (
            <Pill
              key={item.id}
              className={styles['pill']}
              label={item.question}
              variant={'feelgood-mint-outline'}
              onClick={() => {
                swiperRef.current?.slideNext();
                setFormExists(false);
                setCurrentIndex(index);
              }}
              interactive={true}
            ></Pill>
          );
        })
      : [<></>];

  const renderItemsDetail: (items: Array<DetailItem>, currentIndex: number) => Array<JSX.Element> = (
    items: Array<DetailItem>,
    currentIndex: number
  ) =>
    Array.isArray(items)
      ? items
          .filter((item: DetailItem, index: number) => index === currentIndex)
          .map((item: DetailItem, index: number) => {
            return (
              <div key={item.id} className={styles['slide--inner']}>
                <div className={classNames(styles['contact'])}>
                  <RoundButton
                    className={styles['back']}
                    variant={'outline'}
                    icon={<IconArrowLong />}
                    outlineColor={Colors.brilliantBlue}
                    onClick={() => swiperRef.current?.slidePrev()}
                  />
                  <h2 className={classNames(styles['contact__headline'], 'heading-2')}>{item.title}</h2>
                  {item.phone && (
                    <ContactButton
                      className={styles['contact__button']}
                      data={item.phone}
                      variant={'tel'}
                      color={Colors.brilliantBlue}
                    />
                  )}
                  {item.email && (
                    <ContactButton
                      className={styles['contact__button']}
                      data={item.email}
                      variant={'mailto'}
                      color={Colors.brilliantBlue}
                    />
                  )}
                </div>
                {formExists && <div className={styles['contact__divider']}></div>}
                <div className={styles['hubspot-form']}>
                  <HubspotForm
                    portalId={portalId}
                    formId={item.hubspotFormID}
                    region={'na1'}
                    color={Colors.brilliantBlue}
                    onBeforeFormInit={() => setFormExists(true)}
                    onFormSubmitted={scrollTop}
                  />
                </div>
              </div>
            );
          })
      : [<></>];

  return (
    <div className={styles['container']} ref={containerRef}>
      <div className={styles['inner']}>
        <Swiper
          slidesPerView={1}
          loop={false}
          allowTouchMove={false}
          simulateTouch={false}
          speed={500}
          onBeforeInit={(swiper) => (swiperRef.current = swiper)}
          onSlideChangeTransitionEnd={(swiper) => onSlideChangeTransitionEnd(swiper)}
        >
          <SwiperSlide className={styles['slide']}>
            <div className={styles['slide--inner']}>
              <h2 className={classNames(styles['headline'], 'heading-2')}>{headline}</h2>
              {cta && <h3 className={classNames(styles['cta'], 'heading-2')}>{cta}</h3>}
              <div className={styles['pill__container']}>{renderItems(items)}</div>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles['slide']}>{renderItemsDetail(items, currentIndex)}</SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default ContactForms;
