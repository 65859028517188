import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { SprykerClientParams } from './types';
import * as queryString from 'query-string';
import { ICustomerLoginDataParsed, ICustomerLoginRawResponse } from './interfaces';
import { parseLoginDataResponse } from './helper';
import { localStorageKey } from './index';

const defaultConfig = {
  insecure: false,
  retryOnError: true,
  headers: {} as Record<string, string | number | boolean>,
  httpAgent: false,
  httpsAgent: false,
  timeout: 30000,
  proxy: false as const,
  basePath: '',
  adapter: undefined,
  maxContentLength: 1073741824, // 1GB
  paramsSerializer: queryString.stringify,
};

/**
 * Create preconfigured authorized axios instance
 * @private
 * @param {SprykerClientParams} options - Initialization parameters for the HTTP client
 * @return {AxiosInstance} Initialized axios instance
 */
const createHttpClientAuthorized = (options: SprykerClientParams): AxiosInstance => {
  const { url }: { url: string } = options;
  const baseURL: string = url.endsWith('/') ? url.substring(0, url.length - 1) : url;
  const isBrowser = typeof window !== 'undefined';

  const instance: AxiosInstance = axios.create({
    ...defaultConfig,
    ...(options.axiosOptions || {}),
    baseURL,
  }) as AxiosInstance;

  const refreshAccessToken: () => Promise<string> = async () => {
    if (isBrowser) {
      const lsCustomerLoginData: ICustomerLoginDataParsed | null =
        localStorage.getItem(localStorageKey) !== null
          ? JSON.parse(localStorage.getItem(localStorageKey) as string)
          : null;

      if (lsCustomerLoginData?.refreshToken) {
        const body = {
          data: {
            type: 'refresh-tokens',
            attributes: {
              refreshToken: lsCustomerLoginData?.refreshToken,
            },
          },
        };

        const response: AxiosResponse<ICustomerLoginRawResponse> = await axios.post(
          `${baseURL}/refresh-tokens`,
          body,
          {}
        );

        const newCustomerLoginData: ICustomerLoginDataParsed | null = parseLoginDataResponse(response.data);

        if (newCustomerLoginData && newCustomerLoginData.accessToken && newCustomerLoginData.refreshToken) {
          localStorage.setItem(localStorageKey, JSON.stringify(newCustomerLoginData));
          return newCustomerLoginData.accessToken;
        }
      }
    }
    return '';
  };

  instance.interceptors.request.use(async (config) => {
    const accessToken: string = isBrowser
      ? localStorage.getItem(localStorageKey) !== null
        ? (JSON.parse(localStorage.getItem(localStorageKey) as string) as ICustomerLoginDataParsed).accessToken
        : ''
      : '';

    return {
      ...config,
      headers: {
        ...config.headers,
        ...(accessToken && { Authorization: `Bearer ${accessToken || (await refreshAccessToken())}` }),
      },
    };
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        originalRequest.headers.Authorization = `Bearer ${await refreshAccessToken()}`;
        return instance(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

const create = {
  httpClient: createHttpClientAuthorized,
  httpClientAuthorized: createHttpClientAuthorized,
};

export default create;
