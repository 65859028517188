import { TypeCeVacancyListingFields } from '../../types';
import VacancyListingFull from './vacancy-listing-full/vacancy-listing-full';
import VacancyListingShort from './vacancy-listing-short/vacancy-listing-short';
import styles from './vacancy-listing.module.scss';

export function VacancyListing({ type, introShortlist, linkToFullList, category }: TypeCeVacancyListingFields) {
  return (
    <div className={styles['container']} data-elastic-include={true}>
      <div className={styles['inner']}>
        {type === 'Short' ? (
          <VacancyListingShort linkToFullList={linkToFullList ?? ''} intro={introShortlist ?? ''} key={'short'} />
        ) : (
          <VacancyListingFull category={category} intro={introShortlist ?? ''} key={'full'} />
        )}
      </div>
    </div>
  );
}

export default VacancyListing;
