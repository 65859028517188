import { Attribute, AttributeOption, MappingConfig, ValueDataType } from '@dxp/akeneo-client';

export interface IMaterialDetail {
  sku: string;
  parent: string;
  blank_color: string;
  blank_height: string;
  blank_mandrel: string;
  effect_color: string;
}

export interface IAttributeStore {
  attribute: Attribute;
  code: string;
  options: Array<AttributeOption>;
}

export interface IAttributeFilter {
  parent: string;
  attribute: AttributeOption;
}

export interface IMaterialFilter {
  activeBlankShape: string;
  activeBlankColor: string;
  activeBlankHeight: string;
  activeBlankMandrel: string;
  activeEffectColor: string;
}

/**
 * Mapping configuration for IMaterialDetail interface
 */
export const IMaterialDetailConfig: MappingConfig = {
  sku: {
    path: ['identifier'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  parent: {
    path: ['parent'],
    valueData: ValueDataType.direct,
    localized: true,
  },
  blank_height: {
    path: ['values', 'blank_height'],
    valueData: ValueDataType.string,
    localized: false,
  },
  blank_color: {
    path: ['values', 'blank_color'],
    valueData: ValueDataType.string,
    localized: false,
  },
  blank_mandrel: {
    path: ['values', 'blank_mandrel'],
    valueData: ValueDataType.string,
    localized: false,
  },
  effect_color: {
    path: ['values', 'effect_color'],
    valueData: ValueDataType.string,
    localized: false,
  },
};
