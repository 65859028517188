import { ICheckoutData, ICheckoutDataRequestAttributes } from '@dxp/spryker-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { sprykerClient } from '../../index';
import { CheckoutDataActions } from './slice';

/**
 * The watcher function to submit and request checkout-data
 */
export function* watchCheckoutDataRequested() {
  yield takeEvery(CheckoutDataActions.checkoutDataRequested, fetchCheckoutDataAsync);
}

/**
 * The worker function to submit and request checkout-data
 * @param action
 */
export function* fetchCheckoutDataAsync(
  action: PayloadAction<{
    locale: string | undefined;
    checkoutDataRequestAttributes: ICheckoutDataRequestAttributes;
    anonymId: string | null;
  }>
) {
  try {
    const { locale, checkoutDataRequestAttributes, anonymId } = action.payload;

    let checkoutDataResponse: ICheckoutData | null;

    if (!anonymId) {
      checkoutDataResponse = yield call(sprykerClient(locale).checkoutData.postAuthorized, {
        checkoutDataRequestAttributes,
      });
    } else {
      checkoutDataResponse = yield call(sprykerClient(locale).checkoutData.postUnauthorized, {
        checkoutDataRequestAttributes,
        anonymId,
      });
    }

    if (checkoutDataResponse) {
      yield put(CheckoutDataActions.checkoutDataReceived(checkoutDataResponse));
    } else {
      yield put(CheckoutDataActions.checkoutDataRequestFailed('No checkout data received.'));
    }
  } catch (error: any) {
    yield put(CheckoutDataActions.checkoutDataRequestFailed(error));
  }
}
