import { useRouter } from 'next/router';
import { cloneElement, ReactElement } from 'react';
import { IProductPropFullData } from '@dxp/spryker-client';
import { PlatformWebappFeatureFlags } from '@dxp/core';

export interface PriceInformationProps {
  children: ReactElement;
  concreteProduct?: IProductPropFullData;
}

export const PriceInformation = (props: PriceInformationProps & { className?: string }) => {
  const { locale } = useRouter();

  return locale &&
    props.concreteProduct?.prices?.priceDefaultNet &&
    props.concreteProduct.prices.priceDefaultCurrency?.code &&
    !PlatformWebappFeatureFlags.commerce.hidePrice[locale]
    ? cloneElement(props.children, {
        price: props.concreteProduct?.prices?.priceDefaultNet,
        priceOld: props.concreteProduct?.prices?.priceOriginalNet,
        currency: props.concreteProduct.prices.priceDefaultCurrency?.code,
        locale: locale,
      })
    : null;
};

export default PriceInformation;
