import { Loader } from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { ChangeEvent, useEffect, useState } from 'react';
import { Colors } from '../../../types';
import { IconCheck } from '../../decoration/icons';
import styles from './hubspot-form.module.scss';

export interface HubspotFormProps {
  portalId?: string;
  formId: string;
  region: string;
  color?: Colors.brilliantBlue | Colors.shinyYellow | Colors.feelgoodMint | Colors.white | Colors.smartBlue;
  mode?: 'dark' | 'light';
  buttonColor?: Colors.brilliantBlue | Colors.shinyYellow | Colors.feelgoodMint | Colors.white | Colors.smartBlue;
  buttonPosition?: 'left' | 'center' | 'right';
  disableButtonCustomStyles?: boolean;
  locale?: string;
  redirectUrl?: string;
  inlineMessage?: string;
  hideSubmitMessage?: boolean;
  inputValues?: Array<{ name: string; value: string | number | boolean }>;
  hideLoader?: boolean;
  onBeforeFormInit?: (event: ChangeEvent<never>) => void;
  onFormReady?: (event: ChangeEvent<never>) => void;
  onFormSubmit?: (event: ChangeEvent<never>) => void;
  onFormSubmitted?: (event: ChangeEvent<never>) => void;
}

declare global {
  interface Window {
    hbspt: any;
  }
}

export const HubspotForm = (props: HubspotFormProps & { className?: string }) => {
  const { t } = useTranslation('common');
  const [formReady, setFormReady] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSubmitMessage, setShowSubmitMessage] = useState<boolean>(false);

  const handleLoad = () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        target: `#hubspotForm-${props.formId}`,
        // cssRequired: '',
        ...(!props.disableButtonCustomStyles && {
          submitButtonClass: `submit${props.buttonColor ? ' submit--' + props.buttonColor : ''}${
            props.buttonPosition ? ' submit--' + props.buttonPosition : ''
          }`,
        }),
        ...props,
        onBeforeFormInit: ($form: never) => {
          props.onBeforeFormInit?.($form);
          setShowLoader(true);
        },
        onFormReady: ($form: never) => {
          props.onFormReady?.($form);
          setFormReady(true);
        },
        onFormSubmit: ($form: never) => {
          props.onFormSubmit?.($form);
        },
        onFormSubmitted: ($form: never) => {
          props.onFormSubmitted?.($form);
          setShowSubmitMessage(true);
        },
      });
    }
  };

  const injectValueIntoHubSpot = (inputName: string, value: string | number | boolean) => {
    const input = document.querySelector(`input[name=${inputName}]`) as HTMLInputElement;

    if (input) {
      if (typeof value === 'string') {
        input.value = value;
      } else {
        input.value = String(value);
      }

      input.focus();
      input.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  const injectValues = () => {
    if (formReady && props.inputValues) {
      props.inputValues.forEach((input) => {
        if (input.value) {
          injectValueIntoHubSpot(input.name, input.value);
        }
      });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', handleLoad);

    return () => {
      script.removeEventListener('load', handleLoad);
      script.remove();
    };
  }, []);

  useEffect(() => {
    injectValues();
  }, [props.inputValues, formReady]);

  return (
    <div
      className={classNames(
        styles['container'],
        styles[`container--${props.color}`],
        props.mode ? styles[`container--${props.mode}`] : '',
        props.className
      )}
      data-testid={'hubspot-form'}
    >
      {!showSubmitMessage && (
        <div
          className={styles[!formReady ? 'hubspot-form-loading' : '']}
          id={`hubspotForm-${props.formId}`}
          suppressHydrationWarning={true}
        />
      )}

      {showSubmitMessage && !props.hideSubmitMessage && (
        <div className={styles['submit-message__container']}>
          <div className={styles['submit-message__circle']}>
            <IconCheck className={styles['submit-message__icon']} />
          </div>
          <h4 className={classNames(styles['submit-message__headline'], 'heading-4')}>
            {t('hubspot-form.submitted.headline')}
          </h4>
          <p className={classNames(styles['submit-message__copy'], 'text')}>{t('hubspot-form.submitted.copy')}</p>
        </div>
      )}

      {!formReady && showLoader && !props.hideLoader && <Loader color={Colors.intenseBlue} />}
    </div>
  );
};

export default dynamic(() => Promise.resolve(HubspotForm), {
  ssr: false,
});
