import styles from './downloads.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RowItem, VeneerAnimation } from '@dxp/veneer';
import { IAdditionalDocument, ICaseReport, ICatalogBrochure, IInstruction } from '../../interfaces';
import { motion } from 'framer-motion';
import { PlatformWebappFeatureFlags } from '@dxp/core';

export interface DownloadsProps {
  downloads: Array<ICatalogBrochure | IInstruction | ICaseReport | IAdditionalDocument>;
}

export const Downloads = (props: DownloadsProps) => {
  const { t } = useTranslation('common');

  const downloadItems = props.downloads.map((download) => {
    const filesize = download.fileSize ? ` (${download.fileSize})` : '';
    const meta = `${download.fileType}${filesize}`;

    return (
      download.title &&
      download.url && (
        <RowItem
          id={download.id}
          key={download.id}
          title={download.title}
          meta={meta}
          additional_information={PlatformWebappFeatureFlags.wishlistButton ? t('product.wishlist.add') : ''}
          variant={'download'}
          className={styles['download-item']}
          hideWishlist={!PlatformWebappFeatureFlags.wishlistButton}
          url={download.url}
        />
      )
    );
  });

  return !!props.downloads?.length && props.downloads.some((item) => item.title && item.url) ? (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
      data-testid={`download-container`}
    >
      <h2 className={classNames('heading-3', styles['headline'])}>{t('product.detail.downloads')}</h2>
      {downloadItems}
    </motion.div>
  ) : null;
};

export default Downloads;
