import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';
import { IDownload, iDownloadMappingConfig } from './download.interface';

/**
 * The interface for a case report from catalog.
 * Extending the general download interface
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICaseReport extends IDownload {}

/**
 * Mapping configuration for ICaseReport interface
 */
export const iCaseReportMappingConfig: MappingConfig = {
  ...iDownloadMappingConfig,
  url: {
    path: ['values', 'case_reports_asset_link'],
    valueData: ValueDataType.string,
    localized: true,
  },
  fileType: {
    path: ['values', 'case_reports_asset_link'],
    valueData: ValueDataType.string,
    localized: true,
    modifier: () => '',
    // Disabled because the file extensions are not always included in the URL.
    // (url: string) => url.split('.')[url.split('.').length - 1].toUpperCase(),
  },
  documentType: 'caseReports',
};
