import { PriceInformation } from '../../../price-information/price-information';
import styles from './product-teaser-overlay.module.scss';
import {
  Colors,
  DeliveryState,
  DeliveryStatus,
  IconClose,
  Price,
  Quantifier,
  RoundButton,
  StandardButton,
  VeneerAnimation,
} from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { AuthContext, PlatformWebappFeatureFlags } from '@dxp/core';
import { IProductPropFullData } from '@dxp/spryker-client';
import { useDispatch, useSelector } from 'react-redux';
import { CartActions, CartSelectors, CartState } from '../../../../';
import { useContext, useState } from 'react';
import { useRouter } from 'next/router';

export interface ProductTeaserOverlayProps {
  title: string;
  sku?: string;
  onClick?: () => void;
  locale: string;
  deliveryState?: DeliveryState;
  concreteProduct?: IProductPropFullData;
}

export const ProductTeaserOverlay = (props: ProductTeaserOverlayProps & { classname?: string }) => {
  const { t } = useTranslation('common');
  const authContext = useContext(AuthContext);
  const { locale } = useRouter();
  const dispatch = useDispatch();
  const cartLoading: boolean = useSelector((state: { cart: CartState }) => CartSelectors.selectCartLoading(state.cart));
  const [quantity, setQuantity] = useState<number>(1);

  const handleAddToCartClick = (): void => {
    //add product to current cart
    if (props.sku) {
      if (locale && PlatformWebappFeatureFlags.commerce.enable[locale]) {
        dispatch(
          authContext?.isAuthenticated
            ? CartActions.addItemToCart({
                locale: locale,
                payload: { sku: props.sku, quantity: quantity },
              })
            : CartActions.guestAddItemToCart({
                locale: locale,
                payload: { sku: props.sku, quantity: quantity, anonymId: authContext?.anonymId ?? '' },
              })
        );
      } else {
        window.open(`${t('product.commerce.cart.add.link')}search?q=${props.sku}`, '_ blank');
      }
    }
  };

  const handleInputChange = (n: number) => {
    setQuantity(n);
  };

  return (
    <motion.div
      initial={{ y: '100%' }}
      animate={{ y: 0 }}
      exit={{ y: '100%' }}
      transition={{ duration: 0.6, ease: VeneerAnimation.easings.inOutCubic }}
      className={styles['container']}
    >
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        transition={{ duration: 0.5, y: { duration: 0.8 }, delay: 0.25, ease: VeneerAnimation.easings.inOutCubic }}
      >
        <div className={classNames(styles['title'])} data-testid="teaser-title">
          {props.title}
        </div>
        <div className={styles['info-container']}>
          {props.sku && props.deliveryState && <DeliveryStatus deliveryState={props.deliveryState} />}
          {props.sku && <div className={'subtext-regular'}>SKU: {props.sku}</div>}
        </div>
      </motion.div>
      {props.sku && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5,
              delay: 0.47,
              ease: VeneerAnimation.easings.inOutCubic,
            }}
          >
            <PriceInformation concreteProduct={props.concreteProduct} className={styles['price-container']}>
              <Price vat={false} />
            </PriceInformation>
          </motion.div>
          <div className={styles['sale-container']}>
            {locale && PlatformWebappFeatureFlags.commerce.enable[locale] && (
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 30 }}
                transition={{
                  duration: 0.5,
                  y: { duration: 0.8 },
                  delay: 0.32,
                  ease: VeneerAnimation.easings.inOutCubic,
                }}
              >
                <Quantifier variant={'faded-white'} minimum={1} onChange={handleInputChange} inactive={cartLoading} />
              </motion.div>
            )}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
              transition={{
                duration: 0.5,
                y: { duration: 0.8 },
                delay: 0.47,
                ease: VeneerAnimation.easings.inOutCubic,
              }}
            >
              <StandardButton
                label={
                  locale && PlatformWebappFeatureFlags.commerce.enable[locale]
                    ? t('product.commerce.cart.label')
                    : t('product.commerce.cart.add')
                }
                variant={Colors.shinyYellow}
                disabled={!!(locale && PlatformWebappFeatureFlags.commerce.enable[locale]) && cartLoading}
                onClick={handleAddToCartClick}
              />
            </motion.div>
          </div>
        </>
      )}
      <motion.div
        whileHover={{ rotate: 360, transition: { duration: 0.4, ease: VeneerAnimation.easings.inOutCubic } }}
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        transition={{ duration: 0.5, y: { duration: 0.8 }, delay: 0.2, ease: VeneerAnimation.easings.inOutCubic }}
        className={styles['close-button']}
      >
        <RoundButton
          onClick={props.onClick}
          variant={'outline'}
          icon={<IconClose />}
          size={'medium'}
          scaleOnHover={false}
        />
      </motion.div>
    </motion.div>
  );
};

export default ProductTeaserOverlay;
