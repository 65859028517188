import { MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react';

export const useIntersection = (
  ref: RefObject<HTMLElement>,
  callback: (
    entry: IntersectionObserverEntry,
    previousRatio: MutableRefObject<number>,
    previousY: MutableRefObject<number>
  ) => void
) => {
  const previousRatio: MutableRefObject<number> = useRef(0);
  const previousY: MutableRefObject<number> = useRef(0);

  const thresholdArray = (steps: number) =>
    Array(steps + 1)
      .fill(0)
      .map((_, index) => index / steps || 0);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;

      callback(entry, previousRatio, previousY);

      previousY.current = currentY;
      previousRatio.current = currentRatio;
    },
    [callback, previousRatio, previousY]
  );

  useEffect(() => {
    const current = ref.current;
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: thresholdArray(10),
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (current) observer.observe(current);

    return () => {
      if (current) observer.observe(current);
    };
  }, [ref, handleIntersection]);
};
