import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum PromptType {
  FORGOT_PASSWORD,
  DELETE_ADDRESS,
  SET_NEW_PASSWORD,
}

export interface PromptState {
  type: PromptType | null;
  additionalData: string;
}

const initialState: PromptState = {
  type: null,
  additionalData: '',
};

export const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<OpenPromptInterface>) => {
      state.type = action.payload.type;
      state.additionalData = action.payload.additionalData ? action.payload.additionalData : '';
    },
    close: (state) => {
      state.type = initialState.type;
      state.additionalData = initialState.additionalData;
    },
  },
});

export const PromptActions = promptSlice.actions;

export default promptSlice.reducer;

export interface PromptStateInterface {
  prompt: PromptState;
  data?: string;
}

export interface OpenPromptInterface {
  type: PromptType;
  additionalData?: string;
}
