import { call, put, takeEvery } from 'redux-saga/effects';
import { CatalogActions } from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { Category, ListResponse, CategoryQueryParameters } from '@dxp/akeneo-client';
import { akeneoClient } from '../../index';

export function* watchFetchCategories() {
  yield takeEvery(CatalogActions.categoriesRequested.type, fetchCategoriesAsync);
}

export function* fetchCategoriesAsync(action: PayloadAction<{ codes?: Array<string> }>) {
  try {
    if (action.payload?.codes?.length) {
      const categoriesQuery: CategoryQueryParameters = {
        search: JSON.stringify({ code: [{ operator: 'IN', value: action.payload.codes }] }),
      };

      const categoriesResponse: ListResponse & { items: Array<Category> } = yield call(() =>
        akeneoClient.category.get({ query: categoriesQuery })
      );
      yield put(CatalogActions.categoriesReceived(categoriesResponse.items));
    } else {
      const categoriesResponse: ListResponse & { items: Array<Category> } = yield call(() =>
        akeneoClient.category.getAll({})
      );
      yield put(CatalogActions.categoriesReceived(categoriesResponse.items));
    }
  } catch (error) {
    yield put(CatalogActions.categoriesRequestFailed());
  }
}
