import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as TSwiper } from 'swiper/types';
import styles from './media-gallery.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { useMemo, useState } from 'react';
import { Navigation, Thumbs } from 'swiper';
import classNames from 'classnames';
import { Colors, IconArrow } from '@dxp/veneer';
import { SwiperBreakpoints } from '@dxp/core';
import {
  MediaGalleryItem,
  MediaGalleryItemProps,
} from '../../components/media-gallery/media-gallery-item/media-gallery-item';
import MediaGalleryThumb from '../../components/media-gallery/media-gallery-thumb/media-gallery-thumb';

export interface MediaGalleryProps {
  items: MediaGalleryItemProps[];
}

export function MediaGallery(props: MediaGalleryProps) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<TSwiper | null>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<TSwiper | null>(null);

  const key = useMemo<string>(() => uuidv4(), []);

  const thumbsBreakpoints: SwiperBreakpoints = {
    0: {
      slidesPerView: 4,
      spaceBetween: 13,
    },
    760: {
      slidesPerView: 5,
      spaceBetween: 13,
    },
    1020: {
      slidesPerView: 6,
      spaceBetween: 13,
    },
    1360: {
      slidesPerView: 8,
      spaceBetween: 20,
    },
    1600: {
      slidesPerView: 8,
      spaceBetween: 25,
    },
  };

  const renderItems = props?.items.map((item: MediaGalleryItemProps, index: number) => (
    <SwiperSlide key={`${key}_${index}`} className={styles['slide']}>
      <MediaGalleryItem caption={item.caption} imageUrl={item.imageUrl} videoUrl={item.videoUrl} />
    </SwiperSlide>
  ));

  const renderThumbs = props?.items.map((item: MediaGalleryItemProps, index: number) => (
    <SwiperSlide key={`${key}_${index}_thumb`} className={classNames(styles['thumb'])}>
      <MediaGalleryThumb index={index} activeIndex={activeIndex} imageUrl={item.imageUrl} caption={item.caption} />
    </SwiperSlide>
  ));

  return (
    <div className={styles['container']}>
      <div className={styles['inner']}>
        {props?.items.length && (
          <>
            <Swiper
              onSwiper={setSwiper}
              thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
              modules={[Navigation, Thumbs]}
              navigation={true}
              className={styles['gallery']}
              onSlideChange={(swiper) => setActiveIndex(swiper?.realIndex)}
            >
              {renderItems}
            </Swiper>

            <div className={styles['thumbs']}>
              <button
                className={classNames(styles['thumbs__button'], styles['thumbs__button--prev'])}
                onClick={() => swiper?.slidePrev()}
              >
                <IconArrow color={Colors.smartBlue} rotation={90} />
              </button>

              <Swiper
                onSwiper={setThumbsSwiper}
                watchSlidesProgress={true}
                modules={[Navigation, Thumbs]}
                className={styles['thumbs__slider']}
                breakpoints={thumbsBreakpoints}
              >
                {renderThumbs}
              </Swiper>
              <button
                className={classNames(styles['thumbs__button'], styles['thumbs__button--next'])}
                onClick={() => swiper?.slideNext()}
              >
                <IconArrow color={Colors.smartBlue} rotation={270} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MediaGallery;
