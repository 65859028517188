import { NavigationState } from './navigations.slice';
import { createSelector } from '@reduxjs/toolkit';

const selectNavigationsState = (state: NavigationState) => state;

const getNavigation = createSelector(
  [selectNavigationsState, (state: NavigationState, identifier: keyof NavigationState) => identifier],
  (state: NavigationState, identifier: keyof NavigationState) =>
    state && state[identifier]?.items.map((el) => el.fields)
);

const getNavigationTitle = createSelector(
  [selectNavigationsState, (state: NavigationState, identifier: keyof NavigationState) => identifier],
  (state: NavigationState, identifier: keyof NavigationState) => (state && state[identifier]?.title) || ''
);

export const NavigationsSelectors = {
  getNavigation,
  getNavigationTitle,
};
