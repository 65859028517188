import { combineReducers, configureStore, EnhancedStore, Middleware, Reducer, Store } from '@reduxjs/toolkit';
import { languageSwitchSlice, modalSlice, searchSlice, toasterSlice, hubSpotSlice } from '@dxp/core';
import { catalogSlice } from '@dxp/catalog';
import createSagaMiddleware, { Task } from 'redux-saga';
import { createWrapper } from 'next-redux-wrapper';
import rootSaga from './sagas';
import { contentfulDataSlice, navigationsSlice } from '@dxp/content';
import { addressSlice, authSlice, customerSlice, promptSlice } from '@dxp/account';
import {
  checkoutDataSlice,
  checkoutPaymentsSlice,
  checkoutSlice,
  commonCommerceSlice,
  concreteProductSlice,
  orderPaymentSlice,
  ordersSlice,
  paymentSlice,
} from '@dxp/commerce';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer, persistStore } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';
import { createRouterMiddleware, routerReducer } from 'connected-next-router';
import { headerSlice } from './header/slice';
import { accountMobileMenuSlice } from './account-mobile-menu/slice';
import { mainNavigationSlice } from './main-navigation';
import { frontifySlice } from '@dxp/frontify-client';
import { cartSlice } from '@dxp/commerce';

export interface SagaStore extends Store {
  sagaTask?: Task;
}

export interface PersistorStore extends EnhancedStore {
  persistor: Persistor;
}

const makeConfiguredStore: (reducer: Reducer, middleware: Array<Middleware>) => EnhancedStore = (
  reducer: Reducer,
  middleware: Array<Middleware>
) =>
  configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware,
  });

const reducers = {
  accountMobileMenu: accountMobileMenuSlice.reducer,
  address: addressSlice.reducer,
  auth: authSlice.reducer,
  catalog: catalogSlice.reducer,
  cart: cartSlice.reducer,
  checkout: checkoutSlice.reducer,
  checkoutData: checkoutDataSlice.reducer,
  checkoutPayments: checkoutPaymentsSlice.reducer,
  commonCommerce: commonCommerceSlice.reducer,
  concreteProduct: concreteProductSlice.reducer,
  contentfulData: contentfulDataSlice.reducer,
  customer: customerSlice.reducer,
  header: headerSlice.reducer,
  hubSpot: hubSpotSlice.reducer,
  languageSwitch: languageSwitchSlice.reducer,
  mainNavigation: mainNavigationSlice.reducer,
  modal: modalSlice.reducer,
  navigations: navigationsSlice.reducer,
  prompt: promptSlice.reducer,
  router: routerReducer,
  search: searchSlice.reducer,
  toaster: toasterSlice.reducer,
  frontify: frontifySlice.reducer,
  orders: ordersSlice.reducer,
  orderPayments: orderPaymentSlice.reducer,
  payments: paymentSlice.reducer,
};

const rootReducer = combineReducers(reducers);

const createStore: () => Store = () => {
  const isServer = typeof window === 'undefined';
  const sagaMiddleware = createSagaMiddleware();
  const routerMiddleware = createRouterMiddleware();

  let store: EnhancedStore;

  if (isServer) {
    store = makeConfiguredStore(rootReducer, [sagaMiddleware, routerMiddleware]);
  } else {
    const persistedReducer = persistReducer(
      {
        key: 'dxp',
        storage: AsyncStorage,
        whitelist: [],
      },
      combineReducers({
        ...reducers,
        auth: persistReducer({ key: 'auth', storage: AsyncStorage, blacklist: ['error'] }, authSlice.reducer),
        checkoutData: persistReducer(
          { key: 'checkout-data', storage: AsyncStorage, blacklist: ['error'] },
          checkoutDataSlice.reducer
        ),
      })
    );
    store = makeConfiguredStore(persistedReducer, [sagaMiddleware, routerMiddleware]);

    // Nasty hack https://github.com/kirill-konshin/next-redux-wrapper#usage-with-redux-persist
    (store as PersistorStore).persistor = persistStore(store);
  }

  // See sagas.ts file, to register new sagas
  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export type AppStore = ReturnType<typeof createStore> & SagaStore;
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];

export const wrapper = createWrapper(createStore);
