import {
  AccountTeaser,
  AkeneoSlider,
  BenefitList,
  Bundles,
  ContactElements,
  ContactForms,
  DevPlaceholder,
  DownloadShortlist,
  FactsFigures,
  FullWidthTeaser,
  FullwidthMedia,
  GeneralBenefits,
  Headline,
  Highlight,
  ImageTextColumns,
  LinkBar,
  Locations,
  MainStage,
  MediaGallery,
  NewsTeaser,
  NewsletterRegistrationBox,
  ProductListing,
  SimpleTestimonial,
  SingleFullsizeMediaTeaser,
  SocialMediaTeaser,
  SpecialInformationTeaserBoxes,
  Teaserboxes,
  Testimonials,
  TextWithAccordion,
  Textblock,
  TwoBigProductTeaserBoxes,
  TwoHorizontalTeaserboxes,
  VacancyListing,
  VacancySearch,
  VideoListing,
  WordCloud,
  Workflow,
} from '../content-elements';
import { CEHubspotForm } from '../content-elements/hubspot-form/hubspot-form';

export interface ContentTypeConfig {
  additionalAkeneoData?: boolean;
  additionalContentfulData?: boolean;
  additionalFrontifyData?: boolean;
  component: any; // Type should be something like () => JSX.Element, but linting somehow doesn't want to accept it.
}

export const ContentTypeMap: Map<string, ContentTypeConfig> = new Map([
  ['ceAccountTeaser', { component: AccountTeaser }],
  ['ceAkeneoSlider', { component: AkeneoSlider, additionalAkeneoData: true }],
  ['ceBenefitList', { component: BenefitList }],
  ['ceBundles', { component: Bundles }],
  ['ceContactElements', { component: ContactElements }],
  ['ceContentForms', { component: ContactForms }],
  ['ceNewsTeaser', { component: NewsTeaser, additionalContentfulData: true }],
  ['ceFacts', { component: FactsFigures }],
  ['ceFullSizeMediaTeaser', { component: SingleFullsizeMediaTeaser }],
  ['ceFullwidthMedia', { component: FullwidthMedia }],
  ['ceHighlight', { component: Highlight }],
  ['ceImageTextColumns', { component: ImageTextColumns }],
  ['ceMainStage', { component: MainStage }],
  ['ceMediaGallery', { component: MediaGallery }],
  ['ceNewsletterRegistrationBox', { component: NewsletterRegistrationBox }],
  ['ceNewsTeaser', { component: NewsTeaser, additionalContentfulData: true }],
  ['ceSimpleTestimonial', { component: SimpleTestimonial }],
  ['ceTextWithOptionalAccordion', { component: TextWithAccordion }],
  ['ceTwoBigProductTeaserBoxes', { component: TwoBigProductTeaserBoxes, additionalAkeneoData: true }],
  ['ceSocialMediaTeaser', { component: SocialMediaTeaser }],
  ['ceTestimonial', { component: Testimonials }],
  ['ceTextblock', { component: Textblock }],
  ['ceVacancyListing', { component: VacancyListing, additionalContentfulData: true }],
  ['fullWidthTeaserBox', { component: FullWidthTeaser }],
  ['generalBenefits', { component: GeneralBenefits }],
  ['headline', { component: Headline }],
  ['productListing', { component: ProductListing, additionalAkeneoData: true }],
  ['specialInformationTeaserBoxes', { component: SpecialInformationTeaserBoxes }],
  ['teaserboxes', { component: Teaserboxes }],
  ['twoHorizontalTeaserboxes', { component: TwoHorizontalTeaserboxes }],
  ['ceLinkBar', { component: LinkBar }],
  ['ceDownloadShortlist', { component: DownloadShortlist, additionalFrontifyData: true }],
  ['devPlaceholder', { component: DevPlaceholder }],
  ['ceLocations', { component: Locations }],
  ['ceVacancySearch', { component: VacancySearch }],
  ['ceWorkflow', { component: Workflow }],
  ['ceWordCloud', { component: WordCloud }],
  ['ceHubspotForm', { component: CEHubspotForm }],
  ['ceVideoList', { component: VideoListing, additionalContentfulData: true }],
]);
