import Head from 'next/head';

export interface CatalogPageHeadProps {
  description?: string;
  keywords?: string;
  title: string;
  og_title?: string;
  og_description?: string;
  og_image?: string;
}

export const CatalogPageHead = (props: CatalogPageHeadProps) => (
  <Head>
    <title>{props.title}</title>
    {!!props.description?.length && <meta name="description" content={props.description} key={'description'} />}
    {!!props.keywords?.length && <meta name="keywords" content={props.keywords} key={'keywords'} />}
    {!!props.og_title?.length && <meta property="og:title" content={props.og_title} key={'og_title'} />}
    {!!props.og_description?.length && (
      <meta property="og:description" content={props.og_description} key={'og_description'} />
    )}
    {!!props.og_image?.length && <meta property="og:image" content={props.og_image} key={'og_image'} />}
  </Head>
);

export default CatalogPageHead;
