import styles from './required-fields-text.module.scss';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

export const RequiredFieldsText = (props: { className?: string }) => {
  const { t } = useTranslation('account');
  return (
    <div className={classNames(styles['container'], props.className)}>
      <span>*</span>
      {t('requiredFields')}
    </div>
  );
};

export default RequiredFieldsText;
