import { Category } from '@dxp/akeneo-client';
import {
  CatalogSelectors,
  CatalogState,
  HidePrice,
  ICompatibleMachine,
  ICompatibleMaterial,
  IIndication,
  IPdp,
  IProductImage,
  pdpLink,
} from '@dxp/catalog';
import { ProductTeaser, ProductVariant } from '@dxp/commerce';
import { AuthContext, Pagination, PlatformWebappPageSize, toUppercaseLocale } from '@dxp/core';
import {
  AccordionElement,
  Colors,
  Dropdown,
  IconFilter,
  KeyValuePair,
  ScreenSizes,
  StandardButton,
  TextButton,
  VeneerAnimation,
  useScreenSizes,
} from '@dxp/veneer';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MultiSelect from './multi-select/multi-select';
import styles from './product-listing.module.scss';
import NO_PICTURE_FALLBACK_URL  from './assets/images/no_image_vector.svg';

export enum FilterAndSorting {
  disabled = 'disabled',
  extended = 'extended',
  simple = 'simple',
}

export interface ProductListingProps {
  headline: string;
  categories: Array<Category>;
  filterAndSorting: FilterAndSorting;
  type: 'product' | 'productModel';
  locale: string;
}

interface SortConfig {
  category: string;
  sortOptions: Array<KeyValuePair>;
  initialSortOption?: KeyValuePair;
}

const sortConfigs: Array<SortConfig> = [
  {
    category: 'Materials',
    sortOptions: [
      { label: `product.sort.option.name.asc`, key: 'name,asc' },
      { label: `product.sort.option.name.desc`, key: 'name,desc' },
    ],
    initialSortOption: { label: `product.sort.option.name.desc`, key: 'name,desc' },
  },
];

enum FilterVariant {
  INDICATIONS = 'indications',
  COMPATIBLE_MACHINE_OR_MATERIAL = 'compatibleMachineOrMaterial',
  COMPATIBLE_MATERIAL_OR_EQUIPMENT = 'compatibleMaterialOrEquipment',
  CATEGORIES = 'categories',
}

interface FilterConfig {
  category: string;
  filters: Array<Filter>;
}

interface Filter {
  i18nLabel: string;
  limitOptions?: number;
  type: 'checkboxes' | 'buttons';
  variant: FilterVariant;
  filterCondition: (pdp: IPdp, selectedValues: Array<string>) => boolean;
}

const filterConfigs: Array<FilterConfig> = [
  {
    category: 'Materials',
    filters: [
      {
        i18nLabel: 'product.filter.indications',
        limitOptions: 5,
        type: 'checkboxes',
        variant: FilterVariant.INDICATIONS,
        filterCondition: (pdp: IPdp, selectedIndicationCodes: Array<string>) =>
          !selectedIndicationCodes.length ||
          (selectedIndicationCodes.length > 0 &&
            !!pdp.indicationCodes?.length &&
            selectedIndicationCodes.every((selectedIndicationCode: string) =>
              pdp.indicationCodes?.includes(selectedIndicationCode)
            )),
      },
      {
        i18nLabel: 'product.filter.machine',
        limitOptions: 5,
        type: 'checkboxes',
        variant: FilterVariant.COMPATIBLE_MACHINE_OR_MATERIAL,
        filterCondition: (pdp: IPdp, selectedMachineMaterialCodes: Array<string>) =>
          !selectedMachineMaterialCodes.length ||
          (selectedMachineMaterialCodes.length > 0 &&
            !!pdp.compatibleMachineCodes &&
            selectedMachineMaterialCodes.every((selectedMachineCode: string) =>
              pdp.compatibleMachineCodes?.includes(selectedMachineCode)
            )),
      },
      {
        i18nLabel: 'product.filter.equipment',
        limitOptions: 5,
        type: 'checkboxes',
        variant: FilterVariant.COMPATIBLE_MATERIAL_OR_EQUIPMENT,
        filterCondition: (pdp: IPdp, selectedMaterialEquipmentCodes: Array<string>) =>
          !selectedMaterialEquipmentCodes.length ||
          (selectedMaterialEquipmentCodes.length > 0 &&
            !!pdp.compatibleMaterialCodes &&
            selectedMaterialEquipmentCodes.every((selectedMaterialCode: string) =>
              pdp.compatibleMaterialCodes?.includes(selectedMaterialCode)
            )),
      },
      {
        i18nLabel: 'product.filter.categories',
        type: 'buttons',
        variant: FilterVariant.CATEGORIES,
        filterCondition: (pdp: IPdp, selectedCategories: Array<string>) =>
          !selectedCategories.length ||
          (selectedCategories.length > 0 &&
            selectedCategories.every((selectedCategory: string) => pdp.categories?.includes(selectedCategory))),
      },
    ],
  },
];

export const ProductListing = (props: ProductListingProps) => {
  const { t } = useTranslation(['common', 'catalog']);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const screenSize = useScreenSizes();
  const targetElement = useRef(null);
  const authContext = useContext(AuthContext);

  // Paging states
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(PlatformWebappPageSize);
  const [currentRecords, setCurrenRecords] = useState<Array<IPdp>>([]);

  // Sort state
  const [sort, setSort] = useState<{ property: string; direction: 'asc' | 'desc' } | undefined>(undefined);

  // Filter states
  const [selectedIndicationCodes, setSelectedIndicationCodes] = useState<Array<string>>([]);
  const [selectedMachineMaterialCodes, setSelectedMachineMaterialCodes] = useState<Array<string>>([]);
  const [selectedMaterialEquipmentCodes, setSelectedMaterialEquipmentCodes] = useState<Array<string>>([]);
  const [selectedCategories, setSelectedCategories] = useState<Array<string>>([]);

  // Paging constants
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // Constants based on store selectors
  const pdps: Array<IPdp> = useSelector((state: { catalog: CatalogState }) =>
    props.type === 'product'
      ? CatalogSelectors.selectPdps(state.catalog, { onlyWithSlugs: true })
      : CatalogSelectors.selectRootPdps(state.catalog, { onlyWithSlugs: true })
  );
  const productImages: Array<IProductImage> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectProductImages(
      state.catalog,
      pdps.map((pdp) => (pdp.productImageCodes?.length ? pdp.productImageCodes : [])).flat()
    )
  );
  const indications: Array<IIndication> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectIndications(state.catalog, pdps.map((pdp) => pdp.indicationCodes || []).flat())
  );
  const compatibleMachineOrMaterial: Array<ICompatibleMachine> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAllCompatibleMachines(state.catalog)
  );
  const compatibleMaterialOrEquipment: Array<ICompatibleMaterial> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAllCompatibleMaterials(state.catalog)
  );
  const categories: Array<Category> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectCategories(state.catalog)
  );

  // Active sort configuration depending on categories of pdp's.
  const activeSortConfig: SortConfig | undefined = sortConfigs.find((sortConfig: SortConfig) =>
    pdps.some((pdp: IPdp) => pdp.categories.some((category: string) => category === sortConfig.category))
  );

  // Active filter configuration depending on categories of pdp's.
  const activeFilterConfig: FilterConfig | undefined = filterConfigs.find((filterConfig: FilterConfig) =>
    pdps.some((pdp: IPdp) => pdp.categories.some((category) => category === filterConfig.category))
  );

  // Filter effect hook
  useEffect(() => {
    if (activeFilterConfig?.filters.length) {
      setCurrenRecords(
        pdps.filter((pdp: IPdp) => {
          const conditions: Array<boolean> =
            activeFilterConfig?.filters.map((filter: Filter) => {
              switch (filter.variant) {
                case FilterVariant.CATEGORIES:
                  return filter.filterCondition(pdp, selectedCategories);
                case FilterVariant.COMPATIBLE_MACHINE_OR_MATERIAL:
                  return filter.filterCondition(pdp, selectedMachineMaterialCodes);
                case FilterVariant.COMPATIBLE_MATERIAL_OR_EQUIPMENT:
                  return filter.filterCondition(pdp, selectedMaterialEquipmentCodes);
                case FilterVariant.INDICATIONS:
                  return filter.filterCondition(pdp, selectedIndicationCodes);
                default:
                  return true;
              }
            }) || [];
          return conditions.every((condition: boolean) => condition);
        })
      );
    } else {
      setCurrenRecords(pdps);
    }
  }, [
    activeFilterConfig,
    selectedIndicationCodes,
    selectedCategories,
    selectedMachineMaterialCodes,
    selectedMaterialEquipmentCodes,
  ]);

  // Set initial sort option
  useEffect(() => {
    if (activeSortConfig?.initialSortOption?.key) {
      onChangeSort(activeSortConfig?.initialSortOption.key);
    }
  }, [activeSortConfig]);

  // Mount effect hook
  useEffect(() => {
    setInit(true);
  }, []);

  // Body scroll fix effect hook
  useEffect(() => {
    if (screenSize === ScreenSizes.SMALL) {
      if (showFilters) {
        if (targetElement.current) disableBodyScroll(targetElement.current);
      } else {
        if (targetElement.current) {
          enableBodyScroll(targetElement.current);
        }

        // Only scroll top after component is mounted
        if (init) {
          scrollTop();
        }
      }
    } else {
      clearAllBodyScrollLocks();
    }
  }, [screenSize, showFilters]);

  // Unmount effect hook
  useEffect(
    () => () => {
      clearAllBodyScrollLocks();
    },
    []
  );

  const onChangeSort = (value: string) => {
    if (value === '') {
      setSort(undefined);
    } else if (value.includes(',') && value.split(',').length) {
      const values = value.split(',');
      const property = values[0];
      const direction = (values[1] && ['asc', 'desc'].some((dir) => values[1] === dir) ? values[1] : 'asc') as
        | 'asc'
        | 'desc';
      setSort({ property, direction });
    } else {
      setSort({ property: value, direction: 'asc' });
    }
    setCurrentPage(1);
  };

  const sortPdps: (a: IPdp, b: IPdp) => number = (a: IPdp, b: IPdp) => {
    if (sort) {
      const lt = sort.direction === 'asc' ? -1 : 1;
      const gt = sort.direction === 'asc' ? 1 : -1;
      switch (sort.property) {
        case 'name':
          return a.name < b.name ? lt : a.name > b.name ? gt : 0;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const getUniqueCategories: (pdps: Array<IPdp>) => Array<string> = (pdps: Array<IPdp>) => {
    return (
      pdps
        // map pdp to categories
        .map((pdp: IPdp) => pdp.categories)
        // flatten nested category arrays
        .flat()
        // filter categories that each pdp has
        .filter(
          (category: string) =>
            !pdps.map((pdp: IPdp) => pdp.categories).every((pdpCats: Array<string>) => pdpCats.includes(category))
        )
        // filter duplicates
        .filter((category: string, index: number, self: Array<string>) => self.indexOf(category) === index)
    );
  };

  const nPages = Math.ceil(currentRecords.length / recordsPerPage);

  const scrollTop = () => {
    if (containerRef?.current) containerRef.current.scrollIntoView();
  };

  // Check if activeFilterConfig has filter based on filterVariant
  const checkForActiveFilter: (filterVariant: FilterVariant) => boolean | undefined = (filterVariant: FilterVariant) =>
    activeFilterConfig?.filters.some((filter: Filter) => filter.variant === filterVariant);

  // Get filter from activeFilterConfig based on filterVariant
  const getFilter: (filterVariant: FilterVariant) => Filter | undefined = (filterVariant: FilterVariant) =>
    activeFilterConfig?.filters.find((filter: Filter) => filter.variant === filterVariant);

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
      ref={containerRef}
    >
      {props.headline &&
        (props.filterAndSorting === FilterAndSorting.disabled ||
          props.filterAndSorting === FilterAndSorting.extended) && (
          <h2 className={classNames('heading-2', styles['headline'])} data-testid={'headline'}>
            {props.headline}
          </h2>
        )}
      {(props.filterAndSorting === FilterAndSorting.simple || props.filterAndSorting === FilterAndSorting.extended) && (
        <div className={styles['top-container']}>
          <div className={classNames(styles['count'], 'subtext-regular')} data-testid="count">
            {t(`product.count${pdps.length === 1 ? 'One' : 'Other'}`, { count: currentRecords.length })}
          </div>
          {(!!activeFilterConfig?.filters.length || !!activeSortConfig?.sortOptions.length) && (
            <TextButton
              onClick={() => setShowFilters(!showFilters)}
              className={styles['filter-toggle']}
              label={
                screenSize === ScreenSizes.LARGE
                  ? showFilters
                    ? t('product.sort.hide')
                    : t('product.sort.show')
                  : t('product.filter.title')
              }
              icon={<IconFilter />}
              color={Colors.intenseBlue}
              size={'subtext'}
            />
          )}
        </div>
      )}
      <div className={classNames(styles['inner-container'], { [styles['filters-visible']]: showFilters })}>
        {(props.filterAndSorting === FilterAndSorting.simple ||
          props.filterAndSorting === FilterAndSorting.extended) && (
          <div
            className={classNames(
              styles['filters'],
              { [styles['filters--show']]: showFilters },
              styles[`filters--${props.filterAndSorting}`]
            )}
            ref={targetElement}
            data-testid={'filters'}
          >
            <div className={styles['filters__top']}>
              <div className={'text-short-medium'}>{t('product.filter.title')}</div>
            </div>
            <div className={styles['filters__inner']}>
              {/* Sorting */}
              {!!activeSortConfig?.sortOptions?.length && (
                <Dropdown
                  className={styles['dropdown']}
                  variant={Colors.white}
                  values={activeSortConfig.sortOptions.map((sortOption) => ({
                    ...sortOption,
                    label: t(sortOption.label as string),
                  }))}
                  label={t('product.sort.label')}
                  onChange={(e) => onChangeSort(e.target.value)}
                  placeholder={t('product.sort.choose')}
                />
              )}
              {screenSize === ScreenSizes.LARGE && <hr className={styles['divider']} />}
              {/* Filters */}
              {/* Category filter */}
              {!!getUniqueCategories(pdps).length && checkForActiveFilter(FilterVariant.CATEGORIES) && (
                <AccordionElement
                  className={styles['filter']}
                  open={screenSize === ScreenSizes.LARGE}
                  size={'small'}
                  headline={
                    t(getFilter(FilterVariant.CATEGORIES)?.i18nLabel || '') +
                    (screenSize !== ScreenSizes.LARGE && selectedCategories.length > 0
                      ? ` (${selectedCategories.length})`
                      : '')
                  }
                >
                  <MultiSelect
                    buttonSelect={getFilter(FilterVariant.CATEGORIES)?.type === 'buttons'}
                    limitOptions={getFilter(FilterVariant.CATEGORIES)?.limitOptions}
                    onChange={(selectedCategories: Array<string>) => {
                      setSelectedCategories(selectedCategories);
                      setCurrentPage(1);
                    }}
                    selectedValues={selectedCategories}
                    values={getUniqueCategories(pdps).map((category: string) => ({
                      id: category,
                      label:
                        categories.find(
                          (cat: Category) =>
                            cat.code === category &&
                            Object.entries(cat.labels).some(
                              ([localeKey, value]: [localeKey: string, value: string]) =>
                                localeKey === toUppercaseLocale(props.locale).replace('-', '_') && value && value !== ''
                            )
                        )?.labels[toUppercaseLocale(props.locale).replace('-', '_')] || category,
                      count: currentRecords.filter((pdp: IPdp) =>
                        pdp.categories?.some((cat: string) => category === cat)
                      ).length,
                    }))}
                  />
                </AccordionElement>
              )}
              {/* Indications filter */}
              {!!indications.length && checkForActiveFilter(FilterVariant.INDICATIONS) && (
                <AccordionElement
                  className={styles['filter']}
                  open={screenSize === ScreenSizes.LARGE}
                  size={'small'}
                  headline={
                    t(getFilter(FilterVariant.INDICATIONS)?.i18nLabel || '') +
                    (screenSize !== ScreenSizes.LARGE && selectedIndicationCodes.length > 0
                      ? ` (${selectedIndicationCodes.length})`
                      : '')
                  }
                >
                  <MultiSelect
                    buttonSelect={getFilter(FilterVariant.INDICATIONS)?.type === 'buttons'}
                    limitOptions={getFilter(FilterVariant.INDICATIONS)?.limitOptions}
                    onChange={(selectedIndicationCodes: Array<string>) => {
                      setSelectedIndicationCodes(selectedIndicationCodes);
                      setCurrentPage(1);
                    }}
                    selectedValues={selectedIndicationCodes}
                    values={indications.map((indication: IIndication) => ({
                      id: indication.code,
                      label: indication.text,
                      count: currentRecords.filter((pdp: IPdp) =>
                        pdp.indicationCodes?.some((indicationCode: string) => indicationCode === indication.code)
                      ).length,
                    }))}
                  ></MultiSelect>
                </AccordionElement>
              )}
              {/* compatibleMachinesOrMaterial filter */}
              {!!compatibleMachineOrMaterial.length &&
                checkForActiveFilter(FilterVariant.COMPATIBLE_MACHINE_OR_MATERIAL) && (
                  <AccordionElement
                    open={screenSize === ScreenSizes.LARGE}
                    className={styles['filter']}
                    size={'small'}
                    headline={
                      t(getFilter(FilterVariant.COMPATIBLE_MACHINE_OR_MATERIAL)?.i18nLabel || '') +
                      (screenSize !== ScreenSizes.LARGE && selectedMachineMaterialCodes.length > 0
                        ? ` (${selectedMachineMaterialCodes.length})`
                        : '')
                    }
                  >
                    <MultiSelect
                      buttonSelect={getFilter(FilterVariant.COMPATIBLE_MACHINE_OR_MATERIAL)?.type === 'buttons'}
                      limitOptions={getFilter(FilterVariant.COMPATIBLE_MACHINE_OR_MATERIAL)?.limitOptions}
                      onChange={(selectedMachineCodes: Array<string>) => {
                        setSelectedMachineMaterialCodes(selectedMachineCodes);
                        setCurrentPage(1);
                      }}
                      selectedValues={selectedMachineMaterialCodes}
                      values={compatibleMachineOrMaterial.map((machine: ICompatibleMachine) => ({
                        id: machine.identifier,
                        label: machine.name,
                        count: currentRecords.filter((pdp: IPdp) =>
                          pdp.compatibleMachineCodes?.some((machineCode: string) => machine.identifier === machineCode)
                        ).length,
                      }))}
                    />
                  </AccordionElement>
                )}
              {/* compatibleMaterialOrEquipment filter */}
              {!!compatibleMaterialOrEquipment.length &&
                checkForActiveFilter(FilterVariant.COMPATIBLE_MATERIAL_OR_EQUIPMENT) && (
                  <AccordionElement
                    className={styles['filter']}
                    open={screenSize === ScreenSizes.LARGE}
                    size={'small'}
                    headline={
                      t(getFilter(FilterVariant.COMPATIBLE_MATERIAL_OR_EQUIPMENT)?.i18nLabel || '') +
                      (screenSize !== ScreenSizes.LARGE && selectedMaterialEquipmentCodes.length > 0
                        ? ` (${selectedMaterialEquipmentCodes.length})`
                        : '')
                    }
                  >
                    <MultiSelect
                      buttonSelect={getFilter(FilterVariant.COMPATIBLE_MATERIAL_OR_EQUIPMENT)?.type === 'buttons'}
                      limitOptions={getFilter(FilterVariant.COMPATIBLE_MATERIAL_OR_EQUIPMENT)?.limitOptions}
                      onChange={(selectedMaterialCodes: Array<string>) => {
                        setSelectedMaterialEquipmentCodes(selectedMaterialCodes);
                        setCurrentPage(1);
                      }}
                      selectedValues={selectedMaterialEquipmentCodes}
                      values={compatibleMaterialOrEquipment.map((material: ICompatibleMaterial) => ({
                        id: material.code,
                        label: material.label,
                        count: currentRecords.filter((pdp: IPdp) =>
                          pdp.compatibleMaterialCodes?.some((materialCode: string) => material.code === materialCode)
                        ).length,
                      }))}
                    />
                  </AccordionElement>
                )}
            </div>
            <div className={styles['filters__bottom']}>
              <StandardButton
                onClick={() => setShowFilters(false)}
                variant={Colors.brilliantBlue}
                label={t(`product.filter.count${pdps.length === 1 ? 'One' : 'Other'}`, {
                  count: currentRecords.length,
                })}
              />
            </div>
          </div>
        )}

        <div className={styles['results']}>
          <div className={styles['grid']}>
            {!!currentRecords?.length &&
              (sort ? currentRecords.sort(sortPdps) : currentRecords)
                .slice(indexOfFirstRecord, indexOfLastRecord)
                .map((pdp: IPdp) => (
                  <ProductTeaser
                    key={pdp.sku ?? pdp.code}
                    image={
                      productImages.find(
                        (image: IProductImage) => image.code === pdp.productImageCodes.find((code: string) => !!code)
                      )?.imageUrl || NO_PICTURE_FALLBACK_URL
                    }
                    title={pdp.name}
                    variant={
                      props.type === 'productModel'
                        ? ProductVariant.PRODUCT_MODEL
                        : pdp.callToAction?.includes('buy_now')
                        ? ProductVariant.PRODUCT_CART
                        : ProductVariant.PRODUCT
                    }
                    sku={pdp.sku || ''}
                    className={styles['grid__item']}
                    isPriceHidden={
                      authContext?.isAuthenticated
                        ? pdp.hidePrice.includes(HidePrice.FOR_LOGGED)
                        : pdp.hidePrice.includes(HidePrice.FOR_GUEST)
                    }
                    href={pdpLink(pdp.slug, pdp.categories)}
                  />
                ))}
          </div>
          {nPages > 1 && (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={(n) => setCurrentPage(n)}
              scrollTop={scrollTop}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ProductListing;
