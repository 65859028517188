import {
  ConcreteProductActions,
  ConcreteProductSelectors,
  ConcreteProductState,
  PriceInformation,
} from '@dxp/commerce';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import styles from './product-comparison.module.scss';
import { Colors, IconArrow, Price, StandardButton } from '@dxp/veneer';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface ProductComparisonProps {
  locale: string;
  tableRows: Array<ITRButton | ITRHead | ITRSimpleValue>;
  children?: React.ReactElement;
}

interface ITrow {
  type: 'head' | 'simpleValue' | 'button';
  label: string;
}

export interface ITRButton extends ITrow {
  type: 'button';
  data: Array<ITDButton>;
}

interface ITDButton {
  link: UrlObject | string;
  label: string;
}

export interface ITRHead extends ITrow {
  type: 'head';
  data: Array<ITDHead>;
}

export interface ITDHead {
  alt?: string;
  button?: ITDButton;
  headline: string;
  imageUrl: string;
  price?: {
    sku: string;
    locale: string;
    vat: boolean;
  };
  subline: string;
}

export interface ITRSimpleValue extends ITrow {
  type: 'simpleValue';
  data: Array<ITDSimpleValue>;
}

interface ITDSimpleValue {
  value: string;
}

export const ProductComparison = (props: ProductComparisonProps) => {
  const { t } = useTranslation('common');
  const { back } = useRouter();
  const dispatch = useDispatch();

  const [skus, setSkus] = useState<Array<string> | null>(null);

  const concreteProducts = useSelector((state: { concreteProduct: ConcreteProductState }) =>
    ConcreteProductSelectors.selectConcreteProducts(state.concreteProduct, skus || [])
  );

  useEffect(() => {
    const skus: Array<{ sku: string; locale: string }> = props.tableRows
      .filter((tableRow) => tableRow.type === 'head')
      .map((tableRow) => (tableRow as ITRHead).data)
      .flat()
      .filter((tableRowItem) => !!tableRowItem.price?.sku)
      .map((tableRowItem) => {
        return { sku: tableRowItem.price?.sku || '', locale: tableRowItem.price?.locale || '' };
      });

    setSkus(skus.map((sku) => sku.sku));

    skus.forEach((sku) => {
      dispatch(ConcreteProductActions.concreteProductRequested({ sku: sku.sku, locale: sku.locale }));
    });
  }, [props.tableRows]);

  const renderTableData = (row: ITRButton | ITRHead | ITRSimpleValue, item: ITDButton | ITDHead | ITDSimpleValue) => {
    switch (row.type) {
      case 'button':
        return (
          <Link href={(item as ITDButton).link}>
            <StandardButton label={(item as ITDButton).label} variant={Colors.brilliantBlue} />
          </Link>
        );
      case 'head':
        item = item as ITDHead;
        return (
          <>
            <img className={styles['image']} src={item.imageUrl} alt={item.alt} />
            <p>
              <strong>{item.headline}</strong>
              <br />
              {item.subline}
            </p>
            {!!item.price && (
              <PriceInformation
                concreteProduct={
                  concreteProducts.find((product) => product.concreteProduct.sku === (item as ITDHead)?.price?.sku)
                    ?.concreteProduct
                }
                className={styles['price']}
              >
                <Price vat={item.price.vat} layout={'minimal'} priceSize={'small'} />
              </PriceInformation>
            )}
            {!!item.button && (
              <Link href={item.button.link}>
                <StandardButton label={item.button.label} variant={Colors.brilliantBlue} />
              </Link>
            )}
          </>
        );
      case 'simpleValue':
        return (item as ITDSimpleValue).value;
      default:
        return null;
    }
  };

  return (
    <div className={styles['container']}>
      <button onClick={back} className={styles['back']}>
        <IconArrow color={Colors.intenseBlue} className={styles['back__icon']} />
        <span className={styles['back__label']}>{t('back')}</span>
      </button>
      {props.children}

      <div className={styles['table-wrapper']}>
        <div className={styles['scroll']}>
          <table className={styles['table']}>
            <tbody>
              {!!props.tableRows.length &&
                props.tableRows.map((row, index: number) => {
                  return (
                    <tr key={'table-row-' + index}>
                      <th key={'table-data-first'}>{row.label}</th>
                      {row.data.map((item, index: number) => (
                        <td key={'table-data-' + index}>{renderTableData(row, item)}</td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductComparison;
