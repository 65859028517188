import ToasterMessageInterface from '../toasterMessageInterface';
import { useDispatch } from 'react-redux';
import { ToasterActions } from '../../../redux';
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './toaster-message.module.scss';
import classNames from 'classnames';
import { Colors, IconCheck, IconClose, RoundButton } from '@dxp/veneer';

export const ToasterMessage = (msg: ToasterMessageInterface) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!msg.manualDismiss) {
        dispatch(ToasterActions.remove(msg.id || ''));
      }
    }, msg?.autoDismissTime ?? 5000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, msg]);

  return (
    <motion.div
      layout
      className={classNames(styles['toast'], styles['toast--' + msg.type])}
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      onClick={() => dispatch(ToasterActions.remove(msg.id || ''))}
    >
      {!(msg.type === 'normal') && (
        <RoundButton
          variant={'outline'}
          icon={msg.type === 'warning' ? <IconClose /> : <IconCheck />}
          className={styles['icon']}
          scaleOnHover={false}
        />
      )}

      <div className={styles['text']}>
        <div className={styles['title']}>{msg.title}</div>
        <div className={styles['bodytext']}>{msg.bodytext}</div>
      </div>

      <IconClose color={Colors.intenseBlue} className={styles['close']} />
    </motion.div>
  );
};

export default React.memo(ToasterMessage);
