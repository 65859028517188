import { v4 as uuidv4 } from 'uuid';
import styles from './general-benefits.module.scss';
import { TextTeaser, VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import Link from 'next/link';

export interface Benefit {
  title: string;
  copy?: string;
  linkUrl?: string;
  linkText?: string;
}

export interface GeneralBenefitsProps {
  items: Array<Benefit>;
}

export const GeneralBenefits = (props: GeneralBenefitsProps) => {
  const renderItems = (benefits: Array<Benefit>) =>
    benefits?.map((child: Benefit) =>
      child.linkUrl && child.linkText ? (
        <Link href={child.linkUrl} key={uuidv4()}>
          <TextTeaser headline={child.title} copy={child.copy} linkText={child.linkText}></TextTeaser>
        </Link>
      ) : (
        <TextTeaser headline={child.title} copy={child.copy} key={uuidv4()}></TextTeaser>
      )
    );

  return props.items?.length ? (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
    >
      {renderItems(props.items)}
    </motion.div>
  ) : null;
};

export default GeneralBenefits;
