import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { IConcreteProductDataParsed } from '@dxp/spryker-client';
import { IConcreteProductReceivedPayload, IConcreteProductRequestedPayload } from '../../interfaces';

/**
 * The interface for concrete product data provided in redux store.
 */
export interface ConcreteProductState {
  loading: boolean;
  concreteProducts: {
    [sku: string]: IConcreteProductDataParsed;
  };
}

/**
 * The initial concrete product redux state.
 */
const initialState: ConcreteProductState = {
  loading: false,
  concreteProducts: {},
};

/**
 * The concrete product redux slice.
 */
export const concreteProductSlice = createSlice({
  name: 'concreteProduct',
  initialState,
  reducers: {
    concreteProductRequested: (state, action: PayloadAction<IConcreteProductRequestedPayload>) => {
      state.loading = true;
    },
    concreteProductsReceived: (state, action: PayloadAction<IConcreteProductReceivedPayload>) => {
      state.loading = false;
      state.concreteProducts[action.payload.sku] = action.payload.concreteProductDataParsed;
    },
    concreteProductRequestFailed: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action['payload'].concreteProducts,
      };
    });
  },
});

export const ConcreteProductActions = concreteProductSlice.actions;

export default concreteProductSlice.reducer;
