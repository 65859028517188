import styles from './word-cloud-line.module.scss';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';

export interface WordCloudLineProps {
  words: Array<string>;
  showDivider: boolean;
}

export function WordCloudLine(props: WordCloudLineProps) {
  return (
    <div className={classNames(styles['line'], { [styles['line--with-divider']]: props.showDivider })}>
      {props.words.map((word, i) => (
        <React.Fragment key={uuidv4()}>
          <div className={styles['word']}>{word}</div>
          {(props.words.length === 1 || i !== props.words.length - 1) && props.showDivider && (
            <div className={styles['divider']} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default WordCloudLine;
