import {
  CartActions,
  CartSelectors,
  CartState,
  ConcreteProductActions,
  ConcreteProductSelectors,
  ConcreteProductState,
} from '../../../redux';
import PriceInformation from '../../price-information/price-information';
import styles from './upselling-teaser.module.scss';
import { Colors, IconCart, Price, Quantifier, RoundButton, ScreenSizes, useScreenSizes } from '@dxp/veneer';
import Image from 'next/image';
import classNames from 'classnames';
import { PlatformWebappFeatureFlags, textTruncate } from '@dxp/core';
import NTRLink from 'next-translate-routes/link';
import { useTranslation } from 'react-i18next';
import { IConcreteProductDataParsed } from '@dxp/spryker-client';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Url } from 'next-translate-routes/types';
import { useRouter } from 'next/router';

export interface UpsellingTeaserProps {
  href?: Url;
  title: string;
  image: string;
  subline: string;
  sku: string;
  addToCart?: boolean;
  locale: string;
}

export interface upsellingProductCommerceAreaProps {
  concreteProductDataParsed?: IConcreteProductDataParsed;
  addToCart: boolean;
  locale: string;
  sku: string;
}

const UpsellingProductCommerceArea = ({
  concreteProductDataParsed,
  addToCart,
  locale,
  sku,
}: upsellingProductCommerceAreaProps) => {
  const screenSize = useScreenSizes();
  const { t } = useTranslation('common');

  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState<number>(1);
  const cartLoading: boolean = useSelector((state: { cart: CartState }) => CartSelectors.selectCartLoading(state.cart));

  const handleInputChange = (n: number) => {
    setQuantity(n);
  };

  const handleAddToCartClick = (): void => {
    //add product to current cart
    if (locale && PlatformWebappFeatureFlags.commerce.enable[locale]) {
      dispatch(
        CartActions.addItemToCart({
          locale: locale,
          payload: { sku, quantity },
        })
      );
    } else {
      window.open(`${t('product.commerce.cart.add.link')}search?q=${sku}`, '_ blank');
    }
  };

  return addToCart ? (
    <div className={styles['cart']} data-testid="cart">
      {screenSize === ScreenSizes.SMALL && (
        <PriceInformation concreteProduct={concreteProductDataParsed?.concreteProduct}>
          <Price priceSize={'small'} layout={'minimal'} />
        </PriceInformation>
      )}
      <div>
        {locale && PlatformWebappFeatureFlags.commerce.enable[locale] && (
          <Quantifier variant={Colors.white} minimum={1} onChange={handleInputChange} loading={cartLoading} />
        )}
        <RoundButton
          icon={<IconCart />}
          title={t('product.commerce.cart.add')}
          size={'standard'}
          disabled={!!(locale && PlatformWebappFeatureFlags.commerce.enable[locale]) && cartLoading}
          variant={Colors.brilliantBlue}
          className={styles['cart__button']}
          onClick={() => handleAddToCartClick()}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export const UpsellingTeaser = ({
  title,
  image,
  subline,
  sku,
  addToCart = false,
  locale,
  href,
}: UpsellingTeaserProps) => {
  const screenSize = useScreenSizes();
  const dispatch = useDispatch();

  const concreteProductDataParsed: IConcreteProductDataParsed | undefined = useSelector(
    (state: { concreteProduct: ConcreteProductState }) =>
      ConcreteProductSelectors.selectConcreteProduct(state.concreteProduct, sku)
  );

  useEffect(() => {
    if (sku) {
      dispatch(ConcreteProductActions.concreteProductRequested({ sku, locale }));
    }
  }, [dispatch, locale, sku]);

  const productElement = (
    <>
      <div className={styles['image']}>
        {image && (
          <Image
            src={image}
            alt={title}
            className={styles['image']}
            width={95}
            height={95}
            data-testid="image"
            title={title}
          />
        )}
      </div>
      <div className={styles['inner']}>
        <div className={styles['infos']}>
          <div className={classNames(styles['title'], 'subheading')} data-testid="title">
            {textTruncate(title, 35)}
          </div>
          <div className={classNames(styles['subline'], 'text-short')} data-testid="subline" title={subline}>
            {textTruncate(subline, screenSize !== ScreenSizes.SMALL ? 40 : 75, '…')}
          </div>
          {screenSize !== ScreenSizes.SMALL && (
            <PriceInformation concreteProduct={concreteProductDataParsed?.concreteProduct}>
              <Price priceSize={'small'} layout={'minimal'} />
            </PriceInformation>
          )}
        </div>
      </div>
    </>
  );

  return href ? (
    <div className={styles['container']}>
      <NTRLink href={href}>{productElement}</NTRLink>
      <UpsellingProductCommerceArea
        sku={sku}
        locale={locale}
        concreteProductDataParsed={concreteProductDataParsed}
        addToCart={addToCart}
      />
    </div>
  ) : (
    <div className={styles['container']}>
      {productElement}
      <UpsellingProductCommerceArea
        sku={sku}
        locale={locale}
        concreteProductDataParsed={concreteProductDataParsed}
        addToCart={addToCart}
      />
    </div>
  );
};

export default UpsellingTeaser;
