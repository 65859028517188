import { IAddressItem, ICheckoutDataAddressItem, IOrder, IOrderAttributes, IOrderShipment, Links } from '../index';
import { PaymentMethodRequest } from './index';
import { CardElementData } from '@adyen/adyen-web/dist/types/components/Card/types';
import { PaymentMethod, StoredPaymentMethod } from '@adyen/adyen-web/dist/types/types';

export interface ICheckoutRequest {
  data: {
    type: 'checkout';
    attributes: ICheckoutRequestAttributes;
  };
}

export interface ICheckoutRequestAttributes {
  idCart: string;
  customer: CheckoutCustomer;
  acceptedTerms: boolean;
  billingAddress: { id: string } | ICheckoutDataAddressItem | IAddressItem;
  shippingAddress: { id: string } | ICheckoutDataAddressItem | IAddressItem;
  shipment: {
    idShipmentMethod: number;
  };
  payments: Array<PaymentMethodRequest>;
  shipments?: Array<{
    items: Array<string>;
    shippingAddress: { id: string } | ICheckoutDataAddressItem;
    idShipmentMethod: number;
    requestedDeliveryDate: string;
  }>;
  paymentData?: CardElementData['paymentMethod'];
}

export interface IPaymentMethodsRequest {
  data: {
    type: 'checkout-payments';
    attributes: IPaymentMethodsRequestAttributes;
  };
}

export interface IPaymentMethodsRequestAttributes {
  idCart: string;
}

export interface IPaymentMethodsResponse {
  paymentMethods: PaymentMethod[];
}

export interface CheckoutCustomer {
  email: string;
  salutation?: string;
  firstName: string;
  lastName: string;
}

export interface ICheckoutRedirectFields {
  [key: string]: any;
}

export interface ICheckout {
  id: string | null;
  orderReference: string;
  redirectUrl: string | null;
  isExternalRedirect: string | null;
  orders: Array<IOrder>;
  orderShipments: unknown;
  redirectFields?: Array<ICheckoutRedirectFields>;
  redirectMethod?: 'GET' | 'POST';
}

export interface ICheckoutResponse {
  type: 'checkout';
  id: string | null;
  links: Links;
  attributes: ICheckoutResponseAttributes;
  relationships?: ICheckoutResponseRelationships;
}

export interface ICheckoutResponseAttributes {
  orderReference: string;
  redirectUrl: string | null;
  isExternalRedirect: string | null;
}

export interface ICheckoutResponseRelationships {
  [key: string]: CheckoutRelationships;
}

export interface CheckoutRelationships {
  data: CheckoutRelationship[];
}

export interface CheckoutRelationship {
  type: CheckoutType;
  id: string;
}

export interface IncludedCheckout {
  type: CheckoutType;
  id: string;
  attributes: IOrderAttributes | IOrderShipment;
  links: Links;
}

export enum CheckoutType {
  Orders = 'orders',
  OrderShipments = 'order-shipments',
}
