import { motion } from 'framer-motion';
import { Fact, VeneerAnimation } from '@dxp/veneer';
import styles from './facts.module.scss';
import { ReactElement } from 'react';

export interface FactsProps {
  facts: IFact[];
}

export interface IFact {
  code?: string;
  headline: string;
  subline?: string;
  icon?: ReactElement | string;
  innerText?: string;
}

export const Facts = ({ facts }: FactsProps) => {
  const renderItems = facts.map((fact: IFact, index: number) => (
    <Fact className={styles['fact']} {...fact} key={fact.code ? fact.code : index}></Fact>
  ));

  return facts.length > 0 ? (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['inner']}
    >
      <div className={styles['container']}>
        <div className={styles['row']}>{renderItems}</div>
      </div>
    </motion.div>
  ) : null;
};

export default Facts;
