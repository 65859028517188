import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  AssetFilter,
  AssetItems,
  FrontifyAsset,
  FrontifyFinderAsset,
  FrontifyLibrary,
  FrontifyState,
  LibraryConfig,
  LibraryDefaultPayload,
  frontifyApi,
} from '../../index';
import { FrontifyActions } from '../frontify.slice';

const frontifyStateSelector: (state: { frontify: FrontifyState }) => FrontifyState = (state: {
  frontify: FrontifyState;
}) => state.frontify;

/**
 * Watches actions to change the current frontify.assets state
 */
export function* watchGetFrontifyAssets() {
  yield takeEvery(FrontifyActions.assetByPreviewUrlRequested, getFrontifyAssetsAsync);
}

/**
 * The worker function to push FrontifyAssets to the frontify.assets state
 */
function* getFrontifyAssetsAsync(action: PayloadAction<FrontifyFinderAsset[]>) {
  try {
    const response: FrontifyAsset[] = yield call(frontifyApi().findAssets, action.payload);

    if (response.length > 0) {
      yield put(FrontifyActions.assetByPreviewUrlReceived(response));
    }
  } catch (error) {
    console.log(error);

    const assetsFallback: FrontifyAsset[] = action.payload.map((asset: FrontifyFinderAsset) => {
      return {
        id: asset.id.toString(),
        title: asset.title,
        createdAt: asset.created,
        previewUrl: asset.src,
        filename: asset.name,
        extension: asset.ext,
      };
    });
    yield put(FrontifyActions.assetByPreviewUrlReceived(assetsFallback));
  }
}

/**
 * Watches actions to change the current frontify.assets state
 */
export function* watchGetFrontifyLibraries() {
  yield takeEvery(FrontifyActions.librariesRequested, getFrontifyLibrariesAsync);
}

/**
 * The worker function to push FrontifyAssets to the frontify.assets state
 */
function* getFrontifyLibrariesAsync(action: PayloadAction<LibraryConfig[]>) {
  try {
    const response: FrontifyLibrary[] = yield call(frontifyApi().findLibraries, action.payload);
    yield put(FrontifyActions.librariesReceived(response));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Watches actions to change the current frontify.assets state
 */
export function* watchGetFrontifyAssetsByFilter() {
  yield takeEvery(FrontifyActions.assetByFilterRequested, getFrontifyAssetsByFilterAsync);
}

/**
 * The worker function to push FrontifyAssets to the frontify.assets state
 */
function* getFrontifyAssetsByFilterAsync(action: PayloadAction<AssetFilter>) {
  try {
    const state: FrontifyState = yield select(frontifyStateSelector);
    const selectedLibrary = state.librarySelected;
    const mediaFilter =
      action.payload?.mediaFilter?.map(({ queryParam, value }) => ({
        metadataFieldId: selectedLibrary?.metadataFields?.find((field) => field.queryParam === queryParam)?.id ?? '',
        value,
      })) ?? [];

    const response: AssetItems = yield call(frontifyApi().filterAssets, {
      ...action.payload,
      ...(action.payload.page && { page: action.payload.page }),
      ...(action.payload.pageSize && { pageSize: action.payload.pageSize }),
      libraryId: selectedLibrary?.id ?? '',
      mediaFilter,
    });

    yield put(
      FrontifyActions.assetByFilterReceived({
        ...response,
        mediaFilter: action.payload?.mediaFilter ?? [],
      })
    );
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetFrontifyLibraryDefault() {
  yield takeEvery(FrontifyActions.libraryDefault, getFrontifyLibraryDefault);
}

export function* getFrontifyLibraryDefault(action: PayloadAction<LibraryDefaultPayload>) {
  let findLibrariesResponse: FrontifyLibrary[] = yield call(
    frontifyApi().findLibraries,
    action.payload.libraryConfig ?? []
  );
  findLibrariesResponse = findLibrariesResponse.map((libResponse) => ({
    ...libResponse,
    metadataFields: libResponse.metadataFields.filter((item) => !!item),
  }));

  yield put(FrontifyActions.librariesReceived(findLibrariesResponse));

  let library = findLibrariesResponse[0];
  if (action.payload.libraryId) {
    const libraryFound = findLibrariesResponse.find((library) => library.id === action.payload.libraryId);
    if (libraryFound) {
      library = libraryFound;
    }
  }

  yield put(FrontifyActions.librarySelected(library));

  const filterAssetsResponse: AssetItems = yield call(frontifyApi().filterAssets, {
    libraryId: library.id,
    page: 1,
    pageSize: action.payload.pageSize,
  });

  yield put(FrontifyActions.assetByFilterReceived(filterAssetsResponse));

  action.payload?.resolveCb?.();
}

export function* watchGetFrontifyAssetById() {
  yield takeEvery(FrontifyActions.assetByIdRequested, getAssetById);
}

export function* getAssetById(action: PayloadAction<{ assetId: string; resolveCb?: (value: void) => void }>) {
  const { assetId, resolveCb } = action.payload;
  const response: FrontifyAsset = yield call(frontifyApi().findAsset, { assetId });

  yield put(FrontifyActions.assetByIdReceived(response));

  resolveCb && resolveCb();
}
