import { AxiosInstance, AxiosResponse } from 'axios';
import { IStore } from '../interfaces';
import raw from './raw';

/**
 * Get Retrieve current store data
 * @param {AxiosInstance} http the axios instance
 * @return {Promise<Array<IStore> | null>} stores
 */
export const getAll = (http: AxiosInstance): Promise<Array<IStore> | null> => {
  return raw.get(http, `stores`, {}).then((response: AxiosResponse<any>) => {
    if (response) {
      return response.data.data as Array<IStore>;
    } else {
      return null;
    }
  });
};
