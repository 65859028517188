import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  AssetFilter,
  AssetFilterMetaData,
  AssetItems,
  FrontifyAsset,
  FrontifyFinderAsset,
  FrontifyLibrary,
  LibraryConfig,
  LibraryDefaultPayload,
} from '..';

export interface FrontifyState {
  assets: FrontifyAsset[];
  assetsFiltered: FrontifyAsset[];
  assetsFilteredTotal: number;
  libraries: FrontifyLibrary[];
  librarySelected?: FrontifyLibrary;
  filters: {
    page: number;
    pageSize: number;
    mediaFilter: AssetFilterMetaData[];
  };
  selectedAsset: FrontifyAsset;
}

const initialState: FrontifyState = {
  assets: [],
  assetsFiltered: [],
  assetsFilteredTotal: 0,
  libraries: [],
  filters: {
    page: 1,
    pageSize: 12,
    mediaFilter: [],
  },
  selectedAsset: {} as FrontifyAsset,
};

export const frontifySlice = createSlice({
  name: 'frontify',
  initialState,
  reducers: {
    assetByPreviewUrlReceived: (state, action: PayloadAction<FrontifyAsset[]>) => {
      state.assets = [
        ...state.assets.filter(
          (item: FrontifyAsset) => !action.payload.some((asset) => asset.previewUrl === item.previewUrl)
        ),
        ...action.payload,
      ];
    },
    assetByFilterReceived: (state, action: PayloadAction<AssetItems>) => {
      state.assetsFilteredTotal = action.payload.total ?? 0;
      state.assetsFiltered = [...(action.payload.items ?? [])];

      state.filters = {
        page: action.payload.page ?? 1,
        pageSize: action.payload.limit ?? 12,
        mediaFilter: action.payload.mediaFilter ?? initialState.filters.mediaFilter,
      };
    },
    assetByIdReceived: (state, action: PayloadAction<FrontifyAsset>) => {
      state.selectedAsset = action?.payload ?? initialState.selectedAsset;
    },
    librariesReceived: (state, action: PayloadAction<FrontifyLibrary[]>) => {
      state.libraries = [...action.payload];
    },
    librarySelected: (state, action: PayloadAction<FrontifyLibrary>) => {
      state.librarySelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action['payload'].frontify,
      };
    });
  },
});

export const FrontifyActions = {
  ...frontifySlice.actions,
  assetByPreviewUrlRequested: createAction<Array<FrontifyFinderAsset>>(
    [frontifySlice.name, 'asset by previewUrl requested'].join('/')
  ),
  assetByIdRequested: createAction<{ assetId: string; resolveCb?: (value: void) => void }>(
    [frontifySlice.name, 'asset by filter requested'].join('/')
  ),
  assetByFilterRequested: createAction<AssetFilter>([frontifySlice.name, 'asset by filter requested'].join('/')),
  librariesRequested: createAction<Array<LibraryConfig>>([frontifySlice.name, 'libraries requested'].join('/')),
  libraryDefault: createAction<LibraryDefaultPayload>([frontifySlice.name, 'libraries default'].join('/')),
};

export default frontifySlice.reducer;
