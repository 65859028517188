import styles from './pagination.module.scss';
import { Colors, Dropdown, IconArrow, KeyValuePair, RoundButton } from '@dxp/veneer';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

export interface PaginationProps {
  nPages: number;
  currentPage: number;
  setCurrentPage: (n: number) => void;
  scrollTop?: () => void;
}

export const Pagination = (props: PaginationProps & { className?: string }) => {
  const { t } = useTranslation('common');

  const selectRef = useRef<HTMLSelectElement>(null);
  const pages: Array<KeyValuePair> = [...Array(props.nPages + 1).keys()]
    .slice(1)
    .map((item: number) => ({ key: item.toString(), label: t('page', { count: item }) }));

  const onPrevClick = () => {
    if (props.currentPage - 1 > 0) {
      props.setCurrentPage(props.currentPage - 1);
      if (props.scrollTop) {
        props.scrollTop();
      }
    }
  };

  const onNextClick = () => {
    if (props.currentPage + 1 <= props.nPages) {
      props.setCurrentPage(props.currentPage + 1);
      if (props.scrollTop) {
        props.scrollTop();
      }
    }
  };

  useEffect(() => {
    if (selectRef?.current) {
      selectRef.current.value = props.currentPage.toString();
    }
  }, [props.currentPage]);

  return (
    <div className={classNames(styles['container'], props.className)}>
      <Dropdown
        className={styles['dropdown']}
        variant={Colors.lightGrey}
        values={pages}
        onChange={(e) => props.setCurrentPage(+e.target.value)}
        borders={'round'}
        ref={selectRef}
      />
      <div className={styles['arrows']} data-testid={'buttons'}>
        <RoundButton
          variant={Colors.brilliantBlue}
          icon={<IconArrow rotation={90} />}
          disabled={props.currentPage === 1}
          onClick={onPrevClick}
          data-testid={'prev-button'}
          className={'prev-button'}
        />
        <RoundButton
          variant={Colors.brilliantBlue}
          icon={<IconArrow rotation={270} />}
          disabled={props.currentPage === props.nPages}
          onClick={onNextClick}
          data-testid={'next-button'}
          className={'next-button'}
        />
      </div>
    </div>
  );
};

export default Pagination;
