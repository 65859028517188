import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { PaymentMethod } from '@adyen/adyen-web/dist/types/types';

type StateError = string | { [key: string]: string } | null;
export interface PaymentErrors {
  paymentSessionRequest: StateError;
}

export interface PaymentState {
  error: PaymentErrors;
  config: CoreOptions;
  loading: boolean;
}

const initialState: PaymentState = {
  error: {
    paymentSessionRequest: null,
  },
  loading: false,
  config: {
    paymentMethodsResponse: {
      paymentMethods: [],
      storedPaymentMethods: [],
    },
    clientKey: process.env['NX_ADYEN_CLIENT_KEY'] || '',
    locale: '',
    environment: process.env['NX_ADYEN_API_ENVIRONMENT'] || '',
    analytics: {
      enabled: false,
    },
    showPayButton: false,
  },
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // general reducers
    clearError: (state, action: PayloadAction<keyof PaymentErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },

    requestPaymentSession: (state, action: PayloadAction<{ locale: string }>) => {
      state.error.paymentSessionRequest = null;
      state.loading = true;
    },
    requestPaymentSessionSuccess: (state, action) => {
      state.error.paymentSessionRequest = null;
      state.loading = false;
    },
    requestPaymentSessionFailed: (state, action: PayloadAction<StateError>) => {
      state.error.paymentSessionRequest = action.payload;
      state.loading = false;
    },

    clearPaymentSession: (state) => {},
  },
});

export const PaymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
