import { EntityRecord } from '../types';
import { arrayToObject, findObjectValue, findStringsValue, findStringValue, getObjectValueByPath } from './helper';
import { MappingConfig, ValueDataType } from './types';

/**
 * Converts referenceEntity from akeneo-client to an object with given config.
 * @param {EntityRecord} referenceEntity
 * @param {MappingConfig} config
 * @param {string} locale
 * @param {boolean} debug
 * @returns the mapped referenceEntity with given interface.
 */
export const mapReferenceEntity = <Interface>(
  referenceEntity: EntityRecord,
  config: MappingConfig,
  locale: string,
  debug?: boolean
) => {
  const result: Interface = [
    ...Object.entries(config).reduce((map: Map<string, any>, [propertyKey, propertyConfig]) => {
      if (typeof propertyConfig === 'string') {
        return map.set(propertyKey, propertyConfig);
      } else {
        let value: string | Array<string> | unknown;

        switch (propertyConfig.valueData) {
          case ValueDataType.direct:
            value = getObjectValueByPath(referenceEntity, propertyConfig.path) || '';
            return map.set(propertyKey, propertyConfig.modifier ? propertyConfig.modifier(value) : value);
          case ValueDataType.object:
            value =
              findObjectValue(
                getObjectValueByPath(referenceEntity, propertyConfig.path),
                propertyConfig?.localized ? locale : undefined
              ) || null;
            return map.set(propertyKey, propertyConfig.modifier ? propertyConfig.modifier(value) : value);
          case ValueDataType.string:
            value = findStringValue(
              getObjectValueByPath(referenceEntity, propertyConfig.path),
              propertyConfig.localized ? locale : undefined
            );
            return map.set(propertyKey, propertyConfig.modifier ? propertyConfig.modifier(value) : value);
          case ValueDataType.strings:
            value = findStringsValue(
              getObjectValueByPath(referenceEntity, propertyConfig.path),
              propertyConfig.localized ? locale : undefined
            );
            return map.set(propertyKey, propertyConfig.modifier ? propertyConfig.modifier(value) : value);
          case ValueDataType.stringsUseFirst:
            value = findStringsValue(
              getObjectValueByPath(referenceEntity, propertyConfig.path),
              propertyConfig.localized ? locale : undefined
            )[0];
            return map.set(propertyKey, propertyConfig.modifier ? propertyConfig.modifier(value) : value);
          default:
            return map;
        }
      }
    }, new Map<string, any>()),
  ].reduce(arrayToObject, {});
  if (debug) console.log('[Reference entity Mapper Result]: ', result);
  return result as Interface;
};
