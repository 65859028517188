import styles from './vacancy-search.module.scss';
import { Colors, InputDropdownCombo, KeyValuePair, StandardButton, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { IVacancy } from '../../types';
import { useSelector } from 'react-redux';
import { ContentfulDataSelectors, ContentfulDataState } from '../../redux';
import { useEffect, useMemo, useState } from 'react';

export interface VacancySearchProps {
  linkToFullList: string;
}

export function VacancySearch({ linkToFullList }: VacancySearchProps) {
  const { t } = useTranslation('common');
  const [countries, setCountries] = useState<Array<KeyValuePair>>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [locationID, setLocationID] = useState<string>('');

  const items: Array<IVacancy> = useSelector((state: { contentfulData: ContentfulDataState }) =>
    ContentfulDataSelectors.selectVacancies(state.contentfulData)
  );

  useEffect(() => {
    setCountries(
      items
        .map((item) => ({
          key: item.vacancy.location?.sys.id ?? '',
          label: `${item.vacancy.location?.fields.title}${
            item.vacancy.location?.fields.country ? ' (' + item.vacancy.location?.fields.country + ')' : ''
          }`,
        }))
        .filter((item, index, self) => item.key && item.label && index === self.findIndex((t) => t.key === item.key))
    );
  }, [items]);

  const getQuery = useMemo(() => {
    let query = {};
    if (locationID) {
      query = { ...query, 'vacancy-location': locationID };
    }
    if (searchString) {
      query = { ...query, 'vacancy-search': searchString };
    }
    return query;
  }, [locationID, searchString]);

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(styles['container'])}
    >
      <div className={styles['filter']}>
        <InputDropdownCombo
          className={styles['filter__input']}
          input={{
            placeholder: t('vacancies.search.label'),
            onChange: (e) => setSearchString(e.target.value),
          }}
          dropdown={{
            placeholder: t('vacancies.search.location.label'),
            values: countries,
            onChange: (e) => setLocationID(e.target.value),
          }}
        />
        <div className={styles['buttons']}>
          <Link
            className={classNames(
              styles['filter__link'],
              !locationID && !searchString ? styles['filter__link--inactive'] : ''
            )}
            href={{
              pathname: linkToFullList,
              query: getQuery,
            }}
          >
            <StandardButton
              className={styles['filter__search']}
              label={t('vacancies.search.label')}
              variant={Colors.brilliantBlue}
              disabled={!searchString && !locationID}
            />
          </Link>
          <Link className={styles['filter__link']} href={{ pathname: linkToFullList }} data-testid={'link'}>
            <StandardButton
              label={t('vacancies.search.seeAll', {
                amount: items.length,
              })}
              variant={Colors.feelgoodMint}
            />
          </Link>
        </div>
      </div>
    </motion.div>
  );
}

export default VacancySearch;
