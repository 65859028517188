import { AxiosInstance, AxiosResponse } from 'axios';
import { parseCartResponse } from '../helper';
import { IAddItemToCart, ICart, IChangeItemQuantity, ICreatCartInformation, IDeleteItem } from '../interfaces';
import { ICartsRawResponseItem, Included } from '../interfaces/carts-raw';
import { EIncludeTypes } from '../types';
import raw from './raw';

const include: string = [
  EIncludeTypes.CART_ITEMS,
  EIncludeTypes.CONCRETE_PRODUCTS,
  EIncludeTypes.CONCRETE_PRODUCT_PRICES,
  EIncludeTypes.CONCRETE_PRODUCT_IMAGE_SETS,
  EIncludeTypes.VOUCHERS,
  EIncludeTypes.CART_RULES,
].join(',');

/**
 * Get authorized users carts
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202108.0/managing-carts/carts-of-registered-users/managing-carts-of-registered-users.html#retrieve-a-registered-users-carts
 * @param {AxiosInstance} http the axios instance
 * @return {Promise<Array<ICart> | ICart | null>} users cart(s) or null if there are none
 */
export const get = (http: AxiosInstance): Promise<Array<ICart> | ICart | null> => {
  return raw.get(http, `carts`, { params: { include } }).then((response: AxiosResponse<any>) => {
    if (response) {
      return parseCartResponse(
        response.data.data as ICartsRawResponseItem | Array<ICartsRawResponseItem>,
        response.data.included as Array<Included>
      );
    } else {
      return null;
    }
  });
};

/**
 * Create an authorized users carts
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202108.0/managing-carts/carts-of-registered-users/managing-carts-of-registered-users.html#retrieve-a-registered-users-carts
 * @param {AxiosInstance} http the axios instance
 * @param {cartInfo: ICreatCartInformation} payload information about the store to create the cart in
 * @return {Promise<Array<ICart> | ICart | null>} users cart(s) or null if there are none
 */
export const create = (
  http: AxiosInstance,
  payload: { storeInfo: ICreatCartInformation }
): Promise<Array<ICart> | ICart | null> => {
  return http
    .post(
      `carts`,
      {
        data: { type: 'carts', attributes: payload.storeInfo },
      },
      { params: { include } }
    )
    .then((response: AxiosResponse<any>) => {
      if (response) {
        return parseCartResponse(response.data.data, response.data.included);
      } else {
        return null;
      }
    });
};

/**
 * Add an item to authorized users cart
 * @param {AxiosInstance} http the axios instance
 * @param {item: IAddItemToCart; cartId: string} payload the sku and quantity of the item to add and the cartId
 * @return {Promise<Array<ICart> | ICart | null>} users updated cart(s) or null if there are none
 */
export const addItem = (
  http: AxiosInstance,
  payload: { item: IAddItemToCart; cartId: string }
): Promise<Array<ICart> | ICart | null> => {
  return http
    .post(
      `carts/${payload.cartId}/items`,
      {
        data: { type: 'items', attributes: payload.item },
      },
      { params: { include } }
    )
    .then((response: AxiosResponse) => {
      if (response) {
        return parseCartResponse(response.data.data, response.data.included);
      } else {
        return null;
      }
    });
};

/**
 * Add multiple items to authorized users cart
 * @param {AxiosInstance} http the axios instance
 * @param {items: IAddItemToCart[]; cartId: string} payload array of skus and quantities of items to add and the cartId
 * @return {Promise<Array<ICart> | ICart | null>} users updated cart(s) or null if there are none
 */
export const addItems = (
  http: AxiosInstance,
  payload: { items: Array<IAddItemToCart>; cartId: string }
): Promise<Array<ICart> | ICart | null> => {
  const responses: AxiosResponse[] = [];
  let index = 0;

  const addNextItem = (): Promise<ICart | null> => {
    if (index >= payload.items.length) {
      // All items have been added, parse the responses and return the cart
      let cart = null;
      responses.forEach((response) => (cart = parseCartResponse(response.data.data, response.data.included)));
      return Promise.resolve(cart);
    } else {
      // Add item and wait for the request to complete before adding the next one
      const item = payload.items[index++];
      return http
        .post(
          `carts/${payload.cartId}/items`,
          {
            data: { type: 'items', attributes: item },
          },
          { params: { include } }
        )
        .then((response) => {
          responses.push(response);
          return addNextItem();
        })
        .catch(() => addNextItem());
    }
  };

  // Start adding the items
  return addNextItem();
};

/**
 * Change an item quantity in an authorized users cart
 * @param {AxiosInstance} http the axios instance
 * @param {IChangeItemQuantity} payload the sku and quantity of the item to add and the cartId
 * @return {Promise<Array<ICart> | ICart | null>} users updated cart(s) or null if there are none
 */
export const changeItemQuantity = (
  http: AxiosInstance,
  payload: IChangeItemQuantity
): Promise<Array<ICart> | ICart | null> => {
  return http
    .patch(
      `carts/${payload.cartId}/items/${payload.itemId}`,
      {
        data: { type: 'items', attributes: { quantity: payload.itemQuantity } },
      },
      { params: { include } }
    )
    .then((response: AxiosResponse<any>) => {
      if (response) {
        return parseCartResponse(response.data.data, response.data.included);
      } else {
        return null;
      }
    });
};

/**
 * delete an item in an authorized users cart
 * @param {AxiosInstance} http the axios instance
 * @param {IDeleteItem} payload the sku and the cartId
 * @return {Promise<number | null>} status (2xx - 5xx) or null if there is no response
 */
export const deleteItem = (http: AxiosInstance, payload: IDeleteItem): Promise<number | null> => {
  return http.delete(`carts/${payload.cartId}/items/${payload.itemId}`).then((response: AxiosResponse<any>) => {
    if (response) {
      return response.status;
    } else {
      return null;
    }
  });
};

/**
 * add voucher in authorized users cart
 * @param {AxiosInstance} http the axios instance
 * @param payload
 * @return Promise<ICart | Array<ICart> | null>
 */
export const addVoucher = (
  http: AxiosInstance,
  payload: { cartId: string; voucherCode: string }
): Promise<ICart | Array<ICart> | null> => {
  return http
    .post(
      `carts/${payload.cartId}/vouchers`,
      {
        data: { type: 'vouchers', attributes: { code: payload.voucherCode } },
      },
      { params: { include } }
    )
    .then((response: AxiosResponse<any>) => {
      if (response) {
        return parseCartResponse(response.data.data, response.data.included);
      } else {
        return null;
      }
    });
};

/**
 * Delete voucher from authorized users cart
 * @param {AxiosInstance} http the axios instance
 * @param payload Object with cart id and voucher code to delete
 * @return Promise<ICart | Array<ICart> | null>
 */
export const deleteVoucher = (
  http: AxiosInstance,
  payload: { cartId: string; voucherCode: string }
): Promise<number | null> => {
  return http
    .delete(`carts/${payload.cartId}/vouchers/${payload.voucherCode}`)
    .then((response: AxiosResponse<null>) => (response?.status ? response.status : null));
};

/**
 * add custom order reference in authorized users cart
 * @param {AxiosInstance} http the axios instance
 * @param payload
 * @return Promise<ICart | Array<ICart> | null>
 */
export const addCustomOrderReference = (
  http: AxiosInstance,
  payload: { cartId: string; customOrderReference: string }
): Promise<ICart | Array<ICart> | null> => {
  return http
    .post(
      `carts/${payload.cartId}/order-custom-reference`,
      {
        data: { type: 'order-custom-reference', attributes: { orderCustomReference: payload.customOrderReference } },
      },
      { params: { include } }
    )
    .then((response: AxiosResponse<any>) => {
      if (response) {
        return parseCartResponse(response.data.data, response.data.included);
      } else {
        return null;
      }
    });
};
