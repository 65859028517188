import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrder } from '@dxp/spryker-client';

type StateError = string | { [key: string]: string } | null;

export interface OrdersErrors {
  ordersRequest: StateError;
  orderRequest: StateError;
}

/**
 * The interface for orders in redux store.
 */
export interface OrdersState {
  orders: Array<IOrder> | null;
  order: IOrder | null;
  error: OrdersErrors;
  loading: boolean;
}

export interface IOrderRequestedPayload {
  orderId: string;
  locale: string | undefined;
}

/**
 * The initial orders redux state.
 */
const initialState: OrdersState = {
  orders: null,
  order: null,
  error: {
    ordersRequest: null,
    orderRequest: null,
  },
  loading: false,
};

/**
 * The orders redux slice.
 */
export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    // general reducers
    clearOrders: (state) => {
      state.orders = initialState.orders;
      state.error = initialState.error;
    },
    clearError: (state, action: PayloadAction<keyof OrdersErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },

    // orders list reducers
    ordersRequested: (state, action: PayloadAction<{ locale: string }>) => {
      state.error.ordersRequest = null;
      state.loading = true;
    },
    ordersRequestSuccess: (state, action: PayloadAction<Array<IOrder>>) => {
      state.orders = action.payload;
      state.error.ordersRequest = null;
      state.loading = false;
    },
    ordersRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.ordersRequest = action.payload;
      state.loading = false;
    },

    // single order reducers
    orderRequested: (state, action: PayloadAction<IOrderRequestedPayload>) => {
      state.error.orderRequest = null;
      state.loading = true;
    },
    orderRequestSuccess: (state, action: PayloadAction<IOrder>) => {
      state.order = action.payload;
      state.error.orderRequest = null;
      state.loading = false;
    },
    orderRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.orderRequest = action.payload;
      state.loading = false;
    },
    clearOrder: (state, action: PayloadAction<{ orderId: string }>) => {
      state.order = initialState.order;
    },
  },
});

export const OrdersActions = ordersSlice.actions;

export default ordersSlice.reducer;
