import classNames from 'classnames';
import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import styles from './radio-group.module.scss';

export interface RadioGroupProps {
  name?: string;
  style?: 'expanded' | 'boxed';
  onChange?: (id: string) => void;
  options: Array<RadioOption>;
}

export interface RadioOption {
  value: string;
  id: string;
  label?: string;
  description?: string;
  checked?: boolean;
  disabled?: boolean;
}

export const RadioGroup = forwardRef(
  (props: RadioGroupProps & { className?: string }, ref?: ForwardedRef<HTMLInputElement>) => {
    const [isChecked, setIsChecked] = useState<{ id: string | null; dirty: boolean }>({ id: null, dirty: false });
    const classes = classNames(
      styles['container'],
      props.style ? styles['container--style-' + props.style] : styles['container--style-basic'],
      props.className
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked({ id: event.target.id, dirty: true });
      if (props.onChange) {
        props.onChange(event.target.id);
      }
    };

    useEffect(() => {
      if (Array.isArray(props.options)) {
        props.options.map((option: RadioOption) =>
          option.checked ? setIsChecked({ id: option.id, dirty: false }) : ''
        );
      }
    }, [props.options]);

    const renderRadioGroup: (options: Array<RadioOption>) => Array<JSX.Element> = (options: Array<RadioOption>) =>
      Array.isArray(options)
        ? options.map((option: RadioOption) => {
            return (
              <div
                key={option.id}
                className={classNames(
                  'radio-button',
                  styles['radio-button'],
                  isChecked.id === option.id ? styles['checked'] : styles['unchecked'],
                  isChecked.dirty ? styles['dirty'] : ''
                )}
                data-testid={'radio-button'}
              >
                <input
                  id={option.id}
                  type="radio"
                  name={props.name}
                  value={option.value}
                  checked={isChecked.id === option.id}
                  onChange={(event) => handleChange(event)}
                  disabled={option.disabled}
                  data-testid={'radio-button-input'}
                />
                <label htmlFor={option.id} data-testid={'label'}>
                  {option.label}
                </label>
                {option.description && props.style === 'expanded' && (
                  <div className={styles['description']}>{option.description}</div>
                )}
              </div>
            );
          })
        : [<></>];

    return <div className={classes}>{renderRadioGroup(props.options)}</div>;
  }
);

export default RadioGroup;
