import { createSelector } from '@reduxjs/toolkit';
import { CheckoutPaymentsState } from './slice';

const selectCheckoutPaymentsState = (state: CheckoutPaymentsState) => state;

const selectCheckoutPayments = createSelector(
  [selectCheckoutPaymentsState],
  (state: CheckoutPaymentsState) => state.paymentMethods
);

export const CheckoutPaymentsSelectors = {
  selectCheckoutPayments,
};
