import styles from './discount-icon.module.scss';
import { Colors } from '../../../types';
import React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IconCheck } from '@dxp/veneer';
import classNames from 'classnames';

interface DiscountIconProps {
  label?: string;
  className?: string;
}

export const DiscountIcon = (props: DiscountIconProps & { className?: string }) => {
  return (
    <div className={classNames(props.className, styles['container'])}>
      <div className={styles['discount-icon']}>
        <IconCheck color={Colors.intenseBlue} className={styles['discount-icon__icon']} />
      </div>
      {props.label && <div className={styles['discount-label']}>{props.label}</div>}
    </div>
  );
};

export default DiscountIcon;
