import { AxiosInstance, AxiosResponse } from 'axios';
import raw from './raw';
import {
  ICustomerDataParsed,
  ICustomerFromCheckout,
  ICustomerProfile,
  ICustomerProfileIdentity,
  ICustomerProfilePassword,
  ICustomerRawResponse,
  ICustomerRegisterRawResponse,
  IRequestBody,
} from '../interfaces';
import { parseRegisterDataResponse } from '../helper';
import { parseCustomerDataResponse } from '../helper/parsing/customer';

/**
 * Create a customer
 * @see https://docs.spryker.com/docs/pbc/all/identity-access-management/202204.0/manage-using-glue-api/glue-api-create-customers.html
 * @param {AxiosInstance} http the axios instance
 * @param payload
 * @return {Promise<ICustomerDataParsed | null>} Promise with parsed result of user creation data or null
 */
export const create = (
  http: AxiosInstance,
  payload: { customer: ICustomerProfile | ICustomerFromCheckout; anonymId: string }
): Promise<ICustomerDataParsed | null> => {
  const body: IRequestBody = { data: { type: 'customers', attributes: payload.customer } };
  return raw
    .post(http, `customers`, body, { headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId } })
    .then((response: AxiosResponse<ICustomerRegisterRawResponse>) => {
      if (response?.data) {
        return parseRegisterDataResponse(response.data);
      } else {
        return null;
      }
    });
};

/**
 * Get customer by customer reference
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202204.0/managing-customers/managing-customers.html#retrieve-a-customer
 * @param {AxiosInstance} http the axios instance
 * @param {{ customerRef: string }} object the customer reference
 * @return {Promise<ICustomerDataParsed | null>} Parsed customer data or null
 */
export const byCustomerRef = (
  http: AxiosInstance,
  { customerRef }: { customerRef: string }
): Promise<ICustomerDataParsed | null> => {
  return raw.get(http, `customers/${customerRef}`, {}).then((response: AxiosResponse<ICustomerRawResponse>) => {
    if (response?.data) {
      return parseCustomerDataResponse(response.data);
    } else {
      return null;
    }
  });
};

export const update = (
  http: AxiosInstance,
  { customerRef, payload }: { customerRef: string; payload: ICustomerProfileIdentity }
): Promise<ICustomerDataParsed | null> => {
  const attributes: any = { ...payload };
  if (attributes.email) {
    delete attributes.email;
  }
  const body: IRequestBody = {
    data: {
      type: 'customers',
      id: customerRef,
      attributes,
      include: '',
    },
  };
  return raw
    .patch(http, `customers/${customerRef}`, body, {
      // withCredentials: true // TODO: Activate withCredentials in config when spryker supports this header
    })
    .then((response: AxiosResponse<ICustomerRawResponse>) => {
      if (response?.data) {
        return parseCustomerDataResponse(response.data);
      } else {
        return null;
      }
    });
};

export const updatePassword = (
  http: AxiosInstance,
  { customerRef, payload }: { customerRef: string; payload: ICustomerProfilePassword }
): Promise<boolean | null> => {
  const body: IRequestBody = {
    data: {
      type: 'customer-password',
      id: customerRef,
      attributes: payload,
    },
  };
  return raw
    .patch(http, `customer-password/${customerRef}`, body, {
      // withCredentials: true // TODO: Activate withCredentials in config when spryker supports this header
    })
    .then((response: AxiosResponse<any>) => {
      if (response?.status >= 200 && response.status < 300) {
        return true;
      } else {
        return null;
      }
    });
};
