import { ICartDiscount, ICartRule, IVoucher } from '@dxp/spryker-client';
import { CartState } from './slice';
import { createSelector } from '@reduxjs/toolkit';

const selectCartState = (state: CartState) => state;

const isLoading = createSelector([selectCartState], (state: CartState) => state.loading);

const selectDefaultCart = createSelector([selectCartState], (state: CartState) =>
  state.carts?.find((cart) => cart.isDefault)
);

const isAtLeastOneItemInDefaultCart = createSelector([selectCartState], (state: CartState) => {
  const defaultCart = state.carts?.find((cart) => cart.isDefault);

  return defaultCart && defaultCart.items?.length > 0;
});

const selectCartFlyout = createSelector(
  [selectCartState, (state: CartState) => state && state.flyoutIsOpen],
  (state: CartState) => state.flyoutIsOpen
);

const selectCartLoading = createSelector([selectCartState], (state: CartState) => state.loading);

const selectCartId = createSelector(
  [selectCartState],
  (state: CartState) => state.carts?.find((cart) => cart.isDefault)?.id
);

const getVoucherOrCartRuleByDiscount = createSelector(
  [selectCartState, (state: CartState, payload: { cartId: string; discount: ICartDiscount }) => payload],
  (state: CartState, payload: { cartId: string; discount: ICartDiscount }): IVoucher | ICartRule | null => {
    const cart = state.carts?.find((cart) => cart.id === payload.cartId);
    if (cart) {
      const voucher = cart.vouchers.find(
        // TODO: When spryker delivers discount.code: Use voucher.id === payload.discount.code to find voucher.
        (voucher: IVoucher) => voucher.attributes.displayName === payload.discount.displayName
      );
      const cartRule = cart.cartRules.find(
        // TODO: When spryker delivers discount.code: Use cartRule.id === payload.discount.code to find cartRule.
        (cartRule: ICartRule) => cartRule.attributes.displayName === payload.discount.displayName
      );
      if (voucher) {
        return voucher;
      }
      if (cartRule) {
        return cartRule;
      }
      // no voucher or cartRule found
      return null;
    } else {
      // no cart with given cartId found
      return null;
    }
  }
);

const hasDefaultCartWithItems = createSelector(
  [selectCartState],
  (state: CartState) => state.carts?.some((cart) => cart.isDefault && cart.items?.length > 0) || false
);

export const CartSelectors = {
  isLoading,
  selectCartState,
  selectDefaultCart,
  selectCartFlyout,
  isAtLeastOneItemInDefaultCart,
  selectCartLoading,
  getVoucherOrCartRuleByDiscount,
  selectCartId,
  hasDefaultCartWithItems,
};
