import { SearchState } from '@elastic/search-ui';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { call, takeEvery, select, put, all } from 'redux-saga/effects';
import { HubSpotActions } from './slice';
import { i18n } from 'next-i18next';
import { IHubSpotCustomerProfile } from '@dxp/spryker-client';

/**
 * The watcher function for hubspot elements.
 */
export function* watchHubSpot() {
  yield takeEvery(HubSpotActions.registerHubSpotUser, registerHubSpotUserAsync);
}

/**
 * The worker function for hubspot registration.
 */
export function* registerHubSpotUserAsync(action: PayloadAction<{ customer: IHubSpotCustomerProfile }>) {
  try {
    const portalId: string = yield select((state) => state.hubSpot.portalId);
    const formId: string = yield select((state) => state.hubSpot.newsletterRegistrationId);
    const hubSpotCookie = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const apiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const customer: IHubSpotCustomerProfile = action.payload.customer;

    const data = {
      submittedAt: Date.now(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'salutation',
          value: customer.salutation,
        },
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: customer.firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: customer.lastName,
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: customer.company,
        },
        {
          objectTypeId: '0-1',
          name: 'country',
          value: customer.country,
        },
        {
          objectTypeId: '0-1',
          name: 'address',
          value: customer.address1,
        },
        {
          objectTypeId: '0-1',
          name: 'zip',
          value: customer.zipCode,
        },
        {
          objectTypeId: '0-1',
          name: 'city',
          value: customer.city,
        },
        {
          objectTypeId: '0-1',
          name: 'address_2',
          value: customer.address2,
        },
        {
          objectTypeId: '0-1',
          name: 'phone',
          value: customer.phone,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: customer.email,
        },
      ],
      context: {
        hutk: hubSpotCookie.trim() !== '' ? hubSpotCookie : null, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: window.location.href,
        pageName: 'DXP',
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: customer.acceptedTerms,
          text: i18n?.t('hubspot.registration.legalConsent.text', { ns: 'account' }) || '',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: i18n?.t('hubspot.registration.communications.text', { ns: 'account' }) || '',
            },
            {
              value: customer.newsletter,
              subscriptionTypeId: 42232462,
              text: i18n?.t('hubspot.registration.newsletter.text', { ns: 'account' }) || '',
            },
          ],
        },
      },
    };

    const response: AxiosResponse<SearchState> = yield call(axios.post, apiUrl, data);
    if (response?.data) {
      yield all([put(HubSpotActions.registerHubSpotUserSuccess())]);
    } else {
      yield all([put(HubSpotActions.registerHubSpotUserError('Error creating an User'))]);
    }
  } catch (error: any) {
    console.log('Error on registerHubSpotUserAsync worker: ', error);
    yield all([put(HubSpotActions.registerHubSpotUserError(error))]);
  }
}
