import styles from './level-2-pim.module.scss';
import { TypeNavigationElementFields } from '@dxp/content';
import { motion } from 'framer-motion';
import { MouseEventHandler, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';

export const Level2Pim = ({
  href,
  label,
  shy,
  imageUrl,
  onClick,
  onMouseLeave,
  onMouseEnter,
  isClicked,
}: TypeNavigationElementFields & {
  href?: string | null;
  shy?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  isClicked?: boolean;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const content = (
    <motion.span
      className={classNames(styles['button'], { [styles['button--clicked']]: isClicked })}
      onClick={onClick}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      animate={isHovered ? { opacity: 1 } : shy ? { opacity: 0.4 } : { opacity: 1 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      {!!imageUrl && imageUrl !== '' && (
        <motion.span className={styles['button__image']} animate={isHovered ? { scale: 1.1 } : { scale: 1 }}>
          <Image src={imageUrl} alt={label} className={styles['image']} width={250} height={180} />
        </motion.span>
      )}
      <span className={styles['button__label']}>{label}</span>
    </motion.span>
  );

  return href ? (
    <Link className={styles['link']} href={href}>
      {content}
    </Link>
  ) : (
    <span className={styles['link']}>{content}</span>
  );
};

export default Level2Pim;
