import { ICheckout, ICheckoutRequestAttributes } from '@dxp/spryker-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateError = string | { [key: string]: string } | null;

export interface CheckoutErrors {
  checkoutRequest: StateError;
}

/**
 * The interface for checkout state in redux store.
 */
export interface CheckoutState {
  checkout: any;
  error: CheckoutErrors;
  loading: boolean;
}

/**
 * The initial checkout redux state.
 */
const initialState: CheckoutState = {
  checkout: null,
  error: {
    checkoutRequest: null,
  },
  loading: false,
};

/**
 * The checkout redux slice.
 */
export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    // general reducers
    clearCheckoutState: (state) => {
      state.checkout = initialState.checkout;
      state.error = initialState.error;
      state.loading = initialState.loading;
    },
    clearError: (state, action: PayloadAction<keyof CheckoutErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },

    // checkout reducers/ actions
    checkoutRequested: (
      state,
      action: PayloadAction<{
        locale: string;
        checkoutRequestAttributes: ICheckoutRequestAttributes;
        anonymId: string | null;
        termsAccepted: boolean | null;
      }>
    ) => {
      state.error.checkoutRequest = null;
      state.loading = true;
    },
    checkoutRequestSuccess: (state, action: PayloadAction<Array<ICheckout>>) => {
      state.checkout = action.payload;
      state.error.checkoutRequest = null;
      state.loading = false;
    },
    checkoutRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.checkoutRequest = action.payload;
      state.loading = false;
    },
  },
});

export const CheckoutActions = checkoutSlice.actions;

export default checkoutSlice.reducer;
