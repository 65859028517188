import raw from './raw';
import { AxiosInstance, AxiosResponse } from 'axios';
import { TApiResponseData } from '../types';
import { IOrder, IOrderAttributes } from '../interfaces';

/**
 * Retrieve customers orders
 * @param {AxiosInstance} http the axios instance
 * @return {any} the orders
 */
export const get = (http: AxiosInstance): Promise<Array<IOrder> | []> => {
  const include = ``;
  return raw.get(http, `orders/`, { params: { include } }).then((response: AxiosResponse<TApiResponseData>) => {
    const ordersResponse = response.data.data as Array<IOrder>;

    const orders: IOrder[] = ordersResponse.map((order) => {
      const attributes: IOrderAttributes = Object.entries(order.attributes).reduce((newAttributeSet, [key, value]) => {
        if (key === 'totals') {
          newAttributeSet.totals = value;
        } else {
          newAttributeSet[key as keyof IOrderAttributes] = value;
        }
        return newAttributeSet;
      }, {} as IOrderAttributes);

      return {
        type: order.type,
        id: order.id,
        links: order.links,
        attributes,
      };
    });

    return orders;
  });
};

/**
 * Retrieve a specific order
 * @param {AxiosInstance} http the axios instance
 * @param orderId
 * @return {any} the orders
 */
export const getOne = (http: AxiosInstance, { orderId }: { orderId: string }): Promise<IOrder | null> => {
  return raw.get(http, `orders/${orderId}`, {}).then((response: AxiosResponse<TApiResponseData>) => {
    return response.data.data as IOrder;
  });
};
