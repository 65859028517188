import styles from './flag-icon.module.scss';

import classNames from 'classnames';

export interface FlagIconProps {
  countryCode: string;
}

export const FlagIcon = (props: FlagIconProps & { className?: string }) => {
  return (
    <span
      className={classNames(props.className, styles['flag'], styles['flag--' + props.countryCode.toLowerCase()])}
      data-testid="flag"
    ></span>
  );
};

export default FlagIcon;
