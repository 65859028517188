import { ChangeEvent, cloneElement, forwardRef, LegacyRef, ReactElement, useId, useState } from 'react';
import classNames from 'classnames';
import styles from './input.module.scss';
import { Colors, InputPurpose } from '../../../types';
import { motion } from 'framer-motion';
import { VeneerAnimation } from '../../../animations';
import { IconSearch } from '../../decoration/icons';

export interface InputProps {
  autocomplete?: InputPurpose | 'off';
  borders?: 'round' | 'square';
  error?: string;
  label?: string;
  icon?: ReactElement;
  name?: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  type: 'text' | 'textarea' | 'password' | 'email' | 'search';
  variant: Colors.white | Colors.lightGrey;
  disabled?: boolean;
  value?: string;
  hidePasswordReveal?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const Input = forwardRef((props: InputProps & { className?: string }, ref?: LegacyRef<any> | undefined) => {
  const id = useId();

  const classes = classNames(
    styles['group'],
    props.className,
    props.error ? 'invalid' : '',
    props.icon ? 'icon' : '',
    props.required ? 'required' : '',
    { disabled: props.disabled },
    { readonly: props.readonly }
  );
  const [revealed, setRevealed] = useState<boolean>(false);

  const onRevealPasswordClick = () => {
    setRevealed(!revealed);
  };

  return (
    <div className={classes} data-testid={'group'}>
      {props.label && (
        <label className={'label'} htmlFor={id} data-testid={'label'}>
          {props.label}
        </label>
      )}

      <div className={'input-container'}>
        {props.type !== 'textarea' && (
          <>
            {props.icon &&
              props.type !== 'search' &&
              cloneElement(props.icon, { className: 'input-icon', color: Colors.intenseBlue })}
            <input
              autoComplete={props.autocomplete}
              className={classNames(
                'input',
                `input--${props.type}`,
                `input--${props.variant}`,
                props.borders ? `input--${props.borders}` : 'input--square'
              )}
              id={id}
              name={props.name}
              placeholder={props.placeholder}
              ref={ref}
              disabled={props.disabled}
              data-testid={'input'}
              readOnly={props.readonly}
              onChange={props.onChange}
              onBlur={props.onBlur}
              type={props.type === 'password' && revealed ? 'text' : props.type}
              value={props.value}
            />
            {props.type === 'search' && <IconSearch color={Colors.intenseBlue} />}
            {props.type === 'password' && !props.hidePasswordReveal && (
              <button
                className={'password-toggle'}
                aria-label={'Reveal password'}
                type="button"
                onClick={onRevealPasswordClick}
                data-testid={'password-toggle'}
                tabIndex={-1}
              >
                <svg fill="none" viewBox="0 0 21 16" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      clipRule="evenodd"
                      d="m10.9858 11.9762c1.8485 0 3.3471-1.4986 3.3471-3.34712 0-1.84855-1.4986-3.3471-3.3471-3.3471-1.84858 0-3.34713 1.49855-3.34713 3.3471 0 1.84852 1.49855 3.34712 3.34713 3.34712z"
                      fillRule="evenodd"
                    />
                    <path
                      clipRule="evenodd"
                      d="m20.3528 8.50034s-4.0988 5.73786-9.4264 5.73786c-5.32763 0-9.4264-5.73786-9.4264-5.73786s4.09877-5.73789 9.4264-5.73789c5.3276 0 9.4264 5.73789 9.4264 5.73789z"
                      fillRule="evenodd"
                    />
                    <motion.path
                      d="m3.81449 1.07735 14.13821 14.13815"
                      animate={revealed ? { opacity: 0 } : { opacity: 1 }}
                      transition={{ duration: 0.25, ease: VeneerAnimation.easings.inOutCubic }}
                    />
                  </g>
                </svg>
              </button>
            )}
          </>
        )}

        {props.type === 'textarea' && (
          <textarea
            autoComplete={props.autocomplete}
            className={classNames(styles['input'], styles[`input--${props.type}`])}
            id={id}
            name={props.name}
            placeholder={props.placeholder}
            readOnly={props.readonly}
            ref={ref}
            disabled={props.disabled}
            data-testid={'textarea'}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
          />
        )}
      </div>

      <div className={'error-message'}>
        <span data-testid={'error'}>{props.error}</span>
      </div>
    </div>
  );
});

export default Input;
