import { createMappingPropertyConfig, MappingConfig, ValueDataType } from '@dxp/akeneo-client';
import { IPdp } from './pdp.interface';
import { MappingPropertyConfigs } from './shared';

export interface IComparableProduct extends IPdp {
  width?: string;
  length?: string;
  height?: string;
  design_artex?: string;
  sci_artex?: string;
  bennett_artex?: string;
  protrusion_artex?: string;
  retrusion_artex?: string;
  sideshift_artex?: string;
  distraction_artex?: string;
  centric_design_artex?: string;
  plate_clip_artex?: string;
  arbitrary_artex?: string;
}

/**
 * Mapping configuration for IComparableProducts interface
 */
export const IComparableProductConfig: MappingConfig = {
  sku: {
    path: ['identifier'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  slug: {
    path: ['values', 'slug'],
    valueData: ValueDataType.string,
    localized: true,
  },
  slugs: {
    path: ['values', 'slug'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  categories: {
    path: ['categories'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  name: {
    path: ['values', 'name'],
    valueData: ValueDataType.string,
    localized: true,
  },
  claim: {
    path: ['values', 'claim'],
    valueData: ValueDataType.string,
    localized: true,
  },
  shortDescription: {
    path: ['values', 'short_description'],
    valueData: ValueDataType.string,
    localized: true,
  },
  callToAction: {
    path: ['values', 'call_to_action'],
    valueData: ValueDataType.strings,
    localized: true,
  },
  technicalDataCodes: {
    path: ['values', 'technical_data'],
    valueData: ValueDataType.strings,
    localized: false,
    additionalDataCode: 'technical_data',
  },
  longDescriptionHeadline: {
    path: ['values', 'long_description_headline'],
    valueData: ValueDataType.string,
    localized: true,
  },
  longDescription: {
    path: ['values', 'long_description'],
    valueData: ValueDataType.string,
    localized: true,
  },
  highlightHeadline: {
    path: ['values', 'highlight_headline'],
    valueData: ValueDataType.string,
    localized: true,
  },
  highlightText: {
    path: ['values', 'highlight_text'],
    valueData: ValueDataType.string,
    localized: true,
  },
  featureDescription: {
    path: ['values', 'feature_benefits_description'],
    valueData: ValueDataType.string,
    localized: true,
  },
  productImageCodes: {
    path: ['values', 'product_pictures_attribute'],
    valueData: ValueDataType.strings,
    localized: false,
    additionalDataCode: 'product_picture_asset_family',
  },
  additionalMediaHeadline: {
    path: ['values', 'additional_media_text'],
    valueData: ValueDataType.string,
    localized: true,
  },
  width: MappingPropertyConfigs.width,
  length: MappingPropertyConfigs.length,
  height: MappingPropertyConfigs.height,
  design_artex: createMappingPropertyConfig(['values', 'design_artex'], ValueDataType.string, false),
  sci_artex: createMappingPropertyConfig(['values', 'sci_artex'], ValueDataType.string, false),
  bennett_artex: createMappingPropertyConfig(['values', 'bennett_artex'], ValueDataType.string, false),
  protrusion_artex: createMappingPropertyConfig(['values', 'protrusion_artex'], ValueDataType.string, false),
  retrusion_artex: createMappingPropertyConfig(['values', 'retrusion_artex'], ValueDataType.string, false),
  sideshift_artex: createMappingPropertyConfig(['values', 'sideshift_artex'], ValueDataType.string, false),
  distraction_artex: createMappingPropertyConfig(['values', 'distraction_artex'], ValueDataType.string, false),
  centric_design_artex: createMappingPropertyConfig(['values', 'centric_design_artex'], ValueDataType.string, false),
  plate_clip_artex: createMappingPropertyConfig(['values', 'plate_clip_artex'], ValueDataType.string, false),
  arbitrary_artex: createMappingPropertyConfig(['values', 'arbitrary_artex'], ValueDataType.string, false),
};
