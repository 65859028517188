import styles from './level-1-item.module.scss';
import { MouseEventHandler, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { isExternalURL } from '@dxp/core';

export interface Level1ItemProps {
  href?: string;
  label: string;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  active: boolean;
  shy: boolean;
  hasChildren: boolean;
}

export const Level1Item = (props: Level1ItemProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const renderInner = () => (
    <>
      <span className={styles['element__label']}> {props.label}</span>
      {props.hasChildren && (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(styles['icon'], styles['element__icon'])}
          fill="none"
          viewBox="0 0 38 37"
          animate={isHovered && !props.active ? { rotate: '-180deg' } : { rotate: '0deg' }}
        >
          <g className={styles['icon__inner']} strokeWidth="1.5">
            <motion.path
              animate={props.active ? { rotate: '180deg' } : { rotate: '0deg' }}
              d="m33.7158 18.2905h-28.77776"
            />
            <motion.path
              animate={props.active ? { rotate: '90deg' } : { rotate: '0deg' }}
              d="m19.1172 4.11084v28.77776"
            />
          </g>
        </motion.svg>
      )}
    </>
  );

  return (
    <>
      {props.href ? (
        <Link
          onClick={props.onClick}
          href={props.href}
          target={isExternalURL(props.href) ? '_blank' : '_self'}
          rel={isExternalURL(props.href) ? 'noopener' : ''}
        >
          <motion.span
            className={classNames(styles['element'])}
            key={props.label}
            animate={props.active || isHovered ? { opacity: 1 } : props.shy ? { opacity: 0.4 } : { opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.35 }}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
          >
            {renderInner()}
          </motion.span>
        </Link>
      ) : (
        <motion.button
          className={classNames(styles['element'])}
          key={props.label}
          onClick={props.onClick}
          animate={props.active || isHovered ? { opacity: 1 } : props.shy ? { opacity: 0.4 } : { opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: 0.35 }}
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {renderInner()}
        </motion.button>
      )}
    </>
  );
};

export default Level1Item;
