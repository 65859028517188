export enum Colors {
  smartBlue = 'smart-blue',
  feelgoodMint = 'feelgood-mint',
  brilliantBlue = 'brilliant-blue',
  shinyYellow = 'shiny-yellow',
  intenseBlue = 'intense-blue',
  cosyCashmere = 'cosy-cashmere',
  lightGrey = 'light-grey',
  middleGrey = 'middle-grey',
  black = 'black',
  white = 'white',
  error = 'status-red',
}

export const colors = Object.values(Colors);
