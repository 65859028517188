import { AuthState } from '../redux';
import { PlatformWebappStaticRoutes } from '@dxp/core';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

export const useAuth = (
  redirectUrl = PlatformWebappStaticRoutes.account.login,
  redirectWhenAuthenticated = false,
  redirectOnLastPage = false
) => {
  const router = useRouter();
  const { _persist, loading, customerRef } = useSelector((state: { auth: AuthState }) => state.auth);

  useEffect(() => {
    if (_persist?.rehydrated && !loading) {
      // Redirect to the last history page
      if (redirectOnLastPage && customerRef) {
        router.back();

        return;
      }

      // Redirect unauthenticated users
      if (!redirectWhenAuthenticated && !customerRef) {
        router.push(redirectUrl).then();
      }
      // Redirect authenticated users
      if (redirectWhenAuthenticated && customerRef) {
        router.push(redirectUrl).then();
      }
    }
  }, [_persist, loading, customerRef]);

  return customerRef;
};
