import { createContext } from 'react';

export interface IAuthContext {
  anonymId: string | null;
  customerRef: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  rehydrated: boolean;
}

export const AuthContext = createContext<IAuthContext | null>(null);
