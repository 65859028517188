import { Colors, IconArrow, IconClose, RoundButton, VeneerAnimation, Video } from '@dxp/veneer';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { MutableRefObject, createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper as ISwiper } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useKeyboard, useStateSafe } from '../../hooks';
import { ModalActions, ModalItemInterface, ModalStateInterface } from '../../redux';
import YoutubePlayer from '../youtube-player/youtube-player';
import styles from './modal.module.scss';

export interface ModalProps {
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const variants = {
  hidden: {
    y: '-100vh',
  },
  visible: {
    y: '0vh',
  },
  exit: {
    y: '-100vh',
  },
};
const transition = { duration: 0.5, ease: VeneerAnimation.easings.inOutCubic };

export const Modal = (props: ModalProps) => {
  const [mediaRefs, setMediaRefs] = useStateSafe<MutableRefObject<HTMLDivElement>[]>([]);
  const innerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const swiperRef: MutableRefObject<ISwiper | null> = useRef(null);
  const dispatch = useDispatch();
  const modal = useSelector((state: ModalStateInterface) => state.modal);
  const [isBeginning, setIsBeginning] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const onCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(ModalActions.close());
  };

  const onPrevClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const onNextClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  useEffect(() => {
    // add or remove refs
    setMediaRefs((slideRefs) =>
      Array(modal.contents?.items.length)
        .fill(null)
        .map((_, i) => slideRefs[i] || createRef())
    );
  }, [modal.contents?.items.length, setMediaRefs]);

  useKeyboard((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      dispatch(ModalActions.close());
    }
  });

  const onBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (
      e.target === innerRef.current ||
      mediaRefs.some((ref: MutableRefObject<HTMLDivElement>) => ref.current === e.target)
    ) {
      dispatch(ModalActions.close());
    }
  };

  const renderItem = (item: ModalItemInterface, index: number) => {
    switch (item.type) {
      case 'image':
        return <img className={styles['media__item']} src={item.url} alt={item.alt} />;
      case 'video':
        return (
          <>
            {item.title && <h2 className={classNames(styles['description-space'], 'heading-3')}>{item.title}</h2>}
            {item.description && <p className={classNames(styles['description-space'], 'intro')}>{item.description}</p>}
            <Video
              autoplay={index === modal.contents?.activeItem}
              className={styles['media__item']}
              src={item.url}
            ></Video>
          </>
        );
      case 'youtube':
        return (
          <>
            {item.title && <h2 className={classNames(styles['description-space'], 'heading-3')}>{item.title}</h2>}
            {item.description && <p className={classNames(styles['description-space'], 'intro')}>{item.description}</p>}
            <div className={styles['media__item']}>
              <YoutubePlayer className={styles['media__youtube']} id={item.url} title={item.title}></YoutubePlayer>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderSlides = modal.contents?.items.map((item: ModalItemInterface, index: number) => {
    return (
      <SwiperSlide className={styles['slide']} key={index}>
        <div
          className={classNames(styles['media'], styles[`media--${item.type}`])}
          ref={mediaRefs[index]}
          data-type={item.type}
        >
          {renderItem(item, index)}
        </div>
      </SwiperSlide>
    );
  });

  const swiperProps: SwiperProps = {
    className: styles['slideshow'],
    allowTouchMove: false,
    draggable: false,
    simulateTouch: false,
    onActiveIndexChange: (swiper: ISwiper) => {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);

      if (mediaRefs.length) {
        // pause previous video
        const previous = mediaRefs[swiper.previousIndex].current;

        if (previous && previous.getAttribute('data-type') === 'video') {
          const video = previous.querySelector('video');

          if (video) video.pause();
        }

        // start current video

        const active = mediaRefs[swiper.activeIndex].current;

        if (active && active.getAttribute('data-type') === 'video') {
          const video = active.querySelector('video');

          if (video) video.play();
        }
      }
    },
    onInit: (swiper: ISwiper) => {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);

      swiperRef.current = swiper;
    },
    initialSlide: modal.contents?.activeItem,
  };

  return (
    <motion.div
      onClick={onBackgroundClick}
      className={classNames('modal', styles['modal'])}
      variants={variants}
      initial="hidden"
      animate={'visible'}
      exit="exit"
      transition={transition}
    >
      <RoundButton
        className={classNames(styles['close'])}
        onClick={onCloseClick}
        icon={<IconClose />}
        variant={Colors.white}
      ></RoundButton>
      {modal.contents?.items?.length && modal.contents?.items?.length > 1 && (
        <>
          <RoundButton
            className={classNames(styles['control'], styles['control--prev'])}
            disabled={isBeginning}
            onClick={onPrevClick}
            icon={<IconArrow rotation={90} />}
            variant={Colors.white}
          ></RoundButton>
          <RoundButton
            className={classNames(styles['control'], styles['control--next'])}
            disabled={isEnd}
            onClick={onNextClick}
            icon={<IconArrow rotation={270} />}
            variant={Colors.white}
          ></RoundButton>
        </>
      )}

      <div className={styles['inner']} ref={innerRef}>
        <Swiper {...swiperProps}>{renderSlides}</Swiper>
      </div>
    </motion.div>
  );
};

export default Modal;
