import {
  Colors,
  IconArrow,
  IconArrowLong,
  IconBin,
  IconButton,
  IconMarker,
  RoundButton,
  ScreenSizes,
  ToggleSwitch,
  useScreenSizes,
} from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import styles from './address-row.module.scss';
import { IAddressItem } from '@dxp/spryker-client';
import { Controller, useForm } from 'react-hook-form';

export interface AddressRowProps {
  address: IAddressItem;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEdit?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AddressRow = (props: AddressRowProps & { className?: string }) => {
  const { t } = useTranslation('account');
  const { control } = useForm();
  const screenSize = useScreenSizes();

  return (
    <div className={classNames(styles['container'], props.className)}>
      <div className={styles['address']}>
        <IconMarker color={Colors.intenseBlue} className={styles['address__icon']} />
        <div className={styles['address__data']}>
          <strong data-testid={'name'}>
            {props.address.company ? props.address.company : `${props.address.firstName} ${props.address.lastName}`}
          </strong>
          <br />
          <p className={styles['address__line-wrap']}>
            {props.address.iso2Code && (
              <span
                className={classNames(styles['address__line'], styles['address__line--country'])}
                data-testid={'country'}
              >
                ({props.address.iso2Code})&nbsp;
              </span>
            )}
            {props.address.address1 && (
              <span
                className={classNames(styles['address__line'], styles['address__line--address'])}
                data-testid={'address'}
              >
                {props.address.address1},&nbsp;
              </span>
            )}
            {(props.address.zipCode || props.address.city) && (
              <span className={classNames(styles['address__line'], styles['address__line--city'])} data-testid={'city'}>
                {props.address.zipCode} {props.address.city},&nbsp;
              </span>
            )}
            {props.address.phone && (
              <span className={classNames(styles['address__line'], styles['address__line--tel'])} data-testid={'phone'}>
                Tel. {props.address.phone}
              </span>
            )}
          </p>
        </div>
      </div>
      <div className={styles['toggles']}>
        <Controller
          control={control}
          defaultValue={props.address.isDefaultBilling}
          name={'isDefaultBilling'}
          render={({ field: { name, onChange, ref, value }, fieldState: { error } }) => (
            <ToggleSwitch
              name={name}
              ref={ref}
              onChange={(event) => {
                if (props.onChange) props.onChange(event);
                return onChange(event);
              }}
              checked={value}
              locked={value}
              label={t('addresses.billingAddress')}
              variant={Colors.lightGrey}
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={props.address.isDefaultShipping}
          name={'isDefaultShipping'}
          render={({ field: { name, onChange, ref, value }, fieldState: { error } }) => (
            <ToggleSwitch
              name={name}
              ref={ref}
              onChange={(event) => {
                if (props.onChange) props.onChange(event);
                return onChange(event);
              }}
              checked={value}
              locked={value}
              label={t('addresses.shippingAddress')}
              variant={Colors.lightGrey}
            />
          )}
        />
      </div>
      <div className={styles['buttons']}>
        <RoundButton
          className={styles['delete']}
          onClick={props.onDelete}
          title={t('addresses.delete')}
          icon={<IconBin />}
          type={'button'}
          variant={Colors.brilliantBlue}
        />
        {screenSize === ScreenSizes.SMALL ? (
          <IconButton
            icon={<IconArrow color={Colors.intenseBlue} />}
            className={classNames(styles['details'], styles['details--mobile'])}
            onClick={props.onEdit}
          />
        ) : (
          <RoundButton
            className={classNames(styles['details'], styles['details--desktop'])}
            title={t('addresses.edit')}
            onClick={props.onEdit}
            icon={<IconArrowLong rotation={180} />}
            variant={Colors.feelgoodMint}
          />
        )}
      </div>
    </div>
  );
};

export default AddressRow;
