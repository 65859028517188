import {MappingConfig, ValueDataType} from '@dxp/akeneo-client';

/**
 * The interface for a highlight video element from catalog.
 * This video is used in the highlight component.
 * Note: Do not mix up with IHighlightVideo
 */
export interface IHighlightVideoElement {
  videoUrl: string;
  code: string;
  label: string;
  title: string;
  alt: string;
}

/**
 * Mapping configuration for IHighlightVideoElement interface
 */
export const iHighlightVideoElementConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  label: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
  videoUrl: {
    path: ['values', 'highlight_video_elements_asset_link'],
    valueData: ValueDataType.string,
    localized: true,
  },
  title: {
    path: ['values', 'title_tag'],
    valueData: ValueDataType.string,
    localized: true,
  },
  alt: {
    path: ['values', 'alt_tag'],
    valueData: ValueDataType.string,
    localized: true,
  },
};
