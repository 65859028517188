import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export enum HeaderThemes {
  ON_DARK,
  ON_LIGHT,
}

export interface HeaderState {
  theme: HeaderThemes;
}

const initialState: HeaderState = {
  theme: 1,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setLayout: (state: HeaderState, action: PayloadAction<HeaderThemes>) => {
      state.theme = action.payload;
    },
  },
});

export const HeaderActions = headerSlice.actions;
export default headerSlice.reducer;
