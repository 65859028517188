import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

export interface ITestimonial {
  code: string;
  text: string;
  partnerName: string;
  partnerCompany: string;
  partnerLocation: string;
  imageUrl: string;
}

/**
 * Mapping configuration for ITestimonial interface
 */
export const iTestimonialConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  text: {
    path: ['values', 'testimonial_text'],
    valueData: ValueDataType.string,
    localized: true,
  },
  partnerName: {
    path: ['values', 'name_partner'],
    valueData: ValueDataType.string,
    localized: false,
  },
  partnerCompany: {
    path: ['values', 'lab_company_name'],
    valueData: ValueDataType.string,
    localized: false,
  },
  partnerLocation: {
    path: ['values', 'lab_country_city'],
    valueData: ValueDataType.string,
    localized: true,
  },
  imageUrl: {
    path: ['values', 'partner_picture_url'],
    valueData: ValueDataType.string,
    localized: false,
  },
};
