import { ToasterActions } from '@dxp/core';
import {
  IAddItemToCart,
  ICart,
  IChangeItemQuantity,
  ICreatCartInformation,
  IDeleteItem,
  IStore,
  PriceMode,
} from '@dxp/spryker-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { i18n } from 'next-i18next';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { CommonCommerceState, sprykerClient } from '../../../index';
import { CartActions, CartState } from '../slice';

/**
 * The watcher function to fetch carts from an authenticated user
 */
export function* watchFetchCarts() {
  yield takeEvery(CartActions.cartsRequested, fetchCartsAsync);
}

/**
 * The worker function to fetch carts from an authenticated user.
 * @param action
 */
export function* fetchCartsAsync(action: PayloadAction<{ locale: string }>) {
  try {
    const { locale } = action.payload;

    const result: Array<ICart> | null = yield call(sprykerClient(locale).cart.get, {});

    if (result && result.length) {
      yield put(CartActions.cartsReceived(result));
    } else if (result && !result.length) {
      yield put(CartActions.createCartRequested({ locale }));
    } else {
      yield put(CartActions.cartsRequestFailed('No result when fetching cart'));
    }
  } catch (error: any) {
    yield put(CartActions.cartsRequestFailed(error));
  }
}

/**
 * The watcher function to create a cart for an authenticated user
 */
export function* watchCreateCart() {
  yield takeEvery(CartActions.createCartRequested, createCartAsync);
}

/**
 * The worker function to create a cart for an authenticated user
 * @param action
 */
export function* createCartAsync(action: PayloadAction<{ locale: string | undefined }>) {
  try {
    const { locale } = action.payload;

    const storeInformationSelector: (state: { commonCommerce: CommonCommerceState }) => IStore | undefined = (state: {
      commonCommerce: CommonCommerceState;
    }) => state.commonCommerce.stores.find((store) => store.id !== '');
    const storeInformation: IStore | null = yield select(storeInformationSelector);

    if (storeInformation) {
      const storeInfo: ICreatCartInformation = {
        priceMode: PriceMode.NET_MODE,
        currency: storeInformation.attributes.defaultCurrency,
        store: storeInformation.id,
        name: i18n?.t('cartTerm', { ns: 'cart' }) || '',
      };

      const result: ICart | null = yield call(sprykerClient(locale).cart.create, { storeInfo });

      if (result) {
        yield put(CartActions.createCartReceived(result));
      } else {
        yield put(CartActions.createCartFailed('No result when creating a cart'));
      }
    } else {
      yield put(CartActions.createCartFailed('No result selecting the store'));
    }
  } catch (error: any) {
    yield put(CartActions.createCartFailed(error));
  }
}

/**
 * The watcher function to add an item in a cart from an authenticated user
 */
export function* watchAddItemToCart() {
  yield takeEvery(CartActions.addItemToCart, addItemToCartAsync);
}

/**
 * The worker function to add an item in a cart from an authenticated user
 * @param action
 */
export function* addItemToCartAsync(action: PayloadAction<{ locale: string; payload: IAddItemToCart }>) {
  try {
    const { locale, payload } = action.payload;

    const defaultCartSelector: (state: { cart: CartState }) => ICart | null = (state: { cart: CartState }) =>
      state.cart.carts?.length ? state.cart.carts.find((cart) => cart.isDefault) || state.cart.carts[0] : null;
    const customerCart: ICart | null = yield select(defaultCartSelector);

    if (customerCart) {
      const result: ICart | null = yield call(sprykerClient(locale).cart.addItem, {
        cartId: customerCart?.id,
        item: payload,
      });
      if (result) {
        yield all([
          put(
            ToasterActions.add({
              type: 'success',
              title: i18n?.t('toast.addItem.success.title', { ns: 'cart' }) || '',
              bodytext: i18n?.t('toast.addItem.success.body', { ns: 'cart' }) || '',
            })
          ),
          put(CartActions.addItemToCartSuccess(result)),
        ]);
      } else {
        yield all([
          put(
            ToasterActions.add({
              type: 'warning',
              title: i18n?.t('toast.addItem.error.title', { ns: 'cart' }) || '',
              bodytext: i18n?.t('toast.addItem.error.body', { ns: 'cart' }) || '',
            })
          ),
          put(CartActions.addItemToCartFail('No result from addItem')),
        ]);
      }
    } else {
      yield all([
        put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.addItem.error.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addItem.error.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.addItemToCartFail('No default cart found')),
      ]);
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.addItem.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.addItem.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.addItemToCartFail(error)),
    ]);
  }
}

/**
 * The watcher function to add multiple items in a cart from an authenticated user
 */
export function* watchAddItemsToCart() {
  yield takeEvery(CartActions.addItemsToCart, addItemsToCartAsync);
}

/**
 * The worker function to add multiple items in a cart from an authenticated user
 * @param action
 */
export function* addItemsToCartAsync(action: PayloadAction<{ locale: string; payload: Array<IAddItemToCart> }>) {
  try {
    const { locale, payload } = action.payload;

    const defaultCartSelector: (state: { cart: CartState }) => ICart | null = (state: { cart: CartState }) =>
      state.cart.carts?.find((cart) => cart.isDefault) || null;
    const customerCart: ICart | null = yield select(defaultCartSelector);

    if (customerCart) {
      const result: ICart | null = yield call(sprykerClient(locale).cart.addItems, {
        cartId: customerCart?.id,
        items: payload,
      });
      if (result) {
        yield all([
          put(
            ToasterActions.add({
              type: 'success',
              title: i18n?.t('toast.addItems.success.title', { ns: 'cart' }) || '',
              bodytext: i18n?.t('toast.addItems.success.body', { ns: 'cart' }) || '',
            })
          ),
          put(CartActions.addItemsToCartSuccess(result)),
        ]);
      } else {
        yield all([
          put(
            ToasterActions.add({
              type: 'warning',
              title: i18n?.t('toast.addItems.error.title', { ns: 'cart' }) || '',
              bodytext: i18n?.t('toast.addItems.error.body', { ns: 'cart' }) || '',
            })
          ),
          put(CartActions.addItemsToCartFail('No result from addItems')),
        ]);
      }
    } else {
      yield all([
        put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.addItems.error.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addItems.error.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.addItemsToCartFail('No default cart found')),
      ]);
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.addItems.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.addItems.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.addItemsToCartFail(error)),
    ]);
  }
}

/**
 * The watcher function to delete an item in a cart from an authenticated user
 */
export function* watchDeleteCartItem() {
  yield takeEvery(CartActions.deleteItem, deleteItemWorker);
}

/**
 * The worker function to delete an item in a cart from an authenticated user
 * @param action
 */
export function* deleteItemWorker(action: PayloadAction<{ locale: string; payload: IDeleteItem }>) {
  try {
    const { locale, payload } = action.payload;

    yield call(sprykerClient(locale).cart.deleteItem, payload);

    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.deleteItem.success.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.deleteItem.success.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.cartsRequested({ locale })),
    ]);
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.deleteItem.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.deleteItem.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.deleteItemFailed(error)),
    ]);
  }
}

/**
 * The watcher function to change item quantity in a cart from an authenticated user
 */
export function* watchChangeItemQuantity() {
  yield takeEvery(CartActions.changeItemQuantity, changeItemQuantityWorker);
}

/**
 * The worker function to change item quantity in a cart from an authenticated user
 * @param action
 */
export function* changeItemQuantityWorker(action: PayloadAction<{ locale: string; payload: IChangeItemQuantity }>) {
  try {
    const { locale, payload } = action.payload;

    const result: ICart | null = yield call(sprykerClient(locale).cart.changeItemQuantity, payload);

    if (result) {
      yield put(CartActions.changeItemQuantitySuccess(result));
    } else {
      yield put(CartActions.changeItemQuantityFailed('No result from changeQuantity'));
    }
  } catch (error: any) {
    yield put(CartActions.changeItemQuantityFailed(error));
  }
}

/**
 * The watcher function to add voucher to customer cart
 */
export function* watchAddVoucher() {
  yield takeEvery(CartActions.addVoucher, addVoucherWorker);
}

/**
 * The worker function to add voucher to customer cart
 * @generator
 * @yields {all} An array of effects to dispatch on success, including a success toaster notification and a success action to update the cart state.
 * @throws {put} A warning toaster notification and an error action if the request fails.
 * @param action {PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string } }} The action containing the locale, cart ID and voucher code.
 */
export function* addVoucherWorker(
  action: PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string } }>
) {
  try {
    const { locale, payload } = action.payload;

    const result: ICart | null = yield call(sprykerClient(locale).cart.addVoucher, payload);

    if (result) {
      yield all([
        put(
          ToasterActions.add({
            type: 'success',
            title: i18n?.t('toast.addVoucher.success.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addVoucher.success.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.addVoucherSuccess(result)),
      ]);
    } else {
      yield all([
        put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.addVoucher.error.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addVoucher.error.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.addVoucherError('no result')),
      ]);
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.addVoucher.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.addVoucher.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.addVoucherError(error)),
    ]);
  }
}

/**
 * The watcher function to remove voucher from customer cart
 */
export function* watchDeleteVoucher() {
  yield takeEvery(CartActions.deleteVoucher, deleteVoucherWorker);
}

/**
 * The worker function to remove voucher from customer cart
 * @generator
 * @param {object} action - The action object containing locale and payload data.
 * @param {string} action.locale - The locale string.
 * @param {object} action.payload - The payload object containing cartId and voucherCode data.
 * @param {string} action.payload.cartId - The ID of the cart.
 * @param {string} action.payload.voucherCode - The code of the voucher to be deleted.
 * @throws {Error} If there is an error deleting the voucher.
 */
export function* deleteVoucherWorker(
  action: PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string } }>
) {
  try {
    const { locale, payload } = action.payload;

    yield call(sprykerClient(locale).cart.deleteVoucher, payload);

    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.deleteVoucher.success.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.deleteVoucher.success.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.deleteVoucherSuccess()),
      put(CartActions.cartsRequested({ locale })),
    ]);
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.deleteVoucher.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.deleteVoucher.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.deleteVoucherError(error)),
    ]);
  }
}

/**
 * The watcher function to add a custom order reference to a cart
 */
export function* watchAddCustomOrderReferenceToCart() {
  yield takeEvery(CartActions.addCustomOrderReference, addCustomOrderReferenceToCartAsync);
}

/**
 * The worker function to add a custom order reference to a cart
 * @param action
 */
export function* addCustomOrderReferenceToCartAsync(
  action: PayloadAction<{ locale: string; payload: { cartId: string; customOrderReference: string } }>
) {
  try {
    const { locale, payload } = action.payload;

    const result: ICart | null = yield call(sprykerClient(locale).cart.addCustomOrderReference, payload);

    if (result) {
      yield all([
        put(
          ToasterActions.add({
            type: 'success',
            title: i18n?.t('toast.addCustomOrderReference.success.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addCustomOrderReference.success.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.addCustomOrderReferenceSuccess(result)),
      ]);
    } else {
      yield all([
        put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.addCustomOrderReference.error.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addCustomOrderReference.error.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.addCustomOrderReferenceError('no result')),
      ]);
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.addCustomOrderReference.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.addCustomOrderReference.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.addCustomOrderReferenceError(error)),
    ]);
  }
}
