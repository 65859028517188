export * from './catalog-teaser-slider/catalog-teaser-slider';
export * from './contact-person/contact-person';
export * from './core-product-information/core-product-information';
export * from './core-product-information/core-product-information-interactive/core-product-information-interactive';
export * from './core-product-information/core-product-information-slider/core-product-information-slider';
export * from './core-product-information/core-product-information-video-item/core-product-information-video-item';
export * from './downloads/downloads';
export * from './facts-figures/facts-figures';
export * from './indications/indications';
export * from './keyfeatures/keyfeatures';
export * from './material-overview-table/material-overview-column/material-overview-column';
export * from './material-overview-table/material-overview-table';
export * from './product-comparison/product-comparison';
export * from './product-entry-filter/product-entry-filter';
export * from './product-variant-overview/product-variant-overview';
export * from './sticky-product-information/sticky-product-information';
export * from './technical-data-table/technical-data-item/technical-data-item';
export * from './technical-data-table/technical-data-table';
export * from './upselling/upselling';
export * from './video-slider/video-slider';
