import createSprykerClient, { SprykerClientAPI } from '@dxp/spryker-client';

export * from './components';
export * from './hooks';
export * from './interfaces';
export * from './redux';

// Spryker client initialization
const urlDe = process.env['NX_COMMERCE_BASE_URL_DE'] || '';
const urlInt = process.env['NX_COMMERCE_BASE_URL_INT'] || '';
const urlUs = process.env['NX_COMMERCE_BASE_URL_US'] || '';

interface ILocaleSprykerClient {
  id: string;
  client: SprykerClientAPI;
  locales: Array<string>;
}

export const sprykerClients: Array<ILocaleSprykerClient> = [
  {
    id: 'DE',
    client: createSprykerClient({ url: urlDe, axiosOptions: { headers: { 'x-client': 'dxp' } } }),
    locales: ['de-de'],
  },
  {
    id: 'INT',
    client: createSprykerClient({ url: urlInt, axiosOptions: { headers: { 'x-client': 'dxp' } } }),
    locales: ['en-gb'],
  },
  {
    id: 'US',
    client: createSprykerClient({ url: urlUs, axiosOptions: { headers: { 'x-client': 'dxp' } } }),
    locales: ['en-us'],
  },
];

export const sprykerClient: (locale: string | undefined) => SprykerClientAPI = (locale: string | undefined) => {
  const fallback = createSprykerClient({ url: urlInt, axiosOptions: { headers: { 'x-client': 'dxp' } } });
  return locale &&
    sprykerClients.some((iSprykerClient: ILocaleSprykerClient) => iSprykerClient.locales.some((l) => l === locale))
    ? sprykerClients.find((iSprykerClient: ILocaleSprykerClient) => iSprykerClient.locales.some((l) => l === locale))
        ?.client || fallback
    : fallback;
};
