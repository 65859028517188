import { IConcreteProductDataParsed } from '../src/interfaces/product';

export const concreteMockProduct: IConcreteProductDataParsed = {
  concreteProduct: {
    description: 'Transparenter Kunststoff für die Gieß- und Presstechnik',
    descriptionAttributes: [{ name: 'Material', value: 'Ceramill A-Cast' }],
    images: [
      {
        id: 0,
        src: 'https://agirrbach-prod-static-files.s3.eu-central-1.amazonaws.com/products/large/761075_0.png',
        srcSmall: 'https://agirrbach-prod-static-files.s3.eu-central-1.amazonaws.com/products/small/761075_0.png',
      },
    ],
    isAvailable: true,
    isNeverOutOfStock: false,
    isRealtime: false,
    name: 'Ceramill A-Cast',
    prices: {
      priceDefaultCurrency: { code: 'EUR', name: 'Euro', symbol: '€' },
      priceDefaultNet: 3200,
    },
    productType: 'concreteProduct',
    quantity: 6,
    sku: '761075',
  },
  superAttributes: null,
  selectedAttrNames: {},
  productLabels: [],
};
