import { ICart, ICartItem, ICartRule, IOrderItem, IVoucher } from '../../interfaces';
import { CartType, ICartsRawResponseItem, Included } from '../../interfaces/carts-raw';
import { EIncludeTypes, IProductRowPricesIncludedResponse } from '../../types';
import { parsePrices } from './common';

export const parseCartResponse = (
  data: Array<ICartsRawResponseItem> | ICartsRawResponseItem,
  included?: Array<Included>,
  guest?: boolean
): Array<ICart> | ICart | null => {
  const itemType: CartType.Items | CartType.GuestCardItems = guest ? CartType.GuestCardItems : CartType.Items;
  const includedItems: Array<Included> = (included || []).filter((includes) => includes.type === itemType);
  const includedConcreteProducts: Array<Included> = (included || []).filter(
    (includes) => includes.type === CartType.ConcreteProducts
  );
  const includedConcreteProductPrices = (included || []).filter(
    (includes) => includes.type === CartType.ConcreteProductPrice
  );
  const includedImageSets = (included || []).filter((includes) => includes.type === CartType.ConcreteProductImageSets);
  const includedVouchers: Array<IVoucher> = (included || []).filter(
    (includes) => includes.type === CartType.Vouchers
  ) as Array<IVoucher>;
  const includedCartRules: Array<ICartRule> = (included || []).filter(
    (includes) => includes.type === CartType.CartRules
  ) as Array<ICartRule>;

  if (Array.isArray(data)) {
    return data.map((rawCart) => {
      const cartItems: Array<ICartItem> = getCartItems(
        rawCart,
        includedItems,
        includedConcreteProducts,
        includedImageSets,
        includedConcreteProductPrices,
        itemType
      ) as Array<ICartItem>;

      return generateCart(rawCart, cartItems, includedVouchers, includedCartRules);
    });
  } else {
    const cartItems: Array<ICartItem> = getCartItems(
      data,
      includedItems,
      includedConcreteProducts,
      includedImageSets,
      includedConcreteProductPrices,
      itemType
    ) as Array<ICartItem>;

    return generateCart(data, cartItems, includedVouchers, includedCartRules);
  }
};

const getCartItems = (
  rawCart: ICartsRawResponseItem,
  includedItems: Array<Included>,
  includedConcreteProducts: Array<Included>,
  includedImageSets: Array<Included>,
  includedPrices: Array<Included>,
  itemType: CartType.Items | CartType.GuestCardItems
): Array<ICartItem | null> => {
  return rawCart.relationships &&
    rawCart.relationships[itemType] &&
    rawCart.relationships[itemType].data &&
    Array.isArray(rawCart.relationships[itemType].data)
    ? rawCart.relationships[itemType].data
        .map((cartItem) => {
          const item: Included | null = includedItems.find((includedItem) => includedItem.id === cartItem.id)
            ? includedItems.filter((includedItem) => includedItem.id === cartItem.id)[0]
            : null;

          if (item) {
            const concreteProduct = includedConcreteProducts.find((includedProduct) =>
              item?.relationships
                ? includedProduct.id === item.relationships[EIncludeTypes.CONCRETE_PRODUCTS]?.data[0]?.id
                : null
            );

            const imageSet = concreteProduct
              ? includedImageSets.find(
                  (includedImage) =>
                    concreteProduct?.relationships &&
                    includedImage.id ===
                      concreteProduct.relationships[EIncludeTypes.CONCRETE_PRODUCT_IMAGE_SETS]?.data[0]?.id
                )
              : null;

            const pricesInclude = includedPrices.find(
              (includedPrice) =>
                concreteProduct?.relationships &&
                includedPrice.id === concreteProduct.relationships[EIncludeTypes.CONCRETE_PRODUCT_PRICES]?.data[0]?.id
            ) as unknown as IProductRowPricesIncludedResponse | undefined;
            const prices = pricesInclude
              ? parsePrices(pricesInclude.attributes.allPrices, pricesInclude.attributes.price)
              : undefined;

            return {
              attributes: concreteProduct?.attributes.attributes,
              attributeNames: concreteProduct?.attributes.attributeNames,
              superAttributesDefinition: concreteProduct?.attributes.superAttributesDefinition || [],
              id: item.id,
              sku: item.attributes.sku,
              abstractSku: item.attributes.abstractSku || undefined,
              quantity: item.attributes.quantity || undefined,
              calculations: item.attributes.calculations || undefined,
              name: concreteProduct ? concreteProduct.attributes.name : undefined,
              prices,
              image:
                imageSet && imageSet?.attributes?.imageSets?.length
                  ? imageSet.attributes?.imageSets[0].images[0].externalUrlSmall
                  : undefined,
            };
          } else {
            return null;
          }
        })
        .filter((item: ICartItem | null) => !!item)
    : [];
};

const generateCart: (
  data: ICartsRawResponseItem,
  cartItems: Array<ICartItem>,
  includedVouchers: Array<IVoucher>,
  includedCartRules: Array<ICartRule>
) => ICart = (
  data: ICartsRawResponseItem,
  cartItems: Array<ICartItem>,
  includedVouchers: Array<IVoucher>,
  includedCartRules: Array<ICartRule>
) => {
  const cart: ICart = {
    id: data.id,
    type: data.type,
    cartRules: includedCartRules,
    currency: data.attributes.currency,
    discounts: data.attributes.discounts,
    vouchers: includedVouchers,
    priceMode: data.attributes.priceMode,
    store: data.attributes.store,
    totals: data.attributes.totals,
    isDefault: data.attributes.isDefault,
    name: data.attributes.name,
    items: cartItems,
    orderCustomReference: data.attributes.orderCustomReference,
  };
  return cart;
};

export const getCartItemSuperAttributes: (item: ICartItem | IOrderItem) => Array<{ label: string; value: string }> = (
  item: ICartItem | IOrderItem
) => {
  if ('superAttributesDefinition' in item) {
    return item.superAttributesDefinition?.map((superAttribute: string) => ({
      label: item?.attributeNames ? item?.attributeNames[superAttribute] : '',
      value: item?.attributes ? item?.attributes[superAttribute] : '',
    }));
  } else {
    const superAttributesArray = [];

    for (const key in item.metadata.superAttributes) {
      superAttributesArray.push({
        label: key,
        value: item.metadata.superAttributes[key],
      });
    }
    return superAttributesArray;
  }
};
