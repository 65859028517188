import styles from './toaster-stack.module.scss';
import { useSelector } from 'react-redux';
import ToasterMessage from '../toaster-message/toaster-message';
import ToasterMessageInterface from '../toasterMessageInterface';
import { ToasterStateInterface } from '../../../redux';
import { AnimatePresence } from 'framer-motion';

export const ToasterStack = () => {
  const stack = useSelector((state: ToasterStateInterface) => state.toaster.stack);

  return (
    <div className={styles['toaster-stack']}>
      <AnimatePresence initial={false}>
        {stack.map((toast: ToasterMessageInterface) => (
          <ToasterMessage
            key={toast.id ?? '--'}
            title={toast.title}
            bodytext={toast.bodytext}
            id={toast.id ?? '--'}
            type={toast.type}
            manualDismiss={toast.manualDismiss}
            autoDismissTime={toast.autoDismissTime}
          ></ToasterMessage>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToasterStack;
