import styles from './sidebar-button.module.scss';
import { Colors } from '../../../types';
import classNames from 'classnames';
import { cloneElement, ReactElement, useState } from 'react';
import { motion } from 'framer-motion';

export interface SidebarButtonProps {
  color: Colors.smartBlue | Colors.intenseBlue;
  variant: 'text' | 'icon';
  label?: string;
  icon?: ReactElement;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const transition = {
  duration: 1.25,
  type: 'spring',
  times: [0, 0.3, 0.3, 0.3, 0.4, 0.7, 1],
};

const variants = {
  initial: {
    x: 0,
    transition,
  },
  reverseX: {
    x: ['0px', '70px', '70px', '-70px', '-70px', '6px', '0px'],
    transition,
  },
  animateX: {
    x: ['0px', '-70px', '-70px', '70px', '70px', '-6px', '0px'],
    transition,
  },
  animateY: {
    y: ['0px', '0px', '180px', '180px', '0px', '0px', '0px'],
    transition,
  },
};

export const SidebarButton = (props: SidebarButtonProps & { className?: string }) => {
  const [isHovered, setHovered] = useState<boolean | null>(null);

  return (
    <button
      className={classNames(styles['inner'], props.className)}
      onClick={props.onClick}
      data-testid={'sidebar-button'}
      title={props.label}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={classNames(styles[`${props.variant}-area`], styles[props.color])}>
        {props.variant === 'text' ? (
          <motion.div
            animate={isHovered ? ['animateX', 'animateY'] : isHovered === null ? 'initial' : ['reverseX', 'animateY']}
            variants={variants}
            className={styles['text']}
          >
            {props.label}
          </motion.div>
        ) : (
          props.icon && (
            <motion.div
              animate={isHovered ? ['reverseX', 'animateY'] : isHovered === null ? 'initial' : ['animateX', 'animateY']}
              variants={variants}
              className={styles['icon']}
            >
              {cloneElement(props.icon, { color: Colors.brilliantBlue })}
            </motion.div>
          )
        )}
      </div>
    </button>
  );
};

export default SidebarButton;
