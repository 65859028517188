import classNames from 'classnames';
import styles from './slider-wrapper.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType, SwiperOptions } from 'swiper';
import { Colors, IconArrow, IconLink, RoundButton, ScreenSizes, TextButton, useScreenSizes } from '@dxp/veneer';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { SwiperBreakpoints } from '../../interfaces';
import { useTranslation } from 'next-i18next';

export interface SliderWrapperProps {
  headline: string;
  copy: string;
  children: React.ReactElement<typeof SwiperSlide>[];
  links?: Array<{
    code: string;
    label: string;
    assetUrl: string;
  }>;
  breakpoints?: SwiperBreakpoints;
  loop?: boolean;
  arrows?: boolean;
}

const breakpoints: { [width: number]: SwiperOptions; [ratio: string]: SwiperOptions } = {
  760: {
    spaceBetween: 20,
  },
  1020: {
    spaceBetween: 25,
  },
};

export const SliderWrapper = (props: SliderWrapperProps) => {
  const { t } = useTranslation('common');

  const [loop, setLoop] = useState(false);
  const [isBeginning, setIsBeginning] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const swiperRef: MutableRefObject<SwiperType | undefined> = useRef<SwiperType>();
  const screenSize = useScreenSizes();

  const children = useMemo(() => props.children, []);

  const renderLinks = props.links?.map(
    (
      link: {
        code: string;
        label: string;
        assetUrl: string;
      },
      index: number
    ) => {
      return (
        <li key={link.code}>
          <a
            className={styles['links__link']}
            href={link.assetUrl}
            target={'_blank'}
            rel={'noreferrer'}
            data-testid={'link-' + index}
          >
            <TextButton
              label={link.label}
              icon={<IconLink />}
              color={Colors.intenseBlue}
              size={screenSize === ScreenSizes.SMALL ? 'subtext' : 'normal'}
            />
          </a>
        </li>
      );
    }
  );

  useEffect(() => {
    const swiper = swiperRef.current;

    if (swiper) {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);

      // Deactivate loop if not enough slides
      if (props.loop) {
        if (swiper.params.slidesPerView && props.children.length <= swiper.params.slidesPerView) {
          setLoop(false);
        } else {
          setLoop(true);
        }
      }
    }
  }, [props.loop, props.children.length]);

  const mergeBreakpoints = (o1: SwiperBreakpoints, o2: SwiperBreakpoints): SwiperBreakpoints => {
    return Object.assign({}, ...Object.keys(o1).map((k) => ({ [k]: { ...o1[k], ...o2[k] } })));
  };

  const onPrevClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const onNextClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const onActiveIndexChange = (swiper: SwiperType) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  return (
    <div className={styles['grid']}>
      <h2 className={classNames('heading-4', styles['headline'])} data-testid={'headline'}>
        {props.headline}
      </h2>
      <div className={styles['intro']}>
        <p className={classNames('text-long', styles['text'])} data-testid={'copy'}>
          {props.copy}
        </p>
        {props.arrows && props.children.length > 1 && screenSize === ScreenSizes.LARGE && (
          <div className={styles['controls']}>
            <RoundButton
              disabled={isBeginning}
              onClick={onPrevClick}
              icon={<IconArrow rotation={90} />}
              variant={Colors.brilliantBlue}
              size={'medium'}
              title={t('previous')}
            />
            <RoundButton
              disabled={isEnd}
              onClick={onNextClick}
              icon={<IconArrow rotation={270} />}
              variant={Colors.brilliantBlue}
              size={'medium'}
              title={t('next')}
            />
          </div>
        )}
      </div>
      <div className={classNames(styles['misc'])}>
        {props.links?.length ? <ul className={styles['links']}>{renderLinks}</ul> : null}
      </div>
      <div className={classNames(styles['slider'])}>
        {!!props.children.length && (
          <Swiper
            slidesPerView={1.25}
            spaceBetween={13}
            loop={loop}
            breakpoints={props.breakpoints ? mergeBreakpoints(props.breakpoints, breakpoints) : breakpoints}
            onBeforeInit={(swiper) => (swiperRef.current = swiper)}
            threshold={10}
            onActiveIndexChange={onActiveIndexChange}
          >
            {children}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default SliderWrapper;
