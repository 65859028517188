import styles from './filter-button.module.scss';
import classNames from 'classnames';

export interface FilterButtonProps {
  imageUrl?: string;
  active: boolean;
  title: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FilterButton = (props: FilterButtonProps & { className?: string }) => {
  return (
    <button className={classNames(styles['container'], props.className)} onClick={props.onClick}>
      <span className={styles['image-container']}>
        <img src={props.imageUrl} className={styles['image']} alt={props.title} data-testid="image" />
        <span
          className={classNames(styles['indicator'], props.active ? styles['indicator--active'] : null)}
          data-testid="indicator"
        ></span>
      </span>
      <span className={styles['title']} data-testid="title">
        {props.title}
      </span>
    </button>
  );
};

export default FilterButton;
