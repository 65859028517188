import { AutocompleteQueryConfig, QueryConfig, RequestState, SearchState } from '@elastic/search-ui';
import { AutocompleteResponseState } from '@elastic/search-ui/lib/esm/types';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PlatformWebappPageSize } from '../../constants';
import { SearchActions } from './slice';
import { ResultClickParams } from './types';

/**
 * The watcher function for instant search.
 */
export function* watchSearch() {
  yield takeEvery(SearchActions.search, searchAsync);
}

/**
 * The worker function for instant search.
 */
export function* searchAsync(
  action: PayloadAction<{ requestState: RequestState; locale?: string; addResults: boolean }>
) {
  let { requestState } = action.payload;
  const { locale, addResults } = action.payload;

  if (!requestState.resultsPerPage) {
    requestState.resultsPerPage = PlatformWebappPageSize;
  }

  if (!requestState.current) {
    requestState.current = 1;
  }

  if (locale) {
    requestState = {
      ...requestState,
      filters: [
        {
          field: 'url_path_dir1',
          type: 'all',
          values: [locale],
        },
      ],
    };
  }

  const queryConfig: QueryConfig = {
    search_fields: { title: {}, body_content: {}, product_sku: {} },
    result_fields: {
      title: { raw: {} },
      url: { raw: {} },
      url_path: { raw: {} },
      body_content: {
        snippet: {
          size: 250,
          fallback: true,
        },
      },
    },
  };

  try {
    const response: AxiosResponse<SearchState> = yield call(axios.post, '/api/search', { requestState, queryConfig });

    if (response?.data) {
      yield put(
        SearchActions.searchSuccess({
          ...response.data,
          resultSearchTerm: action.payload.requestState.searchTerm || '',
          addResults,
        })
      );
    } else {
      yield put(SearchActions.searchError('No response data'));
    }
  } catch (error: any) {
    console.log('Error on searchAsync worker: ', error);
    yield put(SearchActions.searchError(error));
  }
}

/**
 * The watcher function for autocomplete.
 */
export function* watchAutocomplete() {
  yield takeEvery(SearchActions.autocomplete, autocompleteAsync);
}

/**
 * The worker function for autocomplete.
 */
export function* autocompleteAsync(action: PayloadAction<{ requestState: RequestState; locale?: string }>) {
  let { requestState } = action.payload;
  const { locale } = action.payload;

  if (!requestState.resultsPerPage) {
    requestState.resultsPerPage = 10;
  }

  if (!requestState.current) {
    requestState.current = 1;
  }

  if (locale) {
    requestState = {
      ...requestState,
      filters: [
        {
          field: 'url_path_dir1',
          type: 'all',
          values: [locale],
        },
      ],
    };
  }

  const queryConfig: AutocompleteQueryConfig = {
    results: {
      search_fields: {
        title: {},
      },
    },
    suggestions: {
      types: {
        documents: {
          fields: ['title'],
        },
      },
      size: 10,
    },
  };

  try {
    const response: AxiosResponse<AutocompleteResponseState> = yield call(axios.post, '/api/autocomplete', {
      requestState,
      queryConfig,
    });

    if (response?.data) {
      yield put(SearchActions.autocompleteSuccess({ ...response.data }));
    } else {
      yield put(SearchActions.autocompleteError('No response data for autocomplete'));
    }
  } catch (error: any) {
    console.log('Error on autocompleteAsync worker: ', error);
    yield put(SearchActions.autocompleteError(error));
  }
}

/**
 * The watcher function for result clicks.
 */
export function* watchResultClick() {
  yield takeEvery(SearchActions.resultClick, resultClickAsync);
}

/**
 * The worker function for result clicks.
 */
export function* resultClickAsync(
  action: PayloadAction<{ resultClickParams: ResultClickParams; autocomplete?: boolean }>
) {
  const { resultClickParams, autocomplete } = action.payload;

  try {
    const response: AxiosResponse<void> = yield call(axios.post, '/api/result-click', {
      resultClickParams,
      autocomplete: !!autocomplete,
    });

    if (response) {
      yield put(SearchActions.resultClickSuccess());
    } else {
      yield put(SearchActions.resultClickError('No response for result click'));
    }
  } catch (error: any) {
    console.log('Error on result click worker: ', error);
    yield put(SearchActions.resultClickError(error));
  }
}
