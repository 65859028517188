import styles from './technical-data-table.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import TechnicalDataItem from './technical-data-item/technical-data-item';
import { IconArrow, RoundButton, useScreenSizes, VeneerAnimation } from '@dxp/veneer';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ITechnicalData } from '../../interfaces';

export interface TechnicalDataTableProps {
  items: Array<ITechnicalData>;
}

export const TechnicalDataTable = (props: TechnicalDataTableProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const screenSize = useScreenSizes();
  const initiallyVisibleItems = screenSize === 2 ? 12 : screenSize === 1 ? 10 : 8;

  return Array.isArray(props.items) && props.items.length ? (
    <motion.div
      data-elastic-include={true}
      className={styles['container']}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
    >
      <h2 className={classNames('heading-4', styles['headline'])} data-testid="headline">
        {t('product.detail.technicalDataTable')}
      </h2>
      <div className={styles['table']}>
        {props.items.slice(0, initiallyVisibleItems).map((item) => (
          <div className={styles['item']} key={item.code}>
            <TechnicalDataItem value={item.data} />
          </div>
        ))}

        <AnimatePresence initial={false}>
          {showAll &&
            props.items.slice(initiallyVisibleItems).map((item) => (
              <motion.div
                key={item.code}
                initial="collapsed"
                animate="open"
                exit="collapsed"
                className={styles['item']}
                variants={{
                  open: { height: 'auto' },
                  collapsed: { height: 0 },
                }}
                transition={{ duration: 1, ease: VeneerAnimation.easings.inOutCubic }}
              >
                <TechnicalDataItem value={item.data} />
              </motion.div>
            ))}
        </AnimatePresence>
      </div>
      {initiallyVisibleItems < props.items.length && (
        <div className={styles['show-all']} onClick={() => setShowAll(!showAll)}>
          <RoundButton
            variant={'outline'}
            icon={<IconArrow rotation={showAll ? 180 : 0} />}
            fillBackgroundOnHover={true}
            className={styles['show-all__icon']}
          />
          <div className={styles['show-all__label']}>{showAll ? t('showLess') : t('showAll')}</div>
        </div>
      )}
    </motion.div>
  ) : null;
};

export default TechnicalDataTable;
