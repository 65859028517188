import { useTranslation } from 'next-i18next';
import styles from './cart-item-partial-information.module.scss';
import { getCartItemSuperAttributes, ICartItem, IOrderItem } from '@dxp/spryker-client';
import { useMemo } from 'react';
import classNames from 'classnames';
import { DeliveryState, DeliveryStatus } from '@dxp/veneer';
interface CartItemPartialInformationProps {
  showSKU?: boolean;
  className?: string;
  showQuantity?: boolean;
  item: ICartItem | IOrderItem;
  deliveryState?: DeliveryState;
  showDeliveryInformation?: boolean;
}

export function CartItemPartialInformation({
  item,
  className,
  showSKU,
  showQuantity,
  showDeliveryInformation,
  deliveryState,
}: CartItemPartialInformationProps) {
  const { t } = useTranslation('cart');

  const description = useMemo(
    () =>
      getCartItemSuperAttributes(item)
        ? getCartItemSuperAttributes(item)
            .map((item: { label: string; value: string }) => item.value)
            .join(', ')
        : '',
    [item]
  );

  return (
    <div className={classNames(styles['information'], className)}>
      <div>
        <div className={styles['information__title']}>{item.name}</div>
        <div className={styles['information__description']}>{description}</div>
        {showQuantity && (
          <div className={styles['information__quantity']}>
            {t('count')}: {item.quantity}
          </div>
        )}
        {showSKU && (
          <div className={styles['information__sku']}>
            {t('sku')}: {item.sku}
          </div>
        )}
      </div>

      {showDeliveryInformation && deliveryState && (
        <div className={styles['information__delivery']}>
          <DeliveryStatus deliveryState={deliveryState} />
        </div>
      )}
    </div>
  );
}

export default CartItemPartialInformation;
