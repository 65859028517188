import { Colors, HubspotForm } from '@dxp/veneer';

interface Props {
  title: string;
  portalId?: string;
  formId: string;
  region: string;
  locale: string;
}

export const CEHubspotForm = (props: Props) => {
  const { title, locale, ...formProps } = props;

  return <HubspotForm {...formProps} buttonColor={Colors.brilliantBlue} mode="dark" buttonPosition="left" />;
};

export default CEHubspotForm;
