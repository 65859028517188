import { useEffect, useState } from 'react';

export enum ScreenSizes {
  SMALL,
  MEDIUM,
  LARGE,
}

export const useScreenSizes = (): ScreenSizes => {
  const [currentSize, setCurrentSize] = useState(ScreenSizes.SMALL);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1019) {
        setCurrentSize(ScreenSizes.LARGE);
      } else if (window.innerWidth > 759) {
        setCurrentSize(ScreenSizes.MEDIUM);
      } else {
        setCurrentSize(ScreenSizes.SMALL);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return currentSize;
};
