import { createClient, Entry, EntryCollection } from 'contentful';

import { PlatformWebappPageSize, toLowercaseLocale, toUppercaseLocale } from '@dxp/core';
import {
  EPageContentTypes,
  PageEntry,
  TypeArticleCategoryFields,
  TypeArticleFields,
  TypeCeVideoFields,
  TypeEventFields,
  TypeFaqFields,
  TypeFaqTopicFields,
  TypeNavigationFields,
  TypePageConfigFields,
  TypePageFields,
  TypeSharedTranslationFields,
  TypeVacancyFields,
} from '../types';

const space = process.env['NX_CONTENT_SPACE_ID'] || '';
const accessToken = process.env['NX_CONTENT_DELIVERY_TOKEN'] || '';
const previewToken = process.env['NX_CONTENT_PREVIEW_TOKEN'] || '';
const versionTag: string = process.env['NX_APP_VERSION_TAG'] || 'develop';
const environment = new RegExp(['develop', 'alpha'].join('|')).test(versionTag) ? 'develop' : 'master';

const deliveryClient = createClient({
  space,
  accessToken,
  environment,
});

const previewClient = createClient({
  space,
  accessToken: previewToken,
  host: 'preview.contentful.com',
  environment,
});

const client = (preview?: boolean) => (preview ? previewClient : deliveryClient);

export type GetPageParams<T extends EPageContentTypes> = {
  slug: string;
  locale: string;
  preview?: boolean;
  content_type?: T;
};

const getPage = async <T extends EPageContentTypes = EPageContentTypes.PAGE>(
  params: GetPageParams<T>
): Promise<Entry<PageEntry<T>> | null> => {
  const query = {
    limit: 1,
    include: 10,
    locale: toUppercaseLocale(params.locale),
    'fields.slug': params.slug || '/',
    content_type: params.content_type || 'page',
  };

  const result = await client(!!params.preview)
    .getEntries(query)
    .catch((e) => (process.env.NODE_ENV === 'development' ? console.log('error getting page', e) : null));

  return result?.items ? (result.items[0] as Entry<PageEntry<T>>) : null;
};

const getTranslations = async (): Promise<EntryCollection<TypeSharedTranslationFields> | null> => {
  const items = await client().getEntries<TypeSharedTranslationFields>({
    include: 10,
    content_type: 'sharedTranslation',
  });

  return items || null;
};

const getAllPageSlugs = async (locale: string, contentType = 'page'): Promise<Array<string>> => {
  const query = {
    locale: toUppercaseLocale(locale),
    content_type: contentType,
  };

  const result = await client()
    .getEntries<TypePageFields>(query)
    .catch((e) => (process.env.NODE_ENV === 'development' ? console.log('error getting page slugs', e) : null));

  return result?.items.length
    ? result.items.filter((page) => !!page.fields.slug && page.fields.slug !== '').map((page) => page.fields.slug)
    : [];
};

const getAllArticleSlugs = async (locale: string): Promise<Array<string>> => {
  const query = {
    locale: toUppercaseLocale(locale),
    content_type: 'article',
  };

  const result = await client()
    .getEntries<TypeArticleFields>(query)
    .catch((e) => (process.env.NODE_ENV === 'development' ? console.log('error getting article slugs', e) : null));

  return result?.items.length
    ? result.items
        .filter((article) => !!article.fields.slug && article.fields.slug !== '')
        .map((article) => article.fields.slug)
    : [];
};

const getAllPageSlugsWithUpdated = async (locale: string): Promise<Array<{ slug: string; updatedAt: string }>> => {
  const query = {
    locale: toUppercaseLocale(locale),
    content_type: 'page',
    limit: 1000,
  };

  const result = await client()
    .getEntries<TypePageFields>(query)
    .catch((e) => (process.env.NODE_ENV === 'development' ? console.log('error getting page slugs', e) : null));

  return result?.items.length
    ? result.items
        .filter((page) => !!page.fields.slug && page.fields.slug !== '')
        .map((page) => ({ slug: page.fields.slug, updatedAt: page.sys.updatedAt }))
    : [];
};

const getAllArticleSlugsWithUpdated = async (locale: string): Promise<Array<{ slug: string; updatedAt: string }>> => {
  const query = {
    locale: toUppercaseLocale(locale),
    content_type: 'article',
    limit: 1000,
  };

  const result = await client()
    .getEntries<TypeArticleFields>(query)
    .catch((e) => (process.env.NODE_ENV === 'development' ? console.log('error getting article slugs', e) : null));

  return result?.items.length
    ? result.items
        .filter((article) => !!article.fields.slug && article.fields.slug !== '')
        .map((article) => ({ slug: article.fields.slug, updatedAt: article.sys.updatedAt }))
    : [];
};

const getAllNavigations = async (locale: string): Promise<EntryCollection<TypeNavigationFields> | null> => {
  const items = await client().getEntries<TypeNavigationFields>({
    include: 10,
    content_type: 'navigation',
    locale: toUppercaseLocale(locale),
  });

  return items || null;
};

const getEvents = async (locale: string): Promise<EntryCollection<TypeEventFields> | null> => {
  const items = await client().getEntries<TypeEventFields>({
    include: 10,
    content_type: 'event',
    locale: toUppercaseLocale(locale),
  });

  return items || null;
};

const getVacancies = async (locale: string): Promise<EntryCollection<TypeVacancyFields> | null> => {
  const items = await client().getEntries<TypeVacancyFields>({
    include: 10,
    content_type: 'vacancy',
    locale: toUppercaseLocale(locale),
  });

  return items || null;
};

const getVideos = async (locale: string): Promise<EntryCollection<TypeCeVideoFields> | null> => {
  const items = await client().getEntries<TypeCeVideoFields>({
    include: 10,
    content_type: 'ceVideo',
    locale: toUppercaseLocale(locale),
  });

  return items || null;
};

const getPageConfig = async (locale: string): Promise<Entry<TypePageConfigFields> | null> => {
  const items = await client().getEntries<TypePageConfigFields>({
    include: 10,
    content_type: 'pageConfig',
    locale: toUppercaseLocale(locale),
  });

  return items.items.length ? items.items[0] : null;
};

const getLocalizedSlugs = async (
  id: string,
  content_type: EPageContentTypes | 'article',
  translatedPrefixPath: { [locale: string]: string } = {}
): Promise<{ [locale: string]: string } | null> => {
  const query = {
    limit: 1,
    include: 10,
    locale: '*',
    'sys.id': id,
    content_type,
  };

  const result = await client(false)
    .getEntries<TypePageFields | TypeArticleFields>(query)
    .catch((e) => (process.env.NODE_ENV === 'development' ? console.log(`error getting ${content_type}`, e) : null));

  const slugs = result?.items[0].fields.slug as unknown as { [locale: string]: string };

  Object.entries(slugs).forEach(
    ([locale, slug]: [locale: string, slug: string]) =>
      (slugs[locale] = translatedPrefixPath[locale] ? translatedPrefixPath[locale] + slug : slug)
  );

  return slugs !== null
    ? Object.entries(slugs).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [toLowercaseLocale(key)]: (value === '/'
            ? `/${toLowercaseLocale(key)}`
            : `/${toLowercaseLocale(key)}/${value}`
          ).replace('//', '/'),
        }),
        {}
      )
    : null;
};

export type GetArticleParams = {
  slug: string;
  locale: string;
  preview?: boolean;
};

const getArticle = async (params: GetArticleParams): Promise<Entry<TypeArticleFields> | null> => {
  const query = {
    limit: 1,
    include: 10,
    locale: toUppercaseLocale(params.locale),
    'fields.slug': params.slug || '/',
    content_type: 'article',
  };

  const result = await client(!!params.preview)
    .getEntries(query)
    .catch((e) => (process.env.NODE_ENV === 'development' ? console.log('error getting article', e) : null));

  return result?.items ? (result.items[0] as Entry<TypeArticleFields>) : null;
};

interface GetArticlesQuery {
  'fields.categories.sys.id[in]'?: string;
  'sys.id[nin]'?: string;
  content_type: string;
  include: number;
  limit: number;
  locale: string;
  order: string;
  skip: number;
}

/**
 * TODO: write proper comment
 * @param locale
 * @param page
 * @param pageSize
 * @param category
 * @param excludeId
 */
const getArticles = async (
  locale: string,
  page = 1,
  pageSize = PlatformWebappPageSize,
  category?: string,
  excludeId?: string
): Promise<EntryCollection<TypeArticleFields> | null> => {
  let query: GetArticlesQuery = {
    order: '-fields.articlePublishDate',
    include: 10,
    limit: pageSize,
    skip: (page - 1) * pageSize,
    content_type: 'article',
    locale: toUppercaseLocale(locale),
  };

  if (category) {
    query = { ...query, 'fields.categories.sys.id[in]': category };
  }

  if (excludeId) {
    query = { ...query, 'sys.id[nin]': excludeId };
  }

  const items = await client().getEntries<TypeArticleFields>(query);

  return items || null;
};

const getArticleCategories = async (locale: string): Promise<EntryCollection<TypeArticleCategoryFields> | null> => {
  const items = await client().getEntries<TypeArticleCategoryFields>({
    include: 10,
    content_type: 'articleCategory',
    locale: toUppercaseLocale(locale),
  });

  return items || null;
};

const getPinnedArticles = async (locale: string): Promise<EntryCollection<TypeArticleFields> | null> => {
  const items = await client().getEntries<TypeArticleFields>({
    include: 10,
    limit: 3,
    'fields.pinned': true,
    content_type: 'article',
    locale: toUppercaseLocale(locale),
    order: '-fields.articlePublishDate',
  });

  return items || null;
};

const getLatestArticles = async (locale: string): Promise<EntryCollection<TypeArticleFields> | null> => {
  const items = await client().getEntries<TypeArticleFields>({
    include: 10,
    limit: 4,
    content_type: 'article',
    locale: toUppercaseLocale(locale),
    order: '-fields.articlePublishDate',
  });

  return items || null;
};

const getFaqQuestions = async (locale: string): Promise<EntryCollection<TypeFaqFields> | null> => {
  const items = await client().getEntries<TypeFaqFields>({
    include: 10,
    content_type: 'faq',
    locale: toUppercaseLocale(locale),
  });

  return items || null;
};

const getFaqTopics = async (locale: string): Promise<EntryCollection<TypeFaqTopicFields> | null> => {
  const items = await client().getEntries<TypeFaqTopicFields>({
    include: 10,
    content_type: 'faqTopic',
    locale: toUppercaseLocale(locale),
  });

  return items || null;
};

const getEntriesByType = async <T>(type: string, locale: string, limit = 10): Promise<EntryCollection<T> | null> => {
  const items = await client().getEntries<T>({
    include: 10,
    limit,
    content_type: type,
    locale: toUppercaseLocale(locale),
  });

  return items || null;
};

const getTrainers = async <T>({
  locale,
  names,
  type = 'trainer',
  limit = 100,
}: {
  locale: string;
  names: string[];
  type?: string;
  limit?: number;
}): Promise<EntryCollection<T> | null> => {
  const items = await client().getEntries<T>({
    include: 10,
    limit,
    content_type: type,
    locale: toUppercaseLocale(locale),
    'fields.name[in]': names.join(','),
  });

  return items || null;
};

export const contentful = {
  getPage,
  getTranslations,
  getAllPageSlugs,
  getAllPageSlugsWithUpdated,
  getAllArticleSlugs,
  getAllArticleSlugsWithUpdated,
  getAllNavigations,
  getEvents,
  getVacancies,
  getLocalizedSlugs,
  getPageConfig,
  getArticle,
  getArticles,
  getArticleCategories,
  getPinnedArticles,
  getLatestArticles,
  getFaqQuestions,
  getFaqTopics,
  getEntriesByType,
  getVideos,
  getTrainers,
};
