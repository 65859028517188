import { call, put, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { OrderPaymentActions } from './slice';
import { sprykerClient } from '../../';
import { IPaymentDataRequest, IPaymentDataResponseParsed } from '@dxp/spryker-client';

/**
 * The watcher function to add payment info to an order from an authenticated user
 */
export function* watchFetchOrderPayment() {
  yield takeEvery(OrderPaymentActions.orderPaymentRequested, fetchOrderPaymentAsync);
}

/**
 * The worker function to add payment info to an order from an authenticated user
 * @param action
 */
export function* fetchOrderPaymentAsync(action: PayloadAction<{ locale: string; payload: IPaymentDataRequest }>) {
  try {
    const { locale, payload } = action.payload;

    const paymentDataResponse: IPaymentDataResponseParsed | null = yield call(
      sprykerClient(locale).order.updateOrderPaymentAuthorized,
      { locale: locale, payload: payload }
    );

    if (paymentDataResponse) {
      yield put(OrderPaymentActions.orderPaymentRequestSuccess(paymentDataResponse));
    } else {
      yield put(OrderPaymentActions.orderPaymentRequestFailed('No result when requesting order payment'));
    }
  } catch (error: any) {
    yield put(OrderPaymentActions.orderPaymentRequestFailed(error));
  }
}
