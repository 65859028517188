import styles from './news-meta.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { Pill } from '@dxp/veneer';
import Link from 'next/link';
import { PlatformWebappStaticRoutes } from '@dxp/core';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import Image from 'next/image';

interface NewsContactProps {
  author: any; // TODO Typing
  articlePublishDate: string;
  categories: Array<any>; // TODO typing
  className?: string;
}

export function NewsMeta({ author, articlePublishDate, categories, className }: NewsContactProps) {
  const { t } = useTranslation('news');
  const { locale } = useRouter();

  const publishDate = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(
        new Date(articlePublishDate)
      ),
    [locale, articlePublishDate]
  );

  return (
    <div className={classNames(styles['meta'], className)}>
      <div className={styles['meta__fragment']}>
        {author && (
          <div className={classNames(styles['meta__author'], styles['author'])}>
            <div className={styles['author__image-box']}>
              {!!author.fields.imageUrl && author.fields.imageUrl !== '' && (
                <Image
                  width={98}
                  height={98}
                  src={author.fields.imageUrl}
                  alt={author.fields.firstName + ' ' + author.fields.lastName}
                  className={styles['author__image']}
                />
              )}
            </div>
            <div className={styles['author__info']}>
              <div className={classNames(styles['author__label'], styles['meta__label'])}>
                {t('news.detail.header.author')}
              </div>
              <div className={classNames(styles['author'], styles['meta__value'])}>
                {author.fields.firstName + ' ' + author.fields.lastName}
              </div>
            </div>
          </div>
        )}
        <div className={styles['meta__date']}>
          <div className={styles['meta__label']}>{t('news.detail.header.date')}</div>
          <time dateTime={articlePublishDate} className={styles['meta__value']}>
            {publishDate}
          </time>
        </div>
      </div>
      <div className={styles['meta__fragment']}>
        {categories.length && (
          <div className={styles['meta__categories']}>
            {categories.map((category) => (
              <Link
                key={category.fields.slug}
                href={{
                  pathname: PlatformWebappStaticRoutes.general.news,
                  query: { category: category.fields.slug },
                }}
              >
                <Pill label={category.fields.title} size={'standard'} />
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default NewsMeta;
