import styles from './locale-switch.module.scss';
import { useTranslation } from 'next-i18next';
import { LanguageMenu, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import i18nConfig from '../../next-i18next.config';
import { MainNavigationActions, useAppDispatch } from '../../redux';
import React from 'react';
import { motion } from 'framer-motion';

export const LocaleSwitch = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const locales: Array<string> = i18nConfig.i18n.locales.filter((locale) => locale !== 'default');

  return (
    <motion.div whileHover={VeneerAnimation.uiScaleBig} transition={VeneerAnimation.uiScaleTransition}>
      <LanguageMenu
        className={classNames(styles['language-menu'], className)}
        disabled={locales.length === 0}
        onClick={() => dispatch(MainNavigationActions.openLocaleOverlay())}
        locale={i18n.language}
      />
    </motion.div>
  );
};

export default React.memo(LocaleSwitch);
