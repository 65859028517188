import styles from './material-overview-table.module.scss';
import { IMaterialColumn } from '../../interfaces';
import classNames from 'classnames';
import MaterialOverviewColumn from './material-overview-column/material-overview-column';
import { ScreenSizes, useScreenSizes, VeneerAnimation } from '@dxp/veneer';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';

export interface MaterialOverviewTableProps {
  headline: string;
  columns: Array<IMaterialColumn>;
}

export const MaterialOverviewTable = (props: MaterialOverviewTableProps) => {
  const screenSize = useScreenSizes();

  const swiperProps: SwiperProps = {
    loop: false,
    slidesPerView: 1.25,
    spaceBetween: 13,
    slidesOffsetAfter: 23,
    breakpoints: {
      760: {
        slidesPerView: 2.25,
        spaceBetween: 20,
        slidesOffsetAfter: 37,
      },
    },
  };

  return props.columns.length > 0 ? (
    <div className={styles['container']}>
      <motion.div
        initial={VeneerAnimation.elementEntry.initial}
        whileInView={VeneerAnimation.elementEntry.whileInView}
        viewport={{ once: true }}
        transition={VeneerAnimation.elementEntry.transition}
        className={styles['grid']}
      >
        <div className={classNames('heading-2', styles['headline'])} data-testid="materialOverviewHeadline">
          {props.headline}
        </div>
        {!!props.columns?.length &&
          (screenSize !== ScreenSizes.LARGE ? (
            <div className={styles['slider']}>
              <Swiper {...swiperProps}>
                {props.columns.map((col: IMaterialColumn) => (
                  <SwiperSlide key={col.categoryCode}>
                    <MaterialOverviewColumn column={col} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (
            <div className={styles['table']}>
              {props.columns.map((col: IMaterialColumn) => (
                <MaterialOverviewColumn column={col} className={styles['table__column']} key={col.categoryCode} />
              ))}
            </div>
          ))}
      </motion.div>
    </div>
  ) : null;
};

export default MaterialOverviewTable;
