import { ICustomerDataParsed, ICustomerRawResponse } from '../../interfaces';

export const parseCustomerDataResponse = (response: ICustomerRawResponse): ICustomerDataParsed | null => {
  if (!response) {
    return null;
  }

  const {
    data: { attributes, id },
  } = response;

  return { ...attributes, id };
};
