import styles from './sticky-product-information.module.scss';
import {
  ConcreteProductActions,
  ConcreteProductSelectors,
  ConcreteProductState,
  PriceInformation,
} from '@dxp/commerce';
import { useTranslation } from 'react-i18next';
import { CtaArea } from '../../components';
import { motion } from 'framer-motion';
import { Colors, IconArrow, RoundButton, VeneerAnimation, Price } from '@dxp/veneer';
import { IConcreteProductDataParsed } from '@dxp/spryker-client';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export interface StickyProductInformationProps {
  name: string;
  sku?: string;
  callToAction: Array<string>;
  locale: string;
  ctaContactLink?: string;
}

export const StickyProductInformation = (props: StickyProductInformationProps) => {
  const { t } = useTranslation('common');

  const dispatch = useDispatch();

  const concreteProductDataParsed: IConcreteProductDataParsed | undefined = useSelector(
    (state: { concreteProduct: ConcreteProductState }) =>
      ConcreteProductSelectors.selectConcreteProduct(state.concreteProduct, props.sku)
  );

  useEffect(() => {
    if (props.sku) {
      dispatch(ConcreteProductActions.concreteProductRequested({ sku: props.sku, locale: props.locale }));
    }
  }, [props.sku]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.div
      initial={{ y: '-100%' }}
      animate={{ y: 0 }}
      exit={{ y: '-100%' }}
      transition={{ duration: 1, ease: VeneerAnimation.easings.inOutCubic }}
      className={styles['container']}
    >
      <div className={styles['inner']}>
        <div className={styles['product-information']}>
          <div className={styles['product-information__name']} data-testid={'product-name'}>
            {props.name}
          </div>
          <div className={styles['product-information__sku']}>
            {t('product.sku')}: <span data-testid={'product-sku'}>{props.sku}</span>
          </div>
        </div>
        <div className={styles['commerce']}>
          <PriceInformation
            data-testid={'price'}
            concreteProduct={concreteProductDataParsed?.concreteProduct}
            className={styles['commerce__price']}
          >
            <Price vat={false} layout={'horizontal'} />
          </PriceInformation>
          <RoundButton
            icon={<IconArrow rotation={180} />}
            variant={Colors.brilliantBlue}
            className={styles['commerce__to-top']}
            onClick={() => scrollToTop()}
          />
          <CtaArea
            callToAction={props.callToAction}
            hideWishlist={true}
            className={styles['commerce__cta']}
            contactLink={props.ctaContactLink}
            locale={props.locale || ''}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default StickyProductInformation;
