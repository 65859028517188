import styles from './two-big-product-teaser-boxes.module.scss';
import * as Contentful from 'contentful';
import { useSelector } from 'react-redux';
import { CatalogSelectors, CatalogState, IPdp, IProductImage, pdpLink } from '@dxp/catalog';
import { motion } from 'framer-motion';
import { Colors, IconButton, IconHeart, VeneerAnimation } from '@dxp/veneer';
import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next-translate-routes/link';
import { PlatformWebappFeatureFlags } from '@dxp/core';

export interface TwoBigProductTeaserBoxesProps {
  leftProduct: Contentful.EntryFields.Object;
  rightProduct: Contentful.EntryFields.Object;
  locale: string;
}

export function TwoBigProductTeaserBoxes(props: TwoBigProductTeaserBoxesProps) {
  const { leftProduct, leftProductImage, rightProduct, rightProductImage } = useSelector(
    (state: { catalog: CatalogState }) => CatalogSelectors.selectTwoCeProducts(state.catalog, props)
  );

  const renderProduct = (pdp: IPdp, image?: IProductImage) => (
    // TODO: clarify where the tags (see design) come from and implement them
    <div className={styles['item']}>
      <div className={styles['item__image']}>
        {image?.imageUrl && (
          <div className={styles['item__image__inner']}>
            <Image src={image.imageUrl} alt={pdp.name} width={550} height={440} />
          </div>
        )}
      </div>
      <div className={styles['item__bottom']}>
        <div className={classNames(styles['item__title'], 'text-short-medium')}>{pdp.name}</div>
        {PlatformWebappFeatureFlags.wishlistButton && (
          <IconButton
            className={styles['item__wishlist']}
            icon={<IconHeart filled={false} color={Colors.intenseBlue} />}
          />
        )}
      </div>
    </div>
  );

  return (
    <motion.div
      className={styles['container']}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      data-testid="two-big-product-teaser-boxes-container"
    >
      {leftProduct &&
        (pdpLink(leftProduct.slug, leftProduct.categories) ? (
          <Link href={pdpLink(leftProduct.slug, leftProduct.categories)}>
            {renderProduct(leftProduct, leftProductImage)}
          </Link>
        ) : (
          renderProduct(leftProduct, leftProductImage)
        ))}
      {rightProduct &&
        (pdpLink(rightProduct.slug, rightProduct.categories) ? (
          <Link href={pdpLink(rightProduct.slug, rightProduct.categories)}>
            {renderProduct(rightProduct, rightProductImage)}
          </Link>
        ) : (
          renderProduct(rightProduct, rightProductImage)
        ))}
    </motion.div>
  );
}

export default TwoBigProductTeaserBoxes;
