import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export enum AccountMobileMenuPosition {
  MAIN,
  DATA,
  ORDERS,
}

export interface AccountMobileMenuState {
  position: AccountMobileMenuPosition;
  open: boolean;
}

const initialState: AccountMobileMenuState = {
  open: true,
  position: 0,
};

export const accountMobileMenuSlice = createSlice({
  name: 'account-mobile-menu',
  initialState,
  reducers: {
    goToPosition: (state: AccountMobileMenuState, action: PayloadAction<AccountMobileMenuPosition>) => {
      return { ...state, position: action.payload };
    },
    goToData: (state: AccountMobileMenuState) => {
      return { ...state, position: AccountMobileMenuPosition.DATA };
    },
    goToOrders: (state: AccountMobileMenuState) => {
      return { ...state, position: AccountMobileMenuPosition.ORDERS };
    },
    goToMain: (state: AccountMobileMenuState) => {
      return { ...state, position: AccountMobileMenuPosition.MAIN };
    },
    close: (state: AccountMobileMenuState) => {
      return { ...state, open: false };
    },
    open: (state: AccountMobileMenuState) => {
      return { ...state, open: true };
    },
  },
});

export const accountMobileMenuActions = accountMobileMenuSlice.actions;

export default accountMobileMenuSlice.reducer;
