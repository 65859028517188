export const PlatformWebappStaticRoutes = {
  general: {
    home: '/',
    news: '/news',
    media: '/media',
    faq: '/faq',
    events: '/events',
    search: '/search',
    cart: '/cart',
  },
  account: {
    addresses: '/account/addresses',
    billingMethods: '/account/billing-methods',
    login: '/account/login',
    orders: '/account/orders',
    passwordRestore: '/account/password/restore',
    personalData: '/account/personal-data',
    register: '/account/register',
  },
  production: {
    rotos: '/equipment/production/rotos',
    workpieceHolder: '/equipment/production/workpiece-holder',
  },
  commerce: {
    cart: '/cart',
    checkout: {
      authentication: '/checkout/authentication',
      index: '/checkout',
      success: '/checkout/success',
    },
  },
};
