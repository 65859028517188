import { RefObject, useEffect, useState } from 'react';
import { useWindowSize } from './use-window-resize.hook';

export const useFollowPointer = (
  pointerRef: RefObject<HTMLElement>,
  parentRef?: RefObject<HTMLElement>,
  offset?: { top: number; left: number }
) => {
  const [point, setPoint] = useState({ x: 0, y: 0 });
  const [width, height] = useWindowSize();
  const [maxPositions, setMaxPositions] = useState<{ right: number; bottom: number }>({ right: 0, bottom: 0 });

  useEffect(() => {
    if (parentRef) {
      setMaxPositions({
        right: parentRef.current?.offsetWidth ? parentRef.current?.offsetWidth : 0,
        bottom: parentRef.current?.offsetHeight ? parentRef.current?.offsetHeight : 0,
      });
    }
  }, [parentRef, width, height]);

  useEffect(() => {
    if (!pointerRef.current) return;

    const handlePointerMove = (event: MouseEvent) => {
      if (pointerRef.current) {
        const pointer = pointerRef.current;

        const parentRect = parentRef?.current?.getBoundingClientRect();
        const parentLeft = parentRect?.left ?? 0;
        const parentTop = parentRect?.top ?? 0;

        const offsetLeft = offset?.left ?? 0;
        const offsetTop = offset?.top ?? 0;

        const xResult = event.clientX - (parentLeft - offsetLeft) - pointer.offsetWidth / 2;
        const yResult = event.clientY - (parentTop - offsetTop) - pointer.offsetHeight / 2;

        const x = xResult >= maxPositions.right ? maxPositions.right : xResult;
        const y = yResult >= maxPositions.bottom ? maxPositions.bottom : yResult;
        setPoint({ x, y });
      }
    };

    window.addEventListener('pointermove', handlePointerMove);

    return () => window.removeEventListener('pointermove', handlePointerMove);
  }, [offset, parentRef, pointerRef, maxPositions]);

  return point;
};
