import { UrlObject } from 'url';
import { CategoryConstant, categoryConstants } from '../contstants';

// Helper function to find the corresponding categoryConstant for a given list of categories
const findCategoryConstant = (categories: Array<string>): CategoryConstant | undefined => {
  return Object.values(categoryConstants).find(
    (categoryConstant: CategoryConstant) => categories.includes(categoryConstant.categoryCode)
  );
};

// Function to generate the pdpLink using the product's slug and categories
export const pdpLink = (slug: string, categories: Array<string>): string | UrlObject => {
  // Find the categoryConstant using the helper function
  const categoryConstant = findCategoryConstant(categories);

  // If a categoryConstant is found, return the URL object
  if (categoryConstant) {
    return { pathname: categoryConstant.pdpSlug, query: { slug } };
  } else {
    // TODO: Implement fallback
    return '';
  }
};

// Function to generate the compareLink using the product's slug and categories
export const compareLink = (slug: string, categories: Array<string>): string | UrlObject => {
  // Find the categoryConstant using the helper function
  const categoryConstant = findCategoryConstant(categories);

  // If a categoryConstant is found, return the URL object
  if (categoryConstant) {
    return { pathname: categoryConstant.compareSlug, query: { slug } };
  } else {
    // TODO: Implement fallback
    return '';
  }
};
