import styles from './social-share.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { SocialMediaButton } from '@dxp/veneer';

export function SocialShare(props: { className?: string }) {
  const { t } = useTranslation('news');
  const [urls, setUrls] = useState<{ facebook: string; linkedin: string; twitter: string }>({
    facebook: '',
    linkedin: '',
    twitter: '',
  });

  useEffect(() => {
    setUrls({
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
      twitter: `https://twitter.com/intent/tweet?url=${window.location.href}&text=`,
    });
  }, []);

  return (
    <div className={classNames(styles['container'], props.className)}>
      <div className={styles['headline']} data-testid={'headline'}>
        {t('news.footer.socialshare.headline')}
      </div>
      <div className={styles['buttons']} data-testid={'buttons'}>
        <SocialMediaButton link={urls.facebook} platform={'facebook'} theme={'dark'} />
        <SocialMediaButton link={urls.linkedin} platform={'linkedin'} theme={'dark'} />
        <SocialMediaButton link={urls.twitter} platform={'twitter'} theme={'dark'} />
      </div>
    </div>
  );
}

export default SocialShare;
