import { EIncludeTypes } from '../types';
import { AxiosInstance, AxiosResponse } from 'axios';
import raw from './raw';
import { ICheckoutData, ICheckoutDataRequest, ICheckoutDataRequestAttributes } from '../interfaces';
import { parseCheckoutDataResponse } from '../helper';

const include: string = [EIncludeTypes.PAYMENT_METHODS, EIncludeTypes.SHIPMENT_METHODS, EIncludeTypes.ADDRESSES].join(
  ','
);

/**
 * Post checkout data authorized - be sure Authorization header is set in http instance!
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202009.0/checking-out/submitting-checkout-data.html#submit-checkout-data
 * @param {AxiosInstance} http the axios instance
 * @param payload: {checkoutDataRequestAttributes: ICheckoutDataRequestAttributes} Object with checkoutDataRequestAttributes
 * @return {Promise<ICheckoutData | null>} the checkout data
 */
export const postAuthorized = (
  http: AxiosInstance,
  payload: { checkoutDataRequestAttributes: ICheckoutDataRequestAttributes }
): Promise<ICheckoutData | null> => {
  const data: ICheckoutDataRequest = {
    data: {
      type: 'checkout-data',
      attributes: payload.checkoutDataRequestAttributes,
    },
  };
  return raw.post(http, `checkout-data`, data, { params: { include } }).then((response: AxiosResponse<any>) => {
    if (response) {
      return parseCheckoutDataResponse(response.data.data, response.data.included);
    } else {
      return null;
    }
  });
};

/**
 * Post checkout data unauthorized - be sure anonymId is set in payload!
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202009.0/checking-out/submitting-checkout-data.html#submit-checkout-data
 * @param {AxiosInstance} http the axios instance
 * @param payload: {checkoutDataRequestAttributes: ICheckoutDataRequestAttributes} Object with checkoutDataRequestAttributes
 * @return {Promise<ICheckoutData | null>} the checkout data
 */
export const postUnauthorized = (
  http: AxiosInstance,
  payload: { checkoutDataRequestAttributes: ICheckoutDataRequestAttributes; anonymId: string }
): Promise<ICheckoutData | null> => {
  const data: ICheckoutDataRequest = {
    data: {
      type: 'checkout-data',
      attributes: payload.checkoutDataRequestAttributes,
    },
  };
  return raw
    .post(http, `checkout-data`, data, {
      headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId },
      params: { include },
    })
    .then((response: AxiosResponse<any>) => {
      if (response) {
        return parseCheckoutDataResponse(response.data.data, response.data.included);
      } else {
        return null;
      }
    });
};
