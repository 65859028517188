import styles from './social-media-bar.module.scss';
import { SocialMediaButton, SocialMediaPlatform } from '@dxp/veneer';
import { useTranslation } from 'react-i18next';

export interface SocialMediaBarProps {
  theme: 'light' | 'dark' | 'smart-blue';
  direction: 'vertical' | 'horizontal';
}

export const SocialMediaBar = (props: SocialMediaBarProps) => {
  const { t } = useTranslation('common');
  const platforms: Array<SocialMediaPlatform> = ['youtube', 'linkedin', 'twitter', 'instagram', 'facebook'];

  return (
    <div className={[styles['container'], styles[props.direction]].join(' ')} data-testid={'container'}>
      {platforms.map((platform) => {
        return (
          <SocialMediaButton
            className={styles['item']}
            key={platform}
            theme={props.theme ?? 'dark'}
            platform={platform}
            link={t(`socialMedia.${platform}.link`)}
          />
        );
      })}
    </div>
  );
};

export default SocialMediaBar;
