import styles from './product-variant-overview.module.scss';
import { useTranslation } from 'next-i18next';
import { IConcreteProduct, ProductVariantTeaser } from '@dxp/commerce';
import { VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { IPdp } from '../../interfaces';

export interface ProductVariantOverviewProps {
  variants: Array<ProductRow>;
}

export interface ProductRow {
  pdp: IPdp;
  image: string;
  children: Array<IConcreteProduct>;
}

export const ProductVariantOverview = (props: ProductVariantOverviewProps) => {
  const { t } = useTranslation('common');

  const renderItems = props.variants.map((row) => (
    <ProductVariantTeaser
      key={row.pdp.code || row.pdp.sku}
      image={row.image}
      title={row.pdp.name}
      description={row.pdp.shortDescription}
      id={row.pdp.code}
      concreteProducts={row.children}
      className={styles['list-item']}
    />
  ));

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
    >
      <div className={classNames(styles['count'], 'subtext-regular')} data-testid="count">
        {t(`product.count${props.variants.length === 1 ? 'One' : 'Other'}`, { count: props.variants.length })}
      </div>
      <div className={styles['list']} data-testid="list">
        {renderItems}
      </div>
    </motion.div>
  );
};

export default ProductVariantOverview;
