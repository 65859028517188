import { call, put, takeEvery } from 'redux-saga/effects';
import { contentful } from '../../../utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { ContentfulDataActions } from '../contentful-data.slice';
import { EntryCollection } from 'contentful';
import { TypeEventFields, TypeVacancyFields } from '../../../types';
import { IEvent, IVacancy } from '../../../types/additional-contentful-data.interface';

export function* watchFetchEvents() {
  yield takeEvery(ContentfulDataActions.eventsRequested, fetchEventsAsync);
}

function* fetchEventsAsync(action: PayloadAction<string>) {
  try {
    const events: EntryCollection<TypeEventFields> = yield call(contentful.getEvents, action.payload);

    const response: Array<IEvent> = events.items.map((event): IEvent => {
      return { id: event.sys.id, event: event.fields };
    });

    if (response) {
      yield put(ContentfulDataActions.eventsReceived(response));
    }
  } catch (error) {
    yield put(ContentfulDataActions.eventsRequestFailed());
  }
}

export function* watchFetchVacancies() {
  yield takeEvery(ContentfulDataActions.vacanciesRequested, fetchVacanciesAsync);
}

function* fetchVacanciesAsync(action: PayloadAction<string>) {
  try {
    const vacancies: EntryCollection<TypeVacancyFields> = yield call(contentful.getVacancies, action.payload);

    const response: Array<IVacancy> = vacancies.items.map((vacancy): IVacancy => {
      return { id: vacancy.sys.id, vacancy: vacancy.fields };
    });

    if (response) {
      yield put(ContentfulDataActions.vacanciesReceived(response));
    }
  } catch (error) {
    yield put(ContentfulDataActions.vacanciesRequestFailed());
  }
}
