import { ICustomerLoginData } from '@dxp/spryker-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateError = string | { [key: string]: string } | null;

export interface AuthErrors {
  customerLogin: StateError;
}

interface PersistState {
  version: number;
  rehydrated: boolean;
}

/**
 * The interface for auth data provided in redux store.
 */
export interface AuthState {
  anonymId: string | null;
  loading: boolean;
  customerRef: string | null;
  error: AuthErrors;
  _persist?: PersistState;
}

/**
 * The initial auth redux state.
 */
export const initialAuthState: AuthState = {
  anonymId: null,
  loading: false,
  customerRef: null,
  error: {
    customerLogin: null,
  },
};

/**
 * The auth redux slice.
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    clearError: (state, action: PayloadAction<keyof AuthErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialAuthState.error;
    },

    setAnonymId: (state, action: PayloadAction<string | null>) => {
      state.anonymId = action.payload;
    },

    customerLoginRequested: (
      state,
      action: PayloadAction<ICustomerLoginData & { locale: string | undefined; pushUrl?: string }>
    ) => {
      state.loading = true;
      state.customerRef = initialAuthState.customerRef;
    },
    customerLoginReceived: (state, action: PayloadAction<string | null>) => {
      state.loading = false;
      state.customerRef = action.payload;
    },
    customerLoginRequestFailed: (state) => {
      state.loading = false;
      state.customerRef = initialAuthState.customerRef;
    },
    customerLogout: (state, action: PayloadAction<{ locale: string | undefined }>) => {
      state.loading = false;
      state.customerRef = null;
    },
  },
});

export const AuthActions = authSlice.actions;

export default authSlice.reducer;
