import { IPaymentMethodsRequestAttributes, IPaymentMethodsResponse } from '@dxp/spryker-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethod } from '@adyen/adyen-web/dist/types/types';

type StateError = string | { [key: string]: string } | null;

export interface CheckoutPaymentsErrors {
  checkoutPaymentsRequested: StateError;
}

interface PersistState {
  version: number;
  rehydrated: boolean;
}

/**
 * The interface for checkout-data provided in redux store.
 */
export interface CheckoutPaymentsState {
  paymentMethods: Array<PaymentMethod>;
  error: CheckoutPaymentsErrors;
  loading: boolean;
  _persist?: PersistState;
}

/**
 * The initial checkout-data redux state.
 */
const initialState: CheckoutPaymentsState = {
  paymentMethods: [],
  error: {
    checkoutPaymentsRequested: null,
  },
  loading: false,
};

/**
 * The checkout-payments redux slice.
 */
export const checkoutPaymentsSlice = createSlice({
  name: 'checkoutPayments',
  initialState,
  reducers: {
    // general reducers
    clearError: (state, action: PayloadAction<keyof CheckoutPaymentsErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },
    clearState: (state) => {
      state.paymentMethods = initialState.paymentMethods;
      state.error = initialState.error;
      state.loading = initialState.loading;
    },

    // checkout-payments reducers
    checkoutPaymentsRequested: (
      state,
      action: PayloadAction<{
        locale: string;
        checkoutPaymentsRequestAttributes: IPaymentMethodsRequestAttributes;
        anonymId: string | null;
      }>
    ) => {
      state.error.checkoutPaymentsRequested = null;
      state.loading = true;
    },
    checkoutPaymentsReceived: (state, action: PayloadAction<IPaymentMethodsResponse>) => {
      state.paymentMethods = action.payload.paymentMethods;
      state.error.checkoutPaymentsRequested = null;
      state.loading = false;
    },
    checkoutPaymentsRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.checkoutPaymentsRequested = action.payload;
      state.loading = false;
    },
  },
});

export const CheckoutPaymentsActions = checkoutPaymentsSlice.actions;

export default checkoutPaymentsSlice.reducer;
