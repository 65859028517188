import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ModalItemInterface {
  url: string;
  type: 'image' | 'video' | 'youtube';
  autoplay?: boolean;
  alt?: string;
  title?: string;
  description?: string;
}

export interface ModalContentsInterface {
  items: ModalItemInterface[];
  activeItem?: number;
}

export interface ModalState {
  active: boolean;
  contents?: ModalContentsInterface;
}

const initialState: ModalState = {
  active: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ModalContentsInterface>) => {
      state.active = true;
      state.contents = action.payload;
    },
    close: (state) => {
      state.active = false;
      state.contents = undefined;
    },
  },
});

const { open, close } = modalSlice.actions;

export const ModalActions = {
  open,
  close,
};

export default modalSlice.reducer;

export interface ModalStateInterface {
  modal: ModalState;
}
