import { IAddressItem, ICheckoutDataAddressItem } from './addresses';
import { Links } from '../types';

export interface ICheckoutDataRequest {
  data: {
    type: 'checkout-data';
    attributes: ICheckoutDataRequestAttributes;
  };
}

export interface ICheckoutDataRequestAttributes {
  idCart: string;
  customer: CheckoutDataCustomer;
  billingAddress?: IAddressItem;
  shippingAddress?: IAddressItem;
  payments?: Array<PaymentMethodRequest>;
  shipment?: {
    idShipmentMethod: number;
  };
}

export interface PaymentMethodRequest {
  paymentMethodName: string;
  paymentProviderName: string;
  adyenCreditCardPayment?: {
    encryptedCardNumber?: string;
    encryptedExpiryMonth?: string;
    encryptedExpiryYear?: string;
    encryptedSecurityCode?: string;
  };
}

export interface CheckoutDataCustomer {
  email: string;
  salutation?: string | null;
  firstName: string;
  lastName: string;
}

export interface ICheckoutDataResponse {
  type: 'checkout-data';
  id: string | null;
  links: Links;
  attributes: ICheckoutDataResponseAttributes;
  relationships?: ICheckoutDataResponseRelationships;
}

export interface ICheckoutDataResponseAttributes {
  addresses: Array<ICheckoutDataAddressItem>;
  paymentProviders: Array<IPaymentMethods>;
  shipmentMethods: Array<IShipmentMethods>;
  selectedPaymentMethods: Array<ISelectedPaymentMethods>;
  selectedShipmentMethods: Array<ISelectedShipmentMethods>;
}

export interface ICheckoutDataResponseRelationships {
  [key: string]: Relationships;
}

export interface Relationships {
  data: Relationship[];
}

export interface Relationship {
  type: CheckoutDataType;
  id: string;
}

export enum CheckoutDataType {
  PaymentMethods = 'payment-methods',
  ShipmentMethods = 'shipment-methods',
}

export interface IncludedCheckoutData {
  type: CheckoutDataType;
  id: string;
  attributes: IncludedCheckoutDataAttributes;
  links: Links;
}

export interface IncludedCheckoutDataAttributes {
  carrierName?: string;
  currencyIsoCode?: string;
  deliveryTime?: string | null;
  name?: string;
  price?: number;
  paymentMethodName: string;
  paymentProviderName: string;
  priority?: string | null;
  requiredRequestData?: Array<string>;
}

export interface ICheckoutData {
  id: string | null;
  addresses: Array<IAddressItem>;
  paymentMethods: Array<IPaymentMethods>;
  shipmentMethods: Array<IShipmentMethods>;
  selectedPaymentMethods?: Array<ISelectedPaymentMethods>;
  selectedShipmentMethods?: Array<ISelectedShipmentMethods>;
  acceptedTerms: boolean;
}

export interface IPaymentMethods {
  id: string;
  paymentMethodName: string;
  paymentProviderName: string;
  priority: string | null;
  requiredRequestData?: Array<string>;
}

interface ShipmentMethodAttributes {
  carrierName: string;
  currencyIsoCode: string;
  deliveryTime: string | null;
  name: string;
  price: number | null;
}

export interface ISelectedShipmentMethods extends ShipmentMethodAttributes {
  id: number;
}

export interface IShipmentMethods extends ShipmentMethodAttributes {
  id: string;
}

export interface ISelectedPaymentMethods {
  name: string;
  paymentMethodName: string;
  paymentProviderName: string;
  requiredRequestData?: Array<string>;
}

export interface IGuestCheckoutPersonalData {
  salutation?: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
}
