import styles from './social-media-button.module.scss';
import classNames from 'classnames';
import { Colors } from '../../../types';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { IconClose, IconFacebook, IconInstagram, IconLinkedin, IconTwitter, IconYoutube } from '../../decoration/icons';
import { VeneerAnimation } from '@dxp/veneer/animations';

export type SocialMediaPlatform = 'facebook' | 'instagram' | 'linkedin' | 'twitter' | 'youtube';

export interface SocialMediaButtonProps {
  link: string;
  platform: SocialMediaPlatform;
  theme: 'dark' | 'light' | 'smart-blue';
}

export const SocialMediaButton = (props: SocialMediaButtonProps & { className?: string }) => {
  const classes: string = classNames(styles['inner'], props.className);

  const Icon = useMemo(() => {
    const color =
      props.theme === 'dark' ? Colors.intenseBlue : props.theme === 'light' ? Colors.brilliantBlue : Colors.smartBlue;

    switch (props.platform) {
      case 'facebook':
        return <IconFacebook color={color} />;
      case 'instagram':
        return <IconInstagram color={color} />;
      case 'linkedin':
        return <IconLinkedin color={color} />;
      case 'twitter':
        return <IconTwitter color={color} />;
      case 'youtube':
        return <IconYoutube color={color} />;
      default:
        return <IconClose color={color} />;
    }
  }, [props.platform, props.theme]);

  return (
    <motion.a
      whileHover={{ ...VeneerAnimation.uiScale, transition: VeneerAnimation.uiScaleTransition }}
      href={props.link ?? '#'}
      target={'_blank'}
      rel={'noopener'}
      className={classes}
      data-testid={'item-' + props.platform}
      aria-label={props.platform}
    >
      {Icon}
    </motion.a>
  );
};

export default SocialMediaButton;
