import { Icon, IconProps } from './icon';
import { ReactElement } from 'react';

export type GeneratedIconProps = Omit<IconProps, 'children' | 'name'> & { children?: never };

type GenerateIconParameters = {
  /**
   * Icon name is used as the generated icon's component display name
   */
  name?: string;
  /**
   * The SVG path(s) to render in the icon wrapper
   */
  path: ReactElement;
  /**
   * A collection of default props to set on the generated icon
   */
  props?: GeneratedIconProps;
};

export function generateIcon({ name, path, props: defaultProps }: GenerateIconParameters) {
  const Component = function (props: IconProps) {
    return (
      <Icon {...defaultProps} {...props}>
        {path}
      </Icon>
    );
  };

  if (name) {
    Component.displayName = name;
  }

  return Component;
}
