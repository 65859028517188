import { AxiosInstance, AxiosResponse } from 'axios';
import {
  IPaymentDataRawResponse,
  IPaymentDataRequest,
  IPaymentDataRequestGuest,
  IPaymentDataResponseParsed,
} from '../interfaces';
import { parsePaymentDataResponse } from '../helper';

/**
 * add payment information to order
 * @param {AxiosInstance} http the axios instance
 * @param payload
 * @return Promise<IPaymentDataResponseParsed | null>
 */
export const updateOrderPayment = (
  http: AxiosInstance,
  payload: { locale: string; payload: IPaymentDataRequest | null }
): Promise<IPaymentDataResponseParsed | null> => {
  return http
    .post(
      `order-payments`,
      {
        data: { type: 'order-payments', attributes: payload.payload },
      },
      {}
    )
    .then((response: AxiosResponse<IPaymentDataRawResponse | null>) => {
      if (response) {
        return parsePaymentDataResponse(response);
      } else {
        return null;
      }
    });
};

/**
 * add payment information to order
 * @param {AxiosInstance} http the axios instance
 * @param payload
 * @return Promise<any>
 */
export const updateOrderPaymentGuest = (
  http: AxiosInstance,
  payload: { locale: string; payload: IPaymentDataRequestGuest }
): Promise<IPaymentDataResponseParsed | null> => {
  return http
    .post(
      `order-payments`,
      {
        data: { type: 'order-payments', attributes: payload.payload.attributes },
      },
      { headers: { 'X-Anonymous-Customer-Unique-Id': payload.payload.anonymId }, params: {} }
    )
    .then((response: AxiosResponse<IPaymentDataRawResponse | null>) => {
      if (response) {
        return parsePaymentDataResponse(response);
      } else {
        return null;
      }
    });
};
