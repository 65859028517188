import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

export interface IRoto {
  sku: string;
  parent: string;
  headSize: number;
  callToAction: Array<string>;
}

/**
 * Mapping configuration for IRoto (products!) interface
 */
export const IRotoConfig: MappingConfig = {
  sku: {
    path: ['identifier'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  parent: {
    path: ['parent'],
    valueData: ValueDataType.direct,
    localized: true,
  },
  headSize: {
    path: ['values', 'drill_head_roto'],
    valueData: ValueDataType.string,
    localized: false,
    modifier: (size: number) => (size / 10).toFixed(1),
  },
  callToAction: {
    path: ['values', 'call_to_action'],
    valueData: ValueDataType.strings,
    localized: true,
  },
};
