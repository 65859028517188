import { useEffect } from 'react';

export const useKeyboard = (callback: (event: KeyboardEvent) => void, event: 'keydown' | 'keyup' = 'keydown') => {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      callback(e);
    };

    document.addEventListener(event, handleKeydown);

    return () => document.removeEventListener(event, handleKeydown);
  }, [callback, event]);
};
