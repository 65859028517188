import { IStore } from '@dxp/spryker-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { sprykerClient } from '../../index';
import { CommonCommerceActions } from './slice';

/**
 * The watcher function to fetch stores
 */
export function* watchStoresRequested() {
  yield takeEvery(CommonCommerceActions.storesRequested, fetchStoresAsync);
}

/**
 * The worker function to fetch stores
 * @param action
 */
export function* fetchStoresAsync(action: PayloadAction<{ locale: string }>) {
  try {
    const { locale } = action.payload;

    const stores: Array<IStore> | null = yield call(sprykerClient(locale).store.getAll, {});

    if (stores) {
      yield put(CommonCommerceActions.storesReceived(stores));
    } else {
      yield put(CommonCommerceActions.storesRequestFailed('No stores found.'));
    }
  } catch (error: any) {
    yield put(CommonCommerceActions.storesRequestFailed(error));
  }
}
