import { FrontifyAsset } from '../interfaces';

export type ExtendedFrontifyAsset = {
  videoType: 'youtube' | 'video';
  thumbnail: string;
  speaker?: string | string[];
  isHiddenForGuest?: boolean;
} & FrontifyAsset;

export function getYoutubeVideoId(url: string) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[7].length === 11 ? match[7] : false;
}

enum OnDemand {
  'False',
  'True',
}

export function mapAsset(asset: FrontifyAsset): ExtendedFrontifyAsset {
  const YTVideo = asset.metadataValues?.find((meta) => meta.metadataField.label.toLowerCase().includes('yt'));
  const videoType = asset.metadataValues?.find((meta) => meta.metadataField.label.toLowerCase() === 'type')?.value;
  const onDemandMetaValue = asset.metadataValues?.find((meta) =>
    meta.metadataField.label.toLowerCase().includes('demand')
  )?.value;
  const isHiddenForGuest = Boolean(
    onDemandMetaValue && typeof onDemandMetaValue !== 'number'
      ? OnDemand[onDemandMetaValue as keyof typeof OnDemand]
      : OnDemand.False
  );

  const speaker =
    asset.metadataValues
      ?.filter((meta) => meta.metadataField.label.toLowerCase() === 'speaker')
      ?.map((_s) => String(_s.value)) ?? '';

  if (!YTVideo) {
    const url = new URL(asset.previewUrl ?? '');

    url.searchParams.set('format', 'jpg');

    return {
      ...asset,
      ...(videoType && { type: String(videoType).trim() }),
      speaker,
      videoType: 'video',
      thumbnail: url.toString(),
      isHiddenForGuest,
    };
  }

  const YTId = getYoutubeVideoId(String(YTVideo.value)) || '';

  return {
    ...asset,
    ...(videoType && { type: String(videoType).trim() }),
    videoType: 'youtube',
    speaker,
    previewUrl: YTId,
    thumbnail: `http://img.youtube.com/vi/${YTId}/0.jpg`,
    isHiddenForGuest,
  };
}
