import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  IArticle,
  IArticleCategory,
  IArticleInformation,
  IEvent,
  IFaqQuestion,
  IFaqTopic,
  ITrainer,
  IVacancy,
} from '../../types';

export interface ContentfulDataState {
  events: Array<IEvent>;
  vacancies: Array<IVacancy>;
  trainers: Array<ITrainer>;
  articleInformation: IArticleInformation;
  pinnedArticles: Array<IArticle>;
  articleCategories: Array<IArticleCategory>;
  faqTopics: Array<IFaqTopic>;
  faqQuestions: Array<IFaqQuestion>;
}

export const initialContentfulDataState: ContentfulDataState = {
  events: [],
  vacancies: [],
  trainers: [],
  articleInformation: {
    totalCount: null,
    articles: [],
  },
  pinnedArticles: [],
  articleCategories: [],
  faqTopics: [],
  faqQuestions: [],
};

export interface articlePayload {
  locale: string;
  page: number;
  pageSize: number;
  category?: string;
  excludeId?: string;
}

export interface TrainerRequest {
  locale: string;
  names: string[];
}

export const contentfulDataSlice = createSlice({
  name: 'contentfulData',
  initialState: initialContentfulDataState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    eventsRequested: (state, action: PayloadAction<string>) => {},
    eventsReceived: (state, action: PayloadAction<Array<IEvent>>) => {
      state.events = [
        ...state.events.filter(
          (existingEvent: IEvent) => !action.payload.some((newEvent: IEvent) => newEvent.id === existingEvent.id)
        ),
        ...action.payload,
      ];
    },
    eventsRequestFailed: (state) => {
      state.events = initialContentfulDataState.events;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    vacanciesRequested: (state, action: PayloadAction<string>) => {},
    vacanciesReceived: (state, action: PayloadAction<Array<IVacancy>>) => {
      state.vacancies = [
        ...state.vacancies.filter(
          (existingVacancy: IVacancy) =>
            !action.payload.some((newVacancy: IVacancy) => newVacancy.id === existingVacancy.id)
        ),
        ...action.payload,
      ];
    },
    vacanciesRequestFailed: (state) => {
      state.vacancies = initialContentfulDataState.vacancies;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    trainersRequested: (_state, _action: PayloadAction<TrainerRequest>) => {},
    trainersReceived: (state, action: PayloadAction<ITrainer[]>) => {
      state.trainers = action.payload;
    },
    trainersRequestFailed: (state) => {
      state.trainers = initialContentfulDataState.trainers;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    articlesRequested: (state, action: PayloadAction<articlePayload>) => {},
    articlesReceived: (state, action: PayloadAction<IArticleInformation>) => {
      state.articleInformation = action.payload;
    },
    articlesRequestFailed: (state) => {
      state.articleInformation = initialContentfulDataState.articleInformation;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    pinnedArticlesRequested: (state, action: PayloadAction<string>) => {},
    pinnedArticlesReceived: (state, action: PayloadAction<Array<IArticle>>) => {
      state.pinnedArticles = action.payload;
    },
    pinnedArticlesRequestFailed: (state) => {
      state.pinnedArticles = initialContentfulDataState.pinnedArticles;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    articleCategoriesRequested: (state, action: PayloadAction<string>) => {},
    articleCategoriesReceived: (state, action: PayloadAction<Array<IArticleCategory>>) => {
      state.articleCategories = [
        ...state.articleCategories.filter(
          (existingCategory: IArticleCategory) =>
            !action.payload.some((newCategory: IArticleCategory) => newCategory.id === existingCategory.id)
        ),
        ...action.payload,
      ];
    },
    articleCategoriesRequestFailed: (state) => {
      state.articleCategories = initialContentfulDataState.articleCategories;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    faqTopicsRequested: (state, action: PayloadAction<string>) => {},
    faqTopicsReceived: (state, action: PayloadAction<Array<IFaqTopic>>) => {
      state.faqTopics = [
        ...state.faqTopics.filter(
          (existingFaq: IFaqTopic) => !action.payload.some((newFaq: IFaqTopic) => newFaq.id === existingFaq.id)
        ),
        ...action.payload,
      ];
    },
    faqTopicsRequestFailed: (state) => {
      state.faqTopics = initialContentfulDataState.faqTopics;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    faqQuestionsRequested: (state, action: PayloadAction<string>) => {},
    faqQuestionsReceived: (state, action: PayloadAction<Array<IFaqQuestion>>) => {
      state.faqQuestions = [
        ...state.faqQuestions.filter(
          (existingFaq: IFaqQuestion) => !action.payload.some((newFaq: IFaqQuestion) => newFaq.id === existingFaq.id)
        ),
        ...action.payload,
      ];
    },
    faqQuestionsRequestFailed: (state) => {
      state.faqQuestions = initialContentfulDataState.faqQuestions;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action['payload'].contentfulData,
      };
    });
  },
});

export const ContentfulDataActions = contentfulDataSlice.actions;

export default contentfulDataSlice.reducer;

export interface ContentfulDataStateInterface {
  contentfulData: ContentfulDataState;
}
