import { useTranslation } from 'next-i18next';
import styles from './cart-item-small.module.scss';
import { ICartItem } from '@dxp/spryker-client';
import { Colors, DeliveryState, IconBin, Price, RoundButton } from '@dxp/veneer';
import { CartActions } from '../../../../../redux';
import { useRouter } from 'next-translate-routes';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { AuthContext } from '@dxp/core';
import { CartContext } from '../../../cart';
import CartItemPartialInformation from '../partials/cart-item-partial-information/cart-item-partial-information';
import CartItemPartialImage from '../partials/cart-item-partial-image/cart-item-partial-image';

export interface CartItemSmallProps {
  item: ICartItem;
  manipulatable?: boolean;
  deliveryState?: DeliveryState;
}

export function CartItemSmall({ item, manipulatable, deliveryState }: CartItemSmallProps) {
  const { t } = useTranslation('cart');

  const router = useRouter();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const cart = useContext(CartContext);

  const onDeleteItem = () => {
    if (authContext?.isAuthenticated) {
      dispatch(
        CartActions.deleteItem({
          locale: router.locale || '',
          payload: { itemId: item.id, cartId: cart?.id || '' },
        })
      );
    } else {
      dispatch(
        CartActions.guestDeleteItem({
          locale: router.locale || '',
          payload: { itemId: item.id, cartId: cart?.id || '', anonymId: authContext?.anonymId || '' },
        })
      );
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['image']}>
        <CartItemPartialImage image={item.image} alt={item.name || ''} />
      </div>

      <CartItemPartialInformation
        item={item}
        showQuantity={true}
        deliveryState={deliveryState}
        showDeliveryInformation={true}
        className={styles['information']}
      />

      <div className={styles['price']}>
        <Price
          priceSize={'cart'}
          layout={'minimal'}
          price={item.calculations?.sumNetPrice}
          vat={false}
          currency={cart?.currency || ''}
        />
        {manipulatable && (
          <RoundButton
            title={t('deleteItem')}
            variant={Colors.brilliantBlue}
            icon={<IconBin />}
            interactive={true}
            className={styles['price__delete-item']}
            onClick={() => onDeleteItem()}
          />
        )}
      </div>
    </div>
  );
}

export default CartItemSmall;
