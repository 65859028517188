import raw from './raw';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ICatalogSearchDataParsed, ISearchQuery } from '../interfaces';
import { parseCatalogSearchResponse } from '../helper';
import { ICatalogSearchRawResponse, TApiResponseData } from '../types';

/**
 * Searching the product catalog
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202204.0/searching-the-product-catalog.html
 * @param {AxiosInstance} http the axios instance
 * @param {ISearchQuery} query the search query
 * @return {ICatalogSearchDataParsed} parsed search data
 */
export const query = (
  http: AxiosInstance,
  { query }: { query: ISearchQuery }
): Promise<ICatalogSearchDataParsed | null> =>
  raw
    .get(http, `catalog-search`, { params: query })
    .then((response: AxiosResponse<TApiResponseData>) =>
      parseCatalogSearchResponse(response.data as unknown as ICatalogSearchRawResponse)
    );
