import styles from './cart-item-partial-image.module.scss';
import Image from 'next/image';
import classNames from 'classnames';

/* eslint-disable-next-line */
export interface CartItemPartialImageProps {
  image?: string;
  alt?: string;
  className?: string;
}

export function CartItemPartialImage({ alt, image, className }: CartItemPartialImageProps) {
  return image ? (
    <Image
      src={image}
      className={classNames(styles['image'], className)}
      alt={alt || ''}
      title={alt || ''}
      width={125}
      height={100}
      unoptimized={true}
    />
  ) : (
    <div className={styles['image']}>
      <svg className={styles['noImage']} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 24" fill="none">
        <path
          fill="#005ba0"
          d="M0 17.881V24c3.133-3.798 8.032-6.224 13.5-6.224S23.867 20.255 27 24v-6.119c-3.475-2.848-8.373-4.536-13.5-4.536-5.07 0-10.025 1.688-13.5 4.536Zm0-8.756v4.853c4.044-1.846 8.658-2.901 13.5-2.901 4.842 0 9.456 1.055 13.5 2.901V9.125a37.915 37.915 0 0 0-13.5-2.479c-4.785 0-9.342.844-13.5 2.48ZM13.5 0C8.886 0 4.386.422 0 1.213v4.484a70.84 70.84 0 0 1 13.5-1.319c4.614 0 9.114.422 13.5 1.266v-4.43A75.899 75.899 0 0 0 13.5 0Z"
        />
      </svg>
    </div>
  );
}
export default CartItemPartialImage;
