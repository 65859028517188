import { VideoItem } from '@dxp/core';
import { FrontifyAsset, FrontifyGetters, FrontifyUtils } from '@dxp/frontify-client';
import { Colors, IconDownload, Table } from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PdfTeaser from '../pdf-teaser/pdf-teaser';
import styles from './media-list.module.scss';

interface Props {
  locale: string;
  assets: FrontifyAsset[];
  appearance: string;
  uiConfig?: {
    isDateVisible?: boolean;
  };
}

export const MediaList = ({ locale, assets, appearance, uiConfig }: Props) => {
  const { t } = useTranslation('media');
  // ToDo: Remove hardcoded label (investigation needed)
  const getAssetLanguages = useCallback(
    (asset: FrontifyAsset) =>
      FrontifyGetters.getMetaDataValuesByFieldLabel('Language', asset.metadataValues ?? [])
        .map(({ value }) => value)
        .join(', '),
    []
  );
  const formatDate = useCallback(
    (asset: FrontifyAsset) => {
      const revisionDate = FrontifyGetters.getMetaDataValuesByFieldLabel(
        'Date of Revision',
        asset.metadataValues ?? []
      )[0];

      return revisionDate
        ? new Intl.DateTimeFormat(locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(new Date(revisionDate.value))
        : '';
    },
    [locale]
  );

  const renderListTable = () => {
    if (appearance !== 'list') {
      return <></>;
    }

    const head = [
      { content: t('table.item.title'), isTitle: true },
      ...(uiConfig?.isDateVisible ? [{ className: styles['table__col'], content: t('table.item.revisiondate') }] : []),
      { className: styles['table__col'], content: t('table.item.language') },
      { className: styles['table__col'], content: t('table.item.filetype') },
      { className: styles['table__action'], content: '' },
    ];

    const data = assets.map((assetItem) => [
      { content: <a href={assetItem.downloadUrl ? assetItem.downloadUrl : assetItem.previewUrl}>{assetItem.title}</a> },
      ...(uiConfig?.isDateVisible ? [{ content: formatDate(assetItem) }] : []),
      { content: getAssetLanguages(assetItem) },
      { content: `${assetItem.extension} (${FrontifyUtils.fileSizeToString(assetItem.size)})` },
      {
        content: (
          <a
            href={assetItem.downloadUrl ? assetItem.downloadUrl : assetItem.previewUrl}
            aria-label={assetItem.title}
            rel="noreferrer"
            target="_blank"
          >
            <IconDownload color={Colors.intenseBlue} className={classNames(styles['content__icon'])} />
          </a>
        ),
      },
    ]);

    return <Table className={styles['table']} name="media" head={head} body={data}></Table>;
  };

  return (
    <div className={classNames(styles['content'], styles[`content--${appearance}`])}>
      {appearance === 'list'
        ? renderListTable()
        : assets.map((asset) => {
            if (asset.type === 'Image' || asset.type === 'Document') {
              return (
                <PdfTeaser
                  meta={{ language: getAssetLanguages(asset), date: uiConfig?.isDateVisible ? formatDate(asset) : '' }}
                  key={uuidv4()}
                  extension={asset.extension}
                  previewUrl={asset.previewUrl + '?format=jpg&width=800' || ''}
                  src={asset.downloadUrl ? asset.downloadUrl : asset.previewUrl || ''}
                  title={asset.title}
                  size={FrontifyUtils.fileSizeToString(asset.size)}
                />
              );
            }

            if (asset.type === 'Video') {
              return (
                <VideoItem
                  key={uuidv4()}
                  title={asset.title}
                  thumbnailUrl={asset.previewUrl ? `${asset.previewUrl?.split('?')?.[0]}?format=jpg&width=800` : ''}
                  modalItems={[
                    {
                      type: 'video',
                      url: asset.downloadUrl || '',
                      alt: asset.title,
                    },
                  ]}
                  index={0}
                />
              );
            }
          })}
    </div>
  );
};
