import classNames from 'classnames';
import styles from './video.module.scss';

export interface VideoProps {
  autoplay?: boolean;
  src: string;
}

export const Video = (props: VideoProps & { className?: string }) => {
  const classes: string = classNames(styles['video'], props.className);

  return (
    <video className={classes} autoPlay={props.autoplay} data-testid={'video'} controls playsInline>
      <source src={props.src} data-testid="source"></source>
    </video>
  );
};

export default Video;
