import styles from './keyfeatures.module.scss';
import { AccordionElement, Feature, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { IFeature } from '../../interfaces';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { CustomSEOEvents, SeoActions } from '@dxp/core';

export interface KeyfeaturesProps {
  headline: string;
  copy: string;
  features: Array<IFeature>;
  simple?: boolean;
}

export const Keyfeatures = (props: KeyfeaturesProps) => {
  const dispatch = useDispatch();
  const renderAccordion = props.features.map(
    (feature: IFeature) =>
      feature.label &&
      feature.description && (
        <AccordionElement
          size={'large'}
          headline={feature.label}
          bodytext={feature.description}
          onClick={(isOpen) => isOpen && trackEvent(feature.label)}
          key={feature.code}
        />
      )
  );
  const renderFeatures = props.features.map((feature: IFeature) => (
    <Feature title={feature.label} text={feature.description} key={feature.code} />
  ));

  const trackEvent = (label: string): void => {
    dispatch(
      SeoActions.pushEvent({
        event: CustomSEOEvents.OPEN_ACCORDION,
        properties: {
          label,
        },
      })
    );
  };

  return props.features.length > 0 ? (
    <div className={styles['container']} data-elastic-include={true}>
      <motion.div
        initial={VeneerAnimation.elementEntry.initial}
        whileInView={VeneerAnimation.elementEntry.whileInView}
        viewport={{ once: true }}
        transition={VeneerAnimation.elementEntry.transition}
        className={styles['grid']}
      >
        <h2 className={classNames('heading-4', styles['headline'])} data-testid="keyFeature-headline">
          {props.headline}
        </h2>
        <p className={classNames('text-long', styles['text'])} data-testid="copy">
          {props.copy}
        </p>
        <div className={styles['accordions']}>{props.simple ? renderFeatures : renderAccordion}</div>
      </motion.div>
    </div>
  ) : null;
};

export default Keyfeatures;
