import styles from './back-button.module.scss';
import { Colors } from '@dxp/veneer/types';
import { IconArrowLong } from '@dxp/veneer/lib/decoration/icons';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import RoundButton from '../round-button/round-button';
import classNames from 'classnames';

// eslint-disable-next-line
export interface BackButtonProps {
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  buttonColor: Colors.lightGrey | Colors.white;
}

export const BackButton = ({ label, onClick, className, buttonColor }: BackButtonProps) => {
  const [goBackHovered, setGoBackHovered] = useState<boolean>(false);

  return (
    <motion.div
      className={classNames(styles['go-back'], className)}
      onMouseEnter={() => setGoBackHovered(true)}
      onMouseLeave={() => setGoBackHovered(false)}
      onClick={onClick}
    >
      <RoundButton
        variant={buttonColor}
        icon={<IconArrowLong color={Colors.intenseBlue} />}
        pseudoHover={goBackHovered}
        className={styles['go-back__icon']}
      />
      {label && <div className={styles['go-back__label']}>{label}</div>}
    </motion.div>
  );
};

export default BackButton;
