import createAkeneoClient, { AkeneoClientAPI } from '@dxp/akeneo-client';

export * from './components';
export * from './content-elements';
export * from './contstants';
export * from './interfaces';
export * from './redux';
export * from './utils';

const url = process.env['NX_CATALOG_BASE_URL'] || '';
const username = process.env['NX_CATALOG_USERNAME'] || '';
const password = process.env['NX_CATALOG_PASSWORD'] || '';
const clientId = process.env['NX_CATALOG_CLIENT_ID'] || '';
const secret = process.env['NX_CATALOG_CLIENT_SECRET'] || '';

export const akeneoClient: AkeneoClientAPI = createAkeneoClient({
  url,
  username,
  password,
  clientId,
  secret,
  // Optionally you can also pass in axiosOptions which will be passed to the Axios instance
});
