import styles from './textblock.module.scss';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import { useMemo } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { tidyHtml, VeneerAnimation } from '@dxp/veneer';

export interface TextblockProps {
  headline?: string;
  bodytext: ContentfulDocument;
  layout: 'normal' | 'centered';
  headlineType?: 'h1' | 'h2';
}

export const Textblock = ({ headline, bodytext, layout, headlineType }: TextblockProps) => {
  const content = useMemo(() => tidyHtml(documentToHtmlString(bodytext)), [bodytext]);
  const HeadlineType = useMemo(() => headlineType ?? 'h2', [headlineType]);

  return (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(styles['container'], styles['container--' + layout])}
      data-testid={'container'}
    >
      {headline && (
        <HeadlineType
          className={classNames(styles['headline'], layout === 'centered' ? 'intro--highlight' : 'heading-4')}
          data-testid={'headline'}
        >
          {headline}
        </HeadlineType>
      )}
      <div
        className={classNames(styles['textblock'], layout === 'centered' ? 'intro--start' : 'text-long')}
        data-testid={'bodytext'}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </motion.div>
  );
};

export default Textblock;
