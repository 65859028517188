import { call, put, takeEvery } from 'redux-saga/effects';
import { contentful } from '../../../utils';
import { articlePayload, ContentfulDataActions } from '../contentful-data.slice';
import {
  IArticleCategory,
  TypeArticleCategoryFields,
  TypeArticleFields,
  IArticle,
  IArticleInformation,
} from '../../../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { EntryCollection } from 'contentful';

export function* watchFetchArticles() {
  yield takeEvery(ContentfulDataActions.articlesRequested, fetchArticlesAsync);
}

function* fetchArticlesAsync(action: PayloadAction<articlePayload>) {
  try {
    const articles: EntryCollection<TypeArticleFields> = yield call(
      contentful.getArticles,
      action.payload.locale,
      action.payload.page,
      action.payload.pageSize,
      action.payload.category,
      action.payload.excludeId
    );

    const response: IArticleInformation = {
      totalCount: articles.total,
      articles: articles.items.map((article): IArticle => {
        return { id: article.sys.id, article: article.fields };
      }),
    };

    if (response) {
      yield put(ContentfulDataActions.articlesReceived(response));
    }
  } catch (error) {
    yield put(ContentfulDataActions.articlesRequestFailed());
  }
}

export function* watchFetchPinnedArticles() {
  yield takeEvery(ContentfulDataActions.pinnedArticlesRequested, fetchPinnedArticlesAsync);
}

function* fetchPinnedArticlesAsync(action: PayloadAction<string>) {
  try {
    const pinnedArticles: EntryCollection<TypeArticleFields> = yield call(contentful.getPinnedArticles, action.payload);

    const response: Array<IArticle> = pinnedArticles.items.map((article): IArticle => {
      return { id: article.sys.id, article: article.fields };
    });

    if (response) {
      yield put(ContentfulDataActions.pinnedArticlesReceived(response));
    }
  } catch (error) {
    yield put(ContentfulDataActions.pinnedArticlesRequestFailed());
  }
}

export function* watchFetchArticleCategories() {
  yield takeEvery(ContentfulDataActions.articleCategoriesRequested, fetchArticleCategoriesAsync);
}

function* fetchArticleCategoriesAsync(action: PayloadAction<string>) {
  try {
    const articleCategories: EntryCollection<TypeArticleCategoryFields> = yield call(
      contentful.getArticleCategories,
      action.payload
    );

    const response: Array<IArticleCategory> = articleCategories.items.map((category): IArticleCategory => {
      return { id: category.sys.id, category: category.fields };
    });

    if (response) {
      yield put(ContentfulDataActions.articleCategoriesReceived(response));
    }
  } catch (error) {
    yield put(ContentfulDataActions.articleCategoriesRequestFailed());
  }
}
