import styles from './loader.module.scss';
import classNames from 'classnames';
import React from 'react';
import { Colors } from '../../../types';

export interface LoaderProps {
  color: Colors;
  inline?: boolean;
}

export const Loader = (props: LoaderProps & { className?: string }) => {
  const classes = classNames(
    styles['loader'],
    styles[props.inline ? 'loader--inline' : 'loader--overlay'],
    props.className
  );
  const stopClasses = classNames(
    classNames(styles['loader__stop'], styles[props.color ? 'loader__stop--' + props.color : ''])
  );
  return (
    <div className={classes}>
      <svg
        className={styles['loader__icon']}
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={'svg'}
      >
        <defs>
          <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
            <stop className={stopClasses} stopColor="#f00" stopOpacity="0" offset="0%" />
            <stop className={stopClasses} stopColor="#f00" stopOpacity=".631" offset="63.146%" />
            <stop className={stopClasses} stopColor="#f00" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)">
            <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.9s"
                repeatCount="indefinite"
              />
            </path>
            <circle fill="#fff" cx="36" cy="18" r="1">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.9s"
                repeatCount="indefinite"
              />
            </circle>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Loader;
