import { createSelector } from '@reduxjs/toolkit';
import { CommonCommerceState } from './slice';

const selectCommonCommerceState = (state: CommonCommerceState) => state;

const selectStoreByLocale = createSelector(
  [selectCommonCommerceState, (_: CommonCommerceState, locale: string) => locale],
  (state: CommonCommerceState, locale: string) =>
    state.stores.find((store) =>
      store.attributes.locales.some((storeLocale) => storeLocale.name.toLowerCase().replace('_', '-') === locale)
    ) || null
);

const selectStoreCountries = createSelector(
  [selectCommonCommerceState, (_: CommonCommerceState, locale: string) => locale],
  (state: CommonCommerceState, locale: string) =>
    state.stores.find((store) =>
      store.attributes.locales.some((storeLocale) => storeLocale.name.toLowerCase().replace('_', '-') === locale)
    )?.attributes?.countries || []
);

const selectStorePaymentMethods = createSelector(
  [selectCommonCommerceState, (_: CommonCommerceState, locale: string) => locale],
  (state: CommonCommerceState, locale: string) =>
    state?.stores.find((store) =>
      store.attributes.locales.some((storeLocale) => storeLocale.name.toLowerCase().replace('_', '-') === locale)
    )?.paymentMethods || []
);

const selectStoreConfig = createSelector(
  [selectCommonCommerceState, (_: CommonCommerceState, locale: string) => locale],
  (state: CommonCommerceState, locale: string) =>
    state?.stores?.find((store) =>
      store.attributes.locales.some((storeLocale) => storeLocale.name.toLowerCase().replace('_', '-') === locale)
    )?.attributes?.config
);

const selectCurrentStore = createSelector(
  [selectCommonCommerceState],
  (state: CommonCommerceState) => state.currentStore
);

export const CommonSelectors = {
  selectCommonCommerceState,
  selectStoreByLocale,
  selectStoreCountries,
  selectStorePaymentMethods,
  selectCurrentStore,
  selectStoreConfig,
};
