import { ContentfulDataSelectors, ContentfulDataState, IVacancy } from '@dxp/content';
import { PlatformWebappFeatureFlags } from '@dxp/core';
import { Colors, Dropdown, KeyValuePair, RowItem, StandardButton } from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import styles from './vacancy-listing-full.module.scss';

/* eslint-disable-next-line */
export interface VacancyListingFullProps {
  intro?: string;
  category?: string;
}

interface IGroupedVacancy {
  category: string;
  entries: Array<IVacancy>;
}

interface IFilterValues {
  category?: string;
  entryLevel?: string;
  location?: string;
  search?: string;
}

const filterValuesInitial: IFilterValues = { category: '', entryLevel: '', location: '', search: '' };

// ToDo: Create for filters separate component. The same component was used on media page
const VacancyListingFull = (props: VacancyListingFullProps) => {
  const { query } = useRouter();
  const { t } = useTranslation('common');
  const items: Array<IVacancy> = useSelector((state: { contentfulData: ContentfulDataState }) =>
    ContentfulDataSelectors.selectVacancies(state.contentfulData)
  );
  const [currentRecords, setCurrenRecords] = useState<Array<IVacancy>>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [filterValues, setFilterValues] = useState<IFilterValues>(filterValuesInitial);

  const groupVacancies = (vacancies: Array<IVacancy>): Array<IGroupedVacancy> => {
    const grouped = vacancies.reduce((result: { [key: string]: Array<IVacancy> }, currentValue: IVacancy) => {
      (result[currentValue.vacancy.category] = result[currentValue.vacancy.category] || []).push(currentValue);
      return result;
    }, {});

    return Object.keys(grouped).map((key: string) => ({ category: key, entries: grouped[key] }));
  };

  const sortArray = (array: Array<any>) => {
    const sortedArray = array.sort((a, b) => a.category - b.category);
    const miscellaneous = sortedArray.find((entry) => entry.category === 'miscellaneous');
    const noMiscellaneous = sortedArray.filter((entry) => entry.category !== 'miscellaneous');
    return noMiscellaneous?.length > 0 && miscellaneous
      ? [...noMiscellaneous, miscellaneous]
      : miscellaneous
      ? [miscellaneous]
      : noMiscellaneous.length > 0
      ? noMiscellaneous
      : [];
  };

  const getUniqueCategories: (vacancies: Array<IVacancy>) => Array<KeyValuePair> = (vacancies: Array<IVacancy>) => {
    return vacancies
      .map((item) => ({
        key: item.vacancy.category ?? '',
        label: t('vacancies.fullList.category.' + item.vacancy.category),
      }))
      .filter((item, index, self) => item.key && item.label && index === self.findIndex((t) => t.key === item.key));
  };

  const getUniqueEntryLevel: (vacancies: Array<IVacancy>) => Array<KeyValuePair> = (vacancies: Array<IVacancy>) => {
    return vacancies
      .filter((item) => item.vacancy.entryLevel !== undefined)
      .map((item) => ({
        key: item.vacancy.entryLevel ?? '',
        label: t('vacancies.fullList.entryLevel.' + item.vacancy.entryLevel),
      }))
      .filter((item, index, self) => item.key && item.label && index === self.findIndex((t) => t.key === item.key));
  };

  const getUniqueLocation: (vacancies: Array<IVacancy>) => Array<KeyValuePair> = (vacancies: Array<IVacancy>) => {
    return vacancies
      .map((item) => ({
        key: item.vacancy.location?.sys.id ?? '',
        label: `${item.vacancy.location?.fields.title}${
          item.vacancy.location?.fields.country ? ' (' + item.vacancy.location?.fields.country + ')' : ''
        }`,
      }))
      .filter((item, index, self) => item.key && item.label && index === self.findIndex((t) => t.key === item.key));
  };

  const renderVacancies: (entries: Array<IVacancy>) => Array<JSX.Element> = (entries: Array<IVacancy>) =>
    entries?.map((entry: IVacancy) => (
      <Link
        href={entry.vacancy.detailLink ?? '#'}
        className={styles['vacancy']}
        target={'_blank'}
        key={entry.vacancy.title}
      >
        <RowItem
          id={'a'}
          title={entry.vacancy.title}
          meta={entry.vacancy.entryLevel ? t('vacancies.fullList.entryLevel.' + entry.vacancy.entryLevel) : ''}
          additional_information={entry.vacancy.location?.fields.title ?? ''}
          variant={'vacancy'}
          hideWishlist={!PlatformWebappFeatureFlags.wishlistButton}
          iconColor={Colors.smartBlue}
        />
      </Link>
    )) ?? [];

  const renderVacancyCategory: (vacancies: Array<IVacancy>) => Array<JSX.Element> = (vacancies: Array<IVacancy>) =>
    Array.isArray(vacancies)
      ? sortArray(groupVacancies(vacancies)).map((vacancyGroup: IGroupedVacancy, index: number) => {
          return (
            <div className={styles['vacancy__container']} key={index}>
              <h2 className={classNames(styles['vacancy__headline'], 'intro')}>
                {t('vacancies.fullList.category.' + vacancyGroup.category)}
              </h2>
              {renderVacancies(vacancyGroup.entries)}
            </div>
          );
        })
      : [<></>];

  const renderFilters = useCallback(
    () => (
      <div className={styles['vacancy__filter-list']}>
        <Dropdown
          className={styles['vacancy__filter']}
          variant={Colors.white}
          borders={'round'}
          placeholder={t('vacancies.fullList.filter.category')}
          values={getUniqueCategories(items)}
          value={filterValues.category}
          onChange={(event) =>
            setFilterValues({
              category: event.target.selectedOptions[0].value,
              entryLevel: filterValues.entryLevel,
              location: filterValues.location,
              search: filterValues.search,
            })
          }
        />
        <Dropdown
          className={styles['vacancy__filter']}
          variant={Colors.white}
          borders={'round'}
          placeholder={t('vacancies.fullList.filter.entryLevel')}
          values={getUniqueEntryLevel(items)}
          value={filterValues.entryLevel}
          onChange={(event) =>
            setFilterValues({
              category: filterValues.category,
              entryLevel: event.target.selectedOptions[0].value,
              location: filterValues.location,
              search: filterValues.search,
            })
          }
        />
        <Dropdown
          className={styles['vacancy__filter']}
          variant={Colors.white}
          borders={'round'}
          placeholder={t('vacancies.fullList.filter.location')}
          values={getUniqueLocation(items)}
          value={filterValues.location}
          onChange={(event) =>
            setFilterValues({
              category: filterValues.category,
              entryLevel: filterValues.entryLevel,
              location: event.target.selectedOptions[0].value,
              search: filterValues.search,
            })
          }
        />
        <StandardButton
          className={styles['vacancy__button']}
          label={t('reset')}
          variant={Colors.brilliantBlue}
          disabled={filterValues === filterValuesInitial}
          onClick={() => setFilterValues(filterValuesInitial)}
        />
      </div>
    ),
    [setFilterValues]
  );

  useEffect(() => {
    setCurrenRecords(items);
  }, [items]);

  useEffect(() => {
    const location = query['vacancy-location'];
    const level = query['vacancy-level'];
    const category = query['vacancy-category'] ?? props.category;
    const search = query['vacancy-search'];

    if (category || level || location || search) {
      Scroll.scroller.scrollTo(`vacancy-list`, { smooth: true });
    }
    setFilterValues({
      category: category ? category.toString() : '',
      entryLevel: level ? level.toString() : '',
      location: location ? location.toString() : '',
      search: search ? search.toString() : '',
    });
  }, [query, props.category]);

  useEffect(() => {
    // ToDo: ???
    const result: Array<IVacancy> = items
      .filter((item: IVacancy) =>
        filterValues.category
          ? item.vacancy.category === filterValues.category
          : item.vacancy.category !== filterValues.category
      )
      .filter((item: IVacancy) =>
        filterValues.entryLevel
          ? item.vacancy.entryLevel === filterValues.entryLevel
          : item.vacancy.entryLevel !== filterValues.entryLevel
      )
      .filter((item: IVacancy) =>
        filterValues.location
          ? item.vacancy.location?.sys.id === filterValues.location
          : item.vacancy.location?.sys.id !== filterValues.location
      )
      .filter(
        (item: IVacancy) =>
          item.vacancy.title
            .toLocaleLowerCase()
            .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '') ||
          item.vacancy.category
            .toLocaleLowerCase()
            .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '') ||
          (item.vacancy.entryLevel
            ? item.vacancy.entryLevel
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false) ||
          (item.vacancy.location?.fields.city
            ? item.vacancy.location?.fields.city
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false) ||
          (item.vacancy.location?.fields.country
            ? item.vacancy.location?.fields.country
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false) ||
          (item.vacancy.location?.fields.addressLine1
            ? item.vacancy.location?.fields.addressLine1
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false) ||
          (item.vacancy.location?.fields.addressLine2
            ? item.vacancy.location?.fields.addressLine2
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false) ||
          (item.vacancy.location?.fields.eMail
            ? item.vacancy.location?.fields.eMail
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false) ||
          (item.vacancy.location?.fields.phone
            ? item.vacancy.location?.fields.phone
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false) ||
          (item.vacancy.location?.fields.title
            ? item.vacancy.location?.fields.title
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false) ||
          (item.vacancy.location?.fields.zip
            ? item.vacancy.location?.fields.zip
                .toLocaleLowerCase()
                .includes(filterValues.search ? filterValues.search.toString().toLocaleLowerCase() : '')
            : false)
      );
    setTotalAmount(result.length);
    setCurrenRecords(result);
  }, [items, filterValues]);

  return (
    <>
      {props.intro && (
        <div className={styles['intro']} data-testid={'vacancy-short-intro'}>
          {props.intro}
        </div>
      )}

      {!props.category && (
        <div className={styles['vacancy__header']} id="vacancy-list">
          {renderFilters()}
          <div className={classNames(styles['vacancy__amount'], 'subtext')}>
            {t('vacancies.fullList.amount', {
              amount: totalAmount,
            })}
          </div>
        </div>
      )}
      {renderVacancyCategory(currentRecords)}
    </>
  );
};

export default VacancyListingFull;
