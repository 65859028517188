import { ICart } from '@dxp/spryker-client';
import { DeliveryState } from '@dxp/veneer';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConcreteProductActions, ConcreteProductSelectors, ConcreteProductState } from '../../../../redux';
import { CartContext } from '../../cart';
import CartItemSmall from '../cart-item/cart-item-small/cart-item-small';
import styles from './cart-list.module.scss';

interface CartListProps {
  className?: string;
  manipulatable?: boolean;
}
export function CartList({ className, manipulatable }: CartListProps) {
  const cart: ICart | undefined = useContext(CartContext);
  const { locale } = useRouter();
  const dispatch = useDispatch();

  // const [skus, setSkus] = useState<Array<string> | []>([]);

  const concreteProducts = useSelector((state: { concreteProduct: ConcreteProductState }) =>
    ConcreteProductSelectors.selectConcreteProducts(
      state.concreteProduct,
      cart?.items?.map((cartItem) => cartItem.sku) || []
    )
  );

  useEffect(() => {
    if (cart) {
      cart.items.forEach((cartItem) => {
        dispatch(ConcreteProductActions.concreteProductRequested({ sku: cartItem.sku, locale: locale }));
      });
    }
  }, [cart?.items]);

  return cart && cart.items.length ? (
    <div className={classNames(styles['container'], className)}>
      <div className={styles['list']}>
        {cart?.items.map((item) => {
          const concreteProduct = concreteProducts.find((product) => product.concreteProduct.sku === item.sku);

          const deliveryState =
            concreteProduct?.concreteProduct.isRealtime && concreteProduct.concreteProduct.isAvailable
              ? DeliveryState.AVAILABLE
              : DeliveryState.AVAILABLE_SOON;

          return (
            <CartItemSmall item={item} key={item.sku} manipulatable={manipulatable} deliveryState={deliveryState} />
          );
        })}
      </div>
      <span className={styles['dropshadow']}></span>
    </div>
  ) : (
    <React.Fragment />
  );
}

export default CartList;
