import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export interface LanguageSwitchState {
  [locale: string]: string;
}

const initialState: LanguageSwitchState = {};

export const languageSwitchSlice = createSlice({
  name: 'languageSwitch',
  initialState,
  reducers: {
    push: (state, action: PayloadAction<LanguageSwitchState>) => ({
      ...state,
      ...action.payload,
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action['payload'].languageSwitch,
      };
    });
  },
});

export const LanguageSwitchActions = languageSwitchSlice.actions;

export default languageSwitchSlice.reducer;
