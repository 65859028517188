import { AxiosInstance, AxiosResponse } from 'axios';
import {
  IAddressDataResponse,
  IAddressItem,
  ICreateAddressRequest,
  IDeleteAddressRequest,
  IPatchAddressRequest,
} from '../interfaces';
import raw from './raw';

export const getAddresses = (
  http: AxiosInstance,
  { customerRef }: { customerRef: string }
): Promise<Array<IAddressItem> | null> => {
  return raw.get(http, `customers/${customerRef}/addresses`, {}).then((response) => {
    if (response?.data) {
      return response.data.data.map(
        (address: IAddressDataResponse): IAddressItem => ({ id: address.id, ...address.attributes })
      );
    } else {
      return null;
    }
  });
};

export const create = (
  http: AxiosInstance,
  { payload }: { payload: ICreateAddressRequest }
): Promise<Array<IAddressItem> | null> => {
  return http
    .post(`customers/${payload.customerRef}/addresses`, {
      data: { type: 'addresses', attributes: payload.attributes },
    })
    .then((response: AxiosResponse<any>) => {
      if (response?.data) {
        return { id: response.data.data.id, ...response.data.data.attributes };
      } else {
        return null;
      }
    });
};

export const patchAddress = (
  http: AxiosInstance,
  { payload }: { payload: IPatchAddressRequest }
): Promise<Array<IAddressItem> | null> => {
  return http
    .patch(`customers/${payload.customerRef}/addresses/${payload.addressId}`, {
      data: { type: 'addresses', attributes: payload.updatedFields },
    })
    .then((response) => {
      if (response?.data) {
        return { id: response.data.data.id, ...response.data.data.attributes };
      } else {
        return null;
      }
    });
};

export const deleteAddress = (
  http: AxiosInstance,
  { payload }: { payload: IDeleteAddressRequest }
): Promise<number | null | void> => {
  return http.delete(`customers/${payload.customerRef}/addresses/${payload.addressId}`, {}).then((response) => {
    if (response) {
      return response.status;
    } else {
      return null;
    }
  });
};
