import styles from './word-cloud-line-split.module.scss';
import { useEffect, useRef, useState } from 'react';
import WordCloudLine from '../word-cloud-line/word-cloud-line';

export interface WordCloudLineSplitProps {
  text: string;
}

export function WordCloudLineSplit(props: WordCloudLineSplitProps) {
  const [lines, setLines] = useState<Array<string>>([]);
  const testRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const split = () => {
      const testEl = testRef.current;
      let line: Array<string> = [];
      let output: Array<string> = [];

      props.text.split(',').forEach((word) => {
        if (testEl) {
          testEl.innerHTML = (testEl.innerHTML + ' ' + word).trim();

          if (testEl.scrollWidth > testEl.clientWidth) {
            testEl.innerHTML = word;
            output = output.concat(line.join());
            line = [];
          }
        }
        line = line.concat(word + ',');
      });
      output = output.concat(line.join());

      setLines(output);
    };

    split();
    window.addEventListener('resize', split);
  }, [props.text]);

  useEffect(() => {
    // dispatch it once mounted -> fix split text width bug
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }, []);

  return (
    <>
      <div ref={testRef} className={styles['test']} />
      <div className={styles['container']}>
        {lines.map((line, index) => (
          <WordCloudLine key={'line' + index} words={line.split(',').filter((v) => v !== '')} showDivider={true} />
        ))}
      </div>
    </>
  );
}

export default WordCloudLineSplit;
