import { ConcreteProductState } from './slice';
import { createSelector } from '@reduxjs/toolkit';

const selectConcreteProductState = (state: ConcreteProductState) => state;

const selectConcreteProduct = createSelector(
  [selectConcreteProductState, (state: ConcreteProductState, sku: string | undefined) => sku],
  (state: ConcreteProductState, sku: string | undefined) => (sku ? state.concreteProducts[sku] : undefined)
);

const selectConcreteProducts = createSelector(
  [selectConcreteProductState, (state: ConcreteProductState, skus: Array<string>) => skus],
  (state: ConcreteProductState, skus: Array<string>) =>
    Object.entries(state.concreteProducts)
      .filter(([sku, concreteProduct]) => skus.some((s) => s === sku))
      .map(([sku, concreteProduct]) => concreteProduct)
);

export const ConcreteProductSelectors = {
  selectConcreteProductState,
  selectConcreteProduct,
  selectConcreteProducts,
};
