import styles from './newsletter-registration-box.module.scss';
import { TypeCeNewsletterRegistrationBoxFields } from '../../types';
import { Colors, HubspotForm, Input, StandardButton } from '@dxp/veneer';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { HubSpotSelectors, HubSpotState } from '@dxp/core';

export function NewsletterRegistrationBox({ headline, hubspotFormId }: TypeCeNewsletterRegistrationBoxFields) {
  const { t } = useTranslation('common');
  const [inputValue, setInputValue] = useState<string>('');
  const portalId = useSelector((state: { hubSpot: HubSpotState }) => HubSpotSelectors.selectPortalId(state.hubSpot));
  const { control, formState, handleSubmit } = useForm({ mode: 'onTouched' });

  const onSubmit: (values: FieldValues) => void = (values: FieldValues) => {
    setInputValue(values['email'] ? values['email'] : '');
  };

  return (
    <div className={styles['container']}>
      <div className={styles['inner']}>
        <h2
          className={styles['headline']}
          dangerouslySetInnerHTML={{ __html: headline || '' }}
          data-testid={'newsletter-headline'}
        ></h2>
        <AnimatePresence mode={'wait'} initial={true}>
          {inputValue === '' && (
            <motion.div
              key={'input'}
              className={styles['step-1']}
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.5,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)} className={styles['form']} suppressHydrationWarning={true}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name={'email'}
                  rules={{ required: true }}
                  render={({ field: { name, onChange, ref, value, onBlur }, fieldState: { error } }) => (
                    <Input
                      error={error?.type ? t('form.error.required') : ''}
                      name={name}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      autocomplete="email"
                      className={styles['step-1__input']}
                      label={''}
                      required={true}
                      placeholder={t('newsletter.step1.placeholder')}
                      type="text"
                      variant={Colors.white}
                    />
                  )}
                />
                <div className={styles['form__controls']}>
                  <StandardButton
                    disabled={!formState.isValid}
                    label={t('newsletter.step1.button')}
                    type="submit"
                    variant={Colors.brilliantBlue}
                    className={styles['step-1__button']}
                  />
                </div>
              </form>
            </motion.div>
          )}
          {hubspotFormId && inputValue !== '' && (
            <motion.div
              key={'form'}
              className={styles['step-2']}
              initial={{ opacity: 0, height: '0' }}
              animate={{
                opacity: 1,
                height: 'auto',
                transition: {
                  opacity: { duration: 0.25, delay: 0.15 },
                  height: { duration: 0.4 },
                },
              }}
              exit={{
                opacity: 0,
                height: '0',
                transition: {
                  opacity: { duration: 0.25 },
                  height: { duration: 0.4 },
                },
              }}
            >
              <HubspotForm
                portalId={portalId}
                formId={hubspotFormId ? hubspotFormId : ''}
                region={'na1'}
                color={Colors.white}
                buttonPosition={'left'}
                hideLoader={true}
                inputValues={[
                  {
                    name: 'email',
                    value: inputValue,
                  },
                ]}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default NewsletterRegistrationBox;
