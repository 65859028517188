import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

export interface ICompatibleMachine {
  identifier: string;
  name: string;
  compatibleMaterialCodes: Array<string>;
}

/**
 * Mapping configuration for ICompatibleMachineConfig interface
 */
export const ICompatibleMachineMappingConfig: MappingConfig = {
  identifier: {
    path: ['identifier'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  name: {
    path: ['values', 'name'],
    valueData: ValueDataType.string,
    localized: true,
  },
  compatibleMaterialCodes: {
    path: ['associations', 'machine_material_compatibility', 'product_models'],
    valueData: ValueDataType.direct,
    localized: false,
    additionalDataCode: 'compatibleMaterials',
  },
};
