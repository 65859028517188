export const PlatformWebappFeatureFlags = {
  mediaPage: true,
  wishlistButton: false,
  learnMore: false,
  islandNav: false,
  account: {
    orders: true,
    order: {
      invoiceDownload: false,
    },
    billingMethods: false,
  },
  commerce: {
    // enables/disables ecommerce functionalities depending on locale
    enable: {
      'de-de': false,
      'en-us': false,
      'en-gb': false,
    } as { [key: string]: boolean },
    hidePrice: {
      'de-de': false,
      'en-us': false,
      'en-gb': false,
    } as { [key: string]: boolean },
    respectAvailableInRegion: true,
  },
};
