import { Colors, IconPlay, RoundButton } from '@dxp/veneer';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalActions, ModalContentsInterface, ModalItemInterface } from '../../redux';
import styles from './video-item.module.scss';

export interface VideoSliderItemProps {
  title: string;
  thumbnailUrl: string;
  modalItems: ModalItemInterface[];
  index?: number;
}

const variants = {
  scaleInactive: { scale: 1, transition: { duration: 0.4, easeInOut: [0.65, 0, 0.35, 1] } },
  scaleHovered: { scale: 1.06, transition: { duration: 0.4, easeInOut: [0.65, 0, 0.35, 1] } },
};

export const VideoItem = (props: VideoSliderItemProps) => {
  const { t } = useTranslation('common');

  const dispatch = useDispatch();
  const [activeHover, setActiveHover] = useState<number | null>(null);

  const modalContents: ModalContentsInterface = {
    items: props.modalItems,
    ...(props.index && { activeItem: props.index }),
  };

  return (
    <div
      className={styles['button']}
      onClick={() => dispatch(ModalActions.open(modalContents))}
      onMouseEnter={() => setActiveHover(props.index ?? 0)}
      onMouseLeave={() => setActiveHover(null)}
    >
      <div className={styles['image-container']}>
        <motion.div
          className={styles['image']}
          variants={variants}
          animate={activeHover === props.index ? 'scaleHovered' : 'scaleInactive'}
        >
          <Image
            className={styles['img']}
            src={props.thumbnailUrl}
            fill={true}
            alt={props.title}
            data-testid={'image'}
          />
        </motion.div>
        <RoundButton
          className={styles['icon']}
          icon={<IconPlay />}
          size="medium"
          fillOnHover={true}
          title={t('play')}
          pseudoHover={activeHover !== null}
          variant={Colors.brilliantBlue}
        />
      </div>
      {props.title && (
        <span className={classNames(styles['title'], 'text-short-medium')} data-testid={'title'}>
          {props.title}
        </span>
      )}
    </div>
  );
};

export default VideoItem;
