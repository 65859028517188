import styles from './input-dropdown-combo.module.scss';
import Input from '../../form/input/input';
import { Colors } from '../../../types';
import Dropdown, { KeyValuePair } from '../../form/dropdown/dropdown';
import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { IconSearch } from '@dxp/veneer/lib/decoration/icons';

export interface InputDropdownComboProps {
  input: InputDropdownInput;
  dropdown: InputDropdownDropdown;
}

export interface InputDropdownInput {
  placeholder: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export interface InputDropdownDropdown {
  placeholder: string;
  values: Array<KeyValuePair>;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export const InputDropdownCombo = (props: InputDropdownComboProps & { className?: string }) => {
  return (
    <div className={classNames(styles['container'], props.className)}>
      <Input
        className={styles['input']}
        type={'text'}
        icon={<IconSearch />}
        placeholder={props.input?.placeholder}
        variant={Colors.lightGrey}
        borders={'round'}
        onChange={props.input?.onChange}
      />
      {!!props.dropdown?.values.length && (
        <Dropdown
          className={styles['dropdown']}
          values={props.dropdown.values}
          variant={Colors.lightGrey}
          placeholder={props.dropdown?.placeholder}
          borders={'round'}
          onChange={props.dropdown?.onChange}
        />
      )}
    </div>
  );
};

export default InputDropdownCombo;
