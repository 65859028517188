import { IAddressItem, ICreateAddressRequest, IDeleteAddressRequest, IPatchAddressRequest } from '@dxp/spryker-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateError = string | { [key: string]: string } | null;

export interface AddressErrors {
  addressesRequested: StateError;
  createAddress: StateError;
  deleteAddress: StateError;
  updateAddress: StateError;
}

/**
 * The interface for address data provided in redux store.
 */
export interface AddressState {
  loading: boolean;
  addresses: Array<IAddressItem> | null;
  error: AddressErrors;
}

/**
 * The initial address redux state.
 */
const initialState: AddressState = {
  loading: false,
  addresses: null,
  error: {
    addressesRequested: null,
    createAddress: null,
    deleteAddress: null,
    updateAddress: null,
  },
};

/**
 * The address redux slice.
 */
export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    clearAddresses: (state) => {
      state.addresses = initialState.addresses;
      state.error = initialState.error;
    },
    clearError: (state, action: PayloadAction<keyof AddressErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },

    addressesRequested: (state, action: PayloadAction<{ locale: string | undefined }>) => {
      state.loading = true;
      state.addresses = initialState.addresses;
      state.error.addressesRequested = null;
    },
    addressesReceived: (state, action: PayloadAction<Array<IAddressItem>>) => {
      state.loading = false;
      state.addresses = action.payload;
      state.error.addressesRequested = null;
    },
    addressesRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.loading = false;
      state.addresses = initialState.addresses;
      state.error.addressesRequested = action.payload;
    },

    createAddress: (
      state,
      action: PayloadAction<
        ICreateAddressRequest & { checkoutStep?: 'billing' | 'shipping'; return?: boolean; locale: string | undefined }
      >
    ) => {
      state.loading = true;
      state.error.createAddress = null;
    },
    createAddressSuccess: (state, action: PayloadAction<IAddressItem>) => {
      state.loading = false;
      if (state.addresses) {
        if (action.payload.isDefaultBilling) {
          state.addresses.forEach((address) => (address.isDefaultBilling = false));
        }
        if (action.payload.isDefaultShipping) {
          state.addresses.forEach((address) => (address.isDefaultShipping = false));
        }
        state.addresses = state.addresses.concat([action.payload]);
      } else {
        state.addresses = [action.payload];
      }

      state.error.createAddress = null;
    },
    createAddressFailed: (state, action: PayloadAction<StateError>) => {
      state.loading = false;
      state.error.createAddress = action.payload;
    },

    updateAddress: (
      state,
      action: PayloadAction<{ request: IPatchAddressRequest; return?: boolean; locale: string | undefined }>
    ) => {
      state.loading = true;
      state.error.updateAddress = null;
    },
    updateAddressSuccess: (state) => {
      state.loading = false;
      state.error.updateAddress = null;
    },
    updateAddressFailed: (state, action: PayloadAction<StateError>) => {
      state.loading = false;
      state.error.updateAddress = action.payload;
    },

    deleteAddress: (state, action: PayloadAction<IDeleteAddressRequest & { locale: string | undefined }>) => {
      state.loading = true;
      state.error.deleteAddress = null;
    },
    deleteAddressSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      if (state.addresses) {
        state.addresses = state.addresses.filter((address: IAddressItem) => address.id !== action.payload);
      }
      state.error.deleteAddress = null;
    },
    deleteAddressFailed: (state, action: PayloadAction<StateError>) => {
      state.loading = false;
      state.error.deleteAddress = action.payload;
    },
  },
});

export const AddressActions = addressSlice.actions;

export default addressSlice.reducer;
