import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import ToasterMessageInterface from '../../components/toaster/toasterMessageInterface';
import { v4 as uuidv4 } from 'uuid';

export interface ToasterState {
  stack: Array<ToasterMessageInterface>;
}

const initialState: ToasterState = {
  stack: [],
};

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<ToasterMessageInterface>) => {
      state.stack = [{ id: uuidv4(), ...action.payload }, ...state.stack];
    },
    remove: (state, action: PayloadAction<string>) => {
      state.stack = state.stack.filter((toast) => toast.id !== action.payload);
    },
    clearAll: (state) => {
      state.stack = [];
    },
  },
});

const { add, remove, clearAll } = toasterSlice.actions;

export const ToasterActions = {
  add,
  remove,
  clearAll,
};

export default toasterSlice.reducer;

export interface ToasterStateInterface {
  toaster: ToasterState;
}
