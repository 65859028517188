import raw from './raw';
import { AxiosInstance, AxiosResponse } from 'axios';
import {
  ICustomerLoginData,
  ICustomerLoginDataParsed,
  ICustomerLoginRawResponse,
  IForgotPasswordBodyAttribute,
  IRequestBody,
  IRestorePasswordPayload,
} from '../interfaces';
import { parseLoginDataResponse } from '../helper';
import { localStorageKey } from '../index';

/**
 * Login customer by getting an access token
 * @see https://docs.spryker.com/docs/pbc/all/identity-access-management/202204.0/manage-using-glue-api/glue-api-authenticate-as-a-customer.html#installation
 * @param {AxiosInstance} http the axios instance
 * @param {{ payload: ICustomerLoginData }} object with parameter payload of interface ICustomerLoginData containing credentials
 * @return {Promise<string | null>} Promise with customerRef or null
 */
export const login = (http: AxiosInstance, { payload }: { payload: ICustomerLoginData }): Promise<string | null> => {
  const body: IRequestBody = { data: { type: 'access-tokens', attributes: payload } };
  return raw
    .post(
      http,
      `access-tokens`,
      body,
      // { withCredentials: true }
      { headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId } }
    )
    .then((response: AxiosResponse<ICustomerLoginRawResponse>) => {
      if (response?.data) {
        const iCustomerLoginDataParsed: ICustomerLoginDataParsed | null = parseLoginDataResponse(response.data);
        if (typeof window !== 'undefined' && iCustomerLoginDataParsed) {
          localStorage.setItem(localStorageKey, JSON.stringify(iCustomerLoginDataParsed));
        }
        return iCustomerLoginDataParsed?.customerRef || null;
      } else {
        return null;
      }
    });
};

export const forgotPassword = (
  http: AxiosInstance,
  { payload }: { payload: IForgotPasswordBodyAttribute }
): Promise<boolean> => {
  const body: IRequestBody = { data: { type: 'customer-forgotten-password', attributes: payload } };
  return raw
    .post(
      http,
      `customer-forgotten-password`,
      body,
      // { withCredentials: true }
      {}
    )
    .then((response) => response?.status >= 200 && response.status < 300);
};

export const restorePassword = (
  http: AxiosInstance,
  { payload }: { payload: IRestorePasswordPayload }
): Promise<boolean> => {
  const body: IRequestBody = { data: { type: 'customer-restore-password', attributes: payload } };
  return raw
    .patch(
      http,
      `customer-restore-password/${payload.restorePasswordKey}`,
      body,
      // { withCredentials: true },
      {}
    )
    .then((response) => response?.status >= 200 && response.status < 300);
};
