import { call, put, takeEvery } from 'redux-saga/effects';
import {
  AssetFamilyPayloadAction,
  AssetFamilyStateProperty,
  CatalogActions,
  ProductModelPayloadAction,
  ReferenceEntityPayloadAction,
  ReferenceEntityStateProperty,
} from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  ListResponse,
  MappingPropertyConfig,
  mapProductModel,
  ProductModel,
  ProductModelQueryParameters,
} from '@dxp/akeneo-client';
import {
  akeneoClient,
  AkeneoSearchObject,
  fetchAssetFamilyAsync,
  fetchReferenceEntityAsync,
  iProductImageMappingConfig,
  iTechnicalDataConfig,
  normalizeLocale,
} from '../../index';
import { PlatformWebappFeatureFlags, toUppercaseLocale } from '@dxp/core';

/**
 * The watcher function to fetch product models.
 */
export function* watchFetchProductModel() {
  yield takeEvery(CatalogActions.productModelRequested, fetchProductModelAsync);
}

/**
 * The worker function to fetch product models.
 * @param action
 */
export function* fetchProductModelAsync(action: PayloadAction<ProductModelPayloadAction>) {
  const { codes, mappingConfig } = action.payload;
  let { locale } = action.payload;

  locale = normalizeLocale(locale);

  try {
    let searchObject: AkeneoSearchObject = { identifier: [{ operator: 'IN', value: codes }] };

    if (PlatformWebappFeatureFlags.commerce.respectAvailableInRegion) {
      locale = normalizeLocale(locale);

      searchObject = {
        ...searchObject,
        available_in_region: [{ operator: 'IN', value: [locale] }],
      };
    }

    const query: ProductModelQueryParameters = {
      search: JSON.stringify(searchObject),
    };

    const productModelResponse: ListResponse & { items: Array<ProductModel> } = yield call(
      akeneoClient.productModel.getAll,
      codes.length ? { query } : {}
    );

    const data: Array<any> = productModelResponse.items.map((productModel: ProductModel) =>
      mapProductModel(productModel, action.payload.mappingConfig, locale)
    );

    const assetFamilyActionPayloads: Array<AssetFamilyPayloadAction> = [
      {
        code: 'product_group_pictures_asset_family',
        stateProperty: AssetFamilyStateProperty.productImages,
        mappingConfig: iProductImageMappingConfig,
        locale,
        codes: [],
      },
    ];

    const referenceEntityActionPayloads: Array<ReferenceEntityPayloadAction> = [
      {
        code: 'technical_data',
        stateProperty: ReferenceEntityStateProperty.technicalData,
        mappingConfig: iTechnicalDataConfig,
        locale,
        codes:
          data.map((item) => (item && Array.isArray(item.technicalDataCodes) ? item.technicalDataCodes : [])).flat() ||
          [],
      },
    ];

    for (const assetFamilyActionPayload of assetFamilyActionPayloads.filter(
      (assetFamilyPayloadAction: AssetFamilyPayloadAction) =>
        Object.values(mappingConfig).some(
          (value: MappingPropertyConfig | string) =>
            typeof value !== 'string' && value?.additionalDataCode === assetFamilyPayloadAction.code
        )
    )) {
      const dataAttribute: string =
        Object.keys(mappingConfig).find(
          (key: string) =>
            typeof mappingConfig[key] !== 'string' &&
            (mappingConfig[key] as MappingPropertyConfig)?.additionalDataCode === assetFamilyActionPayload.code
        ) || '';

      const codes: Array<string> = data.reduce(
        (acc, val) => (val[dataAttribute] ? acc.concat(val[dataAttribute]) : acc),
        []
      );

      if (codes?.length > 0) {
        yield fetchAssetFamilyAsync({
          payload: { ...assetFamilyActionPayload, codes },
          type: CatalogActions.assetFamilyRequested.type,
        });
      }
    }

    for (const referenceEntityActionPayload of referenceEntityActionPayloads.filter(
      (referenceEntityPayloadAction: ReferenceEntityPayloadAction) =>
        Object.values(mappingConfig).some(
          (value: MappingPropertyConfig | string) =>
            typeof value !== 'string' && value?.additionalDataCode === referenceEntityPayloadAction.code
        ) && referenceEntityPayloadAction.codes.length > 0
    )) {
      yield fetchReferenceEntityAsync({
        payload: referenceEntityActionPayload,
        type: CatalogActions.referenceEntityRequested.type,
      });
    }

    yield put(CatalogActions.productModelReceived({ ...action.payload, data }));
  } catch (error) {
    yield put(CatalogActions.productModelRequestFailed(action.payload));
  }
}
