import styles from './pill.module.scss';
import classNames from 'classnames';
import { Colors } from '../../../types';

export interface PillProps {
  label: string;
  active?: boolean;
  disabled?: boolean;
  interactive?: boolean;
  size?: 'standard' | 'small';
  onClick?: () => void;
  variant?: 'outline' | 'feelgood-mint-outline' | Colors.brilliantBlue;
}

export const Pill = (props: PillProps & { className?: string }) => {
  const classes: string = classNames(
    styles['inner'],
    props.className,
    { [styles['active']]: props.active },
    { [styles['disabled']]: props.disabled },
    { [styles['interactive']]: props.interactive && !props.disabled },
    { [styles['brilliant-blue']]: props.variant === Colors.brilliantBlue },
    { [styles['feelgood-mint-outline']]: props.variant === 'feelgood-mint-outline' },
    { [styles['standard']]: !props.size || props.size === 'standard' },
    { [styles['small']]: props.size === 'small' }
  );
  return (
    <div className={classes} onClick={!props.disabled ? props.onClick : () => null} data-testid={'label'}>
      {props.label}
    </div>
  );
};

export default Pill;
