import { IFact } from '../../interfaces';
import Facts from '@dxp/veneer/lib/molecule/facts/facts';

export interface FactsFiguresProps {
  facts: IFact[];
}

export const FactsFigures = ({ facts }: FactsFiguresProps) => {
  return facts.length > 0 ? <Facts facts={facts} /> : null;
};

export default FactsFigures;
