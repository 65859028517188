import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';

const searchKey = process.env['NX_ELASTIC_SEARCH_KEY'];
const engineName = process.env['NX_ELASTIC_ENGINE_NAME'];
const endpointBase = process.env['NX_ELASTIC_ENDPOINT_BASE'];

export const appSearchAPIConnector: AppSearchAPIConnector | null =
  searchKey && searchKey !== '' && engineName && engineName !== '' && endpointBase && endpointBase !== ''
    ? new AppSearchAPIConnector({
        searchKey,
        engineName,
        endpointBase,
      })
    : null;
