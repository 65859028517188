import {
  EIncludeTypes,
  ICatalogSearchRawResponse,
  ICatalogSearchRowIncludedResponse,
  IProductLabelsCollectionResponse,
  IProductLabelsRowIncludedResponse,
  IProductRelationsIncluded,
} from '../../types';
import {
  ICatalogSearchDataParsed,
  IFilterValue,
  IValueFacets,
  TActiveFilters,
  TActiveRangeFilters,
} from '../../interfaces';
import { rangeFilterValueToFront } from '../index';
import { rangeMaxType, rangeMinType } from '../../constants';
import { getAvailableLables, getProductLabel, parsePrices } from './common';

export const parseCatalogSearchResponse = (response: ICatalogSearchRawResponse): ICatalogSearchDataParsed | null => {
  if (!response) {
    return null;
  }

  const { data, included }: ICatalogSearchRawResponse = response;

  if (!data || !data[0]) {
    return null;
  }

  const attributes = data[0].attributes;
  const pagination = attributes.pagination;
  const filters: IValueFacets[] = [];
  const activeFilters: TActiveFilters = {};
  const activeRangeFilters: TActiveRangeFilters = {};
  const currentSort: string = attributes.sort.currentSortParam || ' ';
  const currentPaginationPage: number = pagination.currentPage;
  let category: IFilterValue[] = [];
  let currentCategoryId: number | null = null;
  let categoriesLocalizedName: string | null = null;

  attributes.valueFacets.forEach((filter: IValueFacets) => {
    if (filter.name === 'category') {
      category = Array.isArray(filter.values) ? filter.values : [];
      currentCategoryId = Number(filter.activeValue);
      categoriesLocalizedName = filter.localizedName;
    } else {
      filters.push(filter);

      if (filter.activeValue) {
        activeFilters[filter.name] = Array.isArray(filter.activeValue) ? filter.activeValue : [filter.activeValue];
      }
    }
  });

  attributes.rangeFacets.forEach((range) => {
    if (range.activeMin !== range.min || range.activeMax !== range.max) {
      activeRangeFilters[range.name] = {
        min: rangeFilterValueToFront(range.activeMin, rangeMinType),
        max: rangeFilterValueToFront(range.activeMax, rangeMaxType),
      };
    }
  });

  const parseProductItems = attributes.abstractProducts.map((item) => ({
    ...item,
    labels: undefined,
    image: item.images?.find((imageItem) => imageItem.externalUrlSmall)?.externalUrlSmall || '',
    prices: parsePrices(item.prices, item.price),
  }));

  const result: ICatalogSearchDataParsed = {
    items: parseProductItems || [],
    filters,
    activeFilters,
    category,
    currentCategoryId,
    currentSort,
    currentItemsPerPage: attributes.pagination.currentItemsPerPage,
    currentPaginationPage,
    rangeFilters: attributes.rangeFacets,
    activeRangeFilters,
    sortParams: attributes.sort.sortParamNames,
    sortParamLocalizedNames: attributes.sort.sortParamLocalizedNames,
    categoriesLocalizedName,
    pagination: {
      numFound: pagination.numFound,
      currentPage: pagination.currentPage,
      maxPage: pagination.maxPage,
      currentItemsPerPage: pagination.currentItemsPerPage,
      validItemsPerPageOptions: pagination.config.validItemsPerPageOptions,
    },
    spellingSuggestion: attributes.spellingSuggestion,
  };

  if (!included) {
    return result;
  }

  const availableLabels: IProductLabelsCollectionResponse = getAvailableLables(
    included as unknown as Array<IProductRelationsIncluded>
  );

  included.forEach((row: ICatalogSearchRowIncludedResponse) => {
    const isProductHasLabels =
      row.type === EIncludeTypes.ABSTRACT_PRODUCTS &&
      row.relationships &&
      row.relationships[EIncludeTypes.PRODUCT_LABELS] &&
      availableLabels;

    if (isProductHasLabels) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const labelsIdArr: string[] = (row as IProductLabelsRowIncludedResponse).relationships[
        EIncludeTypes.PRODUCT_LABELS
      ].data.map((item) => item.id);
      const appropriateResultItem = result.items.filter((item) => item.abstractSku === row.id)[0];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      appropriateResultItem.labels = getProductLabel(labelsIdArr, availableLabels);
    }
  });

  return result;
};
