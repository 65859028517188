import styles from './special-information-teaser.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';

export interface SpecialInformationTeaserProps {
  title: string;
  copy: string;
  imageUrl: string;
  linkUrl: string;
}

export const SpecialInformationTeaser = (props: SpecialInformationTeaserProps & { className?: string }) => (
  <Link href={props.linkUrl} className={classNames(styles['container'], props.className)}>
    <>
      <div className={styles['image']}>
        <Image src={props.imageUrl} alt={props.title} width={560} height={740} data-testid={'image'} />
      </div>
      <div className={classNames(styles['title'], 'text-short-medium')} data-testid={'title'}>
        {props.title}
      </div>
      <p className={classNames(styles['text'], 'text-short')} data-testid={'bodytext'}>
        {props.copy}
      </p>
    </>
  </Link>
);

export default SpecialInformationTeaser;
