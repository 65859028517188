import styles from './headline.module.scss';
import { motion } from 'framer-motion';
import { tidyHtml, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { useMemo } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Document as ContentfulDocument } from '@contentful/rich-text-types/dist/types/types';

export interface HeadlineProps {
  headline?: string;
  intro: ContentfulDocument | string;
  type: 'h1' | 'h1-indented' | 'section-headline';
}

export const Headline = ({ headline, intro, type, greyBackground }: HeadlineProps & { greyBackground?: boolean }) => {
  const HeadlineTag = `h${type === 'h1' || type === 'h1-indented' ? 1 : 2}` as keyof JSX.IntrinsicElements;
  const introText: string | null = useMemo(
    () => (typeof intro === 'string' ? intro : tidyHtml(documentToHtmlString(intro))),
    [intro]
  );

  return headline ? (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(
        styles['container'],
        styles[`container--${type}`],
        greyBackground ? styles['container--grey'] : ''
      )}
      data-testid={'container'}
    >
      <HeadlineTag className={styles['headline']} data-testid={'headline'}>
        {headline}
      </HeadlineTag>

      {intro && (
        <div className={styles['intro']} data-testid={'intro'} dangerouslySetInnerHTML={{ __html: introText }}></div>
      )}
    </motion.div>
  ) : null;
};

export default Headline;
