import { Asset, AssetQueryParameters, ListResponse, mapAsset } from '@dxp/akeneo-client';
import { toUppercaseLocale } from '@dxp/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { akeneoClient } from '../../index';
import { AssetFamilyPayloadAction, CatalogActions } from '../slice';

const MAXIMUM_FETCH_SIZE = 100;

export function* watchFetchAssetFamily() {
  yield takeEvery(CatalogActions.assetFamilyRequested, fetchAssetFamilyAsync);
}

export function* fetchAssetFamilyAsync(action: PayloadAction<AssetFamilyPayloadAction>) {
  const { payload } = action;
  const assetFamilyCode = action.payload.code;

  const initialQuery: AssetQueryParameters = {
    search: JSON.stringify({ code: [{ operator: 'IN', value: action.payload.codes }] }),
  };

  let continueLoop = true;
  let assetsResult: Array<Asset> = [];

  while (continueLoop) {
    const search_after = assetsResult.length ? assetsResult[assetsResult.length - 1].code.toLowerCase() : '';

    try {
      const { items }: ListResponse & { items: Array<Asset> } = yield call(akeneoClient.assetFamily.getAssets, {
        assetFamilyCode,
        query: {
          ...initialQuery,
          ...(search_after && {
            search_after,
          }),
        },
      });

      assetsResult = assetsResult.concat(items);

      if (items.length < MAXIMUM_FETCH_SIZE) {
        continueLoop = false;
      }
    } catch (error) {
      continueLoop = false;
      yield put(CatalogActions.assetFamilyRequestFailed(payload));
    }
  }

  if (!assetsResult.length) {
    return;
  }

  const data: Array<any> = assetsResult.map((asset: Asset) =>
    mapAsset(asset, action.payload.mappingConfig, toUppercaseLocale(action.payload.locale))
  );

  yield put(CatalogActions.assetFamilyReceived({ ...payload, data }));
}
