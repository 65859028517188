import styles from './catalog-teaser-slider.module.scss';
import { SwiperSlide } from 'swiper/react';
import { VeneerAnimation } from '@dxp/veneer';
import { SliderWrapper } from '@dxp/core';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { v4 as uuidv4 } from 'uuid';

export interface CatalogTeaserSliderProps {
  copy: string;
  children: React.ReactElement[];
}

export const CatalogTeaserSlider = (props: CatalogTeaserSliderProps) => {
  const { t } = useTranslation('common');
  const breakpoints = {
    760: {
      slidesPerView: 1.75,
    },
    1020: {
      slidesPerView: 3.1,
    },
  };

  const renderSlides = props.children.map((child, index) => {
    return (
      <SwiperSlide key={uuidv4()} className={styles['slide']}>
        {child}
      </SwiperSlide>
    );
  });

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
    >
      <SliderWrapper
        headline={t('product.detail.catalogTeaserSlider.headline')}
        copy={props.copy}
        loop={false}
        breakpoints={breakpoints}
        arrows={true}
      >
        {renderSlides}
      </SliderWrapper>
    </motion.div>
  );
};

export default CatalogTeaserSlider;
