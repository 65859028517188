import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MainNavigationState {
  mainNavigationOpen: boolean;
  mainNavigationLevel1: string | null;
  localeOverlayIsOpen: boolean;
}

export const initialMainNavigationState: MainNavigationState = {
  mainNavigationOpen: false,
  mainNavigationLevel1: null,
  localeOverlayIsOpen: false,
};

export const mainNavigationSlice = createSlice({
  name: 'mainNavigation',
  initialState: initialMainNavigationState,
  reducers: {
    openMainNavigation: (state: MainNavigationState) => ({
      ...state,
      mainNavigationOpen: true,
      localeOverlayIsOpen: false,
    }),
    closeMainNavigation: (state: MainNavigationState) => ({
      ...state,
      mainNavigationLevel1: initialMainNavigationState.mainNavigationLevel1,
      mainNavigationOpen: false,
    }),
    enterLevel1: (state: MainNavigationState, action: PayloadAction<string>) => ({
      ...state,
      mainNavigationLevel1: action.payload,
    }),
    leaveLevel1: (state: MainNavigationState) => ({
      ...state,
      mainNavigationLevel1: initialMainNavigationState.mainNavigationLevel1,
    }),
    openLocaleOverlay: (state: MainNavigationState) => ({
      ...state,
      mainNavigationOpen: false,
      localeOverlayIsOpen: true,
    }),
    closeLocaleOverlay: (state: MainNavigationState) => ({
      ...state,
      localeOverlayIsOpen: false,
    }),
  },
});

export const MainNavigationActions = mainNavigationSlice.actions;
export default mainNavigationSlice.reducer;
