import { call, put, takeEvery } from 'redux-saga/effects';
import { CatalogActions, ReferenceEntityPayloadAction } from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { akeneoClient } from '../../index';
import {
  EntityRecord,
  ListResponse,
  mapReferenceEntity,
  ReferenceEntityRecordQueryParameters,
} from '@dxp/akeneo-client';
import { toUppercaseLocale } from '@dxp/core';

/**
 * The watcher function for reference entities.
 */
export function* watchFetchReferenceEntity() {
  yield takeEvery(CatalogActions.referenceEntityRequested, fetchReferenceEntityAsync);
}

/**
 * The worker function to fetch reference entities.
 * @param action
 */
export function* fetchReferenceEntityAsync(action: PayloadAction<ReferenceEntityPayloadAction>) {
  const { payload } = action;
  const referenceEntityCode = action.payload.code;

  try {
    const query: ReferenceEntityRecordQueryParameters = {
      search: JSON.stringify({ code: [{ operator: 'IN', value: action.payload.codes }] }),
    };

    const response: ListResponse & { items: Array<EntityRecord> } = yield call(
      akeneoClient.referenceEntity.getRecords,
      { referenceEntityCode, query }
    );

    const data: Array<any> = response.items.map((referenceEntity: EntityRecord) =>
      mapReferenceEntity(referenceEntity, action.payload.mappingConfig, toUppercaseLocale(action.payload.locale))
    );

    yield put(CatalogActions.referenceEntityReceived({ ...payload, data }));
  } catch (error) {
    yield put(CatalogActions.referenceEntityRequestFailed(payload));
  }
}
