import styles from './sticky-navigator.module.scss';
import { Colors, IconButton, IconCart, IconSearch, ScreenSizes, useScreenSizes } from '@dxp/veneer';
import { PlatformWebappFeatureFlags } from '@dxp/core';

const StickyNavigator = () => {
  const screensize: ScreenSizes = useScreenSizes();
  return screensize === ScreenSizes.LARGE ? (
    <></>
  ) : (
    <>
      {PlatformWebappFeatureFlags.islandNav && (
        <div className={styles['container']}>
          <svg className={styles['bg']} viewBox="0 0 199 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_9551_22442)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.3605 57.3994H30.0696C15.8271 57.3994 4.28125 45.8536 4.28125 31.6111C4.28125 17.3686 15.8271 5.82275 30.0696 5.82275H78.3627C83.2153 2.63686 89.0212 0.783691 95.2599 0.783691C101.499 0.783691 107.304 2.63686 112.157 5.82275H168.991C183.233 5.82275 194.779 17.3686 194.779 31.6111C194.779 45.8536 183.233 57.3994 168.991 57.3994H112.159C107.306 60.5862 101.5 62.4399 95.2599 62.4399C89.0203 62.4399 83.2136 60.5862 78.3605 57.3994Z"
                fill="white"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_9551_22442"
                x="0.28125"
                y="0.783691"
                width="198.5"
                height="69.6562"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9551_22442" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9551_22442" result="shape" />
              </filter>
            </defs>
          </svg>

          <IconButton icon={<IconSearch color={Colors.intenseBlue} />} />
          <div className={styles['menu-toggle']}>
            <svg
              className={styles['menu-toggle__icon']}
              fill="none"
              viewBox="0 0 40 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke="#002d5f" strokeWidth="1.5">
                <path d="m39.6719.986084-39.406233-.000003" />
                <path d="m39.6719 7.74194h-39.406233" />
                <path d="m39.6719 14.7849h-39.406233" />
              </g>
            </svg>
          </div>
          <IconButton icon={<IconCart color={Colors.intenseBlue} />} />
        </div>
      )}
    </>
  );
};

export default StickyNavigator;
