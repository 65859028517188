import styles from './contact-button.module.scss';
import classNames from 'classnames';
import { Colors } from '../../../types';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { IconMail, IconPhone } from '../../decoration/icons';

export interface ContactButtonProps {
  data: string;
  title?: string;
  variant: 'tel' | 'mailto';
  color?: Colors;
}

const variants = {
  scaleInactive: { scaleX: 0, originX: 0, transition: { duration: 0.35, ease: 'easeInOut' } },
  scaleHovered: { scaleX: 1, originX: 0, transition: { duration: 0.35, ease: 'easeInOut' } },
  opacityInactive: { opacity: 0, transition: { duration: 0.2, delay: 0.35, ease: 'easeInOut' } },
  opacityHovered: { opacity: 1, transition: { duration: 0.6, ease: 'easeInOut' } },
};

export const ContactButton = (props: ContactButtonProps & { className?: string }) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const classes: string = classNames(
    styles['container'],
    styles[props.variant],
    styles[props.color ? 'container--' + props.color : ''],
    props.className
  );

  return (
    <a
      href={`${props.variant}:${props.data}`}
      className={classes}
      data-testid="inner"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      title={props.title}
      aria-label={props.title}
    >
      {props.variant === 'tel' ? (
        <IconPhone
          className={styles['icon']}
          color={props.color ? (props.color as typeof IconPhone.prototype.color) : Colors.intenseBlue}
        />
      ) : (
        <IconMail
          className={styles['icon']}
          color={props.color ? (props.color as typeof IconPhone.prototype.color) : Colors.intenseBlue}
        />
      )}

      <span className={styles['label']} data-testid={`${props.variant}-label`}>
        {props.data}
      </span>
      <motion.div
        initial={['scaleInactive', 'opacityInactive']}
        animate={isHovered ? ['scaleHovered', 'opacityHovered'] : ['scaleInactive', 'opacityInactive']}
        variants={variants}
        className={styles['decorator']}
      ></motion.div>
    </a>
  );
};

export default ContactButton;
