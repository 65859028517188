import {
  watchCreateAddress,
  watchCustomerForgotPassword,
  watchCustomerLogin,
  watchCustomerLogout,
  watchCustomerRestorePassword,
  watchDeleteAddress,
  watchFetchAddresses,
  watchFetchCreateCustomer,
  watchFetchCustomer,
  watchUpdateAddress,
  watchUpdateCustomer,
  watchUpdateCustomerPassword,
} from '@dxp/account';
import {
  watchFetchAssetFamily,
  watchFetchAttributes,
  watchFetchCategories,
  watchFetchChildProductsByFamily,
  watchFetchChildProductsByParent,
  watchFetchPdp,
  watchFetchPdpsByCategory,
  watchFetchPdpsByFamily,
  watchFetchProduct,
  watchFetchProductByIdentifier,
  watchFetchProductModel,
  watchFetchReferenceEntity,
} from '@dxp/catalog';
import {
  watchAddCustomOrderReferenceToCart,
  watchAddItemToCart,
  watchAddItemToGuestCart,
  watchAddItemsToCart,
  watchAddItemsToGuestCart,
  watchAddVoucher,
  watchChangeItemQuantity,
  watchCheckoutDataRequested,
  watchCheckoutPaymentsRequested,
  watchCheckoutRequested,
  watchCreateCart,
  watchDeleteCartItem,
  watchDeleteVoucher,
  watchFetchCarts,
  watchFetchConcreteProduct,
  watchFetchGuestCarts,
  watchFetchOrder,
  watchFetchOrderPayment,
  watchFetchOrders,
  watchGuestAddVoucher,
  watchGuestChangeItemQuantity,
  watchGuestDeleteCartItem,
  watchGuestDeleteVoucher,
  watchRequestPaymentSession,
  watchStoresRequested,
} from '@dxp/commerce';
import {
  watchFetchAllNavigations,
  watchFetchArticleCategories,
  watchFetchArticles,
  watchFetchEvents,
  watchFetchFaqQuestions,
  watchFetchFaqTopics,
  watchFetchPinnedArticles,
  watchFetchTrainers,
  watchFetchVacancies,
} from '@dxp/content';
import { watchAutocomplete, watchDataLayer, watchHubSpot, watchModal, watchResultClick, watchSearch } from '@dxp/core';
import {
  watchGetFrontifyAssetById,
  watchGetFrontifyAssets,
  watchGetFrontifyAssetsByFilter,
  watchGetFrontifyLibraries,
  watchGetFrontifyLibraryDefault,
} from '@dxp/frontify-client';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    watchFetchAssetFamily(),
    watchFetchCategories(),
    watchFetchPdp(),
    watchFetchPdpsByCategory(),
    watchFetchPdpsByFamily(),
    watchFetchProductByIdentifier(),
    watchFetchProduct(),
    watchFetchChildProductsByFamily(),
    watchFetchChildProductsByParent(),
    watchFetchProductModel(),
    watchFetchReferenceEntity(),
    watchFetchAttributes(),
    watchDataLayer(),
    watchModal(),
    watchFetchAllNavigations(),
    watchFetchConcreteProduct(),
    watchCustomerLogin(),
    watchCustomerLogout(),
    watchFetchAddresses(),
    watchCreateAddress(),
    watchUpdateAddress(),
    watchFetchCreateCustomer(),
    watchFetchCustomer(),
    watchUpdateCustomer(),
    watchUpdateCustomerPassword(),
    watchDeleteAddress(),
    watchCustomerForgotPassword(),
    watchCustomerRestorePassword(),
    watchFetchEvents(),
    watchFetchVacancies(),
    watchFetchArticles(),
    watchFetchArticleCategories(),
    watchFetchPinnedArticles(),
    watchGetFrontifyAssets(),
    watchGetFrontifyAssetsByFilter(),
    watchGetFrontifyLibraries(),
    watchGetFrontifyLibraryDefault(),
    watchFetchFaqQuestions(),
    watchFetchFaqTopics(),
    watchSearch(),
    watchAutocomplete(),
    watchHubSpot(),
    watchResultClick(),
    watchFetchCarts(),
    watchFetchGuestCarts(),
    watchAddItemToCart(),
    watchAddItemsToCart(),
    watchChangeItemQuantity(),
    watchGuestChangeItemQuantity(),
    watchDeleteCartItem(),
    watchGuestDeleteCartItem(),
    watchAddItemToGuestCart(),
    watchAddItemsToGuestCart(),
    watchAddVoucher(),
    watchDeleteVoucher(),
    watchGuestAddVoucher(),
    watchGuestDeleteVoucher(),
    watchStoresRequested(),
    watchCreateCart(),
    watchCheckoutDataRequested(),
    watchFetchOrders(),
    watchFetchOrder(),
    watchAddCustomOrderReferenceToCart(),
    watchCheckoutRequested(),
    watchFetchOrderPayment(),
    watchRequestPaymentSession(),
    watchCheckoutPaymentsRequested(),
    watchGetFrontifyAssetById(),
    watchFetchTrainers(),
  ]);
}
