import { useTranslation } from 'next-i18next';
import styles from './cart-summary.module.scss';
import { ICart, IOrder } from '@dxp/spryker-client';
import { Key, useContext, useMemo } from 'react';
import { CartContext } from '../../cart';
import { Colors, DiscountIcon, Loader, Price } from '@dxp/veneer';
import { useSelector } from 'react-redux';
import { CartSelectors, CartState } from '../../../../redux';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { CheckoutDataContext } from '@dxp/core';

const DiscountRow = (discount: {
  code: Key | null | undefined;
  displayName: string | undefined;
  amount: number | undefined;
  cart: ICart | undefined;
  order: IOrder | undefined | null;
}) => (
  <tr className={styles['discountRow']} key={uuidv4()}>
    <td>
      <DiscountIcon label={discount.displayName} />
    </td>
    <td>
      <Price
        className={styles['price']}
        price={discount.amount}
        currency={discount.cart?.currency || discount.order?.attributes.currencyIsoCode || ''}
        priceSize={'cart'}
        layout={'minimal'}
        isDiscountValue={true}
      />
    </td>
  </tr>
);

interface ISimplifiedSummary {
  shipping: boolean;
  shippingPrice?: number | null;
  currency?: string;
  name?: string;
  carrierName?: string;
  totalPrice: number;
}

export function CartSummary({ className, order }: { className?: string; order?: IOrder | null }) {
  const { t } = useTranslation('cart');
  const classes = classNames([className, styles['container']]);

  const cart: ICart | undefined = useContext(CartContext);
  const checkoutDataContext = useContext(CheckoutDataContext);
  const cartLoading: boolean = useSelector((state: { cart: CartState }) => CartSelectors.selectCartLoading(state.cart));

  const simplifiedSummary: ISimplifiedSummary = useMemo(() => {
    if (order && order.attributes?.shipments?.length > 0) {
      return {
        shipping: true,
        shippingPrice: order.attributes.shipments[0].defaultGrossPrice,
        name: order.attributes.shipments[0].shipmentMethodName,
        carrierName: order.attributes.shipments[0].carrierName,
        currency: order.attributes.shipments[0].currencyIsoCode,
        totalPrice: order?.attributes.totals.grandTotal + order.attributes.shipments[0].defaultGrossPrice,
      };
    } else if (
      checkoutDataContext &&
      checkoutDataContext?.checkoutData?.selectedShipmentMethods &&
      checkoutDataContext?.checkoutData?.selectedShipmentMethods.length > 0
    ) {
      return {
        shipping: true,
        shippingPrice: checkoutDataContext.checkoutData.selectedShipmentMethods?.[0].price,
        name: checkoutDataContext.checkoutData.selectedShipmentMethods?.[0].name,
        carrierName: checkoutDataContext.checkoutData.selectedShipmentMethods?.[0].carrierName,
        currency: checkoutDataContext.checkoutData.selectedShipmentMethods?.[0].currencyIsoCode,
        totalPrice:
          (cart?.totals?.grandTotal || 0) + (checkoutDataContext.checkoutData.selectedShipmentMethods?.[0].price || 0),
      };
    } else {
      return {
        shipping: false,
        totalPrice: cart?.totals.grandTotal || 0,
      };
    }
  }, [order, checkoutDataContext, cart?.totals.grandTotal]);

  return order || cart ? (
    <div className={classes}>
      {!order && cartLoading && (
        <div className={styles['loader']}>
          <Loader color={Colors.intenseBlue} />
        </div>
      )}
      <table className={styles['table']}>
        <tbody>
          <tr>
            <td>{t('subtotal')}</td>
            <td>
              <Price
                className={classNames([styles['price'], 'price'])}
                price={cart?.totals.subtotal || order?.attributes.totals.subtotal}
                currency={cart?.currency || order?.attributes?.currencyIsoCode || ''}
                priceSize={'cart'}
                layout={'minimal'}
              />
            </td>
          </tr>

          {cart &&
            cart?.discounts?.map((discount) => (
              <DiscountRow
                cart={cart}
                order={order}
                amount={discount.amount}
                displayName={discount.displayName}
                code={discount.code}
                key={uuidv4()}
              />
            ))}

          {!!order?.attributes?.totals.discountTotal && order?.attributes.totals.discountTotal > 0 && (
            <tr>
              <td>{t('discount')}:</td>
              <td>
                <Price
                  className={styles['price']}
                  price={order?.attributes.totals.discountTotal}
                  currency={order?.attributes.currencyIsoCode}
                  priceSize={'cart'}
                  layout={'minimal'}
                  isDiscountValue={true}
                />
              </td>
            </tr>
          )}

          <tr>
            <td>{t('vat')}:</td>
            <td>
              <Price
                className={styles['price']}
                price={cart?.totals.taxTotal || order?.attributes.totals.taxTotal}
                currency={cart?.currency || order?.attributes.currencyIsoCode || ''}
                priceSize={'cart'}
                layout={'minimal'}
              />
            </td>
          </tr>

          {simplifiedSummary.shipping && (
            <tr className={styles['cartShippingRow']} key={uuidv4()}>
              <td>
                <span className={styles['cartShipping']}>
                  {simplifiedSummary.carrierName} ({simplifiedSummary.name})
                </span>
              </td>
              <td>
                <Price
                  className={styles['price']}
                  price={simplifiedSummary.shippingPrice || 0}
                  currency={simplifiedSummary.currency}
                  priceSize={'cart'}
                  layout={'minimal'}
                />
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td>{t('total')}</td>
            <td>
              <Price
                price={simplifiedSummary.totalPrice}
                currency={cart?.currency || order?.attributes.currencyIsoCode || ''}
                priceSize={'small'}
                layout={'minimal'}
                className={styles['price']}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  ) : null;
}

export default CartSummary;
