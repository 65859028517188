import styles from './pinned-stage.module.scss';
import { MutableRefObject, useRef, useState } from 'react';
import { Autoplay, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PlatformWebappStaticRoutes } from '@dxp/core';
import { v4 as uuidv4 } from 'uuid';
import { IArticle, IEvent } from '../../types';
import { Colors, StandardButton } from '@dxp/veneer';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

export interface PinnedStageProps {
  articles?: Array<IArticle>;
  events?: Array<IEvent>;
  className?: string;
}

const shorten = (str: string, maxLen: number, separator = ' ') => {
  if (str.length <= maxLen) return str;
  return str.substring(0, str.lastIndexOf(separator, maxLen));
};

export function PinnedStage({ articles, events, className }: PinnedStageProps) {
  const { t } = useTranslation('events');
  const swiperRef: MutableRefObject<SwiperType | undefined> = useRef<SwiperType>();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const { locale } = useRouter();

  const renderArticles = (items: Array<IArticle>) => {
    return items.map((article) => {
      const publishDate = new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(
        new Date(article.article?.articlePublishDate)
      );
      const link = `${PlatformWebappStaticRoutes.general.news}/${article.article.slug}`;

      return (
        <SwiperSlide className={styles['slide']} key={uuidv4()}>
          <Link href={link}>
            {!!article.article.articleImageUrl && article.article.articleImageUrl !== '' && (
              <Image
                width={1810}
                height={848}
                src={article.article.articleImageUrl}
                alt={article.article.title}
                className={styles['slide__image']}
                priority
              />
            )}
            <span className={styles['slide__gradient']}></span>
            <span className={classNames(styles['slide__content'], styles['content'])}>
              <time dateTime={article.article.articlePublishDate} className={styles['content__date']}>
                {publishDate}
              </time>
              <span className={styles['content__title']}>{article.article.title}</span>
              {article.article.intro && (
                <span className={styles['content__excerpt']}>{shorten(article.article.intro, 200)}</span>
              )}
            </span>
          </Link>
        </SwiperSlide>
      );
    });
  };

  const renderEvents = (items: Array<IEvent>) => {
    return items.map((event) => {
      const eventDateStart = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(event.event?.startDate));
      const eventDateEnd =
        event.event.endDate && event.event.endDate !== event.event.startDate
          ? new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(
              new Date(event.event?.endDate)
            )
          : undefined;

      return (
        <SwiperSlide className={styles['slide']} key={uuidv4()}>
          {!!event.event.imageUrl && event.event.imageUrl !== '' && (
            <Image
              width={1810}
              height={848}
              src={event.event.imageUrl}
              alt={event.event.title}
              className={styles['slide__image']}
              priority
            />
          )}
          <span className={styles['slide__gradient']}></span>
          <span className={classNames(styles['slide__content'], styles['content'])}>
            <time dateTime={event.event.startDate} className={styles['content__date']}>
              {eventDateStart}
              {eventDateEnd && ` - ${eventDateEnd}`}
            </time>
            <span className={styles['content__title']}>{event.event.longTitle ?? event.event.title}</span>
            {event.event.description && (
              <span className={styles['content__excerpt']}>{shorten(event.event.description, 200)}</span>
            )}
            <Link
              href={event.event.externalLink}
              target={'_blank'}
              rel={'nofollow'}
              className={styles['content__button']}
            >
              <StandardButton label={t(`cta.${event.event.linkType}`)} variant={Colors.feelgoodMint} />
            </Link>
          </span>
        </SwiperSlide>
      );
    });
  };

  const swiperProps: SwiperProps = {
    autoplay: { delay: 5000 },
    slidesPerView: 1,
    modules: [Autoplay],
    onBeforeInit: (swiper: SwiperType) => (swiperRef.current = swiper),
    onSlideChange: (swiper: SwiperType) => setActiveIndex(swiper.realIndex),
    threshold: 10,
  };

  return !!articles?.length || !!events?.length ? (
    <div className={classNames(styles['container'], className)}>
      {(events && events?.length > 1) || (articles && articles?.length > 1) ? (
        <>
          <Swiper {...swiperProps} loop={true}>
            {!!articles?.length && renderArticles(articles)}
            {!!events?.length && renderEvents(events)}
          </Swiper>

          <div className={styles['swiper-nav']}>
            {[...Array(articles?.length ? articles.length : events?.length)].map((el, index) => (
              <button
                onClick={() => swiperRef.current?.slideTo(index + 1)}
                className={classNames(styles['swiper-nav__item'], styles['swiper-indicator'])}
                key={'indicator-' + index}
                aria-label={'Jump to slide ' + (index + 1)}
              >
                <div className={styles['swiper-indicator__bar']}>
                  <div
                    className={classNames(
                      styles['swiper-indicator__inner'],
                      activeIndex === index ? styles['swiper-indicator__inner--active'] : ''
                    )}
                  ></div>
                </div>
              </button>
            ))}
          </div>
        </>
      ) : (
        <Swiper {...swiperProps} loop={false}>
          {!!articles?.length && renderArticles(articles)}
          {!!events?.length && renderEvents(events)}
        </Swiper>
      )}
    </div>
  ) : null;
}

export default PinnedStage;
