import { MutableRefObject, RefObject } from 'react';
import { useIntersection } from './use-intersection.hook';

const defaultTresholds = {
  enter: 0.6,
  leave: 0.2,
};

export const useVideoAutoplay = (
  ref: RefObject<HTMLVideoElement>,
  thresholds: {
    enter: number;
    leave: number;
  } = defaultTresholds
) => {
  useIntersection(
    ref,
    (
      entry: IntersectionObserverEntry,
      previousRatio: MutableRefObject<number>,
      previousY: MutableRefObject<number>
    ) => {
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;
      const isIntersecting = entry.isIntersecting;

      // Dectect scroll direction
      if (currentY < previousY.current) {
        if (currentRatio > previousRatio.current && isIntersecting) {
          // Scrolling down enter
          if (entry.intersectionRatio >= thresholds.enter && ref.current?.paused) {
            ref.current?.play();
          }
        } else {
          // Scrolling down leave
          if (entry.intersectionRatio <= thresholds.leave && !ref.current?.paused) {
            ref.current?.load();
          }
        }
      } else if (currentY > previousY.current && isIntersecting) {
        if (currentRatio < previousRatio.current) {
          // Scrolling up leave
          if (entry.intersectionRatio <= thresholds.leave && !ref.current?.paused) {
            ref.current?.load();
          }
        } else {
          // Scrolling up enter
          if (entry.intersectionRatio >= thresholds.enter && ref.current?.paused) {
            ref.current?.play();
          }
        }
      }
    }
  );
};
