import { Colors, StandardButton } from '@dxp/veneer';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { PlatformWebappStaticRoutes } from '../../constants';
import styles from './webinar-item.module.scss';

export interface Props {
  webinarTitle: string;
  detailsUrl: string;
  tagline?: string[];
  description?: string;
  speaker?: string | string[];
  isRegisterShown?: boolean;
}

export const WebinarItem = (props: Props) => {
  const { t: commonT } = useTranslation('common');
  const { t: accountT } = useTranslation('account');

  const { webinarTitle, detailsUrl, tagline, description, speaker, isRegisterShown } = props;

  return (
    <div className={styles['container']}>
      <h3 className={styles['title']}>{webinarTitle}</h3>
      {tagline && <p className={styles['tagline']}>{tagline.join(' | ')}</p>}
      {description && <p className={styles['description']}>{description}</p>}
      {speaker && (
        <p className={styles['speaker']}>
          {commonT('trainer')}: {Array.isArray(speaker) ? speaker.join(', ') : speaker}
        </p>
      )}
      <div className={styles['actions']}>
        {isRegisterShown && (
          <Link href={PlatformWebappStaticRoutes.account.login} aria-label={'Login'}>
            <StandardButton variant={Colors.brilliantBlue} label={accountT('login.register.button')} />
          </Link>
        )}

        <Link href={detailsUrl} aria-label={'Details'}>
          <StandardButton variant={Colors.feelgoodMint} label={'Details'} />
        </Link>
      </div>
    </div>
  );
};

export default WebinarItem;
