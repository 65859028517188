import styles from './news-contact.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { ContactButton } from '@dxp/veneer';
import Image from 'next/image';

export interface NewsContactProps {
  name: string;
  imageUrl?: string;
  title?: string;
  email?: string;
  className?: string;
}

export function NewsContact({ name, imageUrl, title, email, className }: NewsContactProps) {
  const { t } = useTranslation('common');

  return (
    <div className={classNames(styles['container'], className)}>
      <div className={styles['inner']}>
        {!!imageUrl && (
          <div className={styles['image__container']}>
            <Image
              width={267}
              height={210}
              src={imageUrl}
              alt={name}
              className={styles['image']}
              data-testid={'author-image'}
            />
          </div>
        )}

        <div className={styles['text__container']}>
          <h3 className={styles['headline']}>{t('news.footer.contact.headline')}</h3>
          <div className={styles['contact__name']} data-testid={'author-name'}>
            {name}
          </div>
          {!!title && (
            <div className={styles['contact__title']} data-testid={'author-title'}>
              {title}
            </div>
          )}
          {!!email && <ContactButton data={email || ''} variant={'mailto'} className={styles['contact__email']} />}
        </div>
      </div>
    </div>
  );
}

export default NewsContact;
