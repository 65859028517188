import styles from './mandrel.module.scss';
import classNames from 'classnames';
import { useState } from 'react';
import { VeneerAnimation } from '../../../animations';
import { motion } from 'framer-motion';

export enum MandrelType {
  UN = 'mandrel_un',
  DR = 'mandrel_dr',
  AG = 'mandrel_ag',
}

export interface MandrelProps {
  title?: string;
  active?: boolean;
  inactive?: boolean;
  onClick?: () => void;
  type: MandrelType;
}

const variants = {
  borderInactive: { borderColor: 'rgba(0, 0, 0, 0)', transition: VeneerAnimation.uiScaleTransition },
  borderActive: { borderColor: '#002D5F', transition: VeneerAnimation.uiScaleTransition },
  scaleInactive: { scale: 1, transition: VeneerAnimation.uiScaleTransition },
  scaleActive: { ...VeneerAnimation.uiScale, transition: VeneerAnimation.uiScaleTransition },
};

export const Mandrel = (props: MandrelProps) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const classes = classNames(styles['container'], { [styles['inactive']]: props.inactive });

  return (
    <div
      className={classes}
      onClick={props.onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      data-testid={'container'}
    >
      <motion.div
        className={styles['image']}
        animate={!props.inactive && (isHovered || props.active) ? 'borderActive' : 'borderInactive'}
        variants={variants}
      >
        <motion.div
          className={classNames(styles['image__inner'], styles['image__inner--' + props.type])}
          animate={!props.inactive && (isHovered || props.active) ? 'scaleActive' : 'scaleInactive'}
          variants={variants}
          data-testid={'image'}
        ></motion.div>
      </motion.div>
      <div className={classNames(styles['title'], 'subtext-regular')} data-testid={'title'}>
        {props.title}
      </div>
    </div>
  );
};

export default Mandrel;
