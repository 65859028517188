import { createSelector } from '@reduxjs/toolkit';
import { IFaqQuestion, IFaqTopic } from '../../types';
import { ContentfulDataState } from './contentful-data.slice';

const selectContentfulDataState = (state: ContentfulDataState) => state;

const selectAllEvents = createSelector([selectContentfulDataState], (state: ContentfulDataState) =>
  [...state.events].sort((a, b) => new Date(a.event.startDate).getTime() - new Date(b.event.startDate).getTime())
);

const selectPinnedEvents = createSelector([selectContentfulDataState], (state: ContentfulDataState) =>
  state.events
    .filter((event) => event.event.pinned === true)
    .sort((a, b) => new Date(a.event.startDate).getTime() - new Date(b.event.startDate).getTime())
    .slice(0, 3)
);

const selectVacancies = createSelector([selectContentfulDataState], (state: ContentfulDataState) => state.vacancies);

const selectArticleInformation = createSelector(
  [selectContentfulDataState],
  (state: ContentfulDataState) => state.articleInformation
);

const selectPinnedArticles = createSelector(
  [selectContentfulDataState],
  (state: ContentfulDataState) => state.pinnedArticles
);

const selectArticleCategories = createSelector(
  [selectContentfulDataState],
  (state: ContentfulDataState) => state.articleCategories
);

const selectTopCategories = createSelector([selectContentfulDataState], (state: ContentfulDataState) =>
  state.articleCategories.filter((category) => category.category.topCategory === true)
);

const selectArticles = createSelector(
  [selectContentfulDataState],
  (state: ContentfulDataState) => state.articleInformation.articles
);

const selectFaqTopics = createSelector([selectContentfulDataState], (state: ContentfulDataState) =>
  [...state.faqTopics].sort((a: IFaqTopic, b: IFaqTopic) =>
    sortLexicographical(a.faqTopic.title.toLowerCase(), b.faqTopic.title.toLowerCase())
  )
);
const selectFaqQuestions = createSelector([selectContentfulDataState], (state: ContentfulDataState) =>
  [...state.faqQuestions].sort((a: IFaqQuestion, b: IFaqQuestion) =>
    sortLexicographical(a.faq.question.toLowerCase(), b.faq.question.toLowerCase())
  )
);

const selectTrainers = createSelector(
  [(state: { contentfulData: ContentfulDataState }) => state.contentfulData],
  (state: ContentfulDataState) => state.trainers
);

export const ContentfulDataSelectors = {
  selectArticleCategories,
  selectArticleInformation,
  selectArticles,
  selectAllEvents,
  selectPinnedEvents,
  selectPinnedArticles,
  selectVacancies,
  selectTopCategories,
  selectFaqTopics,
  selectFaqQuestions,
  selectTrainers,
};

const sortLexicographical = (a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0);
