import {
  AuthContext,
  CheckoutDataContext,
  IAuthContext,
  ICheckoutDataContext,
  PlatformWebappStaticRoutes,
} from '@dxp/core';
import { ICart, ICheckoutData, ICheckoutRequestAttributes, ISelectedPaymentMethods } from '@dxp/spryker-client';
import { Checkbox, Colors, StandardButton } from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next/router';
import * as process from 'process';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckoutActions,
  CheckoutDataActions,
  CheckoutDataSelectors,
  CheckoutDataState,
  CheckoutSelectors,
  CheckoutState,
} from '../../../';
import { CartContext } from '../cart';
import { CartList, CartSummary } from '../cart-particles';
import styles from './cart-in-checkout.module.scss';

export function CartInCheckout() {
  const { t } = useTranslation('cart');
  const { locale } = useRouter();
  const dispatch = useDispatch();
  const cart: ICart | undefined = useContext(CartContext);
  const authContext: IAuthContext | null = useContext(AuthContext);
  const checkoutDataContext: ICheckoutDataContext = useContext(CheckoutDataContext);
  const checkoutData: ICheckoutData | null = useSelector((state: { checkoutData: CheckoutDataState }) =>
    CheckoutDataSelectors.selectCheckoutData(state.checkoutData)
  );
  const checkoutLoading = useSelector((state: { checkout: CheckoutState }) =>
    CheckoutSelectors.selectLoading(state.checkout)
  );

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [checkoutRequestAttributes, setCheckoutRequestAttributes] = useState<ICheckoutRequestAttributes | undefined>(
    undefined
  );

  // Effect to decide whether a checkout is possible and to create the payload of the checkout request
  useEffect(() => {
    const billingAddress = {
      ...checkoutData?.addresses?.find((address) => address.id === checkoutDataContext.selectedBillingAddressId),
    };
    const shippingAddress = {
      ...checkoutData?.addresses?.find((address) => address.id === checkoutDataContext.selectedShippingAddressId),
    };

    if (
      locale &&
      checkoutDataContext &&
      !checkoutDataContext.guestCheckout &&
      checkoutDataContext.cartId &&
      checkoutDataContext.customer?.firstName &&
      checkoutDataContext.customer?.lastName &&
      checkoutData?.selectedShipmentMethods?.length &&
      checkoutData?.selectedPaymentMethods?.length &&
      billingAddress &&
      shippingAddress &&
      checkoutDataContext.acceptedTerms

      //TODO: reactivate when adyen gets implemented
      // && checkoutDataContext.paymentInfoValid
    ) {
      setSubmitEnabled(true);
      if (billingAddress?.salutation === '' || billingAddress?.salutation === null) {
        delete billingAddress.salutation;
      }
      if (billingAddress?.address2 === '' || billingAddress?.address2 === null) {
        delete billingAddress.address2;
      }
      if (shippingAddress?.salutation === '' || shippingAddress?.salutation === null) {
        delete shippingAddress.salutation;
      }
      if (shippingAddress?.address2 === '' || shippingAddress?.address2 === null) {
        delete shippingAddress.address2;
      }
      // Create checkout request payload
      const checkoutRequestAttributes: ICheckoutRequestAttributes = {
        idCart: checkoutDataContext.cartId,
        acceptedTerms: checkoutDataContext.acceptedTerms,
        customer: {
          ...{
            email: checkoutDataContext.customer.email,
            firstName: checkoutDataContext.customer.firstName,
            lastName: checkoutDataContext.customer.lastName,
          },
          ...(checkoutDataContext.customer.salutation && { salutation: checkoutDataContext.customer.salutation }),
        },
        billingAddress, //: { id: checkoutDataContext.selectedBillingAddressId }, // TODO: According to the documentation, the id of the address should be enough - but Spryker currently throws an error.
        shippingAddress, //: { id: checkoutDataContext.selectedShippingAddressId }, // TODO: According to the documentation, the id of the address should be enough - but Spryker currently throws an error.
        payments: checkoutData.selectedPaymentMethods.map((item: ISelectedPaymentMethods) => ({
          paymentMethodName: item.paymentMethodName,
          paymentProviderName: item.paymentProviderName,
          adyenCreditCardPayment: checkoutDataContext.cardPaymentData,
        })),
        shipment: {
          idShipmentMethod: checkoutData.selectedShipmentMethods[0].id,
        },
      };
      setCheckoutRequestAttributes(checkoutRequestAttributes);
    } else if (
      locale &&
      checkoutDataContext &&
      checkoutDataContext.guestCheckout &&
      checkoutDataContext.cartId &&
      checkoutDataContext.guestCheckoutPersonalData &&
      checkoutData?.selectedShipmentMethods?.length &&
      checkoutData?.selectedPaymentMethods?.length &&
      checkoutDataContext.guestCheckoutBillingAddress &&
      checkoutDataContext.guestCheckoutShippingAddress &&
      checkoutDataContext.acceptedTerms &&
      checkoutDataContext.paymentInfoValid
    ) {
      setSubmitEnabled(true);

      // Create checkout request payload
      const checkoutRequestAttributes: ICheckoutRequestAttributes = {
        idCart: checkoutDataContext.cartId,
        acceptedTerms: checkoutDataContext.acceptedTerms,
        customer: {
          salutation: checkoutDataContext.guestCheckoutPersonalData.salutation,
          firstName: checkoutDataContext.guestCheckoutPersonalData.firstName,
          lastName: checkoutDataContext.guestCheckoutPersonalData.lastName,
          email: checkoutDataContext.guestCheckoutPersonalData.email,
        },
        billingAddress: checkoutDataContext.guestCheckoutBillingAddress,
        shippingAddress: checkoutDataContext.guestCheckoutShippingAddress,
        payments: checkoutData.selectedPaymentMethods.map((item: ISelectedPaymentMethods) => ({
          paymentMethodName: item.paymentMethodName,
          paymentProviderName: item.paymentProviderName,
        })),
        shipment: {
          idShipmentMethod: checkoutData.selectedShipmentMethods[0].id,
        },
        paymentData: checkoutDataContext.cardPaymentData,
      };
      setCheckoutRequestAttributes(checkoutRequestAttributes);
    } else {
      setSubmitEnabled(false);
      setCheckoutRequestAttributes(undefined);
    }
  }, [locale, checkoutDataContext, checkoutData]);

  const submitOrder = () => {
    if (
      locale &&
      submitEnabled &&
      checkoutRequestAttributes &&
      checkoutDataContext.customer &&
      !checkoutDataContext.guestCheckout &&
      //if we have a payment instance, it must be valid
      (checkoutDataContext.paymentInstance ? checkoutDataContext.paymentInstance.isValid : true)
    ) {
      // authorized checkout
      dispatch(
        CheckoutActions.checkoutRequested({
          locale,
          checkoutRequestAttributes,
          anonymId: null,
          termsAccepted: checkoutData?.acceptedTerms || null,
        })
      );
    } else if (
      locale &&
      submitEnabled &&
      checkoutRequestAttributes &&
      checkoutDataContext.guestCheckoutPersonalData &&
      checkoutDataContext.guestCheckout &&
      authContext?.anonymId &&
      //if we have a payment instance, it must be valid
      (checkoutDataContext.paymentInstance ? checkoutDataContext.paymentInstance.isValid : true)
    ) {
      // guest checkout
      dispatch(
        CheckoutActions.checkoutRequested({
          locale,
          checkoutRequestAttributes,
          anonymId: authContext.anonymId,
          termsAccepted: checkoutData?.acceptedTerms || null,
        })
      );
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['title']}>
          {t('headline')} <Link href={PlatformWebappStaticRoutes.commerce.cart}>{t('edit')}</Link>
        </div>
      </div>
      {authContext?.isAuthenticated && (
        <div className={styles['order-reference']}>
          <div className={styles['order-reference__title']}>{t('orderReference.title')}:</div>
          <div className={styles['order-reference__reference']}>{cart?.orderCustomReference || '-'}</div>
        </div>
      )}
      {cart && cart?.items.length ? <CartList className={styles['body']} manipulatable={false} /> : null}
      <div className={styles['footer']}>
        <div className={styles['footer__top']}>
          <CartSummary />
        </div>
        <div className={styles['footer__privacy']}>
          <Checkbox
            required={true}
            className={styles['checkbox']}
            checked={checkoutDataContext.acceptedTerms || false}
            onChange={(checkedState) => {
              dispatch(CheckoutDataActions.setAcceptedTerms(checkedState));
            }}
          >
            <>
              <Link href={{ pathname: t('form.legal.link.terms', { ns: 'account' }) }} legacyBehavior>
                <a rel="noopener noreferrer" target="_blank">
                  {t('form.legal.label.terms', { ns: 'account' })}
                </a>
              </Link>{' '}
              {t('form.legal.label.and', { ns: 'account' })}{' '}
              <Link href={{ pathname: t('form.legal.link.gdpr', { ns: 'account' }) }} legacyBehavior>
                <a rel="noopener noreferrer" target="_blank">
                  {t('form.legal.label.gdpr', { ns: 'account' })}
                </a>
              </Link>
            </>
          </Checkbox>
        </div>
        <div className={classNames(styles['footer__cta'], !cart ? styles['footer__cta--inactive'] : '')}>
          <StandardButton
            label={t('orderWithCosts')}
            variant={Colors.feelgoodMint}
            disabled={!submitEnabled}
            isLoading={checkoutLoading}
            onClick={() => submitOrder()}
          />
        </div>
        {process.env.NODE_ENV === 'development' && (
          <>
            <br />
            <StandardButton
              label={'clear checkout-data'}
              variant={Colors.white}
              onClick={() => dispatch(CheckoutDataActions.clearState())}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default CartInCheckout;
