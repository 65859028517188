import styles from './text-with-accordion.module.scss';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { AccordionElement, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { tidyHtml } from '@dxp/veneer/utils';
import { motion } from 'framer-motion';

interface Accordion {
  id: string;
  title: string;
  richtext: ContentfulDocument;
}

export interface TextWithAccordionProps {
  headline: string;
  copy: ContentfulDocument;
  accordion?: Accordion[];
}

export function TextWithAccordion(props: TextWithAccordionProps) {
  const renderItems = props.accordion
    ? props.accordion.map((item: Accordion) => (
        <AccordionElement
          key={item.id}
          headline={item.title}
          bodytext={tidyHtml(documentToHtmlString(item.richtext))}
          size={'large'}
        />
      ))
    : null;

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
      data-elastic-include={true}
    >
      <div className={classNames(styles['column'], styles['column--left'])}>
        <h2 className={'heading-4'} data-testid={'title'}>
          {props.headline}
        </h2>
      </div>

      <div className={classNames(styles['column'], styles['column--right'])}>
        {props.copy && props.copy.content.length > 0 && (
          <div
            className={classNames('text-long', styles['copy'])}
            dangerouslySetInnerHTML={{ __html: tidyHtml(documentToHtmlString(props.copy)) }}
            data-testid={'copy'}
          ></div>
        )}

        {!!props.accordion?.length && (
          <div className={styles['accordion']} data-testid={'accordion'}>
            {renderItems}
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default TextWithAccordion;
