import { AxiosInstance } from 'axios';
import { createHttpClient } from './http-client';
import {
  AssetFilterParams,
  FrontifyAsset,
  FrontifyFinderAsset,
  FrontifyLibrary,
  LibraryConfig,
  MediaFilterCondition,
  MetadataField,
} from './interfaces';
import { QUERY_ASSET, QUERY_BRANDLEVEL_SEARCH, QUERY_BRAND_LIBRARIES, QUERY_LIBRARY_SEARCH } from './queries';

const BRAND_ID = process.env['NX_FRONTIFY_BRAND_ID'] || '';

export * as FrontifyGetters from './getters';
export * from './hooks';
export * from './interfaces';
export * from './redux';
export * from './utils';

export const findAssets = async (
  http: AxiosInstance,
  assetsFinder: FrontifyFinderAsset[]
): Promise<FrontifyAsset[]> => {
  const assets: FrontifyAsset[] = [];
  if (!assetsFinder) return assets;

  for (const assetFinder of assetsFinder) {
    const response = await http.post('', {
      query: QUERY_BRANDLEVEL_SEARCH,
      variables: {
        searchterm: assetFinder.title,
        brand: BRAND_ID,
      },
    });
    const assetNode = response.data.data?.brand?.search?.edges.find((asset: { node: FrontifyAsset }) => {
      return asset.node.previewUrl && assetFinder.preview_url.indexOf(asset.node.previewUrl) > -1;
    });

    assetNode?.node && assets.push(assetNode.node);
  }

  return assets;
};

export const findLibraries = async (http: AxiosInstance, libraryConfig: LibraryConfig[]) => {
  const response = await http.post('', {
    query: QUERY_BRAND_LIBRARIES,
    variables: {
      brand: BRAND_ID,
    },
  });

  const libraryItems = response.data.data?.brand?.libraries?.items;
  // filter, sort and skip wrong maintained libraryIds
  const libraryItemsSorted = libraryConfig.reduce((result: FrontifyLibrary[], config) => {
    const libraryItem = libraryItems.find((libraryItem: FrontifyLibrary) => libraryItem.id === config.id);
    if (libraryItem) {
      result.push(libraryItem);
    }
    return result;
  }, []);

  const libraryItemsSortedWithMetadataFieldsSorted = libraryItemsSorted.map((libraryItem: FrontifyLibrary) => {
    const libraryConfigItem = libraryConfig.find((config: LibraryConfig) => libraryItem.id === config.id);
    let metadataFieldsSorted;

    if (libraryConfigItem?.metadataFields?.length) {
      metadataFieldsSorted = [...libraryItem.metadataFields]
        .filter(
          (libraryItemMetaData: MetadataField) =>
            (libraryConfigItem?.metadataFields?.findIndex(
              (metadata: string) =>
                libraryItemMetaData.label.trim().toLocaleLowerCase() === metadata.trim().toLocaleLowerCase()
            ) ?? -1) >= 0
        )
        .map((metadataField) => ({
          ...metadataField,
          queryParam: metadataField.label.trim().toLowerCase().split(' ').join('_'),
        }));
    } else {
      metadataFieldsSorted = libraryItem.metadataFields.map((metadataField) => ({
        ...metadataField,
        queryParam: metadataField.label.trim().toLowerCase().split(' ').join('_'),
      }));
    }

    return {
      ...libraryItem,
      metadataFields: metadataFieldsSorted,
    };
  });

  return libraryItemsSortedWithMetadataFieldsSorted;
};

export const filterAssets = async (http: AxiosInstance, params: AssetFilterParams) => {
  const { libraryId, page, pageSize, mediaFilter } = params;

  // https://frontify.github.io/graphql-reference/type/AssetQueryFilterConditionInput
  const assetQueryFilterConditions = mediaFilter?.map((filter: MediaFilterCondition) => ({
    type: 'METADATA_VALUE',
    operator: 'IS',
    ...filter,
  }));

  const response = await http.post('', {
    query: QUERY_LIBRARY_SEARCH,
    variables: {
      libraryId,
      page,
      pageSize,
      query: {
        ...(assetQueryFilterConditions && {
          filter: {
            andConditions: assetQueryFilterConditions,
          },
        }),
      },
    },
  });

  return response.data.data?.library?.assets;
};

export const findAsset = async (http: AxiosInstance, params: { assetId: string }) => {
  const { assetId } = params;

  const response = await http.post('', {
    query: QUERY_ASSET,
    variables: {
      assetId,
    },
  });

  return response.data.data?.asset;
};

const wrap =
  <P extends Record<string, any>, R>(http: AxiosInstance, fn: any) =>
  (params: P) =>
    fn(http, params);

export const frontifyApi = () => {
  const http = createHttpClient();

  return {
    findAssets: wrap(http, findAssets),
    filterAssets: wrap(http, filterAssets),
    findLibraries: wrap(http, findLibraries),
    findAsset: wrap(http, findAsset),
  };
};
