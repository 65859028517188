import {MappingConfig, ValueDataType} from '@dxp/akeneo-client';

/**
 * The interface for highlight image from catalog.
 */
export interface IHighlightImageElement {
  imageUrl: string;
  code: string;
  label: string;
  title: string;
  alt: string;
}

/**
 * Mapping configuration for IHighlightVideoElement interface
 */
export const iHighlightImageElementConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  label: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
  imageUrl: {
    path: ['values', 'highlight_picture_elements_asset_link'],
    valueData: ValueDataType.string,
    localized: true,
  },
  title: {
    path: ['values', 'title_tag'],
    valueData: ValueDataType.string,
    localized: true,
  },
  alt: {
    path: ['values', 'alt_tag'],
    valueData: ValueDataType.string,
    localized: true,
  },
};
