import styles from './main-navigation-footer.module.scss';
import { motion } from 'framer-motion';
import Address from '../../../../components/address/address';
import LegalNavigation from '../../../../components/legal-navigation/legal-navigation';
import { MainNavigationActions, useAppDispatch } from '../../../../redux';
import React from 'react';

export function MainNavigationFooter() {
  const dispatch = useAppDispatch();

  return (
    <motion.div
      initial={false}
      className={styles['footer']}
      key={'footer'}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ ease: 'easeInOut', duration: 0.3 }}
    >
      <Address className={styles['address']}></Address>

      <LegalNavigation
        className={styles['footer__legal']}
        onClick={() => dispatch(MainNavigationActions.closeMainNavigation())}
      />
    </motion.div>
  );
}

export default MainNavigationFooter;
