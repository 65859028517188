import { createSelector } from '@reduxjs/toolkit';
import { CheckoutState } from './slice';

const selectCheckoutState = (state: CheckoutState) => state;
const selectCheckout = createSelector([selectCheckoutState], (state: CheckoutState) => state.checkout);
const selectLoading = createSelector([selectCheckoutState], (state: CheckoutState) => state.loading);

export const CheckoutSelectors = {
  selectCheckout,
  selectLoading,
};
