import { call, put, takeEvery } from 'redux-saga/effects';
import {
  AssetFamilyPayloadAction,
  AssetFamilyStateProperty,
  CatalogActions,
  ChildProductsPayloadAction,
  PdpByFamilyPayloadAction,
  ProductByIdentifierPayloadAction,
  PdpPayloadAction,
  PdpsPayloadAction,
  ProductModelPayloadAction,
  ProductModelStateProperty,
  ProductPayloadAction,
  ProductStateProperty,
  ReferenceEntityPayloadAction,
  ReferenceEntityStateProperty,
} from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AkeneoSearchObject,
  iAdditionalDocumentMappingConfig,
  iAdditionalMediaLinkMappingConfig,
  iAdditionalMediaMappingConfig,
  iCaseReportMappingConfig,
  iCatalogBrochureMappingConfig,
  IComparableProductConfig,
  ICompatibleMachineMappingConfig,
  iCompatibleMaterialMappingConfig,
  iContactPersonConfig,
  ICrossSellingProductConfig,
  ICrossSellingProductModelConfig,
  iFactMappingConfig,
  iFeatureConfig,
  iFeatureMaterialConfig,
  iHighlightImageElementConfig,
  iHighlightVideoConfig,
  iHighlightVideoElementConfig,
  iIndicationMappingConfig,
  iInstructionMappingConfig,
  IMaterialDetailConfig,
  IPdp,
  iProductImageMappingConfig,
  iProductVideoMappingConfig,
  IRotoConfig,
  iTechnicalDataConfig,
  iTestimonialConfig,
  IUpsellingProductConfig,
} from '../../interfaces';
import {
  ListResponse,
  MappingConfig,
  MappingPropertyConfig,
  mapProduct,
  Product,
  ProductModel,
  ProductQueryParameters,
} from '@dxp/akeneo-client';
import {
  akeneoClient,
  fetchAssetFamilyAsync,
  fetchChildProductsByFamilyAsync,
  fetchChildProductsByParentAsync,
  fetchProductAsync,
  fetchProductModelAsync,
  fetchReferenceEntityAsync,
} from '../../index';
import { PlatformWebappFeatureFlags, toUppercaseLocale } from '@dxp/core';

/**
 * The watcher function to fetch a single pdp.
 */
export function* watchFetchPdp() {
  yield takeEvery(CatalogActions.pdpRequested, fetchPdpAsync);
}

/**
 * The worker function to fetch a single pdp.
 * @param action
 */
function* fetchPdpAsync(action: PayloadAction<PdpPayloadAction>) {
  const { pdpType, categories, slug, scope, mappingConfig, fetchChildren } = action.payload;
  let { locale } = action.payload;

  locale = toUppercaseLocale(locale).replace('-', '_');

  try {
    let searchObject: AkeneoSearchObject = {
      slug: [{ operator: '=', value: slug, locale, scope }],
      categories: [{ operator: 'IN', value: categories, locale, scope }],
    };

    if (PlatformWebappFeatureFlags.commerce.respectAvailableInRegion) {
      locale = toUppercaseLocale(locale).replace('-', '_');

      searchObject = {
        ...searchObject,
        available_in_region: [{ operator: 'IN', value: [locale] }],
      };
    }

    const query: ProductQueryParameters = {
      search: JSON.stringify(searchObject),
    };

    const response: ListResponse & { items: Array<Product> | Array<ProductModel> } = fetchChildren
      ? yield call(akeneoClient[pdpType].getAll, {
          query,
        })
      : yield call(akeneoClient[pdpType].get, {
          query,
        });

    const responseItem: Product | ProductModel | undefined =
      pdpType === 'product'
        ? (response.items as Array<Product>).find((productResponse: Product) => !!productResponse)
        : fetchChildren
        ? (response.items as Array<ProductModel>).find(
            (productResponse: ProductModel) => !!productResponse && !productResponse.parent
          )
        : (response.items as Array<ProductModel>).find((productResponse: ProductModel) => !!productResponse);

    const productModelChildren: Array<ProductModel> | null = fetchChildren
      ? (response.items as Array<ProductModel>).filter((productResponse) => productResponse.parent)
      : null;

    if (responseItem) {
      const product: IPdp = mapProduct<IPdp>(responseItem, mappingConfig, locale);

      yield fetchReferencesForProduct(product, mappingConfig, locale);

      yield put(CatalogActions.pdpReceived(product));
    } else {
      yield put(CatalogActions.pdpRequestFailed());
    }

    if (fetchChildren) {
      if (productModelChildren?.length) {
        const products: Array<IPdp> = [];
        productModelChildren.map((item) => products.push(mapProduct<IPdp>(item, mappingConfig, locale)));

        yield fetchReferencesForProducts(
          products,
          mappingConfig,
          locale,
          true,
          '',
          productModelChildren.map((models) => models.code)
        );

        yield put(CatalogActions.pdpsByFamilyReceived(products));
      } else if (responseItem && Object.prototype.hasOwnProperty.call(responseItem, 'code')) {
        const products: Array<IPdp> = [mapProduct<IPdp>(responseItem, mappingConfig, locale)];

        yield fetchReferencesForProducts(products, mappingConfig, locale, true, '', [
          (responseItem as ProductModel).code,
        ]);

        yield put(CatalogActions.pdpsByFamilyReceived(products));
      }
    }
  } catch (error) {
    yield put(CatalogActions.pdpRequestFailed());
  }
}

/**
 * The watcher function to fetch a single pdp.
 */
export function* watchFetchProductByIdentifier() {
  yield takeEvery(CatalogActions.productByIdentifierRequested, fetchProductByIdentifierAsync);
}

/**
 * The worker function to fetch a single pdp.
 * @param action
 */
function* fetchProductByIdentifierAsync(action: PayloadAction<ProductByIdentifierPayloadAction>) {
  const { identifier, type, mappingConfig } = action.payload;
  let { locale } = action.payload;

  locale = toUppercaseLocale(locale).replace('-', '_');

  try {
    const response: Product | ProductModel = yield call(akeneoClient[type].getOne, { code: identifier });

    if (response) {
      const product: IPdp = mapProduct<IPdp>(response, mappingConfig, locale);

      if (
        PlatformWebappFeatureFlags.commerce.respectAvailableInRegion &&
        product &&
        (!product.available_in_region ||
          !product.available_in_region.some((availableLocale) => availableLocale === locale))
      ) {
        yield put(CatalogActions.productByIdentifierRequestFailed());
      } else {
        yield fetchReferencesForProduct(product, mappingConfig, locale);
        yield put(CatalogActions.productByIdentifierReceived(product));
      }
    } else {
      yield put(CatalogActions.productByIdentifierRequestFailed());
    }
  } catch (error) {
    yield put(CatalogActions.productByIdentifierRequestFailed());
  }
}

/**
 * The watcher function to fetch multiple PDP's by family.
 */
export function* watchFetchPdpsByFamily() {
  yield takeEvery(CatalogActions.pdpsByFamilyRequested, fetchPdpsByFamilyAsync);
}

/**
 * The worker function to fetch multiple PDP's by family
 * @param action
 */
function* fetchPdpsByFamilyAsync(action: PayloadAction<PdpByFamilyPayloadAction>) {
  const { pdpType, family, scope, mappingConfig } = action.payload;
  let { locale } = action.payload;

  locale = toUppercaseLocale(locale).replace('-', '_');

  try {
    let searchObject: AkeneoSearchObject = {
      family: [{ operator: 'IN', value: [family], locale, scope }],
    };

    if (PlatformWebappFeatureFlags.commerce.respectAvailableInRegion) {
      locale = toUppercaseLocale(locale).replace('-', '_');

      searchObject = {
        ...searchObject,
        available_in_region: [{ operator: 'IN', value: [locale] }],
      };
    }

    const query: ProductQueryParameters = {
      search: JSON.stringify(searchObject),
      limit: 50,
    };

    const response: ListResponse & { items: Array<ProductModel> } = yield call(akeneoClient[pdpType].get, {
      query,
    });

    const responseItems: Array<ProductModel> | undefined = response.items as Array<ProductModel>;

    if (responseItems.length) {
      const products: Array<IPdp> = [];
      responseItems.map((item) => products.push(mapProduct<IPdp>(item, mappingConfig, locale)));

      yield fetchReferencesForProducts(products, mappingConfig, locale, true, family, []);

      yield put(CatalogActions.pdpsByFamilyReceived(products));
    } else {
      yield put(CatalogActions.pdpsByFamilyRequestFailed());
    }
  } catch (error) {
    console.log('error', error);
    yield put(CatalogActions.pdpRequestFailed());
  }
}

/**
 * The watcher function to fetch multiple PDP's by category.
 */
export function* watchFetchPdpsByCategory() {
  yield takeEvery(CatalogActions.pdpsByCategoryRequested, fetchPdpsByCategoryAsync);
}

/**
 * The worker function to fetch multiple PDP's by category
 * @param action
 */
function* fetchPdpsByCategoryAsync(action: PayloadAction<PdpsPayloadAction>) {
  const { pdpType, categories, scope, mappingConfig, onlyParent } = action.payload;
  let { locale } = action.payload;

  locale = toUppercaseLocale(locale).replace('-', '_');

  try {
    let searchObject: AkeneoSearchObject = {
      categories: [{ operator: 'IN', value: categories, locale, scope }],
      parent: [{ operator: 'EMPTY' }],
    };

    if (PlatformWebappFeatureFlags.commerce.respectAvailableInRegion) {
      locale = toUppercaseLocale(locale).replace('-', '_');

      searchObject = {
        ...searchObject,
        available_in_region: [{ operator: 'IN', value: [locale] }],
      };
    }

    if (!onlyParent) {
      delete searchObject.parent;
    }

    const query: ProductQueryParameters = { search: JSON.stringify(searchObject) };

    const response: ListResponse & { items: Array<Product> } = yield call(akeneoClient[pdpType].getAll, {
      query,
    });

    const responseItems: Array<Product> | undefined = response.items as Array<Product>;

    if (responseItems.length) {
      const products: Array<IPdp> = [];
      responseItems.map((item) => products.push(mapProduct<IPdp>(item, mappingConfig, locale)));

      yield fetchReferencesForProducts(products, mappingConfig, locale, true, '', []);

      yield put(CatalogActions.pdpsByCategoryReceived(products));
    } else {
      yield put(CatalogActions.pdpsByCategoryRequestFailed());
    }
  } catch (error) {
    console.log('error', error);
    yield put(CatalogActions.pdpsByCategoryRequestFailed());
  }
}

/**
 * Helper function to fetch all reference data entities for one product
 * @param product
 * @param mappingConfig
 * @param locale
 */
export function* fetchReferencesForProduct(product: IPdp, mappingConfig: MappingConfig, locale: string) {
  const referenceEntityActionPayloads: Array<ReferenceEntityPayloadAction> = [
    {
      code: 'contact_person',
      stateProperty: ReferenceEntityStateProperty.contactPersons,
      mappingConfig: iContactPersonConfig,
      locale,
      codes: product.contactPersonCode ? [product.contactPersonCode] : [],
    },
    {
      code: 'technical_data',
      stateProperty: ReferenceEntityStateProperty.technicalData,
      mappingConfig: iTechnicalDataConfig,
      locale,
      codes: product.technicalDataCodes || [],
    },
    {
      code: 'features',
      stateProperty: ReferenceEntityStateProperty.features,
      mappingConfig: iFeatureConfig,
      locale,
      codes: product.featureCodes || [],
    },
    {
      code: 'features_materials',
      stateProperty: ReferenceEntityStateProperty.features,
      mappingConfig: iFeatureMaterialConfig,
      locale,
      codes: product.featureCodes || [],
    },
    {
      code: 'facts_figures_reference_entity',
      stateProperty: ReferenceEntityStateProperty.facts,
      mappingConfig: iFactMappingConfig,
      locale,
      codes: product.factCodes || [],
    },
    {
      code: 'testimonials_entity',
      stateProperty: ReferenceEntityStateProperty.testimonials,
      mappingConfig: iTestimonialConfig,
      locale,
      codes: product.testimonialCodes || [],
    },
    {
      code: 'indications_blanks',
      stateProperty: ReferenceEntityStateProperty.indications,
      mappingConfig: iIndicationMappingConfig,
      locale,
      codes: product.indicationCodes || [],
    },
    {
      code: 'link_list',
      stateProperty: ReferenceEntityStateProperty.additionalMediaLinks,
      mappingConfig: iAdditionalMediaLinkMappingConfig,
      locale,
      codes: product.additionalMediaLinkCodes,
    },
  ];

  const assetFamilyActionPayloads: Array<AssetFamilyPayloadAction> = [
    {
      code: 'highlight_video',
      stateProperty: AssetFamilyStateProperty.highlightVideos,
      mappingConfig: iHighlightVideoConfig,
      locale,
      codes: product.highlightVideoCode ? [product.highlightVideoCode] : [],
    },
    {
      code: 'highlight_video_elements_asset_family',
      stateProperty: AssetFamilyStateProperty.highlightVideoElements,
      mappingConfig: iHighlightVideoElementConfig,
      locale,
      codes: product.highlightVideoElementCodes || [],
    },
    {
      code: 'highlight_picture_elements_asset_family',
      stateProperty: AssetFamilyStateProperty.highlightImageElements,
      mappingConfig: iHighlightImageElementConfig,
      locale,
      codes: product.highlightImageElementCodes || [],
    },
    {
      code: 'catalogues_brochures_asset_family',
      stateProperty: AssetFamilyStateProperty.catalogBrochures,
      mappingConfig: iCatalogBrochureMappingConfig,
      locale,
      codes: product.downloadCodes?.cataloguesBrochures || [],
    },
    {
      code: 'instructions_asset_family',
      stateProperty: AssetFamilyStateProperty.instructions,
      mappingConfig: iInstructionMappingConfig,
      locale,
      codes: product.downloadCodes?.instructions || [],
    },
    {
      code: 'case_reports_asset_family',
      stateProperty: AssetFamilyStateProperty.caseReports,
      mappingConfig: iCaseReportMappingConfig,
      locale,
      codes: product.downloadCodes?.caseReports || [],
    },
    {
      code: 'additional_documents_asset_family',
      stateProperty: AssetFamilyStateProperty.additionalDocuments,
      mappingConfig: iAdditionalDocumentMappingConfig,
      locale,
      codes: product.downloadCodes?.additionalDocuments || [],
    },
    {
      code: 'product_videos',
      stateProperty: AssetFamilyStateProperty.productVideos,
      mappingConfig: iProductVideoMappingConfig,
      locale,
      codes: product.productVideoCodes || [],
    },
    {
      code: 'product_picture_asset_family',
      stateProperty: AssetFamilyStateProperty.productImages,
      mappingConfig: iProductImageMappingConfig,
      locale,
      codes: product.productImageCodes || [],
    },
    {
      code: 'product_group_pictures_asset_family',
      stateProperty: AssetFamilyStateProperty.productImages,
      mappingConfig: iProductImageMappingConfig,
      locale,
      codes: product.productImageCodes || [],
    },
    {
      code: 'additional_media_asset_family',
      stateProperty: AssetFamilyStateProperty.additionalMedia,
      mappingConfig: iAdditionalMediaMappingConfig,
      locale,
      codes: product.additionalMediaCodes || [],
    },
  ];

  const productModelActionPayloads: Array<ProductModelPayloadAction> = [
    {
      code: 'comparableProductModels',
      stateProperty: ProductModelStateProperty.comparableProducts,
      mappingConfig: IComparableProductConfig, // TODO: product model mapping config?
      locale,
      codes: product.comparableProductModelCodes || [],
    },
    {
      code: 'compatibleMaterials',
      stateProperty: ProductModelStateProperty.compatibleMaterials,
      mappingConfig: iCompatibleMaterialMappingConfig,
      locale,
      codes: product.compatibleMaterialCodes || [],
    },
    {
      code: 'crossSellingProductModels',
      stateProperty: ProductModelStateProperty.crossSelling,
      mappingConfig: ICrossSellingProductModelConfig,
      locale,
      codes: product.crossSellingProductModelCodes || [],
    },
  ];

  const productActionPayloads: Array<ProductPayloadAction> = [
    {
      code: 'comparableProducts',
      stateProperty: ProductStateProperty.comparableProducts,
      mappingConfig: IComparableProductConfig,
      locale,
      identifier: product.comparableProductIdentifier || [],
    },
    {
      code: 'crossSellingProducts',
      stateProperty: ProductStateProperty.crossSelling,
      mappingConfig: ICrossSellingProductConfig,
      locale,
      identifier: product.crossSellingProductIdentifier || [],
    },
    {
      code: 'upsellingProducts',
      stateProperty: ProductStateProperty.upselling,
      mappingConfig: IUpsellingProductConfig,
      locale,
      identifier: product.upsellingProductIdentifier || [],
    },
  ];

  for (const referenceEntityActionPayload of referenceEntityActionPayloads.filter(
    (referenceEntityPayloadAction: ReferenceEntityPayloadAction) =>
      Object.values(mappingConfig).some(
        (value: MappingPropertyConfig | string) =>
          typeof value !== 'string' && value?.additionalDataCode === referenceEntityPayloadAction.code
      )
  )) {
    yield fetchReferenceEntityAsync({
      payload: referenceEntityActionPayload,
      type: CatalogActions.referenceEntityRequested.type,
    });
  }

  for (const assetFamilyActionPayload of assetFamilyActionPayloads.filter(
    (assetFamilyPayloadAction: AssetFamilyPayloadAction) =>
      Object.values(mappingConfig).some(
        (value: MappingPropertyConfig | string) =>
          typeof value !== 'string' && value?.additionalDataCode === assetFamilyPayloadAction.code
      )
  )) {
    yield fetchAssetFamilyAsync({
      payload: assetFamilyActionPayload,
      type: CatalogActions.assetFamilyRequested.type,
    });
  }

  for (const productModelActionPayload of productModelActionPayloads.filter(
    (productModelPayloadAction: ProductModelPayloadAction) =>
      Object.values(mappingConfig).some(
        (value: MappingPropertyConfig | string) =>
          typeof value !== 'string' && value?.additionalDataCode === productModelPayloadAction.code
      ) &&
      productModelPayloadAction.codes !== null &&
      productModelPayloadAction.codes.length > 0
  )) {
    yield fetchProductModelAsync({
      payload: productModelActionPayload,
      type: CatalogActions.productModelRequested.type,
    });
  }

  for (const productActionPayload of productActionPayloads.filter(
    (productPayloadAction: ProductPayloadAction) =>
      Object.values(mappingConfig).some(
        (value: MappingPropertyConfig | string) =>
          typeof value !== 'string' && value?.additionalDataCode === productPayloadAction.code
      ) &&
      productPayloadAction.identifier !== null &&
      productPayloadAction.identifier.length > 0
  )) {
    yield fetchProductAsync({
      payload: productActionPayload,
      type: CatalogActions.productRequested.type,
    });
  }
}

/**
 * Helper function to fetch all reference data entities for multiple product
 * @param products
 * @param mappingConfig
 * @param locale
 * @param fetchChildren
 * @param family
 * @param parents
 */
export function* fetchReferencesForProducts(
  products: Array<IPdp>,
  mappingConfig: MappingConfig,
  locale: string,
  fetchChildren: boolean,
  family: string,
  parents: Array<string>
) {
  const referenceEntityActionPayloads: Array<ReferenceEntityPayloadAction> = [
    {
      code: 'contact_person',
      stateProperty: ReferenceEntityStateProperty.contactPersons,
      mappingConfig: iContactPersonConfig,
      locale,
      codes: products.map((product) => (product.contactPersonCode ? product.contactPersonCode : '')),
    },
    {
      code: 'technical_data',
      stateProperty: ReferenceEntityStateProperty.technicalData,
      mappingConfig: iTechnicalDataConfig,
      locale,
      codes: products.map((product) => (product.technicalDataCodes?.length ? product.technicalDataCodes : [])).flat(),
    },
    {
      code: 'features',
      stateProperty: ReferenceEntityStateProperty.features,
      mappingConfig: iFeatureConfig,
      locale,
      codes: products.map((product) => (product.featureCodes?.length ? product.featureCodes : [])).flat(),
    },
    {
      code: 'facts_figures_reference_entity',
      stateProperty: ReferenceEntityStateProperty.facts,
      mappingConfig: iFactMappingConfig,
      locale,
      codes: products.map((product) => (product.factCodes?.length ? product.factCodes : [])).flat(),
    },
    {
      code: 'testimonials_entity',
      stateProperty: ReferenceEntityStateProperty.testimonials,
      mappingConfig: iTestimonialConfig,
      locale,
      codes: products.map((product) => (product.testimonialCodes?.length ? product.testimonialCodes : [])).flat(),
    },
    {
      code: 'indications_blanks',
      stateProperty: ReferenceEntityStateProperty.indications,
      mappingConfig: iIndicationMappingConfig,
      locale,
      codes: products.map((product) => (product.indicationCodes?.length ? product.indicationCodes : [])).flat(),
    },
  ];

  const assetFamilyActionPayloads: Array<AssetFamilyPayloadAction> = [
    {
      code: 'highlight_video',
      stateProperty: AssetFamilyStateProperty.highlightVideos,
      mappingConfig: iHighlightVideoConfig,
      locale,
      codes: products.map((product) => (product.highlightVideoCode ? product.highlightVideoCode : '')),
    },
    {
      code: 'highlight_video_elements_asset_family',
      stateProperty: AssetFamilyStateProperty.highlightVideoElements,
      mappingConfig: iHighlightVideoElementConfig,
      locale,
      codes: products
        .map((product) => (product.highlightVideoElementCodes?.length ? product.highlightVideoElementCodes : []))
        .flat(),
    },
    {
      code: 'highlight_picture_elements_asset_family',
      stateProperty: AssetFamilyStateProperty.highlightImageElements,
      mappingConfig: iHighlightImageElementConfig,
      locale,
      codes: products
        .map((product) => (product.highlightImageElementCodes?.length ? product.highlightImageElementCodes : []))
        .flat(),
    },
    {
      code: 'catalogues_brochures_asset_family',
      stateProperty: AssetFamilyStateProperty.catalogBrochures,
      mappingConfig: iCatalogBrochureMappingConfig,
      locale,
      codes: products
        .map((product) =>
          product.downloadCodes?.cataloguesBrochures ? product.downloadCodes?.cataloguesBrochures : []
        )
        .flat(),
    },
    {
      code: 'instructions_asset_family',
      stateProperty: AssetFamilyStateProperty.instructions,
      mappingConfig: iInstructionMappingConfig,
      locale,
      codes: products
        .map((product) => (product.downloadCodes?.instructions ? product.downloadCodes?.instructions : []))
        .flat(),
    },
    {
      code: 'case_reports_asset_family',
      stateProperty: AssetFamilyStateProperty.caseReports,
      mappingConfig: iCaseReportMappingConfig,
      locale,
      codes: products
        .map((product) => (product.downloadCodes?.caseReports ? product.downloadCodes?.caseReports : []))
        .flat(),
    },
    {
      code: 'additional_documents_asset_family',
      stateProperty: AssetFamilyStateProperty.additionalDocuments,
      mappingConfig: iAdditionalDocumentMappingConfig,
      locale,
      codes: products
        .map((product) =>
          product.downloadCodes?.additionalDocuments ? product.downloadCodes?.additionalDocuments : []
        )
        .flat(),
    },
    {
      code: 'product_videos',
      stateProperty: AssetFamilyStateProperty.productVideos,
      mappingConfig: iProductVideoMappingConfig,
      locale,
      codes: products.map((product) => (product.productVideoCodes?.length ? product.productVideoCodes : [])).flat(),
    },
    {
      code: 'product_picture_asset_family',
      stateProperty: AssetFamilyStateProperty.productImages,
      mappingConfig: iProductImageMappingConfig,
      locale,
      codes: products.map((product) => (product.productImageCodes?.length ? product.productImageCodes : [])).flat(),
    },
    {
      code: 'product_group_pictures_asset_family',
      stateProperty: AssetFamilyStateProperty.productImages,
      mappingConfig: iProductImageMappingConfig,
      locale,
      codes: products.map((product) => (product.productImageCodes?.length ? product.productImageCodes : [])).flat(),
    },
    {
      code: 'additional_media_asset_family',
      stateProperty: AssetFamilyStateProperty.additionalMedia,
      mappingConfig: iAdditionalMediaMappingConfig,
      locale,
      codes: products
        .map((product) => (product.additionalMediaCodes?.length ? product.additionalMediaCodes : []))
        .flat(),
    },
  ];

  const childProductsByFamilyActionPayloads: Array<ChildProductsPayloadAction> = [
    {
      code: 'rotos',
      family,
      stateProperty: ProductStateProperty.rotos,
      mappingConfig: IRotoConfig,
      locale,
    },
  ];

  const childProductsByParentActionPayloads: Array<ChildProductsPayloadAction> = [
    {
      code: 'productChildren',
      stateProperty: ProductStateProperty.productChildren,
      mappingConfig: IMaterialDetailConfig,
      parents,
      locale,
    },
  ];

  const productModelActionPayloads: Array<ProductModelPayloadAction> = [
    {
      code: 'comparableProductModels',
      stateProperty: ProductModelStateProperty.comparableProducts,
      mappingConfig: IComparableProductConfig, // TODO: product model mapping config?
      locale,
      codes: products
        .map((product) => (product.comparableProductModelCodes?.length ? product.comparableProductModelCodes : []))
        .flat(),
    },
    {
      code: 'compatibleMaterials',
      stateProperty: ProductModelStateProperty.compatibleMaterials,
      mappingConfig: iCompatibleMaterialMappingConfig,
      locale,
      codes: products
        .map((product) => (product.compatibleMaterialCodes?.length ? product.compatibleMaterialCodes : []))
        .flat(),
    },
  ];

  const productActionPayloads: Array<ProductPayloadAction> = [
    {
      code: 'comparableProducts',
      stateProperty: ProductStateProperty.comparableProducts,
      mappingConfig: IComparableProductConfig,
      locale,
      identifier: products
        .map((product) => (product.comparableProductIdentifier?.length ? product.comparableProductIdentifier : []))
        .flat(),
    },
    {
      code: 'crossSellingProducts',
      stateProperty: ProductStateProperty.crossSelling,
      mappingConfig: ICrossSellingProductConfig,
      locale,
      identifier: products
        .map((product) => (product.crossSellingProductIdentifier?.length ? product.crossSellingProductIdentifier : []))
        .flat(),
    },
    {
      code: 'compatibleMachines',
      stateProperty: ProductStateProperty.compatibleMachines,
      mappingConfig: ICompatibleMachineMappingConfig,
      locale,
      identifier: products
        .map((product) => (product.compatibleMachineCodes?.length ? product.compatibleMachineCodes : []))
        .flat(),
    },
  ];

  for (const referenceEntityActionPayload of referenceEntityActionPayloads.filter(
    (referenceEntityPayloadAction: ReferenceEntityPayloadAction) =>
      Object.values(mappingConfig).some(
        (value: MappingPropertyConfig | string) =>
          typeof value !== 'string' && value?.additionalDataCode === referenceEntityPayloadAction.code
      )
  )) {
    yield fetchReferenceEntityAsync({
      payload: referenceEntityActionPayload,
      type: CatalogActions.referenceEntityRequested.type,
    });
  }

  for (const assetFamilyActionPayload of assetFamilyActionPayloads.filter(
    (assetFamilyPayloadAction: AssetFamilyPayloadAction) =>
      Object.values(mappingConfig).some(
        (value: MappingPropertyConfig | string) =>
          typeof value !== 'string' && value?.additionalDataCode === assetFamilyPayloadAction.code
      )
  )) {
    yield fetchAssetFamilyAsync({
      payload: assetFamilyActionPayload,
      type: CatalogActions.assetFamilyRequested.type,
    });
  }

  for (const productModelActionPayload of productModelActionPayloads.filter(
    (productModelPayloadAction: ProductModelPayloadAction) =>
      Object.values(mappingConfig).some(
        (value: MappingPropertyConfig | string) =>
          typeof value !== 'string' && value?.additionalDataCode === productModelPayloadAction.code
      ) && productModelPayloadAction.codes.length
  )) {
    yield fetchProductModelAsync({
      payload: productModelActionPayload,
      type: CatalogActions.productModelRequested.type,
    });
  }

  for (const productActionPayload of productActionPayloads.filter(
    (productPayloadAction: ProductPayloadAction) =>
      Object.values(mappingConfig).some(
        (value: MappingPropertyConfig | string) =>
          typeof value !== 'string' && value?.additionalDataCode === productPayloadAction.code
      ) && productPayloadAction.identifier.length
  )) {
    yield fetchProductAsync({
      payload: productActionPayload,
      type: CatalogActions.productRequested.type,
    });
  }

  if (fetchChildren) {
    for (const childProductsByFamilyActionPayload of childProductsByFamilyActionPayloads) {
      if (childProductsByFamilyActionPayload.family && childProductsByFamilyActionPayload.family !== '')
        yield fetchChildProductsByFamilyAsync({
          payload: childProductsByFamilyActionPayload,
          type: CatalogActions.productRequested.type,
        });
    }
    for (const childProductsByParentActionPayload of childProductsByParentActionPayloads) {
      if (childProductsByParentActionPayload.parents && childProductsByParentActionPayload.parents.length)
        yield fetchChildProductsByParentAsync({
          payload: childProductsByParentActionPayload,
          type: CatalogActions.productRequested.type,
        });
    }
  }
}
