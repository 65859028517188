import { FieldError } from 'react-hook-form';
import { i18n } from 'next-i18next';

export const ControllerDefaultRules = {
  maxLength: 255,
};

export const InputDefaultError = (error: FieldError | undefined): string => {
  return error?.type && i18n ? i18n.t(`form.error.${error?.type}`, { ns: 'account' }) : '';
};

// email

export const ControllerEmailRules = {
  pattern: /[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}/,
  required: true,
};

// name

export const ControllerNameRules = {
  maxLength: 100,
  required: true,
};

// password confirm

export const InputConfirmPasswordError = (error: FieldError | undefined): string => {
  if (error?.type && i18n) {
    switch (error.type) {
      case 'validate':
        return i18n.t(`form.error.confirmPassword.${error.type}`, { ns: 'account' });
      default:
        return i18n.t(`form.error.${error.type}`, { ns: 'account' });
    }
  } else {
    return '';
  }
};

// password new

const newPassword = {
  maxLength: 72,
  minLength: 7,
};

export const ControllerNewPasswordRules = {
  maxLength: newPassword.maxLength,
  minLength: newPassword.minLength,
  required: true,
};

export const InputNewPasswordError = (error: FieldError | undefined): string => {
  if (error?.type && i18n) {
    switch (error.type) {
      case 'maxLength':
        return i18n.t(`form.error.newPassword.${error.type}`, { length: newPassword.maxLength, ns: 'account' });
      case 'minLength':
        return i18n.t(`form.error.newPassword.${error.type}`, { length: newPassword.minLength, ns: 'account' });
      default:
        return i18n.t(`form.error.${error.type}`, { ns: 'account' });
    }
  } else {
    return '';
  }
};

// zipCode

export const ControllerZipCodeRules = {
  maxLength: 15,
  required: true,
};
