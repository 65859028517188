import {
  CatalogSelectors,
  CatalogState,
  HidePrice,
  IAkeneoSliderItem,
  IProductOrReferenceData,
  pdpLink,
} from '@dxp/catalog';
import { ProductTeaser, ProductVariant } from '@dxp/commerce';
import { AuthContext, SliderWrapper } from '@dxp/core';
import { VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';
import { TypeCeAkeneoSliderFields } from '../../types';
import styles from './akeneo-slider.module.scss';

export interface AkeneoSliderProps extends TypeCeAkeneoSliderFields {
  items: Array<IProductOrReferenceData>;
}

export const AkeneoSlider = (props: AkeneoSliderProps) => {
  const { items } = props;
  const authContext = useContext(AuthContext);

  const data: Array<IAkeneoSliderItem | undefined> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAkeneoSliderData(state.catalog, {
      items: items.length ? items : [],
      type: items.length ? items[0].type : '',
    })
  );

  const breakpoints = {
    760: {
      slidesPerView: 1.75,
    },
    1020: {
      slidesPerView: 2.33,
    },
  };

  const renderSlide = (item: IAkeneoSliderItem) => {
    if (items[0].type === 'reference-entity') {
      return (
        <>
          <img src={item.imageUrl} alt={item.headline} data-testid={'image'} />
          <div className={styles['slide-content']}>
            <div className={'text-short-medium'} data-testid={'headline'}>
              {item.headline}
            </div>

            <div className={'text-short'}>{item.subline}</div>
          </div>
        </>
      );
    } else {
      const link = item.slug && item.categories && pdpLink(item.slug, item.categories);
      const isPriceHidden = item?.product?.hidePrice
        ? authContext?.isAuthenticated
          ? item.product.hidePrice.includes(HidePrice.FOR_LOGGED)
          : item.product.hidePrice.includes(HidePrice.FOR_GUEST)
        : false;

      return (
        <ProductTeaser
          key={item.identifier}
          sku={item.identifier ? item.identifier : ''}
          image={item.imageUrl ? item.imageUrl : ''}
          variant={items[0].type === 'product-model' ? ProductVariant.PRODUCT_MODEL : ProductVariant.PRODUCT}
          title={item.headline ? item.headline : ''}
          href={link}
          isPriceHidden={isPriceHidden}
        />
      );
    }
  };

  const renderSlides = data?.map((item) => {
    return item ? <SwiperSlide key={uuidv4()}>{renderSlide(item)}</SwiperSlide> : <></>;
  });

  return data?.length > 0 ? (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
    >
      <SliderWrapper
        headline={props.headline || ''}
        copy={props.description || ''}
        loop={false}
        breakpoints={breakpoints}
        arrows={true}
      >
        {renderSlides}
      </SliderWrapper>
    </motion.div>
  ) : (
    <></>
  );
};

export default AkeneoSlider;
