import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHubSpotCustomerProfile } from '@dxp/spryker-client';

type StateError = string | { [key: string]: string } | null;

export interface HubSpotState {
  loading: boolean;
  portalId: string;
  newsletterRegistrationId: string;
}

export const initialHubSpotState: HubSpotState = {
  loading: false,
  portalId: '',
  newsletterRegistrationId: '',
};

export const hubSpotSlice = createSlice({
  name: 'hubSpot',
  initialState: initialHubSpotState,
  reducers: {
    clearState: () => initialHubSpotState,
    setPortalId: (state: HubSpotState, action: PayloadAction<string>) => ({
      ...state,
      portalId: action.payload,
    }),
    clearPortalId: (state: HubSpotState) => ({
      ...state,
      portalId: initialHubSpotState.portalId,
    }),
    setNewsletterRegistrationId: (state: HubSpotState, action: PayloadAction<string>) => ({
      ...state,
      newsletterRegistrationId: action.payload,
    }),
    clearNewsletterRegistrationId: (state: HubSpotState) => ({
      ...state,
      newsletterRegistrationId: initialHubSpotState.newsletterRegistrationId,
    }),
    registerHubSpotUser: (
      state,
      action: PayloadAction<{
        customer: IHubSpotCustomerProfile;
      }>
    ) => {
      state.loading = true;
    },
    registerHubSpotUserSuccess: (state) => {
      state.loading = false;
    },
    registerHubSpotUserError: (state, action: PayloadAction<StateError>) => {
      state.loading = false;
    },
  },
});

export const HubSpotActions = hubSpotSlice.actions;

export default hubSpotSlice.reducer;
