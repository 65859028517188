import { Swiper as TSwiper } from 'swiper/types';
import styles from './core-product-information-slider.module.scss';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import { motion } from 'framer-motion';
import { VeneerAnimation } from '@dxp/veneer';
import { IProductImage } from '../../../interfaces';
import Image from 'next/image';

export interface CoreProductInformationSliderProps {
  images: Array<IProductImage>;
}

const thumbs = 7;

export const CoreProductInformationSlider = (props: CoreProductInformationSliderProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<TSwiper | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const renderGallerySlides = props.images.map((image) => {
    return (
      <SwiperSlide key={image.code}>
        {({ isActive, isPrev }) => (
          <motion.div
            initial={{ x: '-100%' }}
            animate={{
              x: isActive ? '0' : isPrev ? '-100%' : '100%',
            }}
            transition={{ type: 'spring', stiffness: 141, damping: 15 }}
          >
            <Image
              className={styles['gallery__image']}
              src={image.imageUrl}
              alt={image.label}
              width={890}
              height={860}
              priority
            />
          </motion.div>
        )}
      </SwiperSlide>
    );
  });

  const renderThumbSlides = props.images.map((image) => {
    return (
      <SwiperSlide key={image.code} className={styles['thumbs__slide']}>
        <Image src={image.imageUrl} alt={image.label} width={120} height={90} />
      </SwiperSlide>
    );
  });

  return (
    <div className={styles['container']}>
      {!!props.images?.length && (
        <>
          <div className={styles['gallery']}>
            <Swiper
              thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
              modules={[Navigation, Thumbs]}
              className={styles['gallery__slider']}
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
            >
              {renderGallerySlides}
            </Swiper>
            {props.images.length > 1 && (
              <div className={styles['gallery__pagination']} style={{ width: 30 * props.images.length + 'px' }}>
                <motion.div
                  style={{ left: 30 * activeIndex + 'px' }}
                  transition={{ duration: 0.47, ease: VeneerAnimation.easings.inOutCubic }}
                  layout
                />
              </div>
            )}
          </div>
          {props.images.length > 1 && (
            <div className={styles['thumbs']}>
              <Swiper
                onSwiper={setThumbsSwiper}
                slidesPerView={thumbs}
                spaceBetween={10}
                watchSlidesProgress={true}
                modules={[Navigation, Thumbs]}
                className={styles['thumbs__slider']}
              >
                {renderThumbSlides}
              </Swiper>

              <div
                className={styles['thumbs__pagination']}
                style={{ width: `${(100 / thumbs) * props.images.length}%` }}
              >
                <motion.div
                  style={{
                    left: (100 / props.images.length) * activeIndex + '%',
                    width: `calc((${100 / props.images.length}%) - 10px)`,
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 141,
                    damping: 15,
                    duration: 0.4,
                  }}
                  layout
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CoreProductInformationSlider;
