import { call, put, takeEvery } from 'redux-saga/effects';
import { contentful } from '../../../utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { ContentfulDataActions } from '../contentful-data.slice';
import { EntryCollection } from 'contentful';
import { TypeFaqFields, TypeFaqTopicFields, IFaqQuestion, IFaqTopic } from '../../../types';

export function* watchFetchFaqTopics() {
  yield takeEvery(ContentfulDataActions.faqTopicsRequested, fetchFaqTopicsAsync);
}

function* fetchFaqTopicsAsync(action: PayloadAction<string>) {
  try {
    const faqTopics: EntryCollection<TypeFaqTopicFields> = yield call(contentful.getFaqTopics, action.payload);

    const response: Array<IFaqTopic> = faqTopics.items.map((faq): IFaqTopic => {
      return { id: faq.sys.id, faqTopic: faq.fields };
    });

    if (response) {
      yield put(ContentfulDataActions.faqTopicsReceived(response));
    }
  } catch (error) {
    yield put(ContentfulDataActions.faqTopicsRequestFailed());
  }
}

export function* watchFetchFaqQuestions() {
  yield takeEvery(ContentfulDataActions.faqQuestionsRequested, fetchFaqQuestionsAsync);
}

function* fetchFaqQuestionsAsync(action: PayloadAction<string>) {
  try {
    const faqQuestions: EntryCollection<TypeFaqFields> = yield call(contentful.getFaqQuestions, action.payload);

    const response: Array<IFaqQuestion> = faqQuestions.items.map((faq): IFaqQuestion => {
      return { id: faq.sys.id, faq: faq.fields };
    });

    if (response) {
      yield put(ContentfulDataActions.faqQuestionsReceived(response));
    }
  } catch (error) {
    yield put(ContentfulDataActions.faqQuestionsRequestFailed());
  }
}
