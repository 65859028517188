/**
 * The interface for products from catalog.
 * This interface holds all codes needed for further catalog elements
 */
export interface IPdp {
  family?: string;
  code?: string;
  sku?: string;
  slug: string;
  slugs: Array<ISlug>;
  categories: Array<string>;
  parent?: string;
  name: string;
  claim: string;
  shortDescription: string;
  callToAction: Array<string>;
  moreInformationSlug: string;
  hidePrice: HidePrice[];
  technicalDataCodes?: Array<string>;
  contactPersonCode?: string;
  longDescriptionHeadline: string;
  longDescription: string;
  highlightHeadline?: string;
  highlightText?: string;
  highlightVideoElementCodes?: Array<string>;
  highlightImageElementCodes?: Array<string>;
  highlightVideoCode?: string;
  downloadCodes?: {
    cataloguesBrochures: Array<string>;
    instructions: Array<string>;
    caseReports: Array<string>;
    additionalDocuments: Array<string>;
  };
  featureDescription: string;
  featureCodes: Array<string>;
  productVideoCodes?: Array<string>;
  productImageCodes: Array<string>;
  factCodes: Array<string>;
  additionalMediaCodes?: Array<string>;
  additionalMediaHeadline: string;
  compatibleMaterialCodes?: Array<string>;
  compatibleMachineCodes?: Array<string>;
  testimonialCodes: Array<string>;
  crossSellingProductIdentifier?: Array<string>;
  crossSellingProductModelCodes?: Array<string>;
  upsellingProductIdentifier?: Array<string>;
  indicationCodes?: Array<string>;
  additionalMediaLinkCodes: Array<string>;
  blankShape?: string;
  comparableProductIdentifier?: Array<string>;
  comparableProductModelCodes?: Array<string>;
  available_in_region?: Array<string>;
}

export interface ISlug {
  locale: string;
  scope: string | null;
  data: string;
}

export enum HidePrice {
  FOR_LOGGED = 'forLogged',
  FOR_GUEST = 'forGuest',
}
