import React from 'react';
import styles from './upselling.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import { UpsellingTeaser } from '@dxp/commerce';


export interface UpsellingBlockProps {
  children: React.ReactElement<typeof UpsellingTeaser>[] | React.ReactNode;
}

export const UpsellingBlock = (props: UpsellingBlockProps) => {
  const { t } = useTranslation('common');

  return (props.children && React.Children.count(props.children) > 0) ? (
    <motion.div
      className={styles['container']}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
    >
      <div className={classNames('subheading', styles['headline'])} data-testid="headline">
        {t('product.detail.upselling.headline')}
      </div>
      <div className={styles['items']} data-testid="items">
        {props.children}
      </div>
    </motion.div>
  ) : null;
};

export default UpsellingBlock;
