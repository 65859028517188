import {MappingConfig, ValueDataType} from '@dxp/akeneo-client';

/**
 * The interface for an additional media from catalog.
 * Extending the general download interface
 */
export interface IAdditionalMedia {
  code: string;
  title: string;
  videoUrl: string;
  thumbnailUrl: string;
  sequence: number;
}

/**
 * Mapping configuration for IAdditionalMedia interface
 */
export const iAdditionalMediaMappingConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  title: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
  videoUrl: {
    path: ['values', 'frontify_link'],
    valueData: ValueDataType.string,
    localized: false,
  },
  thumbnailUrl: {
    path: ['values', 'thumbnail_additional_media'],
    valueData: ValueDataType.string,
    localized: false,
  },
  sequence: {
    path: ['values', 'sequence'],
    valueData: ValueDataType.string,
    localized: false,
    modifier: (sequence: string) => (typeof parseInt(sequence) === 'number' ? parseInt(sequence) : 0),
  },
};
