import axios, { AxiosInstance, AxiosResponse } from 'axios';
const DOMAIN = process.env['NX_FRONTIFY_DOMAIN'] || '';
const TOKEN = process.env['NX_FRONTIFY_ACCESS_TOKEN'] || '';

export * from './redux';
export * from './interfaces';

const defaultConfig = {
  insecure: false,
  retryOnError: true,
  headers: {} as Record<string, string | number | boolean>,
  httpAgent: false,
  httpsAgent: false,
  timeout: 30000,
  proxy: false as const,
  basePath: '',
};

/**
 * Create preconfigured axios instance
 * @private
 * @return {AxiosInstance} Initialized axios instance
 */
export const createHttpClient = (): AxiosInstance => {
  return axios
    .create({
      ...defaultConfig,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${TOKEN}`,
        'x-frontify-beta': 'enabled',
      },
      baseURL: `https://${DOMAIN}/graphql`
    }) as AxiosInstance
};