import styles from './core-product-information-video-item.module.scss';
import React, { ForwardedRef } from 'react';
import classNames from 'classnames';

export interface CoreProductInformationVideoProps {
  video: string;
  poster?: string;
  hide?: boolean;
  onEnded: (event: React.SyntheticEvent<HTMLVideoElement>) => void;
}

export const CoreProductInformationVideoItem = React.forwardRef(
  (props: CoreProductInformationVideoProps & { className?: string }, ref: ForwardedRef<HTMLVideoElement | null>) => {
    const classes: string = classNames(
      styles['video'],
      {
        [styles['video--hide']]: props.hide,
      },
      props.className
    );

    return (
      <video
        ref={ref}
        className={classes}
        muted
        playsInline
        poster={props.poster}
        onEnded={props.onEnded}
        data-testid={'core-product-video'}
      >
        <source src={props.video} type="video/mp4" data-testid={'source'} />
        Your browser does not support the video tag.
      </video>
    );
  }
);

export default CoreProductInformationVideoItem;
