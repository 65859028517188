import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

export interface IUpsellingProduct {
  name: string;
  description: string;
  sku: string;
  slug: string;
  categories: Array<string>;
  callToAction: Array<string>;
  productImageCodes: Array<string>;
}

/**
 * Mapping configuration for IUpsellingProduct interface
 */
export const IUpsellingProductConfig: MappingConfig = {
  raw: 'raw',
  slug: {
    path: ['values', 'slug'],
    valueData: ValueDataType.string,
    localized: true,
  },
  categories: {
    path: ['categories'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  sku: {
    path: ['identifier'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  name: {
    path: ['values', 'name'],
    valueData: ValueDataType.string,
    localized: true,
  },
  description: {
    path: ['values', 'short_description'],
    valueData: ValueDataType.string,
    localized: true,
  },
  callToAction: {
    path: ['values', 'call_to_action'],
    valueData: ValueDataType.strings,
    localized: true,
  },
  productImageCodes: {
    path: ['values', 'product_pictures_attribute'],
    valueData: ValueDataType.strings,
    localized: false,
    additionalDataCode: 'product_picture_asset_family',
  },
};
