import classNames from 'classnames';
import styles from './fullsize-media-teaser.module.scss';
import Link from 'next/link';
import { Colors, StandardButton } from '@dxp/veneer';
import { useVideoAutoplay } from '@dxp/core';
import { MutableRefObject, useRef } from 'react';
import Image from 'next/image';

export interface FullsizeMediaTeaserProps {
  title: string;
  copy: string;
  linkText: string;
  linkUrl: string;
  imageUrl?: string;
  videoUrl?: string;
  variant: 'small' | 'large';
}

export const FullsizeMediaTeaser = (props: FullsizeMediaTeaserProps & { className?: string }) => {
  const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null);

  useVideoAutoplay(videoRef);

  return (
    <div
      className={classNames(styles['container'], props.className, styles[`variant--${props.variant}`])}
      data-testid={'teaser'}
    >
      <div className="inner">
        <h2
          className={classNames(props.variant === 'small' ? 'heading-3' : 'heading-2', styles['headline'])}
          data-testid={'title'}
        >
          {props.title}
        </h2>
        <p className={classNames('text-long', styles['text'])} data-testid={'bodytext'}>
          {props.copy}
        </p>
        <Link href={props.linkUrl} className={styles['link']} passHref>
          <StandardButton label={props.linkText} variant={Colors.feelgoodMint} data-testid={'button'}></StandardButton>
        </Link>
      </div>

      {props.imageUrl && !props.videoUrl && (
        <div className={classNames(styles['media'], styles['media--image'])}>
          <Image
            src={props.imageUrl}
            alt={props.title}
            data-testid={'image'}
            className={styles['media__element']}
            fill={true}
          />
        </div>
      )}

      {props.videoUrl && (
        <div className={classNames(styles['media'], styles['media--video'])}>
          <video
            className={styles['media__element']}
            ref={videoRef}
            src={props.videoUrl}
            poster={props.imageUrl ?? ''}
            playsInline={true}
            controls={false}
            muted={true}
            loop={true}
            data-testid={'video'}
          ></video>
        </div>
      )}
    </div>
  );
};

export default FullsizeMediaTeaser;
