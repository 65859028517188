import styles from './special-information-teaserboxes.module.scss';
import {
  SpecialInformationTeaser,
  SpecialInformationTeaserProps,
} from '../../components/teaser/special-information-teaser/special-information-teaser';
import { v4 as uuidv4 } from 'uuid';
import { SwiperSlide } from 'swiper/react';
import { ScreenSizes, useScreenSizes, VeneerAnimation } from '@dxp/veneer';
import { useEffect, useMemo, useRef } from 'react';
import Swiper, { SwiperOptions } from 'swiper';
import classNames from 'classnames';
import { motion } from 'framer-motion';

export interface SpecialInformationTeaserBoxesProps {
  items: Array<SpecialInformationTeaserProps>;
}

export const SpecialInformationTeaserBoxes = (props: SpecialInformationTeaserBoxesProps) => {
  const screenSize = useScreenSizes();
  const swiperRef = useRef<Swiper | null>(null);
  const renderSlides = props.items?.map((item) => {
    return (
      <SwiperSlide key={uuidv4()} className={styles['slide']}>
        <SpecialInformationTeaser className={styles['item']} {...item} />
      </SwiperSlide>
    );
  });

  const config: SwiperOptions = useMemo<SwiperOptions>(
    () => ({
      slidesPerView: 1,
      spaceBetween: 0,
      breakpoints: {
        760: {
          slidesPerView: 1.4,
          spaceBetween: 23,
        },
        1020: {
          slidesPerView: 3,
          spaceBetween: 25,
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (swiperRef.current && screenSize === ScreenSizes.SMALL) {
      swiperRef.current.destroy(true, true);
    } else {
      swiperRef.current = new Swiper('.swiper-container', config);
    }
  }, [screenSize, config]);

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
    >
      {!!props.items?.length && (
        <div className={classNames('swiper-container', styles['slider'])}>
          <div className={classNames('swiper-wrapper', styles['slide-wrapper'])}>{renderSlides}</div>
        </div>
      )}
    </motion.div>
  );
};

export default SpecialInformationTeaserBoxes;
