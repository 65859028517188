import { RefObject, useEffect, useState } from 'react';

export interface Offset {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export const useOffset = (parent: RefObject<HTMLElement>, element: RefObject<HTMLElement>): Offset => {
  const [offset, setOffset] = useState<Offset>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  useEffect(() => {
    if (!parent?.current && !element.current) return;

    const handleResize = () => {
      const rectOuter = parent?.current?.getBoundingClientRect();
      const rectElement = element?.current?.getBoundingClientRect();

      if (rectOuter && rectElement) {
        setOffset({
          top: rectElement.top - rectOuter.top,
          right: rectOuter.right - rectElement.right,
          bottom: rectOuter.bottom - rectElement.bottom,
          left: rectElement.left - rectOuter.left,
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [parent, element]);

  return offset;
};
