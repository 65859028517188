import styles from './vacancy-listing-short.module.scss';
import { Colors, IconLink, RowItem, StandardButton } from '@dxp/veneer';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { ContentfulDataSelectors, ContentfulDataState, IVacancy } from '@dxp/content';
import classNames from 'classnames';
import { PlatformWebappFeatureFlags } from '@dxp/core';

export interface VacancyListingShortProps {
  intro: string;
  linkToFullList: string;
}

export const VacancyListingShort = ({ intro, linkToFullList }: VacancyListingShortProps) => {
  const { t } = useTranslation('common');
  const limit = 4;

  const items: Array<IVacancy> = useSelector((state: { contentfulData: ContentfulDataState }) =>
    ContentfulDataSelectors.selectVacancies(state.contentfulData)
  );

  return (
    <div className={styles['container']}>
      <div className={styles['intro']} data-testid={'vacancy-short-intro'}>
        {intro}
      </div>

      <div className={styles['filter-links']}>
        {['student', 'beginner', 'experienced'].map((entry) => (
          <Link
            className={classNames(
              styles['filter-link'],
              items?.filter((el) => el.vacancy.entryLevel === entry).length === 0 ? styles['filter-link--disabled'] : ''
            )}
            href={{ pathname: linkToFullList, query: { 'vacancy-level': entry } }}
            key={`level-${entry}`}
          >
            <IconLink color={Colors.smartBlue} className={styles['filter-link__icon']} />
            <div className={styles['filter-link__text']}>
              <div className={styles['filter-link__label']}>{t(`vacancies.shortList.filter.${entry}`)}</div>
              <div className={styles['filter-link__vacancies']}>
                {t('vacancies.shortList.filter.seeFiltered', {
                  amount: items?.filter((el) => el.vacancy.entryLevel === entry).length,
                })}
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className={styles['items']}>
        {items?.slice(0, limit).map((item) => (
          <Link
            href={item.vacancy.detailLink ?? '#'}
            target={'_blank'}
            key={item.vacancy.title}
            data-testid={'vacancy-detail-link'}
          >
            <RowItem
              id={'a'}
              title={item.vacancy.title}
              meta={item.vacancy.entryLevel ?? ''}
              additional_information={item.vacancy.location?.fields.title ?? ''}
              variant={'vacancy'}
              hideWishlist={!PlatformWebappFeatureFlags.wishlistButton}
              iconColor={Colors.smartBlue}
            />
          </Link>
        ))}
      </div>

      {items?.length && items.length > limit && (
        <div className={styles['buttons']}>
          <Link href={linkToFullList}>
            <StandardButton
              label={`${t('vacancies.seeAll', { amount: items?.length })}`}
              variant={Colors.brilliantBlue}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default VacancyListingShort;
