import styles from './social-media-teaser.module.scss';
import { motion } from 'framer-motion';
import { VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { TypeCeSocialMediaTeaserFields } from '../../types';
import { SocialMediaBar } from '@dxp/core';
import Image from 'next/image';

export function SocialMediaTeaser({
  title,
  tileTitle,
  tileSubline,
  image1url,
  image2url,
  image3url,
  image4url,
  image5url,
}: TypeCeSocialMediaTeaserFields) {
  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
    >
      <div className={styles['title-container']}>
        <h2 className={classNames('statement', styles['title'])} data-testid={'title'}>
          {title}
        </h2>

        <div className={styles['social-media-bar']}>
          <SocialMediaBar theme={'dark'} direction={'horizontal'} />
        </div>
      </div>

      <div className={styles['tile-container']}>
        {!!image1url && image1url !== '' && (
          <div className={styles['tile__image']}>
            <Image width={428} height={428} src={image1url} data-testid="image1" alt={''} role={'presentation'} />
          </div>
        )}
        {!!image2url && image2url !== '' && (
          <div className={styles['tile__image']}>
            <Image width={428} height={428} src={image2url} data-testid="image2" alt={''} role={'presentation'} />
          </div>
        )}
        {!!image3url && image3url !== '' && (
          <div className={classNames(styles['tile__image'], styles['cutted'])}>
            <Image width={428} height={428} src={image3url} data-testid="image3" alt={''} role={'presentation'} />
          </div>
        )}
        <div className={styles['tile']}>
          <div data-testid={'tileTitle'} className={styles['tile__title']}>
            {tileTitle}
          </div>
          <p data-testid={'tileSubline'}>{tileSubline}</p>
        </div>
        {!!image4url && image4url !== '' && (
          <div className={styles['tile__image']}>
            <Image width={428} height={428} src={image4url} data-testid="image4" alt={''} role={'presentation'} />
          </div>
        )}
        {!!image5url && image5url !== '' && (
          <div className={classNames(styles['tile__image'], styles['cutted'])}>
            <Image width={428} height={428} src={image5url} data-testid="image5" alt={''} role={'presentation'} />
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default SocialMediaTeaser;
