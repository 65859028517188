import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Colors, IconArrow, RoundButton, Testimonial } from '@dxp/veneer';
import { ITestimonial } from '@dxp/catalog';
import styles from './testimonials.module.scss';
import { MutableRefObject, useRef, useState } from 'react';
import { EffectFade, Swiper as ISwiper } from 'swiper';
import { useTranslation } from 'next-i18next';
import { v4 as uuidv4 } from 'uuid';

export interface TestimonialsProps {
  items: ITestimonial[];
}

export const Testimonials = ({ items }: TestimonialsProps) => {
  const { t } = useTranslation('common');

  const swiperRef: MutableRefObject<ISwiper | null> = useRef(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [previousIndex, setPreviousIndex] = useState<number>(0);
  const [isBeginning, setIsBeginning] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  let numberOfAnimations = 0;

  const onAnimationStart = () => {
    if (numberOfAnimations <= 0) swiperRef.current?.disable();

    numberOfAnimations += 1;
  };

  const onAnimationComplete = () => {
    numberOfAnimations -= 1;

    if (numberOfAnimations <= 0) swiperRef.current?.enable();
  };

  const renderSlides = items?.map((testimonial: ITestimonial, index: number) => {
    return (
      <SwiperSlide key={testimonial.code ?? uuidv4()} className={styles['slide']}>
        <Testimonial
          animation={true}
          animationActive={activeIndex === index}
          animationDirection={activeIndex > previousIndex ? 'forwards' : 'backwards'}
          animationVisible={index === activeIndex || index === previousIndex}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
          imageUrl={testimonial.imageUrl}
          partnerCompany={testimonial.partnerCompany}
          partnerLocation={testimonial.partnerLocation}
          partnerName={testimonial.partnerName}
          text={testimonial.text}
        />
      </SwiperSlide>
    );
  });

  const onPrevClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const onNextClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const onActiveIndexChange = (swiper: ISwiper) => {
    setActiveIndex(swiper.activeIndex);
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
    setPreviousIndex(swiper.previousIndex);
  };

  const swiperProps: SwiperProps = {
    className: styles['slider'],
    allowTouchMove: false,
    draggable: false,
    simulateTouch: false,
    effect: 'fade',
    modules: [EffectFade],
    onActiveIndexChange: onActiveIndexChange,
    onSwiper: (swiper: ISwiper) => {
      swiperRef.current = swiper;

      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    },
    speed: 0,
    // don not change the loop parameter
    loop: false,
  };

  return items?.length > 0 ? (
    <div className={styles['container']} data-elastic-include={true}>
      <div className={styles['grid']}>
        {items.length && (
          <Swiper {...swiperProps} data-testid={'testimonial'}>
            {renderSlides}
          </Swiper>
        )}

        {items.length > 1 ? (
          <div className={styles['controls']}>
            <RoundButton
              disabled={isBeginning}
              onClick={onPrevClick}
              icon={<IconArrow rotation={90} />}
              variant={Colors.brilliantBlue}
              size={'medium'}
              title={t('previous')}
            />
            <RoundButton
              disabled={isEnd}
              onClick={onNextClick}
              icon={<IconArrow rotation={270} />}
              variant={Colors.brilliantBlue}
              size={'medium'}
              title={t('next')}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default Testimonials;
