import classNames from 'classnames';
import { ForwardedRef, forwardRef, ReactElement, useId, useState } from 'react';
import styles from './checkbox.module.scss';
import { Colors } from '../../../types';

export interface CheckboxProps {
  variant?: Colors.lightGrey | Colors.white;
  error?: string;
  label?: string;
  name?: string;
  required?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: ReactElement;
}

export const Checkbox = forwardRef(
  (props: CheckboxProps & { className?: string }, ref?: ForwardedRef<HTMLInputElement>) => {
    const id = useId();
    const defaultChecked = props.checked ? props.checked : false;
    const [isChecked, setIsChecked] = useState(defaultChecked);
    const classes = classNames(
      styles['container'],
      'checkbox',
      styles['container--' + props.variant],
      props.className,
      props.error ? 'invalid' : '',
      props.required ? 'required' : ''
    );

    const handleChange = () => {
      setIsChecked(!isChecked);
      if (props.onChange) {
        props.onChange(!isChecked);
      }
    };

    return (
      <div className={classes} data-testid={'checkbox'}>
        <input
          className={props.variant}
          id={id}
          type="checkbox"
          name={props.name}
          disabled={props.disabled}
          checked={isChecked}
          ref={ref}
          onChange={handleChange}
          onBlur={props.onBlur}
          data-testid={'checkbox-input'}
        />
        <label htmlFor={id} data-testid={'label'}>
          {props.label ? props.label : props.children}
        </label>
      </div>
    );
  }
);

export default Checkbox;
