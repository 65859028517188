import styles from './word-cloud.module.scss';
import React from 'react';
import WordCloudLineSplit from './word-cloud-line-split/word-cloud-line-split';
import { ScreenSizes, useScreenSizes, VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import WordCloudLine from './word-cloud-line/word-cloud-line';
import { v4 as uuidv4 } from 'uuid';

export interface WordCloudProps {
  headline: string;
  items: Array<string>;
}

export function WordCloud({ headline, items }: WordCloudProps) {
  const screensize = useScreenSizes();

  return (
    <motion.div
      className={styles['container']}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
    >
      <div className={styles['inner']}>
        <h2 className={styles['headline']}>{headline}</h2>
        <div className={styles['items']}>
          {screensize !== ScreenSizes.SMALL ? (
            <WordCloudLineSplit text={items.join(',')} />
          ) : (
            items.map((item) => <WordCloudLine words={[item]} key={uuidv4()} showDivider={false}></WordCloudLine>)
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default WordCloud;
