import { AuthContext, Pagination, PlatformWebappPageSize, VideoItem, WebinarItem } from '@dxp/core';
import {
  ExtendedFrontifyAsset,
  Filters as FrontifyFilters,
  FrontifySelectors,
  mapAsset,
  useFrontifyFilters,
} from '@dxp/frontify-client';
import { Filters, SelectedFilter } from '@dxp/veneer';
import { useRouter } from 'next-translate-routes';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './video-listing.module.scss';

export const VideoListing = () => {
  const wasInited = useRef(false);
  const authContext = useContext(AuthContext);
  const router = useRouter();

  const assets = useSelector(FrontifySelectors.getFrontifyAssetsFiltered);
  const assetsTotal = useSelector(FrontifySelectors.getFrontifyAssetsFilteredTotal);
  const videos = useMemo(() => assets.map(mapAsset), [assets]);

  const { filters, filtersState, applyFilters } = useFrontifyFilters();

  useEffect(() => {
    if (wasInited.current) return;

    wasInited.current = true;

    const url = new URLSearchParams(window.location.search);
    const params = Array.from(url.entries()).reduce((acc, [key, value]) => {
      if (key === 'page' || key === 'pageSize') {
        acc[key] = Number(value);
      } else {
        acc.mediaFilter = [
          ...(acc?.mediaFilter || []),
          {
            queryParam: key,
            value,
          },
        ];
      }

      return acc;
    }, {} as Partial<FrontifyFilters>);

    if (Object.keys(params).length) {
      applyFilters({ ...filtersState, ...params });
    }
  }, []);

  const renderItem = (asset: ExtendedFrontifyAsset) => {
    if (asset.type?.includes('Webinar')) {
      const webinarSlug = asset.title.toLocaleLowerCase().split(' ').join('_');
      const detailsUrl = `/${router.locale}${router.asPath.split('?')[0]}/${webinarSlug}?assetId=${asset?.id}`;
      const tags = asset.tags?.map(({ value }) => value);

      return (
        <WebinarItem
          key={asset.id}
          webinarTitle={asset.title || ''}
          speaker={asset.speaker}
          description={asset.description}
          isRegisterShown={!authContext?.isAuthenticated && asset.isHiddenForGuest}
          detailsUrl={detailsUrl}
          tagline={tags}
        />
      );
    }

    return (
      <VideoItem
        key={asset.id}
        title={asset.title || ''}
        thumbnailUrl={asset.thumbnail}
        modalItems={[
          {
            title: asset.title || '',
            description: asset.description || '',
            url: asset.previewUrl ?? '',
            type: asset.videoType,
            alt: asset.title || '',
            autoplay: false,
          },
        ]}
      />
    );
  };

  const pages = useMemo(() => Math.ceil(assetsTotal / PlatformWebappPageSize), [assetsTotal]);

  const onPageChange = (page: number) => applyFilters({ ...filtersState, page });

  const onFilterChange = (filters: SelectedFilter[]) =>
    applyFilters({ ...filtersState, page: 1, mediaFilter: filters });

  return (
    <div className={styles['container']}>
      <Filters filters={filters} onChange={onFilterChange} selectedFilters={filtersState.mediaFilter} />
      <div className={styles['list']}>{videos.map(renderItem)}</div>

      {pages > 1 && <Pagination nPages={pages} currentPage={filtersState.page} setCurrentPage={onPageChange} />}
    </div>
  );
};

export default VideoListing;
