import { OrderPaymentState } from './slice';
import { createSelector } from '@reduxjs/toolkit';

//select single order of current customer
const selectOrderPaymentState = (state: OrderPaymentState) => state;
const selectOrderPayment = createSelector([selectOrderPaymentState], (state: OrderPaymentState) => state.orderPayment);

const selectOrderPaymentLoading = createSelector(
  [selectOrderPaymentState],
  (state: OrderPaymentState) => state.loading
);

export const OrderPaymentSelectors = {
  selectOrderPaymentState,
  selectOrderPayment,
  selectOrderPaymentLoading,
};
