import { parseCheckoutResponse } from '../helper';
import { ICheckout, ICheckoutRequest, ICheckoutRequestAttributes } from '../interfaces';
import { EIncludeTypes } from '../types';
import { AxiosInstance, AxiosResponse } from 'axios';
import raw from './raw';

const include: string = [EIncludeTypes.ORDERS].join(',');

/**
 * Places an order for authorized users - be sure Authorization header is set in http instance!
 * @see https://docs.spryker.com/docs/pbc/all/cart-and-checkout/202212.0/base-shop/manage-using-glue-api/check-out/check-out-purchases.html#place-an-order
 * @param {AxiosInstance} http the axios instance
 * @param payload: {checkoutRequestAttributes: ICheckoutRequestAttributes} Object with checkoutRequestAttributes
 * @return {Promise<ICheckout | null>} the checkout response
 */
export const postAuthorized = (
  http: AxiosInstance,
  payload: { checkoutRequestAttributes: ICheckoutRequestAttributes }
): Promise<ICheckout | null> => {
  const data: ICheckoutRequest = {
    data: {
      type: 'checkout',
      attributes: payload.checkoutRequestAttributes,
    },
  };

  return raw.post(http, `checkout`, data, { params: { include } }).then((response: AxiosResponse<any>) => {
    return response ? parseCheckoutResponse(response.data.data, response.data.included) : null;
  });
};

/**
 * Places an order for unauthorized users - be sure anonymId is set in payload!
 * @see https://docs.spryker.com/docs/pbc/all/cart-and-checkout/202212.0/base-shop/manage-using-glue-api/check-out/check-out-purchases.html#place-an-order
 * @param { AxiosInstance } http the axios instance
 * @param payload: { checkoutRequestAttributes: ICheckoutRequestAttributes, anonymId: string } Object with checkoutRequestAttributes
 * @return { Promise<ICheckout | null> } the checkout data
 */
export const postUnauthorized = (
  http: AxiosInstance,
  payload: { checkoutRequestAttributes: ICheckoutRequestAttributes; anonymId: string }
): Promise<ICheckout | null> => {
  const data: ICheckoutRequest = {
    data: {
      type: 'checkout',
      attributes: payload.checkoutRequestAttributes,
    },
  };

  return raw
    .post(http, `checkout`, data, {
      params: { include },
      headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId },
    })
    .then((response: AxiosResponse<any>) => {
      return response ? parseCheckoutResponse(response.data.data, response.data.included) : null;
    });
};
