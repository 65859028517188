import styles from './ag-logo.module.scss';
import classNames from 'classnames';
import { Colors } from '../../../types';

export interface AgLogoProps {
  color?:
    | 'unstyled'
    | Colors.brilliantBlue
    | Colors.shinyYellow
    | Colors.feelgoodMint
    | Colors.smartBlue
    | Colors.black
    | Colors.white;
}

export const AgLogo = (props: AgLogoProps & { className?: string }) => {
  return (
    <svg
      data-testid="logo-svg"
      fill="none"
      viewBox="0 0 201 24"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles[props.color ?? 'unstyled'], styles['logo'], props.className)}
    >
      <path
        d="m.472656 17.8813v6.1187c3.132914-3.7978 8.031644-6.2242 13.500044-6.2242 5.4683 0 10.367 2.4791 13.5 6.2242v-6.1187c-3.4747-2.8483-8.3735-4.5362-13.5-4.5362-5.06966 0-10.02536 1.6879-13.500044 4.5362zm0-8.75603v4.85273c4.044304-1.8461 8.658224-2.9011 13.500044-2.9011 4.8417 0 9.4557 1.055 13.5 2.9011v-4.85273c-4.1583-1.58241-8.7152-2.47912-13.5-2.47912-4.78485 0-9.34182.84396-13.500044 2.47912zm13.500044-9.12527c-4.61397 0-9.11397.421978-13.500044 1.21319v4.48351c4.329114-.84395 8.886074-1.31868 13.500044-1.31868 4.6139 0 9.1139.42198 13.5 1.26594v-4.43077c-4.3861-.791212-8.8861-1.21319-13.5-1.21319z"
        fill="#005ba0"
      />
      <g fill="#000">
        <path d="m52.0924 18.0504-.9393-2.7075h-4.2546l-.9394 2.7075h-2.4864l4.4756-12.15605h2.2655l4.4203 12.15605zm-2.4864-7.2384c-.2211-.7183-.4421-1.43659-.6079-1.87863-.1105.44204-.3315 1.16033-.6078 1.87863l-.7735 2.2102h2.6522z" />
        <path d="m65.7403 18.0504v-4.4756c0-.6631.0552-2.2102.0552-2.6523-.1657.4421-.4972 1.3262-.8288 1.9892l-2.3759 5.415-2.376-5.415c-.2763-.7183-.6631-1.5471-.8288-1.9892 0 .4421.0552 1.9892.0552 2.6523v4.4756h-2.2654v-12.15605h2.2654l2.4312 5.52545c.2763.6631.6631 1.6024.7736 2.0445.1658-.4421.4973-1.3261.7736-2.0445l2.3759-5.52545h2.3208v12.15605z" />
        <path d="m79.0567 18.0504-.9393-2.7075h-4.2547l-.884 2.7075h-2.4865l4.4756-12.15605h2.2102l4.4204 12.15605zm-2.4865-7.2384c-.221-.7183-.442-1.43659-.6078-1.87863-.1105.44204-.3315 1.16033-.6078 1.87863l-.7735 2.2102h2.6522z" />
        <path d="m91.3231 18.0504-4.3099-6.6306c-.2763-.442-.663-1.0498-.8288-1.3813 0 .442.0553 1.9891.0553 2.6522v5.3597h-2.3208v-12.15605h2.2102l4.1442 6.40955c.2762.4421.663 1.0499.8288 1.3814 0-.442-.0553-1.9892-.0553-2.6522v-5.13875h2.3207v12.15605z" />
        <path d="m104.364 18.0504-4.255-6.6306c-.2764-.442-.6631-1.0498-.8289-1.3813 0 .442.0553 1.9891.0553 2.6522v5.3597h-2.3208v-12.15605h2.2102l4.1442 6.40955c.276.4421.663 1.0499.829 1.3814 0-.442-.055-1.9892-.055-2.6522v-5.13875h2.32v12.15605z" />
        <path d="m114.752 18.2162c-3.205 0-5.305-2.7075-5.305-6.2438 0-3.5916 2.21-6.29909 5.36-6.29909 1.823 0 3.15.6078 4.199 1.93392l-1.16.82883c-.829-1.04985-1.658-1.54714-3.039-1.54714-2.265 0-3.868 2.04443-3.868 5.02818 0 2.9838 1.713 5.0282 3.868 5.0282 2.21 0 3.371-1.2708 3.371-3.6468v-.1105h-2.376v-1.2709h3.812v1.1604c0 3.481-2.1 5.1387-4.862 5.1387z" />
        <path d="m122.321 18.0504v-12.15605h1.437v12.15605z" />
        <path d="m134.312 12.746 2.873 5.2492h-1.713l-2.818-5.194h-3.094v5.2492h-1.437v-12.15605h5.194c2.321 0 4.089 1.10509 4.089 3.4258 0 1.87865-1.216 3.09425-3.094 3.42585zm-1.05-5.63605h-3.647v4.53095h3.481c1.934 0 2.873-.7736 2.873-2.26549-.055-1.49188-.994-2.26546-2.707-2.26546z" />
        <path d="m147.297 12.746 2.873 5.2492h-1.713l-2.818-5.194h-3.094v5.2492h-1.437v-12.15605h5.194c2.321 0 4.089 1.10509 4.089 3.4258 0 1.87865-1.215 3.09425-3.094 3.42585zm-1.05-5.63605h-3.647v4.53095h3.481c1.934 0 2.874-.7736 2.874-2.26549 0-1.49188-.94-2.26546-2.708-2.26546z" />
        <path d="m158.789 18.0504h-4.696v-12.15605h4.807c2.1 0 3.536.93933 3.536 3.03902 0 1.32613-.773 2.09973-1.878 2.48643 1.215.2763 2.486 1.0499 2.486 3.0391 0 2.3759-1.547 3.5915-4.255 3.5915zm0-10.94045h-3.26v3.75735h3.15c1.658 0 2.265-.88409 2.265-1.93393s-.663-1.82342-2.155-1.82342zm0 5.02825h-3.26v4.6966h3.316c1.989 0 2.707-.8841 2.707-2.2654.055-1.6024-1.05-2.4312-2.763-2.4312z" />
        <path d="m174.371 18.0504-1.105-3.0942h-5.47l-1.161 3.0942h-1.491l4.53-12.15605h1.603l4.531 12.15605zm-3.039-8.34346c-.276-.77357-.663-1.87867-.829-2.37596-.11.49729-.497 1.60239-.829 2.43121l-1.436 3.97831h4.531z" />
        <path d="m182.991 18.2162c-3.205 0-5.304-2.7075-5.304-6.2438 0-3.5916 2.21-6.29909 5.359-6.29909 1.824 0 3.15.6078 4.2 1.93392l-1.161.82883c-.829-1.04985-1.657-1.54714-3.039-1.54714-2.265 0-3.868 2.04443-3.868 5.02818 0 2.9838 1.658 5.0282 3.868 5.0282 1.437 0 2.376-.6078 3.26-1.6024l.995.8288c-.995 1.2157-2.431 2.0445-4.31 2.0445z" />
        <path d="m198.629 18.0504v-5.7465h-6.52v5.7465h-1.437v-12.15605h1.437v5.19395h6.52v-5.19395h1.436v12.15605z" />
      </g>
    </svg>
  );
};

export default AgLogo;
