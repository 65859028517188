import { useRouter } from 'next/router';
import styles from './prompt-set-new-password.module.scss';
import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, Input, StandardButton } from '@dxp/veneer';
import classNames from 'classnames';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { AuthState, CustomerActions, PromptActions } from '../../../redux';
import { ICustomerProfilePassword } from '@dxp/spryker-client';
import {
  ControllerNewPasswordRules,
  InputConfirmPasswordError,
  InputDefaultError,
  InputNewPasswordError,
} from '@dxp/account';

export interface PromptSetNewPasswordProps {
  className?: string;
}

export const PromptSetNewPassword = (props: PromptSetNewPasswordProps) => {
  const { t } = useTranslation('account');
  const dispatch = useDispatch();
  const { control, formState, getValues, handleSubmit, resetField } = useForm({ mode: 'onTouched' });
  const customerRef = useSelector((state: { auth: AuthState }) => state.auth.customerRef);
  const { locale } = useRouter();

  const onSubmit: (values: FieldValues) => void = (values: FieldValues) => {
    if (customerRef) {
      const payload: ICustomerProfilePassword = {
        password: values['password'],
        newPassword: values['newPassword'],
        confirmPassword: values['confirmPassword'],
      };
      dispatch(CustomerActions.updateCustomerPasswordRequested({ customerRef, payload, locale }));
    }
  };

  useEffect(() => {
    if (customerRef && formState.isSubmitSuccessful) {
      dispatch(PromptActions.close());
    }
  }, [customerRef, formState.isSubmitSuccessful]);

  return (
    <div className={classNames(styles['container'], props.className)}>
      <div className={classNames('headline', styles['headline'])}>{t('prompts.setNewPassword.headline')}</div>
      <form className={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          defaultValue={''}
          name={'password'}
          rules={{ required: true }}
          render={({ field: { name, onChange, ref, value, onBlur }, fieldState: { error } }) => (
            <Input
              hidePasswordReveal={true}
              error={InputDefaultError(error)}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              value={value}
              autocomplete="current-password"
              className={classNames('prompt-input', styles['input--old-password'])}
              label={t('prompts.setNewPassword.old.label')}
              required={true}
              placeholder={t('prompts.setNewPassword.old.placeholder')}
              type="password"
              variant={Colors.lightGrey}
            />
          )}
        />

        <hr className={styles['divider']} />
        <Controller
          control={control}
          defaultValue={''}
          name={'newPassword'}
          rules={ControllerNewPasswordRules}
          render={({ field: { name, onChange, ref, value, onBlur }, fieldState: { error } }) => (
            <Input
              hidePasswordReveal={false}
              error={InputNewPasswordError(error)}
              name={name}
              onChange={(event) => {
                resetField('confirmPassword');
                return onChange(event);
              }}
              onBlur={onBlur}
              ref={ref}
              value={value}
              autocomplete="new-password"
              className={classNames('prompt-input', styles['input--new-password'])}
              label={t('prompts.setNewPassword.new.label')}
              required={true}
              placeholder={t('prompts.setNewPassword.new.placeholder')}
              type="password"
              variant={Colors.lightGrey}
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={''}
          name={'confirmPassword'}
          rules={{
            required: true,
            validate: (value) => value === getValues()['newPassword'],
          }}
          render={({ field: { name, onChange, ref, value, onBlur }, fieldState: { error } }) => (
            <Input
              hidePasswordReveal={true}
              error={InputConfirmPasswordError(error)}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              value={value}
              autocomplete="off"
              className={classNames('prompt-input', styles['input--repeat-password'])}
              label={t('prompts.setNewPassword.repeat.label')}
              required={true}
              placeholder={t('prompts.setNewPassword.repeat.placeholder')}
              type="password"
              variant={Colors.lightGrey}
            />
          )}
        />

        <div className={classNames('buttons', styles['buttons'])}>
          <StandardButton
            label={t('prompts.button.submit')}
            variant={Colors.feelgoodMint}
            disabled={!formState.isValid}
          />
          <StandardButton
            type={'reset'}
            onClick={() => dispatch(PromptActions.close())}
            label={t('prompts.button.cancel')}
            variant={Colors.brilliantBlue}
          />
        </div>
      </form>
    </div>
  );
};

export default PromptSetNewPassword;
