import styles from './product-entry-filter.module.scss';
import { Colors, Dropdown, StandardButton, VeneerAnimation } from '@dxp/veneer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { ICompatibleMachine, ICompatibleMaterial, IFilter } from '../../interfaces';
import { CatalogActions, CatalogSelectors, CatalogState } from '../../redux';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';

export interface ProductEntryFilterProps {
  headline: string;
  materials: Array<ICompatibleMaterial>;
  machines: Array<ICompatibleMachine>;
}

export const ProductEntryFilter = (props: ProductEntryFilterProps) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { control, reset } = useForm();

  const activeFilter: IFilter | null = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectActiveFilters(state.catalog)
  );

  const updateFilter = (filterCode: 'selectedMachine' | 'selectedMaterial', value: string) => {
    dispatch(CatalogActions.updateFilter({ filterCode, value }));
  };

  const onReset = () => {
    dispatch(CatalogActions.resetFilter());

    return reset();
  };

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['container']}
    >
      <div className={styles['inner']}>
        <h1 className={styles['headline']} data-testid="headline">
          {props.headline}
        </h1>

        <form
          className={styles['filter']}
          onReset={onReset}
          onSubmit={(event) => event.preventDefault()}
          suppressHydrationWarning={true}
        >
          <Controller
            control={control}
            defaultValue={''}
            name={'mats'}
            render={({ field: { name, onChange, ref, value } }) => (
              <Dropdown
                name={name}
                ref={ref}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  updateFilter(
                    'selectedMaterial',
                    props.materials.find((mat) => mat.label === e.target.selectedOptions[0].value)?.code ||
                      e.target.selectedOptions[0].value
                  );

                  return onChange(e);
                }}
                value={value}
                borders={'round'}
                variant={Colors.white}
                className={styles['dropdown']}
                values={props.materials?.map((material) => ({ key: material.code, label: material.label }))}
                placeholder={t('product.dropdown.material')}
              />
            )}
          />

          <Controller
            control={control}
            defaultValue={''}
            name={'machine'}
            render={({ field: { name, onChange, ref, value } }) => (
              <Dropdown
                name={name}
                ref={ref}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  updateFilter(
                    'selectedMachine',
                    props.machines.find((mat) => mat.name === e.target.selectedOptions[0].value)?.identifier ||
                      e.target.selectedOptions[0].value
                  );

                  return onChange(e);
                }}
                value={value}
                borders={'round'}
                variant={Colors.white}
                className={styles['dropdown']}
                values={props.machines?.map((machine) => ({ key: machine.identifier, label: machine.name }))}
                placeholder={t('product.dropdown.machine')}
              />
            )}
          />

          <StandardButton
            label={t('reset')}
            className={styles['button']}
            type="reset"
            variant={Colors.feelgoodMint}
            disabled={activeFilter.selectedMachine === '' && activeFilter.selectedMaterial === ''}
          />
        </form>
      </div>
    </motion.div>
  );
};

export default ProductEntryFilter;
