const fileSizeToString = (bytes?: number): string => {
  if (!bytes || bytes === 0) return 'n/a';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), sizes.length - 1);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};
export const FrontifyUtils = {
  fileSizeToString,
};

export * from './video';
