import { useTranslation } from 'next-i18next';
import { Colors, StandardButton } from '@dxp/veneer';
import { useRouter } from 'next/router';
import { AddressActions, PromptActions } from '../../../redux';
import React from 'react';
import { useDispatch } from 'react-redux';

export interface PromptDeleteAddressProps {
  className?: string;
  id: string;
}

export const PromptDeleteAddress = (props: PromptDeleteAddressProps) => {
  const { t } = useTranslation('account');
  const dispatch = useDispatch();
  const { locale } = useRouter();

  return (
    <div className={props.className}>
      <div className={'headline'}>{t('prompts.deleteAddress.headline')}</div>
      <div className={'copy'}>{t('prompts.deleteAddress.copy')}</div>
      <div className={'buttons'}>
        <StandardButton
          label={t('prompts.button.confirm')}
          variant={Colors.feelgoodMint}
          onClick={() => {
            dispatch(PromptActions.close());
            dispatch(AddressActions.deleteAddress({ addressId: props.id, locale }));
          }}
        />
        <StandardButton
          label={t('prompts.button.cancel')}
          variant={Colors.brilliantBlue}
          onClick={() => {
            dispatch(PromptActions.close());
          }}
        />
      </div>
    </div>
  );
};

export default PromptDeleteAddress;
