import raw from './raw';
import { AxiosInstance, AxiosResponse } from 'axios';
import { EIncludeTypes, IProductRawResponse, TApiResponseData } from '../types';
import { IConcreteProductDataParsed } from '../interfaces';
import { parseConcreteProductResponse } from '../helper';

/**
 * Retrieving concrete products
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202204.0/managing-products/concrete-products/retrieving-concrete-products.html
 * @param {AxiosInstance} http the axios instance
 * @param {string} sku the sku of the desired product
 * @return {IConcreteProductDataParsed} the product
 */
export const bySku = (
  http: AxiosInstance,
  {
    sku,
  }: {
    sku: string;
  }
): Promise<IConcreteProductDataParsed | null> => {
  const include =
    `${EIncludeTypes.CONCRETE_PRODUCT_IMAGE_SETS},` +
    `${EIncludeTypes.CONCRETE_PRODUCT_PRICES},` +
    `${EIncludeTypes.CONCRETE_PRODUCT_AVAILABILITIES},` +
    `${EIncludeTypes.PRODUCT_LABELS}`;
  return raw
    .get(http, `concrete-products/${sku}`, { params: { include } })
    .then((response: AxiosResponse<TApiResponseData>) =>
      parseConcreteProductResponse(response.data as unknown as IProductRawResponse)
    );
};
