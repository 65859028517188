import { AxiosInstance, AxiosResponse } from 'axios';
import { parseCartResponse } from '../helper';
import { ICart, IGuestAddItemToCart, IGuestChangeItemQuantity, IGuestDeleteItem } from '../interfaces';
import { EIncludeTypes } from '../types';
import raw from './raw';

const include: string = [
  EIncludeTypes.GUEST_CART_ITEMS,
  EIncludeTypes.CONCRETE_PRODUCTS,
  EIncludeTypes.CONCRETE_PRODUCT_PRICES,
  EIncludeTypes.CONCRETE_PRODUCT_IMAGE_SETS,
  EIncludeTypes.VOUCHERS,
  EIncludeTypes.CART_RULES,
].join(',');

/**
 * Get guest carts
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202108.0/managing-carts/guest-carts/managing-guest-carts.html
 * @param {AxiosInstance} http the axios instance
 * @param {string} anonymId the generated id from localStorage for an anonymous user
 * @return {Promise<Array<ICart> | ICart | null>} users cart(s) or null if there are none
 */
export const get = (http: AxiosInstance, { anonymId }: { anonymId: string }): Promise<Array<ICart> | ICart | null> => {
  return raw
    .get(http, `guest-carts`, {
      headers: { 'X-Anonymous-Customer-Unique-Id': anonymId },
      params: { include },
    })
    .then((response: AxiosResponse<any>) => {
      if (response?.data?.data) {
        return parseCartResponse(response.data.data, response.data.included, true);
      } else {
        return null;
      }
    });
};

/**
 * Add an item to a guest users cart
 * @param {AxiosInstance} http the axios instance
 * @param {IGuestAddItemToCart} payload the sku and quantity of the item to add and the cartId as well as the anonymId
 * @return {Promise<Array<ICart> | ICart | null>} users cart(s) or null if there are none
 */
export const addItem = (http: AxiosInstance, payload: IGuestAddItemToCart): Promise<Array<ICart> | ICart | null> => {
  return http
    .post(
      `guest-cart-items`,
      {
        data: { type: 'guest-cart-items', attributes: { sku: payload.sku, quantity: payload.quantity } },
      },
      {
        headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId },
        params: { include },
      }
    )
    .then((response: AxiosResponse) => {
      if (response) {
        return parseCartResponse(response.data.data, response.data.included, true);
      } else {
        return null;
      }
    });
};

/**
 * Add multiple items to guest users cart
 * @param {AxiosInstance} http the axios instance
 * @param {items: IAddItemToCart[]; cartId: string} payload array of skus and quantities of items to add and the cartId as well as the anonymId
 * @return {Promise<Array<ICart> | ICart | null>} users updated cart(s) or null if there are none
 */
export const addItems = (
  http: AxiosInstance,
  payload: Array<IGuestAddItemToCart>
): Promise<Array<ICart> | ICart | null> => {
  const responses: AxiosResponse[] = [];
  let index = 0;

  console.log(payload);

  const addNextItem = (): Promise<ICart | null> => {
    if (index >= payload.length) {
      // all items have been added, parse the responses and return the cart
      let cart = null;
      responses.forEach((response) => (cart = parseCartResponse(response.data.data, response.data.included, true)));
      return Promise.resolve(cart);
    } else {
      // add item and wait for the request to complete before adding the next one
      const item = payload[index++];
      return http
        .post(
          `guest-cart-items`,
          {
            data: { type: 'guest-cart-items', attributes: { sku: item.sku, quantity: item.quantity } },
          },
          {
            headers: { 'X-Anonymous-Customer-Unique-Id': item.anonymId },
            params: { include },
          }
        )
        .then((response) => {
          responses.push(response);
          return addNextItem();
        })
        .catch(() => addNextItem());
    }
  };

  // Start adding the items
  return addNextItem();
};

/**
 * Change an item quantity in a guest users cart
 * @param {AxiosInstance} http the axios instance
 * @param {IGuestChangeItemQuantity} payload the sku and quantity of the item to add and the cartId as well as the anonymId
 * @return {Promise<Array<ICart> | ICart | null>} users cart(s) or null if there are none
 */
export const changeItemQuantity = (
  http: AxiosInstance,
  payload: IGuestChangeItemQuantity
): Promise<Array<ICart> | ICart | null> => {
  return raw
    .patch(
      http,
      `guest-carts/${payload.cartId}/guest-cart-items/${payload.itemId}`,
      {
        data: { type: 'guest-cart-items', attributes: { quantity: payload.itemQuantity } },
      },
      {
        headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId },
        params: { include },
      }
    )
    .then((response: AxiosResponse<any>) => {
      if (response) {
        return parseCartResponse(response.data.data, response.data.included, true);
      } else {
        return null;
      }
    });
};

/**
 * delete an item in a guest users cart
 * @param {AxiosInstance} http the axios instance
 * @param {IChangeItemQuantity} payload the sku and the cartId as well as the anonymId
 * @return {Promise<number | null>} status (2xx - 5xx) or null if there is no response
 */
export const deleteItem = (http: AxiosInstance, payload: IGuestDeleteItem): Promise<number | null> => {
  return raw
    .delete(http, `guest-carts/${payload.cartId}/guest-cart-items/${payload.itemId}`, {
      headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId },
    })
    .then((response: AxiosResponse<any>) => {
      if (response) {
        return response.status;
      } else {
        return null;
      }
    });
};

/**
 * add voucher in guest cart
 * @param {AxiosInstance} http the axios instance
 * @param payload Object with cart id, voucher code and anonym id
 * @return Promise<ICart | Array<ICart> | null>
 */
export const addVoucher = (
  http: AxiosInstance,
  payload: { cartId: string; voucherCode: string; anonymId: string }
): Promise<ICart | Array<ICart> | null> => {
  return http
    .post(
      `guest-carts/${payload.cartId}/vouchers`,
      {
        data: { type: 'vouchers', attributes: { code: payload.voucherCode } },
      },
      { headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId }, params: { include } }
    )
    .then((response: AxiosResponse<any>) => {
      if (response) {
        return parseCartResponse(response.data.data, response.data.included, true);
      } else {
        return null;
      }
    });
};

/**
 * Delete voucher from guest cart
 * @param {AxiosInstance} http the axios instance
 * @param payload Object with cart id, voucher code and anonymId
 * @param payload.cartId current cart id
 * @param payload.voucherCode voucherCode to delete
 * @param payload.anonymId guest user anonymous id
 * @return Promise<number | null>
 */
export const deleteVoucher = (
  http: AxiosInstance,
  payload: { cartId: string; voucherCode: string; anonymId: string }
): Promise<number | null> => {
  return http
    .delete(`guest-carts/${payload.cartId}/vouchers/${payload.voucherCode}`, {
      headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId },
    })
    .then((response: AxiosResponse<null>) => (response?.status ? response.status : null));
};

// /**
//  * add custom order reference in guest users cart
//  * @param {AxiosInstance} http the axios instance
//  * @param payload
//  * @return Promise<ICart | Array<ICart> | null>
//  */
// export const addCustomOrderReference = (
//   http: AxiosInstance,
//   payload: {
//     cartId: string;
//     customOrderReference: string;
//     anonymId: string;
//   }
// ): Promise<ICart | Array<ICart> | null> => {
//   return http
//     .post(
//       `carts/${payload.cartId}/order-custom-reference`,
//       {
//         data: { type: 'order-custom-reference', attributes: { orderCustomReference: payload.customOrderReference } },
//       },
//       { headers: { 'X-Anonymous-Customer-Unique-Id': payload.anonymId }, params: { include } }
//     )
//     .then((response: AxiosResponse<any>) => {
//       if (response) {
//         return parseCartResponse(response.data.data, response.data.included);
//       } else {
//         return null;
//       }
//     });
// };
