import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { AssetFilterMetaData } from '../interfaces';
import { FrontifyActions, FrontifySelectors } from '../redux';

export interface FiltersQuery extends Record<string, string | number> {
  pageSize: number;
  page: number;
}

export interface Filters {
  pageSize: number;
  page: number;
  mediaFilter: AssetFilterMetaData[];
}

const mapFiltersToQuery = (filters: AssetFilterMetaData[]) =>
  filters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.queryParam]: String(filter.value),
    }),
    {} as Record<string, string>
  );

export const useFrontifyFilters = () => {
  const librarySelected = useSelector(FrontifySelectors.getFrontifyLibrarySelected);
  const filtersState = useSelector(FrontifySelectors.getFrontifyLibraryFiltersSelected);
  const dispatch = useDispatch();
  const router = useRouter();

  const applyFilters = async ({
    page = filtersState.page,
    pageSize = filtersState.pageSize,
    mediaFilter = filtersState.mediaFilter,
  }: Filters) => {
    dispatch(
      FrontifyActions.assetByFilterRequested({
        page,
        pageSize,
        mediaFilter: mediaFilter,
      })
    );

    //ToDo: find a way to not checking slugs in this hook on route update.
    router.query = {
      ...(router.query.librarySlug && { librarySlug: router.query.librarySlug }),
      ...(router.query.slug && { slug: router.query.slug }),
      page: String(page),
      pageSize: String(pageSize),
      ...mapFiltersToQuery(mediaFilter),
    };

    router.push(router, undefined, {
      shallow: true,
    });
  };

  return { filtersState, filters: librarySelected?.metadataFields ?? [], applyFilters };
};
