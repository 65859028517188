import { AuthContext, CheckoutDataContext, IAuthContext, ICheckoutDataContext } from '@dxp/core';
import {
  CheckoutDataCustomer,
  IAddressItem,
  ICart,
  ICartDiscount,
  ICartRule,
  IVoucher,
  PaymentMethodRequest,
} from '@dxp/spryker-client';
import { Colors, IconBin, IconCheck, RoundButton } from '@dxp/veneer';
import { useRouter } from 'next-translate-routes';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CartActions, CartSelectors, CartState, CheckoutDataActions } from '../../redux';
import { CartContext } from '../cart/cart';
import styles from './discount-item.module.scss';

export interface DiscountItemProps {
  discount: ICartDiscount;
}

export function DiscountItem({ discount }: DiscountItemProps) {
  const dispatch = useDispatch();
  const { locale } = useRouter();

  const authContext: IAuthContext | null = useContext(AuthContext);
  const cart: ICart | undefined = useContext(CartContext);
  const voucherOrCartRule: IVoucher | ICartRule | null = useSelector((state: { cart: CartState }) =>
    CartSelectors.getVoucherOrCartRuleByDiscount(state.cart, { cartId: cart?.id || '', discount })
  );

  const checkoutDataContext = useContext(CheckoutDataContext);

  useEffect(() => {
    if (cart && locale && checkoutDataContext) {
      triggerCheckoutData(cart, locale, checkoutDataContext);
    }
  }, [cart?.vouchers.length]);

  const triggerCheckoutData = (cart: ICart, locale: string, checkoutDataContext: ICheckoutDataContext) => {
    const {
      customer,
      guestCheckout,
      addresses,
      selectedBillingAddressId,
      selectedShippingAddressId,
      guestCheckoutBillingAddress,
      guestCheckoutShippingAddress,
      guestCheckoutPersonalData,
      checkoutData,
    } = checkoutDataContext;

    if (cart && locale) {
      let checkoutCustomer: CheckoutDataCustomer | null = null;
      let billingAddress: IAddressItem | undefined;
      let shippingAddress: IAddressItem | undefined;
      if (guestCheckout && !customer && !!guestCheckoutBillingAddress && !!guestCheckoutShippingAddress) {
        checkoutCustomer = {
          salutation: guestCheckoutPersonalData?.salutation,
          email: guestCheckoutPersonalData?.email ?? '',
          firstName: guestCheckoutPersonalData?.firstName ?? '',
          lastName: guestCheckoutPersonalData?.lastName ?? '',
        };
        billingAddress = guestCheckoutBillingAddress;
        shippingAddress = guestCheckoutShippingAddress;
      } else if (!guestCheckout && !!customer && selectedBillingAddressId !== '' && selectedShippingAddressId !== '') {
        checkoutCustomer = {
          email: customer?.email ?? '',
          firstName: customer?.firstName ?? '',
          lastName: customer?.lastName ?? '',
          salutation: customer?.salutation,
        };
        billingAddress = { ...addresses?.find((address) => address.id === selectedBillingAddressId) };
        shippingAddress = { ...addresses?.find((address) => address.id === selectedShippingAddressId) };
      }

      const payments: Array<PaymentMethodRequest> | undefined = checkoutData?.selectedPaymentMethods?.map((method) => {
        return {
          paymentMethodName: method.paymentMethodName,
          paymentProviderName: method.paymentProviderName,
        };
      });

      if (checkoutCustomer) {
        dispatch(
          CheckoutDataActions.checkoutDataRequested({
            locale,
            checkoutDataRequestAttributes: {
              idCart: cart.id || '',
              customer: checkoutCustomer,
              billingAddress,
              shippingAddress,
              shipment:
                checkoutData?.selectedShipmentMethods?.length && checkoutData?.selectedShipmentMethods?.length > 0
                  ? {
                      idShipmentMethod: checkoutData?.selectedShipmentMethods[0].id,
                    }
                  : undefined,
              payments,
            },
            anonymId: authContext?.anonymId && guestCheckout ? authContext.anonymId : null,
          })
        );
      }
    }
  };

  const removeDiscount = () => {
    const cartId: string | undefined = cart?.id;
    let voucherCode: string | null = discount.code;
    // Currently the discount code is not set by spryker, so we need to find suitable voucher by displayName.
    if (!voucherCode && cart?.vouchers?.some((voucher) => voucher.attributes.displayName === discount.displayName)) {
      voucherCode =
        cart.vouchers.find((voucher) => voucher.attributes.displayName === discount.displayName)?.attributes.code ||
        null;
    }
    if (cart && cartId && voucherCode && locale) {
      if (authContext?.isAuthenticated) {
        dispatch(CartActions.deleteVoucher({ locale, payload: { cartId, voucherCode } }));
      } else {
        dispatch(
          CartActions.guestDeleteVoucher({
            locale,
            payload: { cartId, voucherCode, anonymId: authContext?.anonymId || '' },
          })
        );
      }
    }
  };

  return cart && discount ? (
    <div className={styles['container']}>
      <div className={styles['container__first']}>
        <div className={styles['check']}>
          <IconCheck color={Colors.intenseBlue} className={styles['check__icon']} />
        </div>
        <div className={styles['title']}>{discount.displayName}</div>

        {/*
        <div className={styles['discount']}>
          &nbsp;&ndash; {((discount.amount / cart.totals.grandTotal) * 100).toFixed(0)} %
        </div>
        */}
      </div>
      {voucherOrCartRule?.type === 'vouchers' && (
        <RoundButton
          icon={<IconBin color={Colors.intenseBlue} />}
          variant={Colors.white}
          interactive={true}
          onClick={() => removeDiscount()}
        />
      )}
    </div>
  ) : null;
}

export default DiscountItem;
