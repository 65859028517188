import { Colors } from '../../../types';
import classNames from 'classnames';
import styles from './search-bar.module.scss';
import { IconSearch } from '../../decoration/icons';
import { useMemo } from 'react';
import { motion } from 'framer-motion';
import { VeneerAnimation } from '../../../animations';

export interface SearchBarProps {
  onInput?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  variant: Colors.brilliantBlue | Colors.lightGrey | Colors.white | 'bordered';
  big?: boolean;
  value?: string;
}

export const SearchBar = (props: SearchBarProps & { className?: string }) => {
  const classes = useMemo(
    () =>
      classNames(
        styles['inner'],
        {
          [styles['inner--' + props.variant]]: props.variant,
        },
        props.big ? styles['inner--big'] : '',
        props.className
      ),
    [props.variant, props.big, props.className]
  );

  const iconColor = useMemo(() => {
    switch (props.variant) {
      case Colors.brilliantBlue:
        return Colors.brilliantBlue;
      case Colors.white:
        return Colors.intenseBlue;
      default:
        return Colors.black;
    }
  }, [props.variant]);

  return (
    <motion.div
      whileHover={VeneerAnimation.uiScale}
      transition={VeneerAnimation.uiScaleTransition}
      className={classes}
      data-testid={'searchbar'}
    >
      <IconSearch className={styles['icon']} color={iconColor} />
      <input
        className={styles['input']}
        onInput={props.onInput}
        onKeyDown={props.onKeyDown}
        onChange={props.onChange}
        placeholder={props.placeholder}
        type="text"
        value={props.value}
      />
    </motion.div>
  );
};

export default SearchBar;
