import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { TypeNavigationFields } from '../../types';
import { HYDRATE } from 'next-redux-wrapper';

export interface NavigationState {
  quicknavigation: TypeNavigationFields | null;
  mainnavigation: TypeNavigationFields | null;
  legal: TypeNavigationFields | null;
  footerservice: TypeNavigationFields | null;
  footerhighlights: TypeNavigationFields | null;
  sidebar: TypeNavigationFields | null;
  topnavigation: TypeNavigationFields | null;
}

export const initialNavigationState: NavigationState = {
  quicknavigation: null,
  mainnavigation: null,
  legal: null,
  footerservice: null,
  footerhighlights: null,
  sidebar: null,
  topnavigation: null,
};

export const navigationsSlice = createSlice({
  name: 'navigations',
  initialState: initialNavigationState,
  reducers: {
    set: (state: NavigationState, action: PayloadAction<TypeNavigationFields>) => {
      state[action.payload.identifier] = action.payload;
    },
    setAll: (state: NavigationState, action: PayloadAction<Array<TypeNavigationFields>>) => {
      const newState: NavigationState = Object.assign({}, initialNavigationState);

      action.payload.forEach((el) => {
        newState[el.identifier] = el;
      });

      return newState;
    },
    clear: (state: NavigationState, action: PayloadAction<keyof NavigationState>) => {
      state[action.payload] = initialNavigationState[action.payload];
    },
    clearAll: () => ({ ...initialNavigationState }),
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action['payload'].navigations,
      };
    });
  },
});

const { set, setAll, clear, clearAll } = navigationsSlice.actions;

export const NavigationsActions = {
  set,
  setAll,
  clear,
  clearAll,
  fetchAll: createAction<string>('navigations/fetch all'),
};

export default navigationsSlice.reducer;

export interface NavigationsStateInterface {
  navigations: NavigationState;
}
