import styles from './note.module.scss';
import { IconInfo } from '../icons';
import { Colors } from '../../../types';

export interface NoteProps {
  label: string;
}

export const Note = (props: NoteProps) => {
  return (
    <div className={styles['container']}>
      <IconInfo className={styles['icon']} color={Colors.intenseBlue} />
      <span data-testid={'label'}>{props.label}</span>
    </div>
  );
};

export default Note;
