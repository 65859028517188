import { MetadataField } from '@dxp/frontify-client';
import { useTranslation } from 'next-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Colors } from '../../../types';
import { Dropdown } from '../../form';
import { StandardButton } from '../../interaction';
import styles from './filters.module.scss';

export interface SelectedFilter {
  queryParam: string;
  value: string;
}
interface Props {
  selectedFilters: SelectedFilter[];
  filters: MetadataField[];
  onChange: (filters: SelectedFilter[]) => void;
}

export const Filters = ({ onChange, filters, selectedFilters }: Props) => {
  const { t } = useTranslation('common');

  const setFilter = (filter?: { queryParam: string; value: string }) => {
    if (!filter) {
      onChange([]);

      return;
    }

    const filteredFilters = selectedFilters.filter((item) => filter.queryParam !== item.queryParam);
    const newMediaFilters = filter.value ? [...filteredFilters, filter] : filteredFilters;

    onChange(newMediaFilters);
  };

  return (
    <div className={styles['filters']}>
      {filters.map((filter) => {
        if (filter.type === 'SELECT') {
          const values = filter.values.map((value) => ({
            label: value.value,
            key: value.value,
          }));

          const currentValue = selectedFilters.find((item) => item.queryParam == filter.queryParam)?.value;

          return (
            <Dropdown
              key={uuidv4()}
              variant={Colors.lightGrey}
              values={values}
              placeholder={filter.label}
              borders={'round'}
              value={currentValue}
              onChange={(e) => setFilter({ queryParam: filter.queryParam, value: e.currentTarget.value })}
            />
          );
        }
      })}
      <StandardButton
        disabled={!selectedFilters.length}
        label={t('reset', { ns: 'common' })}
        variant={Colors.feelgoodMint}
        onClick={() => setFilter()}
      />
    </div>
  );
};
