import { IStore } from '@dxp/spryker-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateError = string | { [key: string]: string } | null;

export interface CommonCommerceErrors {
  storesRequested: StateError;
}

/**
 * The interface for common data provided in redux store.
 */
export interface CommonCommerceState {
  error: CommonCommerceErrors;
  loading: boolean;
  stores: Array<IStore>;
  currentStore: string | null;
}

/**
 * The initial common redux state.
 */
const initialState: CommonCommerceState = {
  error: {
    storesRequested: null,
  },
  loading: false,
  stores: [],
  currentStore: null,
};

/**
 * The common redux slice.
 */
export const commonCommerceSlice = createSlice({
  name: 'commonCommerce',
  initialState,
  reducers: {
    // general reducers
    clearError: (state, action: PayloadAction<keyof CommonCommerceErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },

    // stores reducers
    storesRequested: (state, action: PayloadAction<{ locale: string }>) => {
      state.error.storesRequested = null;
      state.loading = true;
    },
    storesReceived: (state, action: PayloadAction<Array<IStore>>) => {
      state.error.storesRequested = null;
      state.loading = false;
      state.stores = action.payload;
    },
    storesRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.storesRequested = action.payload;
      state.loading = false;
    },

    // current store reducers
    setCurrentStore: (state, action: PayloadAction<{ id: string }>) => {
      state.currentStore = action.payload.id;
    },
    resetCurrentStore: (state) => {
      state.currentStore = initialState.currentStore;
    },
  },
});

export const CommonCommerceActions = commonCommerceSlice.actions;

export default commonCommerceSlice.reducer;
