import { AttributeOption, Category, getDeepestChildCategories } from '@dxp/akeneo-client';
import { toUppercaseLocale } from '@dxp/core';
import { createSelector } from '@reduxjs/toolkit';
import {
  IAdditionalDocument,
  IAdditionalMedia,
  IAdditionalMediaLink,
  IAkeneoSliderItem,
  IAttributeFilter,
  ICaseReport,
  ICatalogBrochure,
  ICeProductSelectorProps,
  ICompatibleMaterial,
  IContactPerson,
  ICrossSellingProduct,
  IFact,
  IFeature,
  IHighlightImageElement,
  IHighlightVideo,
  IHighlightVideoElement,
  IIndication,
  IInstruction,
  IMaterialColumn,
  IMaterialDetail,
  IPdp,
  IProductImage,
  IProductOrReferenceData,
  IProductVideo,
  ITechnicalData,
  ITestimonial,
  IUpsellingProduct,
} from '../interfaces';
import { CatalogState } from './slice';

const selectCatalogState = (state: CatalogState) => state;

const selectTechnicalData = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.technicalData.filter((technicalData: ITechnicalData) => codes.some((code) => technicalData.code === code))
);

const selectHighlightVideo = createSelector(
  [selectCatalogState, (state: CatalogState, code: string) => code],
  (state: CatalogState, code: string) =>
    state.highlightVideos.find((highlightVideo: IHighlightVideo) => highlightVideo.code === code) || null
);

const selectContactPerson = createSelector(
  [selectCatalogState, (state: CatalogState, code: string) => code],
  (state: CatalogState, code: string) =>
    state.contactPersons.find((contactPerson: IContactPerson) => contactPerson.code === code) || null
);

const selectHighlightVideoElements = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.highlightVideoElements.filter((highlightVideoElement: IHighlightVideoElement) =>
      codes.some((code) => highlightVideoElement.code === code)
    )
);

const selectHighlightImageElements = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.highlightImageElements.filter((highlightImageElement: IHighlightImageElement) =>
      codes.some((code) => highlightImageElement.code === code)
    )
);

const selectDownloads = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    [...state.catalogBrochures, ...state.instructions, ...state.caseReports, ...state.additionalDocuments].filter(
      (download: ICatalogBrochure | IInstruction | ICaseReport | IAdditionalDocument) =>
        codes.some((code) => download.id === code)
    )
);

const selectProductVideos = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.productVideos.filter((productVideo: IProductVideo) => codes.some((code) => productVideo.code === code))
);

const selectProductImages = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.productImages.filter((productImage: IProductImage) => codes.some((code) => productImage.code === code))
);

const selectFeatures = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.features
      .filter((feature: IFeature) => codes.some((code) => feature.code === code))
      .sort((a, b) => parseFloat(a.priority) - parseFloat(b.priority))
);

const selectFacts = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.facts.filter((fact: IFact) => codes.some((code) => fact.code === code)).slice(0, 3)
);

const selectAdditionalMedia = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.additionalMedia
      .filter((additionalMedia: IAdditionalMedia) => codes.some((code) => additionalMedia.code === code))
      .sort((a: IAdditionalMedia, b: IAdditionalMedia) => a.sequence - b.sequence)
);

const selectCategories = createSelector([selectCatalogState], (state: CatalogState) => state.categories);

const selectMaterials = createSelector(
  [selectCatalogState, (state: CatalogState, materialCodes: Array<string>) => materialCodes],
  (state: CatalogState, materialCodes: Array<string>) =>
    state.compatibleMaterials.filter((material: ICompatibleMaterial) =>
      materialCodes.some((code: string) => material.code === code)
    )
);

const selectMaterialColumns = createSelector(
  [selectCatalogState, (state: CatalogState, payload: { materialCodes: Array<string>; locale: string }) => payload],
  (state: CatalogState, payload: { materialCodes: Array<string>; locale: string }) =>
    state.compatibleMaterials
      // filter materials by given materialCodes
      .filter((material: ICompatibleMaterial) => payload.materialCodes.some((code: string) => material.code === code))
      // select categoryCodes array from material object, map them to categories and filter the deepest child categories
      .map((material: ICompatibleMaterial) =>
        getDeepestChildCategories(
          // map codes to categories
          material.categoryCodes
            .map((categoryCode: string) =>
              state.categories.find((category: Category) => category.code === categoryCode)
            )
            // filter undefined
            .filter((item): item is Category => !!item)
        )
      )
      // flatten two-level array of categories and filter already existing categories
      .reduce(
        (acc: Array<Category>, val: Array<Category>) =>
          acc.concat(val.filter((valItem: Category) => !acc.some((accItem: Category) => accItem === valItem))),
        []
      )
      // map categories to interface IMaterialColumn
      .map(
        (category: Category) =>
          ({
            categoryCode: category.code,
            category: Object.keys(category.labels).some(
              (labelLocaleKey) => labelLocaleKey === toUppercaseLocale(payload.locale).replace('-', '_')
            )
              ? category.labels[toUppercaseLocale(payload.locale).replace('-', '_')]
              : Object.values(category.labels).some((label) => !!label && label !== '')
              ? Object.values(category.labels).find((label) => !!label && label !== '')
              : '', // no translated category name!
            materials: payload.materialCodes
              .map((materialCode: string) =>
                state.compatibleMaterials.find((material: ICompatibleMaterial) => material.code === materialCode)
              )
              .filter(
                (compatibleMaterial) =>
                  compatibleMaterial && (compatibleMaterial.parent === null || compatibleMaterial.parent === '')
              )
              .filter((item): item is ICompatibleMaterial => !!item)
              .filter((material: ICompatibleMaterial) =>
                material.categoryCodes.some((categoryCode: string) => category.code === categoryCode)
              )
              .map((material: ICompatibleMaterial) => ({
                materialCode: material.code,
                slug: material.slug,
                label: material.label,
              })),
          } as IMaterialColumn)
      )
);

const selectTestimonials = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.testimonials.filter((testimonial: ITestimonial) => codes.some((code) => testimonial.code === code))
);

const selectRotos = createSelector(
  [selectCatalogState, (state: CatalogState, locale: string) => locale],
  (state: CatalogState, locale: string) =>
    state.pdps
      .filter((pdp) =>
        state.filter.selectedMaterial !== ''
          ? pdp.compatibleMaterialCodes?.some((code) => code === state.filter.selectedMaterial)
          : pdp
      )
      .filter((pdp) =>
        state.filter.selectedMachine !== ''
          ? pdp.compatibleMachineCodes?.some((code) => code === state.filter.selectedMachine)
          : pdp
      )
      .map((model) => ({
        pdp: model,
        image:
          state.productImages.find((image) => image.code === model.productImageCodes.find((i) => !!i))?.imageUrl || '',
        children: state.rotos
          .filter((roto) => roto.parent === model.code)
          .sort((a, b) => a.headSize - b.headSize)
          .map((roto) => ({
            ...roto,
            title: new Intl.NumberFormat(locale, { minimumFractionDigits: 1 }).format(roto.headSize),
          })),
      }))
);

const selectHolders = createSelector([selectCatalogState], (state: CatalogState) =>
  state.pdps
    .filter((pdp) =>
      state.filter.selectedMaterial !== ''
        ? pdp.compatibleMaterialCodes?.some((code) => code === state.filter.selectedMaterial)
        : pdp
    )
    .filter((pdp) =>
      state.filter.selectedMachine !== ''
        ? pdp.compatibleMachineCodes?.some((code) => code === state.filter.selectedMachine)
        : pdp
    )
    .map((model) => ({
      pdp: model,
      image:
        state.productImages.find((image) => image.code === model.productImageCodes.find((i) => !!i))?.imageUrl || '',
      children: [
        {
          sku: model.sku as string,
          callToAction: model.callToAction,
        },
      ],
    }))
);

const selectAllCompatibleMaterials = createSelector([selectCatalogState], (state: CatalogState) =>
  state.filter.selectedMachine !== ''
    ? state.compatibleMaterials.filter((mat) =>
        mat.compatibleMachineCodes.some((code) => code === state.filter.selectedMachine)
      )
    : state.compatibleMaterials
);
const selectAllCompatibleMachines = createSelector([selectCatalogState], (state: CatalogState) =>
  state.filter.selectedMaterial !== ''
    ? state.compatibleMachines.filter((machine) =>
        machine.compatibleMaterialCodes.some((code) => code === state.filter.selectedMaterial)
      )
    : state.compatibleMachines
);
const selectActiveFilters = createSelector([selectCatalogState], (state: CatalogState) => state.filter);

const selectCrossSellingProducts = createSelector(
  [selectCatalogState, (_: CatalogState, identifiers: string[]) => identifiers],
  (catalogState: CatalogState, identifiers: string[]) =>
    catalogState.crossSelling.filter((crossSellingProduct: ICrossSellingProduct) =>
      identifiers.includes(crossSellingProduct.identifier)
    )
);

const selectCrossSellingProductImages = createSelector(
  [selectCatalogState, (_: CatalogState, identifiers: string[]) => identifiers],
  (catalogState: CatalogState, identifiers: string[]) => {
    const crossSellingProductImageCodes = catalogState.crossSelling
      .filter((crossSellingProduct: ICrossSellingProduct) => identifiers.includes(crossSellingProduct.identifier))
      .flatMap((crossSellingProduct: ICrossSellingProduct) => crossSellingProduct.productImageCodes);

    return catalogState.productImages.filter((productImage: IProductImage) =>
      crossSellingProductImageCodes.includes(productImage.code)
    );
  }
);

const selectUpsellingProducts = createSelector(
  [selectCatalogState, (_: CatalogState, skus: string[]) => skus],
  (catalogState: CatalogState, skus: string[]) =>
    catalogState.upselling.filter((upsellingProduct: IUpsellingProduct) => skus.includes(upsellingProduct.sku))
);

const selectUpsellingProductImages = createSelector(
  [selectCatalogState, (_: CatalogState, identifiers: string[]) => identifiers],
  (catalogState: CatalogState, identifiers: string[]) => {
    const upsellingProductImageCodes = catalogState.upselling
      .filter((upsellingProduct: IUpsellingProduct) => identifiers.includes(upsellingProduct.sku))
      .flatMap((upsellingProduct: IUpsellingProduct) => upsellingProduct.productImageCodes);

    return catalogState.productImages.filter((productImage: IProductImage) =>
      upsellingProductImageCodes.includes(productImage.code)
    );
  }
);

const selectIndications = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.indications.filter((indication: IIndication) => codes.some((code: string) => indication.code === code))
);

const selectAdditionalMediaLinks = createSelector(
  [selectCatalogState, (state: CatalogState, codes: Array<string>) => codes],
  (state: CatalogState, codes: Array<string>) =>
    state.additionalMediaLinks.filter(
      (link: IAdditionalMediaLink) => codes.some((code) => link.code === code) && link.label !== ''
    )
);

const selectPdpBlankShapeAttributes = createSelector(
  [selectCatalogState],
  (state: CatalogState) =>
    state.pdps
      .map((pdp) => {
        const attribute = state.attributes.find((att) => att.code === 'blank_shape');
        if (attribute && Array.isArray(attribute.options)) {
          return attribute.options.find((option) => option.code === pdp.blankShape);
        }
        return undefined;
      })
      .filter(Boolean) as Array<AttributeOption>
);

const selectPdpByAttributeAndOption = createSelector(
  [selectCatalogState, (state: CatalogState, attributes: Array<AttributeOption>) => attributes],
  (state: CatalogState, attributes: Array<AttributeOption>) => {
    const returner: Array<IAttributeFilter> = [];
    attributes.forEach((att) => {
      returner.push({ parent: state.pdps.find((pdp) => pdp.blankShape === att.code)?.code || '', attribute: att });
    });
    return returner;
  }
);

const selectMachineFilter = createSelector(
  [
    selectCatalogState,
    (
      state: CatalogState,
      code: 'activeBlankShape' | 'activeBlankColor' | 'activeBlankHeight' | 'activeBlankMandrel' | 'activeEffectColor'
    ) => code,
  ],
  (
    state: CatalogState,
    code: 'activeBlankShape' | 'activeBlankColor' | 'activeBlankHeight' | 'activeBlankMandrel' | 'activeEffectColor'
  ) => state.machineFilter[code]
);

const selectAllProductChildren = createSelector([selectCatalogState], (state: CatalogState) => state.productChildren);

const selectDistinctProductChildren = createSelector([selectCatalogState], (state: CatalogState) =>
  state.productChildren
    .filter((child) =>
      state.machineFilter.activeBlankShape !== '' ? child.parent === state.machineFilter.activeBlankShape : []
    )
    .filter((child) =>
      state.machineFilter.activeBlankColor !== '' ? child.blank_color === state.machineFilter.activeBlankColor : []
    )
    .filter((child) =>
      state.machineFilter.activeEffectColor !== '' ? child.effect_color === state.machineFilter.activeEffectColor : []
    )
    .filter((child) =>
      state.machineFilter.activeBlankHeight !== '' ? child.blank_height === state.machineFilter.activeBlankHeight : []
    )
    .filter((child) =>
      state.machineFilter.activeBlankMandrel !== 'no_mandrel'
        ? child.blank_mandrel === state.machineFilter.activeBlankMandrel
        : []
    )
);

const selectProductSKUs = createSelector([selectCatalogState], (state: CatalogState) =>
  state.pdp.sku
    ? state.pdp.sku
    : state.productChildren.length > 0
    ? state.productChildren.map((child) => child.sku)
    : ''
);

const selectAllAttributesForProductChildren = createSelector(
  [
    selectCatalogState,
    (state: CatalogState, code: 'blank_height' | 'blank_color' | 'blank_mandrel' | 'effect_color') => code,
  ],
  (state: CatalogState, code: 'blank_height' | 'blank_color' | 'blank_mandrel' | 'effect_color') =>
    findAttributes(state, state.productChildren, code)
);

const selectMandrelData = createSelector([selectCatalogState], (state: CatalogState) => {
  const attribute = state.attributes.find((att) => att.code === 'blank_mandrel');
  if (attribute && Array.isArray(attribute.options)) {
    return attribute.options.filter((opt) => opt.code !== 'no_mandrel');
  }
  return undefined;
});

const selectAvailableColors = createSelector([selectCatalogState], (state: CatalogState) =>
  findAttributes(
    state,
    state.productChildren
      .filter((child) =>
        state.machineFilter.activeBlankShape !== '' ? child.parent === state.machineFilter.activeBlankShape : []
      )
      .filter((child) =>
        state.machineFilter.activeBlankHeight !== '' ? child.blank_height === state.machineFilter.activeBlankHeight : []
      )
      .filter((child) =>
        state.machineFilter.activeBlankMandrel !== 'no_mandrel'
          ? child.blank_mandrel === state.machineFilter.activeBlankMandrel
          : []
      ),
    'blank_color'
  )
);

const selectAvailableEffectColors = createSelector([selectCatalogState], (state: CatalogState) =>
  findAttributes(
    state,
    state.productChildren
      .filter((child) =>
        state.machineFilter.activeBlankShape !== '' ? child.parent === state.machineFilter.activeBlankShape : []
      )
      .filter((child) =>
        state.machineFilter.activeBlankHeight !== '' ? child.blank_height === state.machineFilter.activeBlankHeight : []
      )
      .filter((child) =>
        state.machineFilter.activeBlankMandrel !== 'no_mandrel'
          ? child.blank_mandrel === state.machineFilter.activeBlankMandrel
          : []
      ),
    'effect_color'
  )
);

const selectAvailableHeights = createSelector([selectCatalogState], (state: CatalogState) =>
  findAttributes(
    state,
    state.productChildren
      .filter((child) =>
        state.machineFilter.activeBlankShape !== '' ? child.parent === state.machineFilter.activeBlankShape : []
      )
      .filter((child) =>
        state.machineFilter.activeBlankColor !== '' ? child.blank_color === state.machineFilter.activeBlankColor : []
      )
      .filter((child) =>
        state.machineFilter.activeBlankMandrel !== 'no_mandrel'
          ? child.blank_mandrel === state.machineFilter.activeBlankMandrel
          : []
      ),
    'blank_height'
  )
);

const selectAvailableMandrels = createSelector([selectCatalogState], (state: CatalogState) =>
  findAttributes(
    state,
    state.productChildren
      .filter((child) =>
        state.machineFilter.activeBlankShape !== '' ? child.parent === state.machineFilter.activeBlankShape : []
      )
      .filter((child) =>
        state.machineFilter.activeBlankColor !== '' ? child.blank_color === state.machineFilter.activeBlankColor : []
      )
      .filter((child) =>
        state.machineFilter.activeBlankHeight !== '' ? child.blank_height === state.machineFilter.activeBlankHeight : []
      ),
    'blank_mandrel'
  )
);

function findAttributes(
  state: CatalogState,
  children: Array<IMaterialDetail>,
  code: 'blank_height' | 'blank_color' | 'blank_mandrel' | 'effect_color'
): Array<AttributeOption | undefined> {
  return children
    .map((child) => state.attributes.find((att) => att.code === code)?.options.find((opt) => opt.code === child[code]))
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => (a && b ? a.sort_order - b.sort_order : 0));
}

const selectPdps = createSelector(
  [selectCatalogState, (state: CatalogState, props?: { onlyWithSlugs: boolean }) => props],
  (state: CatalogState, props: any) => {
    return props?.onlyWithSlugs ? state.pdps.filter((pdp) => pdp.slug && pdp.slug !== '/') : state.pdps;
  }
);

const selectTwoCeProducts = createSelector(
  [selectCatalogState, (state: CatalogState, props: ICeProductSelectorProps) => props],
  (state: CatalogState, props: any) => {
    const identifierKeyLeftProduct = props.leftProduct.sku ? 'sku' : 'code';
    const leftProduct: IPdp | undefined = state.ceProducts.find(
      (product) => product[identifierKeyLeftProduct] === props.leftProduct[identifierKeyLeftProduct]
    );
    const leftProductImage: IProductImage | undefined = Array.isArray(state.productImages)
      ? state.productImages.find((image) => image.code === leftProduct?.productImageCodes[0])
      : undefined;

    const identifierKeyRightProduct = props.rightProduct.sku ? 'sku' : 'code';
    const rightProduct: IPdp | undefined = state.ceProducts.find(
      (product) => product[identifierKeyRightProduct] === props.rightProduct[identifierKeyRightProduct]
    );
    const rightProductImage: IProductImage | undefined = Array.isArray(state.productImages)
      ? state.productImages.find((image) => image.code === rightProduct?.productImageCodes[0])
      : undefined;

    return {
      leftProduct,
      leftProductImage,
      rightProduct,
      rightProductImage,
    };
  }
);

const selectAkeneoSliderData = createSelector(
  [selectCatalogState, (state: CatalogState, props: { items: Array<IProductOrReferenceData>; type: string }) => props],
  (
    state: CatalogState,
    props: { items: Array<IProductOrReferenceData>; type: string }
  ): Array<IAkeneoSliderItem | undefined> => {
    return props.items
      .map((item: IProductOrReferenceData) => {
        if (props.type === 'product' || props.type === 'product-model') {
          const identifierKeyProduct = item.sku ? 'sku' : 'code';
          const product: IPdp | undefined = state.ceProducts.find(
            (product) => product[identifierKeyProduct] === item[identifierKeyProduct]
          );
          const productImage: IProductImage | undefined = Array.isArray(state.productImages)
            ? state.productImages.find((image) => image.code === product?.productImageCodes[0])
            : undefined;

          if (!product) {
            return undefined;
          }

          return {
            headline: product.name,
            subline: product.shortDescription,
            imageUrl: productImage?.imageUrl,
            identifier: product && product[identifierKeyProduct],
            slug: product.slug,
            categories: product.categories,
            product,
          };
        } else {
          return state.akeneoslider.find((sliderItem) => sliderItem.identifier === item.code);
        }
      })
      .filter((item) => !!item);
  }
);

const selectRootPdps = createSelector(
  [selectCatalogState, (state: CatalogState, props?: { onlyWithSlugs: boolean }) => props],
  (state: CatalogState, props: any) => {
    const hasNoParent = state.pdps.filter((pdp: IPdp) => !pdp.parent || pdp.parent === '');
    return props?.onlyWithSlugs ? hasNoParent.filter((pdp) => pdp.slug && pdp.slug !== '/') : hasNoParent;
  }
);

const selectAttributes = createSelector(
  [selectCatalogState, (state: CatalogState, props: { attributesCodes: Array<string> }) => props],
  (state: CatalogState, props: { attributesCodes: Array<string> }) => {
    return (
      state.attributes
        .filter((attribute) => props.attributesCodes.some((a) => a === attribute.code))
        .map((attribute) => attribute.attribute) || []
    );
  }
);

const selectAttributeOptions = createSelector(
  [selectCatalogState, (state: CatalogState, props: { attributesCodes: Array<string> }) => props],
  (state: CatalogState, props: { attributesCodes: Array<string> }) => {
    return (
      state.attributes
        .filter((attribute) => props.attributesCodes.some((a) => a === attribute.code))
        .map((attribute) => attribute.options)
        .flat() || []
    );
  }
);

const selectPdpCategories = createSelector([selectCatalogState], (state: CatalogState) => state.pdp.categories);

const selectPdpFilterAttributes = createSelector([selectCatalogState], (state: CatalogState) => state.attributes);

const selectAppointmentSlug = createSelector(
  [selectCatalogState],
  (state: CatalogState) => state.pdp.moreInformationSlug
);

const selectPdpPriceHiddenFor = createSelector([selectCatalogState], (state: CatalogState) => state.pdp.hidePrice);

export const CatalogSelectors = {
  selectTechnicalData,
  selectHighlightVideo,
  selectContactPerson,
  selectHighlightVideoElements,
  selectHighlightImageElements,
  selectDownloads,
  selectFeatures,
  selectProductVideos,
  selectProductImages,
  selectTestimonials,
  selectFacts,
  selectAdditionalMedia,
  selectCategories,
  selectMaterials,
  selectMaterialColumns,
  selectCrossSellingProducts,
  selectCrossSellingProductImages,
  selectUpsellingProducts,
  selectUpsellingProductImages,
  selectIndications,
  selectAdditionalMediaLinks,
  selectRotos,
  selectAllCompatibleMaterials,
  selectAllCompatibleMachines,
  selectActiveFilters,
  selectPdpBlankShapeAttributes,
  selectPdpByAttributeAndOption,
  selectMachineFilter,
  selectMandrelData,
  selectAllProductChildren,
  selectDistinctProductChildren,
  selectAllAttributesForProductChildren,
  selectPdpFilterAttributes,
  selectAvailableColors,
  selectAvailableEffectColors,
  selectAvailableHeights,
  selectAvailableMandrels,
  selectPdps,
  selectTwoCeProducts,
  selectRootPdps,
  selectHolders,
  selectAttributes,
  selectAttributeOptions,
  selectAkeneoSliderData,
  selectProductSKUs,
  selectPdpCategories,
  selectAppointmentSlug,
  selectPdpPriceHiddenFor,
};
