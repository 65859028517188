import styles from './text-teaser.module.scss';
import classNames from 'classnames';
import { Colors } from '../../../types';
import TextButton from '../../interaction/text-button/text-button';
import { IconLink } from '@dxp/veneer/lib/decoration/icons';

export interface TextTeaserProps {
  headline: string;
  copy?: string;
  linkText?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const TextTeaser = (props: TextTeaserProps & { className?: string }) => {
  const classes: string = classNames(styles['container'], props.className);

  return (
    <div className={classes} onClick={props.onClick}>
      <div className={classNames(styles['headline'])} data-testid="text-teaser_headline">
        {props.headline}
      </div>
      {props.copy && (
        <p className={classNames(styles['text'], 'text-long')} data-testid="text-teaser_copy">
          {props.copy}
        </p>
      )}
      {props.linkText && (
        <TextButton label={props.linkText} icon={<IconLink />} color={Colors.intenseBlue} size={'normal'} />
      )}
    </div>
  );
};

export default TextTeaser;
