import { AuthContext, IAuthContext, CheckoutDataContext, ICheckoutDataContext } from '@dxp/core';
import { CheckoutDataCustomer, IAddressItem, ICart, PaymentMethodRequest } from '@dxp/spryker-client';
import { Colors, Input, OpenToggle, StandardButton, VeneerAnimation } from '@dxp/veneer';
import { useRouter } from 'next-translate-routes';
import React, { useContext, useEffect, useState } from 'react';
import { CartActions } from '../../redux';
import { CartContext } from '../cart/cart';
import styles from './add-voucher.module.scss';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { CheckoutDataActions } from '../../redux';

const animations = {
  collapsed: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
      ease: VeneerAnimation.easings.inOutCubic,
    },
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: VeneerAnimation.easings.inOutCubic,
      delay: 0.2,
    },
  },
};

export function AddVoucher({ className }: { className?: string }) {
  const [voucherCode, setVoucherCode] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const { locale } = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation('cart');

  const authContext: IAuthContext | null = useContext(AuthContext);
  const cart: ICart | undefined = useContext(CartContext);
  const checkoutDataContext = useContext(CheckoutDataContext);

  useEffect(() => {
    if (cart && locale && checkoutDataContext) {
      triggerCheckoutData(cart, locale, checkoutDataContext);
    }
  }, [cart?.vouchers.length]);

  const triggerCheckoutData = (cart: ICart, locale: string, checkoutDataContext: ICheckoutDataContext) => {
    const {
      customer,
      guestCheckout,
      addresses,
      selectedBillingAddressId,
      selectedShippingAddressId,
      guestCheckoutBillingAddress,
      guestCheckoutShippingAddress,
      guestCheckoutPersonalData,
      checkoutData,
    } = checkoutDataContext;

    if (cart && locale) {
      let checkoutCustomer: CheckoutDataCustomer | null = null;
      let billingAddress: IAddressItem | undefined;
      let shippingAddress: IAddressItem | undefined;
      if (guestCheckout && !customer && !!guestCheckoutBillingAddress && !!guestCheckoutShippingAddress) {
        checkoutCustomer = {
          salutation: guestCheckoutPersonalData?.salutation,
          email: guestCheckoutPersonalData?.email ?? '',
          firstName: guestCheckoutPersonalData?.firstName ?? '',
          lastName: guestCheckoutPersonalData?.lastName ?? '',
        };
        billingAddress = guestCheckoutBillingAddress;
        shippingAddress = guestCheckoutShippingAddress;
      } else if (!guestCheckout && !!customer && selectedBillingAddressId !== '' && selectedShippingAddressId !== '') {
        checkoutCustomer = {
          email: customer?.email ?? '',
          firstName: customer?.firstName ?? '',
          lastName: customer?.lastName ?? '',
          salutation: customer?.salutation,
        };
        billingAddress = { ...addresses?.find((address) => address.id === selectedBillingAddressId) };
        shippingAddress = { ...addresses?.find((address) => address.id === selectedShippingAddressId) };
      }

      const payments: Array<PaymentMethodRequest> | undefined = checkoutData?.selectedPaymentMethods?.map((method) => {
        return {
          paymentMethodName: method.paymentMethodName,
          paymentProviderName: method.paymentProviderName,
        };
      });

      if (checkoutCustomer) {
        dispatch(
          CheckoutDataActions.checkoutDataRequested({
            locale,
            checkoutDataRequestAttributes: {
              idCart: cart.id || '',
              customer: checkoutCustomer,
              billingAddress,
              shippingAddress,
              shipment:
                checkoutData?.selectedShipmentMethods?.length && checkoutData?.selectedShipmentMethods?.length > 0
                  ? {
                      idShipmentMethod: checkoutData?.selectedShipmentMethods[0].id,
                    }
                  : undefined,
              payments,
            },
            anonymId: authContext?.anonymId && guestCheckout ? authContext.anonymId : null,
          })
        );
      }
    }
  };

  const onSubmit = () => {
    if (locale && cart?.id && voucherCode !== '') {
      if (authContext?.isAuthenticated) {
        dispatch(CartActions.addVoucher({ locale, payload: { cartId: cart.id, voucherCode } }));
      } else {
        dispatch(
          CartActions.guestAddVoucher({
            locale,
            payload: { cartId: cart.id, voucherCode, anonymId: authContext?.anonymId || '' },
          })
        );
      }
    }
  };

  return (
    <div
      className={classNames(
        styles['container'],
        open ? styles['container--open'] : styles['container--closed'],
        className
      )}
    >
      <div className={styles['title']} onClick={() => setOpen(!open)}>
        {t('voucher.add.title')}

        <OpenToggle isOpen={open} className={styles['open-toggle']} />
      </div>

      <motion.div
        initial="collapsed"
        animate={open ? 'open' : 'collapsed'}
        variants={{
          open: { height: 'auto', visibility: 'visible' },
          collapsed: {
            height: 0,
            transitionEnd: {
              visibility: 'hidden',
            },
          },
        }}
        transition={{ duration: 0.8, ease: VeneerAnimation.easings.inOutCubic }}
        className={styles['form']}
      >
        <motion.div className={styles['form__inner']} variants={animations}>
          <Input
            className={styles['form__input']}
            placeholder={t('voucher.add.placeholder')}
            type={'text'}
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value)}
            variant={Colors.white}
          />
          <StandardButton
            className={styles['form__button']}
            disabled={!voucherCode || voucherCode === ''}
            variant={Colors.brilliantBlue}
            onClick={() => onSubmit()}
            label={t('voucher.add.button')}
          />
        </motion.div>
      </motion.div>
    </div>
  );
}

export default AddVoucher;
