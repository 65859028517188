import styles from './standard-button.module.scss';
import classNames from 'classnames';
import { Colors } from '../../../types';
import { motion } from 'framer-motion';
import React from 'react';
import { VeneerAnimation } from '../../../animations';

export interface StandardButtonProps {
  label: string;
  variant: Colors.brilliantBlue | Colors.shinyYellow | Colors.feelgoodMint | Colors.white;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  size?: 'small';
  type?: 'button' | 'reset' | 'submit';
}

export const StandardButton = React.forwardRef((props: StandardButtonProps & { className?: string }, ref) => {
  const classes: string = classNames(
    styles['inner'],
    styles['inner--' + props.variant],
    props.size ? [styles['inner--' + props.size]] : '',
    {
      [styles['inner--disabled']]: props.disabled,
      [styles['inner--loading']]: props.isLoading,
    },
    props.className
  );

  return (
    <motion.button
      whileHover={{ ...VeneerAnimation.uiScale, transition: VeneerAnimation.uiScaleTransition }}
      className={classes}
      disabled={props.disabled}
      data-testid="inner"
      onClick={props.onClick}
      animate={props.isLoading ? VeneerAnimation.uiLoading : {}}
      title={props.label}
      type={props.type ?? 'button'}
    >
      {props.label}
    </motion.button>
  );
});

export default StandardButton;
