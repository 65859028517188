import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import styles from './delivery-status.module.scss';

export enum DeliveryState {
  AVAILABLE = 'available',
  PENDING = 'pending',
  NOT_AVAILABLE = 'notAvailable',
  AVAILABLE_SOON = 'availableSoon',
}

export interface DeliveryStatusProps {
  deliveryState: DeliveryState;
  label?: string;
}

export const DeliveryStatus = ({ deliveryState, label, className }: DeliveryStatusProps & { className?: string }) => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames(styles['container'], className, deliveryState)}>
      <div
        className={classNames(styles['indicator'], styles['indicator--' + deliveryState])}
        data-testid="statusState"
      ></div>
      <div className={styles['label']} data-testid="statusLabel">
        {label ?? t('product.commerce.availability.' + deliveryState)}
      </div>
    </div>
  );
};

export default DeliveryStatus;
