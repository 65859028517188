import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

export interface ICompatibleMaterial {
  code: string;
  parent: string;
  label: string;
  categoryCodes: Array<string>;
  slug: string;
  compatibleMachineCodes: Array<string>;
}

export const iCompatibleMaterialMappingConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  parent: {
    path: ['parent'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  label: {
    path: ['values', 'name'],
    valueData: ValueDataType.string,
    localized: true,
  },
  categoryCodes: {
    path: ['categories'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  slug: {
    path: ['values', 'slug'],
    valueData: ValueDataType.string,
    localized: true,
  },
  compatibleMachineCodes: {
    path: ['associations', 'machine_material_compatibility', 'products'],
    valueData: ValueDataType.direct,
    localized: false,
    additionalDataCode: 'compatibleMachines',
  },
};
