import {
  IDescriptionAttributes,
  IIndexSignature,
  IProductAttributeMap,
  IProductAttributes,
  IProductImage,
  IProductLabel,
  IProductPrices,
  ISuperAttribute,
  ISuperAttributeData,
} from '../../interfaces';
import {
  EIncludeTypes,
  IProductCardImagesResponse,
  IProductImageSetsRawResponse,
  IProductLabelsCollectionResponse,
  IProductPricesResponse,
  IProductRelationsIncluded,
} from '../../types';
import { priceTypeNameDimensionDefault } from '../../constants';

export const parseImageSets = (imageSets: IProductImageSetsRawResponse[]): IProductImage[] | null => {
  if (!Array.isArray(imageSets) || !imageSets.length) {
    return null;
  }

  const images: IProductCardImagesResponse[] = imageSets.reduce(
    (accumulator: IProductCardImagesResponse[], set: IProductImageSetsRawResponse) =>
      accumulator.concat(set.images.map((imgs: IProductCardImagesResponse) => imgs)),
    []
  );

  return images.map((element: IProductCardImagesResponse, index: number) => ({
    id: index,
    src: element.externalUrlLarge,
    srcSmall: element.externalUrlSmall,
  }));
};

export const getProductLabel = (
  labelsIdArr: string[],
  availableLabels: IProductLabelsCollectionResponse
): IProductLabel[] | null => {
  const isLabelsExist: boolean = Array.isArray(labelsIdArr) && labelsIdArr.length > 0;

  return isLabelsExist
    ? labelsIdArr
        .filter((labelId: string) => availableLabels[labelId])
        .map((labelId: string) => {
          return {
            type: availableLabels[labelId].id || '',
            text: availableLabels[labelId].name || '',
            position: availableLabels[labelId].position || 0,
          };
        })
    : null;
};

export const parsePrices = (prices: IProductPricesResponse[], currentPrice: number): IProductPrices => {
  const parsedPrices = prices.reduce(
    (
      accumulator: Record<
        string,
        | number
        | {
            code: string;
            name: string;
            symbol: string;
          }
        | undefined
      >,
      priceData: IProductPricesResponse
    ) => {
      if (currentPrice === priceData.grossAmount || currentPrice === priceData.netAmount) {
        accumulator['priceDefaultGross'] = priceData.grossAmount;
        accumulator['priceDefaultNet'] = priceData.netAmount;
        accumulator['priceDefaultCurrency'] = priceData.currency;
      } else if (priceData.priceDimensionType === priceTypeNameDimensionDefault) {
        accumulator['priceOriginalGross'] = priceData.grossAmount;
        accumulator['priceOriginalNet'] = priceData.netAmount;
        accumulator['priceOriginalCurrency'] = priceData.currency;
      }

      return accumulator;
    },
    {}
  );

  return parsedPrices;
};

export const getAvailableLables = (included: IProductRelationsIncluded[]): IProductLabelsCollectionResponse => {
  const availableLabels: IProductLabelsCollectionResponse = {};

  const includedLabels: Array<any> = included.filter((item) => item.type === EIncludeTypes.PRODUCT_LABELS.toString());

  includedLabels.forEach((label: any) => {
    availableLabels[label.id] = {
      id: label.id,
      frontEndReference: label.attributes.frontEndReference,
      isExclusive: label.attributes.isExclusive,
      name: label.attributes.name,
      position: label.attributes.position,
    };
  });

  return availableLabels;
};

export const parseSuperAttributes = (
  superAttributes: IProductAttributeMap,
  attributeNamesContainer: IIndexSignature
): ISuperAttribute[] | null => {
  if (!superAttributes.super_attributes || typeof superAttributes.super_attributes !== 'object') {
    return null;
  }

  const names: string[] = Object.keys(superAttributes.super_attributes);

  return names.reduce(
    (accumulator: ISuperAttribute[], name: string) => [
      ...accumulator,
      {
        name,
        nameToShow: attributeNamesContainer[name],
        data: superAttributes.super_attributes[name].reduce(
          (accumulator: ISuperAttributeData[], value: string) => [...accumulator, { value, name: value }],
          []
        ),
      },
    ],
    []
  );
};

export const parseDescriptionAttributes = (
  attributeNames: IIndexSignature,
  attributeValues: IProductAttributes
): IDescriptionAttributes[] =>
  Object.keys(attributeNames)
    .filter((attributeKey: string) => Boolean(attributeValues[attributeKey]))
    .map((attributeKey: string) => ({
      name: attributeNames[attributeKey],
      value: attributeValues[attributeKey],
    }));
