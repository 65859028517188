const I18NextHttpBackend = require('i18next-http-backend');

/**
 * @type {import('next-i18next').UserConfig}
 **/
const i18nConfig = {
  i18n: {
    locales: ['default', 'de-de', 'en-gb', 'en-us'], // Hi, you want to add or edit some locales?! Nice, but don't forget to adjust the spryker clients in libs/commerce/src/index.ts - Thanks :)
    defaultLocale: 'default',
  },
  fallbackLng: 'en-gb',
  lowerCaseLng: true,
  backend: {
    loadPath: process.env.NX_APP_BASE_URL + '/api/locales/{{lng}}/{{ns}}',
    addPath: process.env.NX_APP_BASE_URL + '/api/locales/add/{{lng}}/{{ns}}',
    backendOptions: [{ expirationTime: process.env.NODE_ENV === 'development' ? 1000 : 60 * 60 * 1000 }],
  },
  defaultNS: 'common',
  ns: ['account', 'common', 'events', 'faq', 'media', 'news', 'search', 'catalog', 'cart', 'checkout'],
  reloadOnPrerender: true,
  use: [I18NextHttpBackend],
  serializeConfig: false,
  saveMissing: process.env.NODE_ENV === 'development',
};

module.exports = i18nConfig;
