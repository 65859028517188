import { CustomTrackingEvent, CustomTrackingEventPayload } from '../interfaces';
import { takeEvery } from 'redux-saga/effects';
import { SeoActions } from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';

declare global {
  interface Window {
    dataLayer: CustomTrackingEvent[];
  }
}

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

/**
 * Watches actions to change the current window.dataLayer
 */
export function* watchDataLayer() {
  yield takeEvery(SeoActions.pushEvent, updateDataLayer);
}

/**
 * The worker function to push events to the window.dataLayer
 * @param action
 */
function* updateDataLayer(action: PayloadAction<CustomTrackingEventPayload>) {
  const { payload } = action;

  yield window.dataLayer.push({
    event: payload.event,
    ...payload.properties,
  });
}
