import * as Icon from '../lib/decoration/icons';
import { IconProps } from '../lib/decoration/icon/icon';

export type GenericIcon = { (props: IconProps): JSX.Element; displayName: string };

export const iconset: Array<GenericIcon> = [
  Icon.IconArrow,
  Icon.IconArrowLong,
  Icon.IconLink,
  Icon.IconCheck,
  Icon.IconRoundcheck,
  Icon.IconMinus,
  Icon.IconPlus,
  Icon.IconDownload,
  Icon.IconPlay,
  Icon.IconReset,
  Icon.IconClose,
  Icon.IconBin,
  Icon.IconSearch,
  Icon.IconCart,
  Icon.IconFacebook,
  Icon.IconInstagram,
  Icon.IconTwitter,
  Icon.IconYoutube,
  Icon.IconLinkedin,
  Icon.IconFlag,
  Icon.IconHeart,
  Icon.IconHome,
  Icon.IconInfo,
  Icon.IconMail,
  Icon.IconPhone,
  Icon.IconMarker,
  Icon.IconCalendear,
  Icon.IconTimer,
  Icon.IconUser,
  Icon.IconUsergroup,
  Icon.IconClock,
  Icon.IconFlexibility,
  Icon.IconModules,
  Icon.IconMoveinblock,
  Icon.IconSafety,
  Icon.IconDigital,
  Icon.IconCrosslinking,
  Icon.IconCommunication,
  Icon.IconQuantity,
  Icon.IconPaket,
  Icon.IconList,
  Icon.IconService,
  Icon.IconAutomated,
  Icon.IconIntelligent,
  Icon.IconDefault,
  Icon.IconOpenness,
  Icon.IconCamera,
  Icon.IconFilter,
  Icon.IconManagement,
  Icon.IconShield,
  Icon.IconSignal,
  Icon.IconDisplay,
  Icon.IconSort,
];

export const getIcon = (name: string): GenericIcon => {
  return iconset.find((el) => el.displayName === name) ?? Icon.IconClose;
};
