import { createSelector } from '@reduxjs/toolkit';
import { SearchErrors, ElasticSearchState } from './slice';

const selectElasticSearchState = (state: ElasticSearchState) => state;

const isLoading = createSelector(selectElasticSearchState, (state: ElasticSearchState) => state.loading);

const selectAoe = createSelector(selectElasticSearchState, (state: ElasticSearchState) => state.aoe);

const selectSearchResponse = createSelector(
  selectElasticSearchState,
  (state: ElasticSearchState) => state.searchResponse
);

const selectAutocompleteResponse = createSelector(
  selectElasticSearchState,
  (state: ElasticSearchState) => state.autocompleteResponse
);

const selectSearchError = createSelector(
  [selectElasticSearchState, (state: ElasticSearchState, actionType: keyof SearchErrors) => actionType],
  (state: ElasticSearchState, actionType: keyof SearchErrors) =>
    state.errors && Object.prototype.hasOwnProperty.call(state.errors, actionType) ? state.errors[actionType] : null
);

const isMobileSearchVisible = createSelector(
  selectElasticSearchState,
  (state: ElasticSearchState) => state.mobileSearch
);

export const SearchSelectors = {
  isLoading,
  selectAoe,
  selectSearchResponse,
  selectAutocompleteResponse,
  selectSearchError,
  isMobileSearchVisible,
};
