import { Colors } from '@dxp/veneer/types';
import { IconBurger } from '../../decoration/icons';
import styles from './menu-button.module.scss';
import classNames from 'classnames';

export interface MenuButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
}

export const MenuButton = ({ onClick, title, className }: MenuButtonProps & { className?: string }) => {
  const classes: string = classNames(styles['button'], className);

  return (
    <button className={classes} onClick={onClick} title={title} data-testid={'menu-button'}>
      <IconBurger color={Colors.intenseBlue} />
    </button>
  );
};

export default MenuButton;
