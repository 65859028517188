import { AttributeOption } from '@dxp/akeneo-client';
import {
  ConcreteProductActions,
  ConcreteProductSelectors,
  ConcreteProductState,
  PriceInformation,
  ShippingInformation,
} from '@dxp/commerce';
import { AuthContext, PlatformWebappFeatureFlags, toLowercaseLocale } from '@dxp/core';
import { IConcreteProductDataParsed } from '@dxp/spryker-client';
import {
  Colors,
  DeliveryState,
  DeliveryStatus,
  Dropdown,
  KeyValuePair,
  Mandrel,
  MandrelType,
  Pill,
  Price,
} from '@dxp/veneer';
import { motion } from 'framer-motion';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CtaArea } from '../../components';
import { CoreProductInformationInteractive, CoreProductInformationSlider } from '../../content-elements';
import {
  HidePrice,
  IAttributeFilter,
  IAttributeStore,
  IMaterialDetail,
  IProductImage,
  IProductVideo,
} from '../../interfaces';
import { CatalogActions, CatalogSelectors, CatalogState } from '../../redux';
import styles from './core-product-information.module.scss';

export interface CoreProductInformationProps {
  videos?: Array<IProductVideo>;
  images: Array<IProductImage>;
  name: string;
  sku?: string;
  claim: string;
  shortDescription: string;
  callToAction: Array<string>;
  onViewportStatusChange?: Dispatch<SetStateAction<boolean>>;
  type?: 'product-model' | 'product';
  pills?: Array<IAttributeFilter>;
  locale?: string;
  ctaContactLink?: string;
}

export interface IPdpFilterItem {
  filterCode:
    | 'activeBlankShape'
    | 'activeBlankColor'
    | 'activeBlankHeight'
    | 'activeBlankMandrel'
    | 'activeEffectColor';
  value: string;
}

export const CoreProductInformation = (props: CoreProductInformationProps) => {
  const dispatch = useDispatch();
  const outerRef: RefObject<HTMLDivElement> = useRef(null);
  const innerRef: RefObject<HTMLDivElement> = useRef(null);
  const { t } = useTranslation('common');
  const { locale, asPath, push, pathname, query } = useRouter();
  const authContext = useContext(AuthContext);

  const [deliveryState, setDeliveryState] = useState<DeliveryState | undefined>(undefined);
  const [distinctProductSku, setDistinctProductSku] = useState<string | undefined>(undefined);
  const [updateUrlOnFilterChange, setUpdateUrlOnFilterChange] = useState<boolean>(false);

  const concreteProductDataParsed: IConcreteProductDataParsed | undefined = useSelector(
    (state: { concreteProduct: ConcreteProductState }) =>
      ConcreteProductSelectors.selectConcreteProduct(
        state.concreteProduct,
        props.type === 'product-model' ? distinctProductSku : props.sku
      )
  );

  const filterPrefix = 'f_';

  /**
   * Gets akeneo attribute code (e.g. blank_height) for DXP filterCode (e.g. activeBlankHeight)
   * @param filterCode
   */
  const getAttributeCode = (filterCode: IPdpFilterItem['filterCode']): string => {
    // Join the words with underscores and convert to lowercase
    // e.g. activeBlankShape -> active|blank|shape -> active_blank_shape
    const words = filterCode.match(/[A-Z][a-z]+/g);
    const result = words?.join('_').toLowerCase() || '';

    // Replace the "active_" prefix with "blank_"
    const akeneoFilterCode = result.replace('active_', '');
    return pdpFilterAttributes.find((filterAttribute) => filterAttribute.code === akeneoFilterCode)?.code || '';
  };

  /**
   * Converts an akeneo attribute code like blank_height to DXP-style filterCode (e.g. activeBlankHeight)
   * @param attributeCode
   */
  const getFilterCode = (attributeCode: string): IPdpFilterItem['filterCode'] => {
    // Split the input string into an array of words
    const words = attributeCode.split('_');

    // Capitalize the first letter of each word and join them together
    const result = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join('');

    // Add the "active_" prefix to the result
    return (`active${result}` as IPdpFilterItem['filterCode']) || '';
  };
  const useNextQueryParams = (): { [key: string]: string } => {
    return useMemo(() => {
      const queryParamsStr = asPath.split('?').slice(1).join('');
      const urlSearchParams = new URLSearchParams(queryParamsStr);
      return Object.fromEntries(urlSearchParams.entries());
    }, []);
  };

  // distinct products -> all products that are left if different filters are used
  // at the end (after all filters) only 1 product should be left in distinctProducts array
  const distinctProducts: Array<IMaterialDetail> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectDistinctProductChildren(state.catalog)
  );

  // the attribute data for all products differentiated by filter attribute (color, height, mandrel)
  const allColors: Array<AttributeOption | undefined> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAllAttributesForProductChildren(state.catalog, 'blank_color')
  );

  const allHeights: Array<AttributeOption | undefined> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAllAttributesForProductChildren(state.catalog, 'blank_height')
  );

  const allEffectColors: Array<AttributeOption | undefined> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAllAttributesForProductChildren(state.catalog, 'effect_color')
  );

  const allMandrels: Array<AttributeOption> | undefined = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectMandrelData(state.catalog)
  );

  //the attribute data for filtered products differentiated by filter attribute (color, height, mandrel)
  const distinctColors: Array<AttributeOption | undefined> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAvailableColors(state.catalog)
  );

  const distinctHeights: Array<AttributeOption | undefined> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAvailableHeights(state.catalog)
  );

  const distinctMandrels: Array<AttributeOption | undefined> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAvailableMandrels(state.catalog)
  );

  const distinctEffectColors: Array<AttributeOption | undefined> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAvailableEffectColors(state.catalog)
  );

  // the current active shape pill to pass active value to child
  const activeShapePill: string = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectMachineFilter(state.catalog, 'activeBlankShape')
  );

  // the current active mandrel to pass active value to child
  const activeMandrel: string = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectMachineFilter(state.catalog, 'activeBlankMandrel')
  );

  const activeColor: string = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectMachineFilter(state.catalog, 'activeBlankColor')
  );

  const activeHeight: string = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectMachineFilter(state.catalog, 'activeBlankHeight')
  );

  const activeEffectColor: string = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectMachineFilter(state.catalog, 'activeEffectColor')
  );

  const pdpFilterAttributes: Array<IAttributeStore> = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectPdpFilterAttributes(state.catalog)
  );

  const pdpPriceHiddenFor = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectPdpPriceHiddenFor(state.catalog)
  );

  // generate values for the dropdowns -  all values are rendered, the distinction between all and filtered values is disabled
  const colorDropdownValues: Array<KeyValuePair> = allColors
    .map((color) => {
      if (!color) return { key: '', label: '' };

      return {
        key: color?.code,
        label: Object.entries(color.labels).find(
          (entry) => toLowercaseLocale(entry[0]).replace('_', '-') === props.locale
        )?.[1],
        disabled: !distinctColors.includes(color),
      };
    })
    .filter((keyValuePair) => keyValuePair?.key !== '' && keyValuePair?.label !== '');

  const heightDropdownValues: Array<KeyValuePair> = allHeights
    .map((height) => {
      if (!height) return { key: '', label: '' };

      return {
        key: height?.code,
        label: Object.entries(height.labels).find(
          (entry) => toLowercaseLocale(entry[0]).replace('_', '-') === props.locale
        )?.[1],
        disabled: !distinctHeights.includes(height),
      };
    })
    .filter((keyValuePair) => keyValuePair?.key !== '' && keyValuePair?.label !== '');

  const effectColorDropdownValues: Array<KeyValuePair> = allEffectColors
    .map((effectColor) => {
      if (!effectColor) return { key: '', label: '' };

      return {
        key: effectColor?.code,
        label: Object.entries(effectColor.labels).find(
          (entry) => toLowercaseLocale(entry[0]).replace('_', '-') === props.locale
        )?.[1],
        disabled: !distinctEffectColors.includes(effectColor),
      };
    })
    .filter((keyValuePair) => keyValuePair?.key !== '' && keyValuePair?.label !== '');

  // all SKUs from the product children array, selected for elastic search
  const productSKUs: Array<string> | string = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectProductSKUs(state.catalog)
  );

  const finalSKUs = Array.isArray(productSKUs) ? productSKUs.join(', ') : productSKUs;

  // action handler for filter change
  const handleFilterChange = (filterCode: IPdpFilterItem['filterCode'], value: string) => {
    console.log('handle filterchange', filterCode);
    //if blankShape is changed we reset the form
    if (filterCode === 'activeBlankShape') {
      formRef?.current?.reset();
    }

    dispatch(CatalogActions.updateMachineFilter({ filterCode, value }));
  };

  useEffect(() => {
    if (props.type === 'product-model') {
      dispatch(
        CatalogActions.updateMachineFilter({
          filterCode: 'activeBlankShape',
          value: props.pills?.length ? props.pills[0].parent : '',
        })
      );
    }
  }, []);

  // references for form to use reset method
  const formRef = useRef<HTMLFormElement>(null);

  const onReset = () => {
    handleFilterChange('activeBlankShape', props.pills?.length ? props.pills[0].parent : '');
    handleFilterChange('activeBlankColor', '');
    handleFilterChange('activeBlankHeight', '');
    handleFilterChange('activeEffectColor', '');
    handleFilterChange('activeBlankMandrel', 'no_mandrel');
  };

  // dynamic rendering of pills and mandrels, called in template
  const renderShapePills = props.pills?.map((pill, index) => (
    <Pill
      key={pill.attribute.code}
      className={styles['pill']}
      label={
        Object.entries(pill.attribute.labels).find(
          (entry) => toLowercaseLocale(entry[0]).replace('_', '-') === props.locale
        )?.[1] ?? ''
      }
      // set first pill as active when activeShapePill is not set
      active={!activeShapePill && index === 0 ? true : pill.parent === activeShapePill}
      onClick={() => handleFilterChange('activeBlankShape', pill.parent)}
      size={'standard'}
    />
  ));

  const renderMandrels =
    // show mandrel selection only if a mandrel is in distinct selection
    allMandrels?.some((mandrel) => distinctMandrels.some((opt) => opt?.code === mandrel.code)) &&
    allMandrels?.map((mandrel) => (
      <Mandrel
        title={
          Object.entries(mandrel.labels).find(
            (entry) => toLowercaseLocale(entry[0]).replace('_', '-') === props.locale
          )?.[1]
        }
        type={
          mandrel.code === MandrelType.UN
            ? MandrelType.UN
            : mandrel.code === MandrelType.DR
            ? MandrelType.DR
            : MandrelType.AG
        }
        key={mandrel.code}
        inactive={!distinctMandrels.some((opt) => opt?.code === mandrel.code)}
        active={mandrel.code === activeMandrel}
        onClick={() =>
          mandrel.code === activeMandrel
            ? handleFilterChange('activeBlankMandrel', 'no_mandrel')
            : distinctMandrels.some((opt) => opt?.code === mandrel.code)
            ? handleFilterChange('activeBlankMandrel', mandrel.code)
            : ''
        }
      />
    ));

  useEffect(() => {
    if (concreteProductDataParsed?.concreteProduct) {
      setDeliveryState(
        concreteProductDataParsed?.concreteProduct.isRealtime && concreteProductDataParsed?.concreteProduct.isAvailable
          ? DeliveryState.AVAILABLE
          : DeliveryState.AVAILABLE_SOON
      );
    }
  }, [concreteProductDataParsed]);

  const queryParams = useNextQueryParams();
  useEffect(() => {
    const filtersInUrl = Object.entries(queryParams)
      .filter(([key]) => key.startsWith(filterPrefix))
      .map(([key, value]) => {
        const match = key.match(new RegExp(`${filterPrefix}(.*)`));
        const filterCode = match?.length ? match[1] : '';

        return {
          filterCode: getFilterCode(filterCode),
          value,
        } as IPdpFilterItem;
      })
      .filter((filterItem) => filterItem.value && filterItem.value !== 'no_mandrel')
      .sort((a, b) => (a.filterCode === 'activeBlankShape' ? -1 : 1));

    filtersInUrl.forEach((filterItem) => {
      dispatch(CatalogActions.updateMachineFilter(filterItem));
    });

    setUpdateUrlOnFilterChange(true);
  }, []);

  useEffect(() => {
    if (props.type === 'product-model') {
      if (distinctProducts?.length === 1) {
        setDistinctProductSku(distinctProducts[0].sku);
      } else {
        setDistinctProductSku(undefined);
      }
    }
  }, [distinctProducts]);

  // Fetch concrete product by props.sku (case product)
  useEffect(() => {
    if (props.sku) {
      dispatch(ConcreteProductActions.concreteProductRequested({ sku: props.sku, locale }));
    }
  }, [props.sku]);

  // Fetch concrete product by props.sku (case product-model)
  useEffect(() => {
    if (distinctProductSku) {
      dispatch(ConcreteProductActions.concreteProductRequested({ sku: distinctProductSku, locale }));
    }
  }, [distinctProductSku]);

  useEffect(() => {
    const filters: Array<IPdpFilterItem> = [
      { filterCode: 'activeBlankHeight', value: activeHeight },
      { filterCode: 'activeBlankColor', value: activeColor },
      { filterCode: 'activeEffectColor', value: activeEffectColor },
      { filterCode: 'activeBlankShape', value: activeShapePill },
      { filterCode: 'activeBlankMandrel', value: activeMandrel },
    ];

    const filterItems = Object.fromEntries(
      filters
        .filter(
          (filterItem) =>
            filterItem.value &&
            filterItem.value !== 'no_mandrel' &&
            //exclude default shape from url filters
            !(
              props.pills?.length &&
              filterItem.filterCode === 'activeBlankShape' &&
              filterItem.value === props.pills[0].parent
            )
        )
        .map(({ filterCode, value }) => [`${filterPrefix}${getAttributeCode(filterCode)}`, value])
    );

    if (updateUrlOnFilterChange) {
      push({ pathname, query: { slug: query['slug'], ...filterItems } }, undefined, { shallow: true });
    }
  }, [activeHeight, activeColor, activeEffectColor, activeShapePill, activeMandrel]);

  const isPriceHidden = useMemo(
    () =>
      authContext?.isAuthenticated
        ? pdpPriceHiddenFor.includes(HidePrice.FOR_LOGGED)
        : pdpPriceHiddenFor.includes(HidePrice.FOR_GUEST),
    [pdpPriceHiddenFor, authContext?.isAuthenticated]
  );

  return (
    <>
      <Head>
        <meta className="elastic" name="product_sku" content={finalSKUs} />
      </Head>
      <motion.div
        data-elastic-include={true}
        viewport={{ amount: 0.3 }}
        onViewportLeave={() => props.onViewportStatusChange && props.onViewportStatusChange(true)}
        onViewportEnter={() => props.onViewportStatusChange && props.onViewportStatusChange(false)}
        className={styles['container']}
        data-testid={'core-product-information'}
        ref={outerRef}
      >
        <div className={styles['inner']} ref={innerRef}>
          {props.type === 'product-model' ? (
            <CoreProductInformationSlider images={props.images} />
          ) : (
            <CoreProductInformationInteractive images={props.images} videos={props.videos} name={props.name} />
          )}

          <div className={styles['info-container']}>
            {props.name && (
              <h1 className={styles['headline']} data-testid={'headline'}>
                {props.name} {props.claim ? '-' : ''} {props.claim}
              </h1>
            )}
            {props.shortDescription && (
              <p className={styles['intro']} data-testid={'intro'}>
                {props.shortDescription}
              </p>
            )}
            {props.type === 'product-model' && (
              <>
                <div className={styles['pill-container']}>{renderShapePills}</div>
                <form
                  ref={formRef}
                  className={styles['dropdown-container']}
                  onReset={onReset}
                  onSubmit={(e) => e.preventDefault()}
                  suppressHydrationWarning={true}
                >
                  {!!colorDropdownValues.length && (
                    <Dropdown
                      label={t('product.detail.coreProductInformation.color')}
                      placeholder={t('product.detail.coreProductInformation.colorPlaceholder')}
                      variant={Colors.white}
                      value={activeColor ? activeColor : allColors?.length === 1 ? allColors[0]?.code : null}
                      values={colorDropdownValues ? colorDropdownValues : []}
                      borders={'square'}
                      disabledPlaceholder={allColors?.length === 1}
                      onChange={(e) => {
                        if (e.target.selectedOptions[0].value === 'color') {
                          handleFilterChange('activeBlankColor', '');
                        } else {
                          handleFilterChange('activeBlankColor', e.target.selectedOptions[0].value);
                        }
                      }}
                    />
                  )}
                  {!!effectColorDropdownValues.length && (
                    <Dropdown
                      label={t('product.detail.coreProductInformation.effectColor')}
                      placeholder={t('product.detail.coreProductInformation.effectColorPlaceholder')}
                      variant={Colors.white}
                      value={
                        activeEffectColor
                          ? activeEffectColor
                          : allEffectColors?.length === 1
                          ? allEffectColors[0]?.code
                          : null
                      }
                      values={effectColorDropdownValues ? effectColorDropdownValues : []}
                      borders={'square'}
                      disabledPlaceholder={allColors?.length === 1}
                      onChange={(e) => {
                        if (e.target.selectedOptions[0].value === 'color') {
                          handleFilterChange('activeEffectColor', '');
                        } else {
                          handleFilterChange('activeEffectColor', e.target.selectedOptions[0].value);
                        }
                      }}
                    />
                  )}
                  {!!heightDropdownValues.length && (
                    <Dropdown
                      label={t('product.detail.coreProductInformation.height')}
                      placeholder={t('product.detail.coreProductInformation.heightPlaceholder')}
                      variant={Colors.white}
                      // value={allHeights?.length === 1 ? allHeights[0]?.code : null}
                      value={activeHeight ? activeHeight : allHeights?.length === 1 ? allHeights[0]?.code : null}
                      values={heightDropdownValues ? heightDropdownValues : []}
                      borders={'square'}
                      disabledPlaceholder={allHeights?.length === 1}
                      onChange={(e) => {
                        if (e.target.selectedOptions[0].value === 'height') {
                          handleFilterChange('activeBlankHeight', '');
                        } else {
                          handleFilterChange('activeBlankHeight', e.target.selectedOptions[0].value);
                        }
                      }}
                    />
                  )}
                  {(!!props.pills?.length || !!colorDropdownValues.length || !!heightDropdownValues.length) && (
                    <div className={styles['reset-button__container']}>
                      <button className={styles['reset-button']} type="reset">
                        {t('product.detail.coreProductInformation.reset')}
                      </button>
                    </div>
                  )}
                </form>

                <div className={styles['mandrel-container']}>{renderMandrels}</div>
              </>
            )}

            <div className={styles['delivery-status']}>
              {deliveryState && <DeliveryStatus deliveryState={deliveryState} className={styles['delivery']} />}

              {(distinctProductSku || props.sku) && (
                <div className={styles['sku']}>
                  SKU {props.type === 'product-model' ? distinctProductSku : props.sku}
                </div>
              )}
            </div>
            <div className={styles['commerce-information']}>
              {concreteProductDataParsed?.concreteProduct && (
                <PriceInformation concreteProduct={concreteProductDataParsed?.concreteProduct}>
                  <Price vat={false} layout="pdp" priceSize="pdp" isPriceHidden={isPriceHidden} />
                </PriceInformation>
              )}

              {PlatformWebappFeatureFlags.learnMore && (
                <ShippingInformation>
                  <a href={'#'} className={'underline'}>
                    {t('product.commerce.financing.label')}
                  </a>
                  <span className={styles['only-mobile']}>&nbsp;{t('product.commerce.shipping.or')}&nbsp;</span>
                  <a href={'#'} className={'underline'}>
                    {t('product.commerce.shipping.label')}
                  </a>
                </ShippingInformation>
              )}
            </div>

            <CtaArea
              className={styles['cta-area']}
              callToAction={props.callToAction}
              contactLink={props.ctaContactLink}
              sku={props.sku ? props.sku : distinctProductSku ? distinctProductSku : undefined}
              locale={props.locale || ''}
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default CoreProductInformation;
