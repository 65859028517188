import { HubSpotState } from './slice';
import { createSelector } from '@reduxjs/toolkit';

const selectHubSpotState = (state: HubSpotState) => state;

const selectPortalId = createSelector(selectHubSpotState, (state: HubSpotState) => state.portalId);

const selectNewsLetterRegistrationId = createSelector(
  selectHubSpotState,
  (state: HubSpotState) => state.newsletterRegistrationId
);

export const HubSpotSelectors = {
  selectPortalId,
  selectNewsLetterRegistrationId,
};
