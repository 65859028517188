import { createSelector } from '@reduxjs/toolkit';
import { CustomerErrors, CustomerState } from './slice';

const selectCustomerState = (state: CustomerState) => state;

const isLoading = createSelector(selectCustomerState, (state: CustomerState) => state.loading);

const selectCustomer = createSelector(selectCustomerState, (state: CustomerState) => state.customer);

const selectError = createSelector(
  [selectCustomerState, (state: CustomerState, actionType: keyof CustomerErrors) => actionType],
  (state: CustomerState, actionType: keyof CustomerErrors) =>
    state.error && Object.prototype.hasOwnProperty.call(state.error, actionType) ? state.error[actionType] : null
);

export const CustomerSelectors = {
  selectCustomerState,
  isLoading,
  selectCustomer,
  selectError,
};
