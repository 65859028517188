import { IPaymentMethodsRequestAttributes, IPaymentMethodsResponse } from '@dxp/spryker-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { sprykerClient } from '../../index';
import { CheckoutPaymentsActions } from './slice';

/**
 * The watcher function to submit and request checkout-payments data
 */
export function* watchCheckoutPaymentsRequested() {
  yield takeEvery(CheckoutPaymentsActions.checkoutPaymentsRequested, fetchCheckoutPaymentsAsync);
}

/**
 * The worker function to submit and request checkout-payments data
 * @param action
 */
export function* fetchCheckoutPaymentsAsync(
  action: PayloadAction<{
    locale: string | undefined;
    checkoutPaymentsRequestAttributes: IPaymentMethodsRequestAttributes;
    anonymId: string | null;
  }>
) {
  try {
    const { locale, checkoutPaymentsRequestAttributes, anonymId } = action.payload;

    let checkoutPaymentsResponse: IPaymentMethodsResponse | null;

    if (!anonymId) {
      checkoutPaymentsResponse = yield call(
        sprykerClient(locale).checkoutPayments.getAdyenPaymentMethodsConfigAuthorized,
        {
          paymentMethodsRequestAttributes: checkoutPaymentsRequestAttributes,
        }
      );
    } else {
      checkoutPaymentsResponse = yield call(
        sprykerClient(locale).checkoutPayments.getAdyenPaymentMethodsConfigUnauthorized,
        {
          paymentMethodsRequestAttributes: checkoutPaymentsRequestAttributes,
          anonymId,
        }
      );
    }

    if (checkoutPaymentsResponse) {
      yield put(CheckoutPaymentsActions.checkoutPaymentsReceived(checkoutPaymentsResponse));
    } else {
      yield put(CheckoutPaymentsActions.checkoutPaymentsRequestFailed('No payment methods received.'));
    }
  } catch (error: any) {
    yield put(CheckoutPaymentsActions.checkoutPaymentsRequestFailed(error));
  }
}
