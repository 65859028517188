import {
  CheckoutDataType,
  ICheckoutData,
  ICheckoutDataResponse,
  IncludedCheckoutData,
  IPaymentMethods,
  IShipmentMethods,
} from '../../interfaces';

export const parseCheckoutDataResponse = (
  data: ICheckoutDataResponse,
  included?: Array<IncludedCheckoutData>
): ICheckoutData => {
  const includedPaymentMethods: Array<IPaymentMethods> = (included || [])
    .filter((includes) => includes.type === CheckoutDataType.PaymentMethods)
    ?.map((included) => {
      return {
        id: included.id,
        paymentMethodName: included.attributes.paymentMethodName,
        paymentProviderName: included.attributes.paymentProviderName,
        priority: included.attributes.priority || null,
        requiredRequestData: included.attributes.requiredRequestData,
      };
    });
  const includedShipmentMethods: Array<IShipmentMethods> = (included || [])
    .filter((includes) => includes.type === CheckoutDataType.ShipmentMethods)
    .map((included) => {
      return {
        id: included.id,
        carrierName: included.attributes.carrierName || '',
        currencyIsoCode: included.attributes.currencyIsoCode || '',
        deliveryTime: included.attributes.deliveryTime || null,
        name: included.attributes.name || '',
        price: included.attributes.price || null,
      };
    });

  const addresses = data.attributes.addresses.map((address) => ({
    ...address,
    country: '',
  }));

  return {
    acceptedTerms: false,
    id: data.id,
    addresses,
    paymentMethods: includedPaymentMethods,
    shipmentMethods: includedShipmentMethods,
    selectedPaymentMethods: data.attributes.selectedPaymentMethods,
    selectedShipmentMethods: data.attributes.selectedShipmentMethods,
  };
};
