import {
  TypeArticleCategoryFields,
  TypeArticleFields,
  TypeEventFields,
  TypeFaqFields,
  TypeFaqTopicFields,
  TypePageAppointmentFields,
  TypePageFields,
  TypeTrainerFields,
  TypeVacancyFields,
} from '../types/generated-types';

export interface IEvent {
  id: string;
  event: TypeEventFields;
}

export interface IVacancy {
  id: string;
  vacancy: TypeVacancyFields;
}

export interface IArticle {
  id: string;
  article: TypeArticleFields;
}

export interface IArticleCategory {
  id: string;
  category: TypeArticleCategoryFields;
}

export interface IArticleInformation {
  totalCount: number | null;
  articles: Array<IArticle>;
}

export interface IFaqTopic {
  id: string;
  faqTopic: TypeFaqTopicFields;
}

export interface IFaqQuestion {
  id: string;
  faq: TypeFaqFields;
}

export interface ITrainer {
  id: string;
  data: TypeTrainerFields;
}

export enum EPageContentTypes {
  PAGE = 'page',
  PAGE_APPOINTMENT = 'pageAppointment',
}

export type PageEntry<T extends EPageContentTypes> = T extends EPageContentTypes.PAGE
  ? TypePageFields
  : T extends EPageContentTypes.PAGE_APPOINTMENT
  ? TypePageAppointmentFields
  : never;
