import { CheckoutType, ICheckout, ICheckoutResponse, IncludedCheckout, IOrder } from '../../interfaces';

export const parseCheckoutResponse = (data: ICheckoutResponse, included?: Array<IncludedCheckout>): ICheckout => {
  const includedOrders: Array<IOrder> = (included || [])
    .filter((includes) => includes.type === CheckoutType.Orders)
    .map((included) => included as IOrder);

  const includedOrderShipments: Array<any> = (included || [])
    .filter((includes) => includes.type === CheckoutType.OrderShipments)
    .map((included) => included); // TODO: add proper mapping if typing is done

  return {
    id: data.id,
    orderReference: data.attributes.orderReference,
    redirectUrl: data.attributes.redirectUrl,
    isExternalRedirect: data.attributes.isExternalRedirect,
    orders: includedOrders,
    orderShipments: includedOrderShipments,
  };
};
