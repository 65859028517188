import Search from '../../../components/search/search';
import styles from './footer.module.scss';
import { AgLogo, Colors } from '@dxp/veneer';
import { useTranslation } from 'next-i18next';
import { SocialMediaBar } from '@dxp/core';
import Link from 'next/link';
import classNames from 'classnames';
import { NavigationsSelectors, TypeNavigationElementFields } from '@dxp/content';
import { useAppSelector } from '../../../redux';
import React from 'react';
import LegalNavigation from '../../../components/legal-navigation/legal-navigation';
import Address from '../../../components/address/address';
import { PaymentMethods } from '@dxp/commerce';

const Footer = () => {
  const { t } = useTranslation('common');

  const highlightNavigationTitle: string = useAppSelector((state) =>
    NavigationsSelectors.getNavigationTitle(state.navigations, 'footerhighlights')
  );

  const highlightNavigation: Array<TypeNavigationElementFields> | undefined = useAppSelector((state) =>
    NavigationsSelectors.getNavigation(state.navigations, 'footerhighlights')
  );

  const serviceNavigationTitle: string = useAppSelector((state) =>
    NavigationsSelectors.getNavigationTitle(state.navigations, 'footerservice')
  );

  const serviceNavigation: Array<TypeNavigationElementFields> | undefined = useAppSelector((state) =>
    NavigationsSelectors.getNavigation(state.navigations, 'footerservice')
  );

  return (
    <footer className={styles['footer']} data-testid="footer" data-elastic-exclude={true}>
      <div className={styles['container']}>
        <div className={styles['logo-container']}>
          <Link href="/" aria-label={'Home'}>
            <AgLogo className={styles['logo']} color={Colors.brilliantBlue} />
          </Link>
        </div>

        <div className={styles['searchbar-container']}>
          <Search theme={Colors.brilliantBlue} />
        </div>

        <div
          className={classNames(styles['navigation'], styles['navigation--highlights'])}
          data-testid="navigation-highlights"
        >
          <div className={styles['navigation__title']}>{highlightNavigationTitle}</div>
          {highlightNavigation &&
            highlightNavigation
              .filter((item) => !!item && item.link)
              .map((navItem) => (
                <Link href={navItem.link || ''} key={navItem.label} className={styles['navigation__link']}>
                  {navItem.label}
                </Link>
              ))}
        </div>

        <div
          className={classNames(styles['navigation'], styles['navigation--service'])}
          data-testid="navigation-service"
        >
          <div className={styles['navigation__title']}>{serviceNavigationTitle}</div>
          {serviceNavigation &&
            serviceNavigation
              .filter((item) => !!item && item.link)
              .map((navItem) => (
                <Link href={navItem.link || ''} key={navItem.label} className={styles['navigation__link']}>
                  {navItem.label}
                </Link>
              ))}
        </div>

        <Address className={styles['contact']} />

        <LegalNavigation className={styles['legal']} />

        <div
          className={classNames(styles['navigation'], styles['navigation--additional'])}
          data-testid="navigation-additional"
        >
          <div className={styles['navigation__title']}>{t('footer.followUs')}</div>
          <SocialMediaBar theme={'light'} direction={'horizontal'} />

          <div className={styles['navigation__title']}>{t('footer.paymentMethods')}</div>
          <PaymentMethods />
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
