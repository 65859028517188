import styles from './payment-container.module.scss';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import AdyenCheckout from '@adyen/adyen-web';
import { Colors, Loader } from '@dxp/veneer';
import { useTranslation } from 'next-i18next';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import {
  CartSelectors,
  CartState,
  CheckoutPaymentsActions,
  CheckoutPaymentsSelectors,
  CheckoutPaymentsState,
  PaymentSelectors,
  PaymentState,
} from '../../redux';
import { CardElementData } from '@adyen/adyen-web/dist/types/components/Card/types';

import { AuthContext } from '@dxp/core';

export const PaymentContainer = (props: {
  className?: string;
  setPaymentInstance: (paymentInstance: DropinElement) => void;
  setPaymentInfoValid: (paymentInfoValid: boolean) => void;
  setCardPaymentData: (paymentData: CardElementData['paymentMethod']) => void;
}) => {
  const paymentState: PaymentState | undefined = useSelector((state: { payments: PaymentState }) =>
    PaymentSelectors.selectPaymentState(state.payments)
  );
  const paymentMethodsAvailable = useSelector((state: { checkoutPayments: CheckoutPaymentsState }) =>
    CheckoutPaymentsSelectors.selectCheckoutPayments(state.checkoutPayments)
  );

  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const paymentContainer = useRef(null);
  const { t } = useTranslation('checkout');
  const { locale } = useRouter();
  const { loading } = paymentState;
  const cartId = useSelector((state: { cart: CartState }) => CartSelectors.selectCartId(state.cart));

  useEffect(() => {
    if (locale && cartId && authContext) {
      const checkoutPaymentsRequestAttributes = { idCart: cartId };

      dispatch(
        CheckoutPaymentsActions.checkoutPaymentsRequested({
          locale,
          checkoutPaymentsRequestAttributes,
          anonymId: authContext.anonymId,
        })
      );
    }
  }, [dispatch, locale, cartId, authContext]);

  useEffect(() => {
    if (!paymentMethodsAvailable || !paymentMethodsAvailable.length) {
      return;
    }
    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        ...paymentState.config,
        locale: locale,
        paymentMethodsResponse: {
          paymentMethods: paymentMethodsAvailable,
        },
        paymentMethodsConfiguration: {
          card: {
            styles: {
              base: {
                color: '#000',
                padding: '0 20px',
                minHeight: '2.95rem',
              },
            },
          },
        },
        onChange: (state: any, _component: string): void => {
          if (
            state.data.paymentMethod.encryptedCardNumber &&
            state.data.paymentMethod.encryptedExpiryMonth &&
            state.data.paymentMethod.encryptedExpiryYear &&
            state.data.paymentMethod.encryptedSecurityCode
          ) {
            const { encryptedCardNumber, encryptedExpiryMonth, encryptedExpiryYear, encryptedSecurityCode, type } =
              state.data.paymentMethod;

            const cardPaymentData: CardElementData['paymentMethod'] = {
              type,
              encryptedSecurityCode,
              encryptedExpiryMonth,
              encryptedExpiryYear,
              encryptedCardNumber,
            };
            props.setCardPaymentData(cardPaymentData);
            props.setPaymentInfoValid(state.isValid);
          } else {
            props.setPaymentInfoValid(state.isValid);
          }
        },
        onError: (error: { message: string }, _component: string): void => {
          console.error(error.message);
        },
      });

      if (paymentContainer.current) {
        const dropInCheckout = checkout.create('dropin').mount(paymentContainer.current);
        props.setPaymentInstance(dropInCheckout);
      }
    };

    if (!loading) {
      createCheckout();
    }
  }, [paymentState, loading, locale, t, paymentMethodsAvailable]);

  return !loading ? (
    <div className={classNames(props.className)}>
      <div ref={paymentContainer} className={styles['payment']} />
    </div>
  ) : (
    <Loader color={Colors.intenseBlue} />
  );
};
