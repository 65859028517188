import { Colors, IconLink, ScreenSizes, useScreenSizes } from '@dxp/veneer';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './main-stage.module.scss';
import Image from 'next/image';
import { isExternalURL } from '@dxp/core';

export interface MainStageItem {
  title: string;
  subline: string;
  linkUrl: string;
  videoUrl?: string;
  imageUrl?: string;
}

export interface MainStageProps {
  items: MainStageItem[];
}

export function MainStage(props: MainStageProps) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const screenSize = useScreenSizes();

  const increaseIndex = useCallback(() => {
    const nextIndex = activeIndex + 1;

    if (nextIndex < props.items.length) {
      setActiveIndex(nextIndex);
    } else {
      setActiveIndex(0);
    }
  }, [activeIndex, props.items]);

  const onVideoEnded = () => {
    increaseIndex();
  };

  useEffect(() => {
    if (props.items.length > 0 && !props.items[activeIndex].videoUrl && props.items[activeIndex].imageUrl) {
      const timer = setTimeout(() => {
        increaseIndex();
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      return () => false;
    }
  }, [activeIndex, increaseIndex, props.items]);

  const renderItems = props.items?.map(
    (item: MainStageItem, index: number) =>
      activeIndex === index && (
        <motion.div
          key={uuidv4() + index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25, ease: 'easeInOut' }}
          layoutId="item"
        >
          <div className={styles['media']} data-testid="item">
            {item.videoUrl ? (
              <video
                className={classNames(styles['media__item'], styles['media__item--video'])}
                autoPlay={true}
                src={item.videoUrl}
                controls={false}
                muted={true}
                onEnded={onVideoEnded}
                playsInline={true}
              ></video>
            ) : (
              !!item.imageUrl && (
                <Image
                  width={1920}
                  height={960}
                  className={classNames(styles['media__item'], styles['media__item--image'])}
                  src={item.imageUrl}
                  alt={item.title}
                />
              )
            )}
          </div>
        </motion.div>
      )
  );

  const renderNavItems = props.items?.map((item: MainStageItem, index: number) => {
    const active = activeIndex === index;

    return (
      <Link
        href={item.linkUrl}
        target={isExternalURL(item.linkUrl) ? '_blank' : '_self'}
        rel={isExternalURL(item.linkUrl) ? 'noopener' : ''}
        className={classNames(styles['nav-item'], active && styles['nav-item--active'])}
        key={uuidv4()}
        data-testid={'nav-item'}
      >
        <div className={styles['nav-item__title']}>
          <span className={classNames(styles['nav-item__title--text'], 'text-short-medium')}>{item.title}</span>

          <div className={styles['nav-item__controls']} data-testid={'nav-item-control'}>
            <IconLink className={styles['nav-item__icon']} color={active ? Colors.smartBlue : Colors.brilliantBlue} />
          </div>
        </div>

        {screenSize !== ScreenSizes.SMALL && <span className={'text-short'}>{item.subline}</span>}
      </Link>
    );
  });

  return (
    !!props.items.length && (
      <div className={styles['container']}>
        <AnimatePresence>{renderItems}</AnimatePresence>

        <div className={styles['nav']}>
          <div className={styles['nav__inner']}>{renderNavItems}</div>
        </div>
      </div>
    )
  );
}

export default MainStage;
