import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

/**
 * The interface for a feature from catalog.
 */
export interface IFeature {
  label: string;
  description: string;
  priority: string;
  code: string;
}

/**
 * Mapping configuration for IFeature interface
 */
export const iFeatureConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  label: {
    path: ['values', 'naming'],
    valueData: ValueDataType.string,
    localized: true,
  },
  description: {
    path: ['values', 'description'],
    valueData: ValueDataType.string,
    localized: true,
  },
  priority: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
    modifier: (code: string) => code.split('_')[code.split('_').length - 1] || '',
  },
};

/**
 * Mapping configuration for IFeature interface
 */
export const iFeatureMaterialConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  label: {
    path: ['values', 'naming_feature'],
    valueData: ValueDataType.string,
    localized: true,
  },
  description: {
    path: ['values', 'accordeon_subline'],
    valueData: ValueDataType.string,
    localized: true,
  },
  priority: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
    modifier: (code: string) => code.split('_')[code.split('_').length - 1] || '',
  },
};
