import Link from 'next/link';
import styles from './top-navigation.module.scss';
import classNames from 'classnames';
import { useAppSelector } from '../../../../redux';
import { NavigationsSelectors } from '@dxp/content';
import { Colors, IconFaq, IconSupport, VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';

type TypeNavigationElementFields = {
  link?: string;
  label?: string;
  iconName?: string;
};

export const TopNavigation = (props: { className?: string }) => {
  const items = useAppSelector((state) => NavigationsSelectors.getNavigation(state.navigations, 'topnavigation'));

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case 'Faq':
        return <IconFaq color={Colors.intenseBlue} />;
      case 'Support':
        return <IconSupport color={Colors.intenseBlue} />;
      default:
        return null;
    }
  };

  return (
    <nav className={classNames(styles['nav'], props.className)} data-testid={'quick-nav'}>
      <ul className={styles['list']}>
        {items &&
          items
            ?.filter((item: TypeNavigationElementFields) => !!item && item.link)
            .map((item: TypeNavigationElementFields) => (
              <motion.li
                whileHover={VeneerAnimation.uiScaleBig}
                transition={VeneerAnimation.uiScaleTransition}
                className={styles['item']}
                key={item.label}
              >
                <Link href={item.link || ''} className={styles['link']}>
                  {item.iconName && getIcon(item.iconName)}
                  {item.label}
                </Link>
              </motion.li>
            ))}
      </ul>
    </nav>
  );
};

export default TopNavigation;
