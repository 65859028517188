/**
 * Dynamic interface for mapping configuration.
 */
export interface MappingConfig {
  [property: string]: MappingPropertyConfig | string;
}

/**
 * Property configuration to specify where desired data are located in akeneo's response items.
 */
export interface MappingPropertyConfig {
  path: Array<string>;
  valueData: ValueDataType;
  localized: boolean;
  modifier?: (valueData: any | Array<any>) => any | Array<any>;
  additionalDataCode?: string;
}

/**
 * Enumeration to select different types of data from akeneo's response items.
 */
export enum ValueDataType {
  'direct' = 'direct',
  'string' = 'string',
  'strings' = 'strings',
  'stringsUseFirst' = 'stringsUseFirst',
  'object' = 'object',
}
