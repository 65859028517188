import styles from './text-button.module.scss';
import { Colors, getIcon } from '../../../types';
import classNames from 'classnames';
import React, { cloneElement, ReactElement } from 'react';

export interface TextButtonProps {
  label: string;
  subline?: string;
  icon: ReactElement;
  color: Colors.intenseBlue | Colors.white | Colors.brilliantBlue | Colors.smartBlue;
  iconColor?: Colors;
  size: 'normal' | 'subtext' | 'with-subline';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const TextButton = (props: TextButtonProps & { className?: string }) => {
  const classes: string = classNames(
    styles['text-button'],
    styles['text-button--' + props.color],
    styles['text-button--' + props.size],
    props.className
  );

  return (
    <button className={classes} onClick={props.onClick} title={props.label} data-testid={'text-button'}>
      {cloneElement(props.icon, {
        color: props.iconColor ?? props.color,
        className: classNames(styles['text-button__icon'], styles['text-button__icon--' + props.size]),
      })}
      <div className={classNames(styles['text-button__label'], styles['text-button__label--' + props.size])}>
        <span data-testid="label">{props.label}</span>
        {props.size === 'with-subline' && props.subline !== '' && (
          <>
            <br />
            <div className={classNames(styles['text-button__subline'], styles['text-button__subline--' + props.size])}>
              {props.subline}
            </div>
          </>
        )}
      </div>
    </button>
  );
};

export default TextButton;
