import { MappingPropertyConfig, ValueDataType } from '@dxp/akeneo-client';

const dimensionModifier: (input: { amount: string; unit: string }) => string = (input: {
  amount: string;
  unit: string;
}) => parseInt(input.amount).toFixed(0).toString() + ' ' + input.unit;

const width: MappingPropertyConfig = {
  path: ['values', 'width'],
  valueData: ValueDataType.object,
  localized: false,
  modifier: dimensionModifier,
};

const length: MappingPropertyConfig = {
  path: ['values', 'length'],
  valueData: ValueDataType.object,
  localized: false,
  modifier: dimensionModifier,
};

const height: MappingPropertyConfig = {
  path: ['values', 'height'],
  valueData: ValueDataType.object,
  localized: false,
  modifier: dimensionModifier,
};

export const MappingPropertyConfigs = {
  width,
  length,
  height,
};
