import styles from './link-bar.module.scss';
import { TypeCeLinkBarFields } from '../../types';
import Link from 'next/link';
import { Colors, IconLink, TextButton, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { useMemo } from 'react';
import { motion } from 'framer-motion';
import { isExternalURL } from '@dxp/core';

export function LinkBar({ style, links }: TypeCeLinkBarFields) {
  const renderLinks = useMemo(
    () =>
      !!links &&
      links.length > 0 &&
      links.map((el: { linkText: string; linkUrl: string; id: string; subline?: string }) => (
        <Link
          href={el.linkUrl}
          target={isExternalURL(el.linkUrl) ? '_blank' : '_self'}
          rel={isExternalURL(el.linkUrl) ? 'noopener' : ''}
          key={el.id}
          className={styles['item']}
          data-testid="link"
        >
          <TextButton
            label={el.linkText}
            icon={<IconLink />}
            color={Colors.intenseBlue}
            size={style === 'compact' ? 'normal' : 'with-subline'}
            iconColor={Colors.feelgoodMint}
            subline={style === 'extended' ? el.subline : ''}
          />
        </Link>
      )),
    [links, style]
  );

  return (
    <motion.div
      className={classNames(styles['container'], styles['container--' + style])}
      data-testid="container"
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
    >
      {style === 'compact' ? (
        <div className={styles['item-container']} data-testid="item-container">
          {renderLinks}
        </div>
      ) : (
        renderLinks
      )}
    </motion.div>
  );
}

export default LinkBar;
