import styles from './simple-testimonial.module.scss';
import { TypeCeSimpleTestimonialFields } from '../../types';
import { VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import Image from 'next/image';

export function SimpleTestimonial({
  statement,
  sourcePrimary,
  sourceSecondary,
  imageUrl,
}: TypeCeSimpleTestimonialFields) {
  return (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(styles['container'], imageUrl ? styles['container--with-image'] : '')}
    >
      {imageUrl && (
        <div className={styles['image-container']}>
          <Image width={438} height={514} src={imageUrl} className={styles['image']} alt={statement || ''} />
        </div>
      )}

      <div className={styles['statement']} data-testid={'statement'}>
        {statement}
      </div>
      {!!sourcePrimary && (
        <div className={styles['source']}>
          <div className={styles['source__primary']} data-testid={'sourcePrimary'}>
            {sourcePrimary}
          </div>
          {!!sourceSecondary && (
            <div className={styles['source__secondary']} data-testid={'sourceSecondary'}>
              {sourceSecondary}
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
}

export default SimpleTestimonial;
