import {
  IAddressItem,
  ICheckoutData,
  ICustomerDataParsed,
  IGuestCheckoutPersonalData,
  IPaymentMethods,
  IShipmentMethods,
  IStoreCountry,
} from '@dxp/spryker-client';
import { createContext } from 'react';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { CardElementData } from '@adyen/adyen-web/dist/types/components/Card/types';

export interface ICheckoutDataContext {
  cartId: string | undefined;
  customer: ICustomerDataParsed | null;
  customerRef: string | null;
  guestCheckout: boolean;
  guestCheckoutPersonalData: IGuestCheckoutPersonalData | null;
  addresses: Array<IAddressItem> | null;
  selectedBillingAddressId: string;
  selectedShippingAddressId: string;
  guestCheckoutBillingAddress: IAddressItem | null;
  guestCheckoutShippingAddress: IAddressItem | null;
  storeCountries: Array<IStoreCountry> | null;
  checkoutData: ICheckoutData | null;
  shipmentMethods: Array<IShipmentMethods> | undefined;
  paymentMethods: Array<IPaymentMethods> | undefined;
  acceptedTerms: boolean;
  paymentInstance: DropinElement | null;
  paymentInfoValid: boolean;
  cardPaymentData?: CardElementData['paymentMethod'];
}

export const initialCheckoutData = {
  cartId: undefined,
  currentStep: null,
  customer: null,
  customerRef: null,
  guestCheckout: false,
  guestCheckoutPersonalData: null,
  addresses: null,
  selectedBillingAddressId: '',
  selectedShippingAddressId: '',
  guestCheckoutBillingAddress: null,
  guestCheckoutShippingAddress: null,
  storeCountries: null,
  checkoutData: null,
  shipmentMethods: undefined,
  paymentMethods: undefined,
  acceptedTerms: false,
  paymentInstance: null,
  paymentInfoValid: false,
  cardPaymentData: undefined,
};

export const CheckoutDataContext = createContext<ICheckoutDataContext>(initialCheckoutData);

// Values of CheckoutSteps enum must match any attribute of PlatformWebappStaticRoutes.commerce.checkout object!
export enum CheckoutSteps {
  PERSONAL_DATA = 'personal-data',
  ADDRESS = 'address',
  SHIPPING = 'shipping',
  PAYMENT = 'payment-method',
  // ADYENDROPIN = 'payment', //TODO: reactivate when Adyen gets implemented
}
