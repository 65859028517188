import styles from './video-slider.module.scss';
import { SwiperSlide } from 'swiper/react';
import { isValidHttpUrl, ModalItemInterface, SliderWrapper } from '@dxp/core';
import { VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import { IAdditionalMedia, IAdditionalMediaLink } from '../../interfaces';
import { VideoItem } from '@dxp/core';
import { SwiperOptions } from 'swiper';

export interface VideoSliderProps {
  headline: string;
  copy: string;
  videos: IAdditionalMedia[];
  links?: Array<IAdditionalMediaLink>;
}

export const VideoSlider = (props: VideoSliderProps) => {
  const breakpoints: { [width: number]: SwiperOptions; [ratio: string]: SwiperOptions } = {
    760: {
      slidesPerView: 1.75,
    },
    1020: {
      slidesPerView: 2.33,
    },
  };

  const modalItems: ModalItemInterface[] = props.videos.map((media: IAdditionalMedia) => {
    let item: ModalItemInterface;

    if (isValidHttpUrl(media.videoUrl)) {
      item = {
        type: 'video',
        url: media.videoUrl,
      };
    } else {
      item = {
        type: 'youtube',
        url: media.videoUrl,
      };
    }

    return item;
  });
  const renderSlides = props.videos.map((video, index: number) => {
    const thumb = isValidHttpUrl(video.videoUrl)
      ? video.thumbnailUrl
      : `http://img.youtube.com/vi/${video.videoUrl}/maxresdefault.jpg`;

    return (
      <SwiperSlide key={video.code} className={styles['slide']}>
        <VideoItem title={video.title} thumbnailUrl={thumb} index={index} modalItems={modalItems}></VideoItem>
      </SwiperSlide>
    );
  });

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
    >
      <SliderWrapper
        headline={props.headline}
        copy={props.copy}
        links={props.links}
        arrows={true}
        breakpoints={breakpoints}
      >
        {props.videos.length ? renderSlides : []}
      </SliderWrapper>
    </motion.div>
  );
};

export default VideoSlider;
