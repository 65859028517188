import styles from './media-gallery-item.module.scss';
import { Colors, IconPlay, RoundButton } from '@dxp/veneer';
import classNames from 'classnames';
import { ModalActions, ModalContentsInterface, ModalItemInterface } from '@dxp/core';
import { useDispatch } from 'react-redux';
import Image from 'next/image';

export interface MediaGalleryItemProps {
  caption?: string;
  videoUrl?: string;
  imageUrl?: string;
}

export function MediaGalleryItem(props: MediaGalleryItemProps) {
  const dispatch = useDispatch();

  const onPlayClick = (videoUrl: string) => {
    const item: ModalItemInterface = { url: videoUrl, type: 'video' };

    const modalContents: ModalContentsInterface = {
      items: [item],
      activeItem: 0,
    };

    dispatch(ModalActions.open(modalContents));
  };
  return (
    <figure>
      <div className={styles['inner']}>
        {!!props.imageUrl && props.imageUrl !== '' && (
          <Image
            fill={true}
            className={styles['media']}
            src={props.imageUrl}
            alt={props.caption || ''}
            data-testid={'image'}
          />
        )}

        {props.videoUrl ? (
          <RoundButton
            variant={Colors.brilliantBlue}
            icon={<IconPlay />}
            size={'large'}
            className={styles['button']}
            onClick={() => (props.videoUrl ? onPlayClick(props.videoUrl) : false)}
          />
        ) : null}
      </div>
      {props.caption ? (
        <figcaption className={classNames(styles['caption'], 'subtext')} data-testid={'caption'}>
          {props.caption}
        </figcaption>
      ) : null}
    </figure>
  );
}

export default MediaGalleryItem;
