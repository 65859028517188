import { OrdersState } from './slice';
import { createSelector } from '@reduxjs/toolkit';

//select all current customer orders
const selectOrdersState = (state: OrdersState) => state;
const selectOrders = createSelector([selectOrdersState], (state: OrdersState) => state.orders);

//select single order of current customer
const selectOrderState = (state: OrdersState) => state;
const selectOrder = createSelector([selectOrderState], (state: OrdersState) => state.order);

const selectOrdersLoading = createSelector([selectOrdersState], (state: OrdersState) => state.loading);

export const OrderSelectors = {
  selectOrders,
  selectOrdersLoading,
  selectOrder,
};
