import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

/**
 * The interface for a product image from catalog.
 */
export interface IProductImage {
  code: string;
  label: string;
  imageUrl: string;
}

/**
 * Mapping configuration for IProductImage interface
 */
export const iProductImageMappingConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  label: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
  imageUrl: {
    path: ['values', 'asset_link'],
    valueData: ValueDataType.string,
    localized: true,
  },
};
