import {
  ICreateAddressRequest,
  ICustomerDataParsed,
  ICustomerFromCheckout,
  ICustomerProfile,
  ICustomerProfileIdentity,
  ICustomerProfilePassword,
  IForgotPasswordBodyAttribute,
  IRestorePasswordPayload,
} from '@dxp/spryker-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateError = string | { [key: string]: string } | null;

export interface CustomerErrors {
  updateCustomer: StateError;
  customerRestorePassword: StateError;
  customerForgotPassword: StateError;
}

/**
 * The interface for customer data provided in redux store.
 */
export interface CustomerState {
  loading: boolean;
  customer: ICustomerDataParsed | null;
  error: CustomerErrors;
}

/**
 * The initial customer redux state.
 */
const initialState: CustomerState = {
  loading: false,
  customer: null,
  error: {
    updateCustomer: null,
    customerRestorePassword: null,
    customerForgotPassword: null,
  },
};

/**
 * The customer redux slice.
 */
export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    clearCustomer: (state) => {
      state.customer = initialState.customer;
      state.error = initialState.error;
    },
    clearError: (state, action: PayloadAction<keyof CustomerErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },
    createCustomerRequested: (
      state,
      action: PayloadAction<{
        customer: ICustomerProfile | ICustomerFromCheckout;
        address: ICreateAddressRequest | null;
        locale: string | undefined;
        newsletter: boolean;
        anonymId: string;
      }>
    ) => {
      state.loading = true;
    },
    createCustomerReceived: (state, action: PayloadAction<ICustomerDataParsed | null>) => {
      state.loading = false;
    },
    createCustomerRequestFailed: (state) => {
      state.loading = false;
    },
    customerRequested: (state, action: PayloadAction<{ customerRef: string; locale: string | undefined }>) => {
      state.loading = true;
    },
    customerReceived: (state, action: PayloadAction<ICustomerDataParsed | null>) => {
      state.loading = false;
      state.customer = action.payload;
    },
    customerRequestFailed: (state) => {
      state.loading = false;
      state.customer = initialState.customer;
    },
    updateCustomer: (
      state,
      action: PayloadAction<{ customerRef: string; payload: ICustomerProfileIdentity; locale: string | undefined }>
    ) => {
      state.loading = true;
      state.error = { ...state.error, updateCustomer: null };
    },
    updateCustomerSuccess: (state, action: PayloadAction<ICustomerDataParsed | null>) => {
      state.loading = false;
      state.customer = action.payload;
      state.error = { ...state.error, updateCustomer: null };
    },
    updateCustomerError: (state, action: PayloadAction<StateError>) => {
      state.loading = false;
      state.error = { ...state.error, updateCustomer: action.payload };
    },

    customerForgotPassword: (
      state,
      action: PayloadAction<IForgotPasswordBodyAttribute & { locale: string | undefined }>
    ) => {
      state.loading = true;
      state.error = { ...state.error, customerForgotPassword: null };
    },
    customerForgotPasswordSuccess: (state) => {
      state.loading = false;
      state.error = { ...state.error, customerForgotPassword: null };
    },
    customerForgotPasswordError: (state, action: PayloadAction<StateError>) => {
      state.loading = false;
      state.error = { ...state.error, customerForgotPassword: action.payload };
    },
    customerRestorePassword: (
      state,
      action: PayloadAction<IRestorePasswordPayload & { locale: string | undefined }>
    ) => {
      state.loading = true;
      state.error.customerRestorePassword = null;
    },
    customerRestorePasswordSuccess: (state) => {
      state.loading = false;
      state.error.customerRestorePassword = null;
    },
    customerRestorePasswordError: (state, action: PayloadAction<StateError>) => {
      state.loading = false;
      state.error.customerRestorePassword = action.payload;
    },
    updateCustomerPasswordRequested: (
      state,
      action: PayloadAction<{ customerRef: string; payload: ICustomerProfilePassword; locale: string | undefined }>
    ) => {
      state.loading = true;
    },
    updateCustomerPasswordReceived: (state, action: PayloadAction<boolean | null>) => {
      state.loading = false;
    },
    updateCustomerPasswordRequestFailed: (state) => {
      state.loading = false;
    },
  },
});

export const CustomerActions = customerSlice.actions;

export default customerSlice.reducer;
