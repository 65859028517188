import { IConcreteProductDataParsed, TProductType } from '../interfaces';

export const concreteProductType: TProductType = 'concreteProduct';
export const abstractProductType: TProductType = 'abstractProduct';
export const priceTypeNameOriginal = 'ORIGINAL';
export const priceTypeNameDefault = 'DEFAULT';
export const priceTypeNameMerchant = 'PRICE_DIMENSION_MERCHANT_RELATIONSHIP';
export const priceTypeNameDimensionDefault = 'PRICE_DIMENSION_DEFAULT';
export const defaultProductQuantity = 10;
export const quantitySelectedInitial = 1;

export const emptyConcreteProductDataParsed: IConcreteProductDataParsed = {
  superAttributes: [],
  concreteProduct: {
    description: null,
    descriptionAttributes: null,
    images: null,
    name: null,
    prices: null,
    sku: null,
    productType: null,
    isAvailable: null,
    isNeverOutOfStock: null,
    isRealtime: null,
    quantity: null,
  },
  productLabels: [],
  selectedAttrNames: {},
};
