import { MutableRefObject, useRef } from 'react';
import { useVideoAutoplay } from '@dxp/core';
import styles from './image-text-columns.module.scss';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { tidyHtml, VeneerAnimation } from '@dxp/veneer';
import Image from 'next/image';

export interface ImageTextColumnsProps {
  title: string;
  bodytext: ContentfulDocument;
  imageUrl: string;
  videoUrl: string;
  textFirst: boolean;
  classNames?: string;
}

/**
 * Workaround to conditionally use the hook
 * @see https://www.benmvp.com/blog/conditional-react-hooks/
 */
const AutoPlayHelper = (videoRef: MutableRefObject<HTMLVideoElement | null>) => {
  useVideoAutoplay(videoRef);

  return null;
};

export function ImageTextColumns(props: ImageTextColumnsProps) {
  const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null);

  const classes: string = classNames(
    styles['container'],
    props.classNames,
    props.textFirst ? styles['container--text-first'] : styles['container--text-last']
  );

  return (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classes}
      data-testid="container"
    >
      <div className={classNames(styles['column'], styles['column--content'])}>
        <h2 className={classNames('heading-4', styles['title'])} data-testid="title">
          {props.title}
        </h2>
        <div
          className={classNames('text-long', styles['text'])}
          dangerouslySetInnerHTML={{ __html: tidyHtml(documentToHtmlString(props.bodytext)) }}
          data-testid="text"
        />
      </div>
      <div className={classNames(styles['column'], styles['column--media'])}>
        {props.videoUrl && (
          <div className={classNames(styles['media'], styles['media--video'])}>
            <div className={styles['media__inner']}>
              <video
                className={styles['media__item']}
                ref={videoRef}
                src={props.videoUrl}
                loop={true}
                muted={true}
                playsInline={true}
                poster={props.imageUrl}
                data-testid="video"
              ></video>
              <AutoPlayHelper {...videoRef}></AutoPlayHelper>
            </div>
          </div>
        )}
        {!props.videoUrl && props.imageUrl && (
          <div className={classNames(styles['media'], styles['media--image'])}>
            <div className={styles['media__inner']}>
              <Image
                src={props.imageUrl}
                alt={props.title ? props.title : ''}
                data-testid="image"
                className={styles['media__item']}
                fill={true}
              />
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default ImageTextColumns;
