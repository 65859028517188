import { NavigationsSelectors, TypeNavigationElementFields } from '@dxp/content';
import { MainNavigationActions, useAppDispatch, useAppSelector } from '../../../../redux';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './main-navigation-sidebar.module.scss';

export function MainNavigationSidebar({ className }: { className?: string }) {
  const items: Array<TypeNavigationElementFields> | undefined = useAppSelector((state) =>
    NavigationsSelectors.getNavigation(state.navigations, 'sidebar')
  );

  const dispatch = useAppDispatch();

  const renderItems = (items: TypeNavigationElementFields[] | undefined) => {
    return items?.map((item: TypeNavigationElementFields) => (
      <li key={item.label} className={styles['item']}>
        <Link
          className={styles['link']}
          href={item.link || '/'}
          onClick={() => dispatch(MainNavigationActions.closeMainNavigation())}
        >
          {item.label}
        </Link>
      </li>
    ));
  };

  return (
    <nav className={classNames(styles['sidebar'], className)}>
      <ul className={styles['list']}>{renderItems(items)}</ul>
    </nav>
  );
}

export default MainNavigationSidebar;
