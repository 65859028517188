import { CartActions, CartSelectors, CartState } from '@dxp/commerce';
import { AuthContext, PlatformWebappFeatureFlags } from '@dxp/core';
import { Colors, IconHeart, Quantifier, RoundButton, StandardButton } from '@dxp/veneer';
import classNames from 'classnames';
import NextTranslateRouteLink from 'next-translate-routes/link';
import { Url } from 'next-translate-routes/types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogSelectors, CatalogState } from '../../redux';
import { compareLink } from '../../utils';
import styles from './cta-area.module.scss';

export interface CtaAreaProps {
  callToAction: Array<string>;
  hideWishlist?: boolean;
  contactLink?: string;
  sku?: string;
  locale: string;
}

export const CtaArea = ({
  callToAction,
  hideWishlist,
  contactLink,
  className,
  sku,
  locale,
}: CtaAreaProps & { className?: string }) => {
  const { t } = useTranslation('common');

  const router = useRouter();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const cartLoading: boolean = useSelector((state: { cart: CartState }) => CartSelectors.selectCartLoading(state.cart));

  const slug = useMemo(
    () => (Array.isArray(router.query['slug']) ? router.query['slug'][0] : router.query['slug']),
    [router.query]
  );

  const categories: Array<string> | undefined = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectPdpCategories(state.catalog)
  );

  const appointmentSlug = useSelector((state: { catalog: CatalogState }) =>
    CatalogSelectors.selectAppointmentSlug(state.catalog)
  );

  const [quantity, setQuantity] = useState<number>(1);

  const linkToComparePage = useMemo<Url>(
    () => (!!categories && !!categories.length && !!slug && slug !== '' ? compareLink(slug, categories) : ''),
    [slug, categories]
  );

  const handleAddToCartClick = () => {
    if (locale && PlatformWebappFeatureFlags.commerce.enable[locale]) {
      if (sku) {
        authContext?.isAuthenticated
          ? dispatch(
              CartActions.addItemToCart({
                locale: locale,
                payload: { sku, quantity },
              })
            )
          : dispatch(
              CartActions.guestAddItemToCart({
                locale: locale,
                payload: { sku, quantity, anonymId: authContext?.anonymId || '' },
              })
            );
      }
    } else {
      window.open(`${t('product.commerce.cart.add.link')}search?q=${sku ? sku : slug}`, '_ blank');
    }
  };

  return (
    <div className={classNames(styles['cta-area'], className)} data-testid={'cta-area'}>
      {callToAction.some((cta) => cta === 'request_for_more_information') && (
        <NextTranslateRouteLink href={`/appointment/${appointmentSlug}`}>
          <StandardButton label={t('product.detail.requestMoreInfo')} variant={Colors.brilliantBlue} />
        </NextTranslateRouteLink>
      )}
      {callToAction.some((cta) => cta === 'buy_now') &&
        (typeof window !== undefined && sku ? (
          <>
            {locale && PlatformWebappFeatureFlags.commerce.enable[locale] && (
              <Quantifier
                className={styles['quantifier']}
                variant={Colors.white}
                minimum={1}
                loading={cartLoading}
                onChange={(q) => setQuantity(q)}
              />
            )}
            <StandardButton
              label={t('product.commerce.cart.add')}
              variant={Colors.brilliantBlue}
              isLoading={!!(locale && PlatformWebappFeatureFlags.commerce.enable[locale]) && cartLoading}
              onClick={handleAddToCartClick}
            />
          </>
        ) : (
          <React.Fragment />
        ))}
      {callToAction.some((cta) => cta === 'make_appointment') && contactLink && contactLink !== '' && (
        <Link href={contactLink} target={'_self'}>
          <StandardButton label={t('product.detail.makeAppointment')} variant={Colors.brilliantBlue} />
        </Link>
      )}
      {callToAction.some((cta) => cta === 'compare') && !!slug && linkToComparePage !== '' && (
        <NextTranslateRouteLink href={linkToComparePage}>
          <StandardButton label={t('product.detail.compare')} variant={Colors.feelgoodMint} />
        </NextTranslateRouteLink>
      )}
      {!hideWishlist && PlatformWebappFeatureFlags.wishlistButton && (
        <RoundButton
          className={styles['bookmark']}
          variant={Colors.white}
          icon={<IconHeart />}
          fillOnHover={true}
          title={t('product.wishlist.add')}
        />
      )}
    </div>
  );
};

export default CtaArea;
