import {MappingConfig, ValueDataType} from '@dxp/akeneo-client';

/**
 * The interface for technical data from catalog.
 */
export interface ITechnicalData {
  code: string;
  data: string;
}

/**
 * Mapping configuration for ITechnicalData interface
 */
export const iTechnicalDataConfig: MappingConfig = {
  code: { path: ['code'], valueData: ValueDataType.direct, localized: false },
  data: { path: ['values', 'label'], valueData: ValueDataType.strings, localized: true },
};
