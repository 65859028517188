import classNames from 'classnames';
import styles from './feature.module.scss';

export interface FeatureProps {
  title: string;
  text: string;
}

export const Feature = (props: FeatureProps) => (
  <div className={styles['feature']}>
    <span className={classNames('subheading', styles['title'])} data-testid={props.title}>
      {props.title}
    </span>
    <span className={classNames('text-short', styles['text'])} data-testid={props.text}>
      {props.text}
    </span>
  </div>
);

export default Feature;
