import styles from './legal-navigation.module.scss';
import Link from 'next/link';
import React, { MouseEventHandler } from 'react';
import { NavigationsSelectors, TypeNavigationElementFields } from '@dxp/content';
import { useAppSelector } from '../../redux';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

export interface LegalNavigationProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

declare global {
  interface Window {
    Cookiebot: any;
  }
}

export function LegalNavigation(props: LegalNavigationProps) {
  const { t } = useTranslation('common');
  const legalNavigation: Array<TypeNavigationElementFields> | undefined = useAppSelector((state) =>
    NavigationsSelectors.getNavigation(state.navigations, 'legal')
  );

  const changeConsent = () => {
    window?.Cookiebot.renew();
  };

  return (
    <div className={classNames(styles['legal'], props.className)} data-testid="legal">
      {legalNavigation &&
        legalNavigation
          .filter((item) => !!item && item.link)
          .map((navItem) => (
            <Link
              href={navItem.link || ''}
              key={navItem.label}
              className={styles['legal__link']}
              onClick={props.onClick}
            >
              {navItem.label}
            </Link>
          ))}
      <button onClick={changeConsent} className={styles['legal__link']}>
        {t('footer.cookie.settings')}
      </button>
    </div>
  );
}

export default LegalNavigation;
