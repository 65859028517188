import { IPaymentDataRawResponse, IPaymentDataResponseParsed } from '../../interfaces';
import { AxiosResponse } from 'axios';

export const parsePaymentDataResponse = (
  response: AxiosResponse<IPaymentDataRawResponse | null>
): IPaymentDataResponseParsed | null => {
  if (!response || !response.data) {
    return null;
  }

  return {
    id: response.data?.data.id,
    type: response.data?.data.attributes.dataPayload.type,
    paymentIdentifier: response.data?.data.attributes.paymentIdentifier,
    hash: response.data?.data.attributes.dataPayload.hash,
  };
};
