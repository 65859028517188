import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { TApiResponseData } from '../types';
import errorHandler from '../error-handler';

export default {
  get: function get(http: AxiosInstance, url: string, config: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return http
      .get(url, {
        ...config,
      })
      .then((response: AxiosResponse<any>) => {
        return response;
      }, errorHandler);
  },

  post: function post(
    http: AxiosInstance,
    url: string,
    data: any,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    return http
      .post(url, data, {
        ...config,
      })
      .then((response: AxiosResponse<TApiResponseData>) => {
        return response;
      }, errorHandler);
  },

  patch: function patch(
    http: AxiosInstance,
    url: string,
    data: any,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    return http
      .patch(url, data, {
        ...config,
      })
      .then((response: AxiosResponse<TApiResponseData>) => {
        return response;
      }, errorHandler);
  },

  delete: function (http: AxiosInstance, url: string, config: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return http
      .delete(url, {
        ...config,
      })
      .then((response: AxiosResponse<TApiResponseData>) => {
        return response;
      }, errorHandler);
  },
};
