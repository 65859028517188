import styles from './contact-elements.module.scss';
import { TypeCeContactElementsFields } from '../../types';
import { ContactTeaser, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { motion } from 'framer-motion';

export function ContactElements({ items, headline }: TypeCeContactElementsFields) {
  return (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(styles['container'])}
      data-testid={'container'}
    >
      <h2 className={styles['headline']}>{headline}</h2>
      {items.length && (
        <div className={styles['items']}>
          {items.map((item) => (
            <ContactTeaser
              key={item.sys.id}
              className={styles['teaser']}
              layout={'vertical'}
              name={`${item.fields.firstName} ${item.fields.lastName}`}
              image={item.fields.imageUrl || ''}
              department={item.fields.title || ''}
              description={item.fields.bio || ''}
              phone={item.fields.phone || ''}
              email={item.fields.email || ''}
              linkedInActive={!!item.fields.linkedInUrl}
              linkedProfile={item.fields.linkedInUrl || ''}
            />
          ))}
        </div>
      )}
    </motion.div>
  );
}

export default ContactElements;
