import { CheckoutDataActions, sprykerClient } from '@dxp/commerce';
import { CustomSEOEvents, HubSpotActions, SeoActions, ToasterActions } from '@dxp/core';
import {
  IAddressItem,
  ICreateAddressRequest,
  ICustomerDataParsed,
  ICustomerFromCheckout,
  ICustomerLoginData,
  ICustomerProfile,
  ICustomerProfileIdentity,
  ICustomerProfilePassword,
  IForgotPasswordBodyAttribute,
  IRestorePasswordPayload,
} from '@dxp/spryker-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { i18n } from 'next-i18next';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { AddressActions, AuthActions, CustomerActions } from '../../index';

/**
 * The watcher function to request a customer.
 */
export function* watchFetchCustomer() {
  yield takeEvery(CustomerActions.customerRequested, getCustomerAsync);
}

/**
 * The worker function to request a customer.
 * @param action
 */
export function* getCustomerAsync(action: PayloadAction<{ customerRef: string; locale: string | undefined }>) {
  const { locale } = action.payload;

  const { customerRef } = action.payload;
  try {
    const result: ICustomerDataParsed | null = yield call(sprykerClient(locale).customer.byCustomerRef, {
      customerRef,
    });
    yield put(CustomerActions.customerReceived(result));
  } catch (error) {
    yield put(CustomerActions.customerRequestFailed());
  }
}

/**
 * The watcher function for updating customer action.
 */
export function* watchUpdateCustomer() {
  yield takeEvery(CustomerActions.updateCustomer, updateCustomerAsync);
}

/**
 * The worker function to update a customer.
 * @param action
 */
export function* updateCustomerAsync(
  action: PayloadAction<{ customerRef: string; payload: ICustomerProfileIdentity; locale: string | undefined }>
) {
  const { customerRef, payload, locale } = action.payload;

  try {
    ['gender'].forEach((attribute: string) => {
      if (payload[attribute as keyof typeof payload] === null || payload[attribute as keyof typeof payload] === '') {
        delete payload[attribute as keyof typeof payload];
      }
    });

    const result: ICustomerDataParsed | null = yield call(sprykerClient(locale).customer.update, {
      customerRef,
      payload,
    });
    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.updateCustomer.success.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.updateCustomer.success.body', { ns: 'account' }) || '',
        })
      ),
      put(CustomerActions.updateCustomerSuccess(result)),
    ]);
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.updateCustomer.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.updateCustomer.error.body', { ns: 'account' }) || '',
        })
      ),
      put(CustomerActions.updateCustomerError(error)),
      put(CustomerActions.customerRequested({ customerRef, locale })),
    ]);
  }
}

/**
 * The watcher function for updating a customers' password action.
 */
export function* watchUpdateCustomerPassword() {
  yield takeEvery(CustomerActions.updateCustomerPasswordRequested, updateCustomerPasswordAsync);
}

/**
 * The worker function to update a customers' password.
 * @param action
 */
export function* updateCustomerPasswordAsync(
  action: PayloadAction<{ customerRef: string; payload: ICustomerProfilePassword; locale: string | undefined }>
) {
  const { customerRef, payload, locale } = action.payload;

  try {
    const success: boolean | null = yield call(sprykerClient(locale).customer.updatePassword, {
      customerRef,
      payload,
    });
    if (success) {
      yield all([
        put(CustomerActions.updateCustomerPasswordReceived(success)),
        put(
          ToasterActions.add({
            type: 'success',
            title: i18n?.t('toast.updatePassword.success.title', { ns: 'account' }) || '',
            bodytext: i18n?.t('toast.updatePassword.success.body', { ns: 'account' }) || '',
          })
        ),
      ]);
    } else {
      yield all([
        put(CustomerActions.updateCustomerPasswordRequestFailed()),
        put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.updatePassword.error.title', { ns: 'account' }) || '',
            bodytext: i18n?.t('toast.updatePassword.error.body', { ns: 'account' }) || '',
            manualDismiss: true,
          })
        ),
      ]);
    }
  } catch (error) {
    yield all([
      put(CustomerActions.updateCustomerPasswordRequestFailed()),
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.updatePassword.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.updatePassword.error.body', { ns: 'account' }) || '',
          manualDismiss: true,
        })
      ),
    ]);
  }
}

/**
 * The watcher function for create customer action.
 */
export function* watchFetchCreateCustomer() {
  yield takeEvery(CustomerActions.createCustomerRequested, createCustomerAsync);
}

/**
 * The worker function to create customer.
 * @param action
 */
export function* createCustomerAsync(
  action: PayloadAction<{
    customer: ICustomerProfile | ICustomerFromCheckout;
    address: ICreateAddressRequest | null;
    locale: string | undefined;
    newsletter: boolean;
    anonymId: string;
  }>
) {
  const { customer, address, locale, newsletter, anonymId } = action.payload;

  try {
    // Create customer
    const customerResult: ICustomerDataParsed | null = yield call(sprykerClient(locale).customer.create, {
      customer,
      anonymId,
    });

    if (customerResult) {
      // we registered, let's tell the world about it!
      yield put(
        SeoActions.pushEvent({
          event: CustomSEOEvents.SIGN_UP,
          properties: {
            id: customerResult.id || '',
            salutation: customer.salutation || '',
            firstName: customer.firstName || '',
            lastName: customer.lastName || '',
            email: customer.email || '',
            company: customer.company || '',
            phone: 'phone' in customer ? customer.phone || '' : '',
            address1: address?.attributes.address1 || '',
            address2: address?.attributes.address1 || '',
            zipCode: address?.attributes.zipCode || '',
            city: address?.attributes.city || '',
            iso2code: address?.attributes.iso2Code || '',
          },
        })
      );
      // we registered, now register on HubSpot
      yield put(
        HubSpotActions.registerHubSpotUser({
          customer: {
            acceptedTerms: customer.acceptedTerms,
            address1: address?.attributes.address1,
            address2: address?.attributes.address2,
            city: address?.attributes.city,
            company: customer.company,
            country: address?.attributes.country,
            email: customer.email,
            firstName: customer.firstName,
            lastName: customer.lastName,
            newsletter: !!newsletter, //do not change, value needs to be explicitly false if checkbox is not checked
            phone: 'phone' in customer ? customer.phone || '' : '',
            salutation: customer.salutation,
            zipCode: address?.attributes.zipCode,
          },
        })
      );

      // Get access token
      const loginPayload: ICustomerLoginData = { username: customer.email, password: customer.password, anonymId };
      const customerRef: string | null = yield call(sprykerClient(locale).auth.login, { payload: loginPayload });
      if (customerRef) {
        yield put(CheckoutDataActions.clearState());
        yield put(AuthActions.customerLoginReceived(customerRef));
        if (address) {
          // Create address
          const addressResult: IAddressItem | null = yield call(sprykerClient(locale).address.create, {
            payload: { ...address, customerRef },
          });
          if (addressResult) {
            yield put(AddressActions.createAddressSuccess(addressResult));
          } else {
            yield put(
              ToasterActions.add({
                type: 'warning',
                title: i18n?.t('toast.register.error.createAddress.title', { ns: 'account' }) || '',
                bodytext: i18n?.t('toast.register.error.createAddress.body', { ns: 'account' }) || '',
                manualDismiss: true,
              })
            );
          }
        }
      } else {
        yield put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.register.error.accessToken.title', { ns: 'account' }) || '',
            bodytext: i18n?.t('toast.register.error.accessToken.body', { ns: 'account' }) || '',
            manualDismiss: true,
          })
        );
      }
      yield put(CustomerActions.createCustomerReceived(customerResult));
    } else {
      yield put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.register.error.createCustomer.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.register.error.createCustomer.body', { ns: 'account' }) || '',
          manualDismiss: true,
        })
      );
      console.log('i18n i18n', i18n);
      yield put(CustomerActions.createCustomerRequestFailed());
    }
  } catch (error) {
    yield put(
      ToasterActions.add({
        type: 'warning',
        title: i18n?.t('toast.register.error.createCustomer.title', { ns: 'account' }) || '',
        bodytext: i18n?.t('toast.register.error.createCustomer.body', { ns: 'account' }) || '',
        manualDismiss: true,
      })
    );
    yield put(CustomerActions.createCustomerRequestFailed());
  }
}

/**
 * The watcher function for user logouts.
 */
export function* watchCustomerForgotPassword() {
  yield takeEvery(CustomerActions.customerForgotPassword, customerForgotPassword);
}

/**
 * The worker function to handle logouts.
 */
export function* customerForgotPassword(
  action: PayloadAction<IForgotPasswordBodyAttribute & { locale: string | undefined }>
) {
  const { locale } = action.payload;

  try {
    const payload = action.payload;
    delete payload.locale;
    yield call(sprykerClient(locale).auth.forgotPassword, { payload });

    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.forgotPassword.success.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.forgotPassword.success.body', { ns: 'account' }) || '',
        })
      ),
      put(CustomerActions.customerForgotPasswordSuccess()),
    ]);
  } catch (error: any) {
    yield all([
      put(CustomerActions.customerForgotPasswordError(error)),
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.forgotPassword.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.forgotPassword.error.body', { ns: 'account' }) || '',
        })
      ),
    ]);
  }
}

/**
 * The watcher function for password restore request.
 */
export function* watchCustomerRestorePassword() {
  yield takeEvery(CustomerActions.customerRestorePassword, customerRestorePassword);
}

/**
 * The worker function to handle password restore request.
 */
export function* customerRestorePassword(
  action: PayloadAction<IRestorePasswordPayload & { locale: string | undefined }>
) {
  const { locale } = action.payload;

  try {
    const payload = action.payload;
    delete payload.locale;
    yield call(sprykerClient(locale).auth.restorePassword, { payload });
    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.restorePassword.success.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.restorePassword.success.body', { ns: 'account' }) || '',
        })
      ),
      put(CustomerActions.customerRestorePasswordSuccess()),
    ]);
  } catch (error: any) {
    yield all([
      put(CustomerActions.customerRestorePasswordError(error)),
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.restorePassword.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.restorePassword.error.body', { ns: 'account' }) || '',
        })
      ),
    ]);
  }
}
