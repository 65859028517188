import {
  NavigationsSelectors,
  ThreeColumnTeaser,
  TypeNavigationElement,
  TypeNavigationElementFields,
} from '@dxp/content';
import { BlackBackdrop } from '@dxp/core';
import { Colors, IconArrowLong, IconButton, IconClose, ScreenSizes, useScreenSizes } from '@dxp/veneer';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { LocaleSwitch } from '../../../components/locale-switch/locale-switch';
import { MainNavigationActions, MainNavigationsSelectors, useAppDispatch, useAppSelector } from '../../../redux';
import Level1Item from './level-1-item/level-1-item';
import Level2Cms from './level-2-cms/level-2-cms';
import Level2Pim from './level-2-pim/level-2-pim';
import MainNavigationFooter from './main-navigation-footer/main-navigation-footer';
import MainNavigationSidebar from './main-navigation-sidebar/main-navigation-sidebar';
import styles from './main-navigation.module.scss';

export const MainNavigation = () => {
  const [hoveredLevel1, setHoveredLevel1] = useState<string | null>(null);
  const [subItems, setSubItems] = useState<{ current: string; level: number; items: TypeNavigationElement[] }>({
    current: '',
    level: 0,
    items: [],
  });

  interface ExtendedTypeNavigationElementFields extends TypeNavigationElementFields {
    teaserLinkText?: string;
    teaserTitle?: string;
    teaserText?: string;
    teaserImageUrl?: string;
    teaserLinkUrl?: string;
  }

  const screenSize = useScreenSizes();
  const dispatch = useAppDispatch();
  const items: Array<ExtendedTypeNavigationElementFields> | undefined = useAppSelector((state) =>
    NavigationsSelectors.getNavigation(state.navigations, 'mainnavigation')
  );

  const isOpen: boolean = useAppSelector((state) =>
    MainNavigationsSelectors.mainNavigationIsOpen(state.mainNavigation)
  );
  const level1: string | null = useAppSelector((state) => MainNavigationsSelectors.level1(state.mainNavigation));

  const mainTransition = { ease: 'easeInOut', duration: 0.4 };

  useEffect(() => {
    // Body Scroll Fix
    if (typeof window !== 'undefined') {
      if (isOpen) {
        // Timeout for smoother animation
        const timer = setTimeout(() => {
          document.body.style.position = 'fixed';
        }, 400);
        return () => clearTimeout(timer);
      } else {
        document.body.style.position = 'static';
      }
    }
  }, [isOpen]);

  const itemsPerPage = screenSize === ScreenSizes.MEDIUM ? 4 : screenSize === ScreenSizes.LARGE ? 5 : 0;
  const calculateSubLevel = useCallback(
    (i: number) => Math.ceil(i / (itemsPerPage > 0 ? itemsPerPage : 1)),
    [itemsPerPage]
  );
  const onLevel1 = (el: TypeNavigationElementFields) => {
    selectSubLevel();
    dispatch(level1 === el.label ? MainNavigationActions.leaveLevel1() : MainNavigationActions.enterLevel1(el.label));
  };
  const selectSubLevel = (el?: TypeNavigationElement, index?: number) => {
    setSubItems((state) =>
      el && index && state.current !== el.sys.id
        ? {
            current: el.sys.id,
            level: calculateSubLevel(index),
            items: el.fields.children ?? [],
          }
        : {
            current: '',
            level: 0,
            items: [],
          }
    );
  };

  const clearSelection = () => {
    dispatch(MainNavigationActions.closeMainNavigation());
    setSubItems({
      current: '',
      level: 0,
      items: [],
    });
  };

  const renderSubItems = (index: number, imageLayout: string) => (
    <motion.div
      animate={{
        height: subItems.level === calculateSubLevel(index + 1) ? 'auto' : 0,
        display: subItems.level === calculateSubLevel(index + 1) ? 'block' : 'none',
      }}
      className={styles['nav-inner']}
    >
      <div className={classNames(styles['level-2__nav'], styles['level-2__nav--sub'])}>
        {renderItems(subItems.items, imageLayout, false)}
      </div>
    </motion.div>
  );

  const renderItems = (navItems: TypeNavigationElement[], imageLayout: string, allowRender = true) =>
    navItems?.map((el, index) => (
      <>
        <div className={styles['level-2__link']} key={el.sys.id}>
          {imageLayout === 'cms' ? (
            <Level2Cms
              {...el.fields}
              href={!el.fields?.children ? el.fields.link || '/' : null}
              key={el.fields.label}
              onClick={() => (!el.fields?.children ? clearSelection() : selectSubLevel(el, index + 1))}
            />
          ) : (
            <Level2Pim
              {...el.fields}
              href={!el.fields?.children ? el.fields.link || '/' : null}
              key={el.fields.label}
              onClick={() => (!el.fields?.children ? clearSelection() : selectSubLevel(el, index + 1))}
              shy={
                allowRender &&
                screenSize >= ScreenSizes.MEDIUM &&
                subItems.current !== '' &&
                subItems.current !== el.sys.id
              }
              isClicked={screenSize >= ScreenSizes.MEDIUM && subItems.current === el.sys.id}
            />
          )}
          {allowRender &&
            screenSize < ScreenSizes.MEDIUM &&
            el.fields?.children?.length &&
            renderSubItems(index, imageLayout)}
        </div>

        {allowRender &&
          screenSize >= ScreenSizes.MEDIUM &&
          ((index + 1) / itemsPerPage === calculateSubLevel(index + 1) || navItems.length === index + 1) &&
          renderSubItems(index, imageLayout)}
      </>
    ));

  const renderTeaser = () => {
    const selectedItem = items?.find((el) => el.label === level1);
    return (
      selectedItem &&
      selectedItem.teaserLinkText && (
        <ThreeColumnTeaser
          title={selectedItem.teaserTitle}
          text={selectedItem.teaserText}
          imageUrl={selectedItem.teaserImageUrl}
          linkText={selectedItem.teaserLinkText}
          link={selectedItem.teaserLinkUrl}
          className={styles['teaser']}
          onClick={() => dispatch(MainNavigationActions.closeMainNavigation())}
        />
      )
    );
  };

  return (
    <AnimatePresence>
      {isOpen ? (
        <BlackBackdrop key={'backdrop'}>
          <motion.nav className={styles['menu']}>
            <div className={styles['level-1']}>
              <motion.div
                className={styles['level-1__nav']}
                layout
                key={'level1'}
                animate={level1 === null ? { marginLeft: '8.33%' } : { marginLeft: '0%' }}
                transition={mainTransition}
              >
                {items &&
                  !!items.length &&
                  items.map((el) =>
                    el.children && el.children?.length > 0 ? (
                      <Level1Item
                        label={el.label}
                        active={level1 === el.label}
                        shy={(hoveredLevel1 !== null && hoveredLevel1 !== el.label) || level1 !== null}
                        hasChildren={true}
                        key={el.label}
                        onMouseEnter={() => setHoveredLevel1(el.label)}
                        onMouseLeave={() => setHoveredLevel1(null)}
                        onClick={() => onLevel1(el)}
                      />
                    ) : (
                      <Level1Item
                        href={el.link || '/'}
                        key={el.label}
                        label={el.label}
                        active={false}
                        hasChildren={false}
                        shy={level1 !== null || (hoveredLevel1 !== null && hoveredLevel1 !== el.label)}
                        onMouseEnter={() => setHoveredLevel1(el.label)}
                        onMouseLeave={() => setHoveredLevel1(null)}
                        onClick={() => dispatch(MainNavigationActions.closeMainNavigation())}
                      />
                    )
                  )}
              </motion.div>

              <MainNavigationSidebar key={'sidebar'} className={styles['sidebar']} />
            </div>
            <AnimatePresence mode={'popLayout'}>
              {level1 !== null && (
                <motion.div
                  key="level2"
                  className={classNames(
                    styles['level-2'],
                    items?.find((el) => el.label === level1)?.imageLayout === 'cms'
                      ? styles['level-2--cms']
                      : styles['level-2--pim']
                  )}
                  animate={{ x: '0%' }}
                  exit={{ x: '110%' }}
                  initial={{ x: '110%' }}
                  transition={mainTransition}
                >
                  <div className={styles['back']}>
                    <button
                      className={styles['back__button']}
                      onClick={() => dispatch(MainNavigationActions.leaveLevel1())}
                    >
                      <div className={styles['back__icon']}>
                        <IconArrowLong color={Colors.intenseBlue} className={styles['back__svg']} />
                      </div>
                      <span className={classNames(styles['back__label'], 'heading-4')}>{level1}</span>
                    </button>
                  </div>

                  <div className={styles['level-2__nav']}>
                    {items &&
                      !!items.length &&
                      renderItems(
                        items.find((el) => el.label === level1)?.children ?? [],
                        items?.find((el) => el.label === level1)?.imageLayout ?? ''
                      )}
                  </div>
                  {renderTeaser()}
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>{level1 === null && <MainNavigationFooter key={'footer'} />}</AnimatePresence>

            <AnimatePresence>{level1 === null && <LocaleSwitch className={styles['locale-switch']} />}</AnimatePresence>

            <IconButton
              icon={
                <IconClose
                  color={
                    screenSize === ScreenSizes.LARGE
                      ? level1 !== null
                        ? Colors.intenseBlue
                        : Colors.brilliantBlue
                      : Colors.intenseBlue
                  }
                />
              }
              className={styles['close']}
              onClick={() => clearSelection()}
            />
          </motion.nav>
        </BlackBackdrop>
      ) : null}
    </AnimatePresence>
  );
};

export default MainNavigation;
