import { createSelector } from '@reduxjs/toolkit';
import { MainNavigationState } from './slice';

const selectMainNavigationState = (state: MainNavigationState) => state;

const mainNavigationIsOpen = createSelector(
  [selectMainNavigationState, (state: MainNavigationState) => state && state.mainNavigationOpen],
  (state: MainNavigationState) => state.mainNavigationOpen
);

const level1 = createSelector(
  [selectMainNavigationState, (state: MainNavigationState) => state && state.mainNavigationLevel1],
  (state: MainNavigationState) => state.mainNavigationLevel1
);

const localeOverlayIsOpen = createSelector(
  [selectMainNavigationState, (state: MainNavigationState) => state && state.localeOverlayIsOpen],
  (state: MainNavigationState) => state.localeOverlayIsOpen
);

export const MainNavigationsSelectors = {
  mainNavigationIsOpen,
  level1,
  localeOverlayIsOpen,
};
