import styles from './cart-flyout.module.scss';
import { BlackBackdrop } from '@dxp/core';
import { CartActions } from '../../redux';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import React from 'react';
import { Colors, IconButton, IconClose } from '@dxp/veneer';
import Cart from '../cart/cart';

export function CartFlyout() {
  const dispatch = useDispatch();

  return (
    <BlackBackdrop onClick={() => dispatch(CartActions.closeCartFlyout())}>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className={styles['container']}
      >
        <Cart layout={'flyout'} />
        <IconButton
          className={styles['close']}
          icon={<IconClose color={Colors.intenseBlue} />}
          onClick={() => dispatch(CartActions.closeCartFlyout())}
        />
      </motion.div>
    </BlackBackdrop>
  );
}

export default CartFlyout;
