import styles from './media-gallery-thumb.module.scss';
import { motion } from 'framer-motion';

export interface MediaGalleryThumbProps {
  activeIndex: number;
  index: number;
  imageUrl: string | undefined;
  caption: string | undefined;
}

export function MediaGalleryThumb(props: MediaGalleryThumbProps) {
  return (
    <div className={styles['container']}>
      <motion.img
        initial={{ opacity: 0.5 }}
        animate={{ opacity: props.activeIndex === props.index ? 1 : 0.5 }}
        src={props.imageUrl}
        alt={props.caption}
        data-testid={'thumb'}
      />
    </div>
  );
}

export default MediaGalleryThumb;
