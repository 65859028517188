import styles from './level-2-cms.module.scss';
import { TypeNavigationElementFields } from '@dxp/content';
import Link from 'next/link';
import { MouseEventHandler, useState } from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { isExternalURL } from '@dxp/core';

export const Level2Cms = ({
  href,
  label,
  shy,
  imageUrl,
  onClick,
  onMouseLeave,
  onMouseEnter,
}: TypeNavigationElementFields & {
  href?: string | null;
  shy?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const inner = (
    <motion.span
      className={styles['button']}
      onClick={onClick}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      animate={isHovered ? { opacity: 1 } : shy ? { opacity: 0.4 } : { opacity: 1 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      {imageUrl && <Image src={imageUrl} alt={label} className={styles['button__image']} width={556} height={373} />}
      <span className={styles['button__static-shade']}></span>
      <motion.span
        animate={isHovered ? { height: '100%' } : { height: '0%' }}
        transition={{ ease: 'easeInOut', duration: 0.2 }}
        className={styles['button__shade']}
      ></motion.span>

      <span className={styles['button__label']}>{label}</span>
    </motion.span>
  );

  return href ? (
    <Link
      href={href}
      className={styles['link']}
      target={isExternalURL(href) ? '_blank' : '_self'}
      rel={isExternalURL(href) ? 'noopener' : ''}
    >
      {inner}
    </Link>
  ) : (
    <span className={styles['link']}>{inner}</span>
  );
};

export default Level2Cms;
