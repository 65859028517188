import styles from './icon.module.scss';
import { Colors, Rotation } from '../../../types';
import classNames from 'classnames';
import { useMemo } from 'react';

export interface IconProps {
  children?: any;
  className?: string;
  color?:
    | Colors.brilliantBlue
    | Colors.shinyYellow
    | Colors.feelgoodMint
    | Colors.smartBlue
    | Colors.intenseBlue
    | Colors.black
    | Colors.white
    | Colors.lightGrey
    | Colors.middleGrey
    | Colors.error;
  rotation?: Rotation;
  filled?: boolean;
  unstyled?: boolean;
}

export const Icon = (
  {
    children,
    className,
    color = Colors.smartBlue,
    unstyled = false,
    filled = false,
    rotation = 0,
    ...otherProps
  }: IconProps,
  forwardedRef: React.Ref<any>
) => {
  const classes: string = useMemo(
    () =>
      classNames(
        styles['icon-wrapper'],
        styles[color],
        className,
        { [styles['filled']]: filled },
        { [styles['unstyled']]: unstyled },
        { [styles['rotate--' + rotation]]: rotation }
      ),
    [color, unstyled, filled, rotation, className]
  );

  return (
    <span {...otherProps} className={classes}>
      {children}
    </span>
  );
};
