import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CartActions } from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { sprykerClient } from '../../../index';
import { ICart, IGuestAddItemToCart, IGuestChangeItemQuantity, IGuestDeleteItem } from '@dxp/spryker-client';
import { ToasterActions } from '@dxp/core';
import { i18n } from 'next-i18next';

// /**
//  * The watcher function to add a custom order reference to a guest cart
//  */
// export function* watchAddCustomOrderReferenceToGuestCart() {
//   yield takeEvery(CartActions.guestAddCustomOrderReference, addCustomOrderReferenceToCartAsync);
// }

/**
 * The watcher function to fetch carts from a guest user
 */
export function* watchFetchGuestCarts() {
  yield takeEvery(CartActions.guestCartsRequested, fetchGuestCartsAsync);
}

/**
 * The worker function to fetch carts from a guest user
 * @param action
 */
export function* fetchGuestCartsAsync(action: PayloadAction<{ locale: string; anonymId: string }>) {
  try {
    const { locale, anonymId } = action.payload;

    const result: Array<ICart> | null = yield call(sprykerClient(locale).guestCart.get, { anonymId });

    yield put(CartActions.guestCartsReceived(result));
  } catch (error: any) {
    yield put(CartActions.guestCartsRequestFailed(error));
  }
}

/**
 * The watcher function to add an item in a cart from a guest user
 */
export function* watchAddItemToGuestCart() {
  yield takeEvery(CartActions.guestAddItemToCart, guestAddItemToCartAsync);
}

/**
 * The worker function to add an item in a cart from a guest user
 * @param action
 */
export function* guestAddItemToCartAsync(action: PayloadAction<{ locale: string; payload: IGuestAddItemToCart }>) {
  try {
    const { locale, payload } = action.payload;

    const result: ICart | null = yield call(sprykerClient(locale).guestCart.addItem, payload);

    if (result) {
      yield all([
        put(
          ToasterActions.add({
            type: 'success',
            title: i18n?.t('toast.addItem.success.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addItem.success.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.guestAddItemToCartSuccess(result)),
      ]);
    } else {
      yield all([
        put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.addItem.error.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addItem.error.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.guestAddItemToCartFail('No result from addItem')),
      ]);
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.addItem.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.addItem.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.guestAddItemToCartFail('No result from addItem')),
    ]);
  }
}

/**
 * The watcher function to add multiple items to a guest user's cart
 */
export function* watchAddItemsToGuestCart() {
  yield takeEvery(CartActions.guestAddItemsToCart, guestAddItemsToCartAsync);
}

/**
 * The worker function to add multiple items to a guest user's cart
 * @param action
 */
export function* guestAddItemsToCartAsync(
  action: PayloadAction<{ locale: string; payload: Array<IGuestAddItemToCart> }>
) {
  try {
    const { locale, payload } = action.payload;

    const result: ICart | null = yield call(sprykerClient(locale).guestCart.addItems, payload);

    if (result) {
      yield all([
        put(
          ToasterActions.add({
            type: 'success',
            title: i18n?.t('toast.addItems.success.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addItems.success.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.guestAddItemsToCartSuccess(result)),
      ]);
    } else {
      yield all([
        put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.addItems.error.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addItems.error.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.guestAddItemsToCartFail('No result from addItems')),
      ]);
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.addItems.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.addItems.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.guestAddItemsToCartFail('No result from addItems')),
    ]);
  }
}

/**
 * The watcher function to delete an item in a cart from a guest user
 */
export function* watchGuestDeleteCartItem() {
  yield takeEvery(CartActions.guestDeleteItem, guestDeleteItemWorker);
}

/**
 * The worker function to delete an item in a cart from a guest user
 * @param action
 */
export function* guestDeleteItemWorker(action: PayloadAction<{ locale: string; payload: IGuestDeleteItem }>) {
  try {
    const { locale, payload } = action.payload;

    yield call(sprykerClient(locale).guestCart.deleteItem, payload);

    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.deleteItem.success.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.deleteItem.success.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.guestCartsRequested({ locale, anonymId: payload.anonymId })),
    ]);
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.deleteItem.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.deleteItem.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.deleteItemFailed(error)),
    ]);
  }
}

/**
 * The watcher function to change item quantity of an item in a cart from a guest user
 */
export function* watchGuestChangeItemQuantity() {
  yield takeEvery(CartActions.guestChangeItemQuantity, changeGuestItemQuantityWorker);
}

/**
 * The worker function to change item quantity of an item in a cart from a guest user
 * @param action
 */
export function* changeGuestItemQuantityWorker(
  action: PayloadAction<{ locale: string; payload: IGuestChangeItemQuantity }>
) {
  try {
    const { locale, payload } = action.payload;

    const result: ICart | null = yield call(sprykerClient(locale).guestCart.changeItemQuantity, payload);

    if (result) {
      yield put(CartActions.guestChangeItemQuantitySuccess(result));
    } else {
      yield put(CartActions.guestChangeItemQuantityFailed('No result from changeQuantity'));
    }
  } catch (error: any) {
    yield put(CartActions.guestChangeItemQuantityFailed(error));
  }
}

/**
 * The watcher function to add voucher to guest cart
 */
export function* watchGuestAddVoucher() {
  yield takeEvery(CartActions.guestAddVoucher, guestAddVoucherWorker);
}

/**
 * The worker function to add a voucher to the guest cart.
 *
 * @generator
 * @param {PayloadAction<{
 *   locale: string;
 *   payload: {
 *     cartId: string;
 *     voucherCode: string;
 *     anonymId: string;
 *   }
 * }>} action - The Redux action containing the voucher details and the locale.
 *
 * @yields {allEffect} - A Redux-saga all effect that dispatches two actions:
 * - update the cart data with the new voucher
 * - show a success toast message.
 * @throws {Error} If the voucher addition process fails.
 */
export function* guestAddVoucherWorker(
  action: PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string; anonymId: string } }>
) {
  try {
    const { locale, payload } = action.payload;

    const result: ICart | null = yield call(sprykerClient(locale).guestCart.addVoucher, payload);

    if (result) {
      yield all([
        put(
          ToasterActions.add({
            type: 'success',
            title: i18n?.t('toast.addVoucher.success.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addVoucher.success.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.addVoucherSuccess(result)),
      ]);
    } else {
      yield all([
        put(
          ToasterActions.add({
            type: 'warning',
            title: i18n?.t('toast.addVoucher.error.title', { ns: 'cart' }) || '',
            bodytext: i18n?.t('toast.addVoucher.error.body', { ns: 'cart' }) || '',
          })
        ),
        put(CartActions.addVoucherError('no result')),
      ]);
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.addVoucher.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.addVoucher.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.addVoucherError(error)),
    ]);
  }
}

/**
 * The watcher function to delete voucher from guest cart
 */
export function* watchGuestDeleteVoucher() {
  yield takeEvery(CartActions.guestDeleteVoucher, guestDeleteVoucherWorker);
}

/**
 * The worker function to delete voucher from the guest cart
 * @generator
 * @param {PayloadAction<{
 *   locale: string;
 *   payload: {
 *     cartId: string;
 *     voucherCode: string;
 *     anonymId: string;
 *   }
 * }>} action - The Redux action containing the voucher details and the locale.
 *
 * @yields {allEffect} - A Redux-saga all effect that dispatches three actions:
 * - remove the voucher from the cart data
 * - show a success toast message
 * - request the updated cart data.
 * @throws {Error} If the voucher deletion process fails.
 */
export function* guestDeleteVoucherWorker(
  action: PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string; anonymId: string } }>
) {
  try {
    const { locale, payload } = action.payload;

    yield call(sprykerClient(locale).guestCart.deleteVoucher, payload);

    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.guestDeleteVoucher.success.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.guestDeleteVoucher.success.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.guestDeleteVoucherSuccess()),
      put(CartActions.guestCartsRequested({ locale, anonymId: payload.anonymId })),
    ]);
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.guestDeleteVoucher.error.title', { ns: 'cart' }) || '',
          bodytext: i18n?.t('toast.guestDeleteVoucher.error.body', { ns: 'cart' }) || '',
        })
      ),
      put(CartActions.guestDeleteVoucherError(error)),
    ]);
  }
}

// /**
//  * The worker function to add a custom order reference to a guest cart
//  * @param action
//  */
// export function* addCustomOrderReferenceToCartAsync(
//   action: PayloadAction<{ locale: string; payload: { cartId: string; customOrderReference: string; anonymId: string } }>
// ) {
//   console.log('guest addCustomOrderReferenceToCartAsync');
//   try {
//     const { locale, payload } = action.payload;
//
//     const result: ICart | null = yield call(sprykerClient(locale).guestCart.addCustomOrderReference, payload);
//
//     if (result) {
//       yield all([
//         put(
//           ToasterActions.add({
//             type: 'success',
//             title: i18n?.t('toast.addCustomOrderReference.success.title', { ns: 'cart' }) || '',
//             bodytext: i18n?.t('toast.addCustomOrderReference.success.body', { ns: 'cart' }) || '',
//           })
//         ),
//         put(CartActions.addCustomOrderReferenceSuccess(result)),
//       ]);
//     } else {
//       yield all([
//         put(
//           ToasterActions.add({
//             type: 'warning',
//             title: i18n?.t('toast.addCustomOrderReference.error.title', { ns: 'cart' }) || '',
//             bodytext: i18n?.t('toast.addCustomOrderReference.error.body', { ns: 'cart' }) || '',
//           })
//         ),
//         put(CartActions.addCustomOrderReferenceError('no result')),
//       ]);
//     }
//   } catch (error: any) {
//     yield all([
//       put(
//         ToasterActions.add({
//           type: 'warning',
//           title: i18n?.t('toast.addCustomOrderReference.error.title', { ns: 'cart' }) || '',
//           bodytext: i18n?.t('toast.addCustomOrderReference.error.body', { ns: 'cart' }) || '',
//         })
//       ),
//       put(CartActions.addCustomOrderReferenceError(error)),
//     ]);
//   }
// }
