import { createSelector } from '@reduxjs/toolkit';
import { AuthErrors, AuthState } from './slice';
import { localStorageKey } from '@dxp/spryker-client';

const selectAuthState = (state: AuthState) => state;

const isLoading = createSelector(selectAuthState, (state: AuthState) => state.loading);

// TODO: This needs to be refined. Currently, a simple check if auth data are set in localStorage.
const isAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state && state.customerRef && !!localStorage.getItem(localStorageKey)
);

const selectCustomerRef = createSelector(selectAuthState, (state: AuthState) => state.customerRef);

const selectAnonymId = createSelector(selectAuthState, (state: AuthState) => state.anonymId);

const selectError = createSelector(
  [selectAuthState, (state: AuthState, actionType: keyof AuthErrors) => actionType],
  (state: AuthState, actionType: keyof AuthErrors) =>
    state.error && Object.prototype.hasOwnProperty.call(state.error, actionType) ? state.error[actionType] : null
);

export const AuthSelectors = {
  selectAuthState,
  isAuthenticated,
  isLoading,
  selectCustomerRef,
  selectError,
  selectAnonymId,
};
