import styles from './response-panel.module.scss';
import classNames from 'classnames';
import { Colors, getIcon } from '../../../types';
import RoundButton from '../../interaction/round-button/round-button';

export enum ResponsePanelStatus {
  SUCCESS = 'Check',
}

export interface ResponsePanelProps {
  statusIcon?: ResponsePanelStatus;
  headline: string;
  text?: string;
  children?: React.ReactElement[] | React.ReactElement;
}

export const ResponsePanel = (props: ResponsePanelProps & { className?: string }) => {
  const IconComponent = getIcon(props.statusIcon || 'Close');
  return (
    <div className={classNames(styles['container'], props.className)}>
      {props.statusIcon && (
        <RoundButton
          variant={Colors.feelgoodMint}
          size={'medium'}
          icon={<IconComponent />}
          className={styles['icon']}
          data-testid={'icon'}
        />
      )}
      <div className={classNames('heading-4', styles['headline'])} data-testid={'headline'}>
        {props.headline}
      </div>
      <p className={classNames('text-long', styles['text'])} data-testid={'text'}>
        {props.text}
      </p>
      {props.children}
    </div>
  );
};

export default ResponsePanel;
