import { CartState, CheckoutDataActions, CheckoutDataState, sprykerClient } from '@dxp/commerce';
import { all, call, put, PutEffect, select, takeEvery } from 'redux-saga/effects';
import { AddressActions, AddressState, AuthState, CustomerState } from '../../index';
import {
  IAddressItem,
  ICreateAddressRequest,
  ICustomerDataParsed,
  IDeleteAddressRequest,
  IPatchAddressRequest,
} from '@dxp/spryker-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlatformWebappStaticRoutes, ToasterActions } from '@dxp/core';
import { i18n } from 'next-i18next';
import { push } from 'connected-next-router';

/**
 * The watcher function to fetch addresses.
 */
export function* watchFetchAddresses() {
  yield takeEvery(AddressActions.addressesRequested, fetchAddressesAsync);
}

/**
 * The worker function to fetch addresses.
 */
export function* fetchAddressesAsync(action: PayloadAction<{ locale: string | undefined }>) {
  const { locale } = action.payload;

  try {
    const customerRefSelector: (state: { auth: AuthState }) => string | null = (state: { auth: AuthState }) =>
      state.auth.customerRef;
    const customerRef: string = yield select(customerRefSelector);

    const result: Array<IAddressItem> | null = yield call(sprykerClient(locale).address.byCustomerRef, {
      customerRef,
    });
    if (result) {
      yield put(AddressActions.addressesReceived(result));
    } else {
      yield put(AddressActions.addressesRequestFailed({ customError: 'no-result' }));
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.getAddresses.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.getAddresses.error.body', { ns: 'account' }) || '',
        })
      ),
      put(AddressActions.addressesRequestFailed(error)),
    ]);
  }
}

/**
 * The watcher function to create an address.
 */
export function* watchCreateAddress() {
  yield takeEvery(AddressActions.createAddress, createAddressAsync);
}

/**
 * The worker function to create an address.
 */
export function* createAddressAsync(
  action: PayloadAction<
    ICreateAddressRequest & { checkoutStep?: 'billing' | 'shipping'; return?: boolean; locale: string | undefined }
  >
) {
  const { locale } = action.payload;

  if (!action.payload.customerRef) {
    const customerRefSelector: (state: { auth: AuthState }) => string | null = (state: { auth: AuthState }) =>
      state.auth.customerRef;
    action.payload.customerRef = yield select(customerRefSelector);
  }

  try {
    const result: IAddressItem = yield call(sprykerClient(locale).address.create, { payload: action.payload });
    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.createAddress.success.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.createAddress.success.body', { ns: 'account' }) || '',
        })
      ),
      put(AddressActions.createAddressSuccess(result)),
    ]);
    if (action.payload.return) {
      yield put(push(PlatformWebappStaticRoutes.account.addresses));
    }
    //If an address is added via checkout -> activate different checkout actions
    if (action.payload.checkoutStep) {
      const customerSelector: (state: { customer: CustomerState }) => ICustomerDataParsed | null = (state: {
        customer: CustomerState;
      }) => state.customer.customer;
      const addressesSelector: (state: { address: AddressState }) => Array<IAddressItem> | null = (state: {
        address: AddressState;
      }) => state.address.addresses;
      const selectBillingAddressId: (state: { checkoutData: CheckoutDataState }) => string = (state: {
        checkoutData: CheckoutDataState;
      }) => state.checkoutData.selectedBillingAddressId;
      const selectShippingAddressId: (state: { checkoutData: CheckoutDataState }) => string = (state: {
        checkoutData: CheckoutDataState;
      }) => state.checkoutData.selectedShippingAddressId;
      const selectCartId: (state: { cart: CartState }) => string | null = (state: { cart: CartState }) =>
        state.cart.carts?.find((cart) => cart.isDefault)?.id || null;
      const customer: ICustomerDataParsed | null = yield select(customerSelector);
      const addresses: Array<IAddressItem> | null = yield select(addressesSelector);
      const billingAddressId: string = yield select(selectBillingAddressId);
      const shippingAddressId: string = yield select(selectShippingAddressId);
      const cartId: string | null = yield select(selectCartId);
      let billingAddress: IAddressItem | undefined;
      let shippingAddress: IAddressItem | undefined;
      if (action.payload.checkoutStep === 'billing') {
        yield put(CheckoutDataActions.setBillingAddressId(result.id ?? ''));
        billingAddress = result;
        //This is the case if we chose to use billing address for shipping or if we are a new user without address
        if (billingAddressId === shippingAddressId || (billingAddressId === '' && shippingAddressId === '')) {
          yield put(CheckoutDataActions.setShippingAddressId(result.id ?? ''));
          shippingAddress = result;
        } else {
          shippingAddress = addresses?.find((address) => address.id === shippingAddressId);
        }
      } else {
        yield put(CheckoutDataActions.setShippingAddressId(result.id ?? ''));
        billingAddress = addresses?.find((address) => address.id === billingAddressId);
        shippingAddress = result;
      }
      yield put(
        CheckoutDataActions.checkoutDataRequested({
          locale: locale ?? '',
          checkoutDataRequestAttributes: {
            idCart: cartId || '',
            customer: {
              salutation: customer?.salutation || '',
              firstName: customer?.firstName || '',
              lastName: customer?.lastName || '',
              email: customer?.email || '',
            },
            billingAddress,
            shippingAddress,
          },
          anonymId: null,
        })
      );
    }
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.createAddress.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.createAddress.error.body', { ns: 'account' }) || '',
        })
      ),
      put(AddressActions.createAddressFailed(error)),
    ]);
  }
}

/**
 * The watcher function to update an address.
 */
export function* watchUpdateAddress() {
  yield takeEvery(AddressActions.updateAddress, updateAddressAsync);
}

/**
 * The worker function to patch addresses.
 */
export function* updateAddressAsync(
  action: PayloadAction<{ request: IPatchAddressRequest; return?: boolean; locale: string | undefined }>
) {
  const { locale } = action.payload;

  const customerRefSelector: (state: { auth: AuthState }) => string | null = (state: { auth: AuthState }) =>
    state.auth.customerRef;
  const customerRef: string = yield select(customerRefSelector);

  if (action.payload.request.updatedFields['salutation'] === '') {
    action.payload.request.updatedFields['salutation'] = null;
  }

  try {
    yield call(sprykerClient(locale).address.update, {
      payload: {
        addressId: action.payload.request.addressId,
        customerRef: customerRef,
        updatedFields: action.payload.request.updatedFields,
      },
    });

    const actions: PutEffect[] = [
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.editAddress.success.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.editAddress.success.body', { ns: 'account' }) || '',
        })
      ),
      put(AddressActions.addressesRequested({ locale })),
      put(AddressActions.updateAddressSuccess()),
    ];

    if (action.payload.return) {
      actions.push(put(push(PlatformWebappStaticRoutes.account.addresses)));
    }

    yield all(actions);
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.editAddress.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.editAddress.error.body', { ns: 'account' }) || '',
        })
      ),
      put(AddressActions.updateAddressFailed(error)),
    ]);
  }
}

/**
 * The watcher function to delete an address.
 */
export function* watchDeleteAddress() {
  yield takeEvery(AddressActions.deleteAddress, deleteAddressAsync);
}

/**
 * The worker function to delete an address.
 */
export function* deleteAddressAsync(action: PayloadAction<IDeleteAddressRequest & { locale: string | undefined }>) {
  const { locale } = action.payload;

  const customerRefSelector: (state: { auth: AuthState }) => string | null = (state: { auth: AuthState }) =>
    state.auth.customerRef;
  const customerRef: string = yield select(customerRefSelector);

  try {
    yield call(sprykerClient(locale).address.delete, {
      payload: {
        addressId: action.payload.addressId,
        customerRef: customerRef,
      },
    });
    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.deleteAddress.success.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.deleteAddress.success.body', { ns: 'account' }) || '',
        })
      ),
      put(AddressActions.deleteAddressSuccess(action.payload.addressId)),
    ]);
  } catch (error: any) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.deleteAddress.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.deleteAddress.error.body', { ns: 'account' }) || '',
        })
      ),
      put(AddressActions.deleteAddressFailed(error)),
    ]);
  }
}
