const easings = {
  inOutCubic: [0.65, 0, 0.35, 1],
};

const uiLoading = { opacity: [0.8, 0.3, 0.6, 0.8], transition: { repeat: Infinity, duration: 2 } };

export const VeneerAnimation = {
  elementEntry: {
    transition: {
      duration: 1,
      ease: easings.inOutCubic,
    },
    initial: {
      y: 220,
    },
    whileInView: {
      y: 0,
    },
  },
  uiScale: { scale: 1.05 },
  uiScaleBig: { scale: 1.1 },
  uiScaleTransition: { duration: 0.2, ease: easings.inOutCubic },
  uiLoading,
  easings,
};
