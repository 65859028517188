import raw from './raw';
import { AxiosInstance, AxiosResponse } from 'axios';
import { EIncludeTypes, IProductRawResponse, TApiResponseData } from '../types';
import { parseAbstractProductResponse } from '../helper/';
import { IAbstractProductDataParsed } from '../interfaces';

/**
 * Retrieving abstract products
 * @see https://docs.spryker.com/docs/scos/dev/glue-api-guides/202204.0/managing-products/abstract-products/retrieving-abstract-products.html
 * @param {AxiosInstance} http the axios instance
 * @param {string} sku the sku of the desired abstract product
 * @return {any} the product
 */
export const bySku = (
  http: AxiosInstance,
  {
    sku,
  }: {
    sku: string;
  }
): Promise<IAbstractProductDataParsed | null> => {
  const include =
    `${EIncludeTypes.ABSTRACT_PRODUCT_IMAGE_SETS},` +
    `${EIncludeTypes.ABSTRACT_PRODUCT_PRICES},` +
    `${EIncludeTypes.ABSTRACT_PRODUCT_AVAILABILITIES},` +
    `${EIncludeTypes.CONCRETE_PRODUCTS},` +
    `${EIncludeTypes.CONCRETE_PRODUCT_IMAGE_SETS},` +
    `${EIncludeTypes.CONCRETE_PRODUCT_PRICES},` +
    `${EIncludeTypes.CONCRETE_PRODUCT_AVAILABILITIES},` +
    EIncludeTypes.PRODUCT_LABELS;
  return raw
    .get(http, `abstract-products/${sku}`, { params: { include } })
    .then((response: AxiosResponse<TApiResponseData>) =>
      parseAbstractProductResponse(response.data as unknown as IProductRawResponse)
    );
};
