import {MappingConfig, ValueDataType} from '@dxp/akeneo-client';

/**
 * The interface for a product video from catalog.
 */
export interface IProductVideo {
  code: string;
  label: string;
  videoUrl: string;
}

/**
 * Mapping configuration for IProductVideo interface
 */
export const iProductVideoMappingConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  label: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
  videoUrl: {
    path: ['values', 'video_id'],
    valueData: ValueDataType.string,
    localized: false,
  },
};
