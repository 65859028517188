import {MappingConfig, ValueDataType} from '@dxp/akeneo-client';

/**
 * The interface for a download from catalog.
 * Different more specific download types inherit from IDownload
 */
export interface IDownload {
  id: string;
  title: string;
  url: string;
  fileType: string;
  fileSize?: string;
  documentType?: DocumentAssetFamilyType;
}

/**
 * enumerations have to match properties of documentAssetFamilies in CatalogState interface
 */
export enum DocumentAssetFamilyType {
  cataloguesBrochures = 'cataloguesBrochures',
  instructions = 'instructions',
  caseReports = 'caseReports',
  additionalDocuments = 'additionalDocuments',
}

/**
 * Mapping configuration for IDownload interface
 */
export const iDownloadMappingConfig: MappingConfig = {
  id: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  title: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
};
