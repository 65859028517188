import styles from './technical-data-item.module.scss';
import { motion } from 'framer-motion';
import { VeneerAnimation } from '@dxp/veneer';

export interface TechnicalDataItemProps {
  value: string;
}

const animations = {
  collapsed: {
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: VeneerAnimation.easings.inOutCubic,
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: VeneerAnimation.easings.inOutCubic,
      delay: 1.1,
    },
  },
};

export const TechnicalDataItem = (props: TechnicalDataItemProps) => (
  <motion.div variants={animations} className={styles['item']} data-testid="item">
    {props.value}
  </motion.div>
);

export default TechnicalDataItem;
