import { put, takeEvery } from 'redux-saga/effects';
import { ModalActions, ModalContentsInterface } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { CustomSEOEvents, SeoActions } from '../seo';

export function* watchModal() {
  yield takeEvery(ModalActions.open, modalWasOpened);
}

function* modalWasOpened(action: PayloadAction<ModalContentsInterface>) {
  const item = action.payload.items[action.payload.activeItem ?? 0];

  yield put(
    SeoActions.pushEvent({
      event: CustomSEOEvents.MANUALLY_PLAY_VIDEO,
      properties: {
        location: '',
        name: item.title ?? item.url,
      },
    })
  );
}
