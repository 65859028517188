import styles from './accordion-element.module.scss';
import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { VeneerAnimation } from '../../../animations';
import { tidyHtml } from '../../../utils';
import OpenToggle from '@dxp/veneer/lib/decoration/open-toggle/open-toggle';

export interface AccordionElementProps {
  headline: string;
  bodytext?: string;
  open?: boolean;
  children?: ReactElement;
  size: 'large' | 'small';
  onClick?: (e: boolean) => void;
}

const animations = {
  collapsed: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
      ease: VeneerAnimation.easings.inOutCubic,
    },
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: VeneerAnimation.easings.inOutCubic,
      delay: 0.2,
    },
  },
};

export const AccordionElement = (props: AccordionElementProps & { className?: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const classes: string = classNames(
    styles['accordion'],
    {
      [styles['accordion--open']]: isOpen,
    },
    props.className,
    styles['accordion--' + props.size]
  );

  const handleButtonClick = () => {
    setIsOpen(!isOpen);

    if (props.onClick) {
      props.onClick(!isOpen);
    }
  };

  useEffect(() => {
    if (props.open) {
      setIsOpen(props.open);
    }
  }, [props.open]);

  return (
    <div className={classes} data-testid={props.headline}>
      <div className={styles['header']} onClick={handleButtonClick}>
        <div className={styles['title']} data-testid="headline">
          {props.headline}
        </div>
        <OpenToggle isOpen={isOpen} className={styles['icon']} />
      </div>
      <motion.div
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        variants={{
          open: { height: 'auto', visibility: 'visible' },
          collapsed: {
            height: 0,
            transitionEnd: {
              visibility: 'hidden',
            },
          },
        }}
        transition={{ duration: 0.8, ease: VeneerAnimation.easings.inOutCubic }}
      >
        {props.bodytext && (
          <motion.div
            variants={animations}
            className={styles['content']}
            dangerouslySetInnerHTML={{
              __html: tidyHtml(props.bodytext),
            }}
            data-testid="bodytext"
          ></motion.div>
        )}

        {props.children && !props.bodytext && (
          <motion.div variants={animations} className={styles['content']}>
            {props.children}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default AccordionElement;
