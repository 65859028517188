import { createSelector } from '@reduxjs/toolkit';
import { FrontifyAsset, FrontifyFinderAsset, FrontifyLibrary } from '..';
import { FrontifyState } from './frontify.slice';

const selectFrontifyState = (state: { frontify: FrontifyState }) => state.frontify;

export const getFrontifyAssets = createSelector(
  [selectFrontifyState, (_: { frontify: FrontifyState }, selectedItems: FrontifyFinderAsset[]) => selectedItems],
  (state: FrontifyState, selectedItems: FrontifyFinderAsset[]) =>
    state.assets.filter((asset: FrontifyAsset) =>
      selectedItems.some(
        (selected: FrontifyFinderAsset) => asset.previewUrl && selected.preview_url.indexOf(asset.previewUrl) > -1
      )
    )
);

export const getFrontifyAssetsFiltered = createSelector(
  [selectFrontifyState],
  (state: FrontifyState) => state.assetsFiltered
);

export const getFrontifyAssetsFilteredTotal = createSelector(
  [selectFrontifyState],
  (state: FrontifyState) => state.assetsFilteredTotal
);

export const getFrontifyLibraries = createSelector([selectFrontifyState], (state: FrontifyState) => state.libraries);

export const getFrontifyLibrarySelected = createSelector(
  [selectFrontifyState],
  (state: FrontifyState) => state.librarySelected
);

export const getFrontifyLibraryFilters = createSelector(
  [selectFrontifyState, (_: FrontifyState, libraryId: string) => libraryId],
  (state: FrontifyState, libraryId: string) =>
    state.libraries.find((library: FrontifyLibrary) => library.id === libraryId)?.metadataFields
);

export const getFrontifyLibraryFiltersSelected = createSelector(
  [selectFrontifyState],
  (state: FrontifyState) => state.filters
);

export const getSelectedAsset = createSelector([selectFrontifyState], (state) => state.selectedAsset);
