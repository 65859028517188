import { useMemo, useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';

export const useDebouncedClick = (clickFunction: any) => {
  const [inputParams, setInputParams] = useState<any>(null);

  const debouncedFunction = useMemo(() => AwesomeDebouncePromise(clickFunction, 400), []);

  useAsync(async () => (inputParams === null ? 0 : debouncedFunction(inputParams)), [debouncedFunction, inputParams]);

  return {
    inputParams,
    setInputParams,
  };
};
