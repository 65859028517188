import styles from './preview-banner.module.scss';
import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Colors, IconClose } from '@dxp/veneer';
import { PreviewData } from 'next/types';

export interface PreviewBannerProps {
  previewData: PreviewData;
}

const PreviewBanner = (props: PreviewBannerProps) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <div className={styles['preview']} data-testid={'preview'}>
      <div>
        {t('previewHint')}

        {props.previewData &&
        typeof props.previewData === 'object' &&
        Object.prototype.hasOwnProperty.call(props.previewData, 'updatedAt') ? (
          <>
            <div className={styles['timestamp']}>
              <span>{t('previewDraftFrom') + ': '}</span>
              <time dateTime={(props.previewData as { updatedAt: string }).updatedAt}>
                {Intl.DateTimeFormat(router.locale, { dateStyle: 'medium', timeStyle: 'medium' }).format(
                  new Date((props.previewData as { updatedAt: string }).updatedAt)
                )}
              </time>
            </div>
          </>
        ) : null}
      </div>

      <a href={`/api/preview?exit=true&slug=${router.asPath.replace(/^\//, '')}&locale=${router.locale}`}>
        <IconClose color={Colors.intenseBlue} className={styles['close']} />
      </a>
    </div>
  );
};

export default PreviewBanner;
