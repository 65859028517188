import styles from './prompt.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { PromptActions, PromptStateInterface, PromptType } from '../../redux';
import { Colors, IconButton, IconClose } from '@dxp/veneer';
import { useKeyboard } from '@dxp/core';
import { AnimatePresence, motion } from 'framer-motion';
import PromptForgotPassword from './prompt-forgot-password/prompt-forgot-password';
import PromptDeleteAddress from './prompt-delete-address/prompt-delete-address';
import PromptSetNewPassword from './prompt-set-new-password/prompt-set-new-password';

export const Prompt = () => {
  const prompt = useSelector((state: PromptStateInterface) => state.prompt);
  const dispatch = useDispatch();

  useKeyboard((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      dispatch(PromptActions.close());
    }
  });

  return (
    <motion.div
      className={styles['container']}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      key={'container'}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      <div className={styles['close-to-click']} onClick={() => dispatch(PromptActions.close())}></div>

      <motion.div
        initial={{ y: '-150px', opacity: 0 }}
        animate={{ y: '0px', opacity: 1 }}
        key={'inner'}
        transition={{ delay: 0.15, duration: 0.2, ease: 'easeOut' }}
        className={styles['inner']}
      >
        <IconButton
          icon={<IconClose color={Colors.intenseBlue} />}
          onClick={() => dispatch(PromptActions.close())}
          className={styles['close']}
        />

        <AnimatePresence initial={false} mode={'wait'}>
          {prompt.type === PromptType.FORGOT_PASSWORD && (
            <motion.div key={prompt.type} initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PromptForgotPassword className={styles['prompt-content']} />
            </motion.div>
          )}
          {prompt.type === PromptType.DELETE_ADDRESS && (
            <motion.div key={prompt.type} initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PromptDeleteAddress id={prompt.additionalData} className={styles['prompt-content']} key={prompt.type} />
            </motion.div>
          )}
          {prompt.type === PromptType.SET_NEW_PASSWORD && (
            <motion.div key={prompt.type} initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PromptSetNewPassword className={styles['prompt-content']} key={prompt.type} />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default Prompt;
