import styles from './product-variant-teaser.module.scss';
import { Colors, ScreenSizes, StandardButton, useScreenSizes, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import ProductVariantRow from './product-variant-row/product-variant-row';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { IConcreteProduct } from '../../../interfaces';
import { useContext, useMemo, useState } from 'react';
import { CartActions, CartSelectors, CartState } from '../../../redux';
import { AuthContext, PlatformWebappFeatureFlags } from '@dxp/core';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

export interface ProductVariantTeaserProps {
  image: string;
  title: string;
  description: string;
  id: string | undefined;
  concreteProducts: Array<IConcreteProduct>;
}

export const ProductVariantTeaser = (props: ProductVariantTeaserProps & { className?: string }) => {
  const { t } = useTranslation('common');
  const screenSize = useScreenSizes();
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const { locale } = useRouter();
  const cartLoading: boolean = useSelector((state: { cart: CartState }) => CartSelectors.selectCartLoading(state.cart));

  const [temporaryCart, setTemporaryCart] = useState<Record<string, number> | null>(null);

  const updateTemporaryCart = (sku: string, quantity: number) => {
    if (quantity === 0) {
      const newTemporaryCart = { ...temporaryCart };
      delete newTemporaryCart[sku];
      setTemporaryCart(newTemporaryCart);
      return;
    }
    if (temporaryCart) {
      setTemporaryCart({ ...temporaryCart, [sku]: quantity });
    } else {
      setTemporaryCart({ [sku]: quantity });
    }
  };

  const getQuantityInTemporaryCart: number = useMemo(() => {
    if (!temporaryCart) {
      return 0;
    }

    return Object.values(temporaryCart).reduce((total, quantity) => total + quantity, 0);
  }, [temporaryCart]);

  const renderVariants = props.concreteProducts.map((product, index) => (
    <ProductVariantRow
      title={product.title}
      sku={product.sku}
      key={product.sku}
      currentQuantity={temporaryCart?.[product.sku] || 0}
      isFirst={index === 0}
      buyNow={!!product.callToAction?.length && product.callToAction.includes('buy_now')}
      onCartAdd={updateTemporaryCart}
    />
  ));

  const addToCart = () => {
    if (locale && PlatformWebappFeatureFlags.commerce.enable[locale]) {
      const itemsToAdd = Object.entries(temporaryCart || {}).map(([string, number]) => {
        return { sku: string, quantity: number, anonymId: authContext?.anonymId || '' };
      });

      if (locale && itemsToAdd.length) {
        if (!authContext?.isAuthenticated) {
          dispatch(CartActions.guestAddItemsToCart({ locale: locale, payload: itemsToAdd }));
        } else {
          dispatch(CartActions.addItemsToCart({ locale: locale, payload: itemsToAdd }));
        }

        setTemporaryCart(null);
      }
    } else {
      window.open(`${t('product.commerce.cart.add.link')}search?q=${props.title}`, '_ blank');
    }
  };

  const texts = (
    <div className={styles['text-container']}>
      <h2 className={classNames(styles['title'], 'heading-3')} data-testid="title">
        {props.title}
      </h2>
      <p className={classNames(styles['description'], 'text-long')} data-testid="description">
        {props.description}
      </p>
    </div>
  );

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(styles['container'], props.className)}
    >
      <div className={styles['left']}>
        {screenSize !== ScreenSizes.LARGE && texts}
        <div className={styles['image']}>
          {props.image && (
            <Image src={props.image} alt={props.title.toString()} width={350} height={514} data-testid="image" />
          )}
        </div>
      </div>
      <div className={styles['right']}>
        {screenSize === ScreenSizes.LARGE && texts}
        {!!props.concreteProducts?.length && (
          <div className={styles['variants']} data-testid="variants">
            {renderVariants}
          </div>
        )}
        <div className={styles['bottom']}>
          <div className={classNames(styles['info'], 'subtext')}>
            {screenSize === ScreenSizes.LARGE && (
              <>
                {t('product.commerce.vat.priceExcluded')}
                <span className={classNames(styles['info__link'])}>{t('product.commerce.shipping.costsExcluded')}</span>
              </>
            )}
          </div>
          {screenSize !== ScreenSizes.SMALL && (
            // TODO: We should reimplement this.
            //  I just removed it, because the rotos don't have the proper callToAction right now.
            //  Nevertheless: Stay hydrated, smile and have a nice day! :)
            //
            //  FORMER IF
            // props.concreteProducts.some(
            //             (product) => product.callToAction?.length && product.callToAction.includes('buy_now')
            //           ) &&

            <StandardButton
              isLoading={!!(locale && PlatformWebappFeatureFlags.commerce.enable[locale]) && cartLoading}
              disabled={
                !!(locale && PlatformWebappFeatureFlags.commerce.enable[locale]) && getQuantityInTemporaryCart === 0
              }
              label={`${getQuantityInTemporaryCart !== 0 ? '(' + getQuantityInTemporaryCart + ') ' : ''}${t(
                'product.commerce.cart.add'
              )}`}
              variant={Colors.brilliantBlue}
              onClick={addToCart}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ProductVariantTeaser;
