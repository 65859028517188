import styles from './contact-teaser.module.scss';
import ContactButton from '../../interaction/contact-button/contact-button';
import classNames from 'classnames';
import React from 'react';
import SocialMediaButton from '../../interaction/social-media-button/social-media-button';

export interface ContactTeaserProps {
  layout: 'vertical' | 'horizontal';
  name: string;
  image: string;
  department: string;
  description: string;
  phone: string;
  email: string;
  linkedInActive: boolean;
  linkedProfile: string;
  children?: React.ReactNode;
}

export const ContactTeaser = (props: ContactTeaserProps & { className?: string }) => {
  const classes: string = classNames(styles['container'], styles['container--' + props.layout], props.className);

  return (
    <div className={classes} data-testid="container">
      {props.image && (
        <div className={classNames(styles['col'], styles['col--image'])}>
          <img src={props.image} alt={props.name} className={styles['contact-image']} data-testid="image" />
        </div>
      )}
      <div className={classNames(styles['col'], styles['col--text'])}>
        <h3 className={classNames('heading-4', styles['title'])} data-testid="name">
          {props.name}
        </h3>
        {props.linkedInActive && (
          <SocialMediaButton
            className={styles['social']}
            platform={'linkedin'}
            link={props.linkedProfile}
            theme={'dark'}
          />
        )}
        <p className={styles['subtitle']} data-testid="department">
          {props.department}
        </p>
        <p className={styles['text']} data-testid="description">
          {props.description}
        </p>
        <div className={styles['contact']} data-testid="contact">
          {props.phone && <ContactButton className={styles['contact__button']} variant={'tel'} data={props.phone} />}
          {props.email && <ContactButton className={styles['contact__button']} variant={'mailto'} data={props.email} />}
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default ContactTeaser;
