import { IProductRowPricesAttributes, Links } from '../types';

export interface ICartsRawResponseItem {
  type: string;
  id: string;
  attributes: ICartsRawResponseItemAttributes;
  links: Links;
  relationships?: ICartsRawResponseItemRelationships;
}

export interface ICartsRawResponseItemAttributes {
  priceMode: string;
  currency: string;
  store: string;
  name: string;
  isDefault: boolean;
  totals: Totals;
  discounts: Array<ICartsRawResponseDiscounts>;
  orderCustomReference: string | null;
}

export interface Totals {
  expenseTotal: number;
  discountTotal: number;
  taxTotal: number;
  subtotal: number;
  grandTotal: number;
  priceToPay: number;
}

export interface ICartsRawResponseDiscounts {
  displayName: string;
  amount: number;
  code: string;
}

export interface ICartsRawResponseItemRelationships {
  [key: string]: Items;
}

export interface Items {
  data: Item[];
}

export interface Item {
  type: CartType;
  id: string;
}

export enum CartType {
  ConcreteProductImageSets = 'concrete-product-image-sets',
  ConcreteProducts = 'concrete-products',
  ConcreteProductPrice = 'concrete-product-prices',
  Items = 'items',
  GuestCardItems = 'guest-cart-items',
  Vouchers = 'vouchers',
  CartRules = 'cart-rules',
}

export interface Included {
  type: CartType;
  id: string;
  attributes: IncludedAttributes;
  links: Links;
  relationships?: IncludedRelationships;
}

export interface IncludedAttributes extends IProductRowPricesAttributes {
  imageSets?: ImageSet[];
  sku: string;
  isDiscontinued?: boolean;
  discontinuedNote?: null;
  averageRating?: null;
  reviewCount?: number;
  name?: string;
  description?: string;
  attributes?: { [key: string]: string };
  superAttributesDefinition?: string[];
  metaTitle?: string;
  metaKeywords?: string;
  metaDescription?: string;
  attributeNames?: { [key: string]: string };
  quantity?: number;
  groupKey?: string;
  abstractSku?: string;
  amount?: number;
  calculations?: IncludedAttributesCalculation;
  salesUnit?: null;
  selectedProductOptions?: any[];
  code?: string;
  discountType: string;
  displayName: string;
  isExclusive: boolean;
  expirationDateTime: Date;
  discountPromotionAbstractSku?: unknown;
  discountPromotionQuantity?: unknown;
}

export interface IncludedAttributesCalculation {
  sumDiscountAmountAggregation: number;
  sumDiscountAmountFullAggregation: number;
  sumGrossPrice: number;
  sumNetPrice: number;
  sumPrice: number;
  sumPriceToPayAggregation: number;
  sumProductOptionPriceAggregation: number;
  sumSubtotalAggregation: number;
  sumTaxAmountFullAggregation: number;
  taxRate: number;
  unitDiscountAmountAggregation: number;
  unitDiscountAmountFullAggregation: number;
  unitGrossPrice: number;
  unitNetPrice: number;
  unitPrice: number;
  unitPriceToPayAggregation: number;
  unitProductOptionPriceAggregation: number;
  unitSubtotalAggregation: number;
  unitTaxAmountFullAggregation: number;
}

export interface ImageSet {
  name: string;
  images: Image[];
}

export interface Image {
  externalUrlLarge: string;
  externalUrlSmall: string;
}

export interface IncludedRelationships {
  'concrete-product-image-sets'?: Items;
  'concrete-products'?: Items;
  'concrete-product-prices'?: Items;
}
