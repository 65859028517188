import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

export interface IIndication {
  code: string;
  text: string;
  description: string;
  assetLink: string;
  filterAssetLink: string;
}

export const iIndicationMappingConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  text: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
  description: {
    path: ['values', 'indication_description'],
    valueData: ValueDataType.string,
    localized: true,
  },
  assetLink: {
    path: ['values', 'asset_url_indication_detail_page'],
    valueData: ValueDataType.string,
    localized: false,
  },
  filterAssetLink: {
    path: ['values', 'asset_url_indication_filter'],
    valueData: ValueDataType.string,
    localized: false,
  },
};
