import styles from './icon-button.module.scss';
import classNames from 'classnames';
import { cloneElement, ReactElement } from 'react';

export interface IconButtonProps {
  icon: ReactElement;
  notification?: boolean;
  label?: string;
  title?: string;
  type?: 'button' | 'reset' | 'submit';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const IconButton = (props: IconButtonProps & { className?: string }) => {
  // const IconComponent = getIcon(props.icon || 'Close');
  const classes: string = classNames(
    styles['button'],
    props.className,
    props.notification ? styles['with-notification'] : '',
    { [styles['with-label']]: props.label }
  );
  return (
    <button className={classes} onClick={props.onClick} title={props.title} type={props.type ?? 'button'}>
      {cloneElement(props.icon, {
        className: classNames(styles['icon'], props.icon.props['className']),
      })}
      {props.label && <span className={styles['label']}>{props.label}</span>}
    </button>
  );
};

export default IconButton;
