import { createContext } from 'react';
export interface IPageConfig {
  contactPage?: string | undefined;
  mediaConfigs?: Array<any> | undefined;
  metadataSiteName?: string | undefined;
  popularSearches?: Array<string> | undefined;
  hubSpotPortalId?: string | undefined;
  hubSpotNewsletterRegistrationId?: string | undefined;
  cartCTA?: Array<{ linkText: string; linkUrl: string; imageUrl: string }>;
  commerceCTA?: Array<{ linkText: string; linkUrl: string; imageUrl: string; title: string; copy: string }>;
  paymentMethods?:
    | Array<{
        sys: any;
        metadata: any;
        fields: {
          title: string;
          description?: string;
          file: {
            url: string;
            details: {
              size: number;
              image?: {
                width: number;
                height: number;
              };
            };
            fileName: string;
            contentType: string;
          };
        };
      }>
    | undefined;
}
export const PageConfigContext = createContext<null | IPageConfig>(null);
