import styles from './page-loader.module.scss';
import { Colors, Loader } from '@dxp/veneer';

export const PageLoader = () => (
  <div className={styles['container']}>
    <Loader color={Colors.intenseBlue} />
  </div>
);

export default PageLoader;
