import { PlatformWebappFeatureFlags, useStateSafe } from '@dxp/core';
import { IConcreteProductDataParsed, IProductLabel } from '@dxp/spryker-client';
import {
  Colors,
  DeliveryState,
  DeliveryStatus,
  IconButton,
  IconCart,
  IconHeart,
  IconQuantity,
  Pill,
  Price,
  RoundButton,
  VeneerAnimation,
} from '@dxp/veneer';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next-translate-routes/link';
import { Url } from 'next-translate-routes/types';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ConcreteProductActions, ConcreteProductSelectors, ConcreteProductState } from '../../../redux';
import PriceInformation from '../../price-information/price-information';
import ProductLabel from './product-label/product-label';
import ProductTeaserOverlay from './product-teaser-overlay/product-teaser-overlay';
import styles from './product-teaser.module.scss';

export enum ProductVariant {
  PRODUCT_MODEL,
  PRODUCT,
  PRODUCT_CART,
}

export interface ProductTeaserProps {
  image: string;
  title: string;
  priceOld?: string;
  tags?: Array<string>;
  productLabels?: Array<string>;
  sku: string;
  variant: ProductVariant;
  href?: Url;
  isPriceHidden?: boolean;
}

const hoverVariants = {
  initial: { scale: 1, transition: { duration: 0.5, ease: VeneerAnimation.easings.inOutCubic } },
  hover: {
    scale: 1.06,
    transition: { duration: 0.43, ease: VeneerAnimation.easings.inOutCubic },
  },
};

export const ProductTeaser = (props: ProductTeaserProps & { className?: string }) => {
  const { t } = useTranslation('common');

  const { locale } = useRouter();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHovered, setHovered] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  const [productLabels, setProductLabels] = useStateSafe<IProductLabel[] | undefined>(undefined);
  const [deliveryState, setDeliveryState] = useStateSafe<DeliveryState | undefined>(undefined);

  const concreteProductDataParsed: IConcreteProductDataParsed | undefined = useSelector(
    (state: { concreteProduct: ConcreteProductState }) =>
      ConcreteProductSelectors.selectConcreteProduct(state.concreteProduct, props.sku)
  );

  useEffect(() => {
    if (props.sku && props.variant !== ProductVariant.PRODUCT_MODEL) {
      dispatch(ConcreteProductActions.concreteProductRequested({ sku: props.sku, locale }));
    }
  }, [props.sku]);

  useEffect(() => {
    if (concreteProductDataParsed?.productLabels) {
      setProductLabels(concreteProductDataParsed.productLabels);
    } else {
      setProductLabels([]);
    }

    if (concreteProductDataParsed?.concreteProduct) {
      setLoading(false);
    }
  }, [concreteProductDataParsed]);

  useEffect(() => {
    if (concreteProductDataParsed?.concreteProduct) {
      setDeliveryState(
        concreteProductDataParsed?.concreteProduct.isRealtime
          ? concreteProductDataParsed?.concreteProduct.isAvailable
            ? DeliveryState.AVAILABLE
            : concreteProductDataParsed?.concreteProduct.isNeverOutOfStock
            ? DeliveryState.PENDING
            : DeliveryState.NOT_AVAILABLE
          : undefined
      );
    }
  }, [isLoading, concreteProductDataParsed]);

  const contentTop = (
    <>
      <motion.div
        className={styles['image']}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        animate={isHovered ? 'hover' : 'initial'}
        variants={hoverVariants}
      >
        {props.image && <Image src={props.image} alt={props.title} width={315} height={434} data-testid="image" />}
      </motion.div>
      {props.variant === ProductVariant.PRODUCT && !isLoading && deliveryState && (
        <div className={styles['delivery-status']} data-testid="delivery-status">
          <DeliveryStatus deliveryState={deliveryState} />
        </div>
      )}
      {props.variant === ProductVariant.PRODUCT_MODEL && !isLoading && deliveryState && (
        <div
          className={classNames(styles['delivery-status'], styles['delivery-status--static'])}
          data-testid="delivery-status"
        >
          <DeliveryStatus deliveryState={DeliveryState.NOT_AVAILABLE} label={t('product.commerce.deliveryFallback')} />
        </div>
      )}
      {productLabels && (
        <div className={classNames(styles['product-labels'])}>
          {[...productLabels]
            .sort((a: IProductLabel, b: IProductLabel) => b.position - a.position)
            .slice(0, 2)
            .map(({ text, position }) => (
              <ProductLabel key={uuidv4()} position={position} text={text} />
            ))}
        </div>
      )}
      {props.variant === ProductVariant.PRODUCT_MODEL && (
        <IconButton
          icon={<IconQuantity color={Colors.intenseBlue} />}
          label={t('product.commerce.variants')}
          className={styles['variants']}
        />
      )}
    </>
  );

  const contentBottom = (
    <>
      <div className={classNames(styles['title'], 'text-short-medium')} data-testid="title">
        {props.title}
      </div>

      {props.variant === ProductVariant.PRODUCT && concreteProductDataParsed?.concreteProduct && (
        <PriceInformation concreteProduct={concreteProductDataParsed.concreteProduct}>
          <Price
            layout={'minimal'}
            priceSize={props.isPriceHidden ? 'smallest' : 'small'}
            isPriceHidden={props.isPriceHidden}
          />
        </PriceInformation>
      )}
    </>
  );

  const renderTags = (tags: string[]) =>
    tags.map((tag) => <Pill label={tag} active={false} key={uuidv4()} className={styles['tag']} size={'standard'} />);

  return (
    <div className={classNames(styles['container'], props.className)} data-testid="container">
      <div className={styles['top']} data-testid="top">
        {props.href ? (
          <div className={styles['top__inner']}>
            <Link href={props.href}>{contentTop}</Link>
          </div>
        ) : (
          <div className={styles['top__inner']}>{contentTop}</div>
        )}

        {props.variant === ProductVariant.PRODUCT_CART && (
          <>
            <RoundButton
              onClick={() => setIsOpen(true)}
              className={styles['cart-button']}
              data-testid="cart-button"
              variant={Colors.brilliantBlue}
              icon={<IconCart />}
              size={'medium'}
              interactive={true}
            />

            <AnimatePresence>
              {isOpen && (
                <ProductTeaserOverlay
                  locale={locale || ''}
                  classname={styles['overlay']}
                  title={props.title}
                  sku={props.sku}
                  concreteProduct={concreteProductDataParsed?.concreteProduct}
                  onClick={() => setIsOpen(false)}
                />
              )}
            </AnimatePresence>
          </>
        )}
      </div>
      <div className={styles['bottom']} data-testid="bottom">
        {props.href ? (
          <div className={styles['bottom__inner']}>
            <Link href={props.href}>{contentBottom}</Link>
          </div>
        ) : (
          <div className={styles['bottom__inner']}>{contentBottom}</div>
        )}
        {/*TODO: add bookmark functionality */}
        {PlatformWebappFeatureFlags.wishlistButton && (
          <IconButton className={styles['wishlist']} icon={<IconHeart filled={true} color={Colors.intenseBlue} />} />
        )}
      </div>
      {!!props.tags?.length &&
        (props.href ? <Link href={props.href}>{renderTags(props.tags)}</Link> : renderTags(props.tags))}{' '}
    </div>
  );
};

export default ProductTeaser;
