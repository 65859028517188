import { Downloads } from '@dxp/catalog';
import { FrontifyAsset, FrontifySelectors, FrontifyState, FrontifyUtils } from '@dxp/frontify-client';
import { useSelector } from 'react-redux';

export const DownloadShortlist = ({ items }: any) => {
  const frontifyAssets = useSelector((state: { frontify: FrontifyState }) =>
    FrontifySelectors.getFrontifyAssets(state, items)
  );

  if (frontifyAssets) {
    const downloads = frontifyAssets.map((asset: FrontifyAsset) => {
      return {
        id: asset.id,
        title: asset.title,
        url: asset.downloadUrl || '',
        fileSize: FrontifyUtils.fileSizeToString(asset.size),
        fileType: asset.extension,
      };
    });

    return <Downloads downloads={downloads} />;
  }
  return null;
};

export default DownloadShortlist;
