import { Category, MappingConfig } from '@dxp/akeneo-client';
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  IAdditionalDocument,
  IAdditionalMedia,
  IAdditionalMediaLink,
  IAkeneoSliderItem,
  IAttributeStore,
  ICaseReport,
  ICatalogBrochure,
  IComparableProduct,
  ICompatibleMachine,
  ICompatibleMaterial,
  IContactPerson,
  ICrossSellingProduct,
  IFact,
  IFeature,
  IFilter,
  IHighlightImageElement,
  IHighlightVideo,
  IHighlightVideoElement,
  IIndication,
  IInstruction,
  IMaterialDetail,
  IMaterialFilter,
  IPdp,
  IProductImage,
  IProductVideo,
  IRoto,
  ITechnicalData,
  ITestimonial,
  IUpsellingProduct,
} from '../interfaces';

/**
 * The interface for catalog data provided in redux store.
 */
export interface CatalogState {
  loading: boolean;
  pdp: IPdp;
  pdps: Array<IPdp>;
  ceProducts: Array<IPdp>;
  productChildren: Array<IMaterialDetail>;
  categories: Array<Category>;
  attributes: Array<IAttributeStore>;
  contactPersons: Array<IContactPerson>;
  highlightImageElements: Array<IHighlightImageElement>;
  highlightVideoElements: Array<IHighlightVideoElement>;
  highlightVideos: Array<IHighlightVideo>;
  technicalData: Array<ITechnicalData>;
  catalogBrochures: Array<ICatalogBrochure>;
  instructions: Array<IInstruction>;
  caseReports: Array<ICaseReport>;
  additionalDocuments: Array<IAdditionalDocument>;
  features: Array<IFeature>;
  productVideos: Array<IProductVideo>;
  productImages: Array<IProductImage>;
  testimonials: Array<ITestimonial>;
  facts: Array<IFact>;
  additionalMedia: Array<IAdditionalMedia>;
  indications: Array<IIndication>;
  additionalMediaLinks: Array<IAdditionalMediaLink>;
  crossSelling: Array<ICrossSellingProduct>;
  upselling: Array<IUpsellingProduct>;
  compatibleMaterials: Array<ICompatibleMaterial>;
  compatibleMachines: Array<ICompatibleMachine>;
  rotos: Array<IRoto>;
  filter: IFilter;
  machineFilter: IMaterialFilter;
  akeneoslider: Array<IAkeneoSliderItem>;
  comparableProducts: Array<IComparableProduct>;
}

/**
 * The initial redux state.
 */
export const initialCatalogState: CatalogState = {
  loading: false,
  pdp: {
    slug: '',
    slugs: [],
    categories: [],
    code: '',
    sku: '',
    name: '',
    claim: '',
    shortDescription: '',
    callToAction: [],
    moreInformationSlug: '',
    hidePrice: [],
    technicalDataCodes: [],
    contactPersonCode: '',
    longDescriptionHeadline: '',
    longDescription: '',
    highlightHeadline: '',
    highlightText: '',
    highlightVideoElementCodes: [],
    highlightImageElementCodes: [],
    highlightVideoCode: '',
    downloadCodes: {
      cataloguesBrochures: [],
      instructions: [],
      caseReports: [],
      additionalDocuments: [],
    },
    featureDescription: '',
    featureCodes: [],
    productVideoCodes: [],
    productImageCodes: [],
    factCodes: [],
    additionalMediaCodes: [],
    additionalMediaHeadline: '',
    compatibleMaterialCodes: [],
    compatibleMachineCodes: [],
    testimonialCodes: [],
    additionalMediaLinkCodes: [],
  },
  pdps: [],
  ceProducts: [],
  productChildren: [],
  categories: [],
  attributes: [],
  contactPersons: [],
  highlightImageElements: [],
  highlightVideoElements: [],
  highlightVideos: [],
  technicalData: [],
  catalogBrochures: [],
  instructions: [],
  caseReports: [],
  additionalDocuments: [],
  productVideos: [],
  productImages: [],
  features: [],
  facts: [],
  additionalMedia: [],
  testimonials: [],
  crossSelling: [],
  upselling: [],
  indications: [],
  akeneoslider: [],
  additionalMediaLinks: [],
  compatibleMachines: [],
  compatibleMaterials: [],
  rotos: [],
  filter: { selectedMaterial: '', selectedMachine: '' },
  machineFilter: {
    activeBlankShape: '',
    activeBlankColor: '',
    activeBlankHeight: '',
    activeBlankMandrel: 'no_mandrel',
    activeEffectColor: '',
  },
  comparableProducts: [],
};

export enum AssetFamilyStateProperty {
  highlightImageElements = 'highlightImageElements',
  highlightVideoElements = 'highlightVideoElements',
  highlightVideos = 'highlightVideos',
  catalogBrochures = 'catalogBrochures',
  instructions = 'instructions',
  caseReports = 'caseReports',
  additionalDocuments = 'additionalDocuments',
  productVideos = 'productVideos',
  productImages = 'productImages',
  additionalMedia = 'additionalMedia',
}

export enum ReferenceEntityStateProperty {
  contactPersons = 'contactPersons',
  technicalData = 'technicalData',
  features = 'features',
  facts = 'facts',
  testimonials = 'testimonials',
  indications = 'indications',
  additionalMediaLinks = 'additionalMediaLinks',
  akeneoslider = 'akeneoslider',
}

export enum ProductModelStateProperty {
  compatibleMaterials = 'compatibleMaterials',
  crossSelling = 'crossSelling',
  comparableProducts = 'comparableProducts',
}

export enum ProductStateProperty {
  crossSelling = 'crossSelling',
  upselling = 'upselling',
  compatibleMachines = 'compatibleMachines',
  rotos = 'rotos',
  productChildren = 'productChildren',
  comparableProducts = 'comparableProducts',
}

interface CustomPayloadAction {
  locale: string;
  mappingConfig: MappingConfig;
}

export interface PdpPayloadAction extends CustomPayloadAction {
  pdpType: 'product' | 'productModel';
  categories: Array<string>;
  slug: string;
  scope: string;
  fetchChildren?: boolean | number;
}

export interface PdpsPayloadAction extends CustomPayloadAction {
  pdpType: 'product' | 'productModel';
  categories: Array<string>;
  scope: string;
  onlyParent?: boolean;
}

export interface ProductByIdentifierPayloadAction extends CustomPayloadAction {
  identifier: string;
  type: 'product' | 'productModel';
}

export interface PdpByFamilyPayloadAction extends CustomPayloadAction {
  pdpType: 'product' | 'productModel';
  family: string;
  fetchChildren: boolean;
  scope: string;
}

export interface AssetFamilyPayloadAction extends CustomPayloadAction {
  code: string;
  stateProperty: AssetFamilyStateProperty;
  codes: Array<string>;
}

export interface ReferenceEntityPayloadAction extends CustomPayloadAction {
  code: string;
  stateProperty: ReferenceEntityStateProperty;
  codes: Array<string>;
}

export interface ProductPayloadAction extends CustomPayloadAction {
  code: string;
  stateProperty: ProductStateProperty;
  identifier: Array<string>;
}

export interface ChildProductsPayloadAction extends CustomPayloadAction {
  code: string;
  family?: string;
  parents?: Array<string>;
  stateProperty: ProductStateProperty;
}

export interface ProductModelPayloadAction extends CustomPayloadAction {
  code: string;
  stateProperty: ProductModelStateProperty;
  codes: Array<string>;
}

export interface UpdateFilterPayloadAction {
  filterCode: 'selectedMachine' | 'selectedMaterial';
  value: string;
}

export interface UpdateMachineFilterPayloadAction {
  filterCode:
    | 'activeBlankShape'
    | 'activeBlankColor'
    | 'activeBlankHeight'
    | 'activeBlankMandrel'
    | 'activeEffectColor';
  value: string;
}

/**
 * The catalog redux slice.
 */
export const catalogSlice = createSlice({
  name: 'catalog',
  initialState: initialCatalogState,
  reducers: {
    categoriesRequested: (state, action: PayloadAction<undefined | { codes?: Array<string> }>) => {
      state.loading = true;
      state.categories = initialCatalogState.categories;
    },
    categoriesReceived: (state, action: PayloadAction<Array<Category>>) => {
      state.loading = false;
      state.categories = [
        ...state.categories.filter(
          (existingCategory: Category) =>
            !action.payload.some((newCategory: Category) => newCategory.code === existingCategory.code)
        ),
        ...action.payload,
      ];
    },
    categoriesRequestFailed: (state) => {
      state.loading = false;
      state.categories = initialCatalogState.categories;
    },

    attributesRequested: (state, action: PayloadAction<undefined | { codes?: Array<string> }>) => {
      state.loading = true;
      state.attributes = initialCatalogState.attributes;
    },
    attributesReceived: (state, action: PayloadAction<Array<IAttributeStore>>) => {
      state.loading = false;
      state.attributes = [
        ...state.attributes.filter(
          (existingAttribute: IAttributeStore) =>
            !action.payload.some((newAttribute: IAttributeStore) => newAttribute.code === existingAttribute.code)
        ),
        ...action.payload,
      ];
    },
    attributesRequestFailed: (state) => {
      state.loading = false;
      state.attributes = initialCatalogState.attributes;
    },

    pdpRequested: (state, action: PayloadAction<PdpPayloadAction>) => {
      state.loading = true;
      state.pdp = initialCatalogState.pdp;
    },
    pdpReceived: (state, action: PayloadAction<IPdp>) => {
      state.loading = false;
      state.pdp = action.payload;
    },
    pdpRequestFailed: (state) => {
      state.loading = false;
      state.pdp = initialCatalogState.pdp;
    },

    pdpsByCategoryRequested: (state, action: PayloadAction<PdpsPayloadAction>) => {
      state.loading = true;
      state.pdps = initialCatalogState.pdps;
    },
    pdpsByCategoryReceived: (state, action: PayloadAction<Array<IPdp>>) => {
      state.loading = false;
      state.pdps = action.payload;
    },
    pdpsByCategoryRequestFailed: (state) => {
      state.loading = false;
      state.pdps = initialCatalogState.pdps;
    },

    productByIdentifierRequested: (state, action: PayloadAction<ProductByIdentifierPayloadAction>) => {
      state.loading = true;
      state.ceProducts = state.ceProducts || initialCatalogState.ceProducts;
    },
    productByIdentifierReceived: (state, action: PayloadAction<IPdp>) => {
      state.loading = false;
      state.ceProducts = [
        ...state.ceProducts.filter((existingProduct: IPdp) =>
          action.payload.code
            ? action.payload.code !== existingProduct.code
            : action.payload.sku !== existingProduct.sku
        ),
        action.payload,
      ];
    },
    productByIdentifierRequestFailed: (state) => {
      state.loading = false;
    },

    pdpsByFamilyRequested: (state, action: PayloadAction<PdpByFamilyPayloadAction>) => {
      state.loading = true;
      state.pdps = initialCatalogState.pdps;
    },
    pdpsByFamilyReceived: (state, action: PayloadAction<Array<IPdp>>) => {
      state.loading = false;
      state.pdps = action.payload;
    },
    pdpsByFamilyRequestFailed: (state) => {
      state.loading = false;
      state.pdps = initialCatalogState.pdps;
    },

    childProductsByFamilyRequested: (state, action: PayloadAction<ChildProductsPayloadAction>) => {
      state.loading = true;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },
    childProductsByFamilyReceived: (
      state,
      action: PayloadAction<ChildProductsPayloadAction & { data: Array<any> }>
    ) => {
      state.loading = false;
      state[action.payload.stateProperty] = [
        ...(state[action.payload.stateProperty] as Array<any> | []).filter(
          (existingItem) => !action.payload.data.some((newItem) => existingItem.sku === newItem.sku)
        ),
        ...action.payload.data,
      ];
    },
    childProductsByFamilyRequestFailed: (state, action: PayloadAction<ChildProductsPayloadAction>) => {
      state.loading = false;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },

    childProductsByParentRequested: (state, action: PayloadAction<ChildProductsPayloadAction>) => {
      state.loading = true;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },
    childProductsByParentReceived: (
      state,
      action: PayloadAction<ChildProductsPayloadAction & { data: Array<any> }>
    ) => {
      state.loading = false;
      state[action.payload.stateProperty] = [
        ...(state[action.payload.stateProperty] as Array<any> | []).filter(
          (existingItem) => !action.payload.data.some((newItem) => existingItem.sku === newItem.sku)
        ),
        ...action.payload.data,
      ];
    },
    childProductsByParentRequestFailed: (state, action: PayloadAction<ChildProductsPayloadAction>) => {
      state.loading = false;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },

    productRequested: (state, action: PayloadAction<ProductPayloadAction>) => {
      state.loading = true;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },
    productReceived: (state, action: PayloadAction<ProductPayloadAction & { data: Array<any> }>) => {
      state.loading = false;
      state[action.payload.stateProperty] = [
        ...(state[action.payload.stateProperty] as Array<any> | []).filter(
          (existingItem) => !action.payload.data.some((newItem) => existingItem.identifier === newItem.identifier)
        ),
        ...action.payload.data,
      ];
    },
    productRequestFailed: (state, action: PayloadAction<ProductPayloadAction>) => {
      state.loading = false;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },

    productModelRequested: (state, action: PayloadAction<ProductModelPayloadAction>) => {
      state.loading = true;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },
    productModelReceived: (state, action: PayloadAction<ProductModelPayloadAction & { data: Array<any> }>) => {
      state.loading = false;
      state[action.payload.stateProperty] = [
        ...(state[action.payload.stateProperty] as Array<any> | []).filter(
          (existingItem) => !action.payload.data.some((newItem) => existingItem.code === newItem.code)
        ),
        ...action.payload.data,
      ];
    },
    productModelRequestFailed: (state, action: PayloadAction<ProductModelPayloadAction>) => {
      state.loading = false;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },

    referenceEntityRequested: (state, action: PayloadAction<ReferenceEntityPayloadAction>) => {
      state.loading = true;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },
    referenceEntityReceived: (state, action: PayloadAction<ReferenceEntityPayloadAction & { data: Array<any> }>) => {
      state.loading = false;
      state[action.payload.stateProperty] = [
        ...(state[action.payload.stateProperty] as Array<any> | []).filter(
          (existingItem) => !action.payload.data.some((newItem) => existingItem.code === newItem.code)
        ),
        ...action.payload.data,
      ];
    },
    referenceEntityRequestFailed: (state, action: PayloadAction<ReferenceEntityPayloadAction>) => {
      state.loading = false;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },

    assetFamilyRequested: (state, action: PayloadAction<AssetFamilyPayloadAction>) => {
      state.loading = true;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },
    assetFamilyReceived: (state, action: PayloadAction<AssetFamilyPayloadAction & { data: Array<any> }>) => {
      state.loading = false;
      state[action.payload.stateProperty] = [
        ...(state[action.payload.stateProperty] as Array<any> | []).filter(
          (existingItem) => !action.payload.data.some((newItem) => existingItem.code === newItem.code)
        ),
        ...action.payload.data,
      ];
    },
    assetFamilyRequestFailed: (state, action: PayloadAction<AssetFamilyPayloadAction>) => {
      state.loading = false;
      state = {
        ...state,
        [action.payload.stateProperty]: initialCatalogState[action.payload.stateProperty],
      };
    },

    updateFilter: (state, action: PayloadAction<UpdateFilterPayloadAction>) => {
      state.filter =
        action.payload.filterCode === 'selectedMaterial'
          ? { selectedMaterial: action.payload.value, selectedMachine: state.filter.selectedMachine }
          : { selectedMaterial: state.filter.selectedMaterial, selectedMachine: action.payload.value };
    },

    resetFilter: (state) => {
      state.filter = initialCatalogState.filter;
    },

    updateMachineFilter: (state, action: PayloadAction<UpdateMachineFilterPayloadAction>) => {
      action.payload.filterCode === 'activeBlankShape'
        ? (state.machineFilter = {
            activeBlankShape: action.payload.value,
            activeBlankMandrel: 'no_mandrel',
            activeBlankHeight: '',
            activeBlankColor: '',
            activeEffectColor: '',
          })
        : (state.machineFilter[action.payload.filterCode] = action.payload.value);
    },

    resetMachineFilter: (state) => {
      state.machineFilter = initialCatalogState.machineFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action['payload'].catalog,
      };
    });
  },
});

export const CatalogActions = catalogSlice.actions;

export default catalogSlice.reducer;
