import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';

/**
 * The interface for a fact from catalog.
 */
export interface IFact {
  code: string;
  headline: string;
  subline: string;
  icon: string;
  innerText?: string;
}

/**
 * Mapping configuration for IFact interface
 */
export const iFactMappingConfig: MappingConfig = {
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  headline: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
  subline: {
    path: ['values', 'facts_figures_subline'],
    valueData: ValueDataType.string,
    localized: true,
  },
  icon: {
    path: ['values', 'icon_code'],
    valueData: ValueDataType.string,
    localized: false,
  },
  innerText: {
    path: ['values', 'further_information'],
    valueData: ValueDataType.string,
    localized: true,
  },
};
