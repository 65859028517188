import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import styles from './price.module.scss';

export interface PriceProps {
  vat?: boolean;
  layout?: 'horizontal' | 'vertical' | 'minimal' | 'maximal' | 'pdp';
  priceSize?: 'large' | 'small' | 'smallest' | 'cart' | 'commerce-bold' | 'pdp';
  price?: number;
  priceOld?: number;
  currency?: string;
  className?: string;
  locale?: string;
  hideLabel?: boolean;
  isDiscountValue?: boolean;
  isPriceHidden?: boolean;
}

export const Price = (props: PriceProps) => {
  const { t } = useTranslation(['common', 'catalog']);

  const [price, setPrice] = useState<string | undefined>(undefined);
  const [priceOld, setPriceOld] = useState<string | undefined>(undefined);
  const [discount, setDiscount] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.price !== undefined && props.currency) {
      const price = (props.price / 100) * (props.isDiscountValue ? -1 : 1);

      setPrice(new Intl.NumberFormat(props.locale, { style: 'currency', currency: props.currency }).format(price));

      let priceOld = props.priceOld;

      if (priceOld) {
        priceOld = priceOld / 100;
        const discountValue = (1 - price / priceOld) * 100;

        if (discountValue > 0) {
          setPriceOld(
            new Intl.NumberFormat(props.locale, { style: 'currency', currency: props.currency }).format(priceOld)
          );
          setDiscount(`${Math.round(discountValue)}%`);
        }
      }
    } else {
      setPrice(undefined);
    }
  }, [props.price, props.currency, props.priceOld]);

  const classes: string = classNames(
    props.className,
    styles['container'],
    styles[props.layout ? 'container--' + props.layout : 'container--vertical']
  );

  return (
    <div className={classes} data-testid="classContainer">
      {props.layout !== 'minimal' && props.layout !== 'pdp' && !props.hideLabel && (
        <div className={styles['price-type']} data-testid="priceType">
          {t('product.commerce.listPrice')}
        </div>
      )}
      <div
        className={classNames(styles['price-tag'], { [styles['price-tag--pdp']]: props.layout === 'pdp' })}
        data-testid="price"
      >
        <span
          className={classNames(
            styles['price-tag__price'],
            styles['price-tag__price--' + props.priceSize],
            styles[props.isDiscountValue ? 'price-tag__discount-price' : '']
          )}
        >
          {props.isPriceHidden ? t('catalog:product.detail.price.request') : price}
        </span>
        {!props.isPriceHidden && props.layout !== 'minimal' && props.layout !== 'pdp' && props.layout !== 'maximal' && (
          <div className={styles['price-tag__vat']}>
            {props.vat ? t('product.commerce.vat.included') : t('product.commerce.vat.excluded')}
          </div>
        )}
        {/*TODO: Refactor and display single output conditionally*/}
        {!props.isPriceHidden && props.layout === 'maximal' && (
          <div className={styles['price-tag__vat']}>
            {t('product.commerce.vat.excluded')}
            <span className={styles['price-tag__shipping']}>{t('product.commerce.shipping.costs_excluded')}</span>
          </div>
        )}

        {/*TODO: Refactor and make smart with account data (user price with user savings)*/}
        {!props.isPriceHidden && priceOld && props.layout !== 'pdp' && (
          <>
            <span
              className={classNames(
                styles['price-tag__old-price'],
                'crossedout',
                props.priceSize === 'small' ? 'text-short' : 'subtext-regular'
              )}
            >
              {priceOld}
            </span>
            <span
              className={classNames(
                styles['price-tag__savings'],
                props.priceSize === 'small' ? 'text-short' : 'subtext-regular'
              )}
            >
              {discount}
            </span>
          </>
        )}

        {!props.isPriceHidden && priceOld && props.layout === 'pdp' && (
          <span className={classNames(styles['price-pdp'], styles[`price-pdp--${props.priceSize}`])}>{`${
            props.priceSize !== 'cart'
              ? `${t('product.commerce.include')} ${discount} ${t('product.commerce.discount')} | `
              : ''
          } ${t('product.commerce.listPrice')}: ${priceOld} `}</span>
        )}

        {!props.isPriceHidden && props.layout === 'pdp' && (
          <span className={classNames(styles['price-tag__vat--pdp'], styles[`price-tag__vat--${props.priceSize}`])}>
            {props.vat ? t('product.commerce.vat.included') : t('product.commerce.vat.excluded')}
          </span>
        )}
      </div>
    </div>
  );
};

export default Price;
