import styles from './cart-empty-cta.module.scss';
import Image from 'next/image';
import Link from 'next-translate-routes/link';
import React from 'react';

export interface CartEmptyCtaProps {
  linkText: string;
  linkUrl: string;
  imageUrl: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export function CartEmptyCta({ linkText, linkUrl, imageUrl, onClick }: CartEmptyCtaProps) {
  return (
    <Link href={linkUrl} onClick={onClick}>
      <span className={styles['cart-cta']}>
        <Image src={imageUrl} alt={linkText} width={210} height={160} className={styles['cart-cta__image']} />
        <span className={styles['cart-cta__label']}>{linkText}</span>
      </span>
    </Link>
  );
}

export default CartEmptyCta;
