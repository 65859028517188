import { ChangeEvent, useEffect, useState } from 'react';
import { Colors, Input, Pill } from '@dxp/veneer';
import styles from './multi-select.module.scss';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

export interface MultiSelectValue {
  count?: number;
  id: string;
  label: string;
}

export interface MultiSelectProps {
  buttonSelect: boolean;
  enableSearch?: boolean;
  limitOptions?: number;
  onChange: (selectedValues: Array<string>) => void;
  selectedValues: Array<string>;
  values: Array<MultiSelectValue>;
}

export const MultiSelect = (props: MultiSelectProps) => {
  const { t } = useTranslation('common');
  const [selectedValues, setSelectedValues] = useState<Array<string>>([]);
  const [showAllOptions, setShowAllOptions] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (props.selectedValues?.length) {
      setSelectedValues(props.selectedValues);
    }
  }, [props.selectedValues]);

  const onChange: (value: string) => void = (value: string) => {
    if (value) {
      const newValues = selectedValues.some((selectedValue: string) => selectedValue === value)
        ? selectedValues.filter((selectedValue: string) => selectedValue !== value)
        : [...selectedValues, value];

      setSelectedValues(newValues);
      props.onChange(newValues);
    }
  };

  const filterSearch = (value: MultiSelectValue) =>
    search !== '' ? value.label.toLowerCase().includes(search.toLowerCase()) : true;

  return (
    <div className={styles['container']}>
      {props.enableSearch && (
        <Input
          type={'search'}
          variant={Colors.white}
          placeholder={t('product.filter.search')}
          onChange={(e) => setSearch(e?.target?.value ? e.target.value : '')}
        />
      )}
      <div className={props.buttonSelect ? styles['pill-container'] : styles['checkbox-container']}>
        {props.values
          .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
          .filter(filterSearch)
          .filter((value, index) => (!showAllOptions && props.limitOptions ? index < props.limitOptions : true))
          .map((value: MultiSelectValue) => (
            <div
              key={value.id}
              className={classNames(
                styles['item'],
                props.buttonSelect ? styles['item--pill'] : styles['item--checkbox']
              )}
            >
              {props.buttonSelect ? (
                <Pill
                  label={value.label}
                  onClick={() => onChange(value.id)}
                  active={selectedValues.some((selected) => selected === value.id)}
                  disabled={value.count === 0}
                  interactive={true}
                  size={'small'}
                  className={styles['pill']}
                />
              ) : (
                <div className={'checkbox'}>
                  <input
                    id={value.id}
                    disabled={value.count === 0}
                    type={'checkbox'}
                    value={value.id}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                    checked={selectedValues.some((selectedValue: string) => selectedValue === value.id)}
                  />
                  <label htmlFor={value.id}>
                    {value.label} {typeof value.count === 'number' && ` (${value.count.toString()})`}
                  </label>
                </div>
              )}
            </div>
          ))}
      </div>
      {props.limitOptions && props.values.filter(filterSearch).length > props.limitOptions && (
        <button
          onClick={() => setShowAllOptions(!showAllOptions)}
          className={classNames(styles['more'], 'subtext-regular', 'underline')}
        >
          {showAllOptions
            ? t('showLess')
            : t('showMore') +
              (props.values.filter(filterSearch).length > props.limitOptions
                ? ` (${props.values.filter(filterSearch).length - props.limitOptions})`
                : '')}
        </button>
      )}
    </div>
  );
};

export default MultiSelect;
