import styles from './core-product-information-interactive.module.scss';
import CoreProductInformationVideoItem from '../core-product-information-video-item/core-product-information-video-item';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { Colors, RoundButton } from '@dxp/veneer';
import { IProductImage, IProductVideo } from '../../../interfaces';
import { ForwardedRef, RefObject, useRef, useState } from 'react';
import { CustomSEOEvents, SeoActions, useFollowPointer } from '@dxp/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Image from 'next/image';

export enum CoreProductInformationVideoStatus {
  INITIAL = 'INITIAL',
  FORWARD_ENDED = 'FORWARD_ENDED',
  REVERSE_STARTED = 'REVERSE_STARTED',
}

export interface CoreProductInformationVideoWrapProps {
  videos?: Array<IProductVideo>;
  images: Array<IProductImage>;
  name: string;
}

export const CoreProductInformationInteractive = (props: CoreProductInformationVideoWrapProps) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [videoState, setVideoState] = useState<CoreProductInformationVideoStatus>(
    CoreProductInformationVideoStatus.INITIAL
  );
  const [mouseOnParent, setMouseOnParent] = useState<boolean>(false);
  const videoFwRef: ForwardedRef<HTMLVideoElement | null> = useRef(null);
  const videoRevRef: ForwardedRef<HTMLVideoElement | null> = useRef(null);
  const cursorRef: RefObject<HTMLDivElement> = useRef(null);
  const videoContainerRef: RefObject<HTMLDivElement> = useRef(null);
  const { x, y } = useFollowPointer(cursorRef, videoContainerRef);

  const playVideo = () => {
    if (videoState === CoreProductInformationVideoStatus.INITIAL) {
      videoFwRef.current?.play();
    } else {
      setVideoState(CoreProductInformationVideoStatus.REVERSE_STARTED);
      videoRevRef.current?.play();
    }

    dispatch(
      SeoActions.pushEvent({
        event: CustomSEOEvents.MANUALLY_PLAY_VIDEO,
        properties: {
          location: 'core-product-information',
          name: props.name,
        },
      })
    );
  };

  const onVideoEnded = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (videoState === CoreProductInformationVideoStatus.INITIAL) {
      setVideoState(CoreProductInformationVideoStatus.FORWARD_ENDED);
    } else {
      setVideoState(CoreProductInformationVideoStatus.INITIAL);
      videoFwRef.current?.load();
    }
  };

  if (props.videos?.length === 2) {
    return (
      <div
        ref={videoContainerRef}
        className={styles['video-container']}
        onClick={playVideo}
        onMouseEnter={() => setMouseOnParent(true)}
        onMouseLeave={() => setMouseOnParent(false)}
      >
        {props.videos.map((video, index) => (
          <CoreProductInformationVideoItem
            key={video.code}
            className={styles['video']}
            video={index === 0 ? video.videoUrl + '#t=0.001' : video.videoUrl} // Hack for displaying thumbnail on iOS
            ref={index === 0 ? videoFwRef : videoRevRef}
            hide={
              (index === 0 && videoState === CoreProductInformationVideoStatus.REVERSE_STARTED) ||
              (index === 1 && videoState !== CoreProductInformationVideoStatus.REVERSE_STARTED)
            }
            onEnded={onVideoEnded}
          />
        ))}
        <motion.div
          className={classNames(styles['cursor'], styles['cursor--hover'])}
          ref={cursorRef}
          animate={{ x, y, opacity: mouseOnParent ? 1 : 0 }}
          transition={{
            type: 'spring',
            damping: 10,
            stiffness: 50,
            restDelta: 0.001,
          }}
        >
          <RoundButton
            variant={Colors.feelgoodMint}
            label={videoState === CoreProductInformationVideoStatus.INITIAL ? t('zoomOut') : t('zoomIn')}
            size={'large'}
          />
        </motion.div>
        <RoundButton
          className={classNames(styles['cursor'], styles['cursor--static'])}
          variant={Colors.feelgoodMint}
          label={videoState === CoreProductInformationVideoStatus.INITIAL ? t('zoomOut') : t('zoomIn')}
          size={'large'}
        />
      </div>
    );
  }
  return (
    <div className={styles['container']}>
      {!!props.images.length && (
        <Image
          className={styles['image']}
          src={props.images[0].imageUrl}
          alt={props.images[0].label}
          width={890}
          height={860}
          priority
        />
      )}
    </div>
  );
};

export default CoreProductInformationInteractive;
