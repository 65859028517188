import styles from './three-column-teaser.module.scss';
import Image from 'next/image';
import { Colors, StandardButton } from '@dxp/veneer';
import Link from 'next/link';

export interface ThreeColumnTeaserProps {
  title?: string;
  text?: string;
  imageUrl?: string;
  linkText?: string;
  link?: string;
  onClick?: () => void;
}

export const ThreeColumnTeaser = (props: ThreeColumnTeaserProps & { className?: string }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['inner']}>
        {props.imageUrl && (
          <div className={styles['image']}>
            <Image src={props.imageUrl} alt={props.title ? props.title : ''} width={174} height={132} />
          </div>
        )}
        <div className={styles['content']}>
          <div>
            <div className={styles['title']}>{props.title}</div>
            <p className={styles['text']}>{props.text}</p>
          </div>
          {props.link && props.linkText && (
            <Link href={props.link} onClick={() => props.onClick && props.onClick()}>
              <StandardButton label={props.linkText} variant={Colors.brilliantBlue} />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThreeColumnTeaser;
