import { PlatformWebappStaticRoutes } from '@dxp/core';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { TypeArticleFields } from '../../../types';
import styles from './article-teaser.module.scss';

export const ArticleTeaser = (props: TypeArticleFields & { className?: string }) => {
  const { locale } = useRouter();
  const publishDate = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(
        new Date(props.articlePublishDate)
      ),
    [locale, props.articlePublishDate]
  );

  const link = useMemo(() => `${PlatformWebappStaticRoutes.general.news}/${props.slug}`, [props.slug]);
  const teaserImage = props.teaserImage?.fields?.file?.url?.replace('//', 'https://') || props?.teaserImageUrl || '';

  return (
    <Link href={link} className={classNames(styles['container'], props.className)}>
      <span className={styles['image-container']}>
        <Image
          width={560}
          height={540}
          src={teaserImage}
          alt={props.title}
          className={styles['image']}
          data-testid={'articleImage'}
        />
      </span>
      <span className={styles['footer']}>
        <h3 className={styles['title']} data-testid={'title'}>
          {props.title}
        </h3>
        <time dateTime={props.articlePublishDate} className={styles['date']} data-testid={'date'}>
          {publishDate}
        </time>
      </span>
    </Link>
  );
};

export default ArticleTeaser;
