import classNames from 'classnames';
import { Colors } from '../../../types';
import styles from './link-box.module.scss';
import { IconLink } from '../../decoration/icons';
import { useMemo } from 'react';

export interface LinkBoxProps {
  title: string;
  subline: string;
  variant: Colors.smartBlue | Colors.cosyCashmere;
}

export const LinkBox = ({ title, subline, variant, className }: LinkBoxProps & { className?: string }) => {
  const iconColor = useMemo(() => (variant === Colors.smartBlue ? Colors.brilliantBlue : Colors.smartBlue), [variant]);

  return (
    <div className={classNames(styles['inner'], styles[variant], className)} data-testid={'box'}>
      <h2 className={classNames('heading-3', styles['title'])} data-testid={'title'}>
        {title}
      </h2>
      <div className={styles['footer']}>
        <span className={classNames('text-short', styles['subline'])} data-testid={'subline'}>
          {subline}
        </span>
        <IconLink className={styles['icon']} color={iconColor} />
      </div>
    </div>
  );
};

export default LinkBox;
