import classNames from 'classnames';
import Image from 'next/image';
import styles from './highlight.module.scss';
import { MutableRefObject, useRef } from 'react';
import { IHighlightImageElement, IHighlightVideoElement } from '@dxp/catalog';
import { motion } from 'framer-motion';
import { useVideoAutoplay } from '@dxp/core';
import { VeneerAnimation } from '@dxp/veneer';
import { TypeCeHighlightFields } from '../../types';
import { v4 as uuidv4 } from 'uuid';

export interface HighlightProps {
  headline: string;
  text: string;
  classNames?: string;
  videos: Array<IHighlightVideoElement>;
  images: Array<IHighlightImageElement>;
}

function HighlightImage(image: IHighlightImageElement) {
  return (
    <div className={classNames(styles['item'], styles['item--image'])}>
      <div className={styles['item__inner']}>
        <Image src={image.imageUrl} alt={image.alt} data-testid="image" fill={true} />
      </div>
    </div>
  );
}

function HighlightVideo(video: IHighlightVideoElement) {
  const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null);

  useVideoAutoplay(videoRef);

  return (
    <div className={classNames(styles['item'], styles['item--video'])} key={video.code}>
      <div className={styles['item__inner']}>
        <video title={video.label} muted loop playsInline data-testid="video" ref={videoRef}>
          <source src={video.videoUrl} type="video/mp4" data-testid="source" />
        </video>
      </div>
    </div>
  );
}

export const Highlight = (props: HighlightProps | TypeCeHighlightFields) => {
  let images: Array<IHighlightImageElement> = [];
  let videos: Array<IHighlightVideoElement> = [];
  if (Object.prototype.hasOwnProperty.call(props, 'description')) {
    const typedProps = props as TypeCeHighlightFields;

    if (typedProps.mediaType === 'Image') {
      images = [
        {
          imageUrl: typedProps.media1Url,
          alt: typedProps.media1Label,
          label: typedProps.media1Label,
          title: typedProps.media1Label,
          code: uuidv4(),
        },
        {
          imageUrl: typedProps.media2Url,
          alt: typedProps.media2Label,
          label: typedProps.media2Label,
          title: typedProps.media2Label,
          code: uuidv4(),
        },
      ];
    } else {
      videos = [
        {
          videoUrl: typedProps.media1Url,
          alt: typedProps.media1Label,
          label: typedProps.media1Label,
          title: typedProps.media1Label,
          code: uuidv4(),
        },
        {
          videoUrl: typedProps.media2Url,
          alt: typedProps.media2Label,
          label: typedProps.media2Label,
          title: typedProps.media2Label,
          code: uuidv4(),
        },
      ];
    }
  } else {
    const typedProps = props as HighlightProps;
    images = typedProps.images;
    videos = typedProps.videos;
  }
  const renderImages = images
    ?.filter((image: IHighlightImageElement) => !!image.imageUrl)
    .map((image: IHighlightImageElement) => <HighlightImage key={image.code} {...image}></HighlightImage>);

  const renderVideos = videos?.map((video: IHighlightVideoElement) => (
    <HighlightVideo key={video.code} {...video}></HighlightVideo>
  ));

  return (videos.length === 2 && videos.every((item) => !!item.videoUrl)) ||
    (images.length === 2 && images.every((item) => !!item.imageUrl)) ? (
    <div
      className={classNames(styles['inner'], 'grid', !props.headline ? 'reduced-margins' : '')}
      data-elastic-include={true}
    >
      {props.headline && props.text && (
        <motion.div
          initial={VeneerAnimation.elementEntry.initial}
          whileInView={VeneerAnimation.elementEntry.whileInView}
          viewport={{ once: true }}
          transition={VeneerAnimation.elementEntry.transition}
          className={styles['content']}
        >
          <h3 className={classNames('heading-4', styles['headline'])} data-testid="headline">
            {props.headline}
          </h3>
          <p className={classNames('text-long', styles['text'])} data-testid="text">
            {props.text}
          </p>
        </motion.div>
      )}
      {videos.length === 2 && videos.every((item) => !!item.videoUrl) ? (
        <motion.div
          initial={VeneerAnimation.elementEntry.initial}
          whileInView={VeneerAnimation.elementEntry.whileInView}
          viewport={{ once: true }}
          transition={VeneerAnimation.elementEntry.transition}
          className={classNames(styles['media'], styles['media--videos'])}
        >
          {renderVideos}
        </motion.div>
      ) : (
        images.length === 2 &&
        images.every((item) => !!item.imageUrl) && (
          <motion.div
            initial={VeneerAnimation.elementEntry.initial}
            whileInView={VeneerAnimation.elementEntry.whileInView}
            viewport={{ once: true }}
            transition={VeneerAnimation.elementEntry.transition}
            className={classNames(styles['media'], styles['media--images'])}
          >
            {renderImages}
          </motion.div>
        )
      )}
    </div>
  ) : null;
};

export default Highlight;
