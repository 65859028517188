import styles from './locations.module.scss';
import { TypeCeLocationsFields } from '../../types';
import { ContactButton, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

export function Locations({ items, headline }: TypeCeLocationsFields) {
  const renderItems = items.map((item) => (
    <div className={styles['location']} key={uuidv4()} data-testid={'location'} data-elastic-include={true}>
      <div className={classNames(styles['location__title'], 'text-short-medium')} data-testid={'title'}>
        {item.fields.title}
      </div>
      <div className={classNames(styles['location__address'], 'text-long')}>
        {item.fields.addressLine1 && (
          <div className={styles['location__name']} data-testid={'address-1'}>
            {item.fields.addressLine1}
          </div>
        )}
        {item.fields.addressLine2 && (
          <div className={styles['location__street']} data-testid={'address-2'}>
            {item.fields.addressLine2}
          </div>
        )}
        {(item.fields.zip || item.fields.city || item.fields.country) && (
          <div className={styles['location__city']} data-testid={'city'}>
            {item.fields.zip} {item.fields.city}
            {item.fields.country && (item.fields.zip || item.fields.city) && <> | </>}
            {item.fields.country}
          </div>
        )}
      </div>
      {item.fields.phone && (
        <ContactButton className={styles['location__button']} data={item.fields.phone} variant={'tel'} />
      )}
      {item.fields.eMail && (
        <ContactButton className={styles['location__button']} data={item.fields.eMail} variant={'mailto'} />
      )}
    </div>
  ));

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(styles['container'])}
    >
      <h2 className={classNames(styles['headline'], 'heading-4')} data-testid={'headline'}>
        {headline}
      </h2>
      {!!items.length && <div className={styles['items']}>{renderItems}</div>}
    </motion.div>
  );
}

export default Locations;
