import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAddItemToCart,
  ICart,
  IChangeItemQuantity,
  IDeleteItem,
  IGuestAddItemToCart,
  IGuestChangeItemQuantity,
  IGuestDeleteItem,
} from '@dxp/spryker-client';

type StateError = string | { [key: string]: string } | null;

export interface CartErrors {
  addCustomOrderReference: StateError;
  addVoucher: StateError;
  cartsAddItem: StateError;
  cartsAddItems: StateError;
  cartsChangeItemQuantity: StateError;
  cartsDeleteItem: StateError;
  cartsRequest: StateError;
  createCart: StateError;
  deleteVoucher: StateError;
  guestAddCustomOrderReference: StateError;
  guestAddVoucher: StateError;
  guestCartsAddItem: StateError;
  guestCartsAddItems: StateError;
  guestCartsChangeItemQuantity: StateError;
  guestCartsDeleteItem: StateError;
  guestCartsRequest: StateError;
  guestDeleteVoucher: StateError;
}

/**
 * The interface for cart data provided in redux store.
 */
export interface CartState {
  carts: Array<ICart> | null;
  error: CartErrors;
  loading: boolean;
  flyoutIsOpen: boolean;
}

/**
 * The initial concrete product redux state.
 */
const initialState: CartState = {
  carts: null,
  error: {
    cartsRequest: null,
    createCart: null,
    cartsAddItem: null,
    cartsAddItems: null,
    cartsChangeItemQuantity: null,
    cartsDeleteItem: null,
    addVoucher: null,
    guestCartsRequest: null,
    guestCartsAddItem: null,
    guestCartsAddItems: null,
    guestCartsChangeItemQuantity: null,
    guestCartsDeleteItem: null,
    guestAddVoucher: null,
    deleteVoucher: null,
    guestDeleteVoucher: null,
    addCustomOrderReference: null,
    guestAddCustomOrderReference: null,
  },
  loading: false,
  flyoutIsOpen: false,
};

/**
 * The concrete product redux slice.
 */
export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // general reducers
    clearCarts: (state) => {
      state.carts = initialState.carts;
      state.error = initialState.error;
    },
    clearError: (state, action: PayloadAction<keyof CartErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },

    // carts reducers
    cartsRequested: (state, action: PayloadAction<{ locale: string }>) => {
      state.error.cartsRequest = null;
      state.loading = true;
    },
    cartsReceived: (state, action: PayloadAction<Array<ICart>>) => {
      state.carts = action.payload;
      state.error.cartsRequest = null;
      state.loading = false;
    },
    cartsRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.cartsRequest = action.payload;
      state.loading = false;
    },
    createCartRequested: (state, action: PayloadAction<{ locale: string | undefined }>) => {
      state.error.createCart = null;
      state.loading = true;
    },
    createCartReceived: (state, action: PayloadAction<ICart>) => {
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];
      state.error.createCart = null;
      state.loading = false;
    },
    createCartFailed: (state, action: PayloadAction<StateError>) => {
      state.error.createCart = action.payload;
      state.loading = false;
    },
    addItemToCart: (state, action: PayloadAction<{ locale: string; payload: IAddItemToCart }>) => {
      state.error.cartsAddItem = null;
      state.loading = true;
    },
    addItemToCartSuccess: (state, action: PayloadAction<ICart>) => {
      state.error.cartsAddItem = null;
      state.loading = false;
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];
    },
    addItemToCartFail: (state, action: PayloadAction<StateError>) => {
      state.error.cartsAddItem = action.payload;
      state.loading = false;
    },
    addItemsToCart: (state, action: PayloadAction<{ locale: string; payload: Array<IAddItemToCart> }>) => {
      state.error.cartsAddItems = null;
      state.loading = true;
    },
    addItemsToCartSuccess: (state, action: PayloadAction<ICart>) => {
      state.error.cartsAddItems = null;
      state.loading = false;
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];
    },
    addItemsToCartFail: (state, action: PayloadAction<StateError>) => {
      state.error.cartsAddItems = action.payload;
      state.loading = false;
    },

    guestAddItemsToCart: (state, action: PayloadAction<{ locale: string; payload: Array<IGuestAddItemToCart> }>) => {
      state.error.guestCartsAddItems = null;
      state.loading = true;
    },
    guestAddItemsToCartSuccess: (state, action: PayloadAction<ICart>) => {
      state.error.guestCartsAddItems = null;
      state.loading = false;
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];
    },
    guestAddItemsToCartFail: (state, action: PayloadAction<StateError>) => {
      state.error.guestCartsAddItems = action.payload;
      state.loading = false;
    },

    deleteItem: (state, action: PayloadAction<{ locale: string; payload: IDeleteItem }>) => {
      state.error.cartsDeleteItem = null;
      state.loading = true;
    },
    deleteItemFailed: (state, action: PayloadAction<StateError>) => {
      state.error.cartsDeleteItem = action.payload;
      state.loading = false;
    },
    changeItemQuantity: (state, action: PayloadAction<{ locale: string; payload: IChangeItemQuantity }>) => {
      state.error.cartsChangeItemQuantity = null;
      state.loading = true;
    },
    changeItemQuantitySuccess: (state, action: PayloadAction<ICart>) => {
      state.error.cartsChangeItemQuantity = null;
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];
      state.loading = false;
    },
    changeItemQuantityFailed: (state, action: PayloadAction<StateError>) => {
      state.error.cartsChangeItemQuantity = action.payload;
      state.loading = false;
    },

    // guest carts reducers
    guestCartsRequested: (state, action: PayloadAction<{ locale: string; anonymId: string }>) => {
      state.error.guestCartsRequest = null;
      state.loading = true;
    },
    guestCartsReceived: (state, action: PayloadAction<Array<ICart> | null>) => {
      state.carts = action.payload || initialState.carts;
      state.error.guestCartsRequest = null;
      state.loading = false;
    },
    guestCartsRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.guestCartsRequest = action.payload;
      state.loading = false;
    },
    guestAddItemToCart: (state, action: PayloadAction<{ locale: string; payload: IGuestAddItemToCart }>) => {
      state.error.guestCartsAddItem = null;
      state.loading = true;
    },
    guestAddItemToCartSuccess: (state, action: PayloadAction<ICart>) => {
      state.error.guestCartsAddItem = null;
      state.loading = false;
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];
    },
    guestAddItemToCartFail: (state, action: PayloadAction<StateError>) => {
      state.error.guestCartsAddItem = action.payload;
      state.loading = false;
    },
    guestDeleteItem: (state, action: PayloadAction<{ locale: string; payload: IGuestDeleteItem }>) => {
      state.error.guestCartsDeleteItem = null;
      state.loading = true;
    },
    guestDeleteItemFailed: (state, action: PayloadAction<StateError>) => {
      state.error.guestCartsDeleteItem = action.payload;
      state.loading = false;
    },
    guestChangeItemQuantity: (state, action: PayloadAction<{ locale: string; payload: IGuestChangeItemQuantity }>) => {
      state.error.guestCartsChangeItemQuantity = null;
      state.loading = true;
    },
    guestChangeItemQuantitySuccess: (state, action: PayloadAction<ICart>) => {
      state.error.guestCartsChangeItemQuantity = null;
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];
      state.loading = false;
    },
    guestChangeItemQuantityFailed: (state, action: PayloadAction<StateError>) => {
      state.error.guestCartsChangeItemQuantity = action.payload;
      state.loading = false;
    },

    // cart vouchers
    addVoucher: (
      state,
      action: PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string } }>
    ) => {
      state.error.addVoucher = null;
      state.loading = true;
    },
    addVoucherSuccess: (state, action: PayloadAction<ICart>) => {
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];
      state.error.addVoucher = null;
      state.loading = false;
    },
    addVoucherError: (state, action: PayloadAction<StateError>) => {
      state.error.addVoucher = action.payload;
      state.loading = false;
    },

    deleteVoucher: (
      state,
      action: PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string } }>
    ) => {
      state.error.deleteVoucher = null;
      state.loading = true;
    },
    deleteVoucherSuccess: (state) => {
      state.error.deleteVoucher = null;
      state.loading = false;
    },
    deleteVoucherError: (state, action: PayloadAction<StateError>) => {
      state.error.deleteVoucher = action.payload;
      state.loading = false;
    },

    // guest cart vouchers
    guestAddVoucher: (
      state,
      action: PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string; anonymId: string } }>
    ) => {
      state.error.guestAddVoucher = null;
      state.loading = true;
    },
    // TODO: Typing of action payload
    guestAddVoucherSuccess: (state, action: PayloadAction<any>) => {
      state.error.guestAddVoucher = null;
      state.loading = false;
      // TODO: Process response in action payload
    },
    guestAddVoucherError: (state, action: PayloadAction<StateError>) => {
      state.error.guestAddVoucher = action.payload;
      state.loading = false;
    },

    guestDeleteVoucher: (
      state,
      action: PayloadAction<{ locale: string; payload: { cartId: string; voucherCode: string; anonymId: string } }>
    ) => {
      state.error.guestDeleteVoucher = null;
      state.loading = true;
    },
    guestDeleteVoucherSuccess: (state) => {
      state.error.guestDeleteVoucher = null;
      state.loading = false;
    },
    guestDeleteVoucherError: (state, action: PayloadAction<StateError>) => {
      state.error.guestDeleteVoucher = action.payload;
      state.loading = false;
    },

    // cart flyout reducers
    openCartFlyout: (state: CartState) => ({
      ...state,
      flyoutIsOpen: true,
    }),
    closeCartFlyout: (state: CartState) => ({
      ...state,
      flyoutIsOpen: false,
    }),

    // cart add custom order references
    // TODO: when cart endpoint is ready to process customOrderReferences, check this and the guest-cart version
    addCustomOrderReference: (
      state,
      action: PayloadAction<{ locale: string; payload: { cartId: string; customOrderReference: string } }>
    ) => {
      state.error.addCustomOrderReference = null;
      state.loading = true;
    },
    addCustomOrderReferenceSuccess: (state, action: PayloadAction<any>) => {
      state.carts = [
        ...(state.carts || []).filter((existingCart: ICart) => !(action.payload.id === existingCart.id)),
        action.payload,
      ];

      state.error.addCustomOrderReference = null;
      state.loading = false;
    },
    addCustomOrderReferenceError: (state, action: PayloadAction<StateError>) => {
      state.error.addCustomOrderReference = action.payload;
      state.loading = false;
    },

    // // guest cart custom order references
    // guestAddCustomOrderReference: (
    //   state,
    //   action: PayloadAction<{
    //     locale: string;
    //     payload: { cartId: string; customOrderReference: string; anonymId: string };
    //   }>
    // ) => {
    //   console.log('adding custom order reference as guest');
    //   state.error.guestAddCustomOrderReference = null;
    //   state.loading = true;
    // },
    // guestAddCustomOrderReferenceSuccess: (state, action: PayloadAction<any>) => {
    //   state.error.guestAddCustomOrderReference = null;
    //   state.loading = false;
    // },
    // guestAddCustomOrderReferenceError: (state, action: PayloadAction<StateError>) => {
    //   state.error.guestAddCustomOrderReference = action.payload;
    //   state.loading = false;
    // },
  },
});

export const CartActions = cartSlice.actions;

export default cartSlice.reducer;
