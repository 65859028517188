import Header from './header/header';
import styles from './default.module.scss';
import React, { useMemo } from 'react';
import Footer from './footer/footer';
import { PreviewData } from 'next/types';
import PreviewBanner from '../../components/preview-banner/preview-banner';
import StickyNavigator from '../../components/sticky-navigator/sticky-navigator';
import { AnimatePresence, motion } from 'framer-motion';
import MainNavigation from './main-navigation/main-navigation';

export interface DefaultProps {
  children?: React.ReactNode;
  preview: boolean;
  previewData: PreviewData;
  loading?: boolean;
}

const Default = (props: DefaultProps) => {
  const memoizedHeader = useMemo(() => <Header />, []);
  const memoizedFooter = useMemo(() => <Footer />, []);
  const memoizedStickyNavigator = useMemo(() => <StickyNavigator />, []);

  return (
    <div data-testid={'default-layout'}>
      <MainNavigation />
      {memoizedHeader}
      {props.preview && <PreviewBanner previewData={props.previewData} />}
      <AnimatePresence initial={false} mode={'wait'} onExitComplete={() => window.scrollTo(0, 0)}>
        <motion.main
          initial={props.loading ? {} : { opacity: 0 }}
          animate={props.loading ? {} : { opacity: 1 }}
          exit={props.loading ? {} : { opacity: 0 }}
          transition={
            props.loading
              ? {}
              : {
                  duration: 2,
                  ease: 'linear',
                }
          }
          className={styles['main']}
        >
          {props.children}
        </motion.main>
      </AnimatePresence>
      {memoizedFooter}
      {memoizedStickyNavigator}
    </div>
  );
};

export default React.memo(Default);
