import styles from './search-entry.module.scss';
import classNames from 'classnames';
import { motion } from 'framer-motion';

export interface SearchEntryProps {
  title: string;
  excerpt?: string;
  path: string;
}

export const SearchEntry = ({ title, excerpt, path, className }: SearchEntryProps & { className?: string }) => {
  return (
    <motion.div whileHover={{ x: '1.5%' }} className={classNames(styles['container'], className)}>
      <div className={styles['title']} data-testid={'title'}>
        {title}
      </div>
      {excerpt && excerpt !== '' && (
        <div className={styles['excerpt']} dangerouslySetInnerHTML={{ __html: excerpt }} data-testid={'excerpt'}></div>
      )}
      <div className={styles['path']} data-testid={'path'}>
        {path}
      </div>
    </motion.div>
  );
};

export default SearchEntry;
