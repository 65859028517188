import styles from './product-label.module.scss';
export interface ProductLabelProps {
  type?: number;
  text: string;
  position?: number;
}

export const ProductLabel = (props: ProductLabelProps) => {
  return (
    <span data-priority={props.position} className={styles['product-label']}>
      {props.text}
    </span>
  );
};

export default ProductLabel;
