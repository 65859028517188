import styles from './address.module.scss';
import React from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

export interface AddressProps {
  className?: string;
}

export function Address(props: AddressProps) {
  const { t } = useTranslation('common');

  return (
    <div className={classNames(styles['address'], props.className)} data-testid="contact">
      <span className={styles['address__item']}>{t('footer.contact.name')}</span>
      <span className={styles['address__item']}>{t('footer.contact.street')}</span>
      <span className={styles['address__item']}>{t('footer.contact.city')}</span>
      <a href={`tel:${t('footer.contact.phone')}`} className={styles['address__item']}>
        {t('footer.contact.phone')}
      </a>
      <a href={`mailto:${t('footer.contact.mail')}`} className={styles['address__item']}>
        {t('footer.contact.mail')}
      </a>
    </div>
  );
}

export default Address;
