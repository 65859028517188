import { call, put, takeEvery } from 'redux-saga/effects';
import { NavigationsActions } from '../navigations.slice';
import { contentful } from '../../../utils';
import { EntryCollection } from 'contentful';
import { TypeNavigationFields } from '../../../types';
import { PayloadAction } from '@reduxjs/toolkit';

/**
 * Watches actions to change the current window.dataLayer
 */
export function* watchFetchAllNavigations() {
  yield takeEvery(NavigationsActions.fetchAll, fetchNavigationsAsync);
}

/**
 * The worker function to push events to the window.dataLayer
 */
function* fetchNavigationsAsync(action: PayloadAction<string>) {
  try {
    const response: EntryCollection<TypeNavigationFields> | null = yield call(
      contentful.getAllNavigations,
      action.payload
    );

    if (response !== null) {
      yield put(NavigationsActions.setAll(response.items.map((item) => item.fields)));
    }
  } catch (error) {
    yield put(NavigationsActions.clearAll());
  }
}
