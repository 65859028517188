import styles from './open-toggle.module.scss';
import { motion } from 'framer-motion';
import { VeneerAnimation } from '@dxp/veneer/animations';

// eslint-disable-next-line
export interface OpenToggleProps {
  isOpen: boolean;
  className?: string;
}

export const OpenToggle = ({ isOpen, className }: OpenToggleProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 38 37"
      className={styles['icon']}
      data-testid="icon"
    >
      <g stroke="#002d5f" strokeWidth="1.5">
        <path d="m33.7158 18.2905h-28.77776" />
        <motion.path
          d="m19.1172 4.11084v28.77776"
          animate={isOpen ? { rotate: '-90deg', opacity: 0 } : { rotate: '0deg', opacity: 1 }}
          transition={{ duration: 0.4, ease: VeneerAnimation.easings.inOutCubic }}
        />
      </g>
    </svg>
  );
};

export default OpenToggle;
