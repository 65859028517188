import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrder, IOrderPayment, IPaymentDataRequest, IPaymentDataResponseParsed } from '@dxp/spryker-client';

type StateError = string | { [key: string]: string } | null;

export interface OrderPaymentErrors {
  orderPaymentRequest: StateError;
}

/**
 * The interface for orderPayments in redux store.
 */
export interface OrderPaymentState {
  orderPayment: IPaymentDataResponseParsed | null;
  error: OrderPaymentErrors;
  loading: boolean;
}

export interface IOrderPaymentsPayload {
  orderId: string;
  amount: number;
  paymentMethod: string;
  paymentProvider: string;
  locale: string | undefined;
}

/**
 * The initial order payments redux state.
 */
const initialState: OrderPaymentState = {
  orderPayment: null,
  error: {
    orderPaymentRequest: null,
  },
  loading: false,
};

/**
 * The orders redux slice.
 */
export const orderPaymentSlice = createSlice({
  name: 'orders.orderPayments',
  initialState,
  reducers: {
    // general reducers
    clearError: (state, action: PayloadAction<keyof OrderPaymentErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },

    // order payment reducers
    orderPaymentRequested: (state, action: PayloadAction<{ locale: string; payload: IPaymentDataRequest }>) => {
      state.error.orderPaymentRequest = null;
      state.loading = true;
    },
    orderPaymentRequestSuccess: (state, action: PayloadAction<IPaymentDataResponseParsed>) => {
      state.orderPayment = action.payload;
      state.error.orderPaymentRequest = null;
      state.loading = false;
    },
    orderPaymentRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.orderPaymentRequest = action.payload;
      state.loading = false;
    },

    clearOrderPayment: (state, action: PayloadAction<any>) => {
      state.orderPayment = initialState.orderPayment;
    },
  },
});

export const OrderPaymentActions = orderPaymentSlice.actions;

export default orderPaymentSlice.reducer;
