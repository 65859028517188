import styles from './full-width-teaser.module.scss';
import Image from 'next/image';
import { Colors, StandardButton, VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';

export interface FullWidthTeaserProps {
  title: string;
  imageUrl?: string;
  linkText: string;
  link?: string;
}

export const FullWidthTeaser = (props: FullWidthTeaserProps) => {
  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(styles['container'], { [styles['with-image']]: props.imageUrl })}
    >
      <div className={styles['inner']}>
        {props.imageUrl && (
          <div className={styles['image']}>
            <Image src={props.imageUrl} alt={props.title} width={371} height={263} data-testid={'image'} />
          </div>
        )}
        <div className={styles['content']}>
          <div className={classNames('heading-3', styles['title'])} data-testid={'title'}>
            {props.title}
          </div>
          {!!props.link && (
            <Link href={props.link} data-testid={'link'}>
              <StandardButton label={props.linkText} variant={Colors.brilliantBlue} />
            </Link>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default FullWidthTeaser;
