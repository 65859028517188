import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ConcreteProductActions, ConcreteProductState } from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { IConcreteProductRequestedPayload, sprykerClient } from '../../../index';
import { emptyConcreteProductDataParsed, IConcreteProductDataParsed } from '@dxp/spryker-client';

/**
 * The watcher function to fetch a concrete product.
 */
export function* watchFetchConcreteProduct() {
  yield takeEvery(ConcreteProductActions.concreteProductRequested, fetchConcreteProductAsync);
}

/**
 * The worker function to fetch a concrete product.
 * @param action
 */
export function* fetchConcreteProductAsync(action: PayloadAction<IConcreteProductRequestedPayload>) {
  const { sku, locale } = action.payload;

  const dummy: IConcreteProductDataParsed = {
    ...emptyConcreteProductDataParsed,
    concreteProduct: {
      ...emptyConcreteProductDataParsed.concreteProduct,
      sku,
    },
  };

  try {
    const selectConcreteProduct = (state: { concreteProduct: ConcreteProductState }, sku: string) =>
      state.concreteProduct.concreteProducts[sku];

    let concreteProductDataParsed: IConcreteProductDataParsed = yield select(selectConcreteProduct, sku);

    if (concreteProductDataParsed === undefined) {
      try {
        concreteProductDataParsed = yield call(sprykerClient(locale).concreteProducts.bySku, { sku });
      } catch (e) {
        // TODO: differentiate between status 404 (Concrete product not found in errors.details from spryker) and 500
        // 404 > set dummy and don't try again
        // 5xx > put concreteProductRequestFailed action
        concreteProductDataParsed = dummy;
      }
    }

    yield put(ConcreteProductActions.concreteProductsReceived({ sku, concreteProductDataParsed }));
  } catch (error) {
    yield put(ConcreteProductActions.concreteProductRequestFailed());
  }
}
