import { CartActions, CartSelectors } from '@dxp/commerce';
import { NavigationsSelectors } from '@dxp/content';
import { AuthContext, PlatformWebappFeatureFlags, PlatformWebappStaticRoutes, SearchActions } from '@dxp/core';
import {
  AgLogo,
  Colors,
  IconButton,
  IconCart,
  IconHeart,
  IconSearch,
  IconUser,
  MenuButton,
  ScreenSizes,
  StandardButton,
  useScreenSizes,
} from '@dxp/veneer';
import classNames from 'classnames';
import { motion, useScroll } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';
import LocaleSwitch from '../../../components/locale-switch/locale-switch';
import Search from '../../../components/search/search';
import { AppState, MainNavigationActions, useAppDispatch, useAppSelector } from '../../../redux';
import styles from './header.module.scss';
import TopNavigation from './top-navigation/top-navigation';

const Header = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const authContext = useContext(AuthContext);
  const { locale, pathname } = useRouter();
  const [isFixed, updateIsFixed] = useState(false);

  const isCartFilled: boolean | undefined = useAppSelector((state: AppState) =>
    CartSelectors.isAtLeastOneItemInDefaultCart(state.cart)
  );
  const topNavigation = useAppSelector((state) =>
    NavigationsSelectors.getNavigation(state.navigations, 'topnavigation')
  );
  const isTopNavPresent = topNavigation !== undefined;

  const screenSize = useScreenSizes();
  const headerRef = useRef(null);
  const { scrollYProgress } = useScroll();

  useEffect(() => {
    scrollYProgress.on('change', (position) => {
      const posY = Math.floor(position * 1000);

      if (!isFixed && posY > 5) {
        updateIsFixed(true);
      } else if (isFixed && posY < 5) {
        updateIsFixed(false);
      }
    });

    return () => scrollYProgress.destroy();
  }, [scrollYProgress, isFixed]);

  return (
    <motion.header
      ref={headerRef}
      className={classNames(styles['header'], {
        [styles['header__fixed']]: isFixed,
        [styles['header__fixed--top']]: isFixed && isTopNavPresent,
      })}
      data-testid={'header'}
      data-elastic-exclude={true}
    >
      {isTopNavPresent && (
        <div className={styles['top']}>
          <TopNavigation data-testid="top-navigation" />
        </div>
      )}
      <div className={styles['inner']}>
        <Link href={{ pathname: PlatformWebappStaticRoutes.general.home }} aria-label={'Home'}>
          <AgLogo data-testid="logo" className={styles['logo']} />
        </Link>
        <div className={styles['controls']}>
          <Search className={styles['searchbar']} autocomplete={true} theme="bordered" />
          <div className={styles['icon-buttons']}>
            <IconButton
              icon={<IconSearch color={Colors.intenseBlue} />}
              className={styles['icon-buttons__search']}
              title={t('search')}
              onClick={() => dispatch(SearchActions.openMobileSearch())}
            />

            <Link
              href={
                authContext?.isAuthenticated
                  ? PlatformWebappStaticRoutes.account.personalData
                  : PlatformWebappStaticRoutes.account.login
              }
              aria-label={'Account'}
            >
              {screenSize < ScreenSizes.MEDIUM || authContext?.isAuthenticated ? (
                <IconButton icon={<IconUser color={Colors.intenseBlue} />} className={styles['icon-buttons__user']} />
              ) : (
                <StandardButton variant={Colors.brilliantBlue} label={t('common.register')} size="small" />
              )}
            </Link>

            {PlatformWebappFeatureFlags.wishlistButton && (
              <IconButton
                icon={<IconHeart color={Colors.intenseBlue} filled={false} />}
                title={'Wishlist'}
                className={styles['icon-buttons__wishlist']}
              />
            )}

            {locale && PlatformWebappFeatureFlags.commerce.enable[locale] && (
              <IconButton
                icon={<IconCart color={Colors.intenseBlue} />}
                notification={isCartFilled}
                title={'Cart'}
                onClick={() => dispatch(CartActions.openCartFlyout())}
                className={styles['icon-buttons__cart']}
              />
            )}
          </div>
          {/*
          Disable language switch in cart, since changing language here would log you out
          */}
          {pathname !== PlatformWebappStaticRoutes.commerce.cart && (
            <LocaleSwitch className={styles['locale-switch']} />
          )}
          <div className={styles['nav-btn']}>
            <MenuButton
              data-testid="menu-button"
              onClick={() => dispatch(MainNavigationActions.openMainNavigation())}
              title={'Navigation'}
            />
          </div>
        </div>
      </div>
    </motion.header>
  );
};

export default React.memo(Header);
