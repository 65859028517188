import { AuthContext, useDebouncedClick } from '@dxp/core';
import { ICart, ICartItem, IOrderItem } from '@dxp/spryker-client';
import { Colors, DeliveryState, Price, Quantifier, RoundButton } from '@dxp/veneer';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes';
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CartActions,
  CartSelectors,
  CartState,
  ConcreteProductSelectors,
  ConcreteProductState,
} from '../../../../../redux';
import { CartContext } from '../../../cart';
import CartItemPartialImage from '../partials/cart-item-partial-image/cart-item-partial-image';
import CartItemPartialInformation from '../partials/cart-item-partial-information/cart-item-partial-information';
import styles from './cart-item-large.module.scss';

export interface CartItemLargeProps {
  item: ICartItem | IOrderItem;
  className?: string;
  enableCartConnection?: boolean;
  onButtonClick?: (sku: string, quantity: number) => void;
  onQuantityChange?: (sku: string, quantity: number) => void;
  icon?: ReactElement;
  oldQuantity?: number;
}

export function CartItemLarge({
  item,
  className,
  enableCartConnection,
  onButtonClick,
  onQuantityChange,
  icon,
  oldQuantity,
}: CartItemLargeProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const cart = useContext(CartContext);
  const cartConnectionEnabled = enableCartConnection ?? true;
  const { t } = useTranslation('common');

  const [loadingSku, setLoadingSku] = useState('');
  const [newQuantity, setNewQuantity] = useState(item.quantity || 0);
  const [trigger, setTrigger] = useState(0);

  const cartLoading: boolean = useSelector((state: { cart: CartState }) => CartSelectors.selectCartLoading(state.cart));
  const concreteProduct = useSelector((state: { concreteProduct: ConcreteProductState }) =>
    ConcreteProductSelectors.selectConcreteProducts(state.concreteProduct, [item.sku])
  )?.[0];

  const defaultCart: ICart | undefined = useSelector((state: { cart: CartState }) =>
    CartSelectors.selectDefaultCart(state.cart)
  );

  const deliveryState =
    concreteProduct?.concreteProduct?.isRealtime && concreteProduct?.concreteProduct?.isAvailable
      ? DeliveryState.AVAILABLE
      : DeliveryState.AVAILABLE_SOON;

  const oldPrice = useMemo(() => {
    return (item as ICartItem)?.prices ? (item as ICartItem)?.prices?.priceOriginalNet ?? 0 : 0;
  }, [item]);

  //use of debouncedClick hook -> see core
  const { setInputParams } = useDebouncedClick((params: { sku: string; quantity: number }) => {
    onChangeItemQuantityHandler(params);
  });

  //reset loading animation and reset quantifier on fail
  useEffect(() => {
    if (!cartLoading && cartConnectionEnabled) {
      //reset loading animation with setLoadingSku
      setLoadingSku('');
      if (newQuantity !== item.quantity) {
        //trigger child / quantifier component if the new quantity differs from the item quantity from store
        setTrigger((prevTrigger) => prevTrigger + 1);
      } else {
        setNewQuantity(item.quantity);
      }
    }
  }, [cartLoading]);

  const onChangeItemQuantityHandler = (params: { sku: string; quantity: number }) => {
    if (cartConnectionEnabled) {
      const changeItemQuantityPayload = {
        itemId: params.sku,
        itemQuantity: params.quantity,
        cartId: cart?.id || '',
      };

      if (authContext?.isAuthenticated) {
        dispatch(
          CartActions.changeItemQuantity({
            locale: router.locale || '', // TODO Fallback lang?
            payload: changeItemQuantityPayload,
          })
        );
        setLoadingSku(params.sku);
      } else {
        dispatch(
          CartActions.guestChangeItemQuantity({
            locale: router.locale || '', // TODO Fallback lang?
            payload: {
              ...changeItemQuantityPayload,
              anonymId: authContext?.anonymId || '',
            },
          })
        );
        setLoadingSku(params.sku);
      }
    } else {
      if (onQuantityChange) {
        onQuantityChange(params.sku, params.quantity);
      }
    }
  };

  return (
    <div className={classNames(styles['container'], className)}>
      <div className={styles['basic-information']}>
        <div className={styles['image']}>
          <CartItemPartialImage
            image={'image' in item ? item.image : (item as IOrderItem).metadata.image}
            alt={item.name || ''}
            className={styles['basic-information__image']}
          />
        </div>

        <CartItemPartialInformation
          item={item}
          showSKU={true}
          className={styles['basic-information__text']}
          showDeliveryInformation={true}
          deliveryState={deliveryState}
        />
      </div>

      {oldQuantity && (
        <div className={styles['oldQuantity']}>
          {t('quantity')}: {oldQuantity}
        </div>
      )}

      <Price
        className={styles['price']}
        priceSize={'cart'}
        layout={'pdp'}
        price={
          'calculations' in item && item.calculations ? item.calculations.sumNetPrice : (item as IOrderItem).sumNetPrice
        }
        priceOld={oldPrice}
        vat={false}
        currency={cart?.currency || defaultCart?.currency || ''}
      />

      <div className={styles['cta']}>
        <Quantifier
          onChange={(quantity: number) => {
            setInputParams({ sku: item.sku, quantity });
            setNewQuantity(quantity);
          }}
          className={styles['quantifier']}
          variant={Colors.white}
          minimum={1}
          value={item.quantity}
          inactive={cartLoading}
          loading={loadingSku === item.sku && cartLoading}
          triggerReset={trigger}
        />

        <RoundButton
          onClick={() => {
            if (onButtonClick) {
              onButtonClick(item.sku, newQuantity);
            }
          }}
          icon={icon}
          type={'button'}
          inactive={cartLoading}
          variant={Colors.brilliantBlue}
        />
      </div>
    </div>
  );
}

export default CartItemLarge;
