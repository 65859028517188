import styles from './search.module.scss';
import { PlatformWebappStaticRoutes, SearchActions, SearchSelectors } from '@dxp/core';
import { Colors, SearchBar, StandardButton } from '@dxp/veneer';
import { AutocompletedSuggestion, AutocompleteResponseState } from '@elastic/search-ui/lib/esm/types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux';
import classNames from 'classnames';

interface SearchProps {
  value?: string;
  className?: string;
  autocomplete?: boolean;
  theme: Colors.white | Colors.lightGrey | Colors.brilliantBlue | 'bordered';
  bigSearchbar?: boolean;
  onSearch?: (e: any) => void;
}

export function Search(props: SearchProps) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const router = useRouter();

  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const suggestionsRef = useRef<Array<HTMLButtonElement | null>>([]);

  const autocompleteResponse: AutocompleteResponseState | null = useAppSelector((state) =>
    SearchSelectors.selectAutocompleteResponse(state.search)
  );

  useEffect(() => {
    const resetSearch: () => void = () => {
      setSearchTerm('');
      dispatch(SearchActions.clearAutocomplete());
    };

    if (props.autocomplete) {
      window.addEventListener('keydown', handleFocus);
    }

    router.events.on('routeChangeStart', resetSearch);

    return () => {
      window.removeEventListener('keydown', handleFocus);
      router.events.off('routeChangeStart', resetSearch);
    };
  }, []);

  const handleFocus = (e: KeyboardEvent) => {
    if (suggestionsRef.current.length > 0) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();

        const i = suggestionsRef.current.findIndex((el) => document.activeElement?.innerHTML === el?.innerHTML);

        if (e.key === 'ArrowDown') {
          if (i === -1 || i >= suggestionsRef.current.length - 1) {
            suggestionsRef.current[0]?.focus();
          } else {
            suggestionsRef.current[i + 1]?.focus();
          }
        } else if (e.key === 'ArrowUp') {
          if (i === -1 || i === 0) {
            suggestionsRef.current[suggestionsRef.current.length - 1]?.focus();
          } else {
            suggestionsRef.current[i - 1]?.focus();
          }
        }
      }
    }
  };

  const onSearchBarInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setSearchTerm(target.value);
    if (props.autocomplete) {
      if (target.value.length >= 3) {
        dispatch(SearchActions.autocomplete({ requestState: { searchTerm: target.value }, locale }));
      } else {
        dispatch(SearchActions.clearAutocomplete());
      }
    }
  };

  const onSearchBarKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (event?.nativeEvent?.key && event?.nativeEvent?.key === 'Enter' && target.value.length >= 3) {
      search(target.value);
      if (props.autocomplete) dispatch(SearchActions.clearAutocomplete());
    }
  };

  const search = (searchTerm: string) => {
    dispatch(SearchActions.search({ requestState: { searchTerm }, locale, addResults: false }));
    if (router.pathname !== PlatformWebappStaticRoutes.general.search) {
      router.push({ pathname: PlatformWebappStaticRoutes.general.search }, undefined, {
        locale: router.locale,
      });
    }

    props.onSearch?.call('', '');
  };

  const selectSuggestion: (suggestion: string) => void = (suggestion: string) => {
    setSearchTerm(suggestion);
    search(suggestion);
    dispatch(SearchActions.clearAutocomplete());
  };

  const highlightTerm: (entry: string) => string = (entry: string) => {
    return entry.replace(searchTerm, `<em>${searchTerm}</em>`);
  };

  return (
    <div className={classNames(styles['container'], props.className)}>
      <SearchBar
        data-testid="searchbar"
        onInput={onSearchBarInput}
        onKeyDown={onSearchBarKeyDown}
        placeholder={t('search')}
        value={searchTerm}
        variant={props.theme}
        big={!!props.bigSearchbar}
        className={styles['searchbar']}
      />
      {!!props.bigSearchbar && (
        <StandardButton
          label={t('pageNotFound.search')}
          variant={Colors.brilliantBlue}
          onClick={() => search(searchTerm)}
        />
      )}
      {!!autocompleteResponse?.autocompletedSuggestions?.documents?.length && props.autocomplete && (
        <div className={styles['autocomplete']}>
          {(autocompleteResponse?.autocompletedSuggestions.documents as Array<AutocompletedSuggestion>)
            .filter((document: AutocompletedSuggestion) => !!document.suggestion && document.suggestion !== '')
            .map((document: AutocompletedSuggestion, i) => (
              <button
                ref={(el) => (suggestionsRef.current[i] = el)}
                className={styles['autocomplete-entry']}
                onClick={() => selectSuggestion(document.suggestion as string)}
                key={document.suggestion}
                dangerouslySetInnerHTML={{ __html: highlightTerm(document.suggestion || '') }}
              ></button>
            ))}
        </div>
      )}
    </div>
  );
}

export default Search;
