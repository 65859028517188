import {
  ICustomerDataParsed,
  ICustomerLoginDataParsed,
  ICustomerLoginRawResponse,
  ICustomerRegisterRawResponse,
} from '../../interfaces';
import jwtDecoder from 'jwt-decode';

export const parseLoginDataResponse = (response: ICustomerLoginRawResponse): ICustomerLoginDataParsed | null => {
  if (!response) {
    return null;
  }

  const {
    data: { attributes },
  } = response;
  const { sub }: { sub: string } = jwtDecoder(attributes.accessToken);
  const customerRef = JSON.parse(sub).customer_reference;

  return {
    accessToken: attributes.accessToken,
    expiresIn: attributes.expiresIn,
    refreshToken: attributes.refreshToken,
    tokenType: attributes.tokenType,
    customerRef,
  };
};

export const parseRegisterDataResponse = (response: ICustomerRegisterRawResponse): ICustomerDataParsed | null => {
  if (!response) {
    return null;
  }

  const {
    data: { attributes },
  } = response;

  return {
    id: response.data.id,
    salutation: attributes.salutation,
    firstName: attributes.firstName,
    lastName: attributes.lastName,
    email: attributes.email,
    gender: attributes.gender,
    createdAt: attributes.createdAt,
    updatedAt: attributes.updatedAt,
    dateOfBirth: attributes.dateOfBirth,
  };
};
