import styles from './language-menu.module.scss';
import classNames from 'classnames';
import FlagIcon from '../../decoration/flag-icon/flag-icon';

export interface LanguageMenuProps {
  locale: string;
  active?: true;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const LanguageMenu = (props: LanguageMenuProps & { className?: string }) => {
  const [language, country] = props.locale?.split('-').length === 2 ? props.locale?.split('-') : ['', ''];

  return (
    <button
      className={classNames(styles['button'], props.className)}
      onClick={props.onClick}
      data-testid={'language-menu'}
      title={'Select language'}
      disabled={props.disabled}
    >
      <FlagIcon
        countryCode={country.toLowerCase() === 'gb' ? 'international' : country}
        className={styles['button__flag']}
      />
      <span
        className={classNames(styles['button__label'], styles[props.active ? 'button__label--active' : ''])}
        data-testid="button__label"
      >
        {country.toLowerCase() === 'gb' ? 'INT' : language.toUpperCase()}
      </span>
    </button>
  );
};

export default LanguageMenu;
