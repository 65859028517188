import { VeneerAnimation } from '@dxp/veneer';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './indications.module.scss';
import Image from 'next/image';

export interface Indication {
  title: string;
}

export interface IndicationsBlockProps {
  headline: string;
  image?: string;
  indications: Indication[];
}

export const IndicationsBlock = (props: IndicationsBlockProps) => {
  const classes = classNames(styles['container'], props.image ? styles['container--image'] : null);

  const renderItems = props.indications.map((indication: Indication, index: number) => (
    <li className={classNames('subheading', styles['item'])} key={index} data-testid={'indication'}>
      {indication.title}
    </li>
  ));

  return props.headline &&
    props.image &&
    !!props.indications?.length &&
    props.indications.every((indication) => !!indication.title) ? (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classes}
    >
      <h1 className={classNames('heading-2', styles['headline'])} data-testid={'headline'}>
        {props.headline}
      </h1>
      {props.image ? (
        <div className={styles['image']}>
          <Image src={props.image} alt={props.headline} data-testid={'image'} width={850} height={850} />
        </div>
      ) : null}
      <ul className={styles['list']}>{renderItems}</ul>
    </motion.div>
  ) : null;
};

export default IndicationsBlock;
