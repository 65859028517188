import Image from 'next/image';
import { Colors, IconDownload, RoundButton } from '@dxp/veneer';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useState } from 'react';
import styles from './pdf-teaser.module.scss';

export interface PdfTeaserProps {
  meta?: {
    language?: string;
    date?: string;
  };
  extension: string;
  previewUrl: string;
  src: string;
  title: string;
  size: string;
  className?: string;
}

const variants = {
  scaleInactive: { scale: 1, transition: { duration: 0.4, easeInOut: [0.65, 0, 0.35, 1] } },
  scaleHovered: { scale: 1.06, transition: { duration: 0.4, easeInOut: [0.65, 0, 0.35, 1] } },
};

export function PdfTeaser({ meta, className, src, title, previewUrl, extension, size }: PdfTeaserProps) {
  const [activeHover, setActiveHover] = useState<boolean>(false);

  return (
    <div className={classNames(styles['container'], className)}>
      <a
        href={src}
        aria-label={title}
        rel="noreferrer"
        target="_blank"
        className={styles['link']}
        onMouseEnter={() => setActiveHover(true)}
        onMouseLeave={() => setActiveHover(false)}
      >
        <div className={styles['media']}>
          <motion.div
            className={styles['media__inner']}
            variants={variants}
            animate={activeHover ? 'scaleHovered' : 'scaleInactive'}
          >
            <Image className={styles['media__img']} src={previewUrl} width={436} height={570} alt={title} />
          </motion.div>

          <RoundButton
            variant={Colors.brilliantBlue}
            icon={<IconDownload />}
            size={'medium'}
            className={styles['button']}
            onClick={() => false}
          />
        </div>

        <div className={classNames(styles['title'], 'text-short-medium')} data-testid={'title'}>
          {title}
        </div>

        <div className={classNames(styles['caption'], 'text-short')}>
          {meta?.language && <div className={styles['extension']}>{meta?.language}</div>}
          {meta?.date && <span className={styles['extension']}>{meta?.date}, </span>}
          <span className={styles['extension']}>{extension}</span>
          {` (${size})`}
        </div>
      </a>
    </div>
  );
}

export default PdfTeaser;
