import { VeneerAnimation } from '../../../animations';
import classNames from 'classnames';
import { motion, Variants } from 'framer-motion';
import styles from './testimonial.module.scss';

export interface TestimonialProps {
  animation?: boolean;
  animationActive?: boolean;
  animationDirection?: 'forwards' | 'backwards';
  animationVisible?: boolean;
  text: string;
  partnerName: string;
  partnerCompany: string;
  partnerLocation: string;
  imageUrl: string;
  onAnimationStart?: () => void;
  onAnimationComplete?: () => void;
}

const times = [0, 0, 1];
const transition = { duration: 0.5, ease: VeneerAnimation.easings.inOutCubic, times };
const variants: Variants = {
  slideInFromRight: { x: ['-100vw', '100vw', '0vw'] },
  slideInFromLeft: { x: ['-100vw', '-100vw', '0vw'] },
  slideOutToLeft: { x: ['0vw', '0vw', '-100vw'] },
  slideOutToRight: { x: ['0vw', '0vw', '100vw'] },
};

export const Testimonial = (props: TestimonialProps) => {
  const classes: string = classNames(
    styles['container'],
    props.animation ? styles['animation'] : '',
    props.animationVisible ? styles[`animation--visible`] : ''
  );

  const animate = props.animationActive
    ? props.animationDirection === 'forwards'
      ? 'slideInFromRight'
      : 'slideInFromLeft'
    : props.animationDirection === 'forwards'
    ? 'slideOutToLeft'
    : 'slideOutToRight';

  let numberOfAnimations = 0;

  const onAnimationStart = () => {
    if (numberOfAnimations <= 0 && props.onAnimationStart) props.onAnimationStart();

    numberOfAnimations += 1;
  };

  const onAnimationComplete = () => {
    numberOfAnimations -= 1;

    if (numberOfAnimations <= 0 && props.onAnimationComplete) props.onAnimationComplete();
  };

  return (
    <figure className={classes}>
      {props.imageUrl ? (
        <motion.div
          className={styles['image']}
          animate={animate}
          transition={{ ...transition, delay: props.animationActive ? 0.27 : 0 }}
          variants={variants}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          <img className={styles['image__img']} src={props.imageUrl} alt={props.partnerName} data-testid={'image'} />
        </motion.div>
      ) : null}
      <motion.blockquote
        className={styles['quote']}
        animate={animate}
        transition={{ ...transition, delay: props.animationActive ? 0.33 : 0.06 }}
        variants={variants}
        onAnimationStart={onAnimationStart}
        onAnimationComplete={onAnimationComplete}
      >
        <p className={'statement'} data-testid={'text'}>
          {props.text}
        </p>
      </motion.blockquote>
      {props.partnerName || props.partnerCompany || props.partnerLocation ? (
        <motion.figcaption
          className={styles['caption']}
          animate={animate}
          transition={{ ...transition, delay: props.animationActive ? 0.41 : 0.14 }}
          variants={variants}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          {props.partnerName ? (
            <span className={classNames('subtext-regular', styles['name'])} data-testid={'name'}>
              {props.partnerName}
            </span>
          ) : null}
          {props.partnerCompany || props.partnerLocation ? (
            <span className={classNames('subtext', styles['company'])} data-testid={'company-partner'}>
              {props.partnerCompany} {props.partnerLocation}
            </span>
          ) : null}
        </motion.figcaption>
      ) : null}
    </figure>
  );
};

export default Testimonial;
