import { call, put, all, takeEvery } from 'redux-saga/effects';
import { CatalogActions } from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { Attribute, AttributeOption } from '@dxp/akeneo-client';
import { akeneoClient } from '../../index';
import { IAttributeStore } from '../../interfaces';

/**
 * The watcher function to fetch attributes.
 */
export function* watchFetchAttributes() {
  yield takeEvery(CatalogActions.attributesRequested, fetchAttributesAsync);
}

/**
 * Function to fetch data for a single attribute.
 * @param code - The attribute code.
 */
function* fetchAttributeData(code: string): Generator<any, IAttributeStore | null, any> {
  try {
    const attribute: Attribute = yield call(akeneoClient.attribute.getOne, { code });
    const attributeOptions: Array<AttributeOption> = yield call(
      akeneoClient.attribute.getOptions,
      { attributeCode: code }
    );
    return { code, options: attributeOptions, attribute };
  } catch (error) {
    console.error(`Error fetching data for attribute code ${code}:`, error);
    return null;
  }
}

/**
 * The worker function to fetch attributes.
 * @param action - The action payload containing attribute codes.
 */
export function* fetchAttributesAsync(action: PayloadAction<{ codes?: Array<string> } | undefined>) {
  const codes = action.payload?.codes || [];

  try {
    const attributeDataRequests = codes.map((code) => call(fetchAttributeData, code));
    const attributesData: Array<IAttributeStore | null> = yield all(attributeDataRequests);
    const validAttributes: Array<IAttributeStore> = attributesData.filter((attr): attr is IAttributeStore => attr !== null);

    yield put(CatalogActions.attributesReceived(validAttributes));
  } catch (error) {
    yield put(CatalogActions.categoriesRequestFailed());
  }
}
