import classNames from 'classnames';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import styles from './youtube-player.module.scss';

export interface YoutubePlayerProps {
  id: string;
  title?: string;
}

export const YoutubePlayer = (props: YoutubePlayerProps & { className?: string }) => {
  return (
    <div className={classNames(styles['inner'], props.className)}>
      <LiteYouTubeEmbed
        id={props.id}
        poster={'maxresdefault'}
        title={props.title ? props.title : ''}
        activatedClass={styles['active']}
        iframeClass={styles['iframe']}
        playerClass={styles['play']}
        wrapperClass={styles['wrapper']}
      />
    </div>
  );
};

export default YoutubePlayer;
