import { Colors } from '../../../types';
import { VeneerAnimation } from '../../../animations';
import classNames from 'classnames';
import styles from './toggle-switch.module.scss';
import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import { motion } from 'framer-motion';

export interface ToggleSwitchProps {
  checked?: boolean;
  disabled?: boolean;
  error?: string;
  label: string;
  locked?: boolean;
  name?: string;
  required?: boolean;
  variant: Colors.lightGrey | Colors.middleGrey;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  isLoading?: boolean;
}

export const ToggleSwitch = forwardRef(
  (props: ToggleSwitchProps & { className?: string }, ref?: ForwardedRef<HTMLInputElement>) => {
    const classes = classNames(styles['group'], props.className, styles[`variant--${props.variant}`], {
      [styles['disabled']]: props.disabled,
      [styles['invalid']]: props.error,
      [styles['locked']]: props.locked,
      [styles['loading']]: props.isLoading,
    });

    return (
      <motion.label
        data-testid={'toggle-switch'}
        className={classes}
        onClick={props.onClick}
        animate={props.isLoading ? VeneerAnimation.uiLoading : {}}
      >
        <input
          data-testid={'toggle-input'}
          checked={props.checked}
          disabled={props.disabled || props.locked}
          className={styles['input']}
          name={props.name}
          ref={ref}
          type="checkbox"
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
        <i className={styles['toggle']}></i>
        <span className={styles['label']} data-testid={'label'}>
          {props.label}
        </span>
      </motion.label>
    );
  }
);

export default ToggleSwitch;
