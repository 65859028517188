import styles from './news-teaser.module.scss';
import { IArticle, TypeCeNewsTeaserFields } from '../../types';
import { ArticleTeaser } from '../../components';
import { useSelector } from 'react-redux';
import { ContentfulDataSelectors, ContentfulDataState } from '../../redux';
import classNames from 'classnames';

export interface NewsTeaserProps extends TypeCeNewsTeaserFields {
  inArticleLayout?: boolean;
}

export const NewsTeaser = ({ headline, inArticleLayout }: NewsTeaserProps) => {
  const articles: Array<IArticle> = useSelector((state: { contentfulData: ContentfulDataState }) =>
    ContentfulDataSelectors.selectArticles(state.contentfulData)
  );

  return articles.length ? (
    <div
      className={classNames(styles['container'], inArticleLayout ? styles['container--in-article'] : '')}
      data-elastic-exclude={true}
    >
      <h2 className={styles['headline']} data-testid={'news-teaser-headline'}>
        {headline}
      </h2>
      {articles.map((article) => (
        <ArticleTeaser className={styles['teaser']} {...article.article} key={article.id} />
      ))}
    </div>
  ) : null;
};

export default NewsTeaser;
