import {
  IAddressItem,
  ICheckoutData,
  ICheckoutDataRequestAttributes,
  IGuestCheckoutPersonalData,
} from '@dxp/spryker-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateError = string | { [key: string]: string } | null;

export interface CheckoutDataErrors {
  checkoutDataRequested: StateError;
}

interface PersistState {
  version: number;
  rehydrated: boolean;
}

/**
 * The interface for checkout-data provided in redux store.
 */
export interface CheckoutDataState {
  checkoutData: ICheckoutData | null;
  guestCheckoutPersonalData: IGuestCheckoutPersonalData | null;
  guestCheckout: boolean;
  selectedBillingAddressId: string;
  selectedShippingAddressId: string;
  guestCheckoutBillingAddress: IAddressItem | null;
  guestCheckoutShippingAddress: IAddressItem | null;
  error: CheckoutDataErrors;
  loading: boolean;
  acceptedTerms: boolean;
  _persist?: PersistState;
}

/**
 * The initial checkout-data redux state.
 */
const initialState: CheckoutDataState = {
  checkoutData: null,
  guestCheckoutPersonalData: null,
  guestCheckout: false,
  selectedBillingAddressId: '',
  selectedShippingAddressId: '',
  guestCheckoutBillingAddress: null,
  guestCheckoutShippingAddress: null,
  acceptedTerms: false,
  error: {
    checkoutDataRequested: null,
  },
  loading: false,
};

/**
 * The checkout-data redux slice.
 */
export const checkoutDataSlice = createSlice({
  name: 'checkoutData',
  initialState,
  reducers: {
    // general reducers
    clearError: (state, action: PayloadAction<keyof CheckoutDataErrors>) => {
      state.error = { ...state.error, [action.payload]: null };
    },
    clearAllErrors: (state) => {
      state.error = initialState.error;
    },
    clearState: (state) => {
      state.checkoutData = initialState.checkoutData;
      state.guestCheckoutPersonalData = initialState.guestCheckoutPersonalData;
      state.guestCheckout = initialState.guestCheckout;
      state.selectedBillingAddressId = initialState.selectedBillingAddressId;
      state.selectedShippingAddressId = initialState.selectedShippingAddressId;
      state.guestCheckoutBillingAddress = initialState.guestCheckoutBillingAddress;
      state.guestCheckoutShippingAddress = initialState.guestCheckoutShippingAddress;
      state.acceptedTerms = initialState.acceptedTerms;
      state.error = initialState.error;
      state.loading = initialState.loading;
    },
    // checkout-data reducers
    checkoutDataRequested: (
      state,
      action: PayloadAction<{
        locale: string;
        checkoutDataRequestAttributes: ICheckoutDataRequestAttributes;
        anonymId: string | null;
      }>
    ) => {
      state.error.checkoutDataRequested = null;
      state.loading = true;
    },
    checkoutDataReceived: (state, action: PayloadAction<ICheckoutData>) => {
      state.checkoutData = action.payload;
      state.error.checkoutDataRequested = null;
      state.loading = false;
    },
    checkoutDataRequestFailed: (state, action: PayloadAction<StateError>) => {
      state.error.checkoutDataRequested = action.payload;
      state.loading = false;
    },
    setGuestCheckoutPersonalData: (state, action: PayloadAction<IGuestCheckoutPersonalData>) => {
      state.guestCheckoutPersonalData = action.payload;
    },
    clearGuestCheckoutPersonalData: (state) => {
      state.guestCheckoutPersonalData = initialState.guestCheckoutPersonalData;
    },
    toggleGuestCheckout: (state, action: PayloadAction<boolean>) => {
      state.guestCheckout = action.payload;
    },
    setAcceptedTerms: (state, action: PayloadAction<boolean>) => {
      state.acceptedTerms = action.payload;
    },
    setBillingAddressId: (state, action: PayloadAction<string>) => {
      state.selectedBillingAddressId = action.payload;
    },
    setShippingAddressId: (state, action: PayloadAction<string>) => {
      state.selectedShippingAddressId = action.payload;
    },
    clearBillingAddressId: (state) => {
      state.selectedBillingAddressId = initialState.selectedBillingAddressId;
    },
    clearShippingAddressId: (state) => {
      state.selectedShippingAddressId = initialState.selectedShippingAddressId;
    },
    setGuestBillingAddress: (state, action: PayloadAction<IAddressItem>) => {
      state.guestCheckoutBillingAddress = action.payload;
    },
    setGuestShippingAddress: (state, action: PayloadAction<IAddressItem>) => {
      state.guestCheckoutShippingAddress = action.payload;
    },
    clearGuestBillingAddress: (state) => {
      state.guestCheckoutBillingAddress = initialState.guestCheckoutBillingAddress;
    },
    clearGuestShippingAddress: (state) => {
      state.guestCheckoutShippingAddress = initialState.guestCheckoutShippingAddress;
    },
    resetCheckoutAddresses: (state) => {
      state.selectedBillingAddressId = initialState.selectedBillingAddressId;
      state.selectedShippingAddressId = initialState.selectedShippingAddressId;
    },
  },
});

export const CheckoutDataActions = checkoutDataSlice.actions;

export default checkoutDataSlice.reducer;
