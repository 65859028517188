import { Colors, getIcon } from '../../../types';
import classNames from 'classnames';
import styles from './fact.module.scss';
import { cloneElement, ReactElement } from 'react';

export interface FactProps {
  headline: string;
  subline?: string;
  icon?: ReactElement | string;
  innerText?: string;
  iconFilled?: boolean;
}

export const Fact = (props: FactProps & { className?: string }) => {
  const IconComponent =
    typeof props.icon === 'string' ? getIcon(props.icon.charAt(0).toUpperCase() + props.icon.slice(1)) : null;

  return (
    <div className={classNames(styles['container'], props.className)} data-testid={'fact'}>
      {props.icon && !props.innerText && (
        <span className={styles['circle']}>
          {props.icon ? (
            typeof props.icon === 'string' && IconComponent !== null ? (
              <IconComponent className={styles['icon']} color={Colors.smartBlue} filled={props.iconFilled} />
            ) : (
              cloneElement(props.icon as ReactElement, {
                className: styles['icon'],
                color: Colors.smartBlue,
                filled: props.iconFilled,
              })
            )
          ) : null}
        </span>
      )}
      {props.innerText && (
        <div data-testid={'innerText'} className={classNames(styles['inner-text'], 'numbers')}>
          {props.innerText}
        </div>
      )}
      <span className={classNames(styles['headline'], 'text-short-medium')} data-testid={'headline'}>
        {props.headline}
      </span>
      <span className={classNames(styles['subline'], 'text-short')} data-testid={'subline'}>
        {props.subline}
      </span>
    </div>
  );
};

export default Fact;
