import { MappingConfig, ValueDataType } from '@dxp/akeneo-client';
import { IDownload, iDownloadMappingConfig } from './download.interface';

/**
 * The interface for an instruction from catalog.
 * Extending the general download interface
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInstruction extends IDownload {}

/**
 * Mapping configuration for IInstruction interface
 */
export const iInstructionMappingConfig: MappingConfig = {
  ...iDownloadMappingConfig,
  url: {
    path: ['values', 'instructions_asset_link'],
    valueData: ValueDataType.string,
    localized: true,
  },
  fileType: {
    path: ['values', 'instructions_asset_link'],
    valueData: ValueDataType.string,
    localized: true,
    modifier: () => '',
    // Disabled because the file extensions are not always included in the URL.
    // (url: string) => url.split('.')[url.split('.').length - 1].toUpperCase(),
  },
  documentType: 'instructions',
};
