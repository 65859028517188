import { call, put, takeEvery } from 'redux-saga/effects';
import { CatalogActions, ChildProductsPayloadAction, ProductPayloadAction } from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { ListResponse, mapProduct, Product, ProductQueryParameters } from '@dxp/akeneo-client';
import { akeneoClient, AkeneoSearchObject } from '../../index';
import { PlatformWebappFeatureFlags, toUppercaseLocale } from '@dxp/core';

/**
 * Prepare locale by converting to uppercase.
 */
function prepareLocale(locale: string): string {
  return toUppercaseLocale(locale).replace('-', '_');
}

/**
 * Create search object based on provided conditions.
 */
function createSearchObject(identifier: string[], locale: string, additionalCriteria?: any): AkeneoSearchObject {
  return {
    identifier: [{ operator: 'IN', value: identifier }],
    ...(PlatformWebappFeatureFlags.commerce.respectAvailableInRegion && {
      available_in_region: [{ operator: 'IN', value: [locale] }],
    }),
    ...additionalCriteria,
  };
}

export function* watchFetchProduct() {
  yield takeEvery(CatalogActions.productRequested, fetchProductAsync);
}

export function* fetchProductAsync(action: PayloadAction<ProductPayloadAction>) {
  const { identifier, mappingConfig, locale: rawLocale } = action.payload;
  const preparedLocale = prepareLocale(rawLocale);
  try {
    const searchObject: AkeneoSearchObject = createSearchObject(identifier, preparedLocale);
    const query: ProductQueryParameters = {
      search: JSON.stringify(searchObject),
    };
    const productResponse: ListResponse & { items: Array<Product> } = yield call(akeneoClient.product.getAll, {
      query,
    });
    const data: Array<any> = productResponse.items.map((product: Product) =>
      mapProduct(product, mappingConfig, preparedLocale)
    );
    yield put(CatalogActions.productReceived({ ...action.payload, data }));
  } catch (error) {
    yield put(CatalogActions.productRequestFailed(action.payload));
  }
}

export function* watchFetchChildProductsByFamily() {
  yield takeEvery(CatalogActions.childProductsByFamilyRequested, fetchChildProductsByFamilyAsync);
}

export function* fetchChildProductsByFamilyAsync(action: PayloadAction<ChildProductsPayloadAction>) {
  const { payload } = action;
  const productFamily = action.payload.family;
  if (productFamily && productFamily !== '') {
    try {
      const preparedLocale = prepareLocale(payload.locale);
      const searchObject: AkeneoSearchObject = createSearchObject([productFamily], preparedLocale);
      const query: ProductQueryParameters = {
        search: JSON.stringify(searchObject),
      };
      const productResponse: ListResponse & { items: Array<Product> } = yield call(akeneoClient.product.getAll, {
        query,
      });
      const data: Array<any> = productResponse.items.map((product: Product) =>
        mapProduct(product, action.payload.mappingConfig, preparedLocale)
      );
      yield put(CatalogActions.childProductsByFamilyReceived({ ...payload, data }));
    } catch (error) {
      console.error(error);
      yield put(CatalogActions.childProductsByFamilyRequestFailed(payload));
    }
  }
}

export function* watchFetchChildProductsByParent() {
  yield takeEvery(CatalogActions.childProductsByParentRequested, fetchChildProductsByParentAsync);
}

export function* fetchChildProductsByParentAsync(action: PayloadAction<ChildProductsPayloadAction>) {
  const { payload } = action;
  const parents = action.payload.parents;
  if (parents && parents.length) {
    try {
      const searchObject = createSearchObject(parents, prepareLocale(payload.locale));
      const query: ProductQueryParameters = {
        search: JSON.stringify(searchObject),
      };
      const productResponse: ListResponse & { items: Array<Product> } = yield call(akeneoClient.product.getAll, {
        query,
      });
      const data: Array<any> = productResponse.items.map((product: Product) =>
        mapProduct(product, action.payload.mappingConfig, toUppercaseLocale(action.payload.locale))
      );
      yield put(CatalogActions.childProductsByParentReceived({ ...payload, data }));
    } catch (error) {
      console.error(error);
      yield put(CatalogActions.childProductsByParentRequestFailed(payload));
    }
  }
}
