import { createSelector } from '@reduxjs/toolkit';
import { AddressErrors, AddressState } from './slice';

const selectAddressState = (state: AddressState) => state;

const isLoading = createSelector(selectAddressState, (state: AddressState) => state.loading);

const selectAddresses = createSelector(
  selectAddressState,
  (state: AddressState) => state.addresses?.filter((address) => !!address) || []
);

const selectError = createSelector(
  [selectAddressState, (state: AddressState, actionType: keyof AddressErrors) => actionType],
  (state: AddressState, actionType: keyof AddressErrors) =>
    state.error && Object.prototype.hasOwnProperty.call(state.error, actionType) ? state.error[actionType] : null
);

export const AddressSelectors = {
  isLoading,
  selectAddresses,
  selectError,
};
