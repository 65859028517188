import styles from './workflow.module.scss';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import classNames from 'classnames';
import { ProgressBar, VeneerAnimation } from '@dxp/veneer';
import { Swiper as ISwiper } from 'swiper';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'framer-motion';
import getConfig from 'next/config';
import { motion } from 'framer-motion';

export interface WorkflowProps {
  headline: string;
  text: string;
  steps: Array<WorkflowStep>;
  idsHacks?: boolean;
}

export interface WorkflowStep {
  title: string;
  imageUrl: string;
  headline: string;
  copy: string;
}

export function Workflow(props: WorkflowProps) {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [progressing, setProgressing] = useState<boolean>(false);
  const swiperRef: MutableRefObject<ISwiper | null> = useRef(null);
  const swiperProps: SwiperProps = {
    slidesPerView: 1,
    loop: true,
    speed: 800,
  };
  const { basePath } = props.idsHacks ? getConfig().publicRuntimeConfig : '';

  useEffect(() => {
    if (isInView) {
      setProgressing(true);
    } else {
      setProgressing(false);
    }
  }, [isInView]);

  const handleProgressChange = (index: number) => {
    setActiveIndex(index);
    setProgressing(false);

    if (swiperRef.current) {
      swiperRef.current.slideToLoop(index);
    }
  };

  const renderSlides = useMemo(
    () =>
      props.steps.map((item, index) => (
        <SwiperSlide className={styles['item']} key={index}>
          <div className={styles['item__image']}>
            {
              // We need to differentiate for IDS-Microsite, which can not make use of next/image
              props.idsHacks ? (
                <img src={basePath + item.imageUrl} alt={item.title} />
              ) : (
                <Image src={item.imageUrl} alt={item.title} width={706} height={740} />
              )
            }
          </div>
          <div className={styles['item__content']}>
            <h3 className={classNames(styles['item__headline'], 'heading-3')}>{item.headline}</h3>
            <p
              className={'text-long'}
              dangerouslySetInnerHTML={{
                __html: item.copy,
              }}
            />
          </div>
        </SwiperSlide>
      )),
    [props.steps]
  );

  return (
    <motion.div
      data-elastic-include={true}
      initial={!props.idsHacks ? VeneerAnimation.elementEntry.initial : {}}
      whileInView={!props.idsHacks ? VeneerAnimation.elementEntry.whileInView : {}}
      viewport={{ once: true }}
      transition={!props.idsHacks ? VeneerAnimation.elementEntry.transition : {}}
      className={classNames(styles['container'], props.idsHacks ? styles['container--ids'] : '')}
    >
      <div className={styles['top']}>
        <h2 className={classNames(styles['headline'], 'heading-2')}>{props.headline}</h2>
        <p
          className={classNames(styles['text'], 'text-long')}
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        />
      </div>
      <div className={styles['slider-container']} ref={ref}>
        <ProgressBar
          items={props.steps.map((el) => el.title)}
          activeIndex={activeIndex}
          onNext={() => swiperRef?.current?.slideNext()}
          onChange={(i) => handleProgressChange(i)}
          progressing={progressing}
        />
        <Swiper
          className={styles['slider']}
          {...swiperProps}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onRealIndexChange={(swiper) => setActiveIndex(swiper.realIndex)}
          onTouchEnd={() => setProgressing(false)}
        >
          {renderSlides}
        </Swiper>
      </div>
    </motion.div>
  );
}

export default Workflow;
