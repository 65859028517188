import { v4 as uuidv4 } from 'uuid';
import styles from './benefit-list.module.scss';
import { VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import classNames from 'classnames';

export interface BenefitBasic {
  title: string;
  copy?: string;
}

export interface BenefitListProps {
  items: Array<BenefitBasic>;
  classNames?: string;
}

export function BenefitList(props: BenefitListProps) {
  const renderItems = (benefits: Array<BenefitBasic>) =>
    benefits?.map((child: BenefitBasic) => (
      <li className={styles['item']} key={uuidv4()}>
        <div className={styles['item__inner']}>
          <h3 className={classNames('heading-4', styles['item__headline'])}>{child.title}</h3>
          <p className={classNames('text-long', styles['item__text'])}>{child.copy}</p>
        </div>
      </li>
    ));

  return props.items?.length ? (
    <motion.ol
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(props.classNames, styles['list'])}
    >
      {renderItems(props.items)}
    </motion.ol>
  ) : null;
}

export default BenefitList;
