import { CartSelectors, CartState, CheckoutState } from '../redux';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { AuthContext, PlatformWebappFeatureFlags, PlatformWebappStaticRoutes } from '@dxp/core';

export const useCheckoutEnabled = (redirectUrl = PlatformWebappStaticRoutes.general.home) => {
  const router = useRouter();
  const cartLoading = useSelector((state: { cart: CartState }) => state.cart.loading);
  const carts = useSelector((state: { cart: CartState }) => state.cart.carts);
  const checkoutLoading = useSelector((state: { checkout: CheckoutState }) => state.checkout.loading);
  const checkout = useSelector((state: { checkout: CheckoutState }) => state.checkout.checkout);
  const authContext = useContext(AuthContext);

  const hasDefaultCartWithItems = useSelector((state: { cart: CartState }) =>
    CartSelectors.hasDefaultCartWithItems(state.cart)
  );

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const hasCheckout =
      checkoutLoading ||
      (checkout !== null && 'orders' in checkout) ||
      !!(Array.isArray(checkout) && checkout?.length && 'orders' in checkout[0]);

    const hasCart = cartLoading || hasDefaultCartWithItems || hasCheckout;
    let redirectCondition = !hasCart;

    if (authContext?.isAuthenticated) {
      if (router.pathname === PlatformWebappStaticRoutes.commerce.checkout.success) {
        if (
          !!Object.keys(router.query).length &&
          Object.keys(router.query).some((queryParameter) => queryParameter === 'order')
        ) {
          redirectCondition = false;
        } else {
          redirectCondition = !hasCheckout && !checkoutLoading;
        }
      }

      if (redirectCondition) {
        if (carts?.length) {
          console.log('redirect via useCheckoutEnabled - authenticated');
          console.table({
            checkout: checkout,
            cartLoading: cartLoading,
            checkoutLoading: checkoutLoading,
            hasCart: hasCart,
            hasCheckout: hasCheckout,
            hasDefaultCartWithItems: hasDefaultCartWithItems,
            redirect: redirectCondition,
          });
          router.push(redirectUrl);
        }
      }
      return;
    }

    if (!authContext?.isAuthenticated) {
      redirectCondition = Array.isArray(carts) && carts.length === 0 && !hasCart;

      if (router.pathname === PlatformWebappStaticRoutes.commerce.checkout.success) {
        if (
          !!Object.keys(router.query).length &&
          Object.keys(router.query).some((queryParameter) => queryParameter === 'order')
        ) {
          redirectCondition = false;
        } else {
          redirectCondition = !hasCheckout && !checkoutLoading;
        }
      }

      if (redirectCondition) {
        console.log('redirect via useCheckoutEnabled hook - unauthenticated');
        console.table({
          cartsAreArray: Array.isArray(carts),
          cartAmount: carts?.length,
          checkout: checkout,
          cartLoading: cartLoading,
          checkoutLoading: checkoutLoading,
          hasCart: hasCart,
          hasCheckout: hasCheckout,
          hasDefaultCartWithItems: hasDefaultCartWithItems,
          redirect: redirectCondition,
          redirect2: Array.isArray(carts) && carts.length === 0 && !hasCart,
        });
        router.push(redirectUrl);
      }
      return;
    }
  }, [hasDefaultCartWithItems, cartLoading, checkoutLoading, router.isReady]);

  return hasDefaultCartWithItems;
};
