import { IPaymentMethodsRequest, IPaymentMethodsRequestAttributes } from '../interfaces';
import { AxiosInstance } from 'axios';
import { IPaymentMethodsResponse } from '../interfaces';

const mockedPaymentMethodsResponse: IPaymentMethodsResponse = {
  paymentMethods: [
    {
      // details: [
      //   {
      //     key: 'encryptedCardNumber',
      //     type: 'cardToken',
      //   },
      //   {
      //     key: 'encryptedSecurityCode',
      //     type: 'cardToken',
      //   },
      //   {
      //     key: 'encryptedExpiryMonth',
      //     type: 'cardToken',
      //   },
      //   {
      //     key: 'encryptedExpiryYear',
      //     type: 'cardToken',
      //   },
      //   {
      //     key: 'holderName',
      //     optional: true,
      //     type: 'text',
      //   },
      // ],
      name: 'Credit Card',
      type: 'scheme',
    },
    // {
    //   name: 'Pay later with Klarna',
    //   type: 'klarna',
    // },
    // {
    //   name: 'Pay over time with Klarna.',
    //   type: 'klarna_account',
    // },
    // {
    //   name: 'Paysafecard',
    //   type: 'paysafecard',
    // },
  ],
};

/**
 * Get the Adyen payment methods configuration.
 *
 * @param {AxiosInstance} http - The Axios instance for making HTTP requests.
 * @param {Object} payload - The payload containing the payment methods request attributes and optional anonymId.
 * @param {IPaymentMethodsRequestAttributes} payload.paymentMethodsRequestAttributes - The attributes for the payment methods request.
 * @param {string} [payload.anonymId] - The optional anonymous customer unique ID.
 * @returns {Promise<IPaymentMethodsResponse | null>} A promise that resolves to the payment methods response or null.
 */
export const getAdyenPaymentMethodsConfigUnauthorized = (
  http: AxiosInstance,
  payload: { paymentMethodsRequestAttributes: IPaymentMethodsRequestAttributes; anonymId: string }
): Promise<IPaymentMethodsResponse | null> => {
  const data: IPaymentMethodsRequest = {
    data: {
      type: 'checkout-payments',
      attributes: payload.paymentMethodsRequestAttributes,
    },
  };

  const config: any = {
    params: {},
  };

  config.headers = { 'X-Anonymous-Customer-Unique-Id': payload.anonymId };

  return Promise.resolve(mockedPaymentMethodsResponse);

  // TODO: finish this when the real endpoint is ready
  // return raw.post(http, `checkout-payments`, data, config).then((response: AxiosResponse<any>) => {
  //   return response ? doSomeParsing(response.data.data) : null;
  // });
};

/**
 * Get the Adyen payment methods configuration.
 *
 * @param {AxiosInstance} http - The Axios instance for making HTTP requests.
 * @param {Object} payload - The payload containing the payment methods request attributes
 * @param {IPaymentMethodsRequestAttributes} payload.paymentMethodsRequestAttributes - The attributes for the payment methods request.
 * @returns {Promise<IPaymentMethodsResponse | null>} A promise that resolves to the payment methods response or null.
 */
export const getAdyenPaymentMethodsConfigAuthorized = (
  http: AxiosInstance,
  payload: { paymentMethodsRequestAttributes: IPaymentMethodsRequestAttributes }
): Promise<IPaymentMethodsResponse | null> => {
  const data: IPaymentMethodsRequest = {
    data: {
      type: 'checkout-payments',
      attributes: payload.paymentMethodsRequestAttributes,
    },
  };

  return Promise.resolve(mockedPaymentMethodsResponse);

  // TODO: finish this when the real endpoint is ready
  // return raw.post(http, `checkout-payments`, data, config).then((response: AxiosResponse<any>) => {
  //   return response ? doSomeParsing(response.data.data) : null;
  // });
};
