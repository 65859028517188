import { RequestState, SearchState } from '@elastic/search-ui';
import { AutocompleteResponseState } from '@elastic/search-ui/lib/esm/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ResultClickParams } from './types';

export type StateError = string | { [key: string]: string } | null;

export interface SearchErrors {
  search: StateError;
  autocomplete: StateError;
  resultClick: StateError;
}

export interface ElasticSearchState {
  errors: SearchErrors;
  loading: boolean;
  searchResponse: (SearchState & { resultSearchTerm: string }) | null;
  autocompleteResponse: AutocompleteResponseState | null;
  aoe: string | null;
  mobileSearch: boolean;
}

export const initialElasticSearchState: ElasticSearchState = {
  errors: { search: null, autocomplete: null, resultClick: null },
  loading: false,
  searchResponse: null,
  autocompleteResponse: null,
  aoe: null,
  mobileSearch: false,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialElasticSearchState,
  reducers: {
    clearState: () => initialElasticSearchState,
    search: (state, action: PayloadAction<{ requestState: RequestState; locale?: string; addResults: boolean }>) => ({
      ...state,
      errors: { ...state.errors, search: null },
      loading: true,
      // 🐰🥚
      aoe: ['lumberjack', "cheese steak jimmy's", 'robin hood', 'rock on'].some(
        (aoe: string) => aoe === action.payload.requestState.searchTerm
      )
        ? (action.payload.requestState.searchTerm as string)
        : null,
    }),
    searchSuccess: (state, action: PayloadAction<SearchState & { resultSearchTerm: string; addResults: boolean }>) => ({
      ...state,
      errors: { ...state.errors, search: null },
      loading: false,
      searchResponse: action.payload.addResults
        ? {
            ...action.payload,
            results:
              state.searchResponse?.resultSearchTerm !== action.payload.resultSearchTerm ||
              (action.payload.current &&
                state.searchResponse?.rawResponse?.info?.meta?.page.current >= action.payload.current)
                ? action.payload.results
                : [
                    ...state.searchResponse.results.filter(
                      (item) =>
                        !action.payload.results.some((resultItem) => resultItem['id']['raw'] === item['id']['raw'])
                    ),
                    ...action.payload.results,
                  ],
          }
        : action.payload,
    }),
    searchError: (state, action: PayloadAction<StateError>) => ({
      ...state,
      errors: { ...state.errors, search: action.payload },
      loading: false,
      searchResponse: initialElasticSearchState.searchResponse,
    }),

    autocomplete: (state, action: PayloadAction<{ requestState: RequestState; locale?: string }>) => ({
      ...state,
      errors: { ...state.errors, autocomplete: null },
      loading: true,
    }),
    autocompleteSuccess: (state, action: PayloadAction<AutocompleteResponseState>) => ({
      ...state,
      errors: { ...state.errors, search: null },
      loading: false,
      autocompleteResponse: action.payload,
    }),
    clearAutocomplete: (state) => ({
      ...state,
      errors: { ...state.errors, search: null },
      loading: false,
      autocompleteResponse: initialElasticSearchState.autocompleteResponse,
    }),
    autocompleteError: (state, action: PayloadAction<StateError>) => ({
      ...state,
      errors: { ...state.errors, autocomplete: action.payload },
      loading: false,
      autocompleteResponse: initialElasticSearchState.autocompleteResponse,
    }),

    resultClick: (state, action: PayloadAction<{ resultClickParams: ResultClickParams; autocomplete?: boolean }>) => ({
      ...state,
      errors: { ...state.errors, resultClick: null },
      loading: true,
    }),
    resultClickSuccess: (state) => ({
      ...state,
      errors: { ...state.errors, resultClick: null },
      loading: false,
    }),
    resultClickError: (state, action: PayloadAction<any>) => ({
      ...state,
      errors: { ...state.errors, resultClick: action.payload },
      loading: false,
    }),

    openMobileSearch: (state) => ({
      ...state,
      mobileSearch: true,
    }),
    closeMobileSearch: (state) => ({
      ...state,
      mobileSearch: false,
    }),
  },
});

export const SearchActions = searchSlice.actions;

export default searchSlice.reducer;
