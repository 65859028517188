import styles from './bundles.module.scss';
import { TypeCeBundlesFields } from '../../types';
import { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { Colors, IconCheck, VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';

interface BundleProcessed {
  title: string;
  pricePrefix?: string;
  price?: string;
  priceSuffix?: string;
  modules?: string;
  modulesProcessed?: Array<string>;
  includes?: string;
  includesProcessed?: Array<string>;
}

export interface BundlesProps extends TypeCeBundlesFields {
  items: Array<BundleProcessed>;
}

export function Bundles({ title, subline, items }: BundlesProps) {
  const { t } = useTranslation('common');
  const itemsProcessed = useMemo<Array<BundleProcessed>>(() => {
    const result: Array<BundleProcessed> = [];
    items.forEach((item) => {
      result.push({
        ...item,
        modulesProcessed: item.modules?.length ? item.modules?.split(',').map((el) => el.trim()) : [],
        includesProcessed: item.includes?.length ? item.includes?.split(',').map((el) => el.trim()) : [],
      });
    });

    return result;
  }, [items]);

  return (
    <motion.div
      data-elastic-include={true}
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classNames(styles['container'])}
      data-testid={'container'}
    >
      <h2 className={styles['headline']} data-testid={'headline'}>
        {title}
      </h2>
      <p className={styles['subline']} data-testid={'subline'}>
        {subline}
      </p>
      {!!itemsProcessed.length &&
        itemsProcessed.map((item, index) => (
          <div className={styles['bundle']} key={item.title + index} data-testid={'bundle'}>
            <div className={styles['bundle__upper']}>
              <h3 className={styles['bundle__title']} data-testid={'bundle-title'}>
                {item.title}
              </h3>

              {item.modulesProcessed && !!item.modulesProcessed.length && (
                <div className={classNames(styles['bundle__modules'], styles['modules'])}>
                  <h4 className={styles['modules__title']}>
                    <span className={styles['modules__amount']}>{item.modulesProcessed.length}</span>{' '}
                    {t('software.modules.title')}
                  </h4>
                  <ul className={styles['modules__list']} data-testid={'modules-list'}>
                    {item.modulesProcessed.slice(0, 4).map((module, index) => (
                      <li className={styles['modules__item']} key={module + index}>
                        {module}
                      </li>
                    ))}
                  </ul>
                  {item.modulesProcessed.length > 4 && (
                    <div className={styles['modules__more']} data-testid={'modules-more'}>
                      + {item.modulesProcessed.length - 4} {t('software.modules.more')}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={styles['bundle__lower']}>
              {item.includesProcessed && !!item.includesProcessed.length && (
                <ul
                  className={classNames(styles['bundle__includes'], styles['includes'])}
                  data-testid={'includes-list'}
                >
                  {item.includesProcessed.map((include, index) => (
                    <li className={styles['includes__item']} key={include + index}>
                      <IconCheck color={Colors.feelgoodMint} className={styles['includes__icon']} />
                      {include}
                    </li>
                  ))}
                </ul>
              )}

              {item.price && (
                <div className={classNames(styles['bundle__prices'], styles['prices'])} data-testid={'price-container'}>
                  {item.pricePrefix && (
                    <div className={styles['prices__prefix']} data-testid={'price-prefix'}>
                      {item.pricePrefix}
                    </div>
                  )}
                  <div className={styles['prices__price']} data-testid={'price'}>
                    {item.price}
                  </div>
                  {item.priceSuffix && (
                    <div className={styles['prices__suffix']} data-testid={'price-suffix'}>
                      {item.priceSuffix}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
    </motion.div>
  );
}

export default Bundles;
