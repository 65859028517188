import {MappingConfig, ValueDataType} from '@dxp/akeneo-client';

/**
 * The interface for contact persons from catalog.
 */
export interface IContactPerson {
  department: string;
  description: string;
  email: string;
  code: string;
  image: string;
  linkedInActive: boolean;
  linkedInUrl: string;
  name: string;
  phoneNumber: string;
}

/**
 * Mapping configuration for IContact Person interface
 */
export const iContactPersonConfig: MappingConfig = {
  department: {
    path: ['values', 'job_title'],
    valueData: ValueDataType.string,
    localized: true,
  },
  description: {
    path: ['values', 'description'],
    valueData: ValueDataType.string,
    localized: true,
  },
  name: {
    path: ['values', 'label'],
    valueData: ValueDataType.string,
    localized: true,
  },
  email: {
    path: ['values', 'email'],
    valueData: ValueDataType.string,
    localized: false,
  },
  code: {
    path: ['code'],
    valueData: ValueDataType.direct,
    localized: false,
  },
  image: {
    path: ['values', 'contact_photo_asset_link'],
    valueData: ValueDataType.string,
    localized: true,
  },
  linkedInActive: {
    path: ['values', 'button_linkedin'],
    valueData: ValueDataType.string,
    localized: true,
    modifier: (item: string) => item === 'on',
  },
  linkedInUrl: {
    path: ['values', 'linkedin'],
    valueData: ValueDataType.string,
    localized: false,
  },
  phoneNumber: {
    path: ['values', 'phone'],
    valueData: ValueDataType.string,
    localized: false,
  },
};
