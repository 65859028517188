import classNames from 'classnames';
import FullsizeMediaTeaser, {
  FullsizeMediaTeaserProps,
} from '../../components/teaser/fullsize-media-teaser/fullsize-media-teaser';
import styles from './single-fullsize-media-teaser.module.scss';
import { VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';

export interface SingleFullsizeMediaTeaserProps {
  teaser: FullsizeMediaTeaserProps;
}

export const SingleFullsizeMediaTeaser = (props: SingleFullsizeMediaTeaserProps & { className?: string }) => (
  <motion.div
    initial={VeneerAnimation.elementEntry.initial}
    whileInView={VeneerAnimation.elementEntry.whileInView}
    viewport={{ once: true }}
    transition={VeneerAnimation.elementEntry.transition}
    className={classNames(styles['container'], props.className)}
  >
    <FullsizeMediaTeaser {...props.teaser} variant="large"></FullsizeMediaTeaser>
  </motion.div>
);

export default SingleFullsizeMediaTeaser;
