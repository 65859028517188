import { VeneerAnimation } from '@dxp/veneer';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import FullsizeMediaTeaser, {
  FullsizeMediaTeaserProps,
} from '../../components/teaser/fullsize-media-teaser/fullsize-media-teaser';
import styles from './two-horizontal-teaserboxes.module.scss';
import classNames from 'classnames';

export interface TwoHorizontalTeaserboxesProps {
  items: Array<FullsizeMediaTeaserProps>;
}

export const TwoHorizontalTeaserboxes = (props: TwoHorizontalTeaserboxesProps & { className?: string }) => {
  const classes = classNames([styles['container'], props.className]);

  const renderItems = props.items.map((item: FullsizeMediaTeaserProps) => (
    <FullsizeMediaTeaser {...item} key={uuidv4()} variant="small" />
  ));

  return (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={classes}
    >
      {renderItems}
    </motion.div>
  );
};

export default TwoHorizontalTeaserboxes;
