import { CheckoutDataActions, sprykerClient } from '@dxp/commerce';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { AddressActions, AuthActions, CustomerActions } from '../index';
import { PayloadAction } from '@reduxjs/toolkit';
import { ICustomerLoginData } from '@dxp/spryker-client';
import { PlatformWebappStaticRoutes, ToasterActions } from '@dxp/core';
import { i18n } from 'next-i18next';
import { push } from 'connected-next-router';

/**
 * The watcher function to fetch access tokens.
 */
export function* watchCustomerLogin() {
  yield takeEvery(AuthActions.customerLoginRequested, customerLoginWorker);
}

/**
 * The worker function to fetch access tokens.
 * @param action
 */
export function* customerLoginWorker(
  action: PayloadAction<ICustomerLoginData & { locale: string | undefined; pushUrl?: string }>
) {
  const { locale, pushUrl } = action.payload;
  const payload = action.payload;
  delete payload.locale;

  try {
    const result: string | null = yield call(sprykerClient(locale).auth.login, { payload });
    yield all([
      put(
        ToasterActions.add({
          type: 'success',
          title: i18n?.t('toast.login.success.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.login.success.body', { ns: 'account' }) || '',
        })
      ),
      put(CheckoutDataActions.clearState()),
      put(AuthActions.customerLoginReceived(result)),
      ...(pushUrl ? [put(push(pushUrl))] : []),
    ]);
  } catch (error) {
    yield all([
      put(
        ToasterActions.add({
          type: 'warning',
          title: i18n?.t('toast.login.error.title', { ns: 'account' }) || '',
          bodytext: i18n?.t('toast.login.error.body', { ns: 'account' }) || '',
        })
      ),
      put(AuthActions.customerLoginRequestFailed()),
    ]);
  }
}

/**
 * The watcher function for user logouts.
 */
export function* watchCustomerLogout() {
  yield takeEvery(AuthActions.customerLogout, customerLogoutWorker);
}

/**
 * The worker function to handle logouts.
 */
export function* customerLogoutWorker(action: PayloadAction<{ locale: string | undefined }>) {
  const { locale } = action.payload;
  yield call(sprykerClient(locale).auth.logout);
  yield all([
    put(push(PlatformWebappStaticRoutes.general.home)),
    put(CustomerActions.clearCustomer()),
    put(AddressActions.clearAddresses()),
    put(CheckoutDataActions.clearState()),
    put(
      ToasterActions.add({
        type: 'success',
        title: i18n?.t('toast.logout.success.title', { ns: 'account' }) || '',
        bodytext: i18n?.t('toast.logout.success.body', { ns: 'account' }) || '',
      })
    ),
  ]);
}
