import { motion } from 'framer-motion';
import { MouseEvent, MouseEventHandler, ReactElement, useEffect, useRef } from 'react';
import styles from './black-backdrop.module.scss';
import classNames from 'classnames';

export interface BlackBackdropProps {
  children?: ReactElement;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const BlackBackdrop = (props: BlackBackdropProps) => {
  const ref = useRef(null);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!!ref.current && !!e.target && ref.current === e.target && !!props.onClick) {
      props.onClick(e);
    }
  };

  useEffect(() => {
    document.body.classList.add('fixed');

    return () => {
      document.body.classList.remove('fixed');
    };
  });

  return (
    <motion.div
      ref={ref}
      initial="closed"
      animate="open"
      exit="closed"
      onClick={(e: MouseEvent<HTMLDivElement>) => handleClick(e)}
      variants={{
        open: { opacity: 1 },
        closed: { opacity: 0 },
      }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={classNames(styles['black-backdrop'], props.className)}
    >
      {props.children}
    </motion.div>
  );
};

export default BlackBackdrop;
