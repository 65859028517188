import classnames from 'classnames';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { ScreenSizes, useScreenSizes } from '../../../hooks/screenSizeHook';
import styles from './table.module.scss';

interface TableCell {
  rowSpan?: number;
  colSpan?: number;
  content: string | ReactElement;
}

interface TableHeadCell extends TableCell {
  className?: string;
}

interface TableProps {
  name: string;
  head: TableHeadCell[];
  body: TableCell[][];
  className?: string;
}

export const Table = (props: TableProps) => {
  const screenSize = useScreenSizes();

  const renderRow = (row: TableCell[]) => {
    return row.map((el, index) => (
      <td key={`${props.name}_row_elem_${index}`}>
        {screenSize === ScreenSizes.SMALL && props.head[index]?.content && (
          <strong>{`${props.head[index].content}: `}</strong>
        )}
        {el.content}
      </td>
    ));
  };

  return (
    <table className={classNames(styles['table'], props.className)}>
      {screenSize !== ScreenSizes.SMALL && (
        <thead>
          <tr>
            {props.head.map((el, index) => (
              <th
                className={classnames(el.className)}
                colSpan={el.colSpan}
                rowSpan={el.rowSpan}
                key={`${props.name}_head_${index}`}
              >
                {el.content}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {props.body.map((row, rowIndex) => (
          <tr key={`${props.name}_row_${rowIndex}`}>{renderRow(row)}</tr>
        ))}
      </tbody>
    </table>
  );
};
