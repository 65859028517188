import styles from './progress-bar.module.scss';
import { motion, useInView } from 'framer-motion';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ScreenSizes, useScreenSizes } from '@dxp/veneer';

export interface ProgressBarProps {
  items: Array<string>;
  activeIndex: number;
  progressing: boolean;
  onChange: (i: number) => void;
  onNext: () => void;
}

export function ProgressBar(props: ProgressBarProps) {
  const screenSize = useScreenSizes();
  const [progress, setProgress] = useState<number>(0);
  const [activeTitle, setActiveTitle] = useState<string>('');
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const intervalRef = useRef<any>(null);

  useEffect(() => {
    if (props.progressing) {
      intervalRef.current = setInterval(() => {
        setProgress((progress) => progress + 1);
      }, 5000 / 100);
    }

    return () => clearInterval(intervalRef.current);
  }, [props.progressing]);

  useEffect(() => {
    if (progress === 100) {
      props.onNext();
      setProgress(0);
      setActiveTitle(props.items[props.activeIndex]);
    }
  }, [progress]);

  useEffect(() => {
    setProgress(0);
  }, [props.activeIndex]);

  const handleStepClick = (i: number) => {
    props.onChange(i);
    setProgress(0);
  };

  return screenSize === ScreenSizes.SMALL ? (
    <div className={styles['container-small']}>
      {props.items.map((step, index) => (
        <div
          key={index}
          onClick={() => handleStepClick(index)}
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(-1)}
          className={classNames(styles['step'], {
            [styles['step--active']]: props.activeIndex === index,
            [styles['step--current']]: props.activeIndex === index,
          })}
        >
          <div className={classNames(styles['title'], 'subheading')}>{step}</div>
          <div className={styles['dot']}>
            <motion.span
              className={classNames(styles['dot__bg'])}
              animate={{ scale: [1, 1.7, 1], opacity: [1, 0, 1] }}
              transition={{ ease: 'linear', duration: 1.8, repeat: Infinity }}
            ></motion.span>
            <span className={styles['dot__inner']}></span>
          </div>
          <div
            className={styles['line']}
            style={{
              width: '100%',
            }}
          >
            <div
              className={styles['line__fill']}
              style={{ width: index === props.activeIndex ? progress + '%' : index < props.activeIndex ? '100%' : 0 }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className={styles['container']}>
      {props.items.map((step, index) => (
        <div
          key={index}
          onClick={() => handleStepClick(index)}
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(-1)}
          className={classNames(styles['step'], {
            [styles['step--active']]: props.activeIndex === index || index < props.activeIndex,
            [styles['step--current']]: props.activeIndex === index,
          })}
        >
          <div className={classNames(styles['title'], 'subheading')}>{step}</div>
          <div className={styles['dot']}>
            <motion.span
              className={classNames(
                styles['dot__bg'],
                hoverIndex === index
                  ? styles['dot__bg--static']
                  : hoverIndex !== -1 || props.activeIndex !== index
                  ? styles['dot__bg--hide']
                  : null
              )}
              animate={{ scale: [1, 1.7, 1], opacity: [1, 0, 1] }}
              transition={{ ease: 'linear', duration: 1.8, repeat: Infinity }}
            ></motion.span>
            <span className={styles['dot__inner']}></span>
          </div>
          <div
            className={styles['line']}
            style={{
              width: index !== props.items.length - 1 ? 'calc(' + 100 / (props.items.length - 1) + '% - 3px)' : 0,
            }}
          >
            <div
              className={styles['line__fill']}
              style={{ width: index === props.activeIndex ? progress + '%' : index < props.activeIndex ? '100%' : 0 }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProgressBar;
