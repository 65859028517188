import { HubSpotActions, isValidHttpUrl, PlatformWebappStaticRoutes, ToasterActions } from '@dxp/core';
import { push } from 'connected-next-router';
import { i18n } from 'next-i18next';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { CheckoutActions } from './slice';
import { CartActions, CheckoutDataActions, sprykerClient } from '../../';
import { ICheckout, ICheckoutDataAddressItem, ICheckoutRequestAttributes } from '@dxp/spryker-client';
import { isString } from 'lodash';
import { redirect } from 'next/navigation';
import { PutEffect } from '@redux-saga/core/effects';

/**
 * The watcher function to place an order for an authenticated user
 */
export function* watchCheckoutRequested() {
  yield takeEvery(CheckoutActions.checkoutRequested, checkoutRequestedAsync);
}

/**
 * The worker function to place an order for an authenticated user.
 * @param action
 */
export function* checkoutRequestedAsync(
  action: PayloadAction<{
    locale: string;
    checkoutRequestAttributes: ICheckoutRequestAttributes;
    anonymId: string | null;
    termsAccepted: boolean | null;
  }>
) {
  const errorPutEffect = put(
    ToasterActions.add({
      type: 'warning',
      title: i18n?.t('toast.checkout.error.title', { ns: 'checkout' }) || '',
      bodytext: i18n?.t('toast.checkout.error.body', { ns: 'checkout' }) || '',
    })
  );

  try {
    const { locale, checkoutRequestAttributes, anonymId, termsAccepted } = action.payload;
    const customerAddress = checkoutRequestAttributes.billingAddress as ICheckoutDataAddressItem;
    const checkout: Array<ICheckout> | null = anonymId
      ? yield all([
          call(sprykerClient(locale).checkout.postUnauthorized, {
            checkoutRequestAttributes,
            anonymId,
          }),
          put(
            HubSpotActions.registerHubSpotUser({
              customer: {
                acceptedTerms: !!termsAccepted,
                address1: customerAddress.address1,
                address2: customerAddress.address2 || '',
                city: customerAddress.city,
                company: customerAddress.company,
                country: customerAddress.country as unknown as string,
                email: checkoutRequestAttributes.customer.email,
                firstName: checkoutRequestAttributes.customer.firstName,
                lastName: checkoutRequestAttributes.customer.lastName,
                phone: 'phone' in customerAddress ? customerAddress.phone : '',
                salutation: customerAddress.salutation,
                zipCode: customerAddress.zipCode,
              },
            })
          ),
        ])
      : yield call(sprykerClient(locale).checkout.postAuthorized, {
          checkoutRequestAttributes,
        });

    if (checkout) {
      const checkoutSuccessActions: Array<PutEffect<Action>> = [
        put(CheckoutActions.checkoutRequestSuccess(checkout)),
        put(CheckoutDataActions.clearState()),
        put(CartActions.clearCarts()),
        put(CartActions.cartsRequested({ locale })),
      ];

      if (anonymId) {
        checkoutSuccessActions.push(put(CartActions.cartsRequested({ locale })));
      }
      const checkoutResponseData = checkout as unknown as ICheckout;

      if (!checkoutResponseData?.redirectUrl) {
        // console.log('no redirect url -> no 3d secure checkout');
        checkoutSuccessActions.push(put(push(PlatformWebappStaticRoutes.commerce.checkout.success)));
      }
      // else {
      //   console.log('we got a redirect url, handle redirect after yield all');
      // }

      yield all(checkoutSuccessActions);

      // if there is a redirect url in checkout response, create Adyen redirect form
      if (
        checkoutResponseData &&
        isString(checkoutResponseData.redirectUrl) &&
        isValidHttpUrl(checkoutResponseData.redirectUrl) &&
        checkoutResponseData.redirectFields
      ) {
        const adyenRedirectForm = document.createElement('form');
        adyenRedirectForm.id = 'adyenExecute3DStepForm_';
        adyenRedirectForm.name = 'adyenExecute3DStepForm';
        adyenRedirectForm.method = 'post';
        adyenRedirectForm.action = checkoutResponseData.redirectUrl;
        adyenRedirectForm.style.display = 'none';

        // create hidden input fields for redirect fields given by checkout response
        for (const redirectField of checkoutResponseData.redirectFields) {
          for (const [key, value] of Object.entries(redirectField)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.id = `adyenExecute3DStepForm_${key}`;
            input.name = key;
            input.value = value;
            adyenRedirectForm.appendChild(input);
          }
        }

        /*
         submit adyen redirect form, this redirects the user to 3DSecure authorization page
         after confirming the payment there, he gets redirected to spryker to finish the order
         and finally redirected to DXP success page with order parameter
        */
        document.body.appendChild(adyenRedirectForm);
        adyenRedirectForm.submit();
      }
    } else {
      yield all([errorPutEffect, put(CheckoutActions.checkoutRequestFailed('No checkout result'))]);
    }
  } catch (error: any) {
    yield all([errorPutEffect, put(CheckoutActions.checkoutRequestFailed(error))]);
  }
}
