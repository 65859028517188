import { call, put, takeEvery } from 'redux-saga/effects';

import { PaymentActions } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';

/**
 * The watcher function to create a payment session
 */
export function* watchRequestPaymentSession() {
  yield takeEvery(PaymentActions.requestPaymentSession, fetchPaymentSession);
}
/**
 * The worker function to create a payment session
 */
export function* fetchPaymentSession(action: PayloadAction<{ locale: string }>) {
  try {
    const paymentResponse = [
      {
        amount: { currency: 'EUR', value: 12300 },
        countryCode: 'DE',
        expiresAt: '2023-05-23T10:01:59.000Z',
        merchantAccount: 'AmannGirrbachAG118ECOM',
        mode: 'embedded',
        reference: '3d714b24-6d3c-40e3-9148-0163794a9b40',
        returnUrl: '',
        shopperLocale: 'de-DE',
      },
      'payment-reference-id-#######',
    ];

    if (paymentResponse) {
      yield put(PaymentActions.requestPaymentSessionSuccess(paymentResponse));
    } else {
      yield put(PaymentActions.requestPaymentSessionFailed('No valid response when requesting payment'));
    }
  } catch (error: any) {
    yield put(PaymentActions.requestPaymentSessionFailed(error));
  }
}
//TODO: this can probably be removed since spryker generates payment sessons now
