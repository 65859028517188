import { ICart } from '@dxp/spryker-client';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useContext, useRef, useState } from 'react';
import AddVoucher from '../../add-voucher/add-voucher';
import DiscountItem from '../../discount-item/discount-item';
import styles from './cart-on-page.module.scss';
import { CartContext } from '../cart';
import { Colors, IconBin, Input, RoundButton, StandardButton } from '@dxp/veneer';
import Link from 'next-translate-routes/link';
import { AuthContext, IAuthContext, IPageConfig, PageConfigContext, PlatformWebappStaticRoutes } from '@dxp/core';
import { useDispatch, useSelector } from 'react-redux';
import { CartActions, CartSelectors, CartState, CommonCommerceState, CommonSelectors } from '../../../';
import { useAnyCart } from '../../../';
import { CartEmptyCta, CartItemLarge, CartSummary } from '../cart-particles';
import classNames from 'classnames';

export function CartOnPage() {
  // TODO: Delete customOrderReference state once custom order reference was implemented in sprykers cart
  const [customOrderReference, setCustomOrderReference] = useState<string | null>(null);
  const customOrderReferenceInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('cart');
  const { locale } = useRouter();
  const dispatch = useDispatch();

  const cart: ICart | undefined = useContext(CartContext);
  const pageConfig: IPageConfig | null = useContext(PageConfigContext);
  const authContext: IAuthContext | null = useContext(AuthContext);

  const cartLoading: boolean = useSelector((state: { cart: CartState }) => CartSelectors.selectCartLoading(state.cart));
  const hasCartAndItems = useAnyCart();

  const storePaymentMethods = useSelector((state: { commonCommere: CommonCommerceState }) =>
    CommonSelectors.selectStorePaymentMethods(state.commonCommere, locale || '')
  );

  const handleCustomOrderReferenceSubmit = (customOrderReference: string) => {
    if (locale && cart?.id) {
      if (authContext?.isAuthenticated) {
        dispatch(CartActions.addCustomOrderReference({ locale, payload: { cartId: cart.id, customOrderReference } }));
      }
      // else {
      //   dispatch(
      //     CartActions.guestAddCustomOrderReference({
      //       locale,
      //       payload: { cartId: cart.id, customOrderReference, anonymId: authContext?.anonymId || '' },
      //     })
      //   );
      // }
    }
  };

  const handleItemDelete = (sku: string) => {
    const deleteItemPayload = {
      itemId: sku,
      cartId: cart?.id || '',
    };

    if (authContext?.isAuthenticated) {
      dispatch(
        CartActions.deleteItem({
          locale: locale || '', // TODO Fallback lang?
          payload: deleteItemPayload,
        })
      );
    } else {
      dispatch(
        CartActions.guestDeleteItem({
          locale: locale || '', // TODO Fallback lang?
          payload: {
            ...deleteItemPayload,
            anonymId: authContext?.anonymId || '',
          },
        })
      );
    }
  };

  return hasCartAndItems && cart ? (
    <div className={styles['container']}>
      <div className={styles['cart-items']}>
        <AddVoucher className={styles['add-voucher']} />

        <div className={styles['active-vouchers']}>
          {(cart.discounts || []).map((discount, index) => (
            <DiscountItem key={discount.code + index} discount={discount} />
          ))}
        </div>

        {cart.items.map((item) => (
          <CartItemLarge
            className={styles['cart-item']}
            item={item}
            key={item.sku}
            icon={<IconBin />}
            onButtonClick={handleItemDelete}
          />
        ))}
      </div>

      <div className={styles['inner']}>
        <div className={styles['inner__wrap']}>
          {authContext?.isAuthenticated && (
            <div className={styles['order-reference']}>
              {!cart.orderCustomReference ? (
                <>
                  <div className={styles['order-reference__title']}>{t('orderReference.add.title')}</div>
                  <div className={styles['order-reference__content']}>
                    <Input
                      ref={customOrderReferenceInputRef}
                      type={'text'}
                      variant={Colors.white}
                      placeholder={t('orderReference.placeholder')}
                    />
                    <StandardButton
                      label={t('save')}
                      variant={Colors.brilliantBlue}
                      onClick={() => {
                        if (customOrderReferenceInputRef.current?.value) {
                          setCustomOrderReference(customOrderReferenceInputRef.current.value);
                          handleCustomOrderReferenceSubmit(customOrderReferenceInputRef.current.value);
                        }
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles['order-reference--added']}>
                  <div className={styles['order-reference__added--wrap']}>
                    <div className={styles['order-reference--added__title']}>{t('orderReference.title')}:</div>
                    <div className={styles['order-reference--added__reference']}>{cart.orderCustomReference}</div>
                  </div>
                  <RoundButton
                    icon={<IconBin />}
                    type={'button'}
                    disabled={cartLoading}
                    variant={Colors.brilliantBlue}
                    onClick={() => handleCustomOrderReferenceSubmit('')}
                  />{' '}
                </div>
              )}
            </div>
          )}
          <div className={styles['summary']}>
            <div className={styles['summary__title']}>{t('overview')}</div>
            <CartSummary className={styles['summary__prices']} />
            <div className={styles['summary__cta']}>
              <Link href={PlatformWebappStaticRoutes.commerce.checkout.index}>
                <StandardButton
                  isLoading={cartLoading}
                  className={styles['go-to-checkout']}
                  label={t('toCheckout')}
                  variant={Colors.feelgoodMint}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={classNames(styles['container'], styles['container--empty'])}>
      <div className={styles['empty__label']}>{t('emptyCart.title')}</div>
      {!!pageConfig?.cartCTA && pageConfig.cartCTA.map((cta) => <CartEmptyCta key={cta.linkUrl} {...cta} />)}
    </div>
  );
}

export default CartOnPage;
