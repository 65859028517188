import styles from './quantifier.module.scss';
import classNames from 'classnames';
import { Colors } from '../../../types';
import { useEffect, useState } from 'react';
import { IconMinus, IconPlus } from '../../decoration/icons';
import Loader from '../../decoration/loader/loader';

export interface QuantifierProps {
  variant: Colors.white | Colors.lightGrey | 'faded-white';
  minimum?: number;
  value?: number;
  onChange?: (x: number) => void;
  inactive?: boolean;
  loading?: boolean;
  triggerReset?: number;
}

export const Quantifier = (props: QuantifierProps & { className?: string }) => {
  const [number, setNumber] = useState<number>(props.value ?? props.minimum ?? 0);

  useEffect(() => {
    if (props.triggerReset) {
      setNumber(props.value ?? props.minimum ?? 0);
    }
  }, [props.triggerReset]);

  const handleChange = (n: number) => {
    if (n >= (props.minimum ?? 0)) {
      setNumber(n);
      if (props.onChange) {
        props.onChange(n);
      }
    } else {
      console.log('invalid number');
    }
  };

  return (
    <div
      className={classNames(styles['container'], styles['container--' + props.variant], props.className)}
      data-testid={'variant'}
    >
      <div
        className={classNames(styles['button'], styles['button--minus'], {
          [styles['button--inactive']]: number === (props.minimum ?? 0) || props.inactive || props.loading,
        })}
        onClick={() => handleChange(number - 1)}
      >
        <IconMinus color={Colors.intenseBlue} />
      </div>
      {props.loading ? (
        <div className={styles['loader']}>
          <Loader color={Colors.intenseBlue} className={styles['loader__icon']} />
        </div>
      ) : (
        <input
          type="number"
          value={number}
          className={styles['input']}
          aria-label="Amount"
          onChange={(e) => handleChange(+e.target.value)}
          disabled={props.inactive}
        />
      )}
      <div
        className={classNames(styles['button'], styles['button--plus'], {
          [styles['button--inactive']]: props.inactive || props.loading,
        })}
        onClick={() => handleChange(number + 1)}
      >
        <IconPlus color={Colors.intenseBlue} />
      </div>
    </div>
  );
};

export default Quantifier;
