import styles from './payment-methods.module.scss';
import { useContext } from 'react';
import { PageConfigContext } from '@dxp/core';
import Image from 'next/image';

export const PaymentMethods = ({ className }: { className?: string }) => {
  const pageConfig = useContext(PageConfigContext);

  return pageConfig && pageConfig.paymentMethods && pageConfig.paymentMethods?.length > 0 ? (
    <div className={styles['container']}>
      {pageConfig.paymentMethods.map((paymentMethod) => {
        return (
          <div className={styles['item']} key={paymentMethod.sys.id}>
            <Image
              className={styles['item__image']}
              src={paymentMethod.fields.file.url.replace('//', 'https://')}
              alt={paymentMethod.fields.title}
              fill={true}
            />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default PaymentMethods;
