import { createSelector } from '@reduxjs/toolkit';
import { CheckoutDataState } from './slice';

const selectCheckoutDataState = (state: CheckoutDataState) => state;

const selectCheckoutData = createSelector([selectCheckoutDataState], (state: CheckoutDataState) => state.checkoutData);

const selectGuestCheckoutPersonalData = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.guestCheckoutPersonalData
);

const selectGuestCheckout = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.guestCheckout
);

const selectBillingAddressId = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.selectedBillingAddressId
);

const selectShippingAddressId = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.selectedShippingAddressId
);

const selectGuestCheckoutBillingAddress = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.guestCheckoutBillingAddress
);

const selectGuestCheckoutShippingAddress = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.guestCheckoutShippingAddress
);

const selectShipmentMethods = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.checkoutData?.shipmentMethods
);

const selectPaymentMethods = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.checkoutData?.paymentMethods
);

const selectAcceptedTerms = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.acceptedTerms
);

const selectCheckoutDataAddresses = createSelector(
  [selectCheckoutDataState],
  (state: CheckoutDataState) => state.checkoutData?.addresses ?? []
);

export const CheckoutDataSelectors = {
  selectCheckoutDataState,
  selectCheckoutData,
  selectGuestCheckoutPersonalData,
  selectGuestCheckout,
  selectBillingAddressId,
  selectShippingAddressId,
  selectCheckoutDataAddresses,
  selectGuestCheckoutBillingAddress,
  selectGuestCheckoutShippingAddress,
  selectShipmentMethods,
  selectPaymentMethods,
  selectAcceptedTerms,
};
