import { v4 as uuidv4 } from 'uuid';
import { Colors, LinkBox, VeneerAnimation } from '@dxp/veneer';
import styles from './teaserboxes.module.scss';
import { motion } from 'framer-motion';
import Link from 'next/link';

export interface Teaserbox {
  title: string;
  subline: string;
  linkUrl: string;
  variant: Colors;
}

export interface TeaserboxesProps {
  items: Array<Teaserbox>;
}

export const Teaserboxes = (props: TeaserboxesProps) => {
  const renderItems = (items: Array<Teaserbox>) =>
    items?.map((child: Teaserbox) => (
      <Link href={child.linkUrl} key={uuidv4()}>
        <LinkBox
          className={styles['box']}
          title={child.title}
          subline={child.subline}
          variant={child.variant as Colors.smartBlue | Colors.cosyCashmere}
        ></LinkBox>
      </Link>
    ));

  return props.items?.length > 0 ? (
    <motion.div
      initial={VeneerAnimation.elementEntry.initial}
      whileInView={VeneerAnimation.elementEntry.whileInView}
      viewport={{ once: true }}
      transition={VeneerAnimation.elementEntry.transition}
      className={styles['inner']}
    >
      {renderItems(props.items)}
    </motion.div>
  ) : null;
};

export default Teaserboxes;
