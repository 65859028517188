import { call, put, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { IOrderRequestedPayload, OrdersActions } from './slice';
import { sprykerClient } from '../../';
import { IOrder } from '@dxp/spryker-client';

/**
 * The watcher function to fetch orders from an authenticated user
 */
export function* watchFetchOrders() {
  yield takeEvery(OrdersActions.ordersRequested, fetchOrdersAsync);
}

/**
 * The worker function to fetch orders from an authenticated user.
 * @param action
 */
export function* fetchOrdersAsync(action: PayloadAction<{ locale: string }>) {
  try {
    const { locale } = action.payload;
    const result: Array<IOrder> | null = yield call(sprykerClient(locale).orders.get, {});

    if (result) {
      yield put(OrdersActions.ordersRequestSuccess(result));
    } else {
      yield put(OrdersActions.ordersRequestFailed('No result when fetching orders'));
    }
  } catch (error: any) {
    yield put(OrdersActions.ordersRequestFailed(error));
  }
}

/**
 * The watcher function to fetch a specific order from an authenticated user
 */
export function* watchFetchOrder() {
  yield takeEvery(OrdersActions.orderRequested, fetchOrderAsync);
}

/**
 * The worker function to fetch orders from an authenticated user.
 * @param action
 */
export function* fetchOrderAsync(action: PayloadAction<IOrderRequestedPayload>) {
  try {
    const { locale, orderId } = action.payload;
    const result: IOrder | null = yield call(sprykerClient(locale).orders.getOne, { orderId: orderId });
    if (result) {
      yield put(OrdersActions.orderRequestSuccess(result));
    } else {
      yield put(OrdersActions.orderRequestFailed(`No result when fetching order: ${action.payload.orderId}`));
    }
  } catch (error: any) {
    yield put(OrdersActions.orderRequestFailed(error));
  }
}
