import styles from './shipping-information.module.scss';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

export interface ShippingInformationProps {
  children: React.ReactElement | React.ReactElement[];
}

export const ShippingInformation = (props: ShippingInformationProps) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles['container']}>
      <svg
        fill="none"
        viewBox="0 0 13 19"
        className={classNames(styles['icon'], styles['hide-mobile'])}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m5.35847 12.8914c-.03597-.6743.03596-1.5464.60239-2.1938.71928-.80916 1.58242-.5304 2.96704-1.21375.36863-.17982 2.0499-1.01599 2.6074-2.64336.4855-1.42957 0-3.11089-.9081-4.12687-2.18482-2.445558-7.43557-1.807196-9.11689.87212-.48551.77323-.593402 1.59141-.611384 2.16684"
          stroke="#002d5f"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <circle cx="5.38557" cy="16.9368" fill="#002d5f" r="1.16731" />
      </svg>
      <div className={styles['label']} data-testid="label">
        <span className={styles['hide-mobile']}>{t('product.commerce.shipping.learnMore1')}</span>
        {t('product.commerce.shipping.learnMore2')}
      </div>
      <div className={styles['options']} data-testid="options">
        {props.children}
      </div>
    </div>
  );
};

export default ShippingInformation;
